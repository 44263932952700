import axios from 'axios'
import {
    AJOUTER_PLAN_COMPTABLE,
    IMPORTER_PLAN_COMPTABLE,
    LISTE_PLAN_COMPTABLE, LISTE_PLAN_COMPTABLE_ACTIVE,
    MODIFIER_PLAN_COMPTABLE
} from "../../api/apiRoutes";

export const ajouterPlanComptable = async (data) => {
    return axios.post(AJOUTER_PLAN_COMPTABLE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const importerPlanComptable = async (data) => {
    return axios.post(IMPORTER_PLAN_COMPTABLE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const modifierPlanComptable = async (data, plan_id) => {
    return axios.put(`${MODIFIER_PLAN_COMPTABLE}/${plan_id}`, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListePlanComptable = async () => {
    return axios.get(LISTE_PLAN_COMPTABLE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListePlanComptableActive = async () => {
    return axios.get(LISTE_PLAN_COMPTABLE_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}
//
// export const deletePeriode = async (data) => {
//     return axios.post(SUPPRIMER_PERIODE, data).then(res => {
//         if (res.status === 200) {
//             return res.data
//         } else {
//             return {
//                 status: 'error',
//                 message: "La connexion à internet est instable !"
//             }
//         }
//     }).catch(err => console.log(err))
// }
//
//
// export const updateEtatPeriode = async (data) => {
//     return axios.post(MODIFIER_ETAT_PERIODE, data).then(res => {
//         if (res.status === 200) {
//             return res.data
//         } else {
//             return {
//                 status: 'error',
//                 message: "La connexion à internet est instable !"
//             }
//         }
//     }).catch(err => console.log(err))
// }