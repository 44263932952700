import React from "react";

const ExamenAnalytiqueEFPrint = ({dataActifs, dataPassifs, dataResults, dataSig}) => {
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thStyle = {
        border: '1px solid #000000',
        textTransform: 'uppercase',
        textAlign: 'left',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '5px',
        lineHeight: "0.8"
    };

    const spanStyle = {
        fontSize: '10px',
    };

    const spanValueStyle = {
        fontSize: '12px',
        textAlign: 'end',
    };

    const tabHeaderActifs = [
        {title: "Réf", size: "50px", rowSpan: 2, colSpan: 1},
        {title: "Libellé", size: "350px", rowSpan: 2, colSpan: 1},
        {title: "Exercice N", size: "100px", rowSpan: 2, colSpan: 1},
        {title: "Exercice N-1", size: "100px", rowSpan: 2, colSpan: 1},
        {title: "N - N-1", size: "150px", rowSpan: 1, colSpan: 2},
        {title: "Exercice N-2", size: "100px", rowSpan: 2, colSpan: 1},
        {title: "N-1 - N-2", size: "150px", rowSpan: 1, colSpan: 2},
        // {title: "Commentaire", size: "300px", rowSpan: 2, colSpan: 1},
    ]

    return(
        <>
            <div>
                <div className="" style={{display: "flex", flexDirection: "column", marginBottom: "" }}>
                    {(dataActifs ?? []).length > 0 && <div style={{marginBottom: "25px"}}>
                        <h2><strong>BILAN ACTIFS</strong></h2>
                        <table style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                {(tabHeaderActifs ?? []).map((item, index) => (
                                    <th style={{...thStyle, width: item.size}} key={index} rowSpan={item.rowSpan} colSpan={item.colSpan}>
                                        <span style={spanStyle}>
                                          {item.title}
                                      </span>
                                    </th>
                                ))}
                            </tr>
                            <tr style={thStyle}>
                                <th style={{...thStyle, width: "100px"}}>
                                        <span style={spanStyle}>
                                          VARIATION
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "50px"}}>
                                        <span style={spanStyle}>
                                         TAUX
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "100"}}>
                                    <span style={spanStyle}>
                                          VARIATION
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "50px"}}>
                                        <span style={spanStyle}>
                                          TAUX
                                      </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="">
                            {(dataActifs ?? []).map((item, index) => (
                                <tr style={{...tdStyle}} key={index}>
                                    <td style={tdStyle}>
                                      <span style={spanStyle}>
                                          {item.compte}
                                      </span>
                                    </td>
                                    <td style={tdStyle}>
                                      <span style={spanStyle}>
                                         {item.libelle}
                                      </span>
                                    </td>
                                    <td style={tdStyle}>
                                       <div style={spanValueStyle}>
                                        {item.soldeN && item.soldeN.toLocaleString('fr')}
                                      </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN_1 || 0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN - item.soldeN_1).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {( ( (item.soldeN)=== 0 ? 0 : (item.soldeN - item.soldeN_1) / item.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                                        </div>
                                    </td>
                                    {/*<td style={tdStyle}>*/}
                                    {/*    <div style={spanStyle}>*/}
                                    {/*        {item.commentaire || ""}*/}
                                    {/*    </div>*/}
                                    {/*</td>*/}
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN - item.soldeN_1).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {( ( (item.soldeN)=== 0 ? 0 : (item.soldeN - item.soldeN_1) / item.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>}
                    {(dataPassifs ?? []).length > 0 && <div style={{marginBottom: "200px"}}>
                        <h2><strong>BILAN PASSIFS</strong></h2>
                        <table style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                {(tabHeaderActifs ?? []).map((item, index) => (
                                    <th style={{...thStyle, width: item.size}} key={index} rowSpan={item.rowSpan} colSpan={item.colSpan}>
                                        <span style={spanStyle}>
                                          {item.title}
                                      </span>
                                    </th>
                                ))}
                            </tr>
                            <tr style={thStyle}>
                                <th style={{...thStyle, width: "100px"}}>
                                        <span style={spanStyle}>
                                          VARIATION
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "50px"}}>
                                        <span style={spanStyle}>
                                         TAUX
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "100"}}>
                                    <span style={spanStyle}>
                                          VARIATION
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "50px"}}>
                                        <span style={spanStyle}>
                                          TAUX
                                      </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="">
                            {(dataPassifs ?? []).map((item, index) => (
                                <tr style={{...tdStyle}} key={index}>
                                    <td style={tdStyle}>
                                      <span style={spanStyle}>
                                          {item.compte}
                                      </span>
                                    </td>
                                    <td style={tdStyle}>
                                      <span style={spanStyle}>
                                         {item.libelle}
                                      </span>
                                    </td>
                                    <td style={tdStyle}>
                                       <div style={spanValueStyle}>
                                        {item.soldeN && item.soldeN.toLocaleString('fr')}
                                      </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN_1 || 0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN - item.soldeN_1).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {( ( (item.soldeN)=== 0 ? 0 : (item.soldeN - item.soldeN_1) / item.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                                        </div>
                                    </td>
                                    {/*<td style={tdStyle}>*/}
                                    {/*    <div style={spanStyle}>*/}
                                    {/*        {item.commentaire || ""}*/}
                                    {/*    </div>*/}
                                    {/*</td>*/}
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN - item.soldeN_1).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {( ( (item.soldeN)=== 0 ? 0 : (item.soldeN - item.soldeN_1) / item.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>}
                    {(dataResults ?? []).length > 0 && <div style={{marginBottom: "25px"}}>
                        <h2><strong>COMPTE DE RESULTAT</strong></h2>
                        <table style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                {(tabHeaderActifs ?? []).map((item, index) => (
                                    <th style={{...thStyle, width: item.size}} key={index} rowSpan={item.rowSpan} colSpan={item.colSpan}>
                                        <span style={spanStyle}>
                                          {item.title}
                                      </span>
                                    </th>
                                ))}
                            </tr>
                            <tr style={thStyle}>
                                <th style={{...thStyle, width: "100px"}}>
                                        <span style={spanStyle}>
                                          VARIATION
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "50px"}}>
                                        <span style={spanStyle}>
                                         TAUX
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "100"}}>
                                    <span style={spanStyle}>
                                          VARIATION
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "50px"}}>
                                        <span style={spanStyle}>
                                          TAUX
                                      </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="">
                            {(dataResults ?? []).map((item, index) => (
                                <tr style={{...tdStyle}} key={index}>
                                    <td style={tdStyle}>
                                      <span style={spanStyle}>
                                          {item.compte}
                                      </span>
                                    </td>
                                    <td style={tdStyle}>
                                      <span style={spanStyle}>
                                         {item.libelle}
                                      </span>
                                    </td>
                                    <td style={tdStyle}>
                                       <div style={spanValueStyle}>
                                        {item.soldeN && item.soldeN.toLocaleString('fr')}
                                      </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN_1 || 0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN - item.soldeN_1).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {( ( (item.soldeN)=== 0 ? 0 : (item.soldeN - item.soldeN_1) / item.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                                        </div>
                                    </td>
                                    {/*<td style={tdStyle}>*/}
                                    {/*    <div style={spanStyle}>*/}
                                    {/*        {item.commentaire || ""}*/}
                                    {/*    </div>*/}
                                    {/*</td>*/}
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN - item.soldeN_1).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {( ( (item.soldeN)=== 0 ? 0 : (item.soldeN - item.soldeN_1) / item.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>}
                    {(dataSig ?? []).length > 0 && <div style={{marginBottom: "25px"}}>
                        <h2><strong>SIG</strong></h2>
                        <table style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                {(tabHeaderActifs ?? []).map((item, index) => (
                                    <th style={{...thStyle, width: item.size}} key={index} rowSpan={item.rowSpan} colSpan={item.colSpan}>
                                        <span style={spanStyle}>
                                          {item.title}
                                      </span>
                                    </th>
                                ))}
                            </tr>
                            <tr style={thStyle}>
                                <th style={{...thStyle, width: "100px"}}>
                                        <span style={spanStyle}>
                                          VARIATION
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "50px"}}>
                                        <span style={spanStyle}>
                                         TAUX
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "100"}}>
                                    <span style={spanStyle}>
                                          VARIATION
                                      </span>
                                </th>
                                <th style={{...thStyle, width: "50px"}}>
                                        <span style={spanStyle}>
                                          TAUX
                                      </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="">
                            {(dataSig ?? []).map((item, index) => (
                                <tr style={{...tdStyle}} key={index}>
                                    <td style={tdStyle}>
                                      <span style={spanStyle}>
                                          {item.compte}
                                      </span>
                                    </td>
                                    <td style={tdStyle}>
                                      <span style={spanStyle}>
                                         {item.libelle}
                                      </span>
                                    </td>
                                    <td style={tdStyle}>
                                       <span style={spanValueStyle}>
                                        {item.soldeN && item.soldeN.toLocaleString('fr')}
                                      </span>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN_1 || 0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN - item.soldeN_1).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {( ( (item.soldeN)=== 0 ? 0 : (item.soldeN - item.soldeN_1) / item.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                                        </div>
                                    </td>
                                    {/*<td style={tdStyle}>*/}
                                    {/*    <div style={spanStyle}>*/}
                                    {/*        {item.commentaire || ""}*/}
                                    {/*    </div>*/}
                                    {/*</td>*/}
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {(item.soldeN - item.soldeN_1).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td style={tdStyle}>
                                        <div style={spanValueStyle}>
                                            {( ( (item.soldeN)=== 0 ? 0 : (item.soldeN - item.soldeN_1) / item.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>}
                </div>
            </div>
        </>
    )
}

export {ExamenAnalytiqueEFPrint}