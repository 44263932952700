import React, { useEffect, useState } from "react";
import {CloseSquareOutlined, SwapOutlined} from "@ant-design/icons";
import {Button, Col, notification, Row, Table, Tooltip, Typography} from "antd";
import { getBalanceDefinitifDetails, getBalanceProvisoiresDetails } from "../../../../../../../data/balance/balance";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faScaleBalanced} from "@fortawesome/free-solid-svg-icons";
import {SearchHeader} from "../../../../../../layout/header/HeaderList";
import ModalAlert from "../../../../../../modals/Modal";
import {ModalTitle} from "../../../../../../modals/modals.components";

const { Text } = Typography;

const BalanceProvisoireDefinitif = ({
        mission,
        setBalanceData,
        balanceData,
        chargement,
        setIsTaskSave,
        isProvisoire, client
    }) => {
    const [balanceProv, setBalanceProv] = useState([]);
    const [balanceDef, setBalanceDef] = useState([]);
    const [selectedBalances, setSelectedBalances] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
        },
    });

    useEffect(() => {
        if(mission){
            const fetchBalanceProvisoire = async (id) => {
                getBalanceProvisoiresDetails(id)
                    .then((res) => {
                        if (res.results) {
                            setBalanceProv(res.results.Lea_BalanceDetails)
                        } else {
                            setBalanceProv([])
                        }
                    })
            }

            const fetchBalanceDefinitif = async (id) => {
                getBalanceDefinitifDetails(id)
                    .then((res) => {
                        if (res.results) {
                            setBalanceDef(res.results.Lea_BalanceDetails)
                        } else {
                            setBalanceDef([])
                        }
                    })
            }

            fetchBalanceProvisoire(mission.id);
            fetchBalanceDefinitif(mission.id)
        }
    }, [mission])

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

    };

    const handleResultatClick = () => {
        setLoading(true);
        setTimeout(() => {
            if(balanceData && balanceData.length > 0){
                setBalanceData([]);
            }

            const bilanMap = new Map();

            if (balanceDef.length <= balanceProv.length) {
                balanceProv.forEach((element1, index) => {
                    const isExist = balanceDef.find((item) => item.numeroCompte.includes(element1.numeroCompte));

                    const objTabB = {
                        isExistDef: !isExist,
                        isExistProv: false,
                        numeroCompteProv: element1?.numeroCompte || '',
                        libelleProv:  element1?.libelle || '',
                        debitProv:  element1?.soldeDebit || 0,
                        creditProv: element1?.soldeCredit || 0,
                        numeroCompteDef: element1?.numeroCompte || '',
                        libelleDef: element1?.libelle || '',
                        debitDef: isExist ? (isExist.soldeDebit || 0) : 0,
                        creditDef: isExist ? (isExist.soldeCredit || 0) : 0,
                        commentaire: null
                    };
                    bilanMap.set(element1.numeroCompte, objTabB);
                });
            }

            balanceDef.forEach((balance) => {
                const isExist = balanceProv.find((item) => item.numeroCompte.includes(balance.numeroCompte));

                const existingElement = bilanMap.get(balance.numeroCompte);
                if (existingElement) {
                    existingElement.debitDef = balance.soldeDebit || 0;
                    existingElement.creditDef = balance.soldeCredit || 0;
                } else {
                    const newElement = {
                        isExistDef: false,
                        isExistProv: !isExist,
                        compte_Prov: balance.numeroCompte,
                        libelle_Prov: balance.libelle,
                        debitProv: isExist ? (isExist.soldeDebit || 0) : 0,
                        creditProv: isExist ? (isExist.soldeCredit || 0) : 0,
                        compte_Def: balance.numeroCompte,
                        libelle_Def: balance.libelle,
                        debitDef: balance.soldeDebit || 0,
                        creditDef: balance.soldeCredit || 0,
                        commentaire: null,
                    };

                    bilanMap.set(balance.numeroCompte, newElement);
                }
            });

            const updateBalance = [...bilanMap.values()];

            setBalanceData(updateBalance);

            setIsTaskSave(prevState => [...prevState, 1]);

            notification.warning({
                description: "Envoyé avec succès a l'historique Balances !",
            });
            setLoading(false);
        }, 1000)
    };

    const handleModalOpen = (id, balances, title) => {
        setSelectedBalances(balances || [])
        setTitle(title || "")

        setIsModalOpen(prevState => !prevState)
    };

    const columnsModal = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Compte</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.numeroCompte}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.libelle}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Débit</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeDebit || 0).toLocaleString('fr')}
                </div>
            ),
        },

        {
            title: () => <TableTitle>Crédit</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeCredit || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Solde</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {((record.soldeDebit || 0)-(record.soldeCredit || 0)).toLocaleString('fr') || 0}
                </div>
            ),
        },

    ];

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Compte Prov</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistProv ? '' : record.numeroCompteProv}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé Prov</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistProv ? '' : record.libelleProv}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Débit Prov</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.debitProv || 0).toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Crédit Prov</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.creditProv || 0).toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Compte Def</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistDef ? '' : record.numeroCompteDef}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé Def</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                   {record.isExistDef ? '' : record.libelleDef}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Débit Def</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.debitDef || 0).toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Crédit N-1</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.creditDef || 0).toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>DIFF SD</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    { record.debitProv - record.debitDef}
                </div>
            ),
        },
        {
            title: () => <TableTitle>DIFF SC</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {  record.creditProv - record.creditDef}
                </div>
            ),
        },
    ];

    return (
        <>
            <div className="py-5">
                <Row gutter={[16, 16]}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 20 }}
                        xl={{ span: 20 }}
                    >
                        <div className="d-flex flex-wrap align-items-center">
                            <div className="me-10">
                                <Tooltip title={`Balance Provisoire ${mission.annee || "N"}`}>
                                    <Button
                                        size={"large"}
                                        loading={loading || chargement}
                                        style={{
                                            backgroundColor: appTheme.colors.primary,
                                            color: "white"
                                        }}
                                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faScaleBalanced}/>}
                                        onClick={() => handleModalOpen(1, balanceProv, `Balance Provisoire ${mission.annee || "N"}`)}
                                    >
                                        <span className='indicator-label text-white fs-6 fw-bolder'>{`Balance Provisoire ${mission.annee || "N"}`}</span>
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className="">
                                <Tooltip title={`Balance Définitif ${mission?.annee || 'N'}`}>
                                    <Button
                                        size={"large"}
                                        loading={loading || chargement}
                                        style={{
                                            backgroundColor: appTheme.colors.secondary,
                                            color: "white"
                                        }}
                                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faScaleBalanced}/>}
                                        onClick={() => handleModalOpen(2, balanceDef, `Balance Définitif ${mission?.annee || 'N'}`)}
                                    >
                                        <span className='indicator-label text-white'>{`Balance Définitif ${mission?.annee || 'N'}`}</span>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <Tooltip title="Résultats">
                            <Button
                                disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                                size={"large"}
                                // loading={loading}
                                style={{
                                    backgroundColor: appTheme.colors.primary_blue,
                                    color: "white"
                                }}
                                icon={<SwapOutlined
                                    style={{fontSize: '20px', marginRight: '5px'}}/>}
                                onClick={handleResultatClick}
                            >
                                {(!loading || !chargement) &&
                                    <span className='indicator-label text-white'>Résultats</span>}
                                {(loading || chargement) && (
                                    <span className='indicator-progress fs-9 text-white'
                                          style={{display: 'block'}}>
                                              Patientez svp...
                                              <span
                                                  className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                )}
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
                <div className="table-responsive">
                    {(balanceData ?? []).length > 0 && <div className="card-header border-0 pt-2">
                        <SearchHeader
                            placeholder="Rechercher par la référence, le libellé..."
                            // handleSearch={handleSearchChange}
                        />
                    </div>}
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                rowClassName={(record,) =>
                                    `${record.color ? `bg-${record?.color}` : ''} h-3px p-0 m-0 lh-0.5`
                                }
                                columns={columns}
                                rowKey={(record, index) => index}
                                dataSource={(balanceData ?? [])}
                                pagination={tableParams.pagination}
                                onChange={handleTableChange}
                                loading={chargement || loading}
                                rowSelection
                                size={"small"}
                                summary={(pageData) => {
                                    let totalSDRan = 0;
                                    let totalSCRan = 0;
                                    let totalSDN_1 = 0;
                                    let totalSCN_1 = 0;
                                    let totalDiffSD = 0;
                                    let totalDiffSC = 0;
                                    pageData.forEach(({ debitProv, creditProv, debitDef, creditDef}) => {
                                        totalSDRan += debitProv;
                                        totalSCRan += creditProv;
                                        totalSDN_1 += debitDef
                                        totalSCN_1 += creditDef
                                        totalDiffSD += (debitProv - debitDef)
                                        totalDiffSC += (creditProv - creditDef)
                                    });
                                    return (
                                        <>
                                            <Table.Summary fixed>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>Total</Table.Summary.Cell>
                                                    <Table.Summary.Cell index={3} className='text-end'>
                                                        <Text type="success">{''}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={4} className='text-end'>
                                                        <Text type="success" style={{fontSize: '12px', fontWeight: "600"}}>{totalSDRan.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={5} className='text-end'>
                                                        <Text type="success" style={{fontSize: '12px', fontWeight: "600"}}>{totalSCRan.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={6} className='text-end'>
                                                        <Text type="success">{''}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={7} className='text-end'>
                                                        <Text type="success">{''}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={8} className='text-end'>
                                                        <Text type="success" style={{fontSize: '12px', fontWeight: "600"}}>{totalSDN_1.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={9} className='text-end'>
                                                        <Text type="success" style={{fontSize: '12px', fontWeight: "600"}}>{totalSCN_1.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={10} className='text-end'>
                                                        <Text type="success" style={{fontSize: '12px', fontWeight: "600"}}>{totalDiffSD.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={11} className='text-end'>
                                                        <Text type="success" style={{fontSize: '12px', fontWeight: "600"}}>{totalDiffSC.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </Table.Summary>
                                        </>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalAlert
                closeModal={handleModalOpen}
                show={isModalOpen}
                title={<ModalTitle>{title}</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 30 }}
                width={1000}
            >
                <div className="py-4">
                    <div className="table-responsive">
                        <Table
                            rowClassName={(record,) =>
                                `${record.color ? `bg-${record?.color}` : ''} h-3px p-0 m-0 lh-0.5`
                            }
                            columns={columnsModal}
                            rowKey={(record, index) => index}
                            dataSource={selectedBalances}
                            pagination={false}
                            scroll={{ y: 500 }}
                            loading={chargement || loading}
                            rowSelection
                            size={"small"}
                            summary={(pageData) => {
                                let totalSoldeCredit = 0;
                                let totalSoldeDebit = 0;
                                let totalSoldeN = 0;
                                pageData.forEach(({ soldeDebit, soldeCredit}) => {
                                    totalSoldeDebit += soldeDebit;
                                    totalSoldeCredit += soldeCredit;
                                    totalSoldeN += soldeDebit - soldeCredit
                                });
                                return (
                                    <>
                                        <Table.Summary fixed>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>Total</Table.Summary.Cell>
                                                <Table.Summary.Cell index={3} className='text-end'>
                                                    <Text type="success">{""}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={4} className='text-end'>
                                                    <Text type="success" style={{fontSize: '12px'}}>{totalSoldeDebit.toLocaleString('fr')}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={5} className='text-end'>
                                                    <Text type="success" style={{fontSize: '12px'}} >{totalSoldeCredit.toLocaleString('fr')}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={6} className='text-end'>
                                                    <Text type="success" style={{fontSize: '12px'}}>{totalSoldeN}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </Table.Summary>
                                    </>
                                );
                            }}
                        />
                    </div>
                </div>
            </ModalAlert>
        </>
    );
};

export { BalanceProvisoireDefinitif };