export const dataVerificationSA = [
    {
        id: 1,
        ref: '1',
        groupe: "REGULARITE DES REUNIONS DU CONSEIL D’ADMINISTRATION",
        libelle: [
            "Par qui a été convoquée la réunion du CA: ",
            "(i) le PCA;",
            "(ii) le PDG ou l’Administrateur Général",
            "(iii) 1/3 au moins des administrateurs (dans le cas où le CA ne s'est pas réuni depuis 2 mois)"
        ],
        assertions: [],
        codeSommaire: "EG1.1"
    },
    {
        id: 2,
        ref: '2',
        groupe: "REGULARITE DES REUNIONS DU CONSEIL D’ADMINISTRATION",
        libelle: [
            "Le mandat de représentation à une réunion a été donné par : ",
            "(i) Lettre",
            "(ii) Télécopie",
            "(iii) Courriers électroniques"
        ],
        assertions: [],
        codeSommaire: "EG1.1"
    },
    {
        id: 3,
        ref: '3',
        groupe: "REGULARITE DES REUNIONS DU CONSEIL D’ADMINISTRATION",
        libelle: [
            "Pour le quorum, tous les Administrateurs étaient-ils présents physiquement aux réunions ? (Voir la conformité aux statuts) ",
            "Si non, 1/3 au moins était-il physiquement présent ? "
        ],
        assertions: [],
        codeSommaire: "EG1.1"
    },
    {
        id: 4,
        ref: '4',
        groupe: "REGULARITE DES REUNIONS DU CONSEIL D’ADMINISTRATION",
        libelle: [
            "Les PV des réunions antérieures du CA ont-ils été transmis aux administrateurs "
        ],
        assertions: [],
        codeSommaire: "EG1.1"
    },
    {
        id: 5,
        ref: '1',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES ",
        libelle: [
            "Qui a convoqué l’AGO ? ",
            "(i) le PDG ou l’Administrateur Général",
            "(ii) un mandataire de justice (dans ce cas, quels sont les délais fixés par le juge)",
            "(iii) le CAC (après une mise en demeure restée infructueuse); (iv) le liquidateur judiciaire."
        ],
        assertions: [],
        codeSommaire: "EG1.2"
    },
    {
        id: 6,
        ref: '2',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES ",
        libelle: [
            "Comment l’AGO a été convoquée ?  ",
            "(i) Par avis dans un journal d’annonce légal",
            "(ii) Par lettre recommandée avec Accusée de réception (quand toutes les actions sont nominatives)",
            "(iii) Par lettre au porteur; (iv) Par fax avec accord express de l’actionnaire qui aura préalablement communiqué son numéro de fax",
            "(v) Par courrier électronique avec l’accord express de l’actionnaire qui aura préalablement communiqué son adresse électronique"
        ],
        assertions: [],
        codeSommaire: "EG1.2"
    },
    {
        id: 7,
        ref: '3',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES ",
        libelle: [
            "Quand l’AGO a été convoquée ? ",
            "(i) 15 jours au moins avant la tenue pour la 1ère convocation",
            "(ii) 6 jours au moins pour les convocations suivantes",
            "(iii) Délais plus long prévus par les statuts",
        ],
        assertions: [],
        codeSommaire: "EG1.2"
    },
    {
        id: 8,
        ref: '1',
        groupe: "CONTROLE DU RESPECT DU DROIT DE COMMUNICATION",
        libelle: [
            "Par l’analyse des lettres de convocation ou de toute autre correspondance à l’attention des actionnaires, vérifier que dans un délai de 15 jours avant la date de tenue de l’assemblée générale ordinaire annuelle ; les actionnaires ont pu bénéficier du droit de communication des documents suivants:  ",
            "L’inventaire, les états financiers de synthèse et la liste des administrateurs lorsqu'un conseil d'administration a été constitué ; ",
            "Les rapports du commissaire aux comptes et du conseil d'administration ou de l'administrateur général qui sont soumis à l'assemblée",
            "le cas échéant, le texte de l'exposé des motifs, des résolutions proposées, ainsi que des renseignements concernant les candidats au conseil d'administration ou au poste d'administrateur général",
            "la liste des actionnaires du montant global certifié par les commissaires aux comptes des rémunérations versées aux dix (10) ou cinq (5) dirigeants sociaux et salariés les mieux rémunérés selon que l'effectif de la société excède ou non deux cents salariés ",
            "S’assurer que les actionnaires qui le souhaitent ont ou prendre copie des documents ci-dessus visés en dehors de l’inventaire "
        ],
        assertions: [],
        codeSommaire: "EG1.3"
    },
    {
        id: 9,
        ref: '2',
        groupe: "CONTROLE DU RESPECT DU DROIT DE COMMUNICATION",
        libelle: [
            "Pour les Assemblées autres que l’AGO ",
            "S’assurer que les documents suivants ont été mis à la disposition des actionnaires : ",
            "Le rapport du conseil d'administration ou de l'administrateur général selon le cas",
            "Le cas échéant, le rapport du commissaire aux comptes ou du liquidateur",
        ],
        assertions: [],
        codeSommaire: "EG1.3"
    },
    {
        id: 10,
        ref: '3',
        groupe: "CONTROLE DES CONVENTIONS REGLEMENTEES",
        libelle: [
            "Le CAC a-t-il été informé de la conclusion d’une convention ou relation répondant aux caractéristiques ci-après :  ",
            "Toute convention entre une société anonyme et l'un de ses administrateurs, directeurs généraux ou directeurs généraux adjoints ",
            "Convention entre une société et un actionnaire détenant une participation supérieure ou égale à dix pour cent (10 %) du capital de la société",
            "Toute convention à laquelle un administrateur, un directeur général, un directeur adjoint, ou un actionnaire détenant une participation supérieure ou égale à dix pour cent (10 %) du capital de la société est indirectement intéressé ou dans laquelle il traite avec la société par personne interposée",
            "Toute convention entre une société et une entreprise ou une personne morale, si l'un des administrateurs, le directeur général, le directeur général adjoint ou un actionnaire détenant une participation supérieure ou égale à dix pour cent (10 %) du capital de la société est propriétaire de l'entreprise ou associé indéfiniment responsable, gérant, administrateur, administrateur général adjoint, directeur général, directeur général adjoint ou autre dirigeant social de la personne morale contractante",
        ],
        assertions: [],
        codeSommaire: "EG1.4"
    },
    {
        id: 11,
        ref: '4',
        groupe: "CONTROLE DES CONVENTIONS REGLEMENTEES",
        libelle: [
            "Lors de ses travaux, le CAC a-t-il découvert une convention ou une relation professionnelle répondant aux caractéristiques ci-dessus ? ",
        ],
        assertions: [],
        codeSommaire: "EG1.4"
    },
    {
        id: 12,
        ref: '5',
        groupe: "CONTROLE DES CONVENTIONS REGLEMENTEES",
        libelle: [
            "Dans une SA avec Conseil d’Administration, le CA a-t-il donné son autorisation préalable avant la conclusion de la convention ? ",
        ],
        assertions: [],
        codeSommaire: "EG1.4"
    },
    {
        id: 13,
        ref: '6',
        groupe: "CONTROLE DES CONVENTIONS REGLEMENTEES",
        libelle: [
            "En cas de SA Unipersonnelle, inapplicabilité de la procédure à l'actionnaire unique. Administrateur général (art 505)  ",
        ],
        assertions: [],
        codeSommaire: "EG1.4"
    },
    {
        id: 14,
        ref: '1',
        groupe: "CONTROLE DES CONVENTIONS INTERDITES",
        libelle: [
            "Existe-t-il des conventions accordant des :  ",
            "Prêts, avances, découverts en compte courant ou autres mises à disposition de fonds ?",
        ],
        assertions: [],
        codeSommaire: "EG1.5"
    },
    {
        id: 15,
        ref: '2',
        groupe: "CONTROLE DES CONVENTIONS INTERDITES",
        libelle: [
            "Personnes physiques administrateur à titre personnel ",
            "Représentant permanent d’une personne morale administrateur ",
            "DG ;",
            "DGA ; ",
            "Et leurs conjoints, ascendant et descendants et à toutes personnes interposées ; ",
            "Autres à préciser. ",
        ],
        assertions: [],
        codeSommaire: "EG1.5"
    },
    {
        id: 16,
        ref: '1',
        groupe: "APPRECIATION DU RAPPORT DE GESTION",
        libelle: [
            "Dans la forme : ",
            "Rapport établi et signé par le CA ou l’Administrateur général ?",
            "Information sur la situation de la société durant l’exercice écoulé (CA, évolution des charges, ratios etc…) ",
            "Information sur les perspectives de recherches et développement.",
            "Information sur les perspectives de continuation de l’activité (évolution prévisible, budget prévisionnel, développement de marché etc…) ",
        ],
        assertions: [],
        codeSommaire: "EG1.6"
    },
    {
        id: 17,
        ref: '2',
        groupe: "APPRECIATION DU RAPPORT DE GESTION",
        libelle: [
            "Dans le fonds, s’assurer que toutes informations chiffrées concordent avec celles figurant dans les états financiers  ",
        ],
        assertions: [],
        codeSommaire: "EG1.6"
    },
    {
        id: 18,
        ref: '1',
        groupe: "CONTROLE DU REGISTRE DES TITRES NOMINATIFS (Art 746-1 & 716-2)",
        libelle: [
            "Demander et consulter le registre des titres nominatifs  ",
        ],
        assertions: [],
        codeSommaire: "EG1.7"
    },
    {
        id: 19,
        ref: '2',
        groupe: "CONTROLE DU REGISTRE DES TITRES NOMINATIFS (Art 746-1 & 716-2)",
        libelle: [
            "S’assurer qu’il est correctement tenu, notamment vérifier :",
            "La date de l'opération ",
            "Les noms, prénoms et domicile de l'ancien et du nouveau titulaire des titres, en cas de transfert ; ",
            "Les noms, prénoms et domicile du titulaire des titres en cas de conversion de titres au porteur en titres nominatifs ;  ",
            "La valeur nominative et le nombre de titre transférés ou convertis. ",
            "Un numéro d'ordre ",
        ],
        assertions: [],
        codeSommaire: "EG1.7"
    },
    {
        id: 20,
        ref: '1',
        groupe: "CONTROLE DES RELATIONS EXTRA-STATUTAIRES (Art 2-1)",
        libelle: [
            "Existe-t-il des conventions extra-statutaires (pactes d'actionnaires) ? ",
        ],
        assertions: [],
        codeSommaire: "EG1.8"
    },
    {
        id: 21,
        ref: '2',
        groupe: "CONTROLE DES RELATIONS EXTRA-STATUTAIRES (Art 2-1)",
        libelle: [
            "Si oui, vérifier que ces dispositions régissent exclusivement : ",
            "La composition des organes sociaux ; ",
            "La conduite des affaires de la société ;",
            "L'accès au capital social ; ",
            "La transmission des titres sociaux ",
        ],
        assertions: [],
        codeSommaire: "EG1.8"
    },
    {
        id: 22,
        ref: '1',
        groupe: " PROCEDURE D’ALERTE (Art 153) ",
        libelle: [
            "Déclenchement et déroulement de la procédure d’alerte (Article 153) ",
            "Adresser un courrier de demande d’explication au PDG ou PCA ou AG",
        ],
        assertions: [],
        codeSommaire: "EG1.9"
    },
    {
        id: 23,
        ref: '2',
        groupe: " PROCEDURE D’ALERTE (Art 153) ",
        libelle: [
            "A-t-on reçu la réponse du PDG, PCA, ou AG dans le mois suivant la demande d’explication ? ",
        ],
        assertions: [],
        codeSommaire: "EG1.9"
    },
    {
        id: 24,
        ref: '3',
        groupe: "PROCEDURE D’ALERTE (Art 153) ",
        libelle: [
            "La réponse du PDG, PCA, ou AG présente-t-elle des mesures suffisantes pour atténuer les faits de nature à compromettre la continuité d’exploitation ?  ",
        ],
        assertions: [],
        codeSommaire: "EG1.9"
    },
    {
        id: 25,
        ref: '4',
        groupe: "PROCEDURE D’ALERTE (Art 153) ",
        libelle: [
            "Si non, a-t-on demandé au PDG, PCA ou AG de réunir et faire délibérer le CA ? ",
        ],
        assertions: [],
        codeSommaire: "EG1.9"
    },
    {
        id: 26,
        ref: '5',
        groupe: "PROCEDURE D’ALERTE (Art 153) ",
        libelle: [
            "Le Conseil d’administration a-t-il été convoqué ? s’est-il réuni conformément à la loi ? a-t-il délibéré ? ",
        ],
        assertions: [],
        codeSommaire: "EG1.9"
    },
    {
        id: 27,
        ref: '1',
        groupe: "CONTRÔLE DES CESSIONS DE BIENS DES ACTIONNAIRES A LA SOCIÉTÉ (Art 547)",
        libelle: [
            "Des actionnaires ont-ils cédé des biens de plus de cinq millions FCFA à l’entité dans les deux ans de l’immatriculation de l’entité au RCCM ? ",
        ],
        assertions: [],
        codeSommaire: "EG1.10"
    },
    {
        id: 28,
        ref: '2',
        groupe: "CONTRÔLE DES CESSIONS DE BIENS DES ACTIONNAIRES A LA SOCIÉTÉ (Art 547)",
        libelle: [
            "Le CAC a-t-il été informé par le PDG, le PCA, l’Administrateur Général (AG) ",
        ],
        assertions: [],
        codeSommaire: "EG1.10"
    },
    {
        id: 29,
        ref: '3',
        groupe: "CONTRÔLE DES CESSIONS DE BIENS DES ACTIONNAIRES A LA SOCIÉTÉ (Art 547)",
        libelle: [
            "Si oui, préparez un rapport spécial sur la valeur du bien, à déposer au siège social au moins 15 jours avant la tenue de la plus prochaine assemblée générale  ",
        ],
        assertions: [],
        codeSommaire: "EG1.10"
    },
    {
        id: 30,
        ref: '4',
        groupe: "CONTRÔLE DES CESSIONS DE BIENS DES ACTIONNAIRES A LA SOCIÉTÉ (Art 547)",
        libelle: [
            "S’assurer que le rapport respecte la forme ci-dessous : ",
            "La description du bien",
            "Les critères retenus pour la fixation du prix ",
            "L’appréciation par le CAC, de la pertinence des critères ",
        ],
        assertions: [],
        codeSommaire: "EG1.10"
    },
    {
        id: 31,
        ref: '5',
        groupe: "CONTRÔLE DES CESSIONS DE BIENS DES ACTIONNAIRES A LA SOCIÉTÉ (Art 547)",
        libelle: [
            "Vérifier que les actionnaires concernés n’ont pas pris part au vote ni en leur propre ni comme mandataire de la résolution relative à la vente et que ces actions n’ont pas été prises en compte pour le calcul du quorum et de la majorité ",
        ],
        assertions: [],
        codeSommaire: "EG1.10"
    },

]

export const dataVerificationSARL = [
    {
        id: 1,
        ref: '1',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "Qui a convoqué l’AGO ? ",
            "(i) le gérant ; ",
            "(ii) un ou plusieurs associés détenant la moitié des parts sociales ou ; ",
            "(iii) Un ou plusieurs associés représentant au moins le quart des associés et détenant le quart des parts sociales ; ",
            "(iv) Un mandataire ad hoc désigné par la juridiction compétente sur saisine des associés ou du Ministère public ; ",
            "(v) Le CAC (après une mise en demeure restée infructueuse). "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 2,
        ref: '2',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "Comment l’AGO a été convoquée ?; ",
            "(i) Par lettre au porteur contre récépissé ; ",
            "(ii) Par lettre recommandée avec accusé de réception ; ",
            "(iii) Par fax avec l’accord express de l’actionnaire qui aura préalablement communiqué son numéro de fax (télécopie) ; ",
            "(iv) Par courrier électronique avec l’accord express de l’actionnaire qui aura préalablement communiqué son adresse électronique "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 3,
        ref: '3',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "Quand l’AGO a été convoquée ?",
            "(i) 15 jours au moins avant la tenue pour la 1ère convocation  "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 4,
        ref: '4',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "Certains associés se sont-ils faits représentés lors de cette AGO",
            "Si oui ; combien d’associés existe-t-il au sein de la société ? "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 5,
        ref: '5',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "S’il n’y a que deux associés, s’assurer que le mandataire n’est pas le conjoint de l’associé représenté "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 6,
        ref: '6',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "S’il n’y a que deux associés, s’assurer que l’associé n’est pas représenté par l’autre associé "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 7,
        ref: '7',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "S’il n’y a que deux associés, s’assurer que les statuts ont autorisé le mandat de représentation "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 8,
        ref: '8',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "Pour combien d’assemblées a été donnée la procuration ? (S’assurer que le mandat ne vaut que pour la seule assemblée ou pour plusieurs assemblées successives convoquées avec le même ordre du jour) "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 9,
        ref: '9',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "Vérifier que chaque associé ou son mandataire a disposé du nombre de parts qu’il possède dans le capital "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 10,
        ref: '10',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "Vérifier que l’assemblée n’a délibéré que sur les questions inscrites à l’ordre du jour ( en dehors des questions relatives à la révocation du gérant et à son remplacement, l’assemblée ne peut délibérer sur une question non inscrite à l’ordre du jour) "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 11,
        ref: '11',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "Vote à distance - calcul du quorum et de la majorité : ",
            "Certains actionnaires ont-ils voté par correspondance ? ",
            "Si oui, vérifier que : ",
            "Certains associés ont-ils voté par correspondance ? ",
            "Si oui, vérifier que : (i) Ce mode de vote a été prévu par les statuts ; (ii) L'associé concerné a informé le dirigeant social de son absence au moins 3 jours avant la tenue de la réunion ; (iii) Son vote a été réceptionné par la société au moins 24h avant la tenue de l'assemblée  "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 12,
        ref: '12',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "Réunion par Visioconférence : ",
            "Certains associés ont-ils participé à la réunion par visioconférence ou par un moyen similaire ?",
            "Si oui, vérifier que : Ce mode de vote a été prévu par les statuts. Dans la mesure du possible, dire si les moyens utilisés transmettent au moins la voix des participants et satisfont à des caractéristiques techniques permettant la retransmission continue et simultanée des délibérations.  "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 13,
        ref: '13',
        groupe: "REGULARITE DES CONVOCATIONS ET TENUES DES ASSEMBLEES GENERALES",
        libelle: [
            "Dans tous les cas de vote par distance, vérifier qu'il en a été fait mention dans le PV de l'AG et si des éventuels incidents techniques survenus y ont été mentionnés "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 14,
        ref: '1',
        groupe: "CONTROLE DU RESPECT DU DROIT DE COMMUNICATION (Art 345)",
        libelle: [
            "Pour l’AGO Annuelle ",
            "Par l’analyse des lettres de convocation ou de toute autre correspondance à l’attention des actionnaires, vérifier que dans un délai de 15 jours avant la date de tenue de l’assemblée générale ordinaire annuelle ; les associés ont pu bénéficier du droit de communication des documents suivants : ",
            "les états financiers de synthèse",
            "le rapport de gestion ",
            "le texte des résolutions proposées ",
            "le cas échéant, les rapports du commissaire aux comptes",
            "S’assurer que les actionnaires qui le souhaitent ont ou prendre copie des documents ci-dessus visés en dehors de l’inventaire "
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 15,
        ref: '2',
        groupe: "CONTROLE DU RESPECT DU DROIT DE COMMUNICATION (Art 345)",
        libelle: [
            "Pour les Assemblées autres que l’AGO ",
            "S’assurer que les documents suivants ont été mis à la disposition des actionnaires : ",
            "le texte des résolutions proposées ; ",
            "le rapport du gérant ",
            "le cas échéant, le rapport du commissaire aux comptes"
        ],
        assertions: [],
        codeSommaire: "EG2.1"
    },
    {
        id: 15,
        ref: '1',
        groupe: "CONTRÔLE DES CONVENTIONS RÉGLEMENTÉES (Art 350)",
        libelle: [
            "Le CAC a-t-il été informé de la conclusion d’une convention ou relation répondant aux caractéristiques ci-après : ",
            "Convention intervenue directement ou par personne interposée entre la société et l'un de ses gérants ou associés ; ",
            "Convention intervenue avec une entreprise individuelle dont le propriétaire est simultanément gérant ou associé de la société à responsabilité limitée ; ",
            "Toute convention intervenue entre une société dont un associé indéfiniment responsable, gérant, administrateur, directeur général, administrateur général ou autre dirigeant social est simultanément gérant ou associé de la société à responsabilité limitée. "
        ],
        assertions: [],
        codeSommaire: "EG2.3"
    },
    {
        id: 16,
        ref: '2',
        groupe: "CONTRÔLE DES CONVENTIONS RÉGLEMENTÉES (Art 350)",
        libelle: [
            "En cas de SARL Unipersonnelle, inapplicabilité de la procédure à l'associé unique  "
        ],
        assertions: [],
        codeSommaire: "EG2.3"
    },
    {
        id: 17,
        ref: '1',
        groupe: "CONTROLE DES CONVENTIONS INTERDITES (Art 356)",
        libelle: [
            "Existe-t-il des conventions accordant des: ",
            "Prêts, avances, découverts en compte courant, caution ou avals d’engagement envers les tiers, ou autres mises à disposition de fonds ? "
        ],
        assertions: [],
        codeSommaire: "EG2.4"
    },
    {
        id: 18,
        ref: '2',
        groupe: "CONTROLE DES CONVENTIONS INTERDITES (Art 356)",
        libelle: [
            "Si oui indiquer la qualité des contractants : ",
            "Personnes physiques gérante ",
            "Personnes physiques associée de la société ",
            "Et leurs conjoints, ascendants et descendants ",
            "Autres à préciser "
        ],
        assertions: [],
        codeSommaire: "EG2.4"
    },
    {
        id: 19,
        ref: '1',
        groupe: "APPRECIATION DU RAPPORT DE GESTION (Art 288 & 138)",
        libelle: [
            "Dans la forme : ",
            "rapport établi et signé par le Gérant ? ",
            "Information sur la situation de la société durant l’exercice écoulé (CA, évolution des charges, ratios etc…) ",
            "Information sur les perspectives de recherches et développement. ",
            "Information sur les perspectives de continuation de l’activité (évolution prévisible, budget prévisionnel, développement de marché etc…) "
        ],
        assertions: [],
        codeSommaire: "EG2.5"
    },
    {
        id: 20,
        ref: '2',
        groupe: "APPRECIATION DU RAPPORT DE GESTION (Art 288 & 138)",
        libelle: [
            "Dans le fonds, s’assurer que toutes informations chiffrées concordent avec celles figurant dans les états financiers"
        ],
        assertions: [],
        codeSommaire: "EG2.5"
    },
    {
        id: 21,
        ref: '1',
        groupe: "CONTROLE DES RELATIONS EXTRA-STATUTAIRES (Art 2-1) ",
        libelle: [
            "Existe-t-il des conventions extra-statutaires (pactes d'associés) ? "
        ],
        assertions: [],
        codeSommaire: "EG2.6"
    },
    {
        id: 21,
        ref: '1',
        groupe: "CONTROLE DES RELATIONS EXTRA-STATUTAIRES (Art 2-1) ",
        libelle: [
            "Si oui, vérifier que ces dispositions régissent exclusivement : ",
            "Les relations entre associés ; ",
            "La composition des organes sociaux ; ",
            "La conduite des affaires de la société ; ",
            "L'accès au capital social ; ",
            "La transmission des titres sociaux "
        ],
        assertions: [],
        codeSommaire: "EG2.6"
    },


]