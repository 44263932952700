import axios from 'axios'
import { AJOUTER_STATUT, LISTE_STATUT, MODIFIER_ETAT_STATUT, MODIFIER_STATUT, SUPPRIMER_STATUT } from '../../api/apiRoutes'

export const addStatut = async (data) => {
    return axios.post(AJOUTER_STATUT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateStatut = async (data) => {
    return axios.post(MODIFIER_STATUT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeStatut = async () => {
    return axios.get(LISTE_STATUT).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteStatut = async (data) => {
    return axios.post(SUPPRIMER_STATUT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatStatut = async (data) => {
    return axios.post(MODIFIER_ETAT_STATUT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}