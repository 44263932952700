import React, {useEffect, useState} from "react";
import {Button, Input, Tooltip} from "antd";
import {getAideMemoire} from "../../../../../../data/exercice";
import PageChildLabel from "../../../../../common/PageLabel/PageChildLabel";
import FormLabel from "../../../../../common/Form/FormLabel";
import {Spinner} from "@chakra-ui/react";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const EvaluationFonctionControle = ({mission, code, chargement, hanldeValider, missionSelected}) => {
    const [loading, setLoading] = useState(false);
    const [conclusion, setConclusion] = useState("");
    const [aideMemoire, setAideMemoire] = useState(null);

    useEffect(()=> {
        setLoading(true);
        async function fetchData() {
            const response = await getAideMemoire(mission.id, code);
            if(response && response.length > 0) {
                setAideMemoire(response[0]);
                setConclusion(response[0]?.conclusion || "");
                setLoading(false);
            }else{
                setAideMemoire(null);
                setConclusion("");
                setLoading(false);
            }
        }
        fetchData();
    }, [mission, code])

    return (

      <div
          className="mx-10 d-flex flex-column"
      >
          <PageChildLabel label={ "EE2 - Evaluation du fonctionnements des contrôles"} />

          {(loading || chargement) ?
              <div className="d-flex justify-content-center align-items-center">
                  <Spinner />
              </div>
              :
              <div className="d-flex flex-column align-center mt-5 mb-15">
                  {<div className="d-flex justify-content-end align-items-end mb-10">
                      <Tooltip title="Enregistrer">
                          <Button
                              disabled={(missionSelected.isCloture === 1 || missionSelected.isClotureProvisoire === 1)}
                              size={"large"}
                              // loading={loading}
                              style={{
                                  backgroundColor: appTheme.colors.secondary,
                                  color: "white"
                              }}
                              icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                              onClick={() => hanldeValider({code: code, conclusion: conclusion})}
                          >
                              {!loading &&
                                  <span className='indicator-label text-white'>Enregistrer</span>}
                              {loading && (
                                  <span className='indicator-progress fs-9 text-white'
                                        style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                          </Button>
                      </Tooltip>
                  </div>}
                  <FormLabel label={`Conclusion : `} />
                  <div className="my-3"></div>
                  <Input.TextArea
                      size="large"
                      placeholder="Conclusion"
                      value={conclusion}
                      maxLength={5000}
                      showCount
                      autoSize={{
                          minRows: 8,
                          maxRows: 10,
                      }}
                      onChange={(e) => setConclusion(e.target.value)}
                  />
              </div>
          }
      </div>
  )
}

export {EvaluationFonctionControle}