import React, {useEffect, useState} from "react";
import {AcceptationMission} from "../../../components/client/modals/missionSteps/EB/AcceptationMission";
import {getExerciceDetails} from "../../../data/exercice";
import ImportationBalance from "../../../components/client/modals/missionSteps/EC/ImportationBalances";
import {CalculEtatFinanciers} from "../../../components/client/modals/missionSteps/EC/CalculEtatFinanciers";
import {ExamenAnalytique} from "../../../components/client/modals/missionSteps/EC/ExamenAnalytique";
import {ControleFeuilleMairesse} from "../../../components/client/modals/missionSteps/EC/ControleFeuilleMaitresse";
import {ControleIntangibilite} from "../../../components/client/modals/missionSteps/EC/ControleIntangibilite";
import {ControleTestJournaux} from "../../../components/client/modals/missionSteps/EC/ControleTestJournaux";
import {ControleBalanceProvisoire} from "../../../components/client/modals/missionSteps/EC/ControleBalanceProvisoire";
import {StrategieAuditGlobale} from "../../../components/client/modals/missionSteps/EC/Strategieglobale";
import {RisqueIdentification} from "../../../components/client/modals/missionSteps/ED/RisqueIdentification";
import {RisqueEvaluation} from "../../../components/client/modals/missionSteps/ED/RisqueEvaluation";
import {RisqueBlanchiment} from "../../../components/client/modals/missionSteps/ED/RisqueBlanchiment";
import {
    SyntheseConceptionControles
} from "../../../components/client/modals/missionSteps/EE1/SyntheseConceptionControles";
import {RisqueConceptionControles} from "../../../components/client/modals/missionSteps/EE1/RisqueConceptionControles";
import {FonctionnementControles} from "../../../components/client/modals/missionSteps/EE1/FonctionnementControles";
import {ControleProceduresAudit} from "../../../components/client/modals/missionSteps/EE2/ControleProceduresAudit";
import {ControleNoteAnnexe} from "../../../components/client/modals/missionSteps/EE2/ControleNoteAnnexe";
import {ControlePartiesLiee} from "../../../components/client/modals/missionSteps/EE2/ControlePartiesLiee";
import {
    ControleMissionAuditInitiale
} from "../../../components/client/modals/missionSteps/EE2/ControleMissionAuditInitiale";
import {
    ControleTravauxExpertComptable
} from "../../../components/client/modals/missionSteps/EF/ControleTravauxExpertComptable";
import {VerificationSA} from "../../../components/client/modals/missionSteps/EG/VerificationSA";
import {ControleFinMission} from "../../../components/client/modals/missionSteps/EH/ControleFInMission";
import {SyntheseAjustement} from "../../../components/client/modals/missionSteps/EI/SyntheseAjustement";
import {AideMemoire} from "../../../components/client/modals/missionSteps/EI/Aidememoire";
import {MaintienMission} from "../../../components/client/modals/missionSteps/EB/MaintienMission";
import {SyntheseIdentification} from "../../../components/client/modals/missionSteps/ED/SyntheseIdentification";

const MissionSommaireTypePage = ({
         code,
         mission,
         client,
         modalId,
         prev
    }) => {
    const [missionSelected, setMissionSelected] = useState(null);

    useEffect(() => {
        if(mission){
            getExerciceDetails(mission.id)
                .then((res) => {
                    setMissionSelected(res);
                });
        }
    }, [mission])

    const pageSelected = () => {
        switch (code) {
            case "EB1":
                return (
                    <AcceptationMission
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EB2":
                return (
                    <MaintienMission
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EC2.1":
                return (
                    <ImportationBalance
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EC2.2":
                return (
                    <CalculEtatFinanciers
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EC3.1":
                return (
                    <ExamenAnalytique
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EC3.2":
                return (
                    <ControleFeuilleMairesse
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EC3.3":
                return (
                    <ControleIntangibilite
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );

            case "EC3.4":
                return (
                    <ControleTestJournaux
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EC3.5":
                return (
                    <ControleBalanceProvisoire
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EC4":
                return (
                    <StrategieAuditGlobale
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "ED0":
                return (
                    <SyntheseIdentification
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "ED1.1":
            case "ED2.1":
            case "ED3.1":
                return (
                    <RisqueIdentification
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "ED1.2":
            case "ED2.2":
            case "ED3.2":
                return (
                    <RisqueEvaluation
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "ED4":
            case "ED5":
                return (
                    <RisqueBlanchiment
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EE0":
                return (
                    <SyntheseConceptionControles
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EE1":
                return (
                    <RisqueConceptionControles
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EE2":
                return (
                    <FonctionnementControles
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EE3.1":
            case "EE3.2":
            case "EE3.3":
            case "EE3.4":
            case "EE3.5":
            case "EE3.6":
            case "EE3.7":
            case "EE3.8":
            case "EE3.9":
            case "EE3.10":
            case "EE3.11":
            case "EE3.12":
            case "EE3.13":
            case "EE3.14":
                return (
                    <ControleProceduresAudit
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EE3.15":
                return (
                    <ControleNoteAnnexe
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EE3.16":
                return (
                    <ControlePartiesLiee
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EE3.17":
                return (
                    <ControleMissionAuditInitiale
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EF1":
            case "EF2":
            case "EF3":
            case "EF4":
                return (
                    <ControleTravauxExpertComptable
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EG1.1":
            case "EG1.2":
            case "EG1.3":
            case "EG1.4":
            case "EG1.5":
            case "EG1.6":
            case "EG1.7":
            case "EG1.8":
            case "EG1.9":
            case "EG1.10":
            case "EG2.1":
            case "EG2.2":
            case "EG2.3":
            case "EG2.4":
            case "EG2.5":
            case "EG2.6":
            case "EG2.7":
                return (
                    <VerificationSA
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EH1":
            case "EH2":
            case "EH3":
                return (
                    <ControleFinMission
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EI1":
                return (
                    <SyntheseAjustement
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );
            case "EI2":
                return (
                    <AideMemoire
                        mission={missionSelected || mission}
                        client={client}
                        modalId={modalId}
                        code={code}
                        prev={prev}
                    />
                );

            default:
                return window.location.reload();
        }
    };

    return (
        <>
            {pageSelected()}
        </>
    );
};

export default MissionSommaireTypePage;