import React from "react";

const PrintProcedureAudit = ({objectifs, bilan, compteResultat, travauxAudit, travauxAjustement, travauxAjustementTotal, conclusion, observation}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thStyle = {
        border: '1px solid #000000',
        fontWeight: "bold",
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <>
            {objectifs && objectifs.length > 0 &&
                <div style={{marginBottom: '2%'}}>
                    <table style={tableStyle} className="tablePerso">
                        <thead>
                        <tr style={thStyle} >
                            <th
                                className="text-start"
                                style={{fontSize: "14px",
                                    textDecoration: 'underline',
                                    fontWeight: "bold"}}
                            >
                                Objectif
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={tdStyle}>
                            <td style={tdStyle}>
                                <div className='d-flex flex-column'>
                                    {objectifs.map((item) => (
                                        <div className='d-flex align-center mb-2' key={item.id}>
                                            <span style={{fontSize: "10", marginRight: "5%", fontWeight: "bold"}} >{item.code}:</span>
                                            <span style={{fontSize: "10"}}>{item.libelle}</span>
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }
            {bilan && bilan.length > 0 &&
                <div style={{marginBottom: '2%'}}>
                    <table style={tableStyle} className="tablePerso">
                        <thead>
                        <tr style={thStyle} >
                            <th
                                className="text-start"
                                style={{fontSize: "14px",
                                    textDecoration: 'underline',
                                    fontWeight: "bold"}}
                            >
                                BILAN
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={tdStyle}>
                            <td style={tdStyle}>
                                <div className='d-flex flex-column'>
                                    {bilan.map((item) => (
                                        <div className='d-flex align-center mb-2' key={item.id}>
                                            <span style={{fontSize: "10", marginRight: "5%", fontWeight: "bold"}} >{item.code}:</span>
                                            <span style={{fontSize: "10"}}>{item.libelle}</span>
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }
            {compteResultat && compteResultat.length > 0 &&
                <div style={{marginBottom: '5%'}}>
                    <table style={tableStyle} className="tablePerso">
                        <thead>
                        <tr style={thStyle} >
                            <th
                                className="text-start"
                                style={{fontSize: "14px",
                                    textDecoration: 'underline',
                                    fontWeight: "bold"}}
                            >
                                COMPTE DE RESULTAT
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={tdStyle}>
                            <td style={tdStyle}>
                                <div className='d-flex flex-column'>
                                    {compteResultat.map((item) => (
                                        <div className='d-flex align-center mb-2' key={item.id}>
                                            <span style={{fontSize: "10", marginRight: "5%", fontWeight: "bold"}} >{item.code}:</span>
                                            <span style={{fontSize: "10"}}>{item.libelle}</span>
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }
            <div style={{marginBottom: "5%"}} className="table-responsive mb-5">
                <h3 style={{fontWeight: "bold", fontColor: "black", marginBottom: "3%", textAlign: "center"}}>Travaux d'audit</h3>
                <div style={{marginBottom: "2%"}} className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className="tablePerso w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={thStyle} className="thperso w-150px">Référence</th>
                                <th style={thStyle} className="thperso w-500px">Nature des travaux à faire</th>
                                <th style={thStyle} className="thperso w-500px">Commentaire</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(travauxAudit && travauxAudit.length > 0) && travauxAudit.map((item, index) => {
                                return (
                                    <tr style={tdStyle} className="" key={`${index}`}>
                                        <td className="" style={{...tdStyle, fontSize: '10px'}}>
                                            {item.ref}
                                        </td>
                                        <td className=" text-start" style={{...tdStyle, fontSize: '11px' }}>
                                            <p style={{fontSize: '11px'}}>
                                                {item.libelle}
                                            </p>
                                        </td>
                                        <td className=" text-center" style={tdStyle}>
                                            <p style={{fontSize: '11px'}}>
                                                {item.commentaire}
                                            </p>
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div style={{marginBottom: "5%"}} className="table-responsive">
                <h3 style={{fontWeight: "bold", fontColor: "black", marginBottom: "3%", textAlign: "center"}}>Ajustements</h3>
                {/*<div style={{marginBottom: "5%"}} className='mx-auto'>*/}
                {/*    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>*/}
                {/*        <table className="tablePerso w-100" style={tableStyle}>*/}
                {/*            <thead>*/}
                {/*            <tr style={thStyle}>*/}
                {/*                <th style={thStyle} className="thperso w-500px">Description</th>*/}
                {/*                <th style={thStyle} className="thperso w-150px">Provisoire</th>*/}
                {/*                <th style={thStyle} className="thperso w-150px">Définitive</th>*/}
                {/*            </tr>*/}
                {/*            </thead>*/}
                {/*            <tbody>*/}
                {/*            {(travauxAudit && travauxAudit.length > 0) && travauxAudit.map((item, index) => {*/}
                {/*                return (*/}
                {/*                    <tr style={tdStyle} className="" key={`${index}`}>*/}
                {/*                        <p style={{fontSize: '11px'}}>*/}
                {/*                            {item.libelle}*/}
                {/*                        </p>*/}
                {/*                        <td className=" text-start" style={{...tdStyle, fontSize: '11px' }}>*/}
                {/*                            <div className='text-end pe-0' style={{fontSize: '12px'}}>*/}
                {/*                                {item.montantProv.toLocaleString('fr')}*/}
                {/*                            </div>*/}
                {/*                        </td>*/}
                {/*                        <td className=" text-center" style={tdStyle}>*/}
                {/*                            <div className='text-end pe-0' style={{fontSize: '12px'}}>*/}
                {/*                                {item.montantDef.toLocaleString('fr')}*/}
                {/*                            </div>*/}
                {/*                        </td>*/}
                {/*                    </tr>*/}
                {/*                );*/}
                {/*            })}*/}
                {/*            </tbody>*/}
                {/*        </table>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div style={{marginBottom: "2%"}} className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className="tablePerso w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={thStyle} rowSpan={2} className="thperso w-300px">Description</th>
                                <th style={thStyle} rowSpan={2} className="thperso w-150px">Réf F/T</th>
                                <th style={thStyle} rowSpan={2} className="thperso w-150px">Ajustement (+) ou (-) Résultat Produits et Charges</th>
                                <th style={thStyle} rowSpan={1} colSpan={3} className="thperso w-300px">Bilan</th>
                                <th style={thStyle} rowSpan={2} className="thperso w-100px">Anomalie corrigé OUI / NON</th>
                            </tr>
                            <tr style={thStyle}>
                                <th style={thStyle} className="thperso w-100px">Actifs</th>
                                <th style={thStyle} className="thperso w-100px">Passifs</th>
                                <th style={thStyle} className="thperso w-100px">Capitaux Propres</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(travauxAjustement && travauxAjustement.length > 0) && travauxAjustement.map((item, index) => {
                                return (
                                    <tr style={tdStyle} className="text-start" key={`${index}`}>
                                        <td className="" style={{...tdStyle, fontSize: '11px'}}>
                                            <p style={{fontSize: '11px', textAlign: "start"}}>
                                                {item.description}
                                            </p>
                                        </td>
                                        <td className=" text-center" style={{...tdStyle, fontSize: '11px' }}>
                                            {item.ref}
                                        </td>
                                        <td className="text-end pe-0" style={{...tdStyle, fontSize: '11px'}}>
                                            {item.resultatProduit && item.resultatProduit.toLocaleString('fr')}
                                        </td>
                                        <td className="text-end pe-0" style={{...tdStyle, fontSize: '11px'}}>
                                            {item.actif && item.actif.toLocaleString('fr')}
                                        </td>
                                        <td className="text-end pe-0" style={{...tdStyle, fontSize: '11px'}}>
                                            {item.passif && item.passif.toLocaleString('fr')}
                                        </td>
                                        <td className="text-end pe-0" style={{...tdStyle, fontSize: '11px'}}>
                                            {item.capitaux && item.capitaux.toLocaleString('fr')}
                                        </td>
                                        <td className=" text-center" style={{...tdStyle, fontSize: '11px'}}>
                                            {item.action}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className="tablePerso w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={thStyle} className="thperso w-300px">Description</th>
                                <th style={thStyle} className="thperso w-150px">Ajustement (+) ou (-) Résultat Produits et Charges</th>
                                <th style={thStyle} className="thperso w-150px">Actifs</th>
                                <th style={thStyle} className="thperso w-150px">Passifs</th>
                                <th style={thStyle} className="thperso w-150px">Capitaux Propres</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(travauxAjustementTotal && travauxAjustementTotal.length > 0) && travauxAjustementTotal.map((item, index) => {
                                return (
                                    <tr style={tdStyle} className="" key={`${index}`}>
                                        <td className="" style={{...tdStyle, fontSize: '11px'}}>
                                            <p style={{fontSize: '11px', textAlign: "start"}}>
                                                {item.libelle}
                                            </p>
                                        </td>
                                        <td className="text-end pe-0" style={{...tdStyle, fontSize: '11px'}}>
                                            {item.resultatProduit && item.resultatProduit.toLocaleString('fr')}
                                        </td>
                                        <td className="text-end pe-0" style={{...tdStyle, fontSize: '11px'}}>
                                            {item.actif && item.actif.toLocaleString('fr')}
                                        </td>
                                        <td className="text-end pe-0" style={{...tdStyle, fontSize: '11px'}}>
                                            {item.passif && item.passif.toLocaleString('fr')}
                                        </td>
                                        <td className="text-end pe-0" style={{...tdStyle, fontSize: '11px'}}>
                                            {item.capitaux && item.capitaux.toLocaleString('fr')}
                                        </td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {conclusion &&
                <table style={{...tableStyle, marginBottom: "2%"}} className=" ">
                    <thead>
                    <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                        <th
                            className="text-start"
                            style={{fontSize: "14px",
                                textDecoration: 'underline',
                                fontWeight: "bold"}}
                        >
                            Conclusion
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={tdStyle}>
                        <td style={{...tdStyle,  textAlign: "start"}}>
                            <p style={{fontSize: "12px"}}>{conclusion}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            }
            {observation &&
                <table style={{...tableStyle, marginBottom: "2%"}} className=" ">
                    <thead>
                    <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                        <th
                            className="text-start"
                            style={{fontSize: "14px",
                                textDecoration: 'underline',
                                fontWeight: "bold"}}
                        >
                            Observation Générale
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={tdStyle}>
                        <td style={{...tdStyle,  textAlign: "start"}}>
                            <p style={{fontSize: "12px"}}>{observation}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            }
        </>
    )
}

export {PrintProcedureAudit}