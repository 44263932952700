import React, {useRef, useState} from 'react';
import {DatePicker, Space, Tooltip} from 'antd';
import {SearchOutlined} from "@ant-design/icons";
import {PrintHeader} from "../tools/PrintHeader";
import ReactToPrint, {useReactToPrint} from "react-to-print";
import docx from "html-docx-js/dist/html-docx";
import fileSaver from "file-saver";
import * as XLSX from "xlsx";
import {PrintHead} from "../tools/printModal/PrintHead";


const SearchHeader = ({handleSearch, placeholder}) => {
    return (
        <>
            <div className="card-title">
                {/*begin::Search*/}
                <div className="d-flex align-items-center position-relative my-1">
                    {/*begin::Svg Icon | path: icons/duotune/general/gen021.svg*/}
                    <span className="svg-icon svg-icon-1 position-absolute ms-6">
                        <SearchOutlined style={{ color: '#a1a5b7'}}/>
                    </span>
                    {/*end::Svg Icon*/}
                    <input type="text" data-kt-subscription-table-filter="search"
                           className="form-control min-w-150px w-lg-500px w-sm-300px ps-14" placeholder={placeholder || "Rechercher..."}
                           onChange={handleSearch}/>
                </div>
                {/*end::Search*/}
            </div>
        </>
    )
}

const ExportHeader = ({contentComponent, codeSommaire, title, exelData, isLandscape}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const componentRef = useRef();

    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    //     // header: () => <PrintHeader title={"WP ACCEPTATION DE LA MISSION - CRITERES RELATIFS A L’ENTITE"} />,
    // });

    const exportToWord = () => {
        const content = document.getElementById("print-content").innerHTML;

            const fullContent = `
          <html xmlns:w="urn:schemas-microsoft-com:office:word" lang="en">
            <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <style>
                body { margin:0; }
            </style>
            <title>${title}</title>
            </head>
            <body>
              <w:sectPr>
                <w:headerReference w:type="default" r:id="rId1"/>
              </w:sectPr>
              ${content}
            </body>
          </html>
        `;

        const options = {
            orientation: isLandscape ? 'landscape' : 'portrait',
            encoding: 'UTF-8'
        };

        const converted = docx.asBlob(fullContent, options);
        fileSaver.saveAs(converted, `Traitement_${codeSommaire}.docx`);
    };

    const pageStyle = `
    .page-footer, .page-footer-space {
        height: auto;
        display:block;
        margin-top:"100px";
      }
      
      .page-footer {
        position: absolute;
        bottom: 0;
        // display:block;
        width: 100%;
        border-top: 1px solid black; /* for demo */
        background: yellow; /* for demo */
        opacity:0.3;
        
      }

      .page {
        page-break-after: always;
      }
      
      @page {
        margin-bottom: 60mm;
        size: ${isLandscape ? "landscape" : "portrait"}
    }
    `;


    const handleExportExcel = () => {
        // e.preventDefault();

        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(exelData);

        XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

        XLSX.writeFile(wb, "MyExcel.xlsx")
    }


    const toggleMenu = (e) => {
        e.preventDefault()
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
        <Tooltip title='Exportation du traitement'>
            <div className="d-flex flex-column align-items-center">
                <button
                    className="btn btn-sm btn-flex btn-secondary fw-bold fs-9"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                    onClick={toggleMenu}
                >
                    <i className="fa-solid fa-upload text-black"></i>
                    <span className="d-md-inline">Exporter</span>
                </button>
                {isMenuOpen && (
                    <>
                        <div
                            id="kt_users_lists_views_export_menu"
                            className="menu menu-sub menu-sub-dropdown menu-option menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4 show"
                            data-kt-menu="true"
                            data-popper-placement="bottom-end"
                        >
                            <div className="menu-item px-3">
                                 <ReactToPrint
                                    trigger={() => (
                                        <a
                                            className="menu-link px-3"
                                            data-kt-users-export="pdf"
                                        >
                                            Exporter sous PDF
                                        </a>
                                    )}
                                    content={() => componentRef.current}
                                    documentTitle=''
                                    pageStyle={pageStyle}
                                />
                                {/*<a*/}
                                {/*    className="menu-link px-3"*/}
                                {/*    data-kt-users-export="pdf"*/}
                                {/*    onClick={(e) => {*/}
                                {/*        e.preventDefault()*/}
                                {/*        handlePrint()*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    Exporter sous PDF*/}
                                {/*</a>*/}
                            </div>
                            <div className="menu-item px-3">
                                <a
                                    className="menu-link px-3"
                                    data-kt-users-export="word"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        exportToWord()
                                    }}
                                >
                                    Exporter sous Word
                                </a>
                            </div>
                            <div className="menu-item px-3">
                                <a
                                    className="menu-link px-3"
                                    data-kt-users-export="excel"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        handleExportExcel()
                                    }}
                                >
                                    Exporter sous Excel
                                </a>
                            </div>
                            {/*end::Menu item*/}
                            {/*begin::Menu item*/}
                            {/*<div className="menu-item px-3">*/}
                            {/*    <a*/}
                            {/*        className="menu-link px-3"*/}
                            {/*        data-kt-users-export="csv"*/}
                            {/*    >*/}
                            {/*        Exporter sous CSV*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                            {/*end::Menu item*/}
                        </div>
                    </>
                )}
            </div>
        </Tooltip>
            <div className="d-none" >
                <div ref={componentRef} className="print-content" id="print-content" >
                    {/*<PrintHead title={title}/>*/}
                    {contentComponent}
                </div>
            </div>
        </>
    )
}

const DatePickerHeader = ({handleDateChange}) => {
    const { RangePicker } = DatePicker;

    return (
        <>
            <Space direction="vertical" size={12}>
                <RangePicker onChange={handleDateChange}/>
            </Space>
        </>
    )
};

export {SearchHeader, ExportHeader, DatePickerHeader}
