import React, {useEffect, useState} from "react";
import { Identification } from "../../Identification";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import {getDetailsRisqueEvaluationAll} from "../../../../../data/risqueEvaluation";
import {getDetailsRisqueConception} from "../../../../../data/risqueConceptionControle";
import {getListeNiveau} from "../../../../../data/params/niveau";
import {faIdCard, faTasks} from "@fortawesome/free-solid-svg-icons";
import RisqueControleSynthese from "./risqueControleSynthese/RisqueControleSynthese";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const SyntheseConceptionControles = ({
         mission,
         client,
         code,
         // modalId,
         prev
     }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [codeSommaire] = useState(code || 'EE0');
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [isTaskSave, setIsTaskSave] = useState([]);

    const [risquesIncidence, setRisquesIncidence] = useState([]);
    const [proceduresData, setProceduresData] = useState([]);
    const [descriptionsData, setDescriptionData] = useState([]);
    const [niveaux, setNiveaux] = useState([]);
    const [printData, setPrintData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(missionSelected.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [activeTab, missionSelected, codeSommaire])

    useEffect(() => {
        fetchNiveaux();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const risqueEvaluationResult = await getDetailsRisqueEvaluationAll(missionSelected.id);
            const risqueConceptionResult = await getDetailsRisqueConception(missionSelected.id);

            if (risqueEvaluationResult.result.length > 0) {
                const updatedData = risqueEvaluationResult.result
                    .flatMap(event => event.Lea_RisqueIdentificationDetails || [])
                    .map(detail => {
                        const evaluation = detail.Lea_RisqueEvaluations?.[0] || {};
                        const impactValue = evaluation.impactValue || '';
                        const probabiliteValue = evaluation.probabiliteValue || '';

                        return {
                            id: detail.id,
                            incidence: detail.incidence,
                            postes: detail.Lea_RisqueIdentificationAssertions?.[0]?.postes,
                            assertions: detail.Lea_RisqueIdentificationAssertions?.[0]?.assertions,
                            probabiliteValue,
                            probabilite_id: evaluation.probabilite_id || '',
                            impactValue,
                            impact_id: evaluation.impact_id || '',
                            score: evaluation.score || 0,
                            niveau_id: evaluation.niveau_id || '',
                        };
                    });

                const updateItem = updatedData.filter(data => data.impactValue * data.probabiliteValue > 5);
                setRisquesIncidence(updateItem);
            }

            if (risqueConceptionResult.result) {
                const process = risqueConceptionResult.result.procedures.map(opt => ({
                    id: opt.id,
                    procedure: opt.libelleProcedure,
                    incidence_id: opt.risqueDetails_id,
                }));
                setProceduresData(process);

                const descriptionsFetch = [];
                const incidenceData = [];

                risqueConceptionResult.result.risques.forEach(item => {
                    item.Lea_RisqueDescriptionControles?.forEach(risque => {
                        const element = {
                            id: risque.id,
                            description: risque.description,
                            resultat: risque.result,
                            procedure_id: risque.procedure_id,
                            incidence_id: risque.risqueDetails_id,
                        };
                        descriptionsFetch.push(element);
                    });

                    item.Lea_RisqueFonctionControles?.forEach(risque => {
                        const newElement = {
                            assertions: risque.assertions,
                            conclusion: risque.conclusion,
                            recommandation: risque.recommandation,
                            niveauResiduel_id: risque.niveauResiduel_id,
                            incidence_id: risque.risqueDetails_id,
                        };
                        incidenceData.push(newElement);
                    });
                });

                setDescriptionData(descriptionsFetch);
                setSelectedData({
                    ...selectedData,
                    incidenceTab: incidenceData,
                });
            }
        };

        fetchData();
    }, [missionSelected]);

    useEffect(() => {
        const updatedData = risquesIncidence.map(risque => {
            const incidenceSelect = selectedData.incidenceTab?.find(opt => opt.incidence_id === risque.id);

            return {
                id: risque.id,
                incidence: risque.incidence,
                postes: risque.postes,
                assertions: risque.assertions,
                niveau_id: risque.niveau_id,
                score: risque.score,
                niveauLibelle: niveaux.find(item => item.id === risque.niveau_id)?.libelle,
                questions: descriptionsData.filter(question => question.incidence_id === risque.id).map(opt => ({
                    questionLibelle: opt.description,
                    reponse: opt.resultat,
                    procedure_id: opt.procedure_id,
                })),
                procedures: proceduresData.filter(procedure => procedure.incidence_id === risque.id),
                niveauResiduel: niveaux.find(item => item.id === incidenceSelect?.niveauResiduel_id)?.libelle,
                recommandation: incidenceSelect?.recommandation || "",
                conclusion: incidenceSelect?.conclusion || "",
            };
        });

        setPrintData(updatedData);
    }, [descriptionsData, risquesIncidence, niveaux, selectedData, proceduresData]);

    const fetchNiveaux = async () => {
        try {
            const res = await getListeNiveau();
            setNiveaux(res.result);
        } catch (error) {
            console.error('Erreur lors de la récupération des rubriques', error);
        }
    };

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "EE0",
            title: `EE0 – Synthèse de l’identification et de l’évaluation de la conception et du fonctionnement du système de contrôle - ${selectYear}`,
            description: `EE0 – Synthèse de l’identification et de l’évaluation de la conception et du fonctionnement du système de contrôle - ${selectYear}`,
            icon: faTasks,
            content: (
                <RisqueControleSynthese
                    risqueData={printData}
                />
            ),
        },
    ];

    return (
        <>
            <div className="">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EE0 – Synthèse de l’identification et de l’évaluation de la conception et du fonctionnement du système de contrôle"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isExcelPrint={false}
                    isLandingScape={true}
                    printTitle={(`EE0 – Synthèse de l’identification et de l’évaluation de la conception et du fonctionnement du système de contrôle ${selectYear}`)}
                    excelData={selectedData}
                    printContent={(
                        <RisqueControleSynthese
                            risqueData={printData}
                        />
                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>
                        {formSteps[activeTab].content}
                    </>
                </StepBody>
            </div>
        </>
    );
 }

export {SyntheseConceptionControles}