import React, {useState} from "react";
import {Button, Divider, Input, Select, Space, Table, Tooltip, Typography} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import TableTitle from "../../../../../common/TableTitle";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListCheck, faTrash} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

const QuestionnaireMaintien = ({mission, questions, chargement, questionsData, setQuestionData, affectValid}) => {
    const [allElementsAdded, setAllElementsAdded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // setSelectedElementsList([]);
        }
    };
    const onChange = (value) => {
        setLoading(true);
        setTimeout(() => {
            const questId = parseInt(value);

            if (isNaN(questId)) {
                return;
            }

            const questionToAdd = questions.find((item) => item.id === questId);
            if (!questionToAdd) {
                return;
            }

            const isAlreadySelected = questionsData.some((element) => element.question_id === questionToAdd.id);

            if (!isAlreadySelected) {
                const newElement = {
                    question_id: questionToAdd.id,
                    libelle: questionToAdd.libelle,
                    isCheckedN: false,
                    isCheckedN_1: false,
                    motifRequired: true,
                    motif: null,
                };

                setQuestionData([...questionsData, newElement]);
            }
            setLoading(false);
        }, 1000)
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const onSelectedAll = () => {
        setLoading(true);
        setTimeout(() => {
            const newSelectedElementsList = [...questionsData];
            questions.forEach((opt) => {
                if (!newSelectedElementsList.some((element) => element.question_id === opt.id)) {
                    const newElement = {
                        question_id: opt.id,
                        libelle: opt.libelle,
                        isCheckedN: false,
                        isCheckedN_1: false,
                        motifRequired: true,
                        motif: null,
                    };
                    newSelectedElementsList.push(newElement);
                }
            });
            setQuestionData(newSelectedElementsList);
            setAllElementsAdded(true);
            setLoading(false);
        }, 1500)
    }

    const handleDeleteAllElements = () => {
        setLoading(true)
        setTimeout(() => {
            setQuestionData([]);
            setAllElementsAdded(false);
            setLoading(false)
        }, 1500)
    };

    const handleCheckboxChangeN = (index, isChecked) => {
        const updatedList = questionsData.map((item, i) =>
            i === index ? { ...item, isCheckedN: isChecked, motifRequired: isChecked !== item.isCheckedN_1 && item.motif === null } : item
        );
        setQuestionData(updatedList);
    };

    // const handleCheckboxChangeN_1 = (index, isChecked) => {
    //     const updatedList = questionsData.map((item, i) =>
    //         i === index ? { ...item, isCheckedN_1: isChecked, motifRequired: isChecked !== item.isCheckedN && item.motif === null } : item
    //     );
    //     setQuestionData(updatedList);
    // };

    const handleCommentChange = (index, motif) => {
        const updatedList = questionsData.map((item, i) =>
            i === index ? { ...item,  motif, motifRequired: ((!item.motif || item.motif === '') && item.isChecked !== item.isCheckedN_1) } : item
        );
        setQuestionData(updatedList);
    };

    const handleDeleteElement = (index) => {
        setLoading(true);
        setTimeout(() => {
            const updatedList = questionsData.filter((_, i) => i !== index);
            if(updatedList.length <= 0){
                setAllElementsAdded(false);
            }
            setQuestionData(updatedList);
            setLoading(false);
        }, 1000)
    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Questions</TableTitle>,
            dataIndex: 'name',
            width: 450,
            render: (_, record) => (
                <>
                    <Input.TextArea
                        size="large"
                        value={record.libelle}
                        readOnly={true}
                        bordered={false}
                        style={{width: "100%"}}
                        showCount
                        maxLength={5000}
                        autoSize={{
                            minRows: 2,
                            maxRows: 8,
                        }}
                    />
                </>
            ),
        },
        {
            title: () => <TableTitle>Année N</TableTitle>,
            key: 'anne',
            dataIndex: 'anne',
            children: [
                {
                    title: () => <TableTitle>Positif</TableTitle>,
                    dataIndex: 'reponse',
                    width: 70,
                    render: (_, record, index) => (
                        <div className="form-check form-check-custom me-5">
                            <input
                                className="form-check-input"
                                type="radio"
                                value=""
                                disabled={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                name={`positiveEntityN_${index}`}
                                checked={record.isCheckedN}
                                onChange={(e) =>
                                    handleCheckboxChangeN(index, e.target.checked)
                                }
                            />
                        </div>
                    ),
                },
                {
                    title: () => <TableTitle>Négatif</TableTitle>,
                    dataIndex: 'reponse',
                    width: 70,
                    render: (_, record, index) => (
                        <div className="form-check form-check-custom me-5">
                            <input
                                className="form-check-input"
                                type="radio"
                                value=""
                                disabled={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                name={`negativeEntityN_${index}`}
                                checked={!record.isCheckedN}
                                onChange={(e) =>
                                    handleCheckboxChangeN(index, !e.target.checked)
                                }
                            />
                        </div>
                    ),
                },
            ],
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 350,
            render: (_, record, index) => (
                <>
                    <Input.TextArea
                        size="large"
                        placeholder={`${((mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid) ? "" : "Saisisser le commentaire"}`}
                        className={`${record.motifRequired !== true ? '' : 'is-invalid'}`}
                        required={record.motifRequired === true}
                        value={record.motif}
                        readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                        onChange={(e) => {
                            handleCommentChange(index, e.target.value);
                        }}
                        bordered={false}
                        style={{width: "100%", border: record.motifRequired === true ? "1px solid red" : "0"}}
                        showCount
                        maxLength={5000}
                        autoSize={{
                            minRows: 2,
                            maxRows: 8,
                        }}
                    />
                    {record.motifRequired === true &&  (
                        <div className="invalid-feedback">Champ obligatoire</div>
                    )}
                </>
            ),
        },
        {
            title: () => <TableTitle>Actions</TableTitle>,
            width: 70,
            render: (_, record, index) => (
                <>
                    {<Space>
                        {((mission.isCloture !== 1 || mission.isClotureProvisoire !== 1) || affectValid) && <Button
                            onClick={() => handleDeleteElement(index)}
                            title="Supprimer ?"
                            type="primary"
                            icon={<DeleteOutlined/>}
                            danger
                        />}
                    </Space>}
                </>
            ),
        },
    ];

    return(
        <>
            <div className="d-flex flex-column ">
                <div className="w-100 flex-lg-row-auto mb-4 me-4 me-lg-6">
                    <div className="card card-flush py-1">
                        <div className="card-header">
                            <div className="card-title">
                                <Title style={{
                                    display: "block",
                                    fontWeight: '600',
                                    color: appTheme.colors.primary_blue,
                                    textAlign: "center"
                                }} level={3}>{"Questionnaire de maintien"}</Title>
                            </div>
                        </div>
                        <Divider/>
                        {!((mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid) && <div className='d-flex align-center px-3'>
                            <Select
                                style={{width: 800}}
                                size="large"
                                showSearch
                                placeholder="Selectionner une question"
                                optionFilterProp="children"
                                disabled={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={questions && questions.map((item) => ({
                                    label: item.libelle,
                                    value: item.id
                                }))}
                            />
                            {!((mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid) &&  (allElementsAdded ? (
                                    <div className='ms-10'>
                                        <Tooltip title="Tout supprimer">
                                            <Button
                                                size="large"
                                                onClick={handleDeleteAllElements}
                                                style={{backgroundColor: 'red'}}
                                                icon={<FontAwesomeIcon icon={faTrash} color="white"/>}
                                            >
                                            </Button>
                                        </Tooltip>
                                    </div>
                                )
                                :
                                (
                                    <div className='ms-10'>
                                        <Tooltip title="Tout sélectionner">
                                            <Button
                                                size="large"
                                                onClick={onSelectedAll}
                                                style={{backgroundColor: appTheme.colors.primary_blue}}
                                                icon={<FontAwesomeIcon icon={faListCheck} color="white"/>}
                                            >
                                            </Button>
                                        </Tooltip>

                                    </div>
                                ))
                            }
                        </div>}
                    </div>
                </div>
                <div className="w-100">
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                rowKey={(record) => record.id}
                                dataSource={questionsData}
                                pagination={tableParams.pagination}
                                loading={loading || chargement}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuestionnaireMaintien;