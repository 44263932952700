import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { succesMessage } from "../../../../../../helpers/AssetsHelpers";
import {ajouterRisqueCommentaire, getStrategieRisqueCommentaire} from "../../../../../../data/stategieGlobale";
import {getDetailsRisqueEvaluationAll} from "../../../../../../data/risqueEvaluation";
import {Button, Input, Space, Table, Tag, Tooltip, Typography} from "antd";
import {getListeNiveau} from "../../../../../../data/params/niveau";
import {getListeProbabilite} from "../../../../../../data/params/probabilite";
import {getListeImpact} from "../../../../../../data/params/impact";
import {DeleteOutlined} from "@ant-design/icons";
import {areArraysEqual} from "../../../../../utilities/Utilities";
import TableTitle from "../../../../../common/TableTitle";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

const ZoneRisque = ({ mission, isProvisoire, strategies, codeSommaire , heureEntree, date, tache_id, intervention_id, chargement}) => {
    const [risqueIncidences, setRisqueIncidences] = useState([]);
    const [niveaux, setNiveaux] = useState([]);
    const [probabilites, setProbabilites] = useState([]);
    const [impacts, setImpacts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

    };

    useEffect(() => {
        fetchProbabilite();
        fetchImpact();
        fetchNiveaux();
    }, []);

    const fetchNiveaux = async () => {
        try {
            const res = await getListeNiveau();
            setNiveaux(res.result);
        } catch (error) {
            console.error('Erreur lors de la récupération des niveaux', error);
        }
    };

    const fetchProbabilite = async () => {
        try {
            setLoading(true);
            const res = await getListeProbabilite();
            setProbabilites(res.result);
            setLoading(false);
        } catch (error) {
            console.error('Erreur lors de la récupération des rubriques', error);
            setLoading(false);
        }
    };

    const fetchImpact = async () => {
        try {
            setLoading(true);
            const res = await getListeImpact();
            setImpacts(res.result);
            setLoading(false);
        } catch (error) {
            console.error('Erreur lors de la récupération des rubriques', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true)
        getDetailsRisqueEvaluationAll(mission.id)
            .then((res) => {
                if (res.result.length > 0) {
                    const arrayData = [];
                    res.result.forEach((event) => {
                        event.Lea_RisqueIdentificationDetails?.forEach((detail) => {
                            const newSelectArray = {
                                id: detail.id,
                                incidence: detail.incidence,
                                poste: detail.Lea_RisqueIdentificationAssertions[0]?.poste,
                                assertions: detail.Lea_RisqueIdentificationAssertions[0]?.assertions,
                                probabiliteValue: detail.Lea_RisqueEvaluations[0]?.probabiliteValue || '',
                                probabilite_id: detail.Lea_RisqueEvaluations[0]?.probabilite_id || '',
                                impactValue: detail.Lea_RisqueEvaluations[0]?.impactValue || '',
                                impact_id: detail.Lea_RisqueEvaluations[0]?.impact_id || '',
                                score: detail.Lea_RisqueEvaluations[0]?.score || 0,
                                niveau_id: detail.Lea_RisqueEvaluations[0]?.niveau_id || '',
                                commentaire: ""
                            };
                            arrayData.push(newSelectArray);
                        });
                    });

                    // const updateItem = arrayData.filter((data) => data.impactValue * data.probabiliteValue > 5);

                    setRisqueIncidences(arrayData);
                    setLoading(false)
                }
            });
    }, [mission]);

    useEffect(() => {
        setLoading(true)
        if(strategies){
            getStrategieRisqueCommentaire(strategies.id)
                .then((res) => {
                    if (res.result && res.result.length > 0) {
                        res.result?.forEach((record) => {
                            const updatedList = risqueIncidences.map((item) =>
                                item.id === record.risqueDetails_id ? { ...item, commentaire: (record.commentaire || '') } : item
                            );

                            if (!areArraysEqual(updatedList, risqueIncidences)) {
                                setRisqueIncidences(updatedList)
                            }
                        })

                    }
                    setLoading(false)
                });
        }
    }, [strategies, risqueIncidences]);

    const handleDeleteElement = (index) => {
        const updatedList = risqueIncidences.filter((_, i) => i !== index);
        setRisqueIncidences(updatedList);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        const data = {
            strategie_id: strategies?.id,
            risquesData: risqueIncidences,
            codeSommaire ,
            heureEntree,
            date,
            tache_id,
            intervention_id,
            isProvisoire: parseInt(isProvisoire) === 1,
            exercice_id: mission.id,
            client_id: mission.client_id
        }

        const { status, message } = await ajouterRisqueCommentaire(data);
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    };

    const columns = [
        {
            title: () => <TableTitle>Incidence (effet) du facteur de risque sur les EF</TableTitle>,
            dataIndex: 'incidence',
            width: 400,
            render: (_, record) => (
                <span className='' style={{fontSize: '14px'}}>
                  {record.incidence}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 100,
            render: (_, record) => (
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: () => <TableTitle>Probabilité Surv.</TableTitle>,
            dataIndex: 'probabilite',
            width: 100,
            render: (_, record) => (
                <Tag color={record.score < 6 ? "success" : record.score <= 15 ? "warning" : "red"}>
                    <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                        {probabilites.find((probabilite) => probabilite.id === record.probabilite_id)?.libelle}
                    </span>
                </Tag>
            ),
        },

        {
            title: () => <TableTitle>Impact financier</TableTitle>,
            dataIndex: 'impactFinancier',
            width: 100,
            render: (_, record) => (
                <Tag color={record.score < 6 ? "success" : record.score <= 15 ? "warning" : "red"}>
                    <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                        {impacts.find((impact) => impact.id === record.impact_id)?.libelle}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Niveau Risque</TableTitle>,
            dataIndex: 'niveau_id',
            width: 100,
            render: (_, record) => (
                <Tag color={record.score < 6 ? "success" : record.score <= 15 ? "warning" : "red"}>
                    <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                        {niveaux.find((niveau) => niveau.id === record.niveau_id)?.libelle}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 300,
            render: (_, record) => (
                <Input.TextArea
                    size="large"
                    placeholder="Saissiser le commentaire"
                    readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                    maxLength={2000}
                    bordered={false}
                    showCount
                    autoSize={{
                        minRows: 1,
                        maxRows: 6,
                    }}
                    value={record.commentaire}
                    onChange={(e) => {
                        const updatedList = risqueIncidences.map((item) =>
                            item.id === record.id ? { ...item, commentaire: e.target.value } : item
                        );

                        setRisqueIncidences(updatedList);
                    }}
                />
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 50,
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteElement(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    return (
        <div className="d-flex flex-column fv-plugins-bootstrap5 fv-plugins-framework pb-10">
            {<div className="d-flex justify-content-end align-items-end mb-10">
                <Tooltip title="Enregistrer">
                    <Button
                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                        size={"large"}
                        // loading={loading}
                        style={{
                            backgroundColor: appTheme.colors.secondary,
                            color: "white"
                        }}
                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                        onClick={handleSubmit}
                    >
                        {!loading &&
                            <span className='indicator-label text-white'>Enregistrer</span>}
                        {loading && (
                            <span className='indicator-progress fs-9 text-white'
                                  style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                        )}
                    </Button>
                </Tooltip>
            </div>}
            <div className="card-header">
                <div className="card-title">
                    <Title style={{
                        display: "block",
                        fontWeight: '600',
                        color: appTheme.colors.primary,
                        // textAlign: "center"
                    }} level={4}>{"EC4.6: Zone de risques"}</Title>
                </div>
            </div>
            <div className="table-responsive mt-10">
                <Table
                    rowClassName={() =>
                        `cursor-pointer h-3px p-0 m-0 lh-0.5`
                    }
                    columns={columns}
                    rowKey={(record, index) => index}
                    dataSource={risqueIncidences ?? []}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    loading={chargement || loading}
                    rowSelection
                    // size={"small"}
                />
            </div>
        </div>
    );
};

export default ZoneRisque;
