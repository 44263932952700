import React from 'react';

const PrintTraitementBlanchiment = ({datas, objectif, conclusion, title}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thStyle = {
        border: '1px solid #000000',
        fontWeight: "bold",
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <div className="">
            {objectif &&
                <div className="mb-5">
                    <table style={tableStyle} className="tablePerso">
                        <thead>
                        <tr style={thStyle} >
                            <th
                                className="text-start"
                                style={{fontSize: "14px",
                                    textDecoration: 'underline',
                                    fontWeight: "bold"}}
                            >
                                Objectif
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={tdStyle}>
                            <td style={tdStyle}>
                                <p style={{fontSize: "12px"}}>{objectif}</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }

            <div style={{marginTop: '10px'}} className="table-responsive mb-5">
                <div className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className="tablePerso w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={thStyle} className="thperso w-70px">Référence</th>
                                <th style={thStyle} className="thperso w-350px">Travaux à faire (TAF)</th>
                                <th style={thStyle} className="thperso w-100px">Assertions</th>
                                <th style={thStyle} className="thperso w-100px">Réponses</th>
                                <th style={thStyle} className="thperso w-250px">Commentaire</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(datas && datas.length > 0) && datas.map((item, index) => (
                                <tr key={index} style={tdStyle} className="">
                                    <td className="" style={{...tdStyle, fontSize: '11px'}}>
                                        {item.ref}
                                    </td>
                                    <td className=" text-start" style={{...tdStyle, fontSize: '11px' }}>
                                        {

                                            item.libelle.length > 1 ?
                                                item.libelle.map((opt, indexOpt) => (
                                                    <div  key={indexOpt} className='d-flex flex-column'>
                                                        <p className='' style={{fontSize: '10px'}}>
                                                            <strong className='me-2 fw-bolder' style={{fontSize: '13px'}}>.</strong>{opt}
                                                        </p>
                                                    </div>
                                                ))
                                                :
                                                item.libelle.length >= 0 ?
                                                    item.libelle.map((opt, indexOpt) => (

                                                        <p className='' key={indexOpt} style={{fontSize: '10px'}}>
                                                            {opt}
                                                        </p>
                                                    ))
                                                    :
                                                    ""
                                        }
                                    </td>
                                    <td className=" text-center" style={tdStyle}>
                                        <div className='d-flex align-center '>
                                            {item.assertions?.map((assertion) => (
                                                <span className='fw-bolder me-5' key={assertion.id}>
                                                          {assertion}
                                                        </span>
                                            ))}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        {item.reponse === '1' ? 'N/A': item.reponse === '2' ? 'OUI' : 'NON' }
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <p style={{fontSize: '11px'}}>
                                            {item.commentaire}
                                        </p>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            {conclusion &&
                <table style={tableStyle} className=" ">
                    <thead>
                    <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                        <th
                            className="text-start"
                            style={{fontSize: "14px",
                                textDecoration: 'underline',
                                fontWeight: "bold"}}
                        >
                            Conclusion
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={tdStyle}>
                        <td style={{...tdStyle,  textAlign: "start"}}>
                            <p style={{fontSize: "12px"}}>{conclusion}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            }
        </div>
    );
}

export default PrintTraitementBlanchiment;
