import axios from 'axios'
import {
    AJOUTER_INTERVENTION,
    LISTE_INTERVENTION, LISTE_INTERVENTION_ACTIVE,  
    MODIFIER_INTERVENTION,
    MODIFIER_ETAT_INTERVENTION,  SUPPRIMER_INTERVENTION
} from '../../api/apiRoutes'

export const addIntervention = async (data) => {
    return axios.post(AJOUTER_INTERVENTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateIntervention = async (data) => {
    return axios.post(MODIFIER_INTERVENTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeIntervention = async () => {
    return axios.get(LISTE_INTERVENTION).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeInterventionActive = async () => {
    return axios.get(LISTE_INTERVENTION_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteIntervention = async (data) => {
    return axios.post(SUPPRIMER_INTERVENTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatIntervention = async (data) => {
    return axios.post(MODIFIER_ETAT_INTERVENTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}