import React, {useEffect, useState} from "react";
import {Button, Col, Input, Row, Space, Table, Tag, Tooltip, Typography} from "antd";
import {getBalanceDefinitifDetails, getBalanceProvisoiresDetails} from "../../../../../../../data/balance/balance";
import {getExerciceParAnnee} from "../../../../../../../data/exercice";
import {areArraysEqual, isNumber} from "../../../../../../utilities/Utilities";
import {
    getGrandLivreDefinitifDetails,
    getGrandLivreProvisoiresDetails
} from "../../../../../../../data/balance/grandLivre";
import moment from "moment";
import * as XLSX from "xlsx";
import { SearchHeader} from "../../../../../../layout/header/HeaderList";
import {
    ajouterProcedureFeuilleMaitresseRef,
    getProcedureFeuilleMaitresseRef
} from "../../../../../../../data/procedureAudit";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../../helpers/AssetsHelpers";
import TableTitle from "../../../../../../common/TableTitle";
import {ModalTitle} from "../../../../../../modals/modals.components";
import ModalAlert from "../../../../../../modals/Modal";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faFileExport, faSave} from "@fortawesome/free-solid-svg-icons";
import DynamicTable from "../../../../../../modals/DynamicTable";

const { Text, Title } = Typography;

const LivreFilteredModal = ({
        handleVisibility,
        visible,
        chargement,
        livres,
        compte,
        filtersItems,
        setFiltersItems
    }) => {

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = livres.filter(
            (item) =>
                item.libelle.toLowerCase().includes(searchTerm) ||
                item.libelleECR.toLowerCase().includes(searchTerm) ||
                item.numeroCompte.toString().toLowerCase().includes(searchTerm) ||
                item.piece.toLowerCase().includes(searchTerm) ||
                item.piece.toLowerCase().includes(searchTerm) ||
                item.codeJournal.toLowerCase().includes(searchTerm)
        );
        setFiltersItems(filtered);
    };

    const handleExportExcel = (e) => {
        e.preventDefault();

        const updateLivre = livres.map((livre) => ({
            numeroCompte: livre.numeroCompte,
            libelle: livre.libelle,
            date: moment(livre.date).format('DD/MM/YYYY'),
            codeJournal: livre.codeJournal,
            piece: livre.piece,
            libelleECR: livre.libelleECR,
            soldeDebit: livre.soldeDebit,
            soldeCredit: livre.soldeCredit
        }))

        let wb = XLSX.utils.book_new(),
            ws = XLSX.utils.json_to_sheet(updateLivre);

        XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

        XLSX.writeFile(wb, "MyExcel.xlsx")
    }

    const columnsGrands = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>N° de compte</TableTitle>,
            dataIndex: 'numeroCompte',
            sorter: true,
            width: 100,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '13px'}}>
                        {record.numeroCompte}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelleCompte',
            sorter: true,
            width: 250,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <span className="fw-bolder text-uppercase" style={{fontSize: '13px'}}>
                        {record.libelle}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Date</TableTitle>,
            dataIndex: 'date',
            width: 100,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <span className="fw-bolder" style={{fontSize: '13px'}}>
                        {moment(record.date).format('DD/MM/YYYY')}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Code journal</TableTitle>,
            dataIndex: 'codeJournal',
            width: 50,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '13px'}}>
                        {record.codeJournal}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>N° Pièce</TableTitle>,
            dataIndex: 'piece',
            width: 50,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '13px'}}>
                        {record.piece}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Libellé ECR</TableTitle>,
            dataIndex: 'libelle',
            width: 250,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <span className="fw-bolder text-uppercase" style={{fontSize: '13px'}}>
                        {record.libelleECR}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Solde Débit</TableTitle>,
            dataIndex: 'soldeDebit',
            width: 100,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '13px'}}>
                        {isNumber(record.soldeDebit) ? record.soldeDebit.toLocaleString('fr') : record.soldeDebit}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Solde Crédit</TableTitle>,
            dataIndex: 'soldeCredit',
            width: 100,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '13px'}}>
                        {isNumber(record.soldeCredit) ? record.soldeCredit.toLocaleString('fr') : record.soldeCredit}
                    </div>
                </Tooltip>
            ),
        },
    ];

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Opérations liées au compte <strong>{compte.compte || ''}</strong> </ModalTitle>}
                footer={[]}
                modalStyles={{ top: 10 }}
                width={1300}
            >
                <div className="d-flex justify-content-between align-items-end mt-10 mb-5">
                    {<div className="">
                        <SearchHeader
                            placeholder="Rechercher par le libellé, libelléECR, numéro, pièce, code, journal"
                            handleSearch={handleSearchChange}
                        />
                    </div>}
                    <Space>
                        <Tooltip title="Expoter le traitement">
                            <Button
                                size={"middle"}
                                style={{
                                    backgroundColor: appTheme.colors.primary_blue,
                                    color: "white"
                                }}
                                icon={<FontAwesomeIcon icon={faFileExport}/>}
                                onClick={handleExportExcel}
                            >
                                Exporter
                            </Button>
                        </Tooltip>
                    </Space>
                </div>
                <div className="table-responsive">
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                rowClassName={(record,) =>
                                    `${record.error && record.error === true ? `text-info` : ''} cursor-pointer h-3px p-0 m-0 lh-0.5`
                                }
                                columns={columnsGrands}
                                rowKey={(record) => record.id}
                                dataSource={filtersItems}
                                pagination={false}
                                loading={chargement}
                                scroll={{
                                    y: 450,
                                }}
                                rowSelection
                                size={"small"}
                            />
                        </div>
                    </div>
                </div>
            </ModalAlert>
        </>
    )
}

const FeuilleMaitresse = ({
          handleVisibility,
          visible,
          chargement,
          mission,
          tabMaitresse,
          client,
          menuLibelle,
          isProvisoire,
          codeSommaire
    }) => {
    const [balancesData, setBalancesData] = useState([])
    const [balancesN, setBalancesN] = useState([])
    const [balances, setBalances] = useState([])
    const [fmData, setFmData] = useState([])
    const [livreData, setLivreData] = useState([])
    const [livres, setLivres] = useState([])
    const [loading, setLoading] = useState(false)
    const [missionN_1, setMissionN_1] = useState(null);
    const [balanceN_1, setBalanceN_1] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [compte, setCompte] = useState(null);
    const [filtersItems, setFiltersItems] = useState([]);
    const [tabsMenu, setTabsMenu] = useState([]);
    const [libelle, setLibelle] = useState('');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        if(tabMaitresse){
            let lists = [
                {
                    code: 'FMP',
                    libelle: 'FEUILLE MAITRESSE PRINCIPALE'
                }
            ]

            tabMaitresse.forEach((item) => {
                const isAlreadyIn = lists.some((opt) => opt.libelle === item.libelle)
                if(!isAlreadyIn){
                    const newElement = {
                        code: item.code,
                        libelle: item.libelle
                    }

                    lists.push(newElement)
                }
            })
            setTabsMenu(lists)
        }
    }, [tabMaitresse])

    useEffect(() =>{
        setLoading(true);
        if(parseInt(isProvisoire) === 1){
            getBalanceProvisoiresDetails(mission.id)
                .then((res) => {
                    if(res.results) {
                        setBalancesN(res.results.Lea_BalanceDetails)
                        setLoading(false)
                    }else{
                        setBalancesN([])
                        setBalances([])
                        setLoading(false)
                    }
                })
        }
        else{
            getBalanceDefinitifDetails(mission.id)
                .then((res) => {
                    if(res.results) {
                        setBalancesN(res.results.Lea_BalanceDetails)
                        setLoading(false)
                    }else{
                        setBalancesN([])
                        setBalances([])
                        setLoading(false)
                    }
                })
        }
    }, [mission, isProvisoire])

    useEffect(() => {
        if(mission){
            getExerciceParAnnee(client.id, (parseInt(mission.annee) - 1))
                .then((res) => {
                    setMissionN_1(res);
                } )
        }
    }, [mission, client])

    useEffect(() => {
        if(missionN_1){
            setLoading(true);
            getBalanceDefinitifDetails(missionN_1.id)
                .then((res) => {
                    if (res.results) {
                        setBalanceN_1(res.results.Lea_BalanceDetails);
                    } else {
                        setBalanceN_1([]);
                    }
                    setLoading(false);
                })
        }
    }, [mission, missionN_1]);

    useEffect(() =>{
        setLoading(true);
        if(parseInt(isProvisoire) === 1){
            getGrandLivreProvisoiresDetails(mission.id)
                .then((res) => {
                    if(res.results) {
                        setLivreData(res.results.Lea_GrandLivreDetails)
                    }else{
                        setLivreData([])
                    }
                    setLoading(false);
                })
        }
        else{
            getGrandLivreDefinitifDetails(mission.id)
                .then((res) => {
                    if(res.results) {
                        setLivreData(res.results.Lea_GrandLivreDetails)
                    }else{
                        setLivreData([])
                    }
                    setLoading(false);
                })
        }
    }, [mission, isProvisoire])

    useEffect(() => {
        if (mission) {
            setLoading(true);
            getProcedureFeuilleMaitresseRef(mission.id)
                .then((res) => {
                    if (res.result && res.result.length > 0) {
                        setFmData(res.result);
                    } else {
                        setFmData([]);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Erreur lors de la récupération des données de feuille maîtresse :", error);
                    setLoading(false);
                });
        }
    }, [mission, isProvisoire]);

    useEffect(() => {
        const bilanMap = new Map();

        balancesN.forEach((balance) => {
            const isExist = balanceN_1.find((item) => item.numeroCompte.includes(balance.numeroCompte));

            const newElement = {
                compte: balance.numeroCompte ,
                libelle: balance.libelle,
                ref: "",
                soldeN: parseInt(balance.soldeDebit || 0) - parseInt(balance.soldeCredit || 0),
                soldeN_1: isExist ? parseInt(isExist.soldeDebit || 0) - parseInt(isExist.soldeCredit || 0) : 0,
            };

            bilanMap.set(balance.numeroCompte, newElement);
        });

        const arrayBalance = [...bilanMap.values()];

        setBalancesData(arrayBalance.sort((a, b) => a.compte_Prov?.localeCompare(b.compte_Prov)));

    }, [balanceN_1, balancesN])

    const onChange = (key) => {
        let updatedList = []
        if(key === 'FMP'){
            const tab = tabsMenu.find((item) => item.code === key)
            if(tab){
                setLibelle(tab.libelle)
                tabMaitresse.forEach((opt) => {
                    const elements = balancesData.filter((balance) => {
                        const accountNumber = balance.compte.toString();
                        return accountNumber.startsWith(opt.code);
                    });

                    const newBalance = {
                        compte: opt.code ,
                        libelle: opt.libelle,
                        ref: opt.ref,
                        soldeN: elements.reduce((accumulator, balance) => accumulator + (balance.soldeN), 0),
                        soldeN_1: elements.reduce((accumulator, balance) => accumulator + (balance.soldeN_1), 0),
                    }

                    updatedList.push(newBalance)
                })
            }
        }
        else{
            const tab = tabsMenu.find((item) => item.code === key)
            if(tab){
                setLibelle(tab.libelle)
                updatedList = balancesData.filter((balance) => {
                    const accountNumber = balance.compte.toString();
                    return accountNumber.startsWith(key);
                });
            }
        }

        setBalances(updatedList)
    };

    const handleReferenceInput = (value, record) => {
        const fmSelects = [...fmData];

        const isAlready = fmSelects.some(fm => fm.compte === record.compte);
        if(isAlready){
            const fmSelects = fmData.map((item) =>
                item.compte === record.compte ? { ...item,  reference: value } : item
            );

            setFmData(fmSelects);
        }
        else{
            const fmSelect = {
                compte: record.compte,
                reference: value,
                isProvisoire: isProvisoire,
            }

            setFmData([...fmData, fmSelect]);
        }
    }

    const handleLivreFilter = (e, value) => {
        e.preventDefault()

        const compteSelected = balances.find((item) => item.compte.includes(value));
        if(compteSelected) {
            setCompte(compteSelected)
        }

        const updated = livreData.filter((item) => item.numeroCompte.includes(value))
        setLivres(updated)
        setFiltersItems(updated)
    }

    const handleModalOpen = () => {
        setIsModalOpen(prevState => !prevState);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        const updatedList = fmData.map((item) =>({
            compte: item.compte,
            reference: item.reference,
            isProvisoire: isProvisoire,
        }));

        const data = {
            isProvisoire,
            codeSommaire: codeSommaire,
            exercice_id: mission.id,
            client_id: mission.client_id,
            balanceData: updatedList
        }

        const {status, message} = await ajouterProcedureFeuilleMaitresseRef(data)
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    }

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 50,
        },
        {
            title: () => <TableTitle>N° de compte</TableTitle>,
            dataIndex: 'numeroCompte',
            width: 100,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '13px'}}>
                        {record.compte}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'col1',
            width: 300,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '13px'}}>
                    {record.libelle}
                </span>
            ),
        },
        {
            title: () => <TableTitle>REF</TableTitle>,
            dataIndex: 'libelle',
            width: 150,
            render: (_, record, index) => (
                <>
                    <div className="">
                        <Input.TextArea
                            style={{fontSize: '13px'}}
                            bordered={false}
                            size="large"
                            placeholder="Référence"
                            value={fmData.find(fm => fm.compte === record.compte)?.reference || ""}
                            name='ref'
                            autoSize={{
                                minRows: 1,
                                maxRows: 3,
                            }}
                            onChange={(e) =>{
                                handleReferenceInput((e.target.value), record)
                            }}
                        />
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Exercice {mission.annee}</TableTitle>,
            dataIndex: 'soldeDebit',
            width: 110,
            render: (_, record) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '13px'}}>
                        {(record.soldeN || 0).toLocaleString('fr')}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Exercice {parseInt(mission.annee) - 1}</TableTitle>,
            dataIndex: 'soldeCredit',
            width: 110,
            render: (_, record) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '13px'}}>
                        {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Variation</TableTitle>,
            dataIndex: 'soldeCredit',
            width: 100,
            render: (_, record) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '13px'}}>
                        {(record.soldeN - record.soldeN_1).toLocaleString('fr')}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>% Variation</TableTitle>,
            dataIndex: 'soldeCredit',
            width: 80,
            render: (_, record) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '13px'}}>
                        {( ( (record.soldeN_1)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                    </div>
                </>
            ),
        },
    ];

    const columnsTabs = [
        {
            title: () => <TableTitle>Menu</TableTitle>,
            dataIndex: 'numeroCompte',
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '14px'}}>
                        {record.code}
                    </span>
                </Tag>
            ),
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    const handleRowClick = (record) => {
        return new Promise((resolve) => {
            setLoading(true);
            setTimeout(() => {
                onChange(record.code)
                setLoading(false);
                resolve();
            }, 1000);
        });
    };

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> {menuLibelle} </ModalTitle>}
                footer={[]}
                modalStyles={{ top: 10 }}
                width={1300}
            >
                <div className="d-flex justify-content-end align-items-end mb-10">
                    <Space>
                        <Tooltip title="Expoter le traitement">
                            <Button
                                size={"middle"}
                                style={{
                                    backgroundColor: appTheme.colors.primary_blue,
                                    color: "white"
                                }}
                                icon={<FontAwesomeIcon icon={faFileExport}/>}
                                // onClick={handleSubmit}
                            />
                        </Tooltip>
                        <Tooltip title="Enregistrer">
                            <Button
                                disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                                size={"middle"}
                                style={{
                                    backgroundColor: appTheme.colors.secondary,
                                    color: "white"
                                }}
                                icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                onClick={handleSubmit}
                            >
                                {!loading &&
                                    <span className='indicator-label text-white'>Enregistrer</span>}
                                {loading && (
                                    <span className='indicator-progress fs-9 text-white'
                                          style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </Button>
                        </Tooltip>
                    </Space>
                </div>
                <Row gutter={[16, 16]}>
                    <Col span={3}>
                        <div className="table-responsive mx-2">
                            <div className="table-responsive">
                                <DynamicTable
                                    columns={columnsTabs}
                                    dataSource={tabsMenu ?? []}
                                    loading={chargement || loading}
                                    onRowClickHandler={handleRowClick}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col span={21}>
                        <div className="table-responsive ms-2">
                            <Table
                                onRow={(record, index) => {
                                    return {
                                        onDoubleClick: (event) => {
                                            handleLivreFilter(event, record.compte);
                                            handleModalOpen();
                                            setSelectedRowKeys([index]);
                                        },
                                    };
                                }}
                                rowClassName={(record, index) =>
                                    selectedRowKeys.includes(index) ? 'cursor-pointer h-3px p-0 m-0 lh-0.5 highlighted-row' : 'cursor-pointer h-3px p-0 m-0 lh-0.5'
                                }
                                columns={columns}
                                rowKey={(record, index) => index}
                                dataSource={balances ?? []}
                                pagination={false}
                                scroll={{ y: 450}}
                                loading={chargement || loading}
                                rowSelection={{
                                    type: 'radio',
                                    ...rowSelection,
                                }}
                                size={"small"}
                                summary={(pageData) => {
                                    let totalSoldeN = 0;
                                    let totalSoldeN_1 = 0;
                                    let totalVariation = 0;
                                    let totalPourcVariation = 0;
                                    pageData.forEach(({ soldeN, soldeN_1}) => {
                                        totalSoldeN += soldeN;
                                        totalSoldeN_1 += soldeN_1;
                                        totalVariation += soldeN - soldeN_1
                                        totalPourcVariation += (soldeN_1 === 0 ? 0 : (soldeN - soldeN_1) / soldeN_1  * 100)
                                    });
                                    return (
                                        <>
                                            <Table.Summary fixed>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>Sommes</Table.Summary.Cell>
                                                    <Table.Summary.Cell index={3} className='text-end'>
                                                        <Text type="success">{''}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={4} className='text-end'>
                                                        <Text type="success">{''}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={5} className='text-end'>
                                                        <Text type="success" style={{fontSize: "13px"}}>{totalSoldeN.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={6} className='text-end'>
                                                        <Text type="success" style={{fontSize: "13px"}}>{totalSoldeN_1.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={7} className='text-end'>
                                                        <Text type="success" style={{fontSize: "13px"}}>{totalVariation.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={9} className='text-end'>
                                                        <Text type="success" style={{fontSize: "13px"}}>{totalPourcVariation.toLocaleString('fr')}%</Text>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </Table.Summary>
                                        </>
                                    );
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <div className="card-header mt-5">
                    <div className="card-title">
                        <Title style={{
                            display: "block",
                            fontWeight: '600',
                            color: appTheme.colors.primary_blue,
                            textAlign: "center"
                        }} level={4}>{libelle}</Title>
                    </div>
                </div>
            </ModalAlert>
        {isModalOpen && (
            <LivreFilteredModal
                handleVisibility={handleModalOpen}
                visible={isModalOpen}
                livres={livres}
                compte={compte}
                filtersItems={filtersItems}
                setFiltersItems={setFiltersItems}
                codeSommaire={codeSommaire}
                chargement={chargement || loading}
            />
        )}
    </>

    );
};

export {FeuilleMaitresse}