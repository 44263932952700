import React, {useEffect, useState} from "react";
import { BalanceProvisoireDefinitif } from "./EC3/controleProvDef/BalanceProvisoireDefinitif";
import swal from "sweetalert";
import {
    ajouterControleBalanceProvDef,
    listeControleBalanceProvDef
} from "../../../../../data/balance/procedureAnalytique";
import {HistoriqueBalanceProvisoireDefinitif} from "./EC3/controleProvDef/HistoriqueBalanceProvisoireDefinitif";
import {faHistory, faIdCard, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import PrintProvisoireDefinitif from "./EC3/controleProvDef/PrintProvisoireDefinition";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const ControleBalanceProvisoire = ({
       mission,
       client,
       code,
       // modalId,
       prev
    }) => {
    // const user = useSelector(state => state.user)
    const [activeTab, setActiveTab] = useState(0);
    const [balanceData, setBalanceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [codeSommaire] = useState(code || 'EC3.5');
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    const fetchControleBalanceProvDef = async (id) => {
        setLoading(true);
        listeControleBalanceProvDef(id)
            .then((res) => {
                if (res.result) {
                    setBalanceData(res.result);
                    setLoading(false);
                } else {
                    setBalanceData([])
                    setLoading(false);
                }
            })
    }

    useEffect(() => {
        if(missionSelected){
            fetchControleBalanceProvDef(missionSelected.id);
        }
    }, [missionSelected])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true);

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            balanceData:  balanceData,
            exercice_id: mission.id,
            client_id: client.id,
        };

        const {status, message} = await ajouterControleBalanceProvDef(data)
        if (status === 'success') {
            setAuth(false);
            setLoading(false)
            await swal(`Opération effectuée avec succès`, '', 'success');
            setIsTaskSave(prevState => (prevState ?? []).filter((item) => item !== activeTab));
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "CB",
            title: `CONTRÔLE DES BALANCES - ${selectYear}`,
            description: `CONTRÔLE DES BALANCES PROVISOIRES ET DEFINITIVE - ${selectYear}`,
            icon: faTasks,
            content: (
                <BalanceProvisoireDefinitif
                    mission={missionSelected}
                    client={client}
                    setBalanceData={setBalanceData}
                    balanceData={balanceData}
                    chargement={loading}
                    isProvisoire={isProvisoire}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
        {
            code: "H-CB",
            title: `HISTORIQUE CONTRÔLE D'INTANGIBILITÉ - ${selectYear}`,
            description: 'HISTORIQUE CONTRÔLE DES BALANCES PROVISOIRES ET DEFINITIVE',
            icon: faHistory,
            content: (
                <HistoriqueBalanceProvisoireDefinitif
                    balanceData={balanceData}
                    setBalanceData={setBalanceData}
                    mission={missionSelected}
                    chargement={loading}
                    isProvisoire={isProvisoire}
                    handleSubmit={handleSubmit}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
    ];

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EC3.5 - Contrôle balances provisoires et définitives"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isExcelPrint={false}
                    isLandingScape={true}
                    printTitle={(`CONTROLE DES BALANCES PROVISOIRES ET DEFINITIVES ${selectYear}`)}
                    excelData={balanceData}
                    printContent={(
                        <PrintProvisoireDefinitif
                            datas={balanceData}
                        />
                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>{formSteps[activeTab].content}</>
                </StepBody>
            </div>
        </>
    );
};

export { ControleBalanceProvisoire };