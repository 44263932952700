import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import { Identification } from "../../Identification";
import { TraitementControllesFonctionn } from "./fonctionnementControle/TraitementControllesFonction";
import {ajouterRisqueFonction} from "../../../../../data/risqueFonctionControle";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import {getDetailsRisqueEvaluationAll} from "../../../../../data/risqueEvaluation";
import {faIdCard, faSave, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {Button, Tooltip} from "antd";
import {appTheme} from "../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PrintFonctionnementControle from "./fonctionnementControle/PrintFonctionnementControle";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const FonctionnementControles = ({
         mission,
         client,
         code,
         // modalId,
         prev
     }) => {
    // const user = useSelector(state => state.user)
    const [activeTab, setActiveTab] = useState(0);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [codeSommaire] = useState(code || 'EE2');
    const [date, setDate] = useState('');
    const [isAddMemoire, setIsAddMemoire] = useState(false)
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [selectData, setSelectData] = useState([]);
    const [procedureData, setProcedureData] = useState([]);
    const [risqueIncidences, setRisqueIncidences] = useState([]);
    const [printData, setPrintData] = useState([]);
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(missionSelected.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [codeSommaire, activeTab, missionSelected])

    useEffect(() => {
        setLoading(true);
        getDetailsRisqueEvaluationAll(missionSelected.id).then((res) => {
            if (res.result.length > 0) {
                const arrayData = [];
                res.result.forEach((event) => {
                    event.Lea_RisqueIdentificationDetails?.forEach((detail) => {
                        const { Lea_RisqueIdentificationAssertions, Lea_RisqueEvaluations } = detail;
                        const assertions = Lea_RisqueIdentificationAssertions[0]?.assertions || [];
                        const postes = Lea_RisqueIdentificationAssertions[0]?.postes || [];
                        const probabiliteValue = Lea_RisqueEvaluations[0]?.probabiliteValue || '';
                        const impactValue = Lea_RisqueEvaluations[0]?.impactValue || '';
                        const score = Lea_RisqueEvaluations[0]?.score || 0;

                        const newSelectArray = {
                            id: detail.id,
                            incidence: detail.incidence,
                            postes: postes,
                            assertions: assertions,
                            probabiliteValue: probabiliteValue,
                            probabilite_id: Lea_RisqueEvaluations[0]?.probabilite_id || '',
                            impactValue: impactValue,
                            impact_id: Lea_RisqueEvaluations[0]?.impact_id || '',
                            score: score,
                            niveau_id: Lea_RisqueEvaluations[0]?.niveau_id || '',
                        };

                        arrayData.push(newSelectArray)
                    });
                });


                const updateItem = arrayData.filter((data) => data.impactValue * data.probabiliteValue > 5);

                setRisqueIncidences(updateItem);
            }
            setLoading(false);
        });
    }, [missionSelected]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const handleSubmit = async () => {
         setLoading(true);

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            client: client.id,
            controleData: selectData,
            codeSommaire: codeSommaire,
            isAddMemoire,
            isProvisoire,
            exercice_id: mission.id,
            client_id: client.id,
        };

        const {status, message} = await ajouterRisqueFonction(data)
        if (status === 'success') {
            setLoading(false)
            setAuth(false);
            await swal(`Opération effectuée avec succès`, '', 'success')
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "TJ",
            title: `EVALUATION DU FONCTIONNEMENTS DES CONTRÔLES - ${selectYear}`,
            description: `EE2 - Evaluation du fonctionnement des contrôles - ${selectYear}`,
            icon: faTasks,
            content: (
                <TraitementControllesFonctionn
                    setSelectedData={setSelectData}
                    selectedData={selectData}
                    setProcedureData={setProcedureData}
                    procedureData={procedureData}
                    risqueIncidences={risqueIncidences}
                    setIsAddMemoire={setIsAddMemoire}
                    setPrintData={setPrintData}
                    chargement={loading}
                    mission={missionSelected}
                    client={client}
                />
            ),
        },
    ];

    console.log("printData", printData)

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EE2 - Evaluation du fonctionnement des contrôles"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    printTitle={(`EE2 - Evaluation du fonctionnement des contrôles ${selectYear}`)}
                    excelData={selectData}
                    printContent={(
                        <PrintFonctionnementControle
                            datas={printData}
                        />
                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>
                        <div className="d-flex justify-content-end align-items-end mb-10">
                            <Tooltip title="Enregistrer">
                                <Button
                                    disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                                    size={"large"}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: appTheme.colors.secondary,
                                        color: "white"
                                    }}
                                    icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                    onClick={handleSubmit}
                                >
                                    {!loading &&
                                        <span className='indicator-label text-white'>Enregistrer</span>}
                                    {loading && (
                                        <span className='indicator-progress fs-9 text-white'
                                              style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </Button>
                            </Tooltip>
                        </div>
                        {formSteps[activeTab].content}
                    </>
                </StepBody>
            </div>
        </>
    );
}

export {FonctionnementControles}