import React, {useEffect, useState} from "react"
import swal from "sweetalert";
import {TraitementTestJournaux} from "./EC3/testJournaux/TraitementTestJournaux";
import {ajouterControleTestJournaux, listeControleTestJournaux} from "../../../../../data/balance/procedureAnalytique";
import {faIdCard, faSave, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {Button, Tooltip, Typography} from "antd";
import {appTheme} from "../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableJournaux from "./EC3/testJournaux/TableJournaux";
import UserCycleIdentification from "../../UserCycleIdentification";
import {useNavigate} from "react-router-dom";

const {  Title } = Typography;

const ControleTestJournaux = ({
          mission,
          client,
          code,
          // modalId,
          prev
      }) => {
    // const user = useSelector(state => state.user)
    const [activeTab, setActiveTab] = useState(0);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [codeSommaire] = useState(code || 'EC3.4');
    const [date, setDate] = useState('');
    const [isAddMemoire, setIsAddMemoire] = useState(false)
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [conclusion, setConclusion] = useState('');
    const [selectedData, setSelectedData] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    useEffect(()=> {
        setLoading(true)
        listeControleTestJournaux(missionSelected.id)
            .then((res) => {
                if(res.result){
                    const risque = res.result.find((item) => item.codeSommaire === codeSommaire)
                    if(risque) {
                        setSelectedData(risque.Lea_RisqueTexte_BlanchimentQuestions);
                        setConclusion(risque.conclusion);
                    }
                }
                setLoading(false)
            })
    }, [missionSelected, codeSommaire])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const handleSubmit = async () => {
        setLoading(true);

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            isAddMemoire,
            isProvisoire,
            codeSommaire,
            datas: selectedData,
            conclusion,
            exercice_id: mission.id,
            client_id: mission.client_id,
        };

        const {status, message} = await ajouterControleTestJournaux(data)
        if (status === 'success') {
            setLoading(false)
            setAuth(false);
            await swal(`Opération effectuée avec succès`, '', 'success');
            setIsTaskSave(prevState => (prevState ?? []).filter((item) => item !== activeTab));
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "OB",
            title: "OBJECTIFS",
            icon: faTasks,
            content: (
                <>
                    <div className="d-flex justify-content-center align-items-center p-15">
                        <Title className="my-5 mx-auto text-center" style={{display: "block", fontWeight: '600', }} level={4}>
                            Déterminer si des anomalies significatives (fraudes ou erreurs) ont été commises à la suite de l'utilisation d'écritures inappropriées, fictives ou non autorisées.
                        </Title>
                    </div>
                </>
            ),
        },
        {
            code: "TJ",
            title: `TEST DES JOURNAUX ET DES EVENNEMENTS INHABITUELS - ${selectYear}`,
            description: `TEST DES JOURNAUX ET DES EVENNEMENTS INHABITUELS - ${selectYear}`,
            icon: faTasks,
            content: (
                <TraitementTestJournaux
                    selectItems={selectedData}
                    setSelectItems={setSelectedData}
                    setIsAddMemoire={setIsAddMemoire}
                    isAddMemoire={isAddMemoire}
                    conclusion={conclusion}
                    setConclusion={setConclusion}
                    chargement={loading}
                    mission={missionSelected}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
    ];

   return (
    <>
        <div className="mx-5">
            <PageToolbarHeader
                prev={prev}
                pageTitle={"EC3.4 – TEST DES JOURNAUX ET DES EVENNEMENTS INHABITUELS"}
                mission={missionSelected}
                setMission={setMissionSelected}
                isProvisoire={isProvisoire}
                setIsProvisoire={setIsProvisoire}
                selectYear={selectYear}
                setSelectYear={setSelectYear}
                loading={loading}
                setLoading={setLoading}
                client={client}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                setDate={setDate}
                setTache_id={setTache_id}
                tache_id={tache_id}
                setIntervention_id={setIntervention_id}
                intervention_id={intervention_id}
                setHeureEntree={setHeureEntree}
                setUser_id={setUser_id}
                isExcelPrint={false}
                isLandingScape={true}
                printTitle={(`TEST DES JOURNAUX ET DES EVENNEMENTS INHABITUELS ${selectYear}`)}
                excelData={selectedData}
                printContent={(
                    <TableJournaux
                        objectif={"Déterminer si des anomalies significatives (fraudes ou erreurs) ont été commises à la suite de l'utilisation d'écritures inappropriées, fictives ou non autorisées."}
                        datas={selectedData}
                        conclusion={conclusion}
                    />
                )}
            />
            <StepHeader>
                <ModalNavBarMenu
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    steps={formSteps}
                    setLoading={setLoading}
                    loading={loading}
                    isTaskSave={isTaskSave}
                    auth={auth}
                />
            </StepHeader>
            <StepBody>
                <>
                    <div className="d-flex justify-content-end align-items-end mb-10">
                        <Tooltip title="Enregistrer">
                            <Button
                                disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                                size={"large"}
                                // loading={loading}
                                style={{
                                    backgroundColor: appTheme.colors.secondary,
                                    color: "white"
                                }}
                                icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                onClick={handleSubmit}
                            >
                                {!loading &&
                                    <span className='indicator-label text-white'>Enregistrer</span>}
                                {loading && (
                                    <span className='indicator-progress fs-9 text-white'
                                          style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </Button>
                        </Tooltip>
                    </div>
                    {formSteps[activeTab].content}
                </>
            </StepBody>
        </div>
    </>
   );
}

export {ControleTestJournaux}