import axios from 'axios'
import {
    AJOUTER_IMPACT,
    LISTE_IMPACT, LISTE_IMPACT_ACTIVE,  
    MODIFIER_IMPACT,
    MODIFIER_ETAT_IMPACT,  SUPPRIMER_IMPACT
} from '../../api/apiRoutes'

export const addImpact = async (data) => {
    return axios.post(AJOUTER_IMPACT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateImpact = async (data) => {
    return axios.post(MODIFIER_IMPACT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeImpact = async () => {
    return axios.get(LISTE_IMPACT).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeImpactActive = async () => {
    return axios.get(LISTE_IMPACT_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteImpact = async (data) => {
    return axios.post(SUPPRIMER_IMPACT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatImpact = async (data) => {
    return axios.post(MODIFIER_ETAT_IMPACT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}