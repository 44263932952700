import clsx from "clsx";
import React from "react";

const AutresInfos = ({formik}) => {

    return (
        <>
            <div className="card card-flush">
                {/*begin::Card header*/}
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    {/*begin::Card title*/}
                    <div className="card-title">
                        {/*begin::Title*/}
                        <h2>Autres informations</h2>
                        {/*end::Title*/}
                    </div>
                    {/*end::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                    {/*begin::Form*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7 fv-plugins-icon-container">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Actionnaire ou Associé majoritaire</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('actionnaire_majoritaire')}
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.actionnaire_majoritaire && formik.errors.actionnaire_majoritaire},
                                        {
                                            'is-valid': formik.touched.actionnaire_majoritaire && !formik.errors.actionnaire_majoritaire,
                                        }
                                    )}
                                    name="actionnaire_majoritaire"
                                    value={formik.values.actionnaire_majoritaire}
                                />
                                {/*end::Input*/}
                                {formik.touched.actionnaire_majoritaire && formik.errors.actionnaire_majoritaire && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.actionnaire_majoritaire}</div>
                                )}
                            </div>
                        </div>

                        <div className="row fv-row mb-7 fv-plugins-icon-container">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Nom du dirigeant (PCA/PDG/ Adm. GI/Gérant)</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('nom_dirigeant')}
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.nom_dirigeant && formik.errors.nom_dirigeant},
                                        {
                                            'is-valid': formik.touched.nom_dirigeant && !formik.errors.nom_dirigeant,
                                        }
                                    )}
                                    type="text"
                                    name="nom_dirigeant"
                                    value={formik.values.nom_dirigeant}
                                />
                                {/*end::Input*/}
                                {formik.touched.nom_dirigeant && formik.errors.nom_dirigeant && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.nom_dirigeant}</div>
                                )}
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Expert comptable</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('expert_comptable')}
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.expert_comptable && formik.errors.expert_comptable},
                                        {
                                            'is-valid': formik.touched.expert_comptable && !formik.errors.expert_comptable,
                                        }
                                    )}
                                    type="text"
                                    name="expert_comptable"
                                    value={formik.values.expert_comptable}
                                />
                                {/*end::Input*/}
                                {formik.touched.expert_comptable && formik.errors.expert_comptable && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.expert_comptable}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Conseil juridique</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('expert_comptable')}
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.conseil_juridique && formik.errors.conseil_juridique},
                                        {
                                            'is-valid': formik.touched.conseil_juridique && !formik.errors.conseil_juridique,
                                        }
                                    )}
                                    name='conseil_juridique'
                                    type='text'
                                    value={formik.values.conseil_juridique}
                                />
                                {/*end::Input*/}
                                {formik.touched.conseil_juridique && formik.errors.conseil_juridique && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.conseil_juridique}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Co-Commissaire aux Comptes  </span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('commissaire_compte')}
                                    className='form-control form-control-solid'
                                    name='commissaire_compte'
                                    type='text'
                                    value={formik.values.commissaire_compte}
                                />
                                {/*end::Input*/}
                                {formik.touched.commissaire_compte && formik.errors.commissaire_compte && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.commissaire_compte}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Associé signataire des rapports</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('associe_signataire')}
                                    className='form-control form-control-solid'
                                    name='associe_signataire'
                                    type='text'
                                    value={formik.values.associe_signataire}
                                />
                                {/*end::Input*/}
                                {formik.touched.associe_signataire && formik.errors.associe_signataire && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.associe_signataire}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}

                        <div></div>
                    {/*end::Form*/}
                    </div>
                </div>
                {/*end::Card body*/}
            </div>
        </>
    )
}

export {AutresInfos}