import axios from 'axios'
import {
    AJOUTER_PROBABILITE,
    LISTE_PROBABILITE, LISTE_PROBABILITE_ACTIVE,  
    MODIFIER_PROBABILITE,
    MODIFIER_ETAT_PROBABILITE,  SUPPRIMER_PROBABILITE
} from '../../api/apiRoutes'

export const addProbabilite = async (data) => {
    return axios.post(AJOUTER_PROBABILITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateProbabilite = async (data) => {
    return axios.post(MODIFIER_PROBABILITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeProbabilite = async () => {
    return axios.get(LISTE_PROBABILITE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeProbabiliteActive = async () => {
    return axios.get(LISTE_PROBABILITE_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteProbabilite = async (data) => {
    return axios.post(SUPPRIMER_PROBABILITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatProbabilite = async (data) => {
    return axios.post(MODIFIER_ETAT_PROBABILITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}