import axios from 'axios'
import { AJOUTER_SECTEUR_ACTIVITE, LISTE_SECTEUR_ACTIVITE, MODIFIER_ETAT_SECTEUR_ACTIVITE, MODIFIER_SECTEUR_ACTIVITE, SUPPRIMER_SECTEUR_ACTIVITE } from '../../api/apiRoutes'

export const addSecteur = async (data) => {
    return axios.post(AJOUTER_SECTEUR_ACTIVITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateSecteur = async (data) => {
    return axios.post(MODIFIER_SECTEUR_ACTIVITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeSecteur = async () => {
    return axios.get(LISTE_SECTEUR_ACTIVITE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteSecteur = async (data) => {
    return axios.post(SUPPRIMER_SECTEUR_ACTIVITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatSecteur = async (data) => {
    return axios.post(MODIFIER_ETAT_SECTEUR_ACTIVITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}