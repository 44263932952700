import {Navigate, Route, Routes} from 'react-router-dom'
import MainLayout from "../layout/MainLayout";
import DashBoardPage from "../views/dashboard/DashBoardPage";
import NotFoundPage from "../views/not_found/NotFoundPage";
import UsersPage from "../views/users/UserPages";
import ParametrePage from "../views/params/ParametrePage";
import ClientsPage from "../views/clients/clientsPage";
import { PageTitle } from '../components/layout/pageData/PageData';
import { CountEdit } from '../views/users/user/CountEdit';
import MenuClient from "../views/clients/MenuClient";
import Outils from "../views/outils/Outils";
import {ClientDetails} from "../views/clients/client/ClientView";
import ExercicesPage from "../views/exercices/ExercicesPage";


const PrivateRoutes = () => {

    const usersBreadcrumbs = [
        {
            title: 'Accueil',
            path: '/tableau-de-bord',
            isSeparator: false,
            isActive: false,
        },

    ]

    return (
        <Routes>
            <Route element={<MainLayout />}>
                <Route path='login/*' element={<Navigate to='/tableau-de-bord' />} />
                {/*<Route path='auth/forgot-password' element={<Navigate to='/auth/forgot-password' />} />*/}
                {/*begin::Pages */}
                <Route path="/tableau-de-bord" element={
                    <>
                        <PageTitle breadcrumbs={usersBreadcrumbs}>Tableau de bord</PageTitle>
                        <DashBoardPage />
                    </>
                } />

                {/*begin::Users*/}
                <Route path="/utilisateurs/*" element={<UsersPage />} />
                {/*end::Users*/}



                {/*begin::clients*/}
                <Route path="/clients-menu" element={<MenuClient />} />
                <Route path="/clients/*" element={<ClientsPage />} />
                <Route path="/clients/details/:id" element={<ClientDetails />} />
                {/*end::clients*/}

                {/*begin::explorateurs*/}
                <Route path="/client/exercice/*" element={<ExercicesPage />} />
                {/*end::explorateurs*/}

                {/*begin::Paramétrage*/}
                <Route path="/parametre/*" element={<ParametrePage />} />
                {/*end::Paramétrage*/}


                {/*begin::Count*/}
                {/* <Route path="/profil/*" element={<CountEdit />} /> */}
                <Route path="/outils" element={<Outils />} />

                <Route path="/profil" element={
                    <>
                        <PageTitle breadcrumbs={usersBreadcrumbs}>Mon profil</PageTitle>
                        <CountEdit />
                    </>
                } />
                {/*end::Count*/}

                {/*end::Pages */}

                {/* Page Not Found */}
                <Route path='*' element={<NotFoundPage />} />
            </Route>
        </Routes>
    )
}

export {PrivateRoutes}
