import React, { useEffect, useState } from "react";
import { Identification } from "../../Identification";
import { TraitementProcedures } from "./proceduresModal/procedureAudit/TraitementProcedures";
import { PROCEDURE_AUDIT } from "./proceduresModal/procedureAudit/ProcedureAuditData"
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import {faIdCard, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import PrintConceptionControle from "../EE1/conceptionControle/PrintConceptionControle";
import {PrintProcedureAudit} from "./proceduresModal/procedureAudit/PrintProcedureAudit";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const ControleProceduresAudit = ({
             mission,
             client,
             code,
             modalId,
             prev
         }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [codeSommaire] = useState(code || `EE3.${modalId}`);
    const [date, setDate] = useState('');
    const [isAddMemoire, setIsAddMemoire] = useState(false)
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();
    const [libelle, setLibelle] = useState('')
    const [tabMaitresse, setTabMaitresse] = useState([])
    const [objectifs, setObjectifs] = useState([])
    const [bilan, setBilan] = useState([])
    const [compteResultat, setCompteResultat] = useState([])
    const [etatAnnexe, setEtatAnnexe] = useState([])
    const [printData, setPrintData] = useState([]);
    const [conclusionGenerale, setConclusionGenerale] = useState('');
    const [observation, setObservation] = useState('')
    const [descriptionsData, setDescriptionsData] = useState([]);
    const [totalsData, setTotalsData] = useState([
        {
            id: 1,
            libelle: "Total des anomalie identifiées lors de la mission d'audit",
            resultatProduit: 0,
            actif: 0,
            passif: 0,
            capitaux: 0,
        },
        {
            id: 2,
            libelle: "Anomalies corrigées par la Direction",
            resultatProduit: 0,
            actif: 0,
            passif: 0,
            capitaux: 0,
        },
        {
            id: 3,
            libelle: "Total des anomalies non corrigées",
            resultatProduit: 0,
            actif: 0,
            passif: 0,
            capitaux: 0,
        },
    ])

    useEffect(() => {
        switch (modalId) {
            case 1:
                setTabMaitresse(PROCEDURE_AUDIT.ImIncorporelles.travaux)
                setObjectifs(PROCEDURE_AUDIT.ImIncorporelles.objectifs)
                setBilan(PROCEDURE_AUDIT.ImIncorporelles.bilan)
                setCompteResultat(PROCEDURE_AUDIT.ImIncorporelles.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.ImIncorporelles.etatAnnexe)
                return setLibelle('Immobilisation incorporelles')
            case 2:
                setObjectifs(PROCEDURE_AUDIT.Corporelles.objectifs)
                setBilan(PROCEDURE_AUDIT.Corporelles.bilan)
                setCompteResultat(PROCEDURE_AUDIT.Corporelles.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.Corporelles.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.Corporelles.travaux)
                return  setLibelle('Immobilisation corporelles');
            case 3:
                setObjectifs(PROCEDURE_AUDIT.ImFinanciere.objectifs)
                setBilan(PROCEDURE_AUDIT.ImFinanciere.bilan)
                setCompteResultat(PROCEDURE_AUDIT.ImFinanciere.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.ImFinanciere.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.ImFinanciere.travaux)
               return setLibelle('Immobilisation financières');
            case 4:
                setObjectifs(PROCEDURE_AUDIT.Stocks.objectifs)
                setBilan(PROCEDURE_AUDIT.Stocks.bilan)
                setCompteResultat(PROCEDURE_AUDIT.Stocks.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.Stocks.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.Stocks.travaux)
                return   setLibelle('Stocks');
            case 5:
                setObjectifs(PROCEDURE_AUDIT.Ventes_client.objectifs)
                setBilan(PROCEDURE_AUDIT.Ventes_client.bilan)
                setCompteResultat(PROCEDURE_AUDIT.Ventes_client.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.Ventes_client.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.Ventes_client.travaux)
                return setLibelle('Ventes clients');
            case 6:
                setObjectifs(PROCEDURE_AUDIT.creance_dette.objectifs)
                setBilan(PROCEDURE_AUDIT.creance_dette.bilan)
                setCompteResultat(PROCEDURE_AUDIT.creance_dette.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.creance_dette.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.creance_dette.travaux)
                return  setLibelle('Autres créances et dettes');
            case 7: 
                setObjectifs(PROCEDURE_AUDIT.tresorerie.objectifs)
                setBilan(PROCEDURE_AUDIT.tresorerie.bilan)
                setCompteResultat(PROCEDURE_AUDIT.tresorerie.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.tresorerie.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.tresorerie.travaux)
                return  setLibelle('Trésorerie');
            case 8: 
                setObjectifs(PROCEDURE_AUDIT.capitaux_propres.objectifs)
                setBilan(PROCEDURE_AUDIT.capitaux_propres.bilan)
                setCompteResultat(PROCEDURE_AUDIT.capitaux_propres.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.capitaux_propres.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.capitaux_propres.travaux)
               return setLibelle('Capitaux propres');
            case 9: 
                setObjectifs(PROCEDURE_AUDIT.provision_risques.objectifs)
                setBilan(PROCEDURE_AUDIT.provision_risques.bilan)
                setCompteResultat(PROCEDURE_AUDIT.provision_risques.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.provision_risques.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.provision_risques.travaux)
                return  setLibelle('Provisions risques');
            case 10: 
                setObjectifs(PROCEDURE_AUDIT.emprunt_assimiles.objectifs)
                setBilan(PROCEDURE_AUDIT.emprunt_assimiles.bilan)
                setCompteResultat(PROCEDURE_AUDIT.emprunt_assimiles.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.emprunt_assimiles.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.emprunt_assimiles.travaux)
                return setLibelle('Emprunts et assimilés');
            case 11: 
                setObjectifs(PROCEDURE_AUDIT.achat_fournisseur.objectifs)
                setBilan(PROCEDURE_AUDIT.achat_fournisseur.bilan)
                setCompteResultat(PROCEDURE_AUDIT.achat_fournisseur.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.achat_fournisseur.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.achat_fournisseur.travaux)
               return setLibelle('Achat fournisseurs');
            case 12: 
                setObjectifs(PROCEDURE_AUDIT.impot_taxes.objectifs)
                setBilan(PROCEDURE_AUDIT.impot_taxes.bilan)
                setCompteResultat(PROCEDURE_AUDIT.impot_taxes.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.impot_taxes.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.impot_taxes.travaux)
               return setLibelle('Impôts et Taxes');
            case 13: 
                setObjectifs(PROCEDURE_AUDIT.impot_taxes.objectifs)
                setBilan(PROCEDURE_AUDIT.paie_personnel.bilan)
                setCompteResultat(PROCEDURE_AUDIT.paie_personnel.compteResultat)
                setEtatAnnexe(PROCEDURE_AUDIT.paie_personnel.etatAnnexe)
                setTabMaitresse(PROCEDURE_AUDIT.paie_personnel.travaux)
                return setLibelle('Paie personnel');
            case 14: 
               setObjectifs(PROCEDURE_AUDIT.controle_tft.objectifs)
               return setLibelle('Contrôle du tableau des flux de Trésorerie (TFT)');
           
            default:
               return setLibelle('Immobilisation incorporelles')
        }
    }, [modalId] )

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(missionSelected.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [codeSommaire, activeTab, missionSelected])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if ((isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [isTaskSave, navigate]);

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "TJ",
            title: `EE3.${modalId} - ${libelle} - ${selectYear}`,
            description: `EE3.${modalId} - ${libelle} - ${selectYear}`,
            icon: faTasks,
            content: (
                <TraitementProcedures
                    tabMaitresse={tabMaitresse}
                    modalId={modalId}
                    menuLibelle={libelle}
                    objectifs={objectifs}
                    bilan={bilan}
                    setPrintData={setPrintData}
                    compteResultat={compteResultat}
                    etatAnnexe={etatAnnexe}
                    code={codeSommaire}
                    isProvisoire={isProvisoire}
                    setIsAddMemoire={setIsAddMemoire}
                    isAddMemoire={isAddMemoire}
                    totalsData={totalsData}
                    setTotalsData={setTotalsData}
                    conclusionGenerale={conclusionGenerale}
                    setConclusionGenerale={setConclusionGenerale}
                    descriptionsData={descriptionsData}
                    setDescriptionsData={setDescriptionsData}
                    setObservation={setObservation}
                    observation={observation}
                    chargement={loading}
                    mission={missionSelected}
                    client={client}
                    setIsTaskSave={setIsTaskSave}
                    isTaskSave={isTaskSave}
                    procedure_id={modalId}
                />
            ),
        },
    ];

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={`EE3.${modalId} - ${libelle}`}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isExcelPrint={false}
                    isLandingScape={true}
                    printTitle={(`EE3.${modalId} - ${libelle} - ${selectYear}`)}
                    excelData={[]}
                    printContent={(
                        <PrintProcedureAudit
                            objectifs={objectifs}
                            bilan={bilan}
                            compteResultat={compteResultat}
                            travauxAudit={printData}
                            travauxAjustement={descriptionsData}
                            travauxAjustementTotal={totalsData}
                            conclusion={conclusionGenerale}
                            observation={observation}
                        />
                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>
                        {formSteps[activeTab].content}
                    </>
                </StepBody>
            </div>
            {/*{(isModalOpen && modalId === 7) && (*/}
            {/*    <div*/}
            {/*        className="top-0 bottom-0 z-index-1000 h-100"*/}
            {/*        style={{ insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}*/}
            {/*    >*/}
            {/*        <div className="modal fade show" id="kt_modal_move_to_folder" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}>*/}
            {/*            <div className='modal-dialog modal-fullscreen p-9' >*/}
            {/*                <FeuilleMaitresse*/}
            {/*                    handleModalClose={handleModalClose}*/}
            {/*                    mission={mission}*/}
            {/*                    tabMaitresse={tabMaitresse}*/}
            {/*                    client={client}*/}
            {/*                    menuLibelle={libelle}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
        </>
    );
}

export {ControleProceduresAudit}