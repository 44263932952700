export const NotesData = [
    {
        "id": 1,
        "note": "NOTE 1",
        "intitule": "DETTES GARANTIES PAR DES SURETES REELLES"
    },
    {
        "id": 2,
        "note": "NOTE 2",
        "intitule": "INFORMATIONS OBLIGATOIRES"
    },
    {
        "id": 3,
        "note": "NOTE 3A",
        "intitule": "IMMOBILISATION BRUTE"
    },
    {
        "id": 4,
        "note": "NOTE 3B",
        "intitule": "IMMOBILISATION PRISE EN LOCATION ACQUISITION"
    },
    {
        "id": 5,
        "note": "NOTE 3C",
        "intitule": "IMMOBILISATION : AMORTISSEMENTS"
    },
    {
        "id": 6,
        "note": "NOTE 3C BIS",
        "intitule": "IMMOBILISATION (DEPRECIATIONS)"
    },
    {
        "id": 7,
        "note": "NOTE 3D",
        "intitule": "IMMOBILISATION : PLUS-VALUES ET MOINS VALUE DE CESSION"
    },
    {
        "id": 8,
        "note": "NOTE 3E",
        "intitule": "INFORMATIONS SUR LES REEVALUATIONS EFFECTUEES PAR L'ENTITE"
    },
    {
        "id": 9,
        "note": "NOTE 4",
        "intitule": "IMMOBILISATIONS FINANCIERES"
    },
    {
        "id": 10,
        "note": "NOTE 5",
        "intitule": "ACTIF CIRCULANT HAO"
    },
    {
        "id": 11,
        "note": "NOTE 6",
        "intitule": "STOCKS ET EN COURS (1)"
    },
    {
        "id": 12,
        "note": "NOTE 7",
        "intitule": "CLIENTS"
    },
    {
        "id": 13,
        "note": "NOTE 8",
        "intitule": "AUTRES CREANCES"
    },
    {
        "id": 14,
        "note": "NOTE 8A",
        "intitule": "TABLEAU D'ETATEMENT DES CHARGES IMMOBILISEES"
    },
    {
        "id": 15,
        "note": "NOTE 8B",
        "intitule": "TABLEAU D'ETATEMENT DES PROVISIONS POUR CHARGES A REPARTIR"
    },
    {
        "id": 16,
        "note": "NOTE 9",
        "intitule": "TITRES DE PLACEMENT"
    },
    {
        "id": 17,
        "note": "NOTE 10",
        "intitule": "VALEURS A ENCAISSER"
    },
    {
        "id": 18,
        "note": "NOTE 11",
        "intitule": "DISPONIBILITES"
    },
    {
        "id": 19,
        "note": "NOTE 12",
        "intitule": "ECARTS DE CONVERSION"
    },
    {
        "id": 20,
        "note": "NOTE 13",
        "intitule": "CAPITAL : VALEUR NOMINALE DES ACTIONS OU PARTS"
    },
    {
        "id": 21,
        "note": "NOTE 14",
        "intitule": "PRIMES ET RESERVES"
    },
    {
        "id": 22,
        "note": "NOTE 15A",
        "intitule": "SUBVENTIONS ET PROVISIONS REGLEMENTEES "
    },
    {
        "id": 23,
        "note": "NOTE 15B",
        "intitule": "AUTRES FONDS PROPRES "
    },
    {
        "id": 25,
        "note": "NOTE 16A",
        "intitule": "DETTES FINANCIERES ET RESSOURCES ASSIMILEES"
    },
    {
        "id": 26,
        "note": "NOTE 16B",
        "intitule": "ENGAGEMENTS DE RETRAITE ET AVANTAGES ASSIMILES (METHODE ACTUARIELLE)"
    },
    {
        "id": 27,
        "note": "NOTE 16B BIS",
        "intitule": "ENGAGEMENTS DE RETRAITE ET AVANTAGES ASSIMILES"
    },
    {
        "id": 28,
        "note": "NOTE 16C",
        "intitule": "ACTIFS ET PASSIFS EVENTUELS"
    },
    {
        "id": 29,
        "note": "NOTE 17",
        "intitule": "FOURNISSEURS D'EXPLOITATION"
    },
    {
        "id": 30,
        "note": "NOTE 18",
        "intitule": "DETTES FISCALES ET SOCIALES"
    },
    {
        "id": 31,
        "note": "NOTE 19",
        "intitule": "AUTRES DETTES ET PROVISIONS POUR RISQUES A COURT TERME"
    },
    {
        "id": 32,
        "note": "NOTE 20",
        "intitule": "BANQUES, CREDIT D'ESCOMPTE ET DE TRESORERIE"
    },
    {
        "id": 33,
        "note": "NOTE 21",
        "intitule": "CHIFFRE D'AFFAIRES ET AUTRES PRODUITS"
    },
    {
        "id": 34,
        "note": "NOTE 22",
        "intitule": "ACHATS"
    },
    {
        "id": 35,
        "note": "NOTE 23",
        "intitule": "TRANSPORTS"
    },
    {
        "id": 36,
        "note": "NOTE 24",
        "intitule": "SERVICES EXTERIEURS"
    },
    {
        "id": 37,
        "note": "NOTE 25",
        "intitule": "IMPOTS ET TAXES"
    },
    {
        "id": 38,
        "note": "NOTE 26",
        "intitule": "AUTRES CHARGES"
    },
    {
        "id": 39,
        "note": "NOTE 27A",
        "intitule": "CHARGES DE PERSONNEL"
    },
    {
        "id": 40,
        "note": "NOTE 27B",
        "intitule": "EFFECTIFS, MASSE SALARIALE ET PERSONNEL EXTERIEUR"
    },
    {
        "id": 41,
        "note": "NOTE 28",
        "intitule": "DOTATIONS ET CHARGES POUR PROVISIONS ET DEPRECIATIONS"
    },
    {
        "id": 42,
        "note": "NOTE 29",
        "intitule": "CHARGES ET REVENUS FINANCIERS"
    },
    {
        "id": 43,
        "note": "NOTE 30",
        "intitule": "AUTRES CHARGES ET PRODUITS HAO"
    },
    {
        "id": 44,
        "note": "NOTE 31",
        "intitule": "REPARTITION DU RESULTAT ET AUTRES ELEMENTS CARACTERISTIQUES DES CINQ DERNIERS EXERCICES"
    },
    {
        "id": 45,
        "note": "NOTE 32",
        "intitule": "DOTATION DE L'EXERCICE"
    },
    {
        "id": 46,
        "note": "NOTE 33",
        "intitule": "ACHATS DESTINES A LA PRODUCTION"
    },
    {
        "id": 47,
        "note": "NOTE 34",
        "intitule": "FICHE DE SYNTHESE DES PRINCIPAUX INDICATEURS FINANCIERS"
    },
    {
        "id": 48,
        "note": "NOTE 35",
        "intitule": "LISTE DES INFORMATIONS SOCIALES, ENVIRONNEMENTALES ET SOCIETALES A FOURNIR"
    },
    {
        "id": 49,
        "note": "NOTE 36",
        "intitule": "TABLEAUX DES CODES"
    },
    {
        "id": 50,
        "note": "NOTE 37",
        "intitule": "DETERMINATION IMPOT SUR RESULTAT (2)"
    },
    {
        "id": 51,
        "note": "NOTE 38",
        "intitule": "EVENEMENTS POSTERIEURS A LA CLOTURE DE L'EXERCICE"
    },
    {
        "id": 52,
        "note": "NOTE 39",
        "intitule": "CHANGEMENTS DE METHODES COMPTABLES, D'ESTIMATIONS ET CORRECTIONS D'ERREURS"
    },
    {
        "id": 53,
        "note": "NOTE DGI",
        "intitule": "NOTES SUPPLEMENTAIRES DGI"
    }
]
