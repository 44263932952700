import React, {useEffect, useState} from 'react';
import {Alert, Button, Modal, Popconfirm, Space, Table, Tag} from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    LockOutlined,
    PlusOutlined, UnlockOutlined,
} from '@ant-design/icons';
import Swal from 'sweetalert2'
import swal from 'sweetalert'
import {addRole, deleteRole, getListeRole, updateEtatRole, updateRole} from "../../../data/params/role";
import {optionsSwal} from "../../../helpers/AssetsHelpers";
import {DateUserListe} from "../../../components/layout/pageData/DateUserListe";
import {ExportHeader, SearchHeader} from "../../../components/layout/header/HeaderList";


const RolesList = () => {
    // const record = useSelector(state => state.record)
    const [roles, setRoles] = useState([])
    const [role, setRole] = useState([])
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [libelle, setLibelle] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [edite, setEdite] = useState(false);
    const [filtersItems, setFiltersItems] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
    });

    const fetchRoles = () => {
        setLoading(true);
        getListeRole()
            .then((res) => {
                setRoles(res);
                setFiltersItems(res)
                handleCancel()
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchRoles();
    }, [setRoles]);


    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = roles.filter(
            (item) =>
                item.libelle.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.id === parseInt(searchTerm)
        );
        setFiltersItems(filtered);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setRoles([]);
        }
    };
    const showModal = () => {
        setOpen(true);
    };
    const handleAddRole = async () => {
        setLoading(true);
        const data = {libelle, description}
        console.log(data)
        const {status, message} = await addRole(data)
        setMessage(message)
        if (status === 'success') {
            setError(false)
            setLibelle('')
            setDescription('')
            fetchRoles()
            setLoading(false)
        } else {
            setError(true)
            setLoading(false)
        }
    };

    const handleModifierRole = async () => {
        setLoading(true);
        const data = {role_id: role.id, libelle, description}
        const {status, message} = await updateRole(data)
        setMessage(message)
        if (status === 'success') {
            setError(false)
            setLibelle('')
            setDescription('')
            fetchRoles()
            setLoading(false)
        } else {
            setError(true)
            setLoading(false)
        }
    };

    const handleDeleteRole = async (role_id) => {
        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const {status, message} = await deleteRole({role_id})
                if (status === 'error') {
                    await swal(`${message}`, '', 'warning')
                }
                setMessage(message)
                if (status === 'success') {
                    setError(false)
                    setLoading(false)
                    fetchRoles()
                } else {
                    setError(true)
                    setLoading(false)
                }
            }
        })
    }

    const handleUpdateEtatRole = async (role_id, etat) => {
        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const data = {role_id, etat}
                const {status, message} = await updateEtatRole(data)
                if (status === 'error') {
                    await swal(`${message}`, '', 'warning')
                }
                setMessage(message)
                if (status === 'success') {
                    setError(false)
                    setLibelle('')
                    setDescription('')
                    fetchRoles()
                    setLoading(false)
                } else {
                    setError(true)
                    setLoading(false)
                }
            }
        })
    }

    const handleCancel = () => {
        setOpen(false);
        setEdite(false)
        setLibelle('')
        setDescription('')
        setError(false)
    };

    const selectRole = (role_id) => {
        const privil = roles.filter(periode => periode.id === role_id)[0]
        setRole(privil)
        setLibelle(privil.libelle)
        setDescription(privil.description)
        setEdite(true)
        setOpen(true);
    }

    const columns = [
        {
            title: '#',
            render: (text, record, index) => <span className='display-block chart-blue'>{index + 1}</span>
        },
        {
            title: 'Libelle',
            dataIndex: 'libelle',
            key: 'libelle',
            render: (text) => <span className='display-block bold'>{text}</span>,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Etat',
            dataIndex: 'etat_role',
            key: 'etat_role',
            render: (_, record) => record.etat === 1 ? <Tag color="green">Activé</Tag> :
                <Tag color="red">Désactivé</Tag>
        },
        {
            title: 'Créée le / Par',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record, index) => <DateUserListe date={record.createdAt}
                                                         user={record.nom_user + " " + record.prenom_user} key={index}/>
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        icon={<EditOutlined/>}
                        onClick={() => selectRole(record.id)}
                    />

                    {record.etat_role === 0 && <Popconfirm
                        title={`Activer la période ${record.libelle} ?`}
                        description={`Voulez vous vraiment activer la période ${record.libelle} ?`}
                        onConfirm={() => handleUpdateEtatRole(record.id, 1)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            icon={<UnlockOutlined/>}
                            className='chart-bg-orange'
                            title='Activer ?'
                        />
                    </Popconfirm>}

                    {record.etat_role === 1 && <Popconfirm
                        title={`Désactiver la période ${record.libelle} ?`}
                        description={`Voulez vous vraiment désactiver la période ${record.libelle} ?`}
                        onConfirm={() => handleUpdateEtatRole(record.id, 0)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            icon={<LockOutlined/>}
                            className='chart-bg-green'
                            title='Désactiver ?'
                        />
                    </Popconfirm>}

                    <Popconfirm
                        title={`Supprimer la période ${record.libelle} ?`}
                        description={`Voulez vous vraiment supprimer la période ${record.libelle} ?`}
                        onConfirm={() => handleDeleteRole(record.id)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            title='Supprimer ?'
                            type="primary"
                            icon={<DeleteOutlined/>}
                            danger
                        />
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    return (
        <>
            <div className="card w-100">
                {error ? (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{message}</div>
                    </div>
                ) : (
                    ''
                )}
                <div className="card-header border-0 pt-6">
                    <SearchHeader handleSearch={handleSearchChange}/>
                    <div className="card-toolbar">
                        <div className="d-flex justify-content-end" data-kt-record-table-toolbar="base">
                            <ExportHeader/>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_add_user"
                                onClick={showModal}
                            >
                                <span className="svg-icon svg-icon-2">
                                    <PlusOutlined style={{fontSize: '2px'}}/>
                                </span>
                                Nouveau
                            </button>

                        </div>
                    </div>
                </div>
                <div className="card-body py-4">
                    <div className="table-responsive">
                        <Table
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={filtersItems}
                            pagination={tableParams.pagination}
                            loading={loading}
                            onChange={handleTableChange}
                        />
                    </div>
                </div>
            </div>

            <Modal
                open={open}
                title={!edite ? "Enregistrer un nouveau groupe" : "Modifier le groupe"}
                onOk={!edite ? handleAddRole : handleModifierRole}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Annuler
                    </Button>,
                    <Button key="submit" type="primary" loading={loading}
                            onClick={!edite ? handleAddRole : handleModifierRole}>
                        Enregistrer
                    </Button>,
                ]}
            >
                {error === true && <Space
                    direction="vertical"
                    style={{
                        width: '100%',
                    }}
                >

                    <Alert message={message} type="error" showIcon/>

                </Space>}
                <p>
                    <label className='bold'>Libelle</label>
                    <input type="text" name="fname"
                           className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                           onChange={e => setLibelle(e.target.value)} value={libelle}
                           placeholder="Entrez le libelle"/>
                </p>
                <p>
                    <label className='bold'>Description</label>
                    <input type="text" name="fname"
                           className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                           onChange={e => setDescription(e.target.value)} value={description}
                           placeholder="Entrez la description"/>
                </p>
            </Modal>
        </>
    );
};


export default RolesList;
