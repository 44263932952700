import React, { useState } from 'react';
import { Menu } from 'antd';
import ClientList from "./client/ClientList";
import ListeEqupeMission from "../users/equpeMission/ListeEquipeMission";
import {
    faBuildingUser, faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTheme} from "styled-components";


const MenuClient = () => {
    const theme = useTheme();

    const [current, setCurrent] = useState('clients-liste');
    const onClick = (e) => {
        setCurrent(e.key);
    };

    const items = [
        {
            label: 'Clients',
            key: 'clients-liste',
            icon: <FontAwesomeIcon icon={faBuildingUser} color={theme.colors.primary_blue} />,
        },
        {
            label: 'Equipe de missions',
            key: 'client-team',
            icon: <FontAwesomeIcon icon={faPeopleGroup} color={theme.colors.primary_blue} />,
        },
    ];

    const showCurrentPage = () => {
        switch (current) {
            case 'clients-liste':
                return <ClientList/>
            case 'client-team':
                return <ListeEqupeMission />
            default:
                return <ClientList />
        }
    }

    return (
        <>
            <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal"
                items={items}
                style={{marginBottom: '5px', fontWeight: 'bold'}}
            />
            {showCurrentPage()}
        </>
    )
}

export default MenuClient;