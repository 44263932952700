import React, {useEffect, useRef, useState} from 'react';
import {Space, Table, Button, Alert, Modal, Popconfirm, Tag, Radio, Divider, Input, Select} from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  FilterFilled,
  LikeOutlined,
  PlusOutlined
} from '@ant-design/icons';
import {
  addQuestion,
  deleteQuestion,
  getListeGroupeQuestion,
  getListeQuestion,
  updateEtatQuestion,
  updateQuestion
} from '../../../data/params/question';
import {DateUserListe} from "../../../components/layout/pageData/DateUserListe";

const FilterHeader = ({questions, setQuestions, groupes, criteres}) => {
  const [showMenu, setShowMenu] = useState(false);
  const [group, setGroup] = useState('');
  const [critere, setCritere] = useState('');

  const handleShowMenu = (e) => {
    e.preventDefault();
    setShowMenu(true);
  };

  const handleCancel = () => {
    setShowMenu(false);
  }

  const handleChangeSelectCritere = (value) => {
    setCritere(value)
  }

  const handleChangeSelectGroup = (value) => {
    setGroup(value)
  }

  const handleFilter = (critere, group) => {
    const filtered = questions.filter(
        (item) =>
            item.Bog_GroupeQuestion.critereGroupe.toLowerCase().includes(critere.toLowerCase()) ||
            item.Bog_GroupeQuestion.libelle.toLowerCase().includes(group.toLowerCase())
    )
    setQuestions(filtered);

  }

  const handleResetFilter = () => {
    setCritere('')
    setGroup('')
    setQuestions(questions);
  }

  return (
      <>
        <div className="d-flex flex-column align-items-center">
          <button
              type="button"
              className="btn btn-light-primary me-3"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              onClick={handleShowMenu}
          >
                <span className="svg-icon svg-icon-2">
                  <FilterFilled/>
                </span>
            Filtrer
          </button>
          {showMenu === true && (
              <>
                <div className="menu menu-sub menu-sub-dropdown display-drop-menu w-300px w-md-325px"
                     data-kt-menu="true">
                  <div className="px-7 py-5 d-flex justify-content-between">
                    <div className="fs-5 text-dark fw-bolder">Option de filtrage</div>
                    <Button key='back' onClick={handleCancel}>
                      <CloseOutlined style={{color: 'red', fontSize: '20px'}}/>
                    </Button>
                  </div>
                  <div className="separator border-gray-200"></div>
                  <div className="px-7 py-5" data-kt-subscription-table-filter="form">
                    <div className="mb-10">
                      <label className="form-label fs-6 fw-bold">Critères relatif à:</label>
                      <select
                          className="form-select form-select-solid fw-bolder "
                          placeholder="Selectionner"
                          tabIndex="-1"
                          aria-hidden="true"
                          onChange={(e) => handleChangeSelectCritere(e.target.value)}
                          value={critere}
                          autoComplete='off'
                      >
                        <option value=''>Selectionner...</option>
                        {criteres.map((opt) => (
                            <option value={opt} key={opt}>
                              {opt}
                            </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-10">
                      <label className="form-label fs-6 fw-bold">Goupes de question:</label>
                      <select
                          className="form-select form-select-solid fw-bolder "
                          placeholder="Selectionner"
                          tabIndex="-1"
                          aria-hidden="true"
                          onChange={(e) => handleChangeSelectGroup(e.target.value)}
                          value={group}
                          autoComplete='off'
                      >
                        <option value=''>Selectionner...</option>
                        {groupes.map((opt) => (
                            <option value={opt} key={opt}>
                              {opt}
                            </option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button
                          type="reset"
                          className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                          data-kt-menu-dismiss="true"
                          data-kt-subscription-table-filter="reset"
                          onClick={() => handleResetFilter()}
                      >
                        Réinitialiser
                      </button>
                      <button
                          type="submit"
                          className="btn btn-primary fw-bold px-6"
                          data-kt-menu-dismiss="true"
                          data-kt-subscription-table-filter="filter"
                          onClick={() => handleFilter(critere, group)}
                      >
                        Appliquer
                      </button>
                    </div>
                  </div>
                </div>
              </>
          )}
        </div>
      </>
  );
};

const QuestionnaireGeneral = () => {
  const [questions, setQuestions] = useState([])
  const [filtredItems, setFiltredItems] = useState([])
  const [question, setQuestion] = useState([])
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState('') 
  const [description, setDescription] = useState('') 
  const [reponse_defaut, setReponse_Defaut] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false);
  const [edite, setEdite] = useState(false);
  const [libelleGroupe, setLibelleGroupe] = useState('');
  const [critereGroupe, setCritereGroupe] = useState('');
  const [type_id, setTypeId] = useState(null);
  const [groupes, setGroupes] = useState([]);
  const [items, setItems] = useState([]);
  const inputRef = useRef(null);

  // useEffect(() => {
  //   fetchTypeQuestion()
  // }, [type_id])

  useEffect(() => {
    fetchGroupeQuestion()
  }, [])

  useEffect(() => {
    handleListeQuestion()
  }, [])

  const fetchGroupeQuestion = () => {
    setLoading(true);
    getListeGroupeQuestion()
        .then((res) => {
          if(res.result){
            const groupesQuestions = res.result.filter((item) => item.Bog_TypeQuestion.libelle === 'ACCEPTATION')
            const elements = [...new Set(groupesQuestions.map((item) => item.critereGroupe.toUpperCase()))];
            setItems(elements)

            const criteres = [...new Set(groupesQuestions.map((item) => item.libelle))];
            setGroupes(criteres);

            const idT = groupesQuestions[0].type_question_id
            setTypeId(idT)
          }
          setLoading(false);
        });
  };

  const handleListeQuestion = async () => {
    setLoading(true)
    const { result, status, message } = await getListeQuestion()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setQuestions(result)
      setFiltredItems(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const onNameChange = (event) => {
    setCritereGroupe(event.target.value);
  };

  const onNameChangeGroup = (event) => {
    setLibelleGroupe(event.target.value);
  };

  const onChange = (value) => {
    setCritereGroupe(value)
  }

  const onChangeGroup = (value) => {
    setLibelleGroupe(value)
  }

  const addItemGroup = (e) => {
    e.preventDefault();
    setGroupes([...groupes, libelleGroupe]);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, critereGroupe]);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };

  const handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filtered = questions.filter(
        (item) =>
            item.libelle.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.reponse_defaut.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.Bog_GroupeQuestion.critereGroupe.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.Bog_GroupeQuestion.libelle.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFiltredItems(filtered);
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleAddQuestion = async () => {
    setLoading(true);
    const data = { libelle, description, reponse_defaut, libelleGroupe, critereGroupe: critereGroupe.toUpperCase(), type_id }
    // console.log('data', data)
    const { status, message } = await addQuestion(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setDescription('')
      setReponse_Defaut('')
      setLibelleGroupe('')
      setCritereGroupe('')
      handleListeQuestion()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  };

  const handleModifierQuestion = async () => {
    setLoading(true);
    const data = { question_id:question.id, libelle, description, reponse_defaut, libelleGroupe, critereGroupe: critereGroupe.toUpperCase(), type_id  }

    const { status, message } = await updateQuestion({ data, question_id: question.id})
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setDescription('')
      setReponse_Defaut('')
      handleListeQuestion()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setEdite(false)
    setLibelle('')
    setDescription('')
    setReponse_Defaut('')
    setError(false)
  };

  const selectQuestion = (question_id) => {
    const privil = questions.filter(periode => periode.id === question_id)[0]
    setQuestion(privil)
    setLibelle(privil.libelle)
    setDescription(privil.description)
    setReponse_Defaut(privil.reponse_defaut)
    setEdite(true)
    setOpen(true);
  }

  const handleDeleteQuestion = async (question_id) => {
    setLoading(true)
    const data = {question_id}
    const { status, message } = await deleteQuestion(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      handleListeQuestion()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateEtatQuestion = async (question_id, etat) => {
    setLoading(true)
    const data = {question_id, etat}
    const { status, message } = await updateEtatQuestion(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setLibelle('')
      setDescription('')
      handleListeQuestion()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const columns = [
    {
      title: '#',
      render: (text, record, index) => <span className='display-block chart-blue'>{ index+1 }</span>
    },
    {
      title: 'Question',
      dataIndex: 'libelle',
      key: 'libelle',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Réponse',
      dataIndex: 'reponse_defaut',
      key: 'reponse_defaut',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Relatif à',
      dataIndex: 'critereGroup',
      key: 'critereGroup',
      render: (_, record, index) => <span className='display-block bold'>{record.Bog_GroupeQuestion?.critereGroupe}</span>,
    },
    {
      title: 'Groupe',
      dataIndex: 'libelleGroupe',
      key: 'libelleGroupe',
      render: (_, record, index) => <span className='display-block bold'>{record.Bog_GroupeQuestion?.libelle}</span>,
    },
    // {
    //   title: 'Créée le / Par',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (_, record, index) => <DateUserListe date={record.createdAt} user={record.nom_user+" "+record.prenom_user} key={index} />
    // },
    {
      title: 'Etat',
      dataIndex: 'etat',
      key: 'etat',
      render: (_, record) =>  record.etat === 1 ? <Tag color="green">Activé</Tag> : <Tag color="red">Désactivé</Tag>
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => selectQuestion(record.id)}
          />

          {record.etat === 0 && <Popconfirm
            title={`Activer la période ${record.libelle} ?`}
            description={`Voulez vous vraiment activer la période ${record.libelle} ?`}
            onConfirm={() => handleUpdateEtatQuestion(record.id, 1)}
            okText="Oui"
            cancelText="Non"
          >
            <Button
              icon={<DislikeOutlined />}
              className='chart-bg-orange'
              title='Activer ?'
            />
          </Popconfirm>}

          {record.etat === 1 && <Popconfirm
            title={`Désactiver la période ${record.libelle} ?`}
            description={`Voulez vous vraiment désactiver la période ${record.libelle} ?`}
            onConfirm={() => handleUpdateEtatQuestion(record.id, 0)}
            okText="Oui"
            cancelText="Non"
          >
            <Button
              icon={<LikeOutlined />}  
              className='chart-bg-green'
              title='Désactiver ?'
            />
          </Popconfirm>}

          <Popconfirm
            title={`Supprimer la période ${record.libelle} ?`}
            description={`Voulez vous vraiment supprimer la période ${record.libelle} ?`}
            onConfirm={() => handleDeleteQuestion(record.id)}
            okText="Oui"
            cancelText="Non"
          >
            <Button
              title='Supprimer ?'
              type="primary"
              icon={<DeleteOutlined />}
              danger  
            />
          </Popconfirm>
          
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="card mb-5 mb-lg-10 w-100">
        {error === true &&
          <Space
            direction="vertical"
            style={{
              width: '100%',
            }}
          >
            <Alert
              message="Error"
              description={message}
              type="error"
              showIcon
            />
          </Space>
        }
        
        <div className="card-header">
            
          <div className="card-title">
            <h3>Liste des questionnaire général</h3>
          </div>
    
          <div className="card-toolbar">
            <div className="my-1 me-4">
              <input type="text" name="fname" onChange={handleSearchChange} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Rechercher" />
            </div>
            <FilterHeader groupes={groupes} setQuestions={setFiltredItems} questions={questions} criteres={items}/>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={showModal}
              size='large'
            >
              Ajouter
            </Button>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>
                
        <div className="card-body p-0">
            
            <div className="table-responsive">
              <Table
                  loading={loading}
                  columns={columns} dataSource={filtredItems} pagination= { {current: 1, pageSize: 50} } />
            </div>
        </div>
      </div>
      <Modal
        open={open}
        title={!edite ? "Enregistrer une nouvelle question" : "Modifier la question" }
        onOk={ !edite ? handleAddQuestion : handleModifierQuestion }
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={ !edite ? handleAddQuestion : handleModifierQuestion }>
            Enregistrer
          </Button>,
        ]}
      >
        
        {error === true && <Space
          direction="vertical"
          style={{
            width: '100%',
          }}
        >
          <Alert message={message} type="error" showIcon />

        </Space>}
        <p>
          <label className='bold'>Question</label>
          <textarea
              className='form-control mb-2'
              rows="50"
              cols="150"
              maxLength={1000}
              style={{
                height: '100px',
                marginBottom: 24,
              }}
              onChange={e => setLibelle(e.target.value)}
              value={libelle}
              placeholder="Entrez la question"
          >
          </textarea>
        </p>
        <p>
          <label className='bold'>Réponse sans explication</label>
          <div>
            <div className="d-flex mt-3">
              <Radio.Group onChange={(e) => setReponse_Defaut(e.target.value)} value={reponse_defaut}>
                <Radio value="POSITIF">POSITIF</Radio>
                <Radio value="NEGATIF">NEGATIF</Radio>
              </Radio.Group>
            </div>
          </div>
        </p>
        <p>
          <label className='bold'>Critères relatif</label>
          <div>
            <div className="d-flex mt-3">
              <Select
                  style={{ width: 500 }}
                  placeholder="Critère relatif à "
                  onChange={onChange}
                  dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                          <Input
                              placeholder="Entrer le critère"
                              ref={inputRef}
                              value={critereGroupe}
                              onChange={onNameChange}
                          />
                          <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                            Ajouter un critère
                          </Button>
                        </Space>
                      </>
                  )}
                  options={items && items.map((item) => ({ label: item, value: item }))}
              />
            </div>
          </div>
        </p>
        <p>
          <label className='bold'>Libelle du groupe</label>
          <div>
            <div className="d-flex mt-3">
              <Select
                  style={{ width: 500 }}
                  placeholder="Critère relatif à "
                  onChange={onChangeGroup}
                  dropdownRender={(menu) => (
                      <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                          <Input
                              placeholder="Entrer le critère"
                              ref={inputRef}
                              value={libelleGroupe}
                              onChange={onNameChangeGroup}
                          />
                          <Button type="text" icon={<PlusOutlined />} onClick={addItemGroup}>
                            Ajouter un groupe
                          </Button>
                        </Space>
                      </>
                  )}
                  options={groupes && groupes.map((item) => ({ label: item, value: item }))}
              />
            </div>
          </div>
        </p>
      </Modal>
    </>
  )

}

export default QuestionnaireGeneral
