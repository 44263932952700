import React, {Fragment, useEffect, useState} from 'react';
import {
    EllipsisOutlined,
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {
    getExerciceDetails,
    getListeEquipeMission,
    getListeReponseMission,
    VerrouQestion
} from "../../../data/exercice";
import {optionsSwal, toAbsoluteUrl} from "../../../helpers/AssetsHelpers";
import {Button, Divider, notification, Popover, Space, Tag, Tooltip} from "antd";
import Swal from "sweetalert2";
import swal from "sweetalert";
import {useSelector} from "react-redux";
import {Identification} from "../../../components/client/modals/IdentificationMission";
import clsx from "clsx";
import {appTheme} from "../../../config/theme";
import {FormContainer} from "../../../components/common/common.components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEnvelopeOpen, faFolderOpen,
    faLock, faUnlock,
    faBuilding, faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import {faListSquares} from "@fortawesome/free-solid-svg-icons/faListSquares";
import {FicheClientMenu} from "./ClientExploitationFiche";
import {useTheme} from "styled-components";
import MissionSommaireClient from "../../../components/modals/clients/MissionSommaireClient";

const ClientExploitationMission = ({
        missionSelected,
        client,
        setCode,
        setModalId,
        next,
        prev,
       clientSommaire,
       setIsAcceptationValid
    }) => {
    const theme = useTheme()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModalId, setSelectedModalId] = useState(null);
    const [listQuestionAdd, setListQuestionAdd] = useState([]);
    const [equipes, setEquipes] = useState([]);
    const [mission, setMission] = useState(missionSelected);
    const [loading, setLoading] = useState(false);
    const [userSommaire, setUserSommaire] = useState(null);
    const [activeTab, setActiveTab] = useState(0);
    const user = useSelector(state => state.user)
    const [isToggleOpen, setIsToggleOpen] = useState(false);

    const fetchOneExercice = async (mission_id) => {
        getExerciceDetails(mission_id)
            .then((res) => {
                if(res){
                    setMission(res);
                }
            })
            .catch(error => {
                notification.success({
                    description: error || "Impossible de récupérer les détails de l'exercice",
                });
            })
    }

    useEffect(() => {
        if(missionSelected){
            fetchOneExercice(missionSelected.id)
        }
    }, [missionSelected]);

    useEffect(() => {
        const fetchEquipes = async (mission_id, user_id) => {
            getListeEquipeMission(mission_id)
                .then((res) => {
                    if(res){
                        setEquipes(res);

                        const userTeam = res.find((item) => item.membre_id === user_id)
                        if(userTeam){
                            setUserSommaire(userTeam);
                        }
                    }
                })
                .catch((error) => {
                    console.error("Erreur", error);
                });
        };

        fetchEquipes(mission.id, user.id)
    }, [user, mission]);

    useEffect(() => {
        const fetchListeQuestionRepondu = async () => {
            getListeReponseMission(mission.id)
                .then((res) => {
                    setListQuestionAdd(res)
                })
                .catch((error) => {
                    console.error("Erreur", error);
                });
        }

        if(mission){
            fetchListeQuestionRepondu();
        }
    }, [mission]);

    const handleTabChange = (tabIndex, e) => {
        e.preventDefault()
        setActiveTab(tabIndex);
    };

    const handleOpenModal = (modal_id) => {
        setSelectedModalId(modal_id);
        setIsModalOpen(prevState => !prevState);
    };

    const handleVerrouQuestion = async (exercice_id, verou_question)  => {
        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const data = {client_id: client.id, verou_question, exercice_id}
                const {status, message} = await VerrouQestion(data)
                if (status === 'error') {
                    await swal(`${message}`, '', 'warning')
                }
                if (status === 'success') {
                    setLoading(false)
                    await swal(`Opération effectuée avec succès`, '', 'success')
                    fetchOneExercice()
                } else {
                    await swal(message, '', 'error')
                    setLoading(false)
                }
            }
        })
    }

    const steps = [
        {
            id: 1,
            title: 'Sommaire',
            description: 'Sommaire de traitement de cycles LEA',
            content: (
                <MissionSommaireClient
                    client={client}
                    mission={mission}
                    listQuestionAdd={listQuestionAdd}
                    userSommaire={userSommaire}
                    setModalId={setModalId}
                    setCode={setCode}
                    next={next}
                    prev={prev}
                    clientSommaire={clientSommaire}
                    setIsMaintien={setIsAcceptationValid}
                />
            ),
        },
        {
            id: 2,
            title: 'Historique',
            description: 'Historique des interventions',
            content: (
                <Identification mission={mission} client={client}/>
            ),
        },
    ];

    const toggleMenu = (e) => {
        e.preventDefault()
        setIsToggleOpen(!isToggleOpen);
    };

    return (
        <>
            <Fragment>
                {/*<HStack justifyContent="space-between" alignItems="center">*/}
                {/*    <PageLabel label={`Fiche exploitation ${mission.mission} - ${mission.annee}`}/>*/}
                {/*</HStack>*/}

                <FicheClientMenu>
                    <div className="card mb-5 mb-xl-10">
                        <div className="card-body pt-9 pb-0">
                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                <div className="me-7 mb-4">
                                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                        {client.logo_client ? (
                                            <div className='symbol-label'>
                                                <img
                                                    src={toAbsoluteUrl(`/media/${client.logo_client}`)}
                                                    alt={client.denomination}
                                                    className='w-100'
                                                />
                                            </div>
                                        ) : (
                                            <div className={clsx('symbol-label', `bg-green`)} style={{backgroundColor: appTheme.colors.bgColor, fontSize: "40px"}}>
                                                {client.denomination[0].toUpperCase() + '' + client.denomination[1].toUpperCase()}
                                            </div>
                                        )}
                                        <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-column mb-2">
                                                <div className="d-flex me-2">
                                                    <span className="fw-bolder text-uppercase" style={{color: "royalblue", fontSize: "36px", fontWeight: "700"}}>{mission.mission}</span>
                                                    <span className="mx-2"></span>
                                                    <div className="">
                                                        {mission.isCloture === false ?
                                                            (mission.lettre_mission === 0 ?
                                                                <Tag color="yellow">En attente</Tag> :
                                                                <Tag color="blue">En cours</Tag>)
                                                            :
                                                            <Tag color="green">Clôturé</Tag>
                                                        }
                                                        {mission.isCloture === true && <Tooltip title={"Mission verrouillé"} color={"red"}> <Tag color="red" icon={<FontAwesomeIcon icon={faLock} />}/> </Tooltip>}
                                                        {mission.isCloture === true && <Popover
                                                            placement="top"
                                                            title="Motif de clôture"
                                                            content={
                                                                <>
                                                                    <Divider/>
                                                                    <span
                                                                        style={{
                                                                            fontFamily: "Oswald, sans-serif",
                                                                            fontSize: "13px",
                                                                        }}
                                                                    >
                                                                {mission.commentCloture}
                                                              </span>
                                                                </>
                                                            }
                                                        >
                                                            <Button
                                                                icon={<FontAwesomeIcon icon={faExclamationCircle}/>}
                                                                color={""}
                                                            />
                                                        </Popover>}
                                                    </div>
                                                </div>
                                                <div className="d-flex align-center">
                                                    <div>{mission.annee && <Tag style={{fontSize: "18px"}} color={appTheme.colors.primary}>{mission.annee}</Tag>}</div>
                                                    <Tag
                                                        icon={<FontAwesomeIcon style={{fontSize: "18px", marginRight: "5px"}} icon={faBuilding} color={appTheme.colors.primary_blue} />}
                                                    >
                                                        <span className="fw-bolder text-uppercase" style={{fontFamily: "Oswald, sans-serif", fontSize: "18px"}}>{client.sigle}</span>
                                                    </Tag>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex my-4 me-15">
                                                <Space>
                                                    { mission?.fichier_question === 1 && mission?.etat === 2 &&
                                                        <Tooltip color='blue' title="Lettre de mission">
                                                            <Button
                                                                shape="circle"
                                                                size={"middle"}
                                                                style={{color: "blue"}}
                                                                icon={<FontAwesomeIcon icon={faEnvelopeOpen} color={"blue"}/>}
                                                                // onClick={() => handleOpenModal(1, mission?.id)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                    <Tooltip
                                                        title="Documment de la mission"
                                                        color={"yellow"}
                                                    >
                                                        <Button
                                                            className="text-white"
                                                            shape="circle"
                                                            style={{color: "yellow"}}
                                                            icon={<FontAwesomeIcon icon={faFolderOpen} color="yellow" />}
                                                            onClick={() => {
                                                                handleOpenModal(1, mission);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                    {(mission.fichier_question === 1 && mission.lettre_mission === 0) &&
                                                        mission.isChefMission && mission.isManagerMission && <Tooltip
                                                            color='blue'
                                                            title={`${mission?.verou_question === 0 ? 'Questionnaire déverrouillé' : 'Questionnaire verrouillé'}`}
                                                        >
                                                            <Button
                                                                shape="circle"
                                                                size={"middle"}
                                                                style={{backgroundColor: mission?.verou_question === 0 ? "yellow" : "yellowgreen"}}
                                                                icon={<FontAwesomeIcon icon={mission?.verou_question === 0 ? faUnlock : faLock} color={"white"}/>}
                                                                onClick={() => handleVerrouQuestion(mission.id, mission?.verou_question === 1 ? 0 : 1)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </Space>
                                            </div>
                                            <div className="d-flex ms-5 my-4">
                                                <Link
                                                    to={`/clients/exploitation/fiche/${client?.id}`}
                                                    state={{record: client}}
                                                    className="btn btn-sm btn-light-danger me-3"
                                                    data-bs-toggle="modal"
                                                   data-bs-target="#kt_modal_new_target"
                                                >
                                                    Retour
                                                </Link>
                                                <Tooltip title="Voir plus">
                                                    <div className="d-flex flex-column align-items-center ">
                                                        <button
                                                            className="btn btn-sm btn-flex btn-primary fw-bold fs-9"
                                                            data-kt-menu-trigger="click"
                                                            data-kt-menu-placement="bottom-end"
                                                            onClick={toggleMenu}
                                                        >
                                                            <EllipsisOutlined />
                                                        </button>
                                                        {isToggleOpen && (
                                                            <>
                                                                <div
                                                                    id="kt_users_lists_views_export_menu"
                                                                    className="menu menu-sub menu-sub-dropdown menu-option menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4 show"
                                                                    data-kt-menu="true"
                                                                    data-popper-placement="bottom-end"
                                                                >
                                                                    <div className="menu-item px-3">
                                                                       Test
                                                                    </div>
                                                                    <div className="menu-item px-3">
                                                                        Test 2
                                                                    </div>
                                                                    <div className="menu-item px-3">
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap flex-stack">
                                        <div className="d-flex flex-column flex-grow-1 pe-8">
                                            <div className="d-flex flex-wrap">
                                                <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <Tag icon={<FontAwesomeIcon icon={faListSquares} />} color={theme.colors.primary_blue} />
                                                        <div className="fs-2 fw-bold counted" >120
                                                        </div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-500">Interventions</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="symbol-group symbol-hover mb-3">
                                            { equipes.map((item, index) => (
                                                <div key={index} className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title=""
                                                     data-bs-original-title="Alan Warden">
                                                    {item.avatar ? (
                                                        <div className='symbol-label'>
                                                            <img
                                                                src={toAbsoluteUrl(`/media/${item.avatar}`)}
                                                                alt={item.Lea_User?.nom_user}
                                                                className='w-100'
                                                            />
                                                        </div>
                                                    ) : (
                                                        <Tooltip title={item.Lea_User?.nom_user + ' ' + item.Lea_User?.prenom_user}>
                                                            <span className="symbol-label bg-primary text-inverse-primary fw-bolder">
                                                                {item.Lea_User?.nom_user[0].toUpperCase()}
                                                            </span>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            ))}
                                            {/*end::User*/}
                                            {/*begin::All users*/}
                                            <button
                                                onClick={ () =>handleOpenModal(2)}
                                                className="symbol symbol-35px symbol-circle"
                                                data-bs-toggle="modal"
                                                data-bs-target="#kt_modal_view_users"
                                            >
                                                <span className="symbol-label bg-dark text-inverse-dark fs-8 fw-bolder"
                                                      data-bs-toggle="tooltip" data-bs-trigger="hover" title=""
                                                      data-bs-original-title="View more users">...</span>
                                            </button>
                                            {/*end::All users*/}
                                        </div>
                                        {/*<div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">*/}
                                        {/*    <div className="d-flex justify-content-between w-100 mt-auto mb-2">*/}
                                        {/*        <span className="fw-semibold fs-6 text-gray-500">Evolution des mission</span>*/}
                                        {/*        <span className="fw-bold fs-6">50%</span>*/}
                                        {/*    </div>*/}
                                        
                                        {/*    <div className="h-5px mx-3 w-100 bg-light mb-3">*/}
                                        {/*        <div className="bg-success rounded h-5px" role="progressbar"*/}
                                        {/*             aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                    {/*<ProgressIndicator*/}
                                    {/*    data={treeData.filter((node) => node?.status === 'current')[0]}*/}
                                    {/*    completedCount={treeData.filter((node) => node?.status === 'completed').length}*/}
                                    {/*    totalCount={treeData.length}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                                {steps.map((step, index) => (
                                    <li key={index} className="nav-item">
                                        <Tooltip title={step.description}>
                                            <button
                                                data-bs-toggle="tab"
                                                className={`nav-link fs-4 btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0 ${activeTab === index ? 'active' : ''}`}
                                                onClick={(e) => handleTabChange(index, e)}
                                            >
                                                {step.title}
                                            </button>
                                        </Tooltip>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </FicheClientMenu>

                <FormContainer>
                    <div className="tab-content" id="myTabContent">
                        {steps.map((item, index) => (
                            <div
                                key={index}
                                className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                                id={`tab-${index}`}
                                role="tabpanel"
                            >
                                {steps[activeTab].content}
                            </div>
                        ))}
                    </div>
                </FormContainer>
            </Fragment>
        </>
    );
};

export {ClientExploitationMission};
