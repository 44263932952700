import React, {Fragment, useEffect, useState} from 'react';
import {Button, Col, Input, Modal, notification, Select, Space, Table, Tag, Tooltip} from 'antd';
import clsx from 'clsx';
import {toAbsoluteUrl, optionsSwal} from '../../../helpers/AssetsHelpers';
import useProfils from '../../../hooks/params/useProfil';
import {
    GenererPasswordUser,
    getUsers,
    ModifierUser, updateEtatAcessDistantUser,
    updateEtatAcessMobileUser, updateEtatAcessWebUser,
} from "../../../data/user";
import Swal from 'sweetalert2'
import {Link} from "react-router-dom";
import { DateUserListe } from '../../../components/layout/pageData/DateUserListe';
import {REQUEST_STATUS} from "../../../helpers/Constants";
import TableTitle from "../../../components/common/TableTitle";
import {appTheme} from "../../../config/theme";
import {FormContainer, TagItem} from "../../../components/common/common.components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBriefcase,
    faDesktop,
    faFileLines,
    faFolderPlus,
    faLock, faLockOpen, faMapLocation, faMobileScreen,
    faPhoneAlt,
    faUserEdit, faUserGear,
} from "@fortawesome/free-solid-svg-icons";
import {ButtonAlter} from "../../../components/common/button.components";
import {useSelector} from "react-redux";
import {HStack} from "@chakra-ui/react";
import PageLabel from "../../../components/common/PageLabel/PageLabel";
import SearchRow from "../../../components/common/SearchRow";
import FormLabel from "../../../components/common/Form/FormLabel";
import {useTheme} from "styled-components";
import UserProfilChange from "../../../components/modals/users/UserProfilChange";

const UsersList = () => {
    const theme = useTheme()
    const {profils} = useProfils();
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState({});
    const [filtersItems, setFiltersItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [passwdResult, setPasswdResult] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [selectedModal, setSelectedModal] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const logUser = useSelector(state => state.user)
    const isAdmin = logUser.libelleProfil === "Admin"

    const [paramsRecherche, setParamsRecherche] = useState({
        infosUser: "",
        profil_id: "",
        etat_user: "",
        date_debut: "",
        date_fin: "",
    });

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
    });

    useEffect(() => {
        fetchUsers();
    },[]);

    const fetchUsers = () => {
        setLoading(true);
        getUsers()
            .then((res) => {
                setUsers(res);
                setFiltersItems(res)
                setLoading(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setUsers([]);
        }
    };

    const handleModalUserOpen = (modalId, data) => {
        setUser(data)
        setSelectedModal(modalId)
        setIsModalOpen((state) => !state);
    };

    // const handleDeleteUser = async (user_id) => {
    //     Swal.fire(optionsSwal).then(async (result) => {
    //         if (result.isConfirmed) {
    //             setLoading(true)
    //             const {status, message} = await deleteUser({user_id})
    //             if (status === 'error') {
    //                 await swal(`${message}`, '', 'warning')
    //             }
    //             if (status === 'success') {
    //                 setLoading(false)
    //                 fetchUsers()
    //             } else {
    //                 setLoading(false)
    //             }
    //         }
    //     })
    // }

    const handleProfilChange = (value) => {
        setLoadingUpdate(true)
        const data = {
            ...user,
            user_id: value.user.id,
            profil_id: value.profil_id,
        }

        ModifierUser(data)
            .then(
                (res) => {
                    const {status, message} = res
                    if (status === REQUEST_STATUS.SUCCESS) {
                        handleModalUserOpen()
                        fetchUsers();
                        setLoadingUpdate(false)
                        notification.success({
                            description: message,
                        });
                    } else {
                        notification.error({
                            description: message,
                        });
                        setLoadingUpdate(false)
                    }
                },
                (err) => {
                    notification.error({
                        description: err.data.message,
                    });
                }
            );
    };

    const handleUpdateUserEtat = (user_id, etat_user) => {
        setLoadingUpdate(true)
        const data = {user_id, etat_user}

        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                ModifierUser(data)
                    .then(
                        (res) => {
                            const {status, message} = res
                            if (status === REQUEST_STATUS.SUCCESS) {
                                fetchUsers();
                                setLoadingUpdate(false)
                                notification.success({
                                    description: message,
                                });
                            } else {
                                notification.error({
                                    description: message,
                                });
                                setLoadingUpdate(false)
                            }
                        },
                        (err) => {
                            notification.error({
                                description: err.data.message,
                            });
                        }
                    );
            }
        })
    };

    const handleAccesDistant = (user_id, acces_distant) => {
        setLoadingUpdate(true)
        const data = {user_id, acces_distant}

        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                updateEtatAcessDistantUser(data)
                    .then(
                        (res) => {
                            const {status, message} = res
                            if (status === REQUEST_STATUS.SUCCESS) {
                                fetchUsers();
                                setLoadingUpdate(false)
                                notification.success({
                                    description: message,
                                });
                            } else {
                                notification.error({
                                    description: message,
                                });
                                setLoadingUpdate(false)
                            }
                        },
                        (err) => {
                            notification.error({
                                description: err.data.message,
                            });
                        }
                    );
            }
        })
    };

    const handleAccesWeb = (user_id, version_web) => {
        setLoadingUpdate(true)
        const data = {user_id, version_web}

        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                updateEtatAcessWebUser(data)
                    .then(
                        (res) => {
                            const {status, message} = res
                            if (status === REQUEST_STATUS.SUCCESS) {
                                fetchUsers();
                                setLoadingUpdate(false)
                                notification.success({
                                    description: message,
                                });
                            } else {
                                notification.error({
                                    description: message,
                                });
                                setLoadingUpdate(false)
                            }
                        },
                        (err) => {
                            notification.error({
                                description: err.data.message,
                            });
                        }
                    );
            }
        })
    };

    const handleAccesMobile = (user_id, version_mob) => {
        setLoadingUpdate(true)
        const data = {user_id, version_mob}

        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                updateEtatAcessMobileUser(data)
                    .then(
                        (res) => {
                            const {status, message} = res
                            if (status === REQUEST_STATUS.SUCCESS) {
                                fetchUsers();
                                setLoadingUpdate(false)
                                notification.success({
                                    description: message,
                                });
                            } else {
                                notification.error({
                                    description: message,
                                });
                                setLoadingUpdate(false)
                            }
                        },
                        (err) => {
                            notification.error({
                                description: err.data.message,
                            });
                        }
                    );
            }
        })
    };

    const handleGenererPassword = (user_id) => {
        setLoadingUpdate(true)
        const data = {
            user_id,
        }
        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                GenererPasswordUser(data)
                    .then(
                        (res) => {
                            const {status, result, message} = res
                            if (status === REQUEST_STATUS.SUCCESS) {
                                // handleModalUserOpen()
                                // fetchUsers();
                                setLoadingUpdate(false)
                                notification.success({
                                    description: message,
                                });
                                setPasswdResult(result)
                                setShowPassword(true)
                            } else {
                                notification.error({
                                    description: message,
                                });
                                setLoadingUpdate(false)
                            }
                        },
                        (err) => {
                            notification.error({
                                description: err.data.message,
                            });
                        }
                    );
            }
        })
    };

    const dataColumns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Photo</TableTitle>,
            key: "photo",
            dataIndex: "photo",
            render: (_, record) => (
                <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    {record?.avatar ? (
                        <div className='symbol-label'>
                            <img
                                src={toAbsoluteUrl(`/media/${record?.avatar}`)}
                                alt={record.nom_user + ' ' + record.prenom_user}
                                className='w-100'
                            />
                        </div>
                    ) : record.codeCivilite === 'M.' ? (
                        <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                            <img
                                src={toAbsoluteUrl('/media/avatars/male.jpg')}
                                alt={record.nom_user + ' ' + record.prenom_user}
                                className='w-100'
                            />
                        </div>
                    ) : (
                        <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                            <img
                                src={toAbsoluteUrl('/media/avatars/female.jpg')}
                                alt={record.nom_user + ' ' + record.prenom_user}
                                className='w-100'
                            />
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Nom</TableTitle>,
            key: "nom",
            dataIndex: "nom",
            render: (_, record) => (
                <>
                  <span style={{ color: "#000", fontWeight: "bold" }}>
                    {`${record.codeCivilite || ""} ${record.nom_user || ""} ${record.prenom_user || ""}`}
                  </span>
                    <br />
                    {record?.initial && <Tag prefix="@" color={appTheme.colors.primary_blue}>{record?.initial}</Tag>}
                    <br/>
                    {record.telephone1_user && <Tag
                        color={appTheme.colors.alter}
                        style={{fontWeight: "600"}}
                        icon={<FontAwesomeIcon icon={faPhoneAlt} style={{marginRight: "5px"}}/>}

                    >
                        {record.telephone1_user}
                    </Tag>}
                </>
            ),
        },
        {
            title: () => <TableTitle></TableTitle>,
            dataIndex: "",
            render: (_, record) => (
                <>
                    <Tooltip title={record?.version_web === 1 ? "Accès Web" : "Activer ?"} color={record?.version_web === 1 ? "green" : ""}>
                        <Tag
                            onClick={() => handleAccesWeb(record.id, record.version_web === 1 ? 0 : 1)}
                            icon={<FontAwesomeIcon icon={faDesktop}/>}
                            color={record.version_web === 1 ? "green" : "gray"}
                        />
                    </Tooltip>
                    <Tooltip title={record?.version_mob === 1 ? "Accès Mobile" : "Activer ?"} color={record?.version_mob === 1 ? "green" : ""}>
                        <Tag
                            onClick={() => handleAccesMobile(record.id, record.version_mob === 1 ? 0 : 1)}
                            icon={<FontAwesomeIcon icon={faMobileScreen}/>}
                            color={record?.version_mob === 1 ? "green" : "gray"}
                        />
                    </Tooltip>
                    <Tooltip title={record.acces_distant === 1 ? "Accès distant" : "Activer ?"} color={record.acces_distant === 1 ? "green" : ""}>
                        <Tag
                            onClick={() => handleAccesDistant(record.id, record.acces_distant === 1 ? 0 : 1)}
                            icon={<FontAwesomeIcon icon={faMapLocation}/>}
                            color={record.acces_distant === 1 ? "green" : "gray"}
                        />
                    </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>Profil</TableTitle>,
            dataIndex: "libelleProfil",
            render: (_, record) => (
                <>
                    <TagItem>{record?.libelleProfil}</TagItem>
                </>
            ),
        },
        {
            title: () => <TableTitle>Etat</TableTitle>,
            key: 'etat',
            dataIndex: "etat",
            render: (_, record) => (
                record.etat_user === 1 ? (
                    <Tag color='lime'>Actif</Tag>
                ) : (
                    <Tag color='volcano'>Inactif</Tag>
                )
            )
        },
        {
            title: () => <TableTitle>Dernière(s) modification</TableTitle>,
            dataIndex: "",
            align: 'center',
            render: (_, record) => (
                <>
                    <DateUserListe date={record.createdAt} user={(record.nom_admin || "") + ' ' + (record.prenom_admin || "")} />
                </>
            ),
        },
        {
            title: () => <TableTitle>Actions</TableTitle>,
            align: "right",
            width: 150,
            render: (value, record) => (
                <Space>
                    {(record.mission || isAdmin)  && <Link style={{marginRight: '10px'}} to={`/clients/dossier/${record.id}`} state={{record}}>
                        <Tooltip
                            title="Détails de l'utilisateur"
                            color={appTheme.colors.primary}
                        >
                            <Link to={`/utilisateurs/view/${record.id}`} state={{record}}>
                                <Button
                                    style={{backgroundColor: appTheme.colors.primary}}
                                    shape="circle"
                                    icon={<FontAwesomeIcon icon={faFileLines} color="white" />}
                                    size={"middle"}
                                />
                            </Link>
                        </Tooltip>
                    </Link>}
                    {(record.mission || isAdmin)  && <Link style={{marginRight: '10px'}} to={`/clients/dossier/${record.id}`} state={{record}}>
                        <Tooltip
                            title="Modifier l'utilisateur"
                            color={appTheme.colors.primary_blue}
                        >
                            <Link to={`/utilisateurs/edit/${record.id}`} state={{record}}>
                                <Button
                                    style={{backgroundColor: appTheme.colors.primary_blue}}
                                    shape="circle"
                                    icon={<FontAwesomeIcon icon={faUserEdit} color="white" />}
                                    size={"middle"}
                                />
                            </Link>
                        </Tooltip>
                    </Link>}
                    {isAdmin && <Tooltip title="Modifier le profil de l'utilisaateur" className='me-5'>
                        <ButtonAlter
                            shape="circle"
                            type="primary"
                            icon={<FontAwesomeIcon icon={faBriefcase} color="white" />}
                            onClick={() => handleModalUserOpen(1, record)}
                        />
                    </Tooltip>}
                    {isAdmin && <Tooltip title="Modifier le mot de passe" className='me-5'>
                        <Button
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faUserGear} color="" />}
                            size={"middle"}
                            onClick={() => handleGenererPassword(record.id)}
                        />
                    </Tooltip>}
                    {isAdmin && <Tooltip title={record?.etat === 1 ? 'Désactiver' : 'Activer'} className='me-5'>
                        {/* <Button type="primary" shape="circle" icon={<SearchOutlined />} /> */}
                        <Button
                            className="text-white"
                            shape="circle"
                            style={{backgroundColor: appTheme.colors.danger}}
                            icon={record?.etat === 1 ? <FontAwesomeIcon icon={faLockOpen} /> : <FontAwesomeIcon icon={faLock} />}
                            size={"middle"}
                            onClick={() => handleUpdateUserEtat(record.id, record?.etat_user === 1 ? 0 : 1)}

                        />
                    </Tooltip>}
                </Space>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = users.filter(user =>
                (user.nom_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    user.prenom_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    user.login_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    user.email_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase())) &&
                (paramsRecherche.profil_id === "" || user.profil_id === paramsRecherche.profil_id) &&
                (paramsRecherche.etat_user === "" || user.etat_user === paramsRecherche.etat_user)
            );
            setFiltersItems(filteredClients)
            setLoading(false);
        }, 2000);
    };

    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            infosUser: "",
            profil_id: "",
            etat_user: "",
            date_debut: "",
            date_fin: "",
        });
        setFiltersItems(users)
        setLoading(false);
    };

    return (
        <>
            <Fragment>
                <HStack justifyContent="space-between" alignItems="center">
                    <PageLabel label="Liste des utilisateurs"/>

                    {isAdmin && <Link to={`/utilisateurs/ajout`}>
                        <Tag
                            className="fs-6 fw-bolder py-1 px-7"
                            icon={<FontAwesomeIcon style={{marginRight: "5px"}} icon={faFolderPlus} />}
                            color={theme.colors.primary}
                        >
                            Nouvel utilisateur
                        </Tag>
                    </Link>}
                </HStack>
                <SearchRow
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    loading={loading}
                >
                    <Col span={6}>
                        <FormLabel label="Utilisateur" />
                        <Input
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Nom, prenom, initial, téléphone, email"
                            onChange={(e) =>
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    infosUser: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col span={6}>
                        <FormLabel label="Profil" />
                        <Select
                            placeholder="Profil de l'utilisateur"
                            size="large"
                            style={{ width: "100%" }}
                            onSelect={(value) =>
                                setParamsRecherche({ ...paramsRecherche, profil_id: value })
                            }
                            options={profils?.map((profil) => ({
                                value: profil.id,
                                label: profil.libelleProfil,
                            }))}
                        />
                    </Col>
                    <Col span={6}>
                        <FormLabel label="Etat" />
                        <Select
                            placeholder="Etat de l'utilisateur"
                            size="large"
                            style={{ width: "100%" }}
                            onSelect={(value) =>
                                setParamsRecherche({ ...paramsRecherche, etat_user: value })
                            }
                            options={[
                                {label: "ACTIF", value: 1},
                                {label: "INACTIF", value: 0},
                            ]}
                        />
                    </Col>
                </SearchRow>
                <FormContainer>
                    <div className="w-100">
                        <div className="py-4">
                            <div className="table-responsive">
                                <Table
                                    columns={dataColumns}
                                    rowKey={(record) => record.id}
                                    dataSource={filtersItems}
                                    pagination={tableParams.pagination}
                                    loading={loading}
                                    onChange={handleTableChange}
                                />
                            </div>
                        </div>
                    </div>
                </FormContainer>
                {isModalOpen && selectedModal === 1 &&(
                    <UserProfilChange
                        handleVisibility={handleModalUserOpen}
                        data={user}
                        visible={isModalOpen}
                        profils={profils}
                        handleValider={handleProfilChange}
                        chargement={loadingUpdate}
                    />
                )}
                {showPassword &&
                    <Modal
                        title="Nouveau de passe"
                        open={showPassword}
                        onOk={()  => setShowPassword(true)}
                        onCancel={()  => setShowPassword(false)}
                        // width={600}
                        maskStyle={{
                            backdropFilter: "blur(5px)",
                            background: `linear-gradient(70deg, ${theme.colors.primary}60, ${theme.colors.alter}60),${theme.colors.primary}60`,
                        }}
                    >
                        <p className="mx-auto">
                            <TagItem><span className="fs-3 fw-bolder">{passwdResult}</span></TagItem>
                        </p>
                    </Modal>
                }
            </Fragment>

        </>
    );
};


export default UsersList;
