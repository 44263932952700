import clsx from 'clsx';
import {optionsSwal, succesMessage, toAbsoluteUrl} from '../../../helpers/AssetsHelpers';
import Swal from 'sweetalert2'
import swal from 'sweetalert'
import {Link} from "react-router-dom";
import {getListeClients, updateEtatClient} from "../../../data/client";
import {getListeSecteur} from "../../../data/params/secteur";
import {getListeStatut} from "../../../data/params/statut";
import {ajouterExecice, getListeExercice, getListeUserMission} from "../../../data/exercice";
import "../clients.css";
import {
    AuditOutlined,
    PaperClipOutlined,
} from "@ant-design/icons";
import {Button, Col, Input, notification, Select, Space, Table, Tag, Tooltip} from "antd";
import {Fragment, useEffect, useState} from "react";
import {appTheme} from '../../../config/theme';
import {useSelector} from "react-redux";
import PageLabel from "../../../components/common/PageLabel/PageLabel";
import {FormContainer, TagItem} from "../../../components/common/common.components";
import {ModalClientExercice} from "../../../components/modals/clients/ModalClientExercice";
import {REQUEST_STATUS} from "../../../helpers/Constants";
import FormLabel from "../../../components/common/Form/FormLabel";
import SearchRow from "../../../components/common/SearchRow";
import TableTitle from "../../../components/common/TableTitle";
import {
    faEdit, faFileEdit,
    faFolder, faFolderPlus, faFolderTree,
    faLock, faLockOpen,
    faPhoneAlt,
    faSquarePollHorizontal
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ButtonAlter} from "../../../components/common/button.components";
import {useTheme} from "styled-components";
import {HStack} from "@chakra-ui/react";
import FileUploader from "../../../components/client/outils/upload/FileUploader";

const ClientList = () => {
    const theme = useTheme()
    const [clients, setClients] = useState([]);
    const [clientsMission, setClientsMission] = useState([]);
    const [client, setClient] = useState({});
    const [statuts, setStatuts] = useState([])
    const [secteurs, setSecteurs] = useState([])
    const [filtersItems, setFiltersItems] = useState([]);
    // const [message, setMessage] = useState(false);
    // const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedModal, setSelectedModal] = useState(null)
    const [sommaires, setSommaires] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [usersMission, setUsersMission] = useState([]);
    const [loadingExerciceCreate, setLoadingExerciceCreate] = useState(false);
    const [paramsRecherche, setParamsRecherche] = useState({
        infosClient: "",
        secteur_id: "",
        statut_id: "",
    });
    const user = useSelector(state => state.user)
    const isAdmin = user.libelleProfil === "Admin"
    // const date = new Date()

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
    });

    useEffect(() => {
        fetchClients();
        fetchStatuts();
        fetchSecteurs();
    }, []);

    useEffect(() => {
        getListeExercice()
            .then((res) => {
                const updatedClients = clients.map((client) => {
                    const clientMissions = res.filter((exercice) => exercice?.client_id === client?.id);
                    const missions = clientMissions.find(
                        (exercice) => usersMission.some((element) => (exercice.id === element.exercice_id) && (user.id === element.membre_id))
                    );

                    const isVisible = !!missions;

                    return {
                        ...client,
                        mission: missions || null,
                        isVisible,
                    };
                });

                const missionsTable = isAdmin ? updatedClients : updatedClients.filter((client) => client.isVisible === true);

                setClientsMission(missionsTable)
                setFiltersItems(missionsTable)
            })
    }, [clients, user, usersMission, isAdmin]);

    useEffect(() => {
        if(user){
            fetchUserMission(user.id);
        }
    }, [user]);

    const fetchClients = () => {
        setLoading(true);
        getListeClients()
            .then((res) => {
                setClients(res.result);
                setFiltersItems(res.result)
                setLoading(false);
            });
    };

    const fetchSecteurs = () => {
        setLoading(true);
        getListeSecteur()
            .then((res) => {
                setSecteurs(res.result);
                setLoading(false);
            });
    };

    const fetchStatuts = () => {
        setLoading(true);
        getListeStatut()
            .then((res) => {
                setStatuts(res.result);
                setLoading(false);
            });
    };

    const fetchUserMission = (id) => {
        getListeUserMission(id)
            .then((res) => {
                if(res){
                    setUsersMission(res)
                }
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setClients([]);
        }
    };

    const handleUpdateClientEtat = async (client_id, etat) => {
        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const data = {client_id, etat}
                const {status, message} = await updateEtatClient(data)
                if (status === 'error') {
                    await swal(`${message}`, '', 'danger')
                }
                // setMessage(message)
                if (status === 'success') {
                    // setError(false)
                    fetchClients()
                    setLoading(false)
                    await swal(succesMessage, '', 'success')
                } else {
                    // setError(true)
                    setLoading(false)
                }
            }
        })
    }

    const handleCreerExercice = (data) => {
        setLoadingExerciceCreate(true)
        let datas = {
            client_id: data.client_id,
            chef_id: data.chef_id,
            sommaires: sommaires,
            typeMission_id: data.typeMission_id,
            role_id: data.role_id,
            annee: data.annee,
            mission: data.mission,
        };

        ajouterExecice(datas)
            .then(
                (res) => {
                    const {status, message} = res
                    if (status === REQUEST_STATUS.SUCCESS) {
                        handleModalExerciceToggle();
                        fetchClients();
                        setLoadingExerciceCreate(false)
                        notification.success({
                            description: message,
                        });
                    } else {
                        notification.error({
                            description: message,
                        });
                        setLoadingExerciceCreate(false)
                    }
                },
                (err) => {
                    notification.error({
                        description: err.data.message,
                    });
                }
            );
    };

    const handleModalExerciceToggle = (modalId, data) => {
        setClient(data)
        setSelectedModal(modalId)
        setIsModalOpen((state) => !state);
    };

    const dataColumns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Logo</TableTitle>,
            key: "photo",
            dataIndex: "photo",
            render: (_, record) => (
                <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    {record.logo_client ? (
                        <div className='symbol-label'>
                            <img
                                src={toAbsoluteUrl(`/media/${record.logo_client}`)}
                                alt={record.denomination}
                                className='w-100'
                            />
                        </div>
                    ) : (
                        <div className={clsx('symbol-label fs-3', `bg-green`)} style={{backgroundColor: appTheme.colors.bgColor}}>
                            {record.denomination[0].toUpperCase() + '' + record.denomination[1].toUpperCase()}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Nom</TableTitle>,
            key: "nom",
            dataIndex: "nom",
            render: (_, record) => (
                <>
                  <span style={{ color: "#000", fontWeight: "bold" }}>
                    {record.denomination}
                  </span>
                    <br />
                    {record.sigle && <TagItem color={appTheme.colors.primary}>{record.sigle}</TagItem>}
                    <br/>
                    {record.cel1 && <Tag
                        color={appTheme.colors.primary_blue}
                        style={{fontWeight: "600"}}
                        icon={<FontAwesomeIcon icon={faPhoneAlt} style={{marginRight: "5px"}}/>}

                    >
                        {record.cel1}
                    </Tag>}
                </>
            ),
        },
        {
            title: () => <TableTitle>Statut</TableTitle>,
            dataIndex: "libelle_status",
            render: (_, record) => (
                <>
                    <TagItem>{record.libelle_status}</TagItem>
                </>
            ),
        },
        {
            title: () => <TableTitle>Secteur d'activité</TableTitle>,
            dataIndex: "secteur_id",
            render: (_, record) => (
                <>
                      <span style={{ color: "#24247a", fontWeight: "bold" }}>
                        {record?.libelle_secteur}
                      </span>
                </>
            ),
        },
        {
            title: () => <TableTitle>Dernière(s) mission(s)</TableTitle>,
            dataIndex: "libelle_exercice",
            align: 'center',
            render: (_, record) => (
                <>
                    {record.mission && (
                        <>
                            <Tag
                                icon={<AuditOutlined />}
                                style={{ fontSize: "14px", fontWeight: "800px" }}
                                color={appTheme.colors.primary}
                            >
                                {record?.mission.mission}
                            </Tag>
                            <br/>
                            <TagItem>{record?.mission.annee}</TagItem>
                        </>
                    )}
                </>
            ),
        },
        {
            title: () => <TableTitle>Dossier permanent</TableTitle>,
            dataIndex: "dossier",
            render: (_, record) => (
                <>
                    <Link style={{marginRight: '10px'}} to={`/clients/details/${record.id}`} state={{client: record, link: 1}}>
                        <Tooltip title="Accéder au dossier permanent">
                            <Tag style={{fontSize: '28px'}} icon={<FontAwesomeIcon icon={faFolder} color={appTheme.colors.primary} />} />
                        </Tooltip>
                    </Link>
                </>
            ),
        },
        {
            title: () => <TableTitle>Fiche signalétique</TableTitle>,
            dataIndex: "fiche",
            render: (_, record) => (
                <>
                    <Link style={{marginRight: '10px'}} to={`/clients/details/${record.id}`} state={{client: record, link: 0}}>
                        <Tooltip title="Fiche signalétique">
                            <Tag style={{fontSize: '28px'}} icon={<FontAwesomeIcon icon={faSquarePollHorizontal} color={appTheme.colors.primary} />} />
                        </Tooltip>
                    </Link>
                </>
            ),
        },
        {
            title: () => <TableTitle>Actions</TableTitle>,
            align: "right",
            width: 150,
            render: (value, record) => (
                <Space>
                    {(record.mission || isAdmin)  &&
                        <Link to={`/clients/exploitation/fiche/${record.id}`} state={{record}}>
                            <Tooltip
                                title="Consulter Exercices"
                                color={appTheme.colors.primary_blue}
                            >
                                <Button
                                    className="text-white"
                                    shape="circle"
                                    style={{backgroundColor: appTheme.colors.primary_blue}}
                                    icon={<FontAwesomeIcon icon={faFolderTree} />}
                                />
                            </Tooltip>
                        </Link>
                    }
                    {isAdmin && <Tooltip title="Enregistrer une mission" className='me-5'>
                        <ButtonAlter
                            shape="circle"
                            type="primary"
                            icon={<FontAwesomeIcon icon={faFileEdit} />}
                            onClick={() => handleModalExerciceToggle(1, record)}
                        />
                    </Tooltip>}
                    {record.mission && <Tooltip title="Joindre un fichier" className='me-5'>
                        <Button
                            shape="circle"
                            icon={<PaperClipOutlined/>}
                            size={"middle"}
                            onClick={() => handleModalExerciceToggle(2, record)}
                        />
                    </Tooltip>}
                    {(isAdmin)  && <Link to={`/clients/edit/${record.id}`} state={{record}}>
                        <Tooltip
                            title="Modifier infos"
                            color={appTheme.colors.primary}
                        >
                            <Button
                                shape="circle"
                                style={{backgroundColor: appTheme.colors.alter}}
                                icon={<FontAwesomeIcon icon={faEdit} />}
                            />
                        </Tooltip>
                    </Link>}
                    {isAdmin && <Tooltip title={record?.etat === 1 ? 'Désactiver' : 'Activer'} className='me-5'>
                        {/* <Button type="primary" shape="circle" icon={<SearchOutlined />} /> */}
                        <Button
                            className="text-white"
                            shape="circle"
                            style={{backgroundColor: appTheme.colors.danger}}
                            icon={record?.etat === 1 ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faLockOpen} />}
                            size={"middle"}
                            onClick={() => handleUpdateClientEtat(record.id, record?.etat === 1 ? 0 : 1)}

                        />
                    </Tooltip>}
                </Space>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = clientsMission.filter(client =>
                (client.sigle?.toLowerCase()?.includes(paramsRecherche.infosClient.toLowerCase()) &&
                client.denomination?.toLowerCase()?.includes(paramsRecherche.infosClient.toLowerCase())) &&
                (paramsRecherche.secteur_id === "" || client.secteur_id === paramsRecherche.secteur_id) &&
                (paramsRecherche.statut_id === "" || client.statut_id === paramsRecherche.statut_id)
            );
            setFiltersItems(filteredClients)
            setLoading(false);
        }, 2000);
    };

    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            infosClient: "",
            secteur_id: "",
            statut_id: "",
        });
        setFiltersItems(clientsMission)
        setLoading(false);
    };

    return (
        <Fragment>
            <HStack justifyContent="space-between" alignItems="center">
                <PageLabel label="Liste des clients"/>

                {isAdmin && <Link to={`/clients/ajout`}>
                    <Tag
                        className="fs-6 fw-bolder py-1 px-7"
                       icon={<FontAwesomeIcon style={{marginRight: "5px"}} icon={faFolderPlus} />}
                        color={theme.colors.primary}
                    >
                        Nouveau Client
                    </Tag>
                </Link>}
            </HStack>
            <SearchRow
                handleSearch={handleSearch}
                handleReset={handleReset}
                loading={loading}
            >
                <Col span={8}>
                    <FormLabel label="Client" />
                    <Input
                        style={{ width: "100%" }}
                        size="large"
                        placeholder="Sigle ou dénomination"
                        // value={paramsRecherche.infoClient}
                        onChange={(e) =>
                            setParamsRecherche({
                                ...paramsRecherche,
                                infosClient: e.target.value,
                            })
                        }
                    />
                </Col>
                <Col span={8}>
                    <FormLabel label="Statut juridique" />
                    <Select
                        placeholder="Staut juridique"
                        size="large"
                        style={{ width: "100%" }}
                        onSelect={(value) =>
                            setParamsRecherche({ ...paramsRecherche, statut_id: value })
                        }
                        options={statuts.map((statut) => ({
                            value: statut.id,
                            label: statut.libelle,
                        }))}
                    />
                </Col>
                <Col span={8}>
                    <FormLabel label="Secteur d'activité" />
                    <Select
                        placeholder="Secteur d'activité"
                        size="large"
                        style={{ width: "100%" }}
                        onSelect={(value) =>
                            setParamsRecherche({ ...paramsRecherche, secteur_id: value })
                        }
                        options={secteurs.map((secteur) => ({
                            value: secteur.id,
                            label: secteur.libelle,
                        }))}
                    />
                </Col>
            </SearchRow>
            <FormContainer>
                <div className="w-100">
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                columns={dataColumns}
                                rowKey={(record) => record.id}
                                dataSource={filtersItems}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </FormContainer>
            {isModalOpen && selectedModal === 1 &&(
                <ModalClientExercice
                    handleVisibility={handleModalExerciceToggle}
                    data={client}
                    visible={isModalOpen}
                    handleValider={handleCreerExercice}
                    chargement={loadingExerciceCreate}
                    setSommaires={setSommaires}
                    sommaires={sommaires}
                />
            )}
            {isModalOpen && selectedModal === 2 &&(
                <FileUploader
                    handleVisibility={handleModalExerciceToggle}
                    data={client}
                    visible={isModalOpen}
                    handleValider={handleCreerExercice}
                    chargement={loadingExerciceCreate}
                />
            )}
        </Fragment>
    );
};


export default ClientList;
