import React, {Fragment, useEffect, useRef, useState} from "react";
import PageChildLabel from "../common/PageLabel/PageChildLabel";
import { Select, Space, Spin, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {appTheme} from "../../config/theme";
import {
    faFileExcel,
    faFilePdf, faFileWord,
    faPrint,
    faTools,
} from "@fortawesome/free-solid-svg-icons";
import {getListeExerciceClients} from "../../data/exercice";
import {areArraysEqual} from "../utilities/Utilities";
import {PrintHeaderTraitement} from "../layout/tools/PrintHeaderCycle";
import * as XLSX from "xlsx";
import docx from "html-docx-js/dist/html-docx";
import fileSaver from "file-saver";
import ReactToPrint from "react-to-print";

const PageToolbarHeader = ({
       prev,
       pageTitle,
       mission,
       setMission,
       isProvisoire,
       setIsProvisoire,
       selectYear,
       setSelectYear,
       loading,
       setLoading,
       client,
       setIsModalOpen,
       isModalOpen,
       setDate,
       setTache_id,
       tache_id,
       setIntervention_id,
       intervention_id,
       setHeureEntree,
       setUser_id,
       printTitle,
       printContent,
       isExcelPrint,
       isLandscape,
       excelData,
       codeSommaire,
       isEF
   }) => {
    const [missions, setMissions] = useState([]);
    const [arrayYear, setArrayYear] = useState([]);
    const [filteredMissions, setFilteredMissions] = useState([]);
    const [identLoading, setIdentLoading] = useState(false);
    const [isToggleOpen, setIsToggleOpen] = useState(false);

    const componentRef = useRef();

    useEffect(() => {
        if(client){
            getListeExerciceClients(client?.id)
                .then((res) => {
                    setMissions(res);
                    const years = [...new Set(res.map((item) => item.annee))];
                    const yearsFiltered  = years.sort((a, b) => parseInt(b) - parseInt(a));

                    setArrayYear(yearsFiltered);
                });
        }
    }, [client]);

    useEffect(()=>{
        if((filteredMissions && filteredMissions.length <= 0) && selectYear){
            const miss = missions.filter((item) => parseInt(item.annee) === parseInt(selectYear));
            if(miss && miss.length > 0) {
                if (!areArraysEqual(miss, filteredMissions)) {
                    setFilteredMissions(miss);
                    setMission(miss[0])
                }
            }else{
                setFilteredMissions([])
            }
        }
    }, [filteredMissions, missions, selectYear, setMission])

    const onChangeFiltered = (values) => {
        setLoading(true);
        setTimeout(() => {
            setSelectYear(values)

            const miss = missions.filter((item) => parseInt(item.annee) === parseInt(values));
            if(miss && miss.length > 0) {
                setFilteredMissions(miss)
                setMission(miss[0])
            }else{
                setFilteredMissions([])
            }
            setLoading(false)
        }, 1000)
    }

    const onChangeMission = (values) => {
        setLoading(true);
        setTimeout(() => {
            const miss = missions.find((item) => item.id === parseInt(values));
            if(miss){
                setMission(miss)
            }else {
                setMission(mission)
            }
            setLoading(false)
        }, 1000)
    }

    const handleCheckboxChange = (isChecked) => {
        setLoading(true)
        setTimeout(() => {
            setIsProvisoire(isChecked ? "1" : "2");
            setLoading(false)
        }, 1000)
    };

    // const handleModalOpen = (value) => {
    //     setIdentLoading(true)
    //     setTimeout(() => {
    //         setIsModalOpen(false);
    //         setIdentLoading(false)
    //
    //         setTache_id(value.tache_id)
    //         setIntervention_id(value.intervention_id)
    //     }, 1000)
    // };

    const toggleMenu = (e) => {
        e.preventDefault()
        setIsToggleOpen(!isToggleOpen);
    };

    const exportToWord = () => {
        const content = document.getElementById("print-content").innerHTML;

        const fullContent = `
          <html xmlns:w="urn:schemas-microsoft-com:office:word" lang="en">
            <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <style>
                body { margin:0; }
            </style>
            <title></title>
            </head>
            <body>
              <w:sectPr>
                <w:headerReference w:type="default" r:id="rId1"/>
              </w:sectPr>
              ${content}
            </body>
          </html>
        `;

        const options = {
            orientation: isLandscape ? 'Landscape' : 'portrait',
            encoding: 'UTF-8'
        };

        const converted = docx.asBlob(fullContent, options);
        fileSaver.saveAs(converted, `${printTitle || ""}.docx`);
    };

    const pageStyle = `
    .page-footer, .page-footer-space {
        height: auto;
        display:block;
        margin-top:"100px";
      }
      
      .page-footer {
        position: absolute;
        bottom: 0;
        // display:block;
        width: 100%;
        border-top: 1px solid black;
        background: yellow; 
        opacity:0.3;
        
      }

      .page {
        page-break-after: always;
      }
      
      @page {
        margin-bottom: 20mm;
        margin-top: 10mm;
        size: ${isLandscape ? "A4 Landscape" : "A4 portrait"}
        orientation: ${isLandscape ? "Landscape" : "portrait"};
    }
    `;

    const handleExportExcel = (exelData) => {
        if(isEF) {
            let wsActif = XLSX.utils.json_to_sheet(exelData[0].data);
            let wsPassif = XLSX.utils.json_to_sheet(exelData[1].data);
            let wsResults = XLSX.utils.json_to_sheet(exelData[2].data);
            let wsSig = XLSX.utils.json_to_sheet(exelData[3].data);

            let wb = XLSX.utils.book_new()

            XLSX.utils.book_append_sheet(wb, wsActif, exelData[0].libelle);
            XLSX.utils.book_append_sheet(wb, wsPassif, exelData[1].libelle);
            XLSX.utils.book_append_sheet(wb, wsResults, exelData[2].libelle);
            XLSX.utils.book_append_sheet(wb, wsSig, exelData[3].libelle);
                // XLSX.utils.book_append_sheet(wb, ws, ((`TRAITEMENTS - ${codeSommaire || ""} - ${selectYear}`) || ""));

            XLSX.writeFile(wb, `${((`TRAITEMENTS - ${codeSommaire || ""} - ${selectYear}`) || "")}.xlsx`)

        }
        else {
            let wb = XLSX.utils.book_new(),
                ws = XLSX.utils.json_to_sheet(exelData);

            XLSX.utils.book_append_sheet(wb, ws, ((`TRAITEMENTS - ${codeSommaire || ""} - ${selectYear}`) || ""));
            // XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

            XLSX.writeFile(wb, `${((`TRAITEMENTS - ${codeSommaire || ""} - ${selectYear}`) || "")}.xlsx`)
        }
    }

    return(
        <Fragment>
            <div className="mb-3">
                <div className="d-flex justify-content-between">
                    <PageChildLabel label={pageTitle} />
                    <div className="d-flex justify-content-between">
                        <div className="d-flex me-15 mb-4">
                            <Space>
                                <div className="d-flex ms-5">
                                    <Tooltip title="Imprimer le cycle">
                                        <div className="d-flex flex-column align-items-center ">
                                            <button
                                                className="btn btn-sm btn-flex btn-secondary fw-bold fs-7"
                                                data-kt-menu-trigger="click"
                                                data-kt-menu-placement="bottom-end"
                                                onClick={toggleMenu}
                                            >
                                                <FontAwesomeIcon icon={faPrint} className="me-3" />
                                                Exporter
                                            </button>
                                            {isToggleOpen && (
                                                <>
                                                    <div
                                                        id="kt_users_lists_views_export_menu"
                                                        className="menu menu-sub menu-sub-dropdown menu-option menu-column menu-rounded menu-gray-600 menu-state-bg-primary fw-bold fs-7 w-150px py-4 show"
                                                        data-kt-menu="true"
                                                        data-popper-placement="bottom-end"
                                                    >
                                                        <div className="menu-item px-3 py-2 bg-hover-warning text-hover-white rounded-2">
                                                            <ReactToPrint
                                                                trigger={() => (
                                                                    <button className="fw-bold fs-7 text-hover-white"
                                                                            data-kt-menu-trigger="click"
                                                                            data-kt-menu-placement="bottom-end"
                                                                            onClick={() => {}}
                                                                    >
                                                                        <FontAwesomeIcon icon={faFilePdf} style={{fontSize: "16px"}} className="me-3" />
                                                                        PDF
                                                                    </button>
                                                                )}
                                                                content={() => componentRef.current}
                                                                documentTitle=''
                                                                pageStyle={pageStyle}
                                                            />
                                                        </div>
                                                        <div className="menu-item px-3 py-2 bg-hover-warning text-hover-white rounded-2">
                                                            <button className="fw-bold fs-7 text-hover-white"
                                                                    data-kt-menu-trigger="click"
                                                                    data-kt-menu-placement="bottom-end"
                                                                    onClick={(e) => {
                                                                        e.preventDefault()
                                                                        exportToWord()
                                                                    }}
                                                            >
                                                                <FontAwesomeIcon icon={faFileWord} style={{fontSize: "16px"}} className="me-3" />
                                                                WORD
                                                            </button>
                                                        </div>
                                                        {isExcelPrint && <div
                                                            className="menu-item px-3 py-2 bg-hover-warning text-hover-white rounded-2">
                                                            <button
                                                                className="fw-bold border-transparent fs-7 text-hover-white"
                                                                data-kt-menu-trigger="click"
                                                                data-kt-menu-placement="bottom-end"
                                                                onClick={() => handleExportExcel(excelData || [])}
                                                            >
                                                                <FontAwesomeIcon icon={faFileExcel}
                                                                                 style={{fontSize: "16px"}}
                                                                                 className="me-3"/>
                                                                EXCEL
                                                            </button>
                                                        </div>}
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </Tooltip>
                                </div>
                            </Space>
                        </div>
                        <div className="d-flex ms-5">
                            <div>
                                <Tooltip title="Quitter le cycle" color="red">
                                    <button
                                        className="btn btn-sm btn-danger me-3"
                                        onClick={prev}
                                    >
                                        Retour
                                    </button>
                                </Tooltip>
                            </div>
                            {/*<Tooltip title="Voir plus">*/}
                            {/*    <div className="d-flex flex-column align-items-center ">*/}
                            {/*        <button*/}
                            {/*            className="btn btn-sm btn-flex btn-primary fw-bold fs-9"*/}
                            {/*            data-kt-menu-trigger="click"*/}
                            {/*            data-kt-menu-placement="bottom-end"*/}
                            {/*            onClick={toggleMenu}*/}
                            {/*        >*/}
                            {/*            <EllipsisOutlined />*/}
                            {/*        </button>*/}
                            {/*        {isToggleOpen && (*/}
                            {/*            <>*/}
                            {/*                <div*/}
                            {/*                    id="kt_users_lists_views_export_menu"*/}
                            {/*                    className="menu menu-sub menu-sub-dropdown menu-option menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4 show"*/}
                            {/*                    data-kt-menu="true"*/}
                            {/*                    data-popper-placement="bottom-end"*/}
                            {/*                >*/}
                            {/*                    <div className="menu-item px-3">*/}
                            {/*                        Test*/}
                            {/*                    </div>*/}
                            {/*                    <div className="menu-item px-3">*/}
                            {/*                        Test 2*/}
                            {/*                    </div>*/}
                            {/*                    <div className="menu-item px-3">*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}
                            {/*            </>*/}
                            {/*        )}*/}
                            {/*    </div>*/}
                            {/*</Tooltip>*/}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-end align-items-end">
                    <div style={{
                        border: "0px solid rgba(100, 100, 100, 1)",
                        borderRadius: "64px",
                        boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.3)",
                        opacity: "0.7",
                        padding: "5px"
                    }}
                    >
                        <div className="d-flex align-center">
                            <div
                                className="me-lg-15 me-5 pt-3 ms-3"
                            >
                                <FontAwesomeIcon color={appTheme.colors.primary} style={{fontSize: "20px"}} icon={faTools} />
                            </div>
                            <div style={{
                                border: "0px solid rgba(100, 100, 100, 1)",
                                borderRadius: "64px",
                                boxShadow: "0px 0px 7px 0px rgba(0, 0, 0, 0.3)",
                            }}
                            >
                                <div className="d-flex">
                                    <div className="row g-2 me-lg-10">
                                        <div className="col">
                                            <label className={`btn btn-outline btn-outline-dashed ${parseInt(isProvisoire) === 1 ? "bg-primary": ""} d-flex text-start p-2`}>
                                                <span className="form-check form-check-custom form-check-sm align-items-start mt-1">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="accept"
                                                        checked={parseInt(isProvisoire) === 1}
                                                        onChange={(e) =>{
                                                            handleCheckboxChange(e.target.checked)
                                                        }}
                                                    />
                                                </span>
                                                <span className="ms-3">
                                                    <span style={{fontSize: "12px", fontWeight: "700", fontFamily: "Oswald"}}>PROVISOIRE</span>
                                               </span>
                                            </label>
                                        </div>
                                        <div className="col">
                                            <label className={`btn btn-outline btn-outline-dashed d-flex ${parseInt(isProvisoire) === 2 ? "bg-primary": ""} text-start p-2`} data-kt-button="true">
                                                <span className="form-check form-check-custom form-check-sm align-items-start mt-1">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="accept"
                                                        checked={parseInt(isProvisoire) === 2}
                                                        onChange={(e) =>
                                                            handleCheckboxChange(!e.target.checked)
                                                        }
                                                    />
                                                </span>
                                                <span className="ms-3">
                                                    <span style={{fontSize: "12px", fontWeight: "700", fontFamily: "Oswald"}}>DEFINITIF</span>
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="d-sm-block d-flex align-center">
                                        <label className="bg-primary text-white rounded-3 mx-lg-3 mx-2 my-1">
                                            <Select
                                                placeholder="Année"
                                                variant="borderless"
                                                bordered={false}
                                                onChange={onChangeFiltered}
                                                value={selectYear ?? null}
                                                style={{
                                                    flex: 1,
                                                }}
                                                options={(arrayYear ?? []).map((year) => ({
                                                        label: (<span style={{fontWeight: "700", fontFamily: "Oswald"}}>{year}</span>),
                                                        value: year
                                                    }
                                                ))}
                                            />
                                        </label>
                                        <label className="bg-hover-warning border-hover text-hover-white rounded-3 p-1">
                                            <Select
                                                placeholder="Mission"
                                                variant="borderless"
                                                bordered={false}
                                                onChange={onChangeMission}
                                                value={mission.id || null}
                                                style={{
                                                    flex: 1,
                                                }}
                                                options={(filteredMissions ?? []).map((mission) => ({
                                                        label: (<span style={{fontWeight: "700", fontFamily: "Oswald"}}>{`${mission.mission} - ${mission.annee}`}</span>),
                                                        value: mission.id
                                                    }
                                                ))}
                                            />
                                        </label>
                                    </div>
                                    {loading &&<div className="mx-5 pt-2"> <Spin /></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-none" >
                <div ref={componentRef} className="print-content" id="print-content" >
                    <PrintHeaderTraitement
                        title={printTitle || ""}
                        client={client}
                        exercice={mission}
                        tache_id={tache_id}
                        intervention_id={intervention_id}
                        isProvisoire={isProvisoire}
                    />
                    {printContent}
                </div>
            </div>
            {/*{isModalOpen && <IdentificationUser*/}
            {/*    setDate={setDate}*/}
            {/*    setTache_id={setTache_id}*/}
            {/*    tache_id={tache_id}*/}
            {/*    setIntervention_id={setIntervention_id}*/}
            {/*    intervention_id={intervention_id}*/}
            {/*    setUser_id={setUser_id}*/}
            {/*    mission={mission}*/}
            {/*    visible={isModalOpen}*/}
            {/*    prev={prev}*/}
            {/*    handleValider={handleModalOpen}*/}
            {/*    setHeureEntree={setHeureEntree}*/}
            {/*    loading={identLoading}*/}
            {/*/>}*/}
            {/*<IdentificationUser*/}
            {/*    setDate={setDate}*/}
            {/*    setTache_id={setTache_id}*/}
            {/*    tache_id={tache_id}*/}
            {/*    setIntervention_id={setIntervention_id}*/}
            {/*    intervention_id={intervention_id}*/}
            {/*    setUser_id={setUser_id}*/}
            {/*    mission={mission}*/}
            {/*    visible={isModalOpen}*/}
            {/*    prev={prev}*/}
            {/*    handleValider={handleModalOpen}*/}
            {/*    setHeureEntree={setHeureEntree}*/}
            {/*    loading={identLoading}*/}
            {/*/>*/}
        </Fragment>
    )

}

export default PageToolbarHeader;