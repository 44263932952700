import React, {useEffect, useState} from "react";
import {Demarche} from "./Demarche";
import {TravauxAudit} from "./TravauxAudit";
import {Ajustements} from "./Ajustements";
import {Conclusion} from "./Conclusion";
import {ObservationGenerale} from "./ObservationGenerale";
import {getProcedureAudit} from "../../../../../../../data/procedureAudit";
import {notification, Tooltip} from "antd";

const steps = [
    {
        id: 1,
        title: 'Démarche',
        description: 'Démarche',
    },
    {
        id: 2,
        title: 'Travaux d\'Audit',
        description: 'Travaux d\'Audit',
        children: []
    },
    {
        id: 3,
        title: 'Ajustement',
        description: 'Ajustement',
        children: []
    },
    {
        id: 4,
        title: 'Conclusion',
        description: 'Conclusion',
        children: []
    },
    {
        id: 5,
        title: 'Observation Générale',
        description: 'Observation Générale',
        children: []
    },
];

const TraitementProcedures = ({
      mission,
      client,
      tabMaitresse,
      procedure_id,
      menuLibelle,
      compteResultat,
      bilan,
      etatAnnexe,
      objectifs,
      code,
      isAddMemoire,
      isProvisoire,
      setIsAddMemoire,
      setPrintData,
      observation,
      setObservation,
      conclusionGenerale,
      setConclusionGenerale,
      totalsData,
      setTotalsData,
      descriptionsData,
      setDescriptionsData,
      chargement,
      setIsTaskSave,
      isTaskSave,
}) => {
    const [activeTab, setActiveTab] = useState(1);
    const [travauxAuditData, setTravauxAuditData] = useState([]);
    const [travauxAjustementData, setTravauxAjustementData] = useState([]);
    const [procedure, setProcedure] = useState(null);
    const [loading, setLoading] = useState(null);

    useEffect(()=> {
        getProcedureAudit(mission.id)
            .then((res) => {
                if(res.result){
                    const procedureGet = res.result.find((item) => item.codeSommaire === code)
                    if(procedureGet){
                        setProcedure(procedureGet);
                        setObservation(procedureGet.observation);
                        setTravauxAuditData(procedureGet?.Lea_TravauxAudits);
                        setTravauxAjustementData(procedureGet?.Lea_TravauxAjustements);
                        setDescriptionsData(procedureGet?.Lea_TravauxAjustements);
                    }
                    else{
                        setProcedure([]);
                        setTravauxAuditData([]);
                        setTravauxAjustementData([]);
                    }
                }
            })
    }, [code, mission])

    const handleTabChange = (tabIndex) => {
        if(isTaskSave){
            notification.warning({
                description: "Veuillez enregistrer le cycle courant !",
            });
        }

        setLoading(true);
        setTimeout(() => {
            setActiveTab(tabIndex);
            setLoading(false);
        }, 1000)
    };

    return (
        <>
            <div className='mt-3'>
                <div className="row mb-5">
                    <div className="col-sm-12 col-lg-12">
                        <div className="mb-2 mx-3 hover-scroll-x">
                            <div className="d-grid">
                                <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                                    {steps.map((step, index) => (
                                        <li key={index} className="nav-item">
                                            <Tooltip title={step.description}>
                                                <button
                                                    data-bs-toggle="tab"
                                                    className={`nav-link fs-5 text-uppercase btn btn-active-light btn-color-gray-800 btn-active-color-primary rounded-bottom-0 ${activeTab === index ? 'active' : ''}`}
                                                    onClick={() => handleTabChange(index)}
                                                >
                                                    {step.title}
                                                </button>
                                            </Tooltip>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Content */}
                <div className="stepper-form">
                    <div className="tab-content" id="myTabContent">
                        <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`} id="controle" role="tabpanel">
                            <Demarche
                                objectifs={objectifs}
                                bilan={bilan}
                                compteResultat={compteResultat}
                                etatAnnexe={etatAnnexe}
                                chargement={chargement || loading}
                            />
                        </div>
                        <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} role="tabpanel">
                            <TravauxAudit
                                mission={mission}
                                client={client}
                                tabMaitresse={tabMaitresse}
                                setConclusionGenerale={setConclusionGenerale}
                                conclusionGenerale={conclusionGenerale}
                                procedure_id={procedure_id}
                                menuLibelle={menuLibelle}
                                setPrintData={setPrintData}
                                code={code}
                                travauxData={travauxAuditData}
                                isProvisoire={isProvisoire}
                                isAddMemoire={isAddMemoire}
                                setIsAddMemoire={setIsAddMemoire}
                                chargement={chargement || loading}
                                setIsTaskSave={setIsTaskSave}
                                isTaskSave={isTaskSave}
                            />
                        </div>

                        <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`} role="tabpanel">
                            <Ajustements
                                mission={mission}
                                client={client}
                                procedure_id={procedure_id}
                                menuLibelle={menuLibelle}
                                code={code}
                                travauxData={travauxAjustementData}
                                totalsData={totalsData}
                                isProvisoire={isProvisoire}
                                setTotalsData={setTotalsData}
                                descriptionsData={descriptionsData}
                                setDescriptionsData={setDescriptionsData}
                                setIsTaskSave={setIsTaskSave}
                                isTaskSave={isTaskSave}
                                chargement={chargement || loading}
                            />
                        </div>

                        <div className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`} role="tabpanel">
                            <Conclusion
                                conclusionGenerale={conclusionGenerale}
                                procedure={procedure}
                                setConclusion={setConclusionGenerale}
                                procedure_id={procedure_id}
                                code={code}
                                mission={mission}
                                isProvisoire={isProvisoire}
                                setIsTaskSave={setIsTaskSave}
                                isTaskSave={isTaskSave}
                                chargement={chargement || loading}
                            />
                        </div>

                        <div className={`tab-pane fade ${activeTab === 4 ? 'show active' : ''}`} role="tabpanel">
                            <ObservationGenerale
                                observation={observation}
                                setObservation={setObservation}
                                procedure_id={procedure_id}
                                isProvisoire={isProvisoire}
                                code={code}
                                mission={mission}
                                setIsTaskSave={setIsTaskSave}
                                isTaskSave={isTaskSave}
                                chargement={chargement || loading}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export {TraitementProcedures}