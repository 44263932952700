import React, { useState} from "react";
import TableTitle from "../../../../../common/TableTitle";
import {Divider, Input, Table, Tag, Typography} from "antd";
import {appTheme} from "../../../../../../config/theme";

const { Title } = Typography;

const ClientModalMesures = ({ mission, setSelectedElementsListAudit, setSelectedElementsListEntity, selectedElementsListAudit, selectedElementsListEntity, acceptValid }) => {
    const [loading, setLoading] = useState(false);
    // const [entities, setEntities] = useState([]);
    // const [auditors, setAuditors] = useState([]);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        // if (pagination.pageSize !== tableParams.pagination?.pageSize) {
        //     setEntities([]);
        //     setAuditors([]);
        // }
    };

    // useEffect(() => {
    //     setLoading(true);
    //     setTimeout(() => {
    //         setEntities(selectedElementsListEntity?.filter(item => item.isChecked !== item.defaultResponse) || []);
    //         setAuditors(selectedElementsListAudit?.filter(item => item.isChecked !== item.defaultResponse) || []);
    //         setLoading(false)
    //     }, 1500);
    // }, [selectedElementsListAudit, selectedElementsListEntity])


    const columnsEntity = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Questions</TableTitle>,
            dataIndex: 'name',
            width: 450,
            render: (_, record) => (
                <>
                   <span className="fw-bolder fs-5">
                       {record.libelle}
                   </span>
                </>
            ),
        },
        {
            title: () => <TableTitle>Réponse</TableTitle>,
            dataIndex: 'reponse',
            width: 100,
            render: (_, record) => (
                <Tag style={{fontSize: "14px", fontWeight: "700"}}>{record.isChecked ? "OUI" : "NON"}</Tag>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'motif',
            width: 350,
            render: (_, record) => (
                <Input.TextArea
                    size="large"
                    value={record.motif}
                    bordered={false}
                    style={{width: "100%"}}
                    autoSize={{
                        minRows: 2,
                        maxRows: 6,
                    }}
                />
            ),
        },
        {
            title: () => <TableTitle>Mesures</TableTitle>,
            dataIndex: 'commentaires',
            width: 450,
            render: (_, record) => (
                <>
                    <Input.TextArea
                        size="large"
                        placeholder="Saisissez la mesure de sauvegarde"
                        bordered={false}
                        className={`${record.motifRequired !== true ? '' : 'border-1 is-invalid'}`}
                        required={record.motifRequired === true}
                        value={record.mesure_sauve}
                        readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || acceptValid}
                        onChange={(e) =>{
                            const updatedList = selectedElementsListEntity?.map((item, i) =>
                                item.question_id === record.question_id ? { ...item,  mesure_sauve: e.target.value, mesureRequired: !item.mesure_sauve && item.isChecked !== item.defaultResponse } : item
                            );
                            setSelectedElementsListEntity(updatedList);
                        }}
                        style={{width: "100%", border: record.mesureRequired === true ? "1px solid red" : "0"}}
                        showCount
                        maxLength={5000}
                        autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }}
                    />
                    {record.mesureRequired === true && (
                        <div className="text-danger">Champ obligatoire</div>
                    )}
                </>
            ),
        },
    ];

    const columnsAudit = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Questions</TableTitle>,
            dataIndex: 'name',
            width: 450,
            render: (_, record) => (
                <>
                   <span className="fw-bolder fs-5">
                       {record.libelle}
                   </span>
                </>
            ),
        },
        {
            title: () => <TableTitle>Réponse</TableTitle>,
            dataIndex: 'reponse',
            width: 100,
            render: (_, record) => (
                <Tag style={{fontSize: "14px", fontWeight: "700"}}>{record.isChecked ? "OUI" : "NON"}</Tag>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'motif',
            width: 350,
            render: (_, record) => (
                <Input.TextArea
                    size="large"
                    value={record.motif}
                    bordered={false}
                    style={{width: "100%"}}
                    autoSize={{
                        minRows: 2,
                        maxRows: 6,
                    }}
                />
            ),
        },
        {
            title: () => <TableTitle>Mesures</TableTitle>,
            dataIndex: 'commentaires',
            width: 450,
            render: (_, record) => (
                <>
                    <Input.TextArea
                        size="large"
                        placeholder="Saisissez la mesure de sauvegarde"
                        className={`${record.motifRequired !== true ? '' : 'is-invalid'}`}
                        required={record.motifRequired === true}
                        value={record.mesure_sauve}
                        bordered={false}
                        readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || acceptValid}
                        onChange={(e) =>{
                            const updatedList = selectedElementsListAudit?.map((item) =>
                                item.question_id === record.question_id ? { ...item,  mesure_sauve: e.target.value, mesureRequired: !item.mesure_sauve && item.isChecked !== item.defaultResponse } : item
                            );
                            setSelectedElementsListAudit(updatedList);
                        }}
                        style={{width: "100%", border: record.mesureRequired === true ? "1px solid red" : "0"}}
                        showCount
                        maxLength={5000}
                        autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }}
                    />
                    {record.mesureRequired === true && (
                        <div className="text-danger">Champ obligatoire</div>
                    )}
                </>
            ),
        },
    ];

    return(
        <>
            <div className=" pt-15">
                <div className="card-header">
                    <div className="card-title">
                        <Title style={{ display: "block", fontWeight: '600', color: appTheme.colors.primary_blue}} level={3}>EB1.3 - Mesures de sauvegarde</Title>
                    </div>
                </div>
                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6 mx-15">
                    <div className="d-flex flex-stack flex-grow-1 ">
                        <div className=" fw-semibold">
                            <h4 className="text-gray-900 fw-bold">Attention!</h4>

                            <div className="fs-6 text-gray-700 ">
                                Toutes les questions ayant des réponses différentes de la réponse par défaut requiert une mesure de sauvegarde
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                {selectedElementsListEntity?.filter(item => item.isChecked !== item.defaultResponse)?.length > 0 && <div className="w-100">
                    <Title style={{display: "block", fontWeight: '600', color: appTheme.colors.primary}} level={4}>#
                        Critères relatifs a l'entité</Title>
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                columns={columnsEntity}
                                rowKey={(record) => record.id}
                                dataSource={selectedElementsListEntity?.filter(item => item.isChecked !== item.defaultResponse) || []}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                                size="small"
                            />
                        </div>
                    </div>
                </div>}
                <Divider/>
                <br/>
                {selectedElementsListAudit?.filter(item => item.isChecked !== item.defaultResponse)?.length > 0 && <div className="w-100">
                    <Title style={{display: "block", fontWeight: '600', color: appTheme.colors.primary}} level={4}>#
                        Critères relatifs a l'auditeur</Title>
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                columns={columnsAudit}
                                rowKey={(record) => record.id}
                                dataSource={selectedElementsListAudit?.filter(item => item.isChecked !== item.defaultResponse) || []}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                                size="small"
                            />
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
};

export default ClientModalMesures;
