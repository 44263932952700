import React from "react";

const SiegeSocialInfos = ({formik}) => {

    return (
        <>
            <div className="card card-flush">
                {/*begin::Card header*/}
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    {/*begin::Card title*/}
                    <div className="card-title">
                        {/*begin::Title*/}
                        <h2>Informations siège social</h2>
                        {/*end::Title*/}
                    </div>
                    {/*end::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                    {/*begin::Form*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7 fv-plugins-icon-container">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Adresse géographique</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <textarea
                                    {...formik.getFieldProps('localisation')}
                                    className="form-control form-control-solid"
                                    name="localisation"
                                    value={formik.values.localisation}
                                ></textarea>
                                {/*end::Input*/}
                                {formik.touched.localisation && formik.errors.localisation && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.localisation}</div>
                                )}
                            </div>
                        </div>
                        <div className="row fv-row mb-7 fv-plugins-icon-container">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Adresse postale</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('adresse_postale')}
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="adresse_postale"
                                    value={formik.values.adresse_postale}
                                />
                                {/*end::Input*/}
                                {formik.touched.adresse_postale && formik.errors.adresse_postale && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.adresse_postale}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Téléphone</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('cel1')}
                                    type="text"
                                    className="form-control form-control-solid"
                                    name="cel1"
                                    value={formik.values.cel1}
                                />
                                {/*end::Input*/}
                                {formik.touched.cel1 && formik.errors.cel1 && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.cel1}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Adresse email</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('email')}
                                    className='form-control form-control-solid'
                                    placeholder='Email...'
                                    name='email'
                                    type='text'
                                    value={formik.values.email}
                                />
                                {/*end::Input*/}
                                {formik.touched.email && formik.errors.email && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.email}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Site internet</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('site_internet')}
                                    className='form-control form-control-solid'
                                    name='site_internet'
                                    type='text'
                                    value={formik.values.site_internet}
                                />
                                {/*end::Input*/}
                                {formik.touched.site_internet && formik.errors.site_internet && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.site_internet}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        <div></div>
                    {/*end::Form*/}
                </div>
                {/*end::Card body*/}
            </div>
        </>
    )
}

export {SiegeSocialInfos}