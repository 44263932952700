import axios from "axios"
import {
    DOWNLOAD_DOCUMENT_CLIENT, DOWNLOAD_FILE_CLIENT, LISTE_DOCUMENT_MISSION,
    LISTE_FICHE_CLIENT_LEA, LISTE_LETTRE_MISSION_SIGNE, LISTE_MODELE_LETTRE_MISSION,
    UPLOAD_FILE, UPLOAD_FORMULAIRE_QUESTION_MISSION, UPLOAD_LETTRE_MISSION_SIGNE,
    UPLOAD_MODELE_LETTRE_MISSION
} from "../../api/apiRoutes";
import {setAuthorizationHeader} from "../../api/config";

const API_URL = process.env.REACT_APP_API_URL
const FILE_SOMMAIRE_CLIENT = `${API_URL}/lea/exercice/file/sommaire/ajouter`
const MODIFIER_FILE_SOMMAIRE_CLIENT = `${API_URL}/lea/exercice/file/sommaire/modifier`
const SUPPRIMER_FILE_SOMMAIRE_CLIENT = `${API_URL}/lea/exercice/file/sommaire/supprimer`
const LISTE_FILE_CLIENT = `${API_URL}/lea/exercice/file/sommaire`

const COPY_FICHIER = `${API_URL}/lea/exercice/file/sommaire/copy`

const FILE_MISSION_CLIENT = `${API_URL}/lea/exercice/file/mission/ajouter`
const LISTE_FILE_MISSION_CLIENT = `${API_URL}/lea/exercice/file/mission`

const FILE_MODELE_MISSION_CLIENT = `${API_URL}/lea/exercice/file/modele/mission/ajouter`
const LISTE_MODELE_MISSION_CLIENT = `${API_URL}/lea/exercice/file/modele/mission`

const FILE_QUESTIONNAIRE_CLIENT = `${API_URL}/lea/exercice/file/questionnaire/ajouter`
const LISTE_FILE_QUESTIONNAIRE_CLIENT = `${API_URL}/lea/exercice/file/questionnaire`

const FILE_LETTRE_CLIENT = `${API_URL}/lea/exercice/file/lettre/ajouter`
const LISTE_LETTRE_CLIENT = `${API_URL}/lea/exercice/file/lettre`

const FILE_LETTRE_MODELE_CLIENT = `${API_URL}/lea/clients/file/modele-lettre/ajouter`
const LISTE_LETTRE_MODELE_CLIENT = `${API_URL}/lea/clients/file/modele-lettre`

export const uploadExerciceFile = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(UPLOAD_FILE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}

export const getListeFichierMissionClient = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_FILE_MISSION_CLIENT}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const uploadLettreMissionSigne = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(UPLOAD_LETTRE_MISSION_SIGNE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}

export const uploadModeleLettreModele = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(FILE_LETTRE_MODELE_CLIENT, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}

export const uploadFormulaireQuestionMission = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(UPLOAD_FORMULAIRE_QUESTION_MISSION, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}


export const getListeFichierClient = async (client_id, code) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_FICHE_CLIENT_LEA}/${client_id}/${code}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeLettreMissionSigne = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_LETTRE_MISSION_SIGNE}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeDocumentMission = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_DOCUMENT_MISSION}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeModeleLettreMission = async (client_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_LETTRE_MODELE_CLIENT}/${client_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getDocumentClient = async (client_bo, client_lea, fichier) => {
    setAuthorizationHeader()
    return axios
        .get(`${DOWNLOAD_DOCUMENT_CLIENT}/${client_bo}/${client_lea}/${fichier}`, { responseType: 'blob' })
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const downloadFile = async (client_bo, client_lea, fichier) => {
    setAuthorizationHeader()
    return axios
        .get(`${DOWNLOAD_FILE_CLIENT}/${client_bo}/${client_lea}/${fichier}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const downloadFileSommaire = async (client_bo, client_lea, annee, missionName, fichier) => {
    setAuthorizationHeader()
    return axios
        .get(`${DOWNLOAD_FILE_CLIENT}/${client_bo}/${client_lea}/${annee}/${missionName}/${fichier}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const addFileSommaireClient = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(FILE_SOMMAIRE_CLIENT, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}

export const addFileMissionClient = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(FILE_MISSION_CLIENT, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}


export const addFileQuestionnaireClient = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(FILE_QUESTIONNAIRE_CLIENT, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}

export const getListeFileSommaire = async (exercice_id, codeSommaire) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_FILE_CLIENT}/${exercice_id}/${codeSommaire}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const addFileModeleMission = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(FILE_MODELE_MISSION_CLIENT, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}

export const getListeModeleSommaire = async (client_bog_id, codeSommaire) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_MODELE_MISSION_CLIENT}/${client_bog_id}/${codeSommaire}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeModeleDocument = async (client_bog_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_MODELE_MISSION_CLIENT}/${client_bog_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeFileQuestionnaire = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_FILE_QUESTIONNAIRE_CLIENT}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const addFileLettreMissionClient = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(FILE_LETTRE_CLIENT, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}

export const getListeLettreMission = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_LETTRE_CLIENT}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}



export const ModdifierFileSommaire = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(MODIFIER_FILE_SOMMAIRE_CLIENT, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}

export const supprimerFileMission = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(SUPPRIMER_FILE_SOMMAIRE_CLIENT, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}

export const copyFileSommaire = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(COPY_FICHIER, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => {
            console.log(err);
            return {
                status: 'error',
                message: 'Une erreur est survenue lors de l\'envoi du fichier !',
            };
        });
}


