import React, {useEffect, useState} from 'react';
import {Button, Divider, Popconfirm, Popover, Space, Table, Tag, Tooltip} from 'antd';
import {
    AuditOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons';
import {SearchHeader} from '../../../components/layout/header/HeaderList';
import {Link} from "react-router-dom";
import moment from "moment/moment";
import {ListeEquipeMission} from "../../../components/client/modals/ListeEquipeMission";
import {AddEquipeMission} from "../../../components/users/missions/AddEquipeMission";
import {
    clotureProvisoireExercice,
    getAllListeEquipeMission,
    getListeExerciceClients, getListeUserMission,
    VerrouQestion
} from "../../../data/exercice";
import {optionsSwal, toAbsoluteUrl} from "../../../helpers/AssetsHelpers";
import Swal from 'sweetalert2'
import swal from 'sweetalert'
import {useSelector} from "react-redux";
import AddChefMission from "../../../components/users/missions/AddChefMission";
import ListeFileMission from "../../../components/client/outils/files/ListeFileMission";
import TableTitle from "../../../components/common/TableTitle";
import {appTheme} from "../../../config/theme";
import {TagItem} from "../../../components/common/common.components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowCircleLeft, faCalendarTimes, faEnvelopeCircleCheck,
    faExclamationCircle, faFileCircleCheck, faFileCircleExclamation,
    faFolder, faListDots, faLock,
    faUnlock, faUser, faUsersGear, faUserTie
} from "@fortawesome/free-solid-svg-icons";
import ModalMissionClotureComment from "../../../components/modals/clients/ModalMissionClotureComment";
import {ChangeMissionSommaire} from "../../../components/modals/clients/ModalChangeMissionSommaire";

const colorTabs = ["primary", "warning", "success", "info", "danger"]

const FileManagerExercice = ({client, year, setIsMissionModalOpen, setMissionLength}) => {
    const [filtersItems, setFiltersItems] = useState([]);
    const [missions, setMissions] = useState([]);
    const [membersMissions, setMemeberMissions] = useState([]);
    const [mission, setMission] = useState({});
    const [loading, setLoading] = useState(false);
    const [seletedModal, setSetSelected] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [usersMission, setUsersMission] = useState([]);
    const user = useSelector(state => state.user)
    const isAdmin = user.libelleProfil === "Admin"
    const [isManager, setIsManager] = useState(false)

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    useEffect(() => {
        fetchClientExercice()
    }, [client, usersMission, isAdmin]);

    useEffect(() => {
        getAllListeEquipeMission()
            .then((res) => {
                const elements = []
                missions.forEach((item) => {
                    const equipes = res.filter((opt) => opt.exercice_id === item.id);
                    item.equipes = equipes;
                    elements.push(item);
                })
                setMemeberMissions(elements)
            })
    }, [missions]);

    useEffect(() => {
        if(user){
            fetchUserMission(user.id);
        }
    }, [user]);

    const fetchClientExercice = async () => {
        getListeExerciceClients(client.id)
            .then((res) => {
                if(res && res.length > 0){
                    res.forEach((mission) => {
                        mission.isChefMission = (user.id === mission.chefMission)
                        mission.isManagerMission = ((user.id === mission.manager_mission_id) || (usersMission.some((element) => (mission.id === element.exercice_id) && (user.id === element.membre_id) && (element.isManagerMission) )))
                    })

                    const miss = res.filter((item) => item.annee === year)

                    const missions = isAdmin ? miss : miss.filter((opt) =>  usersMission.some((element) => opt.id === element.exercice_id && user.id === element.membre_id ))

                    setMissionLength(res?.filter(item => item.isUsed || 0)?.length || 0);
                    setMissions(missions);
                    setFiltersItems(missions)
                }
            });
    };

    const fetchUserMission = (id) => {
        getListeUserMission(id)
            .then((res) => {
                if(res){
                    setUsersMission(res)
                }
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setMissions([]);
        }
    };

    const handleOpenModal = (modal_id, miss, isManag) => {
        setMission(miss)

        setSetSelected(modal_id)
        setIsManager(isManag || false)
        setIsModalOpen(prevState => !prevState);
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = membersMissions.filter(
            (item) =>
                item.mission.toLowerCase().includes(searchTerm) ||
                item.annee.toLowerCase().includes(searchTerm)
        );
        setFiltersItems(filtered);
    };

    const handleVerrouQuestion = async (exercice_id, verou_question)  => {
        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const data = {client_id: client.id, verou_question, exercice_id}
                const {status, message} = await VerrouQestion(data)
                if (status === 'error') {
                    await swal(`${message}`, '', 'warning')
                }
                if (status === 'success') {
                    setLoading(false)
                    await swal(`Opération effectuée avec succès`, '', 'success')
                    fetchClientExercice()
                } else {
                    setLoading(false)
                }
            }
        })
    }

    const handleClotureExerciceProvisoire = async (exercice_id, isCloture)  => {
        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const data = {client_id: client.id, isClotureProvisoire: isCloture, exercice_id}

                const {status, message} = await clotureProvisoireExercice(data)
                if (status === 'error') {
                    await swal(`${message}`, '', 'warning')
                }
                if (status === 'success') {
                    setLoading(false)
                    await swal(`Opération effectuée avec succès`, '', 'success')
                    fetchClientExercice()
                } else {
                    setLoading(false)
                }
            }
        })
    }

    const dataColumns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Mission</TableTitle>,
            key: "nom",
            dataIndex: "nom",
            render: (_, record) => (
                <>
                    <div className="position-relative ps-6 pe-3 py-2">
                        { record.isCloture === 0 ?
                            record.lettre_mission === 0 ?
                                record.etat === 3 ?
                                    <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-danger"></div>
                                    :
                                    <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-warning"></div>
                                :
                                <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-primary"></div>
                            :
                            <div className="position-absolute start-0 top-0 w-4px h-100 rounded-2 bg-success"></div>
                        }
                        <Link className=""
                              to={`/clients/mission/exploitation/${record.id}`}
                              state={{record: client, mission: record}}>
                            <div className="d-flex align-items-center">
                                <span className="svg-icon svg-icon-2x svg-icon-primary me-4">
                                    <AuditOutlined style={{color: '#009ef7'}}/>
                                </span>
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-1">
                                        <span style={{ color: "#000", fontWeight: "bold" }}>
                                            {record.mission}
                                          </span>
                                        {record.isCloture === 1 ?
                                            <Tag color="green" className="ms-3">Terminée</Tag>
                                            :
                                            (record.etat === 1 ?
                                                <div className="badge badge-light-primary ms-5">En attente</div>
                                                :
                                                (record.etat === 2 || record.lettre_mission === 1) ?
                                                    <div className="badge badge-light-success ms-5">Accepté(e)</div>
                                                    :
                                                    <div className="badge badge-light-danger ms-5">Refusé(e)</div>)
                                        }
                                    </div>
                                    <div>
                                        {record.annee && <TagItem color={appTheme.colors.primary}>{record.annee}</TagItem>}
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div className="d-flex align-center">
                            <span className="fs-7 text-muted fw-bolder me-2">Début:</span>
                            <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                                <ClockCircleOutlined style={{fontSize: '15px'}} />{' '}
                                {moment(record.createdAt).format('DD/MM/YYYY à HH:mm')}
                            </span>
                        </div>
                    </div>

                </>
            ),
        },
        {
            title: () => <TableTitle>Etat</TableTitle>,
            dataIndex: "libelle_status",
            align: "center",
            render: (_, record) => (
                <>
                    <div className="">
                        {record.isCloture === 0 ?
                                record.lettre_mission === 0 ?
                                    <Tag color="yellow">En attente</Tag> :
                                    <Tag color="blue">En cours</Tag>
                                :
                        <Tag color="green">Clôturé</Tag>}
                        {record.isCloture === 1 && <Tooltip title={"Mission verrouillé"} color={"red"}> <Tag color="red" icon={<FontAwesomeIcon icon={faLock} />}/> </Tooltip>}
                        {record.isCloture === 1 && <Popover
                            placement="top"
                            title="Motif de clôture"
                            content={
                                <>
                                    <Divider/>
                                    <span
                                        style={{
                                            fontFamily: "Oswald, sans-serif",
                                            fontSize: "13px",
                                        }}
                                    >
                        {record.commentCloture}
                      </span>
                                </>
                            }
                        >
                            <Button
                                icon={<FontAwesomeIcon icon={faExclamationCircle}/>}
                                color={""}
                            />
                        </Popover>}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Equipe de mission</TableTitle>,
            dataIndex: "secteur_id",
            align: "center",
            render: (_, record) => (
                <>
                    <div className="mx-auto text-center">

                    <div onClick={() =>handleOpenModal(2, record)}>
                        <div className='symbol-group symbol-hover flex-nowrap'>
                            {record?.equipes?.map((item, index) => (
                                <div key={index} className="symbol symbol-35px symbol-circle">
                                    {item.avatar ? (
                                        <div className='symbol-label'>
                                            <img
                                                src={toAbsoluteUrl(`/media/${item.avatar}`)}
                                                alt={item.nom_user}
                                                className='w-100'
                                            />
                                        </div>
                                    ) : (
                                        <Tooltip title={item.nom_user + ' ' + item.prenom_user}>
                                            <span className={`symbol-label bg-${colorTabs[index]} text-inverse-primary fw-bolder`}>
                                                {item.nom_user[0].toUpperCase()}
                                            </span>
                                        </Tooltip>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle></TableTitle>,
            dataIndex: "",
            align: 'center',
            render: (_, record) => (
                <>
                    {record.fichier_question === 1 && <Tooltip title="Formulaire manuscrit soumis" color="green">
                        <Tag
                            icon={<FontAwesomeIcon icon={faFileCircleCheck}/>}
                            color="green"
                        />
                    </Tooltip>}
                    {record.fichier_question === 1 && <Tooltip title="Lettre de mission soumis" color="green">
                        <Tag
                            icon={<FontAwesomeIcon icon={faEnvelopeCircleCheck}/>}
                            color="green"
                        />
                    </Tooltip>}
                </>
            ),
        },
        {
            title: () => <TableTitle>Documents</TableTitle>,
            dataIndex: "dossier",
            width: 70,
            render: (_, record) => (
                <>
                    <Tooltip
                        title="Documment de l'exercice"
                        color={appTheme.colors.primary_blue}
                    >
                        <Button
                            className="text-white"
                            shape="circle"
                            style={{backgroundColor: appTheme.colors.primary_blue}}
                            icon={<FontAwesomeIcon icon={faFolder} />}
                            onClick={() => {
                                handleOpenModal(1, record);
                            }}
                        />
                    </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>Actions</TableTitle>,
            align: "right",
            width: 150,
            render: (_, record) => (
                record.isCloture !== 1 && <Space>
                    {((record.isManagerMission || isAdmin)) &&
                        <Tooltip color='yellow' title="Modifier le sommaire de l'exercice">
                            <Button
                                shape="circle"
                                size={"middle"}
                                style={{backgroundColor: appTheme.colors.primary}}
                                icon={<FontAwesomeIcon icon={faListDots} color={"white"} />}
                                onClick={() => {
                                    handleOpenModal(6, record, false)
                                }}
                            />
                        </Tooltip>}
                    {(isAdmin) &&
                        <Tooltip color='blue'
                                 title="Modifier le manager de mission">
                            <Button
                                shape="circle"
                                size={"middle"}
                                style={{backgroundColor: "blue"}}
                                onClick={() => {
                                    handleOpenModal(4, record, true)
                                }}
                                icon={<FontAwesomeIcon icon={faUserTie} color={"white"} />}
                            />
                        </Tooltip>}
                    {(record.isManagerMission || isAdmin) &&
                        <Tooltip color='blue'
                                 title="Modifier le chef de mission">
                            <Button
                                shape="circle"
                                size={"middle"}
                                style={{backgroundColor: appTheme.colors.primary_blue}}
                                icon={<FontAwesomeIcon icon={faUser} color={"white"} />}
                                onClick={() => {
                                    handleOpenModal(4, record, false)
                                }}
                            />
                        </Tooltip>}
                    {(record.isChefMission || record.isManagerMission || isAdmin) &&
                        <Tooltip color='blue'
                                 title="Configurer l'équipe de mission">
                            <Button
                                shape="circle"
                                size={"middle"}
                                style={{backgroundColor: appTheme.colors.secondary}}
                                icon={<FontAwesomeIcon icon={faUsersGear} color={"white"}/>}
                                onClick={() => {
                                    handleOpenModal(3, record)
                                }}
                            />
                        </Tooltip>}
                    {(record.fichier_question === 1 && record.lettre_mission === 0) &&
                        record.isChefMission && record.isManagerMission && <Tooltip
                            color='blue'
                            title={`${record?.verou_question === 0 ? 'Questionnaire déverrouillé' : 'Questionnaire verrouillé'}`}
                        >
                            <Button
                                shape="circle"
                                size={"middle"}
                                style={{backgroundColor: record?.verou_question === 0 ? "yellow" : "yellowgreen"}}
                                icon={<FontAwesomeIcon icon={record?.verou_question === 0 ? faUnlock : faLock} color={"white"}/>}
                                onClick={() => handleVerrouQuestion(record.id, record?.verou_question === 1 ? 0 : 1)}
                            />
                        </Tooltip>
                    }
                    {((record.isManagerMission || isAdmin) && record.isClotureProvisoire === 1 && record.isCloture !==1) && <Tooltip
                        color='blue'
                        title={`${'Cloture définitive de la mission' }`}
                    >
                        <Popconfirm
                            placement="top"
                            title={"Êtes-vous sure de vouloir faire la clôture définitive de cette mission ? "}
                            description={"Clôture définitive de cette mission"}
                            onConfirm={() => handleOpenModal( 5, record)}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button
                                shape="circle"
                                size={"middle"}
                                style={{backgroundColor:  "red"}}
                                icon={<FontAwesomeIcon icon={faFileCircleCheck} color="white"/>}
                                // onClick={() => }
                            />
                        </Popconfirm>
                    </Tooltip>}
                    {((record.isManagerMission || isAdmin) && record.isCloture !== 1) && <Tooltip
                        color='blue'
                        title={`${record?.isClotureProvisoire === 0 ? 'Cloture provisoire de la mission' : 'Mettre la mission en cours'}`}
                    >
                        <Button
                            shape="circle"
                            size={"middle"}
                            icon={<FontAwesomeIcon icon={record?.isClotureProvisoire === 1 ? faFileCircleExclamation : faCalendarTimes} color="white"/>}
                            style={{backgroundColor:  "orangered"}}
                            onClick={() => handleClotureExerciceProvisoire(record.id, record.isClotureProvisoire !== true)}
                        />
                    </Tooltip>}
                </Space>
            ),
        },
    ];

    return (
        <>
            <div className='d-flex flex-column w-100'>
                <div className="card-header border-0 pt-6">
                    <div className="d-flex justify-content-between align-items-center">

                        <SearchHeader handleSearch={handleSearchChange}/>
                        <Button
                            size={"middle"}
                            style={{backgroundColor: "red"}}
                            icon={<FontAwesomeIcon color="white" icon={faArrowCircleLeft} />}
                            onClick={() => setIsMissionModalOpen(false)}
                        >
                            <span className="text-white">Retour</span>
                        </Button>
                    </div>
                </div>
                <div className="w-100">
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                columns={dataColumns}
                                dataSource={filtersItems}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && seletedModal === 1 &&(
                <ListeFileMission
                    handleVisibility={handleOpenModal}
                    data={client}
                    mission={mission}
                    visible={isModalOpen}
                    isSommaireAdd={true}
                />
            )}
            {isModalOpen && seletedModal === 2 &&(
                <ListeEquipeMission
                    handleVisibility={handleOpenModal}
                    data={client}
                    mission={mission}
                    visible={isModalOpen}
                />
            )}
            {isModalOpen && seletedModal === 3 &&(
                <AddEquipeMission
                    handleVisibility={handleOpenModal}
                    client={client}
                    exercice={mission}
                    visible={isModalOpen}
                    fetchData={fetchClientExercice}
                />
            )}
            {isModalOpen && seletedModal === 4 &&(
                <AddChefMission
                    handleVisibility={handleOpenModal}
                    client={client}
                    exercice={mission}
                    visible={isModalOpen}
                    fetchData={fetchClientExercice}
                    isManager={isManager}
                />
            )}
            {isModalOpen && seletedModal === 4 &&(
                <AddChefMission
                    handleVisibility={handleOpenModal}
                    client={client}
                    exercice={mission}
                    visible={isModalOpen}
                    fetchData={fetchClientExercice}
                    isManager={isManager}
                />
            )}
            {isModalOpen && seletedModal === 5 &&(
                <ModalMissionClotureComment
                    handleVisibility={handleOpenModal}
                    client={client}
                    exercice={mission}
                    visible={isModalOpen}
                    fetchData={fetchClientExercice}
                />
            )}
            {isModalOpen && seletedModal === 6 &&(
                <ChangeMissionSommaire
                    handleVisibility={handleOpenModal}
                    client={client}
                    mission={mission}
                    visible={isModalOpen}
                    fetchData={fetchClientExercice}
                    chargement={loading}
                />
            )}
            {/*{isModalOpen && seletedModal === 2 &&*/}
            {/*    <div*/}
            {/*        className="top-0 bottom-0 z-index-1000 h-100"*/}
            {/*        style={{insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)'}}*/}
            {/*    >*/}
            {/*        <div className="modal fade show" id="kt_modal_upload" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}>*/}
            {/*            <div className='modal-dialog modal-dialog-centered mw-1000px'>*/}
            {/*                <LettreMissionClient onClose={handleCloseModal} client={client} mission={mission} />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
            {/*{isModalOpen && seletedModal === 3 &&*/}
            {/*    <div*/}
            {/*        className="top-0 bottom-0 z-index-1000 h-100"*/}
            {/*        style={{insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)'}}*/}
            {/*    >*/}
            {/*        <div className="modal fade show" id="kt_modal_move_to_folder" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}>*/}
            {/*            <div className='modal-dialog modal-dialog-centered mw-750px'>*/}
            {/*                <QuestionnaireAssignation onClose={handleCloseModal} mission={mission} client={client} />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}
        </>
    );
};

export default FileManagerExercice;
