import React, {useEffect, useState} from "react";
import {
    DeleteFilled,
} from "@ant-design/icons";
import {addFileMissionClient, addFileSommaireClient,} from "../../../../data/file";
import {getListeSommaireActive} from "../../../../data/params/sommaire";
import {Button, Checkbox, Col, Input, Row, Select, Typography} from "antd";
import {dossierSommaires} from "../../modals/missionSteps/EA/DPSommaires";
import swal from "sweetalert";
import {ModalTitle} from "../../../modals/modals.components";
import {Form, Formik} from "formik";
import FormLabel from "../../../common/Form/FormLabel";
import {getIconForFileType} from "../../../layout/menu/CustomMenu";
import FormError from "../../../common/Form/FormError";
import ModalAlert from "../../../modals/Modal";
import * as Yup from "yup";

const FileUploaderMission = ({
         handleVisibility,
         mission,
         // date,
         // heureEntree,
         // user_id,
         // tache_id,
         // intervention_id,
         isSommaireAdd,
         visible,
         fetchData
     }) => {
    const [sommaires, setSommaires] = useState([]);
    const [loadingCreate, setLoadingCreate] = useState(false);

    useEffect(() => {
        fetchSommaire()
    }, []);

    const fetchSommaire = async () => {
        getListeSommaireActive()
            .then((res) => {
                setSommaires(res.result.sort((a, b) => a.libelle.localeCompare(b.libelle)));
            });
    };

    const handleUploadAll = async (value) => {
        setLoadingCreate(true);

        const fileSizeLimit = 10 * 1024 * 1024;
        for (const file of value.selectedFiles) {
            if (file.size <= fileSizeLimit) {
                const extension = file.name.split(".").pop().toLowerCase();

                const formData = new FormData();
                formData.append("file", file);
                formData.append("reference", value.reference);
                formData.append("libelle", value.libelle);
                formData.append("description", value.description);
                formData.append("exercice_id", mission.id);
                formData.append("client_id", mission.client_id);
                formData.append("codeSommaire", value.codeSommaire);
                formData.append('size', file.size);
                formData.append('extension', extension);
                formData.append('code', value.categorie);
                formData.append('isAddPermanent', value.isAddPermanent);
                // formData.append("intervention_id", intervention_id);
                // formData.append("date", date);
                // formData.append("heureEntree", heureEntree);
                // formData.append("tache_id", tache_id);
                // formData.append("user_id", user_id);
                formData.append("isSommaireAdd", isSommaireAdd  ? null : true);

                try {
                    const response = value.codeSommaire ? await addFileSommaireClient(formData) : await addFileMissionClient(formData);
                    if (response.status === "success") {
                        await swal('Opération effectuée avec succès', '', 'success')
                        fetchData(mission.id);
                        handleVisibility()
                        setLoadingCreate(false)
                    } else {
                        await swal(response.message, '', 'error')
                        setLoadingCreate(false)
                    }
                } catch (error) {
                    await swal("Failed to upload file: " + file.name, '', 'error')
                    setLoadingCreate(false)
                }
            } else {
                setLoadingCreate(false)
                await swal("File size exceeds the limit", '', 'error')
            }
        }
    };

    const dataValidationScheme = Yup.object({
        client_id: Yup.number()
            .required("Veuillez sélectionner le client")
            .nullable("Le client est obligatoire")
            .typeError("Le client est obligatoire"),
        mission_id: Yup.number()
            .required("Veuillez sélectionner la mission")
            .nullable("Le mission est obligatoire")
            .typeError("Le mission est obligatoire"),
        libelle: Yup.string().required("Le nom du fichier est obligatoire"),
        // code: Yup.string().required("Le code sommaire du DP est obligatoire"),
    });

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Joindre un fichier {mission ? ` a ${mission.mission || ""} - ${mission.annee || ""}` : ""}</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 30 }}
                width={800}
            >
                <Formik
                    initialValues={{
                        selectedFiles: [],
                        codeSommaire: "",
                        libelle: '',
                        description: "",
                        categorie: null,
                        isAddPermanent: false,
                        reference: "",
                        notes: "",
                        client_id: (mission && mission.client_id) || "",
                        mission_id: (mission && mission.id) || ""
                    }}
                    onSubmit={(values) => handleUploadAll(values)}
                    validationSchema={dataValidationScheme}
                >
                    {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                        <Form>
                            <Row gutter={[16, 16]}>
                                <Col span={20}>
                                    <FormLabel label="Fichier" required />
                                    <div className='row fv-row mb-7 fv-plugins-icon-container'>
                                        <div className='d-flex align-center'>
                                            <div className=''>
                                                <div className='d-flex align-items-center mb-3'>
                                                    <input
                                                        type='file'
                                                        style={{width: "100%"}}
                                                        className='form-control w-lg-400px'
                                                        // accept=".xlsx, .csv, .xls"
                                                        onChange={(value) => {
                                                            const files = value.target.files;
                                                            const fileSizeLimit = 10 * 1024 * 1024;
                                                            const validFiles = Array.from(files).filter(
                                                                (file) => file.size <= fileSizeLimit
                                                            );
                                                            setFieldValue("selectedFiles", [...values.selectedFiles, ...validFiles])
                                                        }}
                                                        required={true}
                                                    />
                                                </div>
                                                <span className='form-text fs-6 text-muted'>
                                                            Taille maximale de fichier 10MB.
                                                        </span>
                                            </div>
                                        </div>

                                        <div className='dropzone-items'>
                                            {/* Show the list of selected files */}
                                            {values.selectedFiles.map((file, index) => (
                                                <div key={index} className='m-6'>
                                                    {getIconForFileType(file.name)} {file.name}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {touched.selectedFiles && errors.selectedFiles && (
                                        <FormError fieldName="selectedFiles" />
                                    )}
                                </Col>
                                <Col span={4}>
                                    <div className='pt-10'>
                                        <button
                                            className='dropzone-remove-all btn btn-sm btn-light-primary'
                                            onClick={() => {
                                                setFieldValue("selectedFiles", [])
                                            }}
                                        >
                                            <DeleteFilled style={{color: 'red', fontSize: '15px'}}/>
                                        </button>
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <FormLabel label="Sommaire" />
                                    <Select
                                        showSearch
                                        placeholder="Sélectionnez le sommaire..."
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={sommaires.map((statut) => ({
                                            value: statut.libelle,
                                            label: `${statut.libelle} - ${statut.description}`,
                                        }))}

                                        onChange={(value) =>
                                            setFieldValue("codeSommaire", value)
                                        }
                                        size="large"
                                        style={{ width: "100%" }}
                                    />
                                    {touched.codeSommaire && errors.codeSommaire && (
                                        <FormError fieldName="codeSommaire" />
                                    )}
                                </Col>
                                <Col span={12}>
                                    <FormLabel label="Reférence" />
                                    <Input
                                        size="large"
                                        placeholder="Saisir la reférence"
                                        name="reference"
                                        value={values.reference}
                                        onChange={(e) =>
                                            setFieldValue("reference", e.target.value)
                                        }
                                    />
                                    {touched.reference && errors.reference && (
                                        <FormError fieldName="reference" />
                                    )}

                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Nom du fichier" required />
                                    <Input
                                        size="large"
                                        placeholder="Le nom du fichier"
                                        name="libelle"
                                        value={values.libelle}
                                        onChange={(e) =>
                                            setFieldValue("libelle", e.target.value)
                                        }
                                    />
                                    {touched.libelle && errors.libelle && (
                                        <FormError fieldName="libelle" />
                                    )}
                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Note" />
                                    <Input.TextArea
                                        size="large"
                                        placeholder="Message"
                                        name="notes"
                                        value={values.notes}
                                        onChange={(e) =>
                                            setFieldValue("notes", e.target.value)
                                        }
                                        showCount
                                        maxLength={2000}
                                        rows={2}
                                    />
                                    {touched.notes && errors.notes && (
                                        <FormError fieldName="notes" />
                                    )}
                                </Col>
                                <Col span={6}>
                                    <label className="form-check form-switch form-check-custom form-check-solid">
                                        <Checkbox
                                            onChange={() =>
                                                setFieldValue("isAddPermanent", !values.isAddPermanent)
                                            }
                                            value={values.isAddPermanent}
                                        >
                                            Copier dans Dossier Permanent
                                        </Checkbox>
                                    </label>
                                </Col>
                                <Col span={18}>
                                    <FormLabel label="Copie dans sommaire DP"/>
                                    {values.isAddPermanent &&
                                    <Select
                                        showSearch
                                        placeholder="Sélectionnez..."
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        options={dossierSommaires && dossierSommaires.map((sommaire) => ({
                                            value: sommaire.code,
                                            label: sommaire.libelle.toUpperCase()
                                        }))}

                                        onChange={(value) =>
                                            setFieldValue("categorie", value)
                                        }
                                        size="large"
                                        style={{ width: "100%" }}
                                    />}
                                    {(values.isAddPermanent && !values.categorie ) && (
                                        <Typography.Text type="danger">Veuillez sélectionner le sommaire du dossier permanent </Typography.Text>
                                    )}
                                </Col>
                                <Col span={24}>
                                    <Button
                                        type="primary"
                                        block
                                        size="large"
                                        loading={loadingCreate}
                                        disabled={loadingCreate}
                                        onClick={handleSubmit}
                                    >
                                        {!loadingCreate && <span className='indicator-label'>Enregistrer</span>}
                                        {loadingCreate && (
                                            <span className="indicator-progress text-white">
                                            Veuillez patienter...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                        </span>
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </ModalAlert>
        </>
    );
};

export default FileUploaderMission;
