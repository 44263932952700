import React, {useEffect, useState} from "react";
import {getListeFichierClient} from "../../../../../data/file";
import {DOWNLOAD_FILE_CLIENT} from "../../../../../api/apiRoutes";
import {
    ClockCircleOutlined,
    UserOutlined
} from "@ant-design/icons";
import moment from "moment/moment";
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Input,
    notification,
    Popover,
    Select,
    Space,
    Table,
    Tag,
    Tooltip
} from "antd";
import FileUploader from "../../../outils/upload/FileUploader";
import {cleanAndNormalizeName} from "../../../../utilities/Utilities";
import TableTitle from "../../../../common/TableTitle";
import {appTheme} from "../../../../../config/theme";
import {getIconForFileType} from "../../../../layout/menu/CustomMenu";
import {FormContainer, TagItem} from "../../../../common/common.components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCloudUpload,
    faDownload,
    faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import SearchRow from "../../../../common/SearchRow";
import FormLabel from "../../../../common/Form/FormLabel";
import useUsers from "../../../../../hooks/users/useUser";

const DossierPermanentModal = ({code, client}) => {
    const users = useUsers();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [filtersItems, setFiltersItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [paramsRecherche, setParamsRecherche] = useState({
        infoFile: "",
        user_id: "",
        date_debut: "",
        date_fin: "",
    });

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 5,
        },
    });

    useEffect(() => {
        if(code && client){
            fetchDocuments(client.id, code)
        }
    }, [code, client])

    const fetchDocuments = (client_id, code) => {
        setLoading(true);
        getListeFichierClient(client_id, code)
            .then((res) => {
                setFiles(res.result.sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt);
                }));
                setFiltersItems(res.result.sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt);
                }))
                setLoading(false);
            });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setFiles([]);
        }
    };

    const downloadDocumentsClient = (client_bo, client_lea, fichier) => {
        setLoading(true);
        setTimeout(() => {
            const clientBogName = cleanAndNormalizeName(client_bo);
            const clientName = cleanAndNormalizeName(client_lea);
            // ${clientBogName}/${clientName}/${exercice.annee}/${missionName}`
            window.open(`${DOWNLOAD_FILE_CLIENT}/${clientBogName}/${clientName}/${fichier}`, '_blank')

            setLoading(false);
            notification.success({
                description: "Télécharger avec succès",
            });
        }, 1500);
    };

    const handleOpenModal = ()  => {
        setIsModalOpen(prevState => !prevState);
    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Référence</TableTitle>,
            dataIndex: 'reference',
            render: (_, record) => (
                <Tag color={appTheme.colors.primary_blue}>{record.reference}</Tag>

            ),
        },
        {
            title: () => <TableTitle>Document</TableTitle>,
            dataIndex: 'libelle',
            render: (_, record) => (
                <div className='d-flex align-items-center'>
                    <span className='svg-icon svg-icon-2x svg-icon-primary me-4'>
                      {getIconForFileType(record.extension || record.libelle)}
                    </span>
                    <div style={{
                        fontFamily: "Oswald, sans-serif",
                        fontSize: "12px", fontWeight: '700'
                    }}>
                        {record.libelle}
                    </div>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Taille (KO)</TableTitle>,
            dataIndex: 'size',
            render: (_, record) => (
                <TagItem>{(record.size / 1024).toFixed(2)}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Dernière modif.</TableTitle>,
            dataIndex: 'modif',
            render: (_, record) => (
                <div className='d-flex flex-column align-center'>
                    <span className='chart-orange display-block f-size-12 pe-5' style={{fontWeight: 600, fontSize: '11px'}}>
                        <UserOutlined style={{fontSize: '15px'}} /> {(record.Lea_User?.nom_user || "") + ' ' + (record.Lea_User?.prenom_user || "")}
                    </span>
                    <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                        <ClockCircleOutlined style={{fontSize: '12px'}} />{' '}
                        {moment(record.updatedAt).format('DD/MM/YYYY à HH:mm')}
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Dossier client</TableTitle>,
            dataIndex: 'dossiers',
            render: () => (
                <div className='d-flex flex-column'>
                    <span style={{ color: "#000", fontWeight: "bold", fontSize: "12px" }}>
                        {client.denomination}
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Notes</TableTitle>,
            dataIndex: 'description',
            render: (_, record) => (
                <>
                    {record.description && <Popover
                        placement="top"
                        title="Notes"
                        content={
                            <>
                                <Divider/>
                                <span
                                    style={{
                                        fontFamily: "Oswald, sans-serif",
                                        fontSize: "13px",
                                    }}
                                >
                        {record.description}
                      </span>
                            </>
                        }
                    >
                        <Button
                            icon={<FontAwesomeIcon icon={faExclamationCircle}/>}
                            color={""}
                        />
                    </Popover>}
                </>
            ),
        },
        {
            title: () => <TableTitle>Actions</TableTitle>,
            dataIndex: 'action',
            render: (_, record) => (
                <Space>
                    <Tooltip color={appTheme.colors.primary} title="Téléchager le fichier">
                        <Button
                            shape="circle"
                            size={"middle"}
                            style={{backgroundColor: appTheme.colors.primary}}
                            onClick={() => downloadDocumentsClient(record?.client_bo, record?.client_lea, record.fichier)}
                            icon={<FontAwesomeIcon icon={faDownload} color={"white"} />}
                        />
                    </Tooltip>
                    {/*{(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) &&*/}
                    {/*    <>*/}
                    {/*        <Tooltip  title="Faire une copie dans le dossier permanent">*/}
                    {/*            <Button*/}
                    {/*                shape="circle"*/}
                    {/*                size={"middle"}*/}
                    {/*                style={{}}*/}
                    {/*                onClick={() => handleModalOpen(3, record)}*/}
                    {/*                icon={<FontAwesomeIcon icon={faCopy} color={""} />}*/}
                    {/*            />*/}
                    {/*        </Tooltip>*/}
                    {/*        <Tooltip color={appTheme.colors.primary} title="Modifier les informations du fichiers le fichier">*/}
                    {/*            <Button*/}
                    {/*                shape="circle"*/}
                    {/*                size={"middle"}*/}
                    {/*                style={{backgroundColor: ""}}*/}
                    {/*                onClick={() => handleModalOpen(2, record)}*/}
                    {/*                icon={<FontAwesomeIcon icon={faFileEdit} color={appTheme.colors.primary_blue} />}*/}
                    {/*            />*/}
                    {/*        </Tooltip>*/}
                    {/*        <Tooltip title="Supprimer ce fichier">*/}
                    {/*            <Popconfirm*/}
                    {/*                title="Confirmer la suppression de ce fichier"*/}
                    {/*                description="Voulez-vous supprimer ce fichier?"*/}
                    {/*                onConfirm={() => handleDeleteFileSommaire(record.id)}*/}
                    {/*            >*/}
                    {/*                <Button*/}
                    {/*                    shape="circle"*/}
                    {/*                    size={"middle"}*/}
                    {/*                    style={{backgroundColor: "red"}}*/}
                    {/*                    icon={<FontAwesomeIcon icon={faTrash} color={"white"} />}*/}
                    {/*                />*/}
                    {/*            </Popconfirm>*/}
                    {/*        </Tooltip>*/}
                    {/*    </>*/}
                    {/*}*/}
                </Space>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = files.filter(file => {
                const referenceMatch = file.reference?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const libelleMatch = file.libelle?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const descriptionMatch = file.description?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const userMatch = paramsRecherche.user_id === "" || file.Lea_User?.id === paramsRecherche.user_id;
                const createdAtMatch = (paramsRecherche.date_debut === "" && paramsRecherche.date_fin === "") || moment(file.createdAt).isBetween(
                    moment(paramsRecherche.date_debut),
                    moment(paramsRecherche.date_fin)
                );

                return (referenceMatch || descriptionMatch || libelleMatch) && userMatch && createdAtMatch;
            });

            setFiltersItems(filteredClients);
            setLoading(false);
        }, 1000);
    };


    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            infoFile: "",
            user_id: "",
            date_debut: "",
            date_fin: "",
        });
        setFiltersItems(files)
        setLoading(false);
    };

    return (
        <>
            <SearchRow
                handleSearch={handleSearch}
                handleReset={handleReset}
                loading={loading}
            >
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Fichier" />
                    <Input
                        style={{ width: "100%" }}
                        size="middle"
                        placeholder="Reference ou libellé ou description"
                        // value={paramsRecherche.infoClient}
                        onChange={(e) =>
                            setParamsRecherche({
                                ...paramsRecherche,
                                infosClient: e.target.value,
                            })
                        }
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Utilisateurs" />
                    <Select
                        placeholder="Utilisateur"
                        size="middle"
                        style={{ width: "100%" }}
                        onSelect={(value) =>
                            setParamsRecherche({ ...paramsRecherche, user_id: value })
                        }
                        options={users && users.users.map((user) => ({
                            value: user.id,
                            label: `${user.initial || ""} - ${user.nom_user.toUpperCase()} ${user.prenom_user ? user.prenom_user.toUpperCase() : ""}`
                        }))}
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Date debut" />
                    <DatePicker
                        size="middle"
                        placeholder="Date début de la création"
                        style={{ width: "100%" }}
                        allowClear
                        onChange={(date, dateString) =>
                            date !== null
                                ? setParamsRecherche({
                                    ...paramsRecherche,
                                    date_debut: date.format("YYYY-MM-DD"),
                                })
                                : setParamsRecherche({ ...paramsRecherche, date_debut: "" })
                        }
                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Date fin" />
                    <DatePicker
                        size="middle"
                        placeholder="Date fin  de la création"
                        style={{ width: "100%" }}
                        allowClear
                        onChange={(date, dateString) =>
                            date !== null
                                ? setParamsRecherche({
                                    ...paramsRecherche,
                                    date_fin: date.format("YYYY-MM-DD"),
                                })
                                : setParamsRecherche({ ...paramsRecherche, date_fin: "" })
                        }
                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                    />
                </Col>
            </SearchRow>
            <FormContainer>
                <div className="w-100">
                    <div className="d-flex justify-content-end align-center my-2 me-5">
                        <Tooltip color={appTheme.colors.primary} title="Importer un fichier pour l'exercice">
                            <Button
                                size={"middle"}
                                style={{backgroundColor: appTheme.colors.primary}}
                                onClick={() => handleOpenModal()}
                                icon={<FontAwesomeIcon icon={faCloudUpload} color={"white"}/>}
                            >
                                Importer
                            </Button>
                        </Tooltip>
                    </div>
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                rowKey={(record) => record.id}
                                dataSource={filtersItems}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                                rowSelection
                                size={"small"}
                            />
                        </div>
                    </div>
                </div>
            </FormContainer>
            {isModalOpen &&
                <FileUploader
                    code={code}
                    data={client}
                    chargement={loading}
                    fetchFile={fetchDocuments}
                    visible={isModalOpen}
                    handleVisibility={handleOpenModal}
                />
            }
        </>
    );
}

export default DossierPermanentModal;