import { DeleteOutlined} from "@ant-design/icons";
import {Button, Col, Input, notification, Row, Select, Space, Table, Tag, Tooltip, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {getListeCycle} from "../../../../../../data/params/cycle";
import {getListeRubriqueByCycle} from "../../../../../../data/params/rubriqueCycle";
import {getListeQuestionCycle} from "../../../../../../data/params/questionCycle";
import {getListeAssertionActive} from "../../../../../../data/params/assertion";
import {getListeNiveau} from "../../../../../../data/params/niveau";
import {getDetailsRisqueConception} from "../../../../../../data/risqueConceptionControle";
import TableTitle from "../../../../../common/TableTitle";
import {ModalTitle} from "../../../../../modals/modals.components";
import {FormContainer} from "../../../../../common/common.components";
import FormLabel from "../../../../../common/Form/FormLabel";
import ModalAlert from "../../../../../modals/Modal";
import SearchRow from "../../../../../common/SearchRow";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faCloudUpload} from "@fortawesome/free-solid-svg-icons";
import {Spinner} from "@chakra-ui/react";
import DynamicTable from "../../../../../modals/DynamicTable";

const {  Title } = Typography;


export const postesStat = [
    { key: "EE3.1", title: "Immobilisations incorporelles" },
    { key: "EE3.2", title: "Immobilisations corporelles" },
    { key: "EE3.3", title: "Immobilisations financières" },
    { key: "EE3.4", title: "Stocks" },
    { key: "EE3.5", title: "Ventes-clients" },
    { key: "EE3.6", title: "Autres créance et dettes" },
    { key: "EE3.7", title: "Trésorerie" },
    { key: "EE3.8", title: "Capitaux propres" },
    { key: "EE3.9", title: "Provisions risques" },
    { key: "EE3.10", title: "Emprunts et assimilés" },
    { key: "EE3.11", title: "Achats fournisseurs" },
    { key: "EE3.12", title: "Impôts et taxes" },
    { key: "EE3.13", title: "Paie personnel" },
];

const ModaQuestionlCycles = ({
         // assertions,
         assertData,
         handleVisibility,
         visible,
         incidence,
         setSelectItems,
         selectItems,
         chargement,
     }) => {
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [cycles, setCycles] = useState([]);
    const [rubriques, setRubriques] = useState([]);
    const [questionsFiltered, setQuestionsFiltered] = useState( []);
    const [questionsTravail, setQuestionsTravail] = useState([]);

    const [paramsRecherche, setParamsRecherche] = useState({
        question: "",
        cycleId: null,
        rubriqueId: null,
        assertion: "",
    });

    useEffect(() => {
        const fetchCycle = async () => {
            try {
                const res = await getListeCycle();
                if (res.result) {
                    setCycles(res.result);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des événements risques', error);
            }
        };

        fetchCycle();
    }, []);

    useEffect(() => {
        const fetchRubriquesCycle = async (cycle_id) => {
            try {
                const res = await getListeRubriqueByCycle(cycle_id);
                if (res.result) {
                    setRubriques(res.result);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des événements risques', error);
            }
        };

        if(paramsRecherche.cycleId){
            fetchRubriquesCycle(paramsRecherche.cycleId);
        }
    }, [paramsRecherche]);

    useEffect(() => {
        const fetchQuestionCycle = async () => {
            try {
                const res = await getListeQuestionCycle();
                if (res.result && res.result.length > 0) {
                    const questData = res.result.filter((item) =>
                        (assertData.some(assert =>
                            item.assertions.includes(assert.libelle))
                        )
                    );
                    setQuestions(questData);
                    setQuestionsFiltered(questData);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des événements risques', error);
            }
        };

        fetchQuestionCycle()
    }, [assertData]);

    const toggleCheckbox = (id) => {
        const selectedList = [...questionsTravail];
        const valueId = parseInt(id);

        if (isNaN(valueId)) {
            return;
        }

        const add = questions.find((item) => item.id === valueId);

        const isAlreadySelected = questionsTravail.some((element) => element.question_id === add.id);
        if (!isAlreadySelected) {
            const newElement = {
                question_id: add.id,
                ref: add.ref,
                questionLibelle: add.libelle,
                assertions: add.assertions,
            };

            setQuestionsTravail([...selectedList, newElement]);
        }
        else {
            const selectedIndex = selectedList.findIndex((item) => item.question_id === (add.id));

            const updatedList = selectedList.filter((_, i) => i !== selectedIndex);
            setQuestionsTravail(updatedList)
        }
    };

    const handleValidate = () => {
        if(questionsTravail.length > 0 ){
            setLoading(true);
            setTimeout(() => {
                const updateList = []
                questionsTravail.forEach((programme) => {

                    const newElement = {
                        // id: programme.id,
                        question_id: programme.question_id,
                        questionLibelle: programme.questionLibelle,
                        reponse: '',
                        incidence_id: incidence.id,                    };

                    updateList.push(newElement)
                })

                setSelectItems([...selectItems, ...updateList]);

                handleVisibility();
                notification.success({
                    description: "Question ajouté avec succès !",
                });

                setLoading(false);
            }, 1000)
        }
    }

    const columnsQuestions = [
        {
            title: () => <TableTitle>REF</TableTitle>,
            dataIndex: 'reference',
            width: 40,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '13px'}}>
                        {record.ref}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 250,
            render: (_, record, index) => (
                <span className='' key={index} style={{fontSize: '13px'}}>
                    {record.libelle}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 50,
            render: (_, record) => (
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 50,
            render: (_, record) => (
                <span className="form-check form-check-custom me-5">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={
                            questionsTravail.some((item) => item.question_id === record.id)
                        }
                        onChange={() => {toggleCheckbox(record.id)}}
                    />
              </span>
            ),
        },
    ];

    const columnsSelectQuestions = [
        {
            title: () => <TableTitle>Question</TableTitle>,
            dataIndex: 'libelle',
            width: 450,
            render: (_, record) => (
                <div className="d-flex align-center">
                    <div className="me-2">
                        <Tag>
                            <span className='fw-boldest' style={{fontSize: '12px'}}>
                                {record.ref}
                            </span>
                        </Tag>
                    </div>
                    <span className='' style={{fontSize: '13px'}}>
                        {record.questionLibelle}
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 100,
            render: (_, record) => (
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = questions.filter((ident) => {
                const {
                    question,
                    cycleId,
                    rubriqueId,
                    assertion,
                } = paramsRecherche;

                const lowercaseQuestion = question?.toLowerCase();
                const lowercaseAssertion = assertion?.toLowerCase();


                const matchQuestion =
                    !question ||
                    (ident.libelle
                        ?.toLowerCase()
                        ?.includes(lowercaseQuestion) ||
                    ident.ref
                        ?.toLowerCase()
                        ?.includes(lowercaseQuestion) );

                const matchAssertion =
                    !assertion ||
                    (ident.assertions ?? []).some(ass =>
                        ass?.toLowerCase()
                            ?.includes(lowercaseAssertion)
                    );

                const matchCycle =
                    !cycleId ||
                    ident.Bog_RubriqueCycle?.cycle_id === parseInt(cycleId);

                const matchRubrique =
                    !rubriqueId ||
                    ident?.rubriqueCycle_id === parseInt(rubriqueId);


                return (
                    matchQuestion &&
                    matchAssertion &&
                    matchCycle &&
                    matchRubrique
                );
            });

            setQuestionsFiltered(filteredClients)

            setLoading(false);
        }, 1000);
    };

    const handleReset = () => {
        setLoading(true);
        setParamsRecherche({
            question: "",
            cycleId: null,
            rubriqueId: null,
            assertion: "",
        });
        setQuestionsFiltered(questions);
        setLoading(false);
    };

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Sélectionner les questions de travail (sources) </ModalTitle>}
                footer={[]}
                modalStyles={{ top: 10 }}
                width={1300}
            >
                <FormContainer>
                    <SearchRow
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        loading={loading}
                    >
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <FormLabel label="Question" />
                            <Input
                                style={{ width: "100%" }}
                                size="large"
                                placeholder="Rechercher par la référence, libellé"
                                value={paramsRecherche.question || null}
                                onChange={(e) => {
                                    setParamsRecherche({
                                        ...paramsRecherche,
                                        question: e.target.value,
                                    })
                                }}
                            />
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <FormLabel label="Cycle" />
                            <Select
                                showSearch
                                placeholder="Rechercher par le cycle..."
                                optionFilterProp="label"
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={cycles.map((cy) => ({
                                    value: cy.id,
                                    label: cy.libelle,
                                }))}
                                value={paramsRecherche.cycleId || null}
                                onSelect={(value) =>
                                    setParamsRecherche({
                                        ...paramsRecherche,
                                        cycleId: value,
                                    })
                                }
                                size="large"
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <FormLabel label="Rubrique" />
                            <Select
                                showSearch
                                placeholder="Rechercher par la rubrique..."
                                optionFilterProp="label"
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={rubriques.map((rub) => ({
                                    value: rub.id,
                                    label: rub.libelle,
                                }))}
                                value={paramsRecherche.rubriqueId || null}
                                onSelect={(value) =>
                                    setParamsRecherche({
                                        ...paramsRecherche,
                                        rubriqueId: value,
                                    })
                                }
                                size="large"
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <FormLabel label="Assertions" />
                            <Select
                                showSearch
                                placeholder="Rechercher par l'assertion..."
                                optionFilterProp="label"
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={assertData.map((assertion) => ({
                                    value: assertion.libelle,
                                    label: assertion.libelle,
                                }))}
                                value={paramsRecherche.assertion || null}
                                onSelect={(value) =>
                                    setParamsRecherche({
                                        ...paramsRecherche,
                                        assertion: value,
                                    })
                                }
                                size="large"
                                style={{ width: "100%" }}
                            />
                        </Col>
                    </SearchRow>
                </FormContainer>
                <Row gutter={[16, 16]}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 16 }}
                        lg={{ span: 14 }}
                        xl={{ span: 14 }}
                    >
                        <FormContainer>
                            <div className="table-responsive">
                                <Table
                                    rowClassName={() =>
                                        `h-3px p-0 m-0 lh-0.5`
                                    }
                                    columns={columnsQuestions}
                                    dataSource={questionsFiltered ?? []}
                                    pagination={false}
                                    scroll={{y: 400}}
                                    loading={chargement || loading}
                                    size={"small"}
                                />
                            </div>
                        </FormContainer>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 10 }}
                        xl={{ span: 10 }}
                    >
                        <FormContainer>
                            <div className="table-responsive">
                                <Table
                                    rowClassName={() =>
                                        `h-3px p-0 m-0 lh-0.5`
                                    }
                                    columns={columnsSelectQuestions}
                                    dataSource={questionsTravail ?? []}
                                    pagination={false}
                                    scroll={{y: 400}}
                                    loading={chargement || loading}
                                    size={"small"}
                                />
                            </div>
                        </FormContainer>
                    </Col>
                </Row>
                <div className="d-flex justify-content-center align-items-center mt-3">
                    <div className="w-350px">
                        <Button
                            type="primary"
                            block
                            size="large"
                            // icon={<FontAwesomeIcon icon={fa} /> }
                            loading={loading}
                            disabled={loading}
                            onClick={handleValidate}
                        >
                            Valider
                        </Button>
                    </div>
                </div>
            </ModalAlert>
        </>
    )
}

const TraitementConceptionControles = ({ 
       setSelectedData,
       selectedData,
       mission,
       chargement,
       risqueIncidences,
       setProcedureData,
       procedureData,
       setIsTaskSave,
       setPrintData,
    }) => {
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [niveaux, setNiveaux] = useState([]);
    const [assertions, setAssertions] = useState([]);
    const [incidence, setIncidence] = useState({});
    const [assertData, setAssertData] = useState([])

    useEffect(() => {
        const fetchAssertion = async () => {
            try {
                const res = await getListeAssertionActive();
                setAssertions(res.result);
            } catch (error) {
                console.error('Erreur lors de la récupération des rubriques', error);
            }
        };
        const fetchNiveaux = async () => {
            try {
                const res = await getListeNiveau();
                setNiveaux(res.result);
            } catch (error) {
                console.error('Erreur lors de la récupération des niveaux', error);
            }
        };

        fetchAssertion();
        fetchNiveaux();
    }, []);

    useEffect(() => {
        setLoading(true);
            if (risqueIncidences.length > 0) {
                const asserts = [];

                risqueIncidences.forEach((event) => {
                    (event.assertions ?? []).forEach((item) => asserts.push(item));
                });

                const assertArray = assertions.filter(ass => asserts.includes(ass.libelle));

                setAssertData(assertArray || []);

                if (Object.keys(incidence).length <= 0) {
                    setIncidence(risqueIncidences[0]);
                }
            }
            setLoading(false);
    }, [mission, incidence, risqueIncidences, assertions]);

    useEffect(() => {
        getDetailsRisqueConception(mission.id)
            .then((res) => {
                if(res.result ){
                    const process = res.result.procedures.map((opt) => ({
                        id: opt.risqueDetails_id,
                        libelleProcedure: opt.libelleProcedure,
                        incidence_id: opt.risqueDetails_id,
                    }))

                    setProcedureData(process)

                    const questionsFetch = []

                    res.result.risques.map((item) => {
                        if(item.Lea_RisqueQuestionControlles){
                           item.Lea_RisqueQuestionControlles.forEach((risque) => {
                               const element = {
                                   id: risque.risqueDetails_id,
                                   questionLibelle: risque.questionLibelle,
                                   reponse: risque.reponse,
                                   incidence_id: risque.risqueDetails_id,
                               }

                               questionsFetch.push(element)
                           })
                        }
                    })

                    setSelectedData(questionsFetch)
                }
            })
    }, [mission, setProcedureData, setSelectedData]);

    useEffect(() => {
        const updatedData = risqueIncidences.flatMap((risque) => ({
            id: risque.id,
            incidence: risque.incidence,
            postes: risque.postes,
            assertions: risque.assertions,
            niveau_id: risque.niveau_id,
            niveauLibelle: niveaux.find((item) => item.id === risque.niveau_id)?.libelle,
            questions: selectedData.filter((question) => question.incidence_id === risque.id).map((opt) => ({
                questionLibelle: opt.questionLibelle,
                reponse: opt.reponse,
            })),
            procedures: procedureData.filter((procedure) => procedure.incidence_id === risque.id),
        }))

        setPrintData(updatedData)
    }, [selectedData, procedureData, risqueIncidences, niveaux, setPrintData]);

    const handleAdd = () => {
        setLoading(true);
        setTimeout(() => {
            const newElement = {
                libelleProcedure: '',
                incidence_id: incidence.id
            };

            setProcedureData([...procedureData, newElement]);

            setIsTaskSave(prevState => [...prevState, 1]);
            notification.success({
                description: "Procédure ajoutée avec succès !",
            });
            setLoading(false);
        }, 1000);
    };

    const handleQuestion = () => {
        setLoading(true);
        setTimeout(() => {
            const newElement = {
                questionLibelle: '',
                reponse: '',
                incidence_id: incidence.id,
            };

            setSelectedData([...selectedData, newElement]);
            setIsTaskSave(prevState => [...prevState, 1]);
            notification.success({
                description: "Question ajoutée avec succès !",
            });
            setLoading(false);
        }, 1000);

    };

    const handleDeleteElement = (index) => {
        setLoading(true);
        setTimeout(() => {
            const updatedData = [...procedureData]
            // updatedData.filter((proc) => proc.incidence_id === incidence.id).filter((_, i) => i !== index);
            updatedData.filter((proc) => proc.incidence_id === incidence.id).splice(index, 1);
            setProcedureData(updatedData);

            setIsTaskSave(prevState => [...prevState, 1]);
            notification.success({
                description: "Procédure supprimée avec succès !",
            });
            setLoading(false)
        }, 1000);
    };

    const handleDeleteQuestions = (index) => {
        setLoading(true);
        setTimeout(() => {
            const updatedData = [...selectedData]
            // updatedData.filter((quest) => quest.incidence_id === incidence.id).filter((_, i) => i !== index);
            updatedData.filter((quest) => quest.incidence_id === incidence.id).splice(index, 1);
            setSelectedData(updatedData);

            setIsTaskSave(prevState => [...prevState, 1]);
            notification.success({
                description: "Question supprimée avec succès !",
            });
            setLoading(false)
        }, 1000);

    };

    const handleOpenModal = () => {
        setIsModalOpen(prevState => !prevState);
    };

    const columnsRisque = [
        {
            title: () => <TableTitle>Incidence (effet) du facteur de risque sur les EF</TableTitle>,
            dataIndex: 'incidence',
            width: 400,
            render: (_, record) => (
                <span className='' style={{fontSize: '14px'}}>
                  {record.incidence}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 100,
            render: (_, record) => (
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: () => <TableTitle>Niveau Risque</TableTitle>,
            dataIndex: 'niveau_id',
            width: 100,
            render: (_, record) => (
                <Tag color={record.score < 6 ? "success" : record.score <= 15 ? "warning" : "red"}>
                    <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                        {niveaux.find((niveau) => niveau.id === record.niveau_id)?.libelle}
                    </span>
                </Tag>
            ),
        },
    ];

    const columnsProcedures = [
        {
            title: () => <TableTitle>Procédures</TableTitle>,
            dataIndex: 'libelleProcedure',
            width: 500,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser la procédure..."
                        value={record.libelleProcedure}
                        maxLength={3000}
                        showCount
                        autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }}
                        onChange={(e) => {
                            const updatedData = [...procedureData];
                            updatedData.filter((proc) => proc.incidence_id === incidence.id)[index].libelleProcedure = e.target.value
                            setProcedureData(updatedData);
                            setIsTaskSave(prevState => [...prevState, 1]);
                        }}
                    />
                </div>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 100,
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteElement(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    const columsQuestions = [
        {
            title: () => <TableTitle>Question</TableTitle>,
            dataIndex: 'questions',
            width: 400,
            render: (_, record, index) => (
                <Input.TextArea
                    style={{fontSize: "14px"}}
                    size="large"
                    bordered={false}
                    placeholder="Saisisser ici..."
                    value={record.questionLibelle}
                    maxLength={3000}
                    showCount
                    autoSize={{
                        minRows: 2,
                        maxRows: 6,
                    }}
                    onChange={(e) => {
                        const updatedData = [...selectedData];
                        updatedData.filter((proc) => proc.incidence_id === incidence.id)[index].questionLibelle = e.target.value
                        setSelectedData(updatedData);
                        setIsTaskSave(prevState => [...prevState, 1]);
                    }}
                />
            ),
        },
        {
            title: () => <TableTitle>Réponses</TableTitle>,
            dataIndex: 'reponses',
            width: 400,
            render: (_, record, index) => (
                <Input.TextArea
                    style={{fontSize: "14px"}}
                    size="large"
                    bordered={false}
                    placeholder="Saisisser ici..."
                    value={record.reponse}
                    maxLength={3000}
                    showCount
                    autoSize={{
                        minRows: 2,
                        maxRows: 6,
                    }}
                    onChange={(e) => {
                        const updatedData = [...selectedData];
                        updatedData.filter((proc) => proc.incidence_id === incidence.id)[index].reponse = e.target.value
                        setSelectedData(updatedData);
                        setIsTaskSave(prevState => [...prevState, 1]);
                    }}
                />
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 100,
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteQuestions(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    const handleRowClick = (record) => {
        return new Promise((resolve) => {
            setLoading(true);
            setTimeout(() => {
                setIncidence(record);
                setLoading(false);
                resolve();
            }, 1000);
        });
    };

    return (
        <>
            <div className='mx-3'>
                <Row gutter={[16, 16]}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                    >
                        <div className="table-responsive">
                            {/*<Table*/}
                            {/*    onRow={(record) => {*/}
                            {/*        return {*/}
                            {/*            onClick: () => {*/}
                            {/*                setLoading(true);*/}
                            {/*                setTimeout(() => {*/}
                            {/*                    setIncidence(record);*/}
                            {/*                    setLoading(false);*/}
                            {/*                }, 1000)*/}
                            {/*            },*/}
                            {/*        };*/}
                            {/*    }}*/}
                            {/*    rowClassName={() =>*/}
                            {/*        `cursor-pointer h-3px p-0 m-0 lh-0.5`*/}
                            {/*    }*/}
                            {/*    columns={columnsRisque}*/}
                            {/*    rowKey={(record, index) => index}*/}
                            {/*    dataSource={risqueIncidences ?? []}*/}
                            {/*    pagination={false}*/}
                            {/*    scroll={{y: 400}}*/}
                            {/*    loading={chargement || loading}*/}
                            {/*    rowSelection*/}
                            {/*    // size={"small"}*/}
                            {/*/>*/}
                            <DynamicTable
                                columns={columnsRisque}
                                dataSource={risqueIncidences}
                                loading={chargement}
                                onRowClickHandler={handleRowClick}
                            />
                        </div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                    >
                        <Title className="my-5" style={{display: "block", fontWeight: '600', color: appTheme.colors.primary}} level={4}># {"Incidence sélectionnée"}</Title>
                        {
                            loading ?
                                    <div className="d-flex justify-content-center align-items-center my-auto">
                                        <Spinner/>
                                    </div>
                                :
                                <Row>
                                    <Col span={24}>
                                        <FormLabel label={"Incidence (effet) du facteur de risque sur les EF"} />
                                        <Input.TextArea
                                            style={{fontSize: "14px"}}
                                            size="large"
                                            bordered={false}
                                            value={incidence.incidence}
                                            maxLength={3000}
                                            // showCount
                                            autoSize={{
                                                minRows: 2,
                                                maxRows: 8,
                                            }}
                                        />
                                    </Col>
                                    <br/>
                                    <Col span={6}>
                                        <FormLabel label={"Assertions"} />
                                        <div className='d-flex align-center' >
                                            {(incidence?.assertions ?? []).map((assertion, index) => (
                                                <Space>
                                                    <Tag key={index}>
                                                <span className='fw-boldest' key={index}>
                                                    {assertion}
                                                </span>
                                                    </Tag>
                                                </Space>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <FormLabel label={"Niveaux de risque"} />
                                        <Tag color={incidence.score < 6 ? "success" : incidence.score <= 15 ? "warning" : "red"}>
                                    <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                                        {niveaux.find((niveau) => niveau.id === incidence.niveau_id)?.libelle}
                                    </span>
                                        </Tag>
                                    </Col>
                                    <Col span={12}>
                                        <FormLabel label={"Postes des EF concernés"} />
                                        <div className="d-flex flex-column">
                                            {(incidence?.postes ?? []).map((poste, index) => (
                                                poste ? <div className="mb-2" key={index}>
                                                        <Tag
                                                            style={{fontSize: "12px", fontWeight: "700", textTransform: "uppercase"}}
                                                        >
                                                            {`${poste} - ${(postesStat ?? []).find( opt => opt.key === poste)?.title}`}
                                                        </Tag>
                                                    </div>
                                                    :
                                                    ""
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                        }
                    </Col>
                </Row>
                <div className="mb-15"></div>
                <div className="d-flex justify-content-end align-center my-2 me-5">
                    <div className="d-flex flex-wrap align-center">
                        <Space>
                            {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                                <Button
                                    size={"middle"}
                                    style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                    onClick={handleQuestion}
                                    icon={<FontAwesomeIcon icon={faCartPlus}/>}
                                >
                                    Ajouter
                                </Button>
                            </Tooltip>}
                            {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                                <Button
                                    size={"middle"}
                                    style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                    onClick={handleOpenModal}
                                    icon={<FontAwesomeIcon icon={faCloudUpload}/>}
                                >
                                    Charger
                                </Button>
                            </Tooltip>}
                        </Space>
                    </div>
                </div>
                <div className="table-responsive pt-5 pb-10">
                    <Table
                        rowClassName={() =>
                            `h-3px p-0 m-0 lh-0.5`
                        }
                        columns={columsQuestions}
                        // rowKey={(record, index) => index}
                        dataSource={selectedData.filter(data => data.incidence_id === incidence.id) ?? []}
                        pagination={false}
                        scroll={{y: 800}}
                        loading={chargement || loading}
                        // rowSelection
                        size={"small"}
                    />
                </div>
                <div className="mb-15"></div>
                <div className="col-xl-6">
                    <div className="d-flex justify-content-end align-center my-2 me-5">
                        <div className="d-flex flex-wrap align-center">
                            <Space>
                                {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                                    <Button
                                        size={"middle"}
                                        style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                        onClick={handleAdd}
                                        icon={<FontAwesomeIcon icon={faCartPlus}/>}
                                    >
                                        Ajouter
                                    </Button>
                                </Tooltip>}
                            </Space>
                        </div>
                    </div>
                    <div className="table-responsive pt-5 pb-10">
                        <Table
                            rowClassName={() =>
                                `h-3px p-0 m-0 lh-0.5`
                            }
                            columns={columnsProcedures}
                            // rowKey={(record, index) => index}
                            dataSource={procedureData.filter(data => data.incidence_id === incidence.id) ?? []}
                            pagination={false}
                            scroll={{y: 800}}
                            loading={chargement || loading}
                            // rowSelection
                            size={"small"}
                        />
                    </div>
                </div>
            </div>

            {isModalOpen &&
                <ModaQuestionlCycles
                    handleVisibility={handleOpenModal}
                    visible={isModalOpen}
                    selectItems={selectedData}
                    setSelectItems={setSelectedData}
                    incidence={incidence}
                    assertData={assertData}
                    assertions={assertions}
                    chargement={loading}
                />
            }
        </>
    );
};


export  {TraitementConceptionControles}