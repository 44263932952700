import React, {useEffect, useState} from "react"
import { Identification } from "../../Identification";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import {TraitementBlanchiment} from "./blanchiment/TraitementBlanchiment";
import swal from "sweetalert";
import {ajouterRisqueTextBlanchiment, getListeRisqueTextBlanchiment} from "../../../../../data/risqueTextBlanchiment";
import {faIdCard, faSave, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {Button, Tooltip} from "antd";
import {appTheme} from "../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PrintTraitementBlanchiment from "./blanchiment/PrintTraitementBlanchiment";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const RisqueBlanchiment = ({
           mission,
           client,
           code,
           modalId,
           prev
       }) => {
    // const user = useSelector(state => state.user)
    const [activeTab, setActiveTab] = useState(0);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [codeSommaire] = useState(code || 'ED4');
    const [date, setDate] = useState('');
    const [isAddMemoire, setIsAddMemoire] = useState(false)
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [conclusion, setConclusion] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [title, setTitle] = useState('');
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(mission.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [activeTab, mission, codeSommaire])

    useEffect(()=> {
        getListeRisqueTextBlanchiment(missionSelected.id)
            .then((res) => {
                if(res.result){
                    const risque = res.result.find((item) => item.codeSommaire === codeSommaire)
                    if(risque) {
                        setSelectedData(risque.Lea_RisqueTexte_BlanchimentQuestions);
                        setConclusion(risque.conclusion);
                    }
                }
            })
        if(modalId === 1){
            setTitle("ED4 - Risques liés aux blanchiments d'argent");
        }
        else{
            setTitle("ED5 - Risques liés au respect des textes légaux et réglementaires");
        }
    }, [missionSelected, codeSommaire, modalId])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const handleSubmit = async () => {
        setLoading(true);

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            isAddMemoire,
            codeSommaire,
            isProvisoire,
            datas: selectedData,
            conclusion,
            exercice_id: mission.id,
            client_id: mission.client_id,
        };

        const {status, message} = await ajouterRisqueTextBlanchiment(data)
        if (status === 'success') {
            setLoading(false)
            setAuth(false);
            await swal(`Opération effectuée avec succès`, '', 'success');
            setIsTaskSave(prevState => (prevState ?? []).filter((item) => item !== activeTab));
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "CB",
            title: `TRAITEMENT - ${selectYear}`,
            description: `TRAITEMENT - ${selectYear}`,
            icon: faTasks,
            content: (
                <TraitementBlanchiment
                    selectItems={selectedData}
                    setSelectItems={setSelectedData}
                    setIsAddMemoire={setIsAddMemoire}
                    isAddMemoire={isAddMemoire}
                    conclusion={conclusion}
                    setConclusion={setConclusion}
                    modalId={modalId}
                    mission={missionSelected}
                    chargement={loading}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
    ];

   return (
    <>
        <div className="mx-5">
            <PageToolbarHeader
                prev={prev}
                pageTitle={title}
                mission={missionSelected}
                setMission={setMissionSelected}
                isProvisoire={isProvisoire}
                setIsProvisoire={setIsProvisoire}
                selectYear={selectYear}
                setSelectYear={setSelectYear}
                loading={loading}
                setLoading={setLoading}
                client={client}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                setDate={setDate}
                setTache_id={setTache_id}
                tache_id={tache_id}
                setIntervention_id={setIntervention_id}
                intervention_id={intervention_id}
                setHeureEntree={setHeureEntree}
                setUser_id={setUser_id}
                isExcelPrint={false}
                isLandingScape={true}
                printTitle={(`${title} ${selectYear}`)}
                excelData={selectedData}
                printContent={(
                    <PrintTraitementBlanchiment
                        datas={selectedData}
                        conclusion={conclusion}
                        title={title}
                    />
                )}
            />
            <StepHeader>
                <ModalNavBarMenu
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    steps={formSteps}
                    setLoading={setLoading}
                    loading={loading}
                    isTaskSave={isTaskSave}
                    auth={auth}
                />
            </StepHeader>
            <StepBody>
                <>
                    <div className="d-flex justify-content-end align-items-end mb-10">
                        <Tooltip title="Enregistrer">
                            <Button
                                disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                                size={"large"}
                                // loading={loading}
                                style={{
                                    backgroundColor: appTheme.colors.secondary,
                                    color: "white"
                                }}
                                icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                onClick={handleSubmit}
                            >
                                {!loading &&
                                    <span className='indicator-label text-white'>Enregistrer</span>}
                                {loading && (
                                    <span className='indicator-progress fs-9 text-white'
                                          style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                )}
                            </Button>
                        </Tooltip>
                    </div>
                    {formSteps[activeTab].content}
                </>
            </StepBody>
        </div>
    </>
   );
}

export {RisqueBlanchiment}