import React from 'react';
import '../testejournaux.css';

const PrintIntangibilite = ({datas}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        minHeight: "20px"
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <div className="card">

            <div className="table-responsive mb-5">
                <div className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className="tablePerso w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={thStyle} className="thperso w-80px">Compte_Prov</th>
                                <th style={thStyle} className="thperso w-200px">Libellé Prov</th>
                                <th style={thStyle} className="thperso w-80px">SD_RAN</th>
                                <th style={thStyle} className="thperso w-80px">SC_RAN</th>
                                <th style={thStyle} className="thperso w-80px">Compte Def</th>
                                <th style={thStyle} className="thperso w-200px">Libellé Def</th>
                                <th style={thStyle} className="thperso w-80px">SD_Def</th>
                                <th style={thStyle} className="thperso w-80px">SC_Def</th>
                                <th style={thStyle} className="thperso w-80px">DIFF_SD</th>
                                <th style={thStyle} className="thperso w-80px">DIFF_SC</th>
                                <th style={thStyle} className="thperso w-200px">Commentaire</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(datas && datas.length > 0) && datas.map((item, index) => (
                                <tr key={index} style={tdStyle} className="">
                                    <td className="" style={{...tdStyle, fontSize: '9px' }}>
                                        {item.compte_Prov}
                                    </td>
                                    <td className=" text-start" style={{...tdStyle, fontSize: '9px' }}>
                                        {item.libelle_Prov}
                                    </td>
                                    <td className=" text-center" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {(item.SD_RAN || 0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {(item.SC_RAN || 0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td className="" style={{...tdStyle, fontSize: '9px' }}>
                                        {item.compte_Def}
                                    </td>
                                    <td className="" style={{...tdStyle, fontSize: '9px' }}>
                                        {item.libelle_Def}
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {(item.SD_N1).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {item.SC_N1.toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {item.DIFF_SD.toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {item.DIFF_SC.toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className="text-start" style={{fontSize: '9px'}}>
                                            {item.commentaire || ""}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrintIntangibilite;
