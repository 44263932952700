import React, {useEffect, useState} from 'react';
import {Alert, Button, Modal, Popconfirm, Select, Space, Table, Tag} from 'antd';
import {
    DeleteOutlined,
    EditOutlined,
    LockOutlined,
    PlusOutlined, UnlockOutlined,
} from '@ant-design/icons';
import {optionsSwal} from '../../../../helpers/AssetsHelpers';
import {ExportHeader, SearchHeader} from '../../../../components/layout/header/HeaderList';
import Swal from 'sweetalert2'
import swal from 'sweetalert'
import {addRoleMission, deleteRoleMission, getListeRoleMission, updateEtatRoleMission, updateRoleMission} from "../../../../data/params/RoleMission";
import {DateUserListe} from "../../../../components/layout/pageData/DateUserListe";
import {getListeTacheActive} from "../../../../data/params/tache";
import {getListeInterventionActive} from "../../../../data/params/intervention";


const RoleMissionsList = () => {
    // const record = useSelector(state => state.record)
    const [roleMissions, setRoleMissions] = useState([])
    const [profil, setRoleMission] = useState([])
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [libelle, setLibelle] = useState('')
    const [description, setDescription] = useState('')
    const [actions, setActions] = useState([])
    const [intervention, setInterventions] = useState([])
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [edite, setEdite] = useState(false);
    const [filtersItems, setFiltersItems] = useState([]);
    const [taches, setTaches] = useState([]);
    const [interventionsList, setInterventionsList] = useState([]);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
    });

    useEffect(() => {
        fetchListeTache()
        fetchIntervention()
    }, [])

    const fetchListeTache = () => {
        getListeTacheActive()
            .then((res) => {
                setTaches(res.result)
            })
    };

    const fetchIntervention = () => {
        getListeInterventionActive()
            .then((res) => {
                setInterventionsList(res.result)
            })
    };

    const fetchRoleMissions = () => {
        setLoading(true);
        getListeRoleMission()
            .then((res) => {
                setRoleMissions(res);
                setFiltersItems(res)
                handleCancel()
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchRoleMissions();
    }, [setRoleMissions]);


    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = roleMissions.filter(
            (item) =>
                item.libelle.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                // item.actions?.map((action) => action.toLowerCase().includes(searchTerm.toLowerCase())) ||
                // item.intervention?.map((intervention) => intervention.toLowerCase().includes(searchTerm.toLowerCase())) ||
                item.id === parseInt(searchTerm)
        );
        setFiltersItems(filtered);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setRoleMissions([]);
        }
    };
    const showModal = () => {
        setOpen(true);
    };
    const handleAddRoleMission = async () => {
        setLoading(true);
        const data = {libelle, description, actions, intervention}

        const {status, message} = await addRoleMission(data)
        setMessage(message)
        if (status === 'success') {
            setError(false)
            setLibelle('')
            setDescription('')
            setActions('')
            setInterventions('')
            fetchRoleMissions()
            setLoading(false)
        } else {
            setError(true)
            setLoading(false)
        }
    };

    const handleModifierPofil = async () => {
        setLoading(true);
        const data = {profil_id: profil.id, libelle, description, actions, intervention}
        const {status, message} = await updateRoleMission(data)
        setMessage(message)
        if (status === 'success') {
            setError(false)
            setLibelle('')
            setDescription('')
            setActions('')
            setInterventions('')
            fetchRoleMissions()
            setLoading(false)
        } else {
            setError(true)
            setLoading(false)
        }
    };

    const handleDeleteRoleMission = async (profil_id) => {
        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const {status, message} = await deleteRoleMission({profil_id})
                if (status === 'error') {
                    await swal(`${message}`, '', 'warning')
                }
                setMessage(message)
                if (status === 'success') {
                    setError(false)
                    setLoading(false)
                    fetchRoleMissions()
                } else {
                    setError(true)
                    setLoading(false)
                }
            }
        })
    }

    const handleUpdateEtatRoleMission = async (profil_id, etat_profil) => {
        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const data = {profil_id, etat: etat_profil}
                const {status, message} = await updateEtatRoleMission(data)
                if (status === 'error') {
                    swal(`${message}`, '', 'warning')
                }
                setMessage(message)
                if (status === 'success') {
                    setError(false)
                    setLibelle('')
                    setDescription('')
                    setActions('')
                    setInterventions('')
                    fetchRoleMissions()
                    setLoading(false)
                } else {
                    setError(true)
                    setLoading(false)
                }
            }
        })
    }

    const handleCancel = () => {
        setOpen(false);
        setEdite(false)
        setLibelle('')
        setDescription('')
        setActions('')
        setInterventions('')
        setError(false)
    };

    const selectRoleMission = (profil_id) => {
        const privil = roleMissions.filter(periode => periode.id === profil_id)[0]
        setRoleMission(privil)
        setLibelle(privil.libelle)
        setDescription(privil.description)
        setActions(privil.actions)
        setInterventions(privil.intervention)
        setEdite(true)
        setOpen(true);
    }

    const columns = [
        {
            title: '#',
            render: (text, record, index) => <span className='display-block chart-blue'>{index + 1}</span>
        },
        {
            title: 'Libelle',
            dataIndex: 'libelle',
            key: 'libelle',
            render: (text) => <span className='display-block bold'>{text}</span>,
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => (
                <div className='d-flex flex-column align-center'>
                    {record.actions?.map((action, index) => (
                        <span className='fw-bolder me-5' key={index}>
                          <span className="fw-bolder fs-20 me-5">.</span>{taches.find((item) => item.id === action)?.libelle}
                        </span>
                    ))}
                </div>
            )
        },
        {
            title: 'Interventions',
            dataIndex: 'interventions',
            key: 'interventions',
            render: (_, record) => (
                <div className='d-flex flex-column align-center'>
                    {record.intervention?.map((intervention, index) => (
                        <span className='fw-bolder me-5' key={index}>
                         <span className="fw-bolder fs-20 me-5">.</span>{interventionsList.find((item) => item.id === intervention)?.libelle}
                        </span>
                    ))}
                </div>
            )
        },
        {
            title: 'Etat',
            dataIndex: 'etat',
            key: 'etat',
            render: (_, record) => record.etat === 1 ? <Tag color="green">Activé</Tag> :
                <Tag color="red">Désactivé</Tag>
        },
        {
            title: 'Créée le / Par',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record, index) => <DateUserListe date={record.createdAt}
                 user={record.nom_user + " " + record.prenom_user} key={index}/>
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        icon={<EditOutlined/>}
                        onClick={() => selectRoleMission(record.id)}
                    />

                    {record.etat === 0 && <Popconfirm
                        title={`Activer la période ${record.libelle} ?`}
                        description={`Voulez vous vraiment activer la période ${record.libelle} ?`}
                        onConfirm={() => handleUpdateEtatRoleMission(record.id, 1)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            icon={<UnlockOutlined/>}
                            className='chart-bg-orange'
                            title='Activer ?'
                        />
                    </Popconfirm>}

                    {record.etat === 1 && <Popconfirm
                        title={`Désactiver la période ${record.libelle} ?`}
                        description={`Voulez vous vraiment désactiver la période ${record.libelle} ?`}
                        onConfirm={() => handleUpdateEtatRoleMission(record.id, 0)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            icon={<LockOutlined/>}
                            className='chart-bg-green'
                            title='Désactiver ?'
                        />
                    </Popconfirm>}

                    <Popconfirm
                        title={`Supprimer la période ${record.libelle} ?`}
                        description={`Voulez vous vraiment supprimer la période ${record.libelle} ?`}
                        onConfirm={() => handleDeleteRoleMission(record.id)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            title='Supprimer ?'
                            type="primary"
                            icon={<DeleteOutlined/>}
                            danger
                        />
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    return (
        <>
            <div className="card w-100">
                {error ? (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{message}</div>
                    </div>
                ) : (
                    ''
                )}
                <div className="card-header border-0 pt-6">
                    <SearchHeader handleSearch={handleSearchChange}/>
                    <div className="card-toolbar">
                        <div className="d-flex justify-content-end" data-kt-record-table-toolbar="base">
                            <ExportHeader/>
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_add_user"
                                onClick={showModal}
                            >
                                <span className="svg-icon svg-icon-2">
                                    <PlusOutlined style={{fontSize: '2px'}}/>
                                </span>
                                Nouveau
                            </button>

                        </div>
                    </div>
                </div>
                <div className="card-body py-4">
                    <div className="table-responsive">
                        <Table
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={filtersItems}
                            pagination={tableParams.pagination}
                            loading={loading}
                            onChange={handleTableChange}
                        />
                    </div>
                </div>
            </div>

            <Modal
                open={open}
                title={!edite ? "Enregistrer un nouveau groupe" : "Modifier le groupe"}
                onOk={!edite ? handleAddRoleMission : handleModifierPofil}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Annuler
                    </Button>,
                    <Button key="submit" type="primary" loading={loading}
                            onClick={!edite ? handleAddRoleMission : handleModifierPofil}>
                        Enregistrer
                    </Button>,
                ]}
            >
                {error === true && <Space
                    direction="vertical"
                    style={{
                        width: '100%',
                    }}
                >

                    <Alert message={message} type="error" showIcon/>

                </Space>}
                <div className='row fv-row mb-7 fv-plugins-icon-container'>
                    <div className='col-md-3 text-md-end'>
                        {/*begin::Label*/}
                        <label className='fs-6 fw-bold form-label mt-3'>
                            <span>Libelle</span>
                        </label>
                        {/*end::Label*/}
                    </div>
                    <div className='col-md-9'>
                        <input type="text" name="fname"
                               className="form-control form-control-lg mb-3 mb-lg-0"
                               onChange={e => setLibelle(e.target.value)} value={libelle}
                               placeholder="Entrez le libelle"/>
                    </div>
                </div>
                <div className='row fv-row mb-7 fv-plugins-icon-container'>
                    <div className='col-md-3 text-md-end'>
                        {/*begin::Label*/}
                        <label className='fs-6 fw-bold form-label mt-3'>
                            <span>Tâches ou actions</span>
                        </label>
                        {/*end::Label*/}
                    </div>
                    <div className='col-md-9'>
                        <Select
                            mode='tags'
                            style={{width: '100%'}}
                            placeholder='choisir les tâches'
                            onChange={setActions}
                            value={actions}
                            options={taches.map((item) => ({
                                label: item.libelle,
                                value: item.id,
                            }))}
                        />
                        {/*end::Input*/}
                    </div>
                </div>
                <div className='row fv-row mb-7 fv-plugins-icon-container'>
                    <div className='col-md-3 text-md-end'>
                        {/*begin::Label*/}
                        <label className='fs-6 fw-bold form-label mt-3'>
                            <span>Interventions</span>
                        </label>
                        {/*end::Label*/}
                    </div>
                    <div className='col-md-9'>
                        <Select
                            mode='tags'
                            style={{width: '100%'}}
                            placeholder='choisir les interventions'
                            onChange={setInterventions}
                            value={intervention}
                            options={interventionsList.map((item) => ({
                                label: item.libelle,
                                value: item.id,
                            }))}
                        />
                        {/*end::Input*/}
                    </div>
                </div>
            </Modal>
        </>
    );
};


export default RoleMissionsList;
