import React, {useEffect, useState} from "react";
import {Button, Table, Tooltip, Typography} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {ajouterStrategieBilanEA, getStrategieBilanEA} from "../../../../../../data/stategieGlobale";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../helpers/AssetsHelpers";
import TableTitle from "../../../../../common/TableTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {appTheme} from "../../../../../../config/theme";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

const DonneesChiffrees = ({mission, isProvisoire, strategies, codeSommaire , heureEntree, date, tache_id, intervention_id, chargement, etatsFinanciers}) => {
    const [activeTab, setActiveTab] = useState(0);
    const [bilanActif, setBilanActif] = useState([]);
    const [bilanPassif, setBilanPassif] = useState([]);
    const [compteResult, setCompteResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isActif, setIsActif] = useState(true)
    const [isPassif, setIsPassif] = useState(true)
    const [isResultat, setIsResultat] = useState(true)
    const [isProvisoireTemp, setIsProvisoireTemp] = useState(false)
    const totalActifsValues = ["AD", "AI", "AQ", "AQ", "AZ", "BG", "BK", "BT"]
    const totalPassifsValues = ['CP', 'DD', 'DF', 'DP', 'DT']
    const totalResultatsValues = ['XA', 'XB', 'XC', 'XD', 'XE', 'XF', 'XG', 'XH']

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

    };

    useEffect(() => {
        setIsProvisoireTemp((parseInt(isProvisoire) === 1) || true);
    }, [isProvisoire])

    useEffect(()=> {
        setLoading(true)
        if(strategies){
            getStrategieBilanEA(strategies.id)
                .then((res) => {
                    if(res.result && res.result.length > 0){
                        const actifs = res.result?.filter((item) => item.libelleEA?.includes("ACTIFS") && item.isProvisoire === isProvisoireTemp)
                        if(actifs && actifs.length > 0){
                            const elements = actifs.map((item) => ({
                                ref: item.ref,
                                libelle: item.libelle,
                                montant: item.montant,
                                montantN1: item.montantN1,
                            }));

                            setLoading(false)
                            setIsActif(true)
                            setBilanActif(elements)
                        }
                        else{
                            setBilanActif([])
                            setLoading(false)
                        }
                        const passifs = res.result?.filter((item) => item.libelleEA?.includes("PASSIFS") && item.isProvisoire === isProvisoireTemp)
                        if(passifs && passifs.length > 0){
                            const elements = passifs.map((item) => ({
                                ref: item.ref,
                                libelle: item.libelle,
                                montant: item.montant,
                                montantN1: item.montantN1,
                            }));

                            setBilanPassif(elements.sort((a, b) => {
                                return a.ref.localeCompare(b.ref);
                            }))
                            setIsPassif(true)
                            setLoading(false)
                        }
                        else{
                            setBilanPassif([])
                            setLoading(false)
                        }
                        const results = res.result?.filter((item) => item.libelleEA?.includes("RESULTATS") && item.isProvisoire === isProvisoireTemp)
                        if(results && results.length > 0){
                            const elements = results.map((item) => ({
                                ref: item.ref,
                                libelle: item.libelle,
                                montant: item.montant,
                                montantN1: item.montantN1,
                            }));

                            setLoading(false)
                            setIsResultat(true)
                            setCompteResult(elements)
                        }
                        else{
                            setCompteResult([])
                            setLoading(false)
                        }
                    }
                    else{
                        setIsActif(false);
                        setIsPassif(false)
                        setIsResultat(false)
                    }
                })
        }
        else{
            setLoading(false)
        }
    }, [strategies, isProvisoireTemp])

    useEffect(()=> {
        setLoading(true)
        if((isActif === false) && (isPassif === false) && (isResultat === false)){
            if((etatsFinanciers ?? []).length > 0){
                const actifs = etatsFinanciers[0]?.Lea_BilanActifs?.filter((item) => (item.montant !== 0) && (item.montantN1 !== 0) && (item.isProvisoire === isProvisoireTemp))
                if(actifs && actifs.length > 0){
                    const elements = actifs.map((item) => ({
                        ref: item.ref,
                        libelle: item.libelle,
                        montant: item.montant,
                        montantN1: item.montantN1,
                    }));

                    setBilanActif(elements);
                    setLoading(false)
                }
                else{
                    setBilanActif([])
                    setLoading(false)
                }
                const passifs = etatsFinanciers[0]?.Lea_BilanPassifs?.filter((item) => (item.montant !== 0) && (item.montantN1 !== 0) && (item.isProvisoire === isProvisoireTemp))
                if(passifs && passifs.length > 0){
                    const elements = passifs.map((item) => ({
                        ref: item.ref,
                        libelle: item.libelle,
                        montant: item.montant,
                        montantN1: item.montantN1,
                    }));

                    setBilanPassif(elements.sort((a, b) => {
                        return a.ref.localeCompare(b.ref);
                    }))

                    setLoading(false)
                }
                else{
                    setBilanPassif([])
                    setLoading(false)
                }
                const results = etatsFinanciers[0]?.Lea_CompteResultats?.filter((item) => (item.montant !== 0) && (item.montantN1 !== 0) && (item.isProvisoire === isProvisoireTemp))
                if(results && results.length > 0){
                    const elements = results.map((item) => ({
                        ref: item.ref,
                        libelle: item.libelle,
                        montant: item.montant,
                        montantN1: item.montantN1,
                    }));

                    setLoading(false)
                    setCompteResult(elements);
                }
                else{
                    setCompteResult([])
                    setLoading(false)
                }
            }
        }
        setLoading(false)
    }, [mission, isProvisoireTemp, isActif, isPassif, isResultat, etatsFinanciers])

    const handleTabChange = (tabName) => {
        setLoading(true);
        setTimeout(() => {
            setActiveTab(tabName);
            setLoading(false);
        }, 1000)
    };

    const handleDeleteActif = (index) => {
        const updatedList = bilanActif.filter((_, i) => i !== index);
        setBilanActif(updatedList);
    }

    const handleDeletePassif = (index) => {
        const updatedList = bilanPassif.filter((_, i) => i !== index);
        setBilanPassif(updatedList);
    }

    const handleDeleteResultat = (index) => {
        const updatedList = compteResult.filter((_, i) => i !== index);
        setCompteResult(updatedList);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            strategie_id: strategies?.id,
            bilanData: activeTab === 0 ? bilanActif : activeTab === 1 ? bilanPassif : compteResult,
            libelleEA: activeTab === 0 ? "ACTIFS" : activeTab === 1 ? "PASSIFS" : "RESULTATS",
            codeSommaire ,
            heureEntree,
            date,
            tache_id,
            intervention_id,
            isProvisoire: isProvisoireTemp,
            exercice_id: mission.id,
            client_id: mission.client_id
        }

        const {status, message} = await ajouterStrategieBilanEA(data)
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    }

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Référence</TableTitle>,
            dataIndex: 'ref',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.ref}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 400,
            render: (_, record) => (
                <>
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                     {record.libelle}
                </span>
                </>
            ),
            responsive: ['sm']
        },
        {
            title: () => <TableTitle>{`Exercice ${mission.annee || "N"}`}</TableTitle>,
            dataIndex: 'exerciceN',
            width: 100,
            render: (_, record) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {record.montant && record.montant.toLocaleString('fr')}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.montantN1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>N  -  N-1</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width:100,
                    render: (_, record) => (
                        <>
                            <div
                                className={`text-end p-1 fw-bolder 
                                        ${( ( (record.montant)=== 0 ? 0 : (record.montant - record.montantN1) / record.montantN1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.montant)=== 0 ? 0 : (record.montant - record.montantN1) / record.montantN1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                                style={{fontSize: '14px'}}
                            >
                                {(record.montant - record.montantN1).toLocaleString('fr')}
                            </div>
                        </>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    width:70,
                    key:'taux',
                    render: (_, record) => (
                        <div
                            className={`text-end p-1 fw-bolder 
                                        ${( ( (record.montant)=== 0 ? 0 : (record.montant - record.montantN1) / record.montantN1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.montant)=== 0 ? 0 : (record.montant - record.montantN1) / record.montantN1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                            style={{fontSize: '14px'}}
                        >
                            {( ( (record.montant)=== 0 ? 0 : (record.montant - record.montantN1) / record.montantN1 ) * 100).toFixed(2).toLocaleString('fr')}%
                        </div>
                    ),
                }
            ],
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exercice_N_1',
            width:100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.montantN1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 2) || "N-2"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: () => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {0}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>N1  -  N-2</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width: 100,
                    render: (_, record) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(record.montantN1 || 0).toLocaleString('fr') || 0}
                        </div>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    key:'taux',
                    width: 70,
                    render: (_, ) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(0).toLocaleString('fr') || 0}%
                        </div>
                    ),
                }
            ],
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 50,
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => activeTab === 0 ? handleDeleteActif(index) : activeTab === 1 ? handleDeletePassif(index) : handleDeleteResultat(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    return(
        <div className="py-2">
            {<div className="d-flex justify-content-end align-items-end mb-10">
                <Tooltip title="Enregistrer">
                    <Button
                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                        size={"large"}
                        // loading={loading}
                        style={{
                            backgroundColor: appTheme.colors.secondary,
                            color: "white"
                        }}
                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                        onClick={handleSubmit}
                    >
                        {!loading &&
                            <span className='indicator-label text-white'>Enregistrer</span>}
                        {loading && (
                            <span className='indicator-progress fs-9 text-white'
                              style={{display: 'block'}}>
                              Patientez svp...
                              <span
                                  className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </Button>
                </Tooltip>
            </div>}
            <div className="card-header">
                <div className="card-title">
                    <Title style={{
                        display: "block",
                        fontWeight: '600',
                        color: appTheme.colors.primary,
                        // textAlign: "center"
                    }} level={4}>{"EC4.3 - Données chiffrées"}</Title>
                </div>
            </div>
            <div className="flex-lg-row-fluid mx-lg-10">
                <div className="d-flex justify-content-between align-center">
                    <div className="d-flex flex-column align-center">
                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-5">
                            {["Bilan Actifs", "Bilan Passifs", "Compte Résultats"].map((item, index) => (
                                <li className="nav-item" key={index}>
                                    <Tooltip title={item}>
                                        <button
                                            className={`nav-link text-active-primary pb-4 ${activeTab === index ? 'active' : ''}`}
                                            onClick={() => handleTabChange(index)}
                                        >
                                                <span className="fs-4 fw-bolder">
                                                    {item}
                                                </span>
                                        </button>
                                    </Tooltip>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="tab-content" id="myTabContent">
                {["Bilan Actifs", "Bilan Passifs", "Compte Résultats"].map((item, index) => (
                    <div
                        key={index}
                        className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                        id={`tab-${index}`}
                        role="tabpanel"
                    >
                        <div className="py-4">
                            <div className="table-responsive">
                                <Table
                                    rowClassName={(record,) =>
                                        `cursor-pointer h-3px p-0 m-0 lh-0.5
                                        ${(activeTab === 0 ? totalActifsValues : activeTab === 1 ? totalPassifsValues : totalResultatsValues).includes(record.ref) ? "bg-primary" : ""} 
                                        ${record.ref.includes(activeTab === 0 ? "DZ" : activeTab === 1 ? "BZ" : "XI") ? "bg-success text-white" : ""}`
                                    }
                                    columns={columns}
                                    rowKey={(record, index) => index}
                                    dataSource={activeTab === 0 ? bilanActif : activeTab === 1 ? bilanPassif : compteResult}
                                    pagination={tableParams.pagination}
                                    onChange={handleTableChange}
                                    loading={chargement || loading}
                                    rowSelection
                                    size={"small"}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export {DonneesChiffrees}