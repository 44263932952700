import React from "react";
import FormLabel from "../../../../../common/Form/FormLabel";
import {Input} from "antd";

const ObservationGenerale = ({observation, setObservation}) => {

    return (
        <div className="d-flex flex-column my-7">
            <FormLabel required label="Observatio générale : (à préciser)"/>
            <Input.TextArea
                size="large"
                placeholder="Saisissez l'observation"
                value={observation}
                // bordered={false}
                onChange={(e) =>{
                    setObservation(e.target.value);
                }}
                style={{width: "100%"}}
                showCount
                maxLength={5000}
                autoSize={{
                    minRows: 8,
                    maxRows: 12,
                }}
            />
        </div>
    )
}

export {ObservationGenerale}