import styled from "styled-components";

export const StepTitle = styled.h1`
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
  padding: 10px 0px;
`;
export const StepFooter = styled.div``;
export const StepBody = styled.div`
  min-height: 100px;
  padding: 15px 25px;
  background: #fff;
  box-shadow: 0px 0px 25px #00000020;
  border-radius: 10px;
  margin: 20px 0px;

  @media screen and (max-width: 720px) {
    margin: 50px 0px;
    padding: 0rem;
  }
`;
export const StepHeader = styled.div``;
