import {Avatar, Image} from "antd";
import React from "react";
import { TopBarContainer } from "./header.components";
import LogoEgouexpert from "../../assets/images/logo_egouexpert.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "styled-components";
import useUser from "../../hooks/useAuth";
import { appTheme } from "../../config/theme";

const HeaderUser = () => {
    const theme = useTheme();
    const { user } = useUser();
    return (
        <TopBarContainer>
            <div
                style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            >
                <Image preview={false} src={LogoEgouexpert} height={50} />
                <div
                    style={{
                        marginTop: 22,
                        marginLeft: 10,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <p
                        style={{
                            fontFamily: "oswald",
                            fontSize: 20,
                            fontWeight: "bold",
                            marginBottom: "0px",
                        }}
                    >
                        {user.nom_cabinet}
                    </p>
                    <p>{user.nom_fr_fr}</p>
                </div>
            </div>

            <div style={{backgroundColor: appTheme.colors.secondary, borderRadius: '10px'}} className="d-flex align-items-center ms-1" id="kt_header_user_menu_toggle">
                <div className="btn btn-flex align-items-center bg-hover-white bg-hover-opacity-10 py-2 px-2 px-md-3" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">

                        <div
                            style={{
                                marginTop: '5px',
                                marginRight: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                                textAlign: 'right'
                            }}
                        >
                            <p style={{
                                fontFamily: 'Oswald',
                                fontSize: '18px',
                                fontWeight: '700',
                                marginBottom: '0px',
                                color: '#fff'
                            }}>
                                {user.nom_user} {user.prenom_user}</p>
                            <p style={{
                                fontFamily: 'Poppins', fontWeight: 'bold', fontSize: '11px'
                            }}>{user.libelleProfil}</p>

                        </div>
                        <div className="symbol symbol-30px symbol-md-40px">
                            <Avatar
                                size={48}
                                shape="square"
                                style={{ backgroundColor: theme.colors.secondary }}
                                icon={<FontAwesomeIcon icon={faUser} color={theme.colors.primary} />}
                            />
                        </div>

                    </div>
            </div>
        </TopBarContainer>
    );
};

export default HeaderUser;
