import axios from 'axios'
import { AJOUTER_CIVILITE, LISTE_CIVILITE, MODIFIER_CIVILITE, SUPPRIMER_CIVILITE } from '../../api/apiRoutes'
import { MODIFIER_ETAT_CIVILITE } from '../../api/apiRoutes';
import {setAuthorizationHeader} from "../../api/config";

export const addCivilite = async (data) => {
    setAuthorizationHeader()
    return axios.post(AJOUTER_CIVILITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateCivilite = async (data) => {
    setAuthorizationHeader()
    return axios.post(MODIFIER_CIVILITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeCivilite = async () => {
    setAuthorizationHeader()
    return axios.get(LISTE_CIVILITE).then(res => {
        if (res.status === 200) {
            return res.data.result
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteCivilite = async (data) => {
    setAuthorizationHeader()
    return axios.post(SUPPRIMER_CIVILITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatCivilite = async (data) => {
    setAuthorizationHeader()
    return axios.post(MODIFIER_ETAT_CIVILITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}