import React from "react";
import {Table as TableChackra, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import TableTitle from "../../../../../common/TableTitle";
import {Input, Space, Tag} from "antd";

const tableHeaders = [
    {title: "Incidence (effet)", width: 300, rowSpan: 2, colSpan: 1},
    {title: "Assertions", width: 100, rowSpan: 2, colSpan: 1},
    {title: "Postes", width: 100, rowSpan: 2, colSpan: 1},
    {title: "Risque", width: 100, rowSpan: 2, colSpan: 1},
    {title: "Procédures", width: 250, rowSpan: 2, colSpan: 1},
    {title: "Descriptions/Résultats", width: 400, rowSpan: 1, colSpan: 2},
    {title: "Risque résiduel", width: 100, rowSpan: 2, colSpan: 1},
    {title: "Recommandation", width: 300, rowSpan: 2, colSpan: 1},
    {title: "Conclusion", width: 300, rowSpan: 2, colSpan: 1},
]

const postesStat = [
    { key: "EE3.1", title: "Immobilisations incorporelles" },
    { key: "EE3.2", title: "Immobilisations corporelles" },
    { key: "EE3.3", title: "Immobilisations financières" },
    { key: "EE3.4", title: "Stocks" },
    { key: "EE3.5", title: "Ventes-clients" },
    { key: "EE3.6", title: "Autres créance et dettes" },
    { key: "EE3.7", title: "Trésorerie" },
    { key: "EE3.8", title: "Capitaux propres" },
    { key: "EE3.9", title: "Provisions risques" },
    { key: "EE3.10", title: "Emprunts et assimilés" },
    { key: "EE3.11", title: "Achats fournisseurs" },
    { key: "EE3.12", title: "Impôts et taxes" },
    { key: "EE3.13", title: "Paie personnel" },
];

const tableStyle = {
    borderCollapse: 'collapse',
    width: '100%',
};

const thStyle = {
    border: '1px solid #000000',
    fontWeight: "bold",
    textAlign: 'center',
    padding: '5px',
};

const tdStyle = {
    border: '1px solid #000000',
    textAlign: 'center',
    padding: '2px',
};

const RisqueControleSynthese = ({risqueData}) => {
    return (
        <div className="mt-5 mb-15">
            <div className="table-responsive w-100">
                <table style={{...tableStyle, marginBottom: "5%"}} className=" ">
                    <TableChackra variant="simple">
                        <Thead>
                            <Tr>
                                {tableHeaders.map((item, index) => (
                                    <Th
                                        key={index}
                                        width={item.width || 200}
                                        colSpan={item.colSpan}
                                        rowSpan={item.rowSpan}
                                        style={{textAlign: "center"}}
                                    >
                                        <TableTitle>{item.title}</TableTitle>
                                    </Th>
                                ))}
                            </Tr>
                            <Tr>
                                <Th width={200}>
                                    <TableTitle>Description PC</TableTitle>
                                </Th>
                                <Th width={200}>
                                    <TableTitle>Constatations</TableTitle>
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(risqueData)
                                ? (risqueData ?? []).map((risque, risqueIndex) => (
                                    risque.procedures.map((procedure, eventIndex) => {
                                        const incidenceLibelle =  risque.incidence || '';
                                        const assertions = risque.assertions || [];
                                        const postes = risque.postes || [];
                                        const niveauLibelle = risque.niveauLibelle || "";
                                        const procedureLibelle = procedure.procedure || "";
                                        const questions = (risque.questions ?? []).filter(question => question.procedure_id === procedure.id) || [];
                                        const niveauRisqueResiduel = risque.niveauResiduel || "";
                                        const recommandation = risque.recommandation || "";
                                        const conclusion = risque.conclusion || "";

                                        return (
                                            <>
                                                <Tr key={`${risqueIndex}-${eventIndex}`}>
                                                    {eventIndex === 0 && (
                                                        <Td width={250} rowSpan={(risque.procedures ?? []).length}>
                                                            {/*<Input.TextArea*/}
                                                            {/*    style={{fontSize: "12px", fontWeight: "700", textTransform: "uppercase", width: "100%"}}*/}
                                                            {/*    size="large"*/}
                                                            {/*    bordered={false}*/}
                                                            {/*    value={incidenceLibelle}*/}
                                                            {/*    maxLength={2000}*/}
                                                            {/*    autoSize={{*/}
                                                            {/*        minRows: 1,*/}
                                                            {/*        maxRows: 5,*/}
                                                            {/*    }}*/}
                                                            {/*/>*/}
                                                            <span className="fw-bolder">
                                                                {incidenceLibelle}
                                                            </span>
                                                        </Td>
                                                    )}
                                                    {eventIndex === 0 && (
                                                        <Td width={100} rowSpan={(risque.procedures ?? []).length}>
                                                            <Space>
                                                                {(assertions ?? [])?.map((assertion, index) => (
                                                                    <Tag key={index}>
                                                                        <span className='fw-bolder' >
                                                                            {assertion}
                                                                        </span>
                                                                    </Tag>
                                                                ))}
                                                            </Space>
                                                        </Td>
                                                    )}
                                                    {eventIndex === 0  && (
                                                        <Td width={100} rowSpan={(risque.procedures ?? []).length}>
                                                            <div className="d-flex flex-column">
                                                                {postes.map((poste, index) => (
                                                                    poste ? <div className="mb-2" key={index}>
                                                                            <Tag
                                                                                style={{fontSize: "8px", fontWeight: "700", textTransform: "uppercase"}}
                                                                            >
                                                                                {`${poste} - ${(postesStat ?? []).find( opt => opt.key === poste)?.title}`}
                                                                            </Tag>
                                                                        </div>
                                                                        :
                                                                        ""
                                                                ))}
                                                            </div>
                                                        </Td>
                                                    )}
                                                    {eventIndex === 0  && (
                                                        <Td width={100} rowSpan={(risque.procedures ?? []).length}>
                                                            <Tag color={risque.score < 6 ? "success" : risque.score <= 15 ? "warning" : "red"}>
                                                                <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                                                                    {niveauLibelle}
                                                                </span>
                                                            </Tag>
                                                        </Td>
                                                    )}
                                                    <Td width={250}>
                                                        <div className="text-start fs-6">
                                                            {/*<Input.TextArea*/}
                                                            {/*    style={{fontSize: "14px"}}*/}
                                                            {/*    size="large"*/}
                                                            {/*    bordered={false}*/}
                                                            {/*    value={procedureLibelle}*/}
                                                            {/*    maxLength={2000}*/}
                                                            {/*    autoSize={{*/}
                                                            {/*        minRows: 1,*/}
                                                            {/*        maxRows: 10,*/}
                                                            {/*    }}*/}
                                                            {/*/>*/}
                                                            <span>{procedureLibelle}</span>
                                                        </div>
                                                    </Td>
                                                    {eventIndex === 0  && (
                                                        <Td width={200} rowSpan={(risque.procedures ?? []).length}>
                                                            {questions.map((opt, indexOpt) => (
                                                                <div key={indexOpt} className='d-flex flex-column'>
                                                                   <span className='' style={{fontSize: '12px'}}>
                                                                       <strong style={{
                                                                           fontSize: '15px',
                                                                           fontWeight: "bold",
                                                                           marginRight: "2%"
                                                                       }}>.</strong>{opt.questionLibelle}
                                                                   </span>
                                                                </div>
                                                            ))}
                                                        </Td>
                                                    )}
                                                    {eventIndex === 0  && (
                                                        <Td width={200} rowSpan={(risque.procedures ?? []).length}>
                                                            {questions.map((opt, indexOpt) => (
                                                                <div key={indexOpt} className='d-flex flex-column'>
                                                                   <span className='' style={{fontSize: '12px'}}>
                                                                       <strong style={{
                                                                           fontSize: '15px',
                                                                           fontWeight: "bold",
                                                                           marginRight: "2%"
                                                                       }}>.</strong>{opt.reponse}
                                                                   </span>
                                                                </div>
                                                            ))}
                                                        </Td>
                                                    )}
                                                    {eventIndex === 0  && (
                                                        <Td width={100} rowSpan={(risque.procedures ?? []).length}>
                                                            <Tag>
                                                                <span style={{fontFamily: "Oswald, sans-serif"}}  className='fw-bolder' >
                                                                    {niveauRisqueResiduel}
                                                                </span>
                                                            </Tag>
                                                        </Td>
                                                    )}
                                                    {eventIndex === 0  && (
                                                        <Td width={300} rowSpan={(risque.procedures ?? []).length}>
                                                            {/*<Input.TextArea*/}
                                                            {/*    style={{fontSize: "13px"}}*/}
                                                            {/*    size="large"*/}
                                                            {/*    bordered={false}*/}
                                                            {/*    value={recommandation}*/}
                                                            {/*    maxLength={2000}*/}
                                                            {/*    autoSize={{*/}
                                                            {/*        minRows: 1,*/}
                                                            {/*        maxRows: 10,*/}
                                                            {/*    }}*/}
                                                            {/*/>*/}
                                                            <span>{recommandation}</span>
                                                        </Td>
                                                    )}
                                                    {eventIndex === 0  && (
                                                        <Td width={300} rowSpan={(risque.procedures ?? []).length}>
                                                            {/*<Input.TextArea*/}
                                                            {/*    style={{fontSize: "13px"}}*/}
                                                            {/*    size="large"*/}
                                                            {/*    bordered={false}*/}
                                                            {/*    value={conclusion}*/}
                                                            {/*    maxLength={2000}*/}
                                                            {/*    autoSize={{*/}
                                                            {/*        minRows: 1,*/}
                                                            {/*        maxRows: 10,*/}
                                                            {/*    }}*/}
                                                            {/*/>*/}
                                                            <span>{conclusion}</span>
                                                        </Td>
                                                    )}
                                                </Tr>
                                            </>
                                        );
                                    })))
                                : null}
                        </Tbody>
                    </TableChackra>
                </table>
            </div>
        </div>
    )
}

export default RisqueControleSynthese;