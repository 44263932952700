import React, { Fragment } from "react";
import { TopbarWrapper } from "./header.components";
import HeaderMenu from "./HeaderMenu";
import HeaderUser from "./HeaderUser";

const HeaderBar = () => {
    // const theme = useTheme();

    return (
        <Fragment>
            <TopbarWrapper>
                <HeaderUser />
                <HeaderMenu />
            </TopbarWrapper>
        </Fragment>
    );
};

export default HeaderBar;
