import axios from "axios";

export const setAuthorizationHeader = () => {
    const token = JSON.parse(localStorage.getItem('accessToken'));
    axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
    };
};

export const setFileAuthorizationHeader = () => {
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'POST, GET, PUT';
    axios.defaults.headers.common['Access-Control-Allow-Headers'] = 'Content-Type';
};