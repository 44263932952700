import React, { useEffect, useState } from "react";
import {
    ClockCircleOutlined,
    DownloadOutlined, FileTextFilled,
    UserOutlined
} from "@ant-design/icons";
import {DOWNLOAD_FILE_CLIENT} from "../../../../../../api/apiRoutes";
import {Button, Col, DatePicker, Divider, Input, Popover, Select, Table, Tooltip} from "antd";
import { getListeModeleLettreMission} from "../../../../../../data/file";
import moment from "moment";
import {cleanAndNormalizeName} from "../../../../../utilities/Utilities";
import {ModalTitle} from "../../../../../modals/modals.components";
import SearchRow from "../../../../../common/SearchRow";
import FormLabel from "../../../../../common/Form/FormLabel";
import {FormContainer, TagItem} from "../../../../../common/common.components";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUpload, faDownload, faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import ModalAlert from "../../../../../modals/Modal";
import TableTitle from "../../../../../common/TableTitle";
import {getIconForFileType} from "../../../../../layout/menu/CustomMenu";
import useUsers from "../../../../../../hooks/users/useUser";

const ListeModeleDocuments = ({
      client,
      // handleVisibility,
      // visible,
      data,
      mission,
      codeSommaire,
      }) => {
    const users = useUsers();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [filtersItems, setFiltersItems] = useState([]);

    const [paramsRecherche, setParamsRecherche] = useState({
        infoFile: "",
        user_id: "",
        date_debut: "",
        date_fin: "",
    });

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 5,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setFiles([]);
        }
    };

    useEffect(() => {
        if(client){
            fetchDocumentsClient(client.client_bog_id);
        }
    }, [client]);

    const fetchDocumentsClient = (client_id) => {
        setLoading(true);
        getListeModeleLettreMission(client_id)
            .then((res) => {
                setFiles(res.result);
                setFiltersItems(res.result)
                setLoading(false);
            });
    };

    const downloadDocumentsClient = (client_bog, fichier) => {
        setLoading(true)

        const clientBogName = cleanAndNormalizeName(client_bog);

        window.open(`${DOWNLOAD_FILE_CLIENT}/${clientBogName}/MODELES/LETTRE-MISSION/${fichier}`, '_blank')
    }

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Reference</TableTitle>,
            dataIndex: 'reference',
            render: (_, record) => (
                <TagItem>{record.reference}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Document</TableTitle>,
            dataIndex: 'libelle',
            render: (_, record) => (
                <div className='d-flex align-items-center'>
                    <span className='svg-icon svg-icon-2x svg-icon-primary me-4'>
                      {getIconForFileType(`${record.libelle}.${record.extension || ""}`)}
                    </span>
                    <div style={{
                        fontFamily: "Oswald, sans-serif",
                        fontSize: "12px", fontWeight: '700'
                    }}>
                        {record.libelle}
                    </div>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Taille (KO)</TableTitle>,
            dataIndex: 'size',
            render: (_, record) => (
                <TagItem>{(record.size / 1024).toFixed(2)}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Dernière modif.</TableTitle>,
            dataIndex: 'modif',
            render: (_, record) => (
                <div className='d-flex flex-column align-center'>
                    <span className='chart-orange display-block f-size-12 pe-5' style={{fontWeight: 600, fontSize: '11px'}}>
                        <UserOutlined style={{fontSize: '15px'}} /> {record.Lea_User?.nom_user + ' ' + record.Lea_User?.prenom_user}
                    </span>
                    <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                        <ClockCircleOutlined style={{fontSize: '12px'}} />{' '}
                        {moment(record.updatedAt).format('DD/MM/YYYY à HH:mm')}
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Notes</TableTitle>,
            dataIndex: 'description',
            render: (_, record) => (
                <>
                    {record.description && <Popover
                        placement="top"
                        title="Notes"
                        content={
                            <>
                                <Divider/>
                                <span
                                    style={{
                                        fontFamily: "Oswald, sans-serif",
                                        fontSize: "13px",
                                    }}
                                >
                        {record.description}
                      </span>
                            </>
                        }
                    >
                        <Button
                            icon={<FontAwesomeIcon icon={faExclamationCircle}/>}
                            color={""}
                        />
                    </Popover>}
                </>
            ),
        },
        {
            title: (<span style={{fontSize: '11px'}}>Action</span>),
            dataIndex: 'action',
            width: 100,
            render: (_, record) => (
                <div>
                    <Tooltip color={appTheme.colors.primary} title="Téléchager le fichier">
                        <Button
                            shape="circle"
                            size={"middle"}
                            style={{backgroundColor: appTheme.colors.primary}}
                            onClick={() => downloadDocumentsClient(record.Bog_Client?.nom_cabinet, record.Lea_Client?.denomination, record.fichier)}
                            icon={<FontAwesomeIcon icon={faDownload} color={"white"} />}
                        />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = files.filter(file => {
                const referenceMatch = file.reference?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const libelleMatch = file.libelle?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const descriptionMatch = file.description?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const userMatch = paramsRecherche.user_id === "" || file.Lea_User?.id === paramsRecherche.user_id;
                const createdAtMatch = (paramsRecherche.date_debut === "" && paramsRecherche.date_fin === "") || moment(file.createdAt).isBetween(
                    moment(paramsRecherche.date_debut),
                    moment(paramsRecherche.date_fin)
                );

                return (referenceMatch || descriptionMatch || libelleMatch) && userMatch && createdAtMatch;
            });

            setFiltersItems(filteredClients);
            setLoading(false);
        }, 2000);
    };


    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            infoFile: "",
            user_id: "",
            date_debut: "",
            date_fin: "",
        });
        setFiltersItems(files)
        setLoading(false);
    };

    return (
        <>
            <SearchRow
                handleSearch={handleSearch}
                handleReset={handleReset}
                loading={loading}
            >
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Fichier" />
                    <Input
                        style={{ width: "100%" }}
                        size="middle"
                        placeholder="Reference ou libellé ou description"
                        // value={paramsRecherche.infoClient}
                        onChange={(e) =>
                            setParamsRecherche({
                                ...paramsRecherche,
                                infosClient: e.target.value,
                            })
                        }
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Utilisateurs" />
                    <Select
                        placeholder="Utilisateur"
                        size="middle"
                        style={{ width: "100%" }}
                        onSelect={(value) =>
                            setParamsRecherche({ ...paramsRecherche, user_id: value })
                        }
                        options={users && users.users.map((user) => ({
                            value: user.id,
                            label: `${user.initial || ""} - ${user.nom_user.toUpperCase()} ${user.prenom_user ? user.prenom_user.toUpperCase() : ""}`
                        }))}
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Date debut" />
                    <DatePicker
                        size="middle"
                        placeholder="Date début de la création"
                        style={{ width: "100%" }}
                        allowClear
                        onChange={(date, dateString) =>
                            date !== null
                                ? setParamsRecherche({
                                    ...paramsRecherche,
                                    date_debut: date.format("YYYY-MM-DD"),
                                })
                                : setParamsRecherche({ ...paramsRecherche, date_debut: "" })
                        }
                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Date fin" />
                    <DatePicker
                        size="middle"
                        placeholder="Date fin  de la création"
                        style={{ width: "100%" }}
                        allowClear
                        onChange={(date, dateString) =>
                            date !== null
                                ? setParamsRecherche({
                                    ...paramsRecherche,
                                    date_fin: date.format("YYYY-MM-DD"),
                                })
                                : setParamsRecherche({ ...paramsRecherche, date_fin: "" })
                        }
                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                    />
                </Col>
            </SearchRow>
            <FormContainer>
                <div className="w-100">
                    {/*{mission.isCloture !== 1 && <div className="d-flex justify-content-end align-center my-2 me-5">*/}
                    {/*    <Tooltip color={appTheme.colors.primary} title="Importer un fichier pour l'exercice">*/}
                    {/*        <Button*/}
                    {/*            size={"middle"}*/}
                    {/*            style={{backgroundColor: appTheme.colors.primary}}*/}
                    {/*            onClick={() => handleModalOpen(1)}*/}
                    {/*            icon={<FontAwesomeIcon icon={faCloudUpload} color={"white"}/>}*/}
                    {/*        >*/}
                    {/*            Importer*/}
                    {/*        </Button>*/}
                    {/*    </Tooltip>*/}
                    {/*</div>}*/}
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                rowKey={(record) => record.id}
                                dataSource={filtersItems}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                                rowSelection
                                size={"small"}
                            />
                        </div>
                    </div>
                </div>
            </FormContainer>
            {/*<ModalAlert*/}
            {/*    closeModal={handleVisibility}*/}
            {/*    show={visible}*/}
            {/*    title={<ModalTitle> Liste des modèles document {codeSommaire ? `de ${codeSommaire || ""}` : ""}</ModalTitle>}*/}
            {/*    footer={[]}*/}
            {/*    modalStyles={{ top: 15 }}*/}
            {/*    width={1200}*/}
            {/*>*/}
            {/*    */}
            {/*</ModalAlert>*/}
        </>
    );
}

export {ListeModeleDocuments}