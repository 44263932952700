import React from "react";

const EtatFinanciers = ({data, isActif, isPassif, isResult}) => {
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thStyle = {
        border: '1px solid #000000',
        textTransform: 'uppercase',
        textAlign: 'left',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '5px',
        lineHeight: "0.7"
    };

    const spanStyle = {
        fontSize: '10px',
    };

    const spanValueStyle = {
        fontSize: '12px',
        textAlign: 'end',
    };

    const tabHeaderActifs = [
        {title: "Réf", size: "50px"},
        {title: "Libellé", size: "350px"},
        {title: "Notes", size: "50px"},
        {title: "Brut", size: "100px"},
        {title: "Amor et Déprec", size: "100px"},
        {title: "Exercice N Net", size: "100px"},
        {title: "Exercice N-1 Net", size: "100px"},
    ]

    const tabHeaderPassifs = [
        {title: "Réf", size: "50px"},
        {title: "Libellé", size: "350px"},
        {title: "Notes", size: "50px"},
        {title: "Exercice N Net", size: "150px"},
        {title: "Exercice N-1 Net", size: "150px"},
    ]

    const tabHeaderResults = [
        {title: "Réf", size: "50px"},
        {title: "Libellé", size: "350px"},
        {title: "", size: "50px"},
        {title: "Notes", size: "50px"},
        {title: "Exercice N Net", size: "100px"},
        {title: "Exercice N-1 Net", size: "100px"},
    ]

    const totalActifs = ["AD", "AI", "AQ", "AZ", "BG", "BT", "BZ"];
    const totalPassifs = ["CP", "DD", "DF", "DP", "DT", "DZ"];
    const totalResults = ["XA", "XB", "XC", "XD", "XE", "XF", "XH", "XI"];

  return(
      <>
          <div>
              <div className="" style={{display: "flex", flexDirection: "column", marginBottom: "" }}>
                  {isActif && <div style={{marginBottom: "30px"}}>
                      <h1>BILAN ACTIFS</h1>
                      <table style={tableStyle}>
                          <thead>
                            <tr style={thStyle}>
                                {(tabHeaderActifs ?? []).map((item, index) => (
                                    <th style={{...thStyle, width: item.size}} key={index}>
                                        <span style={spanStyle}>
                                          {item.title}
                                      </span>
                                    </th>
                                ))}
                            </tr>
                          </thead>
                          <tbody className="">
                          {(data ?? []).map((item, index) => (
                              <tr style={{...tdStyle, backgroundColor: totalActifs.includes(item.ref) ? "grey" : ""}} key={index}>
                                  <td style={tdStyle}>
                                      <span style={spanStyle}>
                                          {item.ref}
                                      </span>
                                  </td>
                                  <td style={tdStyle}>
                                      <span style={spanStyle}>
                                         {item.libelle}
                                      </span>
                                  </td>
                                  <td style={tdStyle}>
                                       <span style={spanStyle}>
                                        {item.note || ""}
                                      </span>
                                  </td>
                                  <td style={tdStyle}>
                                       <div style={spanValueStyle}>
                                        {item.brut?.toLocaleString('fr')}
                                      </div>
                                  </td>
                                  <td style={tdStyle}>
                                      <div style={spanValueStyle}>
                                        {item.amortissement?.toLocaleString('fr')}
                                      </div>
                                  </td>
                                  <td style={tdStyle}>
                                      <div style={spanValueStyle}>
                                          {(item.montant ?? 0).toLocaleString('fr')}
                                      </div>
                                  </td>
                                  <td style={tdStyle}>
                                      <div style={spanValueStyle}>
                                          {(item.montantN1 ?? 0).toLocaleString('fr')}
                                      </div>
                                  </td>
                              </tr>
                          ))}
                          </tbody>
                      </table>
                  </div>}
                  {isPassif && <div style={{marginBottom: "30px"}}>
                      <h1>BILAN PASSIFS</h1>
                      <table style={tableStyle}>
                          <thead>
                            <tr style={thStyle}>
                                {(tabHeaderPassifs ?? []).map((item, index) => (
                                    <th style={{...thStyle, width: item.size}} key={index}>
                                        <span style={spanStyle}>
                                          {item.title}
                                      </span>
                                    </th>
                                ))}
                            </tr>
                          </thead>
                          <tbody className="">
                          {(data ?? []).map((item, index) => (
                              <tr style={{...tdStyle, backgroundColor: totalPassifs.includes(item.ref) ? "grey" : ""}} key={index}>
                                  <td style={tdStyle}>
                                      <span style={spanStyle}>
                                          {item.ref}
                                      </span>
                                  </td>
                                  <td style={tdStyle}>
                                      <span style={spanStyle}>
                                         {item.libelle}
                                      </span>
                                  </td>
                                  <td style={tdStyle}>
                                       <span style={spanStyle}>
                                        {item.note || ""}
                                      </span>
                                  </td>
                                  <td style={tdStyle}>
                                      <div style={spanValueStyle}>
                                          {(item.montant ?? 0).toLocaleString('fr')}
                                      </div>
                                  </td>
                                  <td style={tdStyle}>
                                      <div style={spanValueStyle}>
                                          {(item.montantN1 ?? 0).toLocaleString('fr')}
                                      </div>
                                  </td>
                              </tr>
                          ))}
                          </tbody>
                      </table>
                  </div>}
                  {isResult && <div style={{marginBottom: "30px"}}>
                      <h1>COMPTE DE RESULTAT</h1>
                      <table style={tableStyle}>
                          <thead>
                            <tr style={thStyle}>
                                {(tabHeaderResults ?? []).map((item, index) => (
                                    <th style={{...thStyle, width: item.size}} key={index}>
                                        <span style={spanStyle}>
                                          {item.title}
                                      </span>
                                    </th>
                                ))}
                            </tr>
                          </thead>
                          <tbody className="">
                          {(data ?? []).map((item, index) => (
                              <tr style={{...tdStyle, backgroundColor: totalResults.includes(item.ref) ? "grey" : ""}} key={index}>
                                  <td style={tdStyle}>
                                      <span style={spanStyle}>
                                          {item.ref}
                                      </span>
                                  </td>
                                  <td style={tdStyle}>
                                      <span style={spanStyle}>
                                         {item.libelle}
                                      </span>
                                  </td>
                                  <td style={tdStyle}>
                                       <div style={{...spanStyle, textAlign: "center", fontSize: "12px"}}>
                                        {item.signe || ""}
                                      </div>
                                  </td>
                                  <td style={tdStyle}>
                                      <div style={{...spanStyle, textAlign: "center", fontSize: "12px"}}>
                                        {item.note || ""}
                                      </div>
                                  </td>
                                  <td style={tdStyle}>
                                      <div style={spanValueStyle}>
                                        {(item.montant ?? 0).toLocaleString('fr')}
                                      </div>
                                  </td>
                                  <td style={tdStyle}>
                                    <div style={spanValueStyle}>
                                        {(item.montantN1 ?? 0).toLocaleString('fr')}
                                      </div>
                                  </td>
                              </tr>
                          ))}
                          </tbody>
                      </table>
                  </div>}
              </div>
          </div>
      </>
  )
}

export {EtatFinanciers}