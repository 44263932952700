import React, {useEffect, useState} from "react";
import {getStrategieGlobaleMission} from "../../../../../data/stategieGlobale";
import {areArraysEqual} from "../../../../utilities/Utilities";
import {Table} from "antd";
import TableTitle from "../../../../common/TableTitle";
import DynamicTable from "../../../../modals/DynamicTable";

const TraitementSyntheseAjustement = ({
      mission,
      travauxData,
      chargement
    }) => {
    const [loading, setLoading] = useState(false);
    const [descriptionsData, setDescriptionsData] = useState([]);
    const [descriptionsItems, setDescriptionsItems] = useState([]);
    const [totalsData, setTotalsData] = useState([
        {
            id: 1,
            libelle: "Total des anomalie identifiées lors de la mission d'audit",
            resultatProduit: 0,
            actif: 0,
            passif: 0,
            capitaux: 0,
        },
        {
            id: 2,
            libelle: "Anomalies corrigées par la Direction",
            resultatProduit: 0,
            actif: 0,
            passif: 0,
            capitaux: 0,
        },
        {
            id: 3,
            libelle: "Total des anomalies non corrigées",
            resultatProduit: 0,
            actif: 0,
            passif: 0,
            capitaux: 0,
        },
    ]);

    useEffect(() => {
        if (travauxData) {
            const updatedList = travauxData.map((item) => ({
                id: item.id,
                cycle: item.cycle,
                resultatProduit: item.resultatProduit,
                actif: item.actif,
                passif: item.passif,
                capitaux: item.capitaux,
                details: item.details,
            }));
            setDescriptionsData(updatedList);
            setDescriptionsItems([0])
        }
    }, [travauxData]);

    const calculateTotal = (filteredDescriptions, id) => {
        const updatedList = totalsData.map((item) =>
            item.id === id
                ? {
                    ...item,
                    resultatProduit: filteredDescriptions.reduce((acc, opt) => acc + (opt.resultatProduit || 0), 0),
                    actif: filteredDescriptions.reduce((acc, opt) => acc + (opt.actif || 0), 0),
                    passif: filteredDescriptions.reduce((acc, opt) => acc + (opt.passif || 0), 0),
                    capitaux: filteredDescriptions.reduce((acc, opt) => acc + (opt.capitaux || 0), 0),
                }
                : item
        );

        setTotalsData(updatedList);
    };

    console.log("descriptionsItems", descriptionsItems)
    console.log("totalsData", totalsData)

    useEffect(() => {
        const filteredDescriptionsYes = descriptionsItems.filter((item) => item.action === 'OUI');
        if (filteredDescriptionsYes.length > 0) {
            calculateTotal(filteredDescriptionsYes, 2);
        }

        const filteredDescriptionsNo = descriptionsItems.filter((item) => item.action === 'NON');
        if (filteredDescriptionsNo.length > 0) {
            calculateTotal(filteredDescriptionsNo, 3);
        }

        const updatedTotal = totalsData.map((item) =>
            item.id === 1
                ? {
                    ...item,
                    resultatProduit: descriptionsItems.reduce((acc, opt) => acc + (opt.resultatProduit || 0), 0),
                    actif: descriptionsItems.reduce((acc, opt) => acc + (opt.actif || 0), 0),
                    passif: descriptionsItems.reduce((acc, opt) => acc + (opt.passif || 0), 0),
                    capitaux: descriptionsItems.reduce((acc, opt) => acc + (opt.capitaux || 0), 0),
                }
                : item
        );

        if (!areArraysEqual(updatedTotal, totalsData)) {
            setTotalsData(updatedTotal);
        }
    }, [descriptionsData, setTotalsData, totalsData]);

    const columnTotalData = [
        {
            title: () => <TableTitle></TableTitle>,
            dataIndex: 'description',
            width: 350,
            render: (_, record) => (
                <span className='' style={{fontSize: '14px'}}>
                  {record.libelle}
                </span>
            ),
        },

        {
            title: () => <TableTitle>Résultats produits</TableTitle>,
            dataIndex: 'resultatProduit',
            width: 150,
            render: (_, record, ) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.resultatProduit.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Actifs</TableTitle>,
            dataIndex: 'actif',
            width: 150,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.actif.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Passifs</TableTitle>,
            dataIndex: 'passif',
            width: 150,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.passif.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Capitaux propres</TableTitle>,
            dataIndex: 'capitaux',
            width: 150,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.capitaux.toLocaleString('fr')}
                </div>
            ),
        },
    ];

    const columnDescriptionData = [
        {
            title: () => <TableTitle>Sommaires</TableTitle>,
            width: 250,
            key: 'description',
            dataIndex: 'description',
            render: (_, record) => (
                <span className='fw-bolder' style={{fontSize: '14px'}}>
                  {record.cycle}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Ajustement (+) ou (-) Résultat Produits et Charges</TableTitle>,
            key: 'resultatProduit',
            dataIndex: 'resultatProduit',
            width: 150,
            render: (_, record) => (
                <div className='text-end pe-0' style={{fontSize: '14px'}}>
                    {record.resultatProduit?.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Bilan</TableTitle>,
            key: 'bilan',
            dataIndex: 'bilan',
            children: [
                {
                    title: () => <TableTitle>Actif</TableTitle>,
                    dataIndex: 'actif',
                    width: 150,
                    render: (_, record) => (
                        <div className='text-end pe-0' style={{fontSize: '14px'}}>
                            {record.actif?.toLocaleString('fr')}
                        </div>
                    ),
                },
                {
                    title: () => <TableTitle>Passifs</TableTitle>,
                    dataIndex: 'passif',
                    width: 150,
                    render: (_, record) => (
                        <div className='text-end pe-0' style={{fontSize: '14px'}}>
                            {record.passif?.toLocaleString('fr')}
                        </div>
                    ),
                },
                {
                    title: () => <TableTitle>Capitaux Propres</TableTitle>,
                    dataIndex: 'capitaux',
                    width: 150,
                    render: (_, record) => (
                        <div className='text-end pe-0' style={{fontSize: '14px'}}>
                            {record.capitaux?.toLocaleString('fr')}
                        </div>
                    ),
                },
            ],
        },

    ];

    const handleRowClick = (record) => {
        return new Promise((resolve) => {
            setLoading(true);
            setTimeout(() => {
                setDescriptionsItems(record?.details ||[]);
                setLoading(false);
                resolve();
            }, 1000);
        });
    };

    return(
        <div className=''>
            <div className="table-responsive mb-10">
                <DynamicTable
                    columns={columnDescriptionData}
                    dataSource={descriptionsData ?? []}
                    loading={chargement || loading}
                    onRowClickHandler={handleRowClick}
                />
            </div>
            <div className='col-lg-10 mt-15'>
                <div className="table-responsive mb-5">
                    <Table
                        rowClassName={() =>
                            'cursor-pointer h-3px p-0 m-0 lh-0.5'
                        }
                        columns={columnTotalData}
                        // rowKey={(record, index) => index}
                        dataSource={totalsData ?? []}
                        pagination={false}
                        loading={chargement || loading}
                        size="small"
                    />
                </div>
            </div>
        </div>
    )
}

export default TraitementSyntheseAjustement