import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {useNavigate} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import {UserDetailSchema} from "../../../components/utilities/Utilities";
import {ModifierUser} from "../../../data/user";
import {getListePays} from "../../../data/params/pays";
import {getListeCivilite} from "../../../data/params/civilite";
import swal from "sweetalert";

const UserEdit = () => {
    const [civilites, setCivilites] = useState([])
    const [pays, setPays] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    let location = useLocation()

    const user = location.state.record

    useEffect(() => {
        fetchPays()
        fetchCivilites()
    }, [ setPays, setCivilites]);



    const fetchPays = () => {
        setLoading(true);
        getListePays()
            .then((res) => {
                setPays(res);
                setLoading(false);
            });
    };

    const fetchCivilites = () => {
        setLoading(true);
        getListeCivilite()
            .then((res) => {
                setCivilites(res);
                setLoading(false);
            });
    };

    const initialValues = {
        initial: user.initial,
        user_id: user.id,
        avatar: user.avatar || '',
        civilite_id: user.civilite_id || null,
        nom_user: user.nom_user || '',
        prenom_user: user.prenom_user || '',
        email_user: user.email_user || '',
        login_user: user.login_user || '',
        telephone1_user: user.telephone1_user || '',
        telephone2_user: user.telephone2_user || '',
        habitation_user: user.habitation_user || '',
        pays_id: user.pays_id || null,
    }

    const formik = useFormik({
        initialValues,
        validationSchema: UserDetailSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setSubmitting(true)
            try {
                const {message, status} = await ModifierUser(values)
                if (status !== 'success') {
                    setStatus(message)
                }else{
                    navigate('/utilisateurs/liste')
                    await swal(`Opération effectuée avec succès`, '', 'success')
                }
            } catch (ex) {
                console.error(ex)
            }
        },
    })

    return (
        <div className='card mb-5 mb-xl-10 w-100'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Détails de l'utilisateur</h3>
                </div>
            </div>

            {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            ) : (
                ''
            )}

            <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                    <div className='card-body border-top p-9'>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Civilité</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <select
                                    {...formik.getFieldProps('civilite_id')}
                                    className={clsx(
                                        'form-select form-select-solid form-select-lg fw-bold',
                                        {'is-invalid': formik.touched.civilite_id && formik.errors.civilite_id},
                                        {
                                            'is-valid': formik.touched.civilite_id && !formik.errors.civilite_id,
                                        }
                                    )}
                                    autoComplete='off'
                                    disabled={formik.isSubmitting}
                                >
                                    <option value=''>Selectionner...</option>
                                    {civilites.map((civilie) => (
                                        <option value={civilie.id} key={civilie.id}>
                                            {civilie.libelleCivilite}
                                        </option>
                                    ))}
                                </select>
                                {formik.touched.civilite_id && formik.errors.civilite_id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.civilite_id}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Initial</span>
                            </label>

                            <div className='col-lg-4 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Initial'
                                    {...formik.getFieldProps('initial')}
                                />
                                {formik.touched.initial && formik.errors.initial && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.initial}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                Nom et Prénoms
                            </label>

                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='Nom'
                                            {...formik.getFieldProps('nom_user')}
                                        />
                                        {formik.touched.nom_user && formik.errors.nom_user && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.nom_user}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Prenoms'
                                            {...formik.getFieldProps('prenom_user')}
                                        />
                                        {formik.touched.prenom_user && formik.errors.prenom_user && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.prenom_user}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                                Lieu d'habitation
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Lieu habitation'
                                    {...formik.getFieldProps('habitation_user')}
                                />
                                {formik.touched.habitation_user && formik.errors.habitation_user && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.habitation_user}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Pays</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <select
                                    className='form-select form-select-solid form-select-lg fw-bold'
                                    {...formik.getFieldProps('pays_id')}
                                    defaultValue={'110'}
                                >
                                    <option value=''>Selectionner...</option>
                                    {pays.map((pys) => (
                                        <option value={pys.id} key={pys.id}>
                                            {pys.nom_fr_fr}
                                        </option>
                                    ))}
                                </select>
                                {formik.touched.pays_id && formik.errors.pays_id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.pays_id}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Email</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    placeholder='Email'
                                    {...formik.getFieldProps('email_user')}
                                    className='form-control form-control-lg form-control-solid'
                                    type='email'
                                    name='email_user'
                                    autoComplete='off'
                                />
                                {formik.touched.email_user && formik.errors.email_user && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.email_user}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Login</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Login'
                                    {...formik.getFieldProps('login_user')}
                                />
                                {formik.touched.login_user && formik.errors.login_user && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.login_user}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Téléphone 1</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Téléphone 1'
                                    {...formik.getFieldProps('telephone1_user')}
                                />
                                {formik.touched.telephone1_user && formik.errors.telephone1_user && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.telephone1_user}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span>Téléphone 2</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Téléphone 2'
                                    {...formik.getFieldProps('telephone2_user')}
                                />
                                {formik.touched.telephone2_user && formik.errors.telephone2_user && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.telephone2_user}</div>
                                    </div>
                                )}
                            </div>
                        </div>



                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                data-kt-users-modal-action='submit'
                                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
                            >
                                <span className='indicator-label'>Enregistrer</span>
                                {formik.isSubmitting && (
                                    <span className='indicator-progress'>
                                        Veillez patientez...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export {UserEdit}
