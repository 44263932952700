import axios from "axios";
import {setAuthorizationHeader} from "../../api/config";

const AJOUTER_RISQUE_EVALUATION = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/ajouter-risque-evaluation`
const MODIFIER_RISQUE_EVALUATION = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/modifier-risque-evaluation`
const SUPPRIMER_ALL_RISQUE_EVALUATION = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/supprimer-risque-evaluation`
const LISTE_RISQUE_EVALUATION_ACTIVITE = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-evaluation/activite`
const LISTE_RISQUE_EVALUATION_FRAUDE = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-evaluation/fraude`
const LISTE_RISQUE_EVALUATION_INFO = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-evaluation/info`
const LISTE_RISQUE_EVALUATION_BLANCHIMENT = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-evaluation/blanchiment`
const LISTE_RISQUE_EVALUATION_RESPECT = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-evaluation/respect`
const LISTE_RISQUE_EVALUATION_All = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-evaluation/liste`

export const ajouterRisqueEvaluation = async (data) => {
    setAuthorizationHeader()
    return axios.post(AJOUTER_RISQUE_EVALUATION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const modifierRisqueEvaluation = async (data) => {
    setAuthorizationHeader()
    return axios.post(MODIFIER_RISQUE_EVALUATION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const supprimerAllRisqueEvaluation = async (data) => {
    setAuthorizationHeader()
    return axios.post(SUPPRIMER_ALL_RISQUE_EVALUATION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueEvaluationActivite = async (exercice_id) => {
    setAuthorizationHeader()
    return axios.get(`${LISTE_RISQUE_EVALUATION_ACTIVITE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueEvaluationFraude = async (exercice_id) => {
    setAuthorizationHeader()
    return axios.get(`${LISTE_RISQUE_EVALUATION_FRAUDE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueEvaluationInformatique = async (exercice_id) => {
    setAuthorizationHeader()
    return axios.get(`${LISTE_RISQUE_EVALUATION_INFO}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueEvaluationBlanchiment = async (exercice_id) => {
    setAuthorizationHeader()
    return axios.get(`${LISTE_RISQUE_EVALUATION_BLANCHIMENT}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueEvaluationRespect = async (exercice_id) => {
    setAuthorizationHeader()
    return axios.get(`${LISTE_RISQUE_EVALUATION_RESPECT}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueEvaluationAll = async (exercice_id) => {
    setAuthorizationHeader()
    return axios.get(`${LISTE_RISQUE_EVALUATION_All}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}