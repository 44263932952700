import { Button } from "antd";
import styled from "styled-components";

export const ButtonSecondary = styled(Button).attrs((props) => ({
    type: props.type || "primary",
}))`
  background-color: ${({ theme }) => theme.colors.secondary};
  border: none;
  color: #ffffff;
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const ButtonAlter = styled(Button).attrs((props) => ({
    type: props.type || "primary",
}))`
  background-color: ${({ theme }) => theme.colors.alter};
  border: none;
  color: #ffffff;
  &:hover {
    background-color: ${({ theme }) => theme.colors.alter};
  }
`;

export const ButtonSuccess = styled(Button).attrs((props) => ({
    type: props.type || "primary",
}))`
  background-color: ${({ theme }) => theme.colors.success};
  border: none;
  color: #ffffff;
  &:hover {
    background-color: ${({ theme }) => theme.colors.success};
  }
`;

export const ButtonDanger = styled(Button).attrs((props) => ({}))`
  background-color: ${({ theme }) => theme.colors.danger};
  border: none;
  color: #ffffff;

  &:hover {
    background-color: ${({ theme }) => theme.colors.danger};
    color: #ffffff;
  }
`;
