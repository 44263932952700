import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageTitle} from "../../components/layout/pageData/PageData";
import {ClientAdd} from "./client/ClientAdd";
import ClientList from "./client/ClientList";
import {ClientEdit} from "./client/ClientEdit";
import FileManagerExercice from "../exercices/exercice/FileManagerExercice";
import {AcceptationMission} from "../../components/client/modals/missionSteps/EB/AcceptationMission";
import {MaintienMission} from "../../components/client/modals/missionSteps/EB/MaintienMission";
import ClientExploitationFiche from "./client/ClientExploitationFiche";
import React from "react";
import MissionExploitationPage from "../exercices/exercice/MissionExploitationPage";

const ClientsPages = () => {

    const Breadcrumbs = [
        {
            title: 'Accueil',
            path: '/tableau-de-bord',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
        {
            title: 'Clients',
            path: '/clients/liste',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='liste'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des clients</PageTitle>
                            <ClientList  />
                        </>
                    }
                />
                <Route
                    path='ajout'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Enregistrer un nouveau client</PageTitle>
                            <ClientAdd/>
                        </>
                    }
                />
                <Route
                    path='edit/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Modifier un client</PageTitle>
                            <ClientEdit/>
                        </>
                    }
                />
                <Route
                    path='exercice/:year'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Détails du dossier</PageTitle>
                            <FileManagerExercice/>
                        </>
                    }
                />
                <Route
                    path='exploitation/fiche/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Fiche d'exploitation client</PageTitle>
                            <ClientExploitationFiche/>
                        </>
                    }
                />
                <Route
                    path='mission/exploitation/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Dossiers de la mission</PageTitle>
                            <MissionExploitationPage/>
                        </>
                    }
                />
                <Route index element={<Navigate to='/clients/liste' />} />
            </Route>
        </Routes>
    )
}

export default ClientsPages
