import React, {useEffect, useState} from "react";
import { Identification } from "../../Identification";
import swal from "sweetalert";
import {TraitementVerificationSA} from "./EG1/TraitementVerificationSA";
import {ObservationVerificationSA} from "./EG1/ObservationVerificationSA";
import {
    ajouterVerificationSpecifique,
    getListeVerificationSpecifique
} from "../../../../../data/verificationSpecifique";
import {succesMessage} from "../../../../../helpers/AssetsHelpers";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import {faIdCard, faSave, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {Button, Tooltip} from "antd";
import {appTheme} from "../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PrintVerification from "./PrintVerification";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const VerificationSA = ({
        mission,
        client,
        code,
        modalId,
        prev
    }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [codeSommaire] = useState(code || 'EG1.1');
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [isTaskSave, setIsTaskSave] = useState([]);

    const [title, setTitle] = useState('')
    const [travauxData, setTravauxData] = useState([]);
    const [datas, setDatas] = useState([]);
    const [conclusion, setConclusion] = useState('')
    const [observation, setObservation] = useState('')
    const [isAddMemoire, setIsAddMemoire] = useState(false)
    const [isSa, setIsSa] = useState(false)
    const [excelPrintData, setExcelPrintData] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if(modalId === 1){
            setIsSa(false);
            setTitle('EG1.1 - Contrôle de la régularité des réunions du Conseil d\'Administration');
        }
        else if(modalId === 2){
            setIsSa(false);
            setTitle('EG1.2 - Régularité des AGO');
        }
        else if(modalId === 3){
            setIsSa(false);
            setTitle('EG1.3 - Contrôle du respect du droit de communication');
        }
        else if(modalId === 4){
            setIsSa(false);
            setTitle('EG1.4 - Contrôle des conventions réglementées');
        }
        else if(modalId === 5){
            setIsSa(false);
            setTitle('EG1.5 - Contrôle des conventions interdites ');
        }
        else if(modalId === 6){
            setIsSa(false);
            setTitle('EG1.6 - Appréciation du rapport de Gestion');
        }
        else if(modalId === 7){
            setIsSa(false);
            setTitle('EG1.7 - Contrôle du registre des titres nominatifs ');
        }
        else if(modalId === 8){
            setIsSa(false);
            setTitle('EG1.8 - Contrôle des pactes d\'actionnaires');
        }else if(modalId === 9){
            setTitle('EG1.9 - Procédures d\'alerte');
        }
        else if(modalId === 10){
            setIsSa(false);
            setTitle('EG1.10 - Contrôle des cessions de biens des actionnaires à la société');
        }
        else if(modalId === 11){
            setTitle('EG2.1 - Régularité des AGO');
            setIsSa(true);
        }
        else if(modalId === 12){
            setIsSa(true);
            setTitle('EG2.2 - Contrôle du respect du droit de communication');
        }
        else if(modalId === 13){
            setIsSa(true);
            setTitle('EG2.4 - Contrôle des conventions interdites');
        }
        else if(modalId === 14){
            setIsSa(true);
            setTitle('EG2.5 - Appréciation du rapport de Gestion ');
        }
        else if(modalId === 15){
            setIsSa(true);
            setTitle('EG2.6 - Contrôle des pactes d\'associés');
        }
        else if(modalId === 16){
            setIsSa(true);
            setTitle('EG2.7 - Procédures d\'alerte');
        }
    }, [modalId])

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(missionSelected.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [codeSommaire, activeTab, missionSelected])

    useEffect(()=> {
        const fetchListVerification = (exercice_id, code) => {
            getListeVerificationSpecifique(exercice_id, code)
                .then((res) => {
                    if(res.result){
                        // const noteFetch = res.result.find((item) => item.codeSommaire === code)
                        if(res.result){
                            setObservation(res.result.observation)
                            setConclusion(res.result.conclusion)
                            setDatas(res.result?.Lea_VerificationSpecifiqueControles);
                        }
                        else{
                            setObservation('')
                            setConclusion('')
                            setDatas([]);
                        }
                    }
                })
        }

        if(missionSelected) {
            fetchListVerification(missionSelected.id, codeSommaire)
        }
    }, [missionSelected, codeSommaire])

    useEffect(() => {
        if((travauxData ?? []).length > 0){
            const data = travauxData.flatMap((item) =>
                item.questions.map((question) => ({
                    question_id: question.question_id,
                    groupe: question.groupe,
                    ref: question.ref,
                    libelle: question.libelle,
                    assertions: question.assertions,
                    reponse: question.reponse === '1' ? 'S/O': question.reponse === '2' ? 'OUI' : 'NON',
                    commentaire: question.commentaire,
                }))
            );
            setExcelPrintData(data)
        }
    }, [travauxData])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault()

        let travaux = travauxData.flatMap((item) =>
            item.questions.map((question) => ({
                question_id: question.question_id,
                groupe: question.groupe,
                ref: question.ref,
                libelle: question.libelle,
                assertions: question.assertions,
                reponse: question.reponse,
                commentaire: question.commentaire,
            }))
        );

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            travauxData: travaux,
            conclusion,
            isAddMemoire,
            isProvisoire,
            observation,
            codeSommaire,
            exercice_id: missionSelected.id,
            client_id: missionSelected.client_id
        }

        const {status, message} = await ajouterVerificationSpecifique(data)
        if (status === 'success') {
            setLoading(false)
            setAuth(false);
            await swal(succesMessage, '', 'success')
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "TR",
            title: "Traitement",
            icon: faTasks,
            content: (
                <>
                    <TraitementVerificationSA
                        mission={missionSelected}
                        client={client}
                        isProvisoire={isProvisoire}
                        setIsAddMemoire={setIsAddMemoire}
                        isAddMemoire={isAddMemoire}
                        setConclusion={setConclusion}
                        conclusion={conclusion}
                        selectItems={travauxData}
                        setSelectItems={setTravauxData}
                        datas={datas}
                        modalId={modalId}
                        codeSommaire={codeSommaire}
                        chargement={loading}
                        isSa={isSa}
                        setIsTaskSave={setIsTaskSave}
                    />
                </>
            ),
        },
        {
            code: "OG",
            title: `Observation générale - ${selectYear}`,
            description: `Observation générale - ${selectYear}`,
            icon: faTasks,
            content: (
                <ObservationVerificationSA
                    observation={observation}
                    setObservation={setObservation}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
    ];

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={title}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isExcelPrint={true}
                    isLandingScape={true}
                    printTitle={(`${title} ${selectYear}`)}
                    excelData={excelPrintData}
                    printContent={(
                        <PrintVerification
                            datas={travauxData}
                            conclusion={conclusion}
                            observation={observation}
                        />
                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>
                        {activeTab >= 1 && <div className="d-flex justify-content-end align-items-end mb-10">
                            <Tooltip title="Enregistrer">
                                <Button
                                    disabled={(missionSelected.isCloture === 1 || missionSelected.isClotureProvisoire === 1)}
                                    size={"large"}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: appTheme.colors.secondary,
                                        color: "white"
                                    }}
                                    icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                    onClick={handleSubmit}
                                >
                                    {!loading &&
                                        <span className='indicator-label text-white'>Enregistrer</span>}
                                    {loading && (
                                        <span className='indicator-progress fs-9 text-white'
                                              style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </Button>
                            </Tooltip>
                        </div>}
                        {formSteps[activeTab].content}
                    </>
                </StepBody>
            </div>
        </>
    );
 }

export {VerificationSA}