import React, {useEffect} from "react";
import {Input, Skeleton, Table} from "antd";
import TableTitle from "../../../../../common/TableTitle";
import FormLabel from "../../../../../common/Form/FormLabel";

const DecisionMaintien = ({mission, questions, loading, acceptLoading, questionsData, setQuestionData, setConclusion, conclusion, affectValid}) => {

    useEffect(() => {
        const quests = questions.flatMap((question) => ({
            libelle: question.libelle,
            commentaire: null
        }))

        setQuestionData(quests)
    }, [questions])


    const handleCommentChange = (index, motif) => {
        const updatedList = questionsData.map((item, i) =>
            i === index ? { ...item,  commentaire: motif } : item
        );
        setQuestionData(updatedList);
    };


    const columns = [
        {
            title: () => <TableTitle>Questions</TableTitle>,
            dataIndex: 'name',
            width: 500,
            render: (_, record) => (
                <>
                    <Input.TextArea
                        size="large"
                        value={record.libelle}
                        readOnly={true}
                        bordered={false}
                        style={{width: "100%"}}
                        showCount
                        maxLength={5000}
                        autoSize={{
                            minRows: 2,
                            maxRows: 8,
                        }}
                    />
                </>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 500,
            render: (_, record, index) => (
                <>
                    <Input.TextArea
                        size="large"
                        placeholder="Saisisser le commentaire"
                        value={record.motif}
                        readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire ===1)}
                        onChange={(e) => {
                            handleCommentChange(index, e.target.value);
                        }}
                        bordered={false}
                        style={{width: "100%"}}
                        showCount
                        maxLength={5000}
                        autoSize={{
                            minRows: 2,
                            maxRows: 8,
                        }}
                    />
                </>
            ),
        },
    ];

    return(
        <>{
            acceptLoading ?
                <Skeleton active />
            :
                <div className=''>
                    <p className="fs-5 fw-semibold text-gray-600 py-6">
                        Si le client n’a pas été accepté, ce document doit être conservé par l’associé (ou autre réviseur d’entreprises) responsable de la mission. <br/>
                        Le contenu de ce document doit d’abord être revu avant communication à des tiers. <br/>
                        <br/>
                        Il se peut toutefois que des événements ultérieurs viennent modifier les fondements de cette nomination. Un réexamen de la situation et une nouvelle consultation s’imposeront alors et donneront lieu à une nouvelle conclusion, adaptée le cas échéant. <br/>
                        La checklist ci-dessous pourra être utilisée dans une telle situation.

                    </p>

                    <div className="table-responsive mb-2">
                        <Table
                            loading={loading}
                            columns={columns}
                            dataSource={questionsData}
                            pagination={false}
                            scroll={{ y: 500 }}
                            // bordered
                            size={"small"}
                        />
                    </div>

                    <div className="d-flex flex-column my-7">
                        <FormLabel required label="Conclusion :"/>
                        <Input.TextArea
                            size="large"
                            placeholder="Saisisser la conclusion"
                            value={conclusion}
                            readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                            onChange={(e) => {
                                setConclusion(e.target.value);
                            }}
                            style={{width: "100%"}}
                            showCount
                            maxLength={5000}
                            autoSize={{
                                minRows: 7,
                                maxRows: 10,
                            }}
                        />
                    </div>
                </div>
          }
        </>
    )
}

export default DecisionMaintien;