import {
    Button,
    Col,
    Input,
    InputNumber,
    notification,
    Row,
    Select,
    Space, Spin,
    Table,
    Tooltip,
    Typography
} from "antd";
import React, { useEffect, useState } from "react"
import {
    ajouterBalance,
} from "../../../../../../../data/balance/balance";
import {ajouterZeros, areArraysEqual} from "../../../../../../utilities/Utilities";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../../helpers/AssetsHelpers";
import {SearchHeader} from "../../../../../../layout/header/HeaderList";
import * as XLSX from "xlsx"
import {appTheme} from "../../../../../../../config/theme";
import TableTitle from "../../../../../../common/TableTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faFileExcel, faFilter, faRefresh, faSave} from "@fortawesome/free-solid-svg-icons";
import FormLabel from "../../../../../../common/Form/FormLabel";

const { Text, Title } = Typography;

const SearchCompte = ({
          mission,
          isProvisoire,
          plans,
          date,
          codeSommaire,
          tache_id,
          user_id,
          heureEntree,
          intervention_id,
          client,
          balanceData,
          setBalancesData,
          chargement
    }) => {
    const [plansSelect, setPlansSelect] = useState([])
    const [loading, setLoading] = useState(false);
    const [planLoading, setPlanLoading] = useState(false);
    const [validateLoading, setValidateLoading] = useState(true);
    const [filtersItems, setFiltersItems] = useState(balanceData || []);
    const [balanceSelect, setBalanceSelect] = useState(null)
    const [number, setNumber] = useState('')
    const [sensSelect, setSensSelect] = useState('')
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const sensTable = [
        {
            sens: 'D',
            libelle: "Sens Débiteurs anormaux"
        },
        {
            sens: 'C',
            libelle: "Sens Créditeurs anormaux"
        },
    ]

    useEffect(() => {
        setLoading(true)
        const updateBalance = [...balanceData]
        if(updateBalance.length > 0){
            updateBalance.forEach((item) => {

                const num = item.numeroCompte.toString().slice(0, 4);
                const numExist = plans.find((key) => key.numero === num);
                if(numExist){
                    item.sensCompte = numExist.sensCompte
                }

                if(!numExist) {
                    item.error = {
                        state: true,
                        color: 'info',
                        message: 'Ce numéro de compte n`\'existe pas'
                    }
                }
                else if(item.libelle === '') {
                    item.error = {
                        state: true,
                        color: 'info',
                        message: 'Aucun libéllé disponible pour ce numéro de compte'
                    }
                }
                else if (numExist.sensCompte === 'C' && item.soldeDebit){
                    item.error = {
                        state: true,
                        color: 'danger',
                        message: 'Ce numéro de compte n`\'est pas débiteur'
                    }
                }
                else if (numExist.sensCompte === 'D' && item.soldeCredit){
                    item.error = {
                        state: true,
                        color: 'danger',
                        message: 'Ce numéro de compte n`\'est pas créditeur'
                    }
                }
            })
        }

        if (!areArraysEqual(updateBalance, balanceData)) {
            setBalancesData(updateBalance);
        }
        setLoading(false)
    }, [plans, balanceData, setBalancesData])

    useEffect(() => {
        setPlanLoading(true)
        if (balanceSelect) {
            const num = balanceSelect.numeroCompte.toString().slice(0, 2);
            const plansExist = plans.filter((plan) => plan.numero.startsWith(num));
            if (plansExist.length > 0) {
                setPlansSelect(plansExist);
                setPlanLoading(false)
            } else {
                setPlansSelect([]);
                setPlanLoading(false)
            }
        }
    }, [balanceSelect, plans]);

    useEffect(() => {
        setFiltersItems(balanceData)
    }, [balanceData])

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // setLivreData([]);
        }
    };

    const handleSearchChange = (e) => {
        setLoading(true)
        setTimeout(() => {
            const searchTerm = e.target.value.toLowerCase();
            const filtered = balanceData.filter(
                (item) =>
                    item.libelle.toLowerCase().startsWith(searchTerm) ||
                    item.numeroCompte.toString().toLowerCase().startsWith(searchTerm) ||
                    item.sens?.toLowerCase().startsWith(searchTerm)
            );
            setFiltersItems(filtered);
            setLoading(false)
        }, 2000)
    };

    const handleBalanceActualise = () => {
        setLoading(true)
        setTimeout(() => {
            setFiltersItems(balanceData)
            setSensSelect(null)
            setLoading(false)
        }, 1000)
    }
    const handleCompteNonSisco = () => {
        setLoading(true)
        setTimeout(() => {
            const updatedBalances = balanceData.filter(
                (balance) => {
                    const num = balance.numeroCompte.toString().slice(0, 4);
                    const plansExist = plans.find((plan) => plan.numero === (num));

                    return !plansExist;
                })

            setFiltersItems(updatedBalances)
            notification.success({
                message: `${(updatedBalances ?? []).length} éléments trouvés`,
            });
            setLoading(false)
        }, 1000)
    }

    const handleSensCompteFilter = (sens) => {
        setLoading(true)
        setTimeout(() => {
            setSensSelect((sensTable ?? []).find(item => item.sens === sens) || null)
            const updatedBalances = balanceData.filter(
                (balance) => {
                    const solde = sens === 'C' ? balance.soldeDebit : balance.soldeCredit

                    const num = balance.numeroCompte.toString().slice(0, 4);
                    const plansExist = plans.find((plan) => plan.numero === (num));
                    if(plansExist) {
                        const sensCompte = plansExist.sensCompte === sens && solde
                        return sensCompte;
                    }
                })

            setFiltersItems(updatedBalances)
            setLoading(false)
            notification.success({
                message: `${(updatedBalances ?? []).length} éléments trouvés`,
            });
        }, 1000)
    }

    const handleClick = (id) => {
        setValidateLoading(true);
        setTimeout(() => {
            const plan = plans.find((key) => key.id === id);
            const updated = {
                ...balanceSelect,
                // numeroCompte: parseInt(plan.numero),
                numeroCompte: ajouterZeros(plan.numero, balanceSelect.numeroCompte),
                libelle: plan.intitule,
                sensCompte: plan.sensCompte
            }
            setBalanceSelect(updated);
            setValidateLoading(false)
        }, 500)
    }

    const exportToExcel = () => {
        const data = filtersItems.map((record) => {
            return columns.map((column) => record[column.dataIndex]);
        });

        const ws = XLSX.utils.aoa_to_sheet(data);
        filtersItems.forEach((record, index) => {
            const style = {
                font: { color: record.error?.color },
                fill: { bgColor: record.isModified ? '#1f5df8' : '#FFFFFF' },
            };
            XLSX.utils.format_cell(ws[XLSX.utils.encode_cell({ r: index + 1, c: 0 })], style);
        });

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, 'exported_data.xlsx');
    };

    const handleSubmit = async (e) => {
        setValidateLoading(true);
        setLoading(true);
        setPlanLoading(true);
        e.preventDefault();
        setTimeout(async () => {
            setLoading(true)

            const updatedList = balanceData.map((item) =>
                item.numeroCompte === number ?
                    {
                        ...item,
                        numeroCompte: balanceSelect.numeroCompte.toString(),
                        libelle: balanceSelect.libelle,
                        anouveauDebit: balanceSelect.anouveauDebit,
                        anouveauCredit: balanceSelect.anouveauCredit,
                        mvtDebit: balanceSelect.mvtDebit,
                        mvtCredit: balanceSelect.mvtCredit,
                        soldeDebit: balanceSelect.soldeDebit,
                        soldeCredit: balanceSelect.soldeCredit,
                        sensCompte: balanceSelect.sensCompte,
                        isModified: true,
                        error: {
                            state: false,
                            color: '',
                            message: ''
                        }
                    } : item
            );
            setBalancesData(updatedList);

            setValidateLoading(false);
            setLoading(false);
            setPlanLoading(false);
            notification.success({
                message: "Modification effectuée avec succès",
            });
        }, 1500)
    }

    const handleImporterBalance = async () => {
        setValidateLoading(true);
        setLoading(true);
        setPlanLoading(true);
        if(balanceData.length > 0){
            const data = {
                intervention_id,
                date,
                heureEntree,
                tache_id,
                user_id,
                codeSommaire,
                exercice_id: mission.id,
                libelle: mission.annee,
                client_id: client.id,
                isProvisoire: parseInt(isProvisoire) === 1,
                balanceData: balanceData
            }
            const {status, message} = await ajouterBalance(data)
            if (status === 'success') {
                setLoading(false)
                await swal(succesMessage, '', 'success')
            } else {
                await swal(message, '', 'error')
                setLoading(false)
            }
        }
    };


    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>N° de compte</TableTitle>,
            dataIndex: 'numeroCompte',
            width: 100,
            render: (_, record) => (
                <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                    <span className="fw-bolder" style={{fontSize: '14px'}}>
                        {record.numeroCompte}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 400,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <span className="fw-bolder" style={{fontSize: '14px'}}>
                            {record.libelle}
                        </span>
                    </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>RAN Débit</TableTitle>,
            dataIndex: 'anouveauDebit',
            width: 100,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.anouveauDebit && record.anouveauDebit.toLocaleString('fr')}
                        </div>
                    </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>RAN Crédit</TableTitle>,
            dataIndex: 'anouveauCredit',
            width: 100,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.anouveauCredit && record.anouveauCredit.toLocaleString('fr')}
                        </div>
                    </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>Mouvement Débit</TableTitle>,
            dataIndex: 'mvtDebit',
            width: 100,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.mvtDebit && record.mvtDebit.toLocaleString('fr')}
                        </div>
                    </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>Mouvement Crédit</TableTitle>,
            dataIndex: 'mvtCredit',
            width: 100,
            render: (_, record, ) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.mvtCredit && record.mvtCredit.toLocaleString('fr')}
                        </div>
                    </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>Solde Débit</TableTitle>,
            dataIndex: 'soldeDebit',
            width: 100,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.soldeDebit && record.soldeDebit.toLocaleString('fr')}
                        </div>
                    </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>Solde Crédit</TableTitle>,
            dataIndex: 'soldeCredit',
            width: 100,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.soldeCredit && record.soldeCredit.toLocaleString('fr')}
                        </div>
                    </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>SENS</TableTitle>,
            dataIndex: 'sensCompte',
            width: 50,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <span style={{fontSize: '14px', fontWeight: '700'}}>
                            {record.sensCompte}
                        </span>
                    </Tooltip>
                </>
            ),
        },
    ];

    const columnSens = [
        {
            title: () => <TableTitle>Compte</TableTitle>,
            dataIndex: 'numero',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                        {record.numero}
                    </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'intitule',
            width: 300,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                        {record.intitule}
                    </span>
            ),
        },
        {
            title: () => <TableTitle>SENS</TableTitle>,
            dataIndex: 'sens',
            width: 50,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                        {record.sensCompte}
                    </span>
            ),
        },
    ];

    const handleRowClick = (record) => {
        return new Promise((resolve) => {
            setValidateLoading(true);
            setPlanLoading(true);
            setLoading(true);
            setTimeout(() => {
                setBalanceSelect(record)
                setNumber(record.numeroCompte);
                setValidateLoading(false);
                setPlanLoading(false);
                setLoading(false);
                resolve();
            }, 1000)
        });
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    return(
        <>
            <div className="p-sm-5 mb-5">
                <Row justify="space-between">
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                    >
                        <Space>
                            <Tooltip title="Actualiser la balance">
                                <Button
                                    size={"large"}
                                    onClick={handleBalanceActualise}
                                    style={{backgroundColor: appTheme.colors.primary, color: "white"}}
                                    icon={<FontAwesomeIcon icon={faRefresh} style={{marginRight: "5px"}} /> }
                                >
                                    <span className="fw-bolder fs-6">Actualiser</span>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Filtrer les comptes Non SYCOHADA">
                                <Button
                                    size={"large"}
                                    onClick={handleCompteNonSisco}
                                    style={{backgroundColor: appTheme.colors.primary, color: "white"}}
                                    icon={<FontAwesomeIcon icon={faFilter} style={{marginRight: "5px"}} /> }
                                >
                                    <span className="fw-bolder fs-6">Non SYCOHADA</span>
                                </Button>
                            </Tooltip>
                            <Tooltip title="Filtrer selon le sens des comptes">
                                <label className="border-2 rounded-3 mx-lg-3 mx-2 my-1">
                                    <Select
                                        placeholder="Sélectionner le sens de compte"
                                        variant="borderless"
                                        bordered={false}
                                        size="large"
                                        onChange={handleSensCompteFilter}
                                        value={sensSelect?.sens ?? null}
                                        style={{
                                            flex: 1,
                                        }}
                                        options={(sensTable ?? []).map((item) => ({
                                                label: (<span style={{fontWeight: "700", fontFamily: "Oswald"}}>{item.libelle}</span>),
                                                value: item.sens
                                            }
                                        ))}
                                    />
                                </label>
                            </Tooltip>
                        </Space>

                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                    >
                        <Space>
                            <Tooltip title="Exporter la balance au format EXCEL">
                                <Button
                                    size={"large"}
                                    onClick={exportToExcel}
                                    style={{backgroundColor: appTheme.colors.alter, color: "white"}}
                                    icon={<FontAwesomeIcon icon={faFileExcel} /> }
                                >
                                    Exporter
                                </Button>
                            </Tooltip>
                            <Tooltip title="Enregistrer">
                                <Button
                                    size={"large"}
                                    onClick={handleImporterBalance}
                                    style={{backgroundColor: appTheme.colors.secondary, color: "white"}}
                                    icon={<FontAwesomeIcon icon={faSave} style={{marginRight: "5px"}} /> }
                                >
                                    Enregistrer
                                </Button>
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
            </div>
            <div className="table-responsive mb-10">
                <div className="card-header border-0 pt-2">
                    <SearchHeader
                        placeholder="Rechercher par le numéro, le libellé"
                        handleSearch={handleSearchChange}
                    />
                </div>
                <div className="py-4">
                    <div className="table-responsive">
                        <Table
                            onRow={(record, index) => {
                                return {
                                    // onClick: (event) => {
                                    //     setValidateLoading(true);
                                    //     setPlanLoading(true);
                                    //     setLoading(true);
                                    //     setTimeout(() => {
                                    //         event.preventDefault();
                                    //         setBalanceSelect(record)
                                    //         setNumber(record.numeroCompte);
                                    //         setValidateLoading(false);
                                    //         setPlanLoading(false);
                                    //         setLoading(false);
                                    //     }, 1000)
                                    // }
                                    onClick: () => {
                                        handleRowClick(record)
                                        setSelectedRowKeys([index]);
                                    }
                                };
                            }}
                            rowClassName={(record, index) =>
                                `${record.error && record.error?.state === true ? `text-${record.error?.color}` : ''} ${
                                    record.isModified && record.isModified ? 'bg-light-warning' : ''
                                } cursor-pointer h-3px p-0 m-0 lh-0.5 ${selectedRowKeys.includes(index) ? 'cursor-pointer h-3px p-0 m-0 lh-0.5 highlighted-row' : 'cursor-pointer h-3px p-0 m-0 lh-0.5'}`
                            }
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={filtersItems}
                            pagination={tableParams.pagination}
                            loading={loading || chargement}
                            // scroll={{
                            //     y: 300,
                            // }}
                            onChange={handleTableChange}

                            rowSelection={{
                                type: 'radio',
                                ...rowSelection,
                            }}
                            size={"small"}
                            summary={(pageData) => {
                                let totalAnouveauDebit = 0;
                                let totalAnouveauCredit = 0;
                                let totalMvtDebit = 0;
                                let totalMvtCredit = 0;
                                let totalSoldeCredit = 0;
                                let totalSoldeDebit = 0;

                                pageData.forEach(({ anouveauDebit, anouveauCredit, mvtDebit, mvtCredit, soldeDebit, soldeCredit}) => {
                                    totalAnouveauDebit += anouveauDebit;
                                    totalAnouveauCredit += anouveauCredit;
                                    totalMvtDebit += mvtDebit;
                                    totalMvtCredit += mvtCredit;
                                    totalSoldeDebit += soldeDebit;
                                    totalSoldeCredit += soldeCredit;
                                });
                                return (
                                    <>
                                        <Table.Summary fixed>
                                            <Table.Summary fixed>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}>
                                                        <Text>{''}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                    <Table.Summary.Cell className="fw-boldest" index={2}>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}}>{'TOTAL'}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                                    <Table.Summary.Cell index={4} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success" >{totalAnouveauDebit.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={5} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success">{totalAnouveauCredit}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={6} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success">{totalMvtDebit.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={7} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success">{totalMvtCredit.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={8} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success">{totalSoldeDebit.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={9} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success">{totalSoldeCredit.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </Table.Summary>
                                        </Table.Summary>
                                    </>
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
            <br/>
            <div>
                <div className="d-flex justify-content-between align-center">
                    <Title style={{display: "block", fontWeight: '600', color: appTheme.colors.primary}} level={4}># Correction de compte</Title>
                    <div>
                        <Button
                            size={"large"}
                            onClick={handleSubmit}
                            style={{backgroundColor: appTheme.colors.primary_blue, color: "white"}}
                            icon={<FontAwesomeIcon icon={faCircleCheck} style={{marginRight: "5px"}} /> }
                        >
                            <span className="fw-bolder fs-6">Valider</span>
                        </Button>
                    </div>
                </div>
                <br/>
                <Row gutter={[16, 16]}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                    >
                        <FormLabel label="Comptes proposés pour correction"/>
                        <div className="py-4">
                            <div className="table-responsive">
                                <Table
                                    onRow={(record) => {
                                        return {
                                            onClick: (event) => {
                                                event.preventDefault();
                                                handleClick(record.id)
                                            }
                                        };
                                    }}
                                    rowClassName={() => "cursor-pointer h-3px p-0 m-0 lh-0.5"}
                                    columns={columnSens}
                                    rowKey={(record) => record.id}
                                    dataSource={plansSelect}
                                    pagination={false}
                                    loading={planLoading || chargement}
                                    scroll={{
                                        y: 300,
                                    }}
                                    rowSelection
                                    size={"small"}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                    >
                        <FormLabel label="Modification de compte"/>
                        {validateLoading ?
                            (
                                <div className="mx-auto px-auto pb-sm-15">
                                    <Spin spinning={validateLoading}/>
                                </div>
                            ):
                            (
                                <>
                                    <Row gutter={[16, 16]}>
                                        <Col
                                            xs={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                        >
                                            <Title style={{display: "block", fontWeight: '600', color: "black"}} level={5}>Compte</Title>
                                            <Input
                                                style={{ width: "100%" }}
                                                size="middle"
                                                placeholder="Numéro de compte"
                                                value={((balanceSelect?.numeroCompte ?? '').toString()) ?? ""}
                                                onChange={(e) => {
                                                    const updated = { ...balanceSelect,  numeroCompte: parseInt(e.target.value)}
                                                    setBalanceSelect(updated);
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                        >
                                            <Title
                                                style={{display: "block",
                                                    fontWeight: '600',
                                                    color: "black"}}
                                                level={5}
                                            >
                                                Sens de compte
                                            </Title>
                                            <Input
                                                style={{ width: "100%" }}
                                                size="middle"
                                                placeholder="Numéro de compte"
                                                value={(balanceSelect?.sensCompte) ?? ""}
                                            />
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 24 }}
                                            xl={{ span: 24 }}
                                        >
                                            <Title
                                                style={{display: "block",
                                                    fontWeight: '600',
                                                    color: "black"}}
                                                level={5}
                                            >
                                                Libellé
                                            </Title>
                                            <Input
                                                style={{ width: "100%" }}
                                                size="middle"
                                                placeholder="Numéro de compte"
                                                value={balanceSelect?.libelle ?? ""}
                                                onChange={(e) => {
                                                    const updated = { ...balanceSelect,  libelle: (e.target.value)}
                                                    setBalanceSelect(updated);
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                        >
                                            <Title
                                                style={{display: "block",
                                                    fontWeight: '600',
                                                    color: "black"}}
                                                level={5}
                                            >
                                                A-nouveaux Débit
                                            </Title>
                                            <InputNumber
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                parser={(value) => value.replace(/\$\s?|( *)/g, '')}
                                                style={{ width: "100%" }}
                                                size="middle"
                                                placeholder="Anouveau débit"
                                                value={balanceSelect?.anouveauDebit ?? 0}
                                                onChange={(value) => {
                                                    const updated = { ...balanceSelect,  anouveauDebit: parseInt(value)}
                                                    setBalanceSelect(updated);
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                        >
                                            <Title
                                                style={{display: "block",
                                                    fontWeight: '600',
                                                    color: "black"}}
                                                level={5}
                                            >
                                                A-nouveaux Crédit
                                            </Title>
                                            <InputNumber
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                parser={(value) => value.replace(/\$\s?|( *)/g, '')}
                                                style={{ width: "100%" }}
                                                size="middle"
                                                placeholder="Anouveau crédit"
                                                value={balanceSelect?.anouveauCredit ?? 0}
                                                onChange={(e) => {
                                                    const updated = { ...balanceSelect,  anouveauCredit: parseInt(e.target.value)}
                                                    setBalanceSelect(updated);
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                        >
                                            <Title
                                                style={{display: "block",
                                                    fontWeight: '600',
                                                    color: "black"}}
                                                level={5}
                                            >
                                                Mouvement Débit
                                            </Title>
                                            <InputNumber
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                parser={(value) => value.replace(/\$\s?|( *)/g, '')}
                                                style={{ width: "100%" }}
                                                size="middle"
                                                placeholder="Mouvement débit"
                                                value={balanceSelect?.mvtDebit ?? 0}
                                                onChange={(e) => {
                                                    const updated = { ...balanceSelect,  mvtDebit: parseInt(e.target.value)}
                                                    setBalanceSelect(updated);
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                        >
                                            <Title
                                                style={{display: "block",
                                                    fontWeight: '600',
                                                    color: "black"}}
                                                level={5}
                                            >
                                                Mouvement Crédit
                                            </Title>
                                            <InputNumber
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                parser={(value) => value.replace(/\$\s?|( *)/g, '')}
                                                style={{ width: "100%" }}
                                                size="middle"
                                                placeholder="Mouvement crédit"
                                                value={balanceSelect?.mvtCredit ?? 0}
                                                onChange={(e) => {
                                                    const updated = { ...balanceSelect,  mvtCredit: parseInt(e.target.value)}
                                                    setBalanceSelect(updated);
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                        >
                                            <Title
                                                style={{display: "block",
                                                    fontWeight: '600',
                                                    color: "black"}}
                                                level={5}
                                            >
                                                Solde Débit
                                            </Title>
                                            <InputNumber
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                parser={(value) => value.replace(/\$\s?|( *)/g, '')}
                                                style={{ width: "100%" }}
                                                size="middle"
                                                placeholder="Solde débit"
                                                value={balanceSelect?.soldeDebit ?? 0}
                                                onChange={(e) => {
                                                    const updated = { ...balanceSelect,  soldeDebit: parseInt(e.target.value)}
                                                    setBalanceSelect(updated);
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            xs={{ span: 24 }}
                                            md={{ span: 24 }}
                                            lg={{ span: 12 }}
                                            xl={{ span: 12 }}
                                        >
                                            <Title
                                                style={{display: "block",
                                                    fontWeight: '600',
                                                    color: "black"}}
                                                level={5}
                                            >
                                                Solde Crédit
                                            </Title>
                                            <InputNumber
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                                                parser={(value) => value.replace(/\$\s?|( *)/g, '')}
                                                style={{ width: "100%" }}
                                                size="middle"
                                                placeholder="Solde débit"
                                                value={balanceSelect?.soldeCredit ?? 0}
                                                onChange={(e) => {
                                                    const updated = { ...balanceSelect,  soldeCredit: parseInt(e.target.value)}
                                                    setBalanceSelect(updated);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    </Col>

                </Row>
            </div>
        </>
    )
}

export {SearchCompte}