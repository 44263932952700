import React, {useEffect, useMemo, useState} from 'react';
import {InfosGenerales} from "./EC4/InfosGenerales";
import {DonneesChiffrees} from "./EC4/DonneesChiffrees";
import {SeuilSignification} from "./EC4/SeuilSignification";
import ContexteMission from "./EC4/EC4_1";
import {getStrategieGlobaleMission} from "../../../../../data/stategieGlobale";
import OrganisationPolitique from "./EC4/OrganisationPolitique";
import PolitiqueCommerciale from "./EC4/PolitiqueCommerciale";
import ZoneRisque from "./EC4/ZoneRisque";
import {EquipeAudit} from "./EC4/EquipeAudit";
import {Rapport} from "./EC4/Rapports";
import ObservationStrategie from "./EC4/ObservationStrategie";
import {Identification} from "../../Identification";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import {faChevronDown, faIdCard, faLock, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import {Dropdown, Space, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {appTheme} from "../../../../../config/theme";
import {getDetailsEtatsFinancier} from "../../../../../data/balance/etatFinancier";
import {getListeMembreMission} from "../../../../../data/exercice";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

export const StrategieHeader = ({title, error, message, handleSubmit, loading}) => {

    return (
        <div className="pt-lg-2">
            <div className='d-flex justify-content-between align-center'>
                <div className="">
                    <h2 className="fs-xl-4">{title}</h2>
                </div>
                <div>
                    <button
                        className='btn btn-primary text-hover-primary btn-md fs-7'
                        onClick={handleSubmit}
                    >
                        {!loading && <span className='indicator-label'>Enregistrer</span>}
                        {loading && (
                            <span className='indicator-progress' style={{display: 'block'}}>
                              Patientez svp...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </button>
                </div>
                {error ? (
                    <div className='mb-lg-5 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{message}</div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

const StrategieAuditGlobale = ({
           mission,
           client,
           code,
           // modalId,
           prev
       }) => {
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);

    const [activeTab, setActiveTab] = useState(0);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectYear, setSelectYear] = useState(mission?.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission);
    const [isProvisoire, setIsProvisoire] = useState('1');

    const [title, setTitle] = useState('Voir plus');

    const [users, setUsers] = useState([])
    const [budgetTemps, setBudgetTemps] = useState([])
    const [programmeTaches, setProgrammeTaches] = useState([])
    const [rapports, setRapports] = useState([])
    const [strategies, setStrategies] = useState(null);
    const [menuStep, setMenuStep] = useState(null);
    const [etatsFinanciers, setEtatsFinanciers] = useState([]);
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    const codeSommaire = useMemo(() => code || 'EC4', [code]);

    useEffect(() => {
        if (activeTab === 0) {
            const fetchIdentifications = async () => {
                const res = await getListeIdentificationSommaire(missionSelected.id, codeSommaire);
                setIdentifications(res.result);
            };

            fetchIdentifications();
        }
    }, [activeTab, codeSommaire, missionSelected]);

    useEffect(() => {
        const fetchUserMission = async (id) => {
            setLoading(true);
            getListeMembreMission(id)
                .then((res) => {
                    if(res){
                        setUsers(res);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        };

        fetchUserMission(missionSelected.id);
    }, [missionSelected]);

    useEffect(() => {
        const fetchStrategieGlobale = async () => {
            setLoading(true);
            await getStrategieGlobaleMission(missionSelected.id)
                .then((res) => {
                    if(res.result){
                        const strategie = res.result.find((item) => item.isProvisoire === (parseInt(isProvisoire) === 1))
                        if(strategie){
                            setStrategies(strategie);
                            setBudgetTemps(strategie.Lea_StrategieHeureTravails);
                            setProgrammeTaches(strategie.Lea_StrategieProgrammeTaches);
                            setRapports(strategie.Lea_StrategieRapports);
                            setLoading(false);
                        }
                        else{
                            setStrategies(null);
                            setBudgetTemps([]);
                            setProgrammeTaches([]);
                            setRapports([]);
                            setLoading(false);
                        }
                    }
                });
        };
        setLoading(true);

        if(missionSelected && isProvisoire) {
            fetchStrategieGlobale()
        }

    },[missionSelected, isProvisoire]);

    useEffect(() => {
        const fetchEtatsFinanciers = async () => {
            setLoading(true);
            await getDetailsEtatsFinancier(missionSelected.id)
                .then((res) => {
                    if((res.result ?? []).length > 0){
                        setEtatsFinanciers(res.result);
                    }
                    setLoading(false);
                }).catch(err => {
                    
                })
        };

        fetchEtatsFinanciers();
    },[missionSelected]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const handleTabChange = (tabIndex, title) => {
        if(!auth){
            return setActiveTab(0);
        }
        setActiveTab(tabIndex);
        setTitle(title || 'Voir plus')
        if(tabIndex >= 4){
            const form = (formSteps ?? []).find((item, index) => index === (tabIndex))
            setMenuStep(form)
        } else {
            setMenuStep(null);
        }
    };

    const formSteps = useMemo(() => ([
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "CN",
            title: `Contexte et nature de la mission - ${selectYear}`,
            icon: faTasks,
            content: (
                <ContexteMission
                    code={codeSommaire}
                    strategies={strategies}
                    isProvisoire={isProvisoire}
                    mission={missionSelected}
                    chargement={loading}
                    date={date}
                    intervention_id={intervention_id}
                    tache_id={tache_id}
                    heureEntree={heureEntree}
                    codeSommaire={codeSommaire}
                />
            ),
        },
        {
            code: "IG",
            title: `Informations générale - ${selectYear}`,
            icon: faTasks,
            content: (
                <InfosGenerales 
                    strategies={strategies} 
                    isProvisoire={isProvisoire} 
                    code={codeSommaire} 
                    mission={missionSelected} 
                    chargement={loading}
                    date={date}
                    intervention_id={intervention_id}
                    tache_id={tache_id}
                    heureEntree={heureEntree}
                    codeSommaire={codeSommaire}
                />
            ),
        },
        {
            code: "DC",
            title: `Données chiffrées - ${selectYear}`,
            icon: faTasks,
            content: (
                <DonneesChiffrees 
                    client={client} 
                    strategies={strategies} 
                    isProvisoire={isProvisoire} 
                    date={date} 
                    intervention_id={intervention_id} 
                    tache_id={tache_id} 
                    heureEntree={heureEntree} 
                    codeSommaire={codeSommaire} 
                    mission={missionSelected}
                    etatsFinanciers={etatsFinanciers}
                    chargement={loading}
                />
            ),
        },
        {
            code: "OPC",
            title: `Organisation, politique comptable - ${selectYear}`,
            description: 'Organisation, politique comptable et référentiel comptable',
            icon: faTasks,
            content: (
                <OrganisationPolitique 
                    strategies={strategies} 
                    isProvisoire={isProvisoire} 
                    mission={missionSelected}
                    chargement={loading}
                    date={date}
                    intervention_id={intervention_id}
                    tache_id={tache_id}
                    heureEntree={heureEntree}
                    codeSommaire={codeSommaire}
                />
            ),
        },
        {
            code: "PCO",
            title: `Politique commerciale - ${selectYear}`,
            description: 'Politique commerciale et opérationnelle',
            icon: faTasks,
            content: (
                    <PolitiqueCommerciale
                        strategies={strategies}
                        isProvisoire={isProvisoire}
                        mission={missionSelected}
                        chargement={loading}
                        date={date}
                        intervention_id={intervention_id}
                        tache_id={tache_id}
                        heureEntree={heureEntree}
                        codeSommaire={codeSommaire}
                    />
                )
        },
        {
            code: "ZR",
            title: `Zone de risque - ${selectYear}`,
            icon: faTasks,
            content: (
                    <ZoneRisque
                        strategies={strategies}
                        isProvisoire={isProvisoire}
                        mission={missionSelected}
                        chargement={loading}
                        date={date}
                        intervention_id={intervention_id}
                        tache_id={tache_id}
                        heureEntree={heureEntree}
                        codeSommaire={codeSommaire}
                    />
                )
        },
        {
            code: "SS",
            title: `Seuil de signification - ${selectYear}`,
            description: 'Seuil de signification au niveau des comptes et pris dans leur ensemble',
            icon: faTasks,
            content: (
                    <SeuilSignification
                        strategies={strategies}
                        isProvisoire={isProvisoire}
                        mission={missionSelected}
                        chargement={loading}
                        date={date}
                        intervention_id={intervention_id}
                        tache_id={tache_id}
                        heureEntree={heureEntree}
                        codeSommaire={codeSommaire}
                        etatsFinanciers={etatsFinanciers}
                    />
                )
        },
        {
            code: "EA",
            title: `Equipe d'Audit - ${selectYear}`,
            icon: faTasks,
            content: (
                    <EquipeAudit
                        strategies={strategies}
                        budgetTemps={budgetTemps}
                        programmeTaches={programmeTaches}
                        isProvisoire={isProvisoire}
                        mission={missionSelected}
                        chargement={loading}
                        date={date}
                        intervention_id={intervention_id}
                        tache_id={tache_id}
                        heureEntree={heureEntree}
                        codeSommaire={codeSommaire}
                        etatsFinanciers={etatsFinanciers}
                    />
                )
        },
        {
            code: "RE",
            title: `Rapports à émettre - ${selectYear}`,
            icon: faTasks,
            content: (
                    <Rapport
                        strategies={strategies}
                        isProvisoire={isProvisoire}
                        rapports={rapports}
                        mission={missionSelected}
                        chargement={loading}
                        date={date}
                        intervention_id={intervention_id}
                        tache_id={tache_id}
                        heureEntree={heureEntree}
                        codeSommaire={codeSommaire}
                    />
                )
        },
        {
            code: "OG",
            title: `Observation Générales - ${selectYear}`,
            icon: faTasks,
            content: (
                    <ObservationStrategie
                        strategies={strategies}
                        isProvisoire={isProvisoire}
                        mission={missionSelected}
                        chargement={loading}
                        date={date}
                        intervention_id={intervention_id}
                        tache_id={tache_id}
                        heureEntree={heureEntree}
                        codeSommaire={codeSommaire}
                    />
                )
        },
    ]), [date, mission, prev, loading, codeSommaire, auth, selectYear, strategies, isProvisoire, missionSelected, intervention_id, tache_id, heureEntree, client, etatsFinanciers, budgetTemps, programmeTaches, rapports]);

    const items = (formSteps ?? []).slice(4).map((step, index) => ({
        label: (
            <div className="app-nav-menu-option">
                <Tooltip key={index} color="" title={step?.description || ''}>
                    <div
                        className="border-0 d-flex align-center"
                        onClick={() => handleTabChange(index + 4 , step.title)}
                    >
                        <FontAwesomeIcon className="app-nav-menu-option-icon" style={{marginRight: "10px", fontSize: "18px", color: activeTab !== (index + 4) ? "darkgrey" : ""}} icon={step.icon} />
                        <span style={{color: activeTab !== (index + 4) ? "darkgrey" : ""}} className="app-nav-menu-option-title d-block">{step.title}</span>
                    </div>
                </Tooltip>
                {activeTab === (index + 4) && <div style={{
                    backgroundColor: appTheme.colors.primary,
                    width: "100%",
                    height: "3px",
                    marginTop: "3px"
                }}></div>}
            </div>
        ),
        key: index
    }))

    const onClick = ({ key }) => {
        // console.log("key")
    };

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EC4 - Stratégie globale d’Audit"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                />
                <StepHeader>
                    {/*<ModalNavBarMenu setActiveTab={setActiveTab} activeTab={activeTab} steps={formSteps} />*/}
                    <div className="mb-2 mx-3 scroll-x z-index-9999">
                        <Space>
                            {(formSteps ?? []).slice(0, 4)?.map((step, index) => (
                                <>
                                    <div className="app-nav-menu-option">
                                        <Tooltip key={index} color="" title={step?.description || ''}>
                                            <div
                                                className="border-0"
                                                onClick={() => handleTabChange(index)}
                                            >
                                                <FontAwesomeIcon className="app-nav-menu-option-icon" style={{marginRight: "10px", fontSize: "18px", color: activeTab !== index ? "darkgrey" : ""}} icon={step.icon} />
                                                <span style={{color: activeTab !== index ? "darkgrey" : ""}} className="app-nav-menu-option-title d-block">{step.title}</span>
                                                {!auth && index > 0 && <Tooltip title="Cycle verrouillé. Veuillez vous identifier" color="red">
                                                    <div><FontAwesomeIcon icon={faLock}
                                                                          style={{color: 'red', fontSize: '12px'}}/></div>
                                                </Tooltip>}
                                            </div>
                                        </Tooltip>
                                        {activeTab === index && <div style={{
                                            backgroundColor: appTheme.colors.primary,
                                            width: "100%",
                                            height: "3px",
                                            marginTop: "3px"
                                        }}></div>}
                                    </div>
                                    {formSteps[index + 1]  && <div style={{backgroundColor: appTheme.colors.primary, width: "100px", height: "2px"}}></div>}
                                </>
                            ))}
                            <Dropdown
                                menu={{
                                    items,
                                    onClick,
                                }}
                            >
                                {menuStep ?
                                        <div className="d-flex align-center">
                                            <div className="app-nav-menu-option">
                                                <Tooltip color="" title={menuStep?.description || ''}>
                                                    <div
                                                        className="border-0 d-flex align-center"
                                                    >
                                                        <FontAwesomeIcon className="app-nav-menu-option-icon" style={{marginRight: "10px", fontSize: "18px"}} icon={menuStep.icon} />
                                                        <span className="app-nav-menu-option-title d-block">{menuStep.title}</span>
                                                    </div>
                                                </Tooltip>
                                                {menuStep && <div style={{
                                                    backgroundColor: appTheme.colors.primary,
                                                    width: "100%",
                                                    height: "3px",
                                                    marginTop: "3px"
                                                }}></div>}
                                            </div>
                                            <FontAwesomeIcon className="ms-3 pt-2" icon={faChevronDown}/>
                                        </div>
                                    :
                                    <button
                                        className="btn btn-sm btn-flex btn-secondary fw-bold fs-6"
                                        onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <div className="d-flex align-center">
                                                {/*<EllipsisOutlined />*/}
                                                <span className="mx-3">{title}</span>
                                                <FontAwesomeIcon className="pt-1" icon={faChevronDown}/>
                                            </div>
                                        </Space>
                                    </button>
                                }
                            </Dropdown>
                        </Space>
                    </div>
                </StepHeader>
                <StepBody>
                    {formSteps[activeTab].content}
                </StepBody>
            </div>
        </>
    );
};

export { StrategieAuditGlobale };
