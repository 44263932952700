import React from "react";
const ControleEquilibre = ({
       xiResultat,
       cjPassif,
       dzPassif,
       bzActif,
       bzActifN1,
       cjPassifN1,
       dzPassifN1,
       xiResultatN1,
    }) => {
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        minHeight: "20px"
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <>
            <div className='gx-5 d-flex align-lg-center pt-5 px-15'>
                <div className='col-xl-6 mb-5 mb-xl-10 px-5'>
                    <div className="card-header pt-5 pb-2">
                        <h5 className="card-title fw-bolder">BILAN</h5>
                    </div>
                    <table style={tableStyle} className='w-100'>
                        <thead className='bg-primary p-2'>
                        <tr style={thStyle} className="">
                            <th className="fw-bolder text-start w-50" rowSpan="1" colSpan="1" >
                                Libellé
                            </th>
                            <th style={thStyle} className="w-10 text-center" rowSpan="1" colSpan="1">

                            </th>
                            <th style={thStyle} className="w-20 text-end fw-bolder" rowSpan="1" colSpan="1">
                                Année N
                            </th>
                            <th style={thStyle} className="fw-bolder w-20 text-end" rowSpan="1" colSpan="1">
                                Année N-1
                            </th>
                        </tr>
                        </thead>
                        <tbody className='fw-bold p-2'>
                        <tr style={tdStyle} className="even p-2" >
                            <td style={tdStyle} className="fw-bolder text-start">
                                Actif
                            </td>
                            <td style={tdStyle} className='fw-bolder text-center'>
                                BZ
                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {bzActif.toLocaleString('fr')}
                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {bzActifN1}
                            </td>
                        </tr>
                        <tr style={tdStyle} className="even p-2" >
                            <td style={tdStyle} className="fw-bolder text-start">
                                Passif
                            </td>
                            <td style={tdStyle} className='fw-bolder text-center'>
                                DZ
                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {dzPassif.toLocaleString('fr')}
                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {dzPassifN1}
                            </td>
                        </tr>
                        <tr className="even bg-light-primary p-2" >
                            <td style={tdStyle} className="fw-bolder text-start">
                                Ecart
                            </td>
                            <td style={tdStyle} className='fw-bolder text-center'>

                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {(bzActif - dzPassif).toLocaleString('fr')}
                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {(bzActifN1 - dzPassifN1).toLocaleString('fr')}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className='col-xl-6 mb-5 mb-xl-10 px-5'>
                    <div className="card-header pt-5">
                        <h5 className="card-title fw-bolder">COMPTE DE RESULTAT</h5>
                    </div>
                    <table style={tableStyle} className='w-100'>
                        <thead className='bg-primary p-2'>
                        <tr style={thStyle} className="">
                            <th className="fw-bolder text-start w-50" rowSpan="1" colSpan="1" >
                                Libellé
                            </th>
                            <th style={thStyle} className="w-10 text-center" rowSpan="1" colSpan="1">

                            </th>
                            <th style={thStyle} className="w-20 text-end fw-bolder" rowSpan="1" colSpan="1">
                                Année N
                            </th>
                            <th style={thStyle} className="fw-bolder w-20 text-end" rowSpan="1" colSpan="1">
                                Année N-1
                            </th>
                        </tr>
                        </thead>
                        <tbody className='fw-bold p-2'>
                        <tr style={tdStyle} className="even p-2" >
                            <td style={tdStyle} className="fw-bolder text-start">
                                Résultat au compte de Résultat
                            </td>
                            <td style={tdStyle} className='fw-bolder text-center'>
                                XI
                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {xiResultat.toLocaleString('fr')}
                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {xiResultatN1}
                            </td>
                        </tr>
                        <tr style={tdStyle} className="even p-2" >
                            <td style={tdStyle} className="fw-bolder text-start">
                                Résultat au bilan
                            </td>
                            <td style={tdStyle} className='fw-bolder text-center'>
                                CJ
                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {cjPassif.toLocaleString('fr')}
                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {cjPassifN1}
                            </td>
                        </tr>
                        <tr className="even bg-light-primary p-2" >
                            <td style={tdStyle} className="fw-bolder text-start">
                                Ecart
                            </td>
                            <td style={tdStyle} className='fw-bolder text-center'>

                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {(xiResultat - cjPassif).toLocaleString('fr')}
                            </td>
                            <td style={tdStyle} className='fw-bolder text-end'>
                                {(xiResultatN1 - cjPassifN1).toLocaleString('fr')}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export {ControleEquilibre}