import React from "react";
import {appTheme} from "../../../../../../../config/theme";
import {Typography} from "antd";

const { Title } = Typography;

const Demarche = ({compteResultat, bilan, objectifs}) => {
  return (
      <div className=''>
          {objectifs && objectifs.length > 0 &&
              <div className="card shadow-sm-2 mx-5 border">
                  <div className="card-header">
                      <div className="card-title">
                          <Title style={{
                              display: "block",
                              fontWeight: '600',
                              color: appTheme.colors.primary_blue,
                              textAlign: "center"
                          }} level={4}>OBJECTIF DU CONTRÔLE DES COMPTES</Title>
                      </div>
                  </div>
                  <div id="kt_docs_card_collapsible" className="collapse show">
                      <div className="card-body">
                          <div className='d-flex flex-column'>
                              {objectifs.map((item) => (
                                <div className='d-flex align-center mb-2' key={item.id}>
                                  <span className='fs-4 me-5 fw-boldest'>{item.code}:</span>
                                  <span className='fs-4'>{item.libelle}</span>
                                </div>
                              ))}
                          </div>
                      </div>
                  </div>
              </div>
          }

          {bilan && bilan.length > 0 &&
              <div className="card shadow-sm-2 m-5 border">
                  <div className="card-header">
                      <div className="card-title">
                          <Title style={{
                              display: "block",
                              fontWeight: '600',
                              color: appTheme.colors.primary_blue,
                              textAlign: "center"
                          }} level={4}> BILAN</Title>
                      </div>
                  </div>
                  <div id="kt_docs_card_collapsible" className="collapse show">
                      <div className="card-body">
                          <div className='d-flex flex-column'>
                              {bilan.map((item) => (
                                  <div className='d-flex align-center mb-2' key={item.id}>
                                      <span className='fs-4 me-5 fw-boldest'>{item.code}:</span>
                                      <span className='fs-4'>{item.libelle}</span>
                                  </div>
                              ))}
                          </div>
                      </div>
                  </div>
            </div>
          }

          {compteResultat && compteResultat.length > 0 &&
              <div className="card shadow-sm-2 m-5 border">
                  <div className="card-header">
                      <div className="card-title">
                          <Title style={{
                              display: "block",
                              fontWeight: '600',
                              color: appTheme.colors.primary_blue,
                              textAlign: "center"
                          }} level={4}> COMPTE DE RÉSULTAT</Title>
                      </div>
                  </div>
                  <div id="kt_docs_card_collapsible" className="collapse show">
                      <div className="card-body">
                          <div className='d-flex flex-column'>
                              {compteResultat.map((item) => (
                                  <div className='d-flex align-center mb-2' key={item.id}>
                                      <span className='fs-4 me-5 fw-boldest'>{item.code}:</span>
                                      <span className='fs-4'>{item.libelle}</span>
                                  </div>
                              ))}
                          </div>
                      </div>
                  </div>
              </div>
          }
      </div>
  )
}

export {Demarche}