import React, {useEffect, useState} from "react";
import {
    ajouterBalance,
    getBalanceDefinitifDetails,
    getBalanceProvisoiresDetails
} from "../../../../../../data/balance/balance";
import * as XLSX from "xlsx";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../helpers/AssetsHelpers";
import {Button, notification, Table, Tooltip, Typography} from "antd";
import {BalanceEdit} from "./BalanceEdit";
import {areArraysEqual} from "../../../../../utilities/Utilities";
import {SearchHeader} from "../../../../../layout/header/HeaderList";
import TableTitle from "../../../../../common/TableTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faSave} from "@fortawesome/free-solid-svg-icons";

const { Text } = Typography;
const Balance = ({
         isProvisoire,
         missionSelected,
         plans,
         client,
         livreData,
         balanceData,
         setBalancesData,
         heureEntree,
         date,
         codeSommaire,
         intervention_id,
         tache_id,
         user_id, chargement
    }) => {
    const [excelData, setExcelData] = useState([])
    const [filtersItems, setFiltersItems] = useState([]);
    const [error, setError] = useState(null)
    const [balanceTrue, setBalanceTrue] = useState(null)
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModalId, setSelectedModalId] = useState(null);
    const [numero, setNumero] = useState('')
    const [message, setMessage] = useState('')
    const [balance, setBalance] = useState(null)
    const [isBalance, setIsBalance] = useState(false)
    const [isVisible, setIsVisible] = useState(false)

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
        },
    });

    useEffect(() =>{
        if(parseInt(isProvisoire) === 1){
            fetchBalanceProvisoire();
        }
        else{
            fetchBalanceDefinitif();
        }
    }, [missionSelected, isProvisoire])

    const fetchBalanceProvisoire = () => {
        setLoading(true)
        getBalanceProvisoiresDetails(missionSelected.id)
            .then((res) => {
                if(res.results) {
                    setBalancesData(res.results.Lea_BalanceDetails)
                    setIsBalance(true)
                    setError(false)
                    setBalanceTrue(false)
                    setMessage("")
                    setLoading(false)
                }else{
                    setBalancesData([])
                    setError(false)
                    setBalanceTrue(false)
                    setLoading(false)
                    setMessage("")
                }
            })
    }

    const fetchBalanceDefinitif = () => {
        setLoading(true)
        getBalanceDefinitifDetails(missionSelected.id)
            .then((res) => {
                if(res.results) {
                    setBalancesData(res.results.Lea_BalanceDetails)
                    setIsBalance(true)
                    setError(false)
                    setBalanceTrue(false)
                    setMessage("")
                    setLoading(false)
                }else{
                   setBalancesData([])
                    setError(false)
                    setBalanceTrue(false)
                    setLoading(false)
                    setMessage("")
                }
            })
    }

    useEffect(() =>{
        setLoading(true)
        setMessage('')
        const balanceDetails = excelData.map((item) => ({
            numeroCompte: item[0],
            libelle: item[1],
            anouveauDebit: item[2] === '' ? null : item[2],
            anouveauCredit: item[3] === '' ? null : item[3],
            mvtDebit: item[4] === '' ? null : item[4],
            mvtCredit: item[5] === '' ? null : item[5],
            soldeDebit: item[6] === '' ? null : item[6],
            soldeCredit: item[7] === '' ? null : item[7],
            isModified: false
        }));

        setBalancesData(balanceDetails)
        setFiltersItems(balanceDetails)
        setError(false)
        setMessage("")
        setLoading(false)
        setBalanceTrue(false)
    }, [excelData, setBalancesData])

    useEffect(() => {
        setLoading(true)
        const updateBalance = [...balanceData]
        if(updateBalance.length > 0){
            updateBalance.forEach((item) => {
                const anouveauCredit = item.anouveauCredit || 0;
                const anouveauDebit = item.anouveauDebit || 0;
                const mvtCredit = item.mvtCredit || 0;
                const mvtDebit = item.mvtDebit || 0;
                const soldeCredit = item.soldeCredit || 0;
                const soldeDebit = item.soldeDebit || 0;

                const num = item.numeroCompte.toString().slice(0, 4);
                const soldC = (anouveauCredit - anouveauDebit + mvtCredit - mvtDebit) === soldeCredit - soldeDebit
                const numExist = plans.find((key) => key.numero === num);
                if(!numExist) {
                    item.error = {
                        state: true,
                        color: 'info',
                        message: 'Ce numéro de compte n`\'existe pas'
                    }
                }
                else if(item.libelle === '') {
                    item.error = {
                        state: true,
                        color: 'info',
                        message: 'Aucun libellé disponible pour ce numéro de compte'
                    }
                }
                else if (numExist.sensCompte === 'C' && item.soldeDebit){
                    item.error = {
                        state: true,
                        color: 'danger',
                        message: 'Ce numéro de compte n`\'est pas débiteur'
                    }
                }
                else if (numExist.sensCompte === 'D' && item.soldeCredit){
                    item.error = {
                        state: true,
                        color: 'danger',
                        message: 'Ce numéro de compte n`\'est pas créditeur'
                    }
                }
                else if(soldC === false){
                    item.error = {
                        state: true,
                        color: 'warning',
                        message: 'Les soldes ne correspondent pas'
                    }
                    setError(true)
                    setMessage('Balance non équilibrée')
                }
                else{
                    item.error = {
                        state: false,
                        color: '',
                        message: ''
                    }
                }
            })

            const totalMvtDebit = updateBalance.reduce((acc, detail) => acc + parseInt(detail.mvtDebit || 0), 0)
            const totalMvtCredit = updateBalance.reduce((acc, detail) => acc + parseInt(detail.mvtCredit || 0), 0)
            const totalAnDebit = updateBalance.reduce((acc, detail) => acc + parseInt(detail.anouveauDebit || 0), 0)
            const totalAnCredit = updateBalance.reduce((acc, detail) => acc + parseInt(detail.anouveauCredit || 0), 0)
            const totalSoldeDebit = updateBalance.reduce((acc, detail) => acc + parseInt(detail.soldeDebit || 0), 0)
            const totalSoldeCredit = updateBalance.reduce((acc, detail) => acc + parseInt(detail.soldeCredit || 0), 0)

            if(
                (totalMvtDebit !== totalMvtCredit) ||
                (totalAnDebit !== totalAnCredit) ||
                (totalSoldeDebit !== totalSoldeCredit)
            ) {
                setError(true)
                setMessage('Balance non équilibrée')
            }
            else {
                setError(false)
                setBalanceTrue(true)
                setMessage('Balance équilibrée')
            }
        }

        if (!areArraysEqual(updateBalance, balanceData)) {
            setBalancesData(updateBalance);
        }
        setLoading(false)
    }, [livreData, plans, balanceData, setBalancesData, setError, setMessage, setBalanceTrue])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            if(isVisible){
                setFiltersItems(balanceData)
                if((balanceData ?? []).length <= 0){
                    notification.warning({
                        description: "Aucune balance disponible !",
                    });
                }
            }
            else{
                setFiltersItems([])
            }
            setLoading(false)
        }, 500)
    }, [isVisible, balanceData, isBalance])

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setBalancesData([]);
        }
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = balanceData.filter(
            (item) =>
                item.libelle?.toLowerCase().includes(searchTerm) ||
                item.numeroCompte.toString()?.toLowerCase().startsWith(searchTerm)
        );
        setFiltersItems(filtered);
    };

    const handleFile = (e) => {
        setLoading(true)
        const initialColumns = [
            'N° de compte',
            'Libellé',
            'AN D',
            'AN C',
            'Mvt D',
            'Mvt C',
            'Solde D',
            'Solde C',
        ];
        const fileTypes = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/csv',
        ];
        const selectedFile = e.target.files[0];

        if (selectedFile && missionSelected) {
            setIsBalance(false)
            if (selectedFile && fileTypes.includes(selectedFile.type)) {

                const reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);

                reader.onload = (e) => {
                    const arrayBuffer = e.target.result;
                    const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    const filledData = rawData.map((row) =>
                        initialColumns.map((col, index) =>
                            typeof row[index] !== 'undefined' ? row[index] : ''
                        )
                    );

                    setExcelData(filledData);
                    setIsVisible(true)
                    setLoading(false)
                    notification.success({
                        description: "Balance importé avec succès.",
                    });
                };
            } else {
                setExcelData([]);
                setLoading(false)
            }
        }
        setLoading(false)
    };

    const handleOpenModal = (modal_id, numeroCompte) => {
        setSelectedModalId(modal_id);
        setNumero(numeroCompte);

        const balanc = balanceData.find((item) => item.numeroCompte === numeroCompte)
        setBalance(balanc)

        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setNumero('')
        setBalance(null)
        setSelectedModalId(null);
    };

    const handleImporterBalance = async (e) => {
        e.preventDefault()

        setLoading(true);
        if(balanceData.length > 0){
            const data = {
                intervention_id,
                date,
                heureEntree,
                tache_id,
                user_id,
                codeSommaire,
                exercice_id: missionSelected.id,
                libelle: missionSelected.annee,
                client_id: client.id,
                isProvisoire: parseInt(isProvisoire) === 1,
                balanceData: balanceData
            }

            const {status, message} = await ajouterBalance(data)
            setMessage(message)
            if (status === 'success') {
                setLoading(false)
                setIsVisible(false)
                await swal(succesMessage, '', 'success')
            } else {
                setLoading(false)
            }
        }
    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>N° de compte</TableTitle>,
            dataIndex: 'numeroCompte',
            width: 100,
            render: (_, record) => (
                <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                    <span className="fw-bolder" style={{fontSize: '14px'}}>
                        {record.numeroCompte}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 400,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <span className="fw-bolder text-uppercase" style={{fontSize: '14px'}}>
                            {record.libelle}
                        </span>
                     </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>RAN Débit</TableTitle>,
            dataIndex: 'RAN D',
            width: 100,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.anouveauDebit && record.anouveauDebit.toLocaleString('fr')}
                        </div>
                     </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>RAN Crédit</TableTitle>,
            dataIndex: 'anouveauCredit',
            width: 100,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.anouveauCredit && record.anouveauCredit.toLocaleString('fr')}
                        </div>
                     </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>Mouvement Débit</TableTitle>,
            dataIndex: 'mvtDebit',
            width: 100,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.mvtDebit && record.mvtDebit.toLocaleString('fr')}
                        </div>
                     </Tooltip>
                </> 
            ),
        },
        {
            title: () => <TableTitle>Mouvement Crédit</TableTitle>,
            dataIndex: 'mvtCredit',
            width: 100,
            render: (_, record, ) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.mvtCredit && record.mvtCredit.toLocaleString('fr')}
                        </div>
                     </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>Solde Débit</TableTitle>,
            dataIndex: 'soldeDebit',
            width: 100,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.soldeDebit && record.soldeDebit.toLocaleString('fr')}
                        </div>
                     </Tooltip>
                </>
            ),
        },
        {
            title: () => <TableTitle>Solde Crédit</TableTitle>,
            dataIndex: 'soldeCredit',
            width: 100,
            render: (_, record) => (
                <>
                    <Tooltip title={`${record.error && (record.error?.state !== true) ? '' : record.error?.message}`}>
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {record.soldeCredit && record.soldeCredit.toLocaleString('fr')}
                        </div>
                    </Tooltip>
                </>
            ),
        },
    ];

    return (
    <>
        <div className="">
            {(!loading || !chargement) && <div className="mx-auto">
                {(isVisible || filtersItems.length > 0) &&
                error ? (
                    <div className='my-5 alert alert-danger mx-auto'>
                        <div className='alert-text fw-boldest fs-4 text-center'>{message}</div>
                    </div>
                ) : (
                    ''
                )}
                {((isVisible || filtersItems.length > 0) && balanceTrue) ? (
                    <div className='my-5 alert alert-success mx-auto'>
                        <div className='alert-text fw-boldest fs-4 text-center'>{message}</div>
                    </div>
                ) : (
                    ''
                )
                }
            </div>}
            <div className='d-flex justify-content-lg-between align-items-center'>
                <div className="d-flex">
                    <div className='w-lg-700px cursor-pointer w-150px d-flex align-items-center mb-2 mt-2'>
                        <input
                            readOnly={missionSelected.isCloture !== 1 || missionSelected.isClotureProvisoire !== 1}
                            type="file"
                            className='form-control me-5'
                            accept=".xlsx, .csv, .xls"
                            onChange={handleFile}
                            required={true}
                        />
                    </div>
                    <div className="pt-3">
                        <Tooltip color='blue'
                                 title={isVisible ? "Masquer la balance enregistrée" : "Afficher la balance enregistrée"}>
                            <Button
                                size={"large"}
                                shape={"circle"}
                                className=""
                                icon={<FontAwesomeIcon icon={isVisible ? faEye : faEyeSlash}/>}
                                onClick={() => {
                                    setIsVisible(!isVisible)
                                }}
                            />
                        </Tooltip>
                    </div>
                </div>
                <div className="">
                    <Button
                        size="large"
                        type="primary"
                        // disabled={(missionSelected.isCloture === 1 || missionSelected.isClotureProvisoire ===1)}
                        onClick={handleImporterBalance}
                        style={{ marginRight: "20px", color: "white" }}
                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave} /> }
                    >
                        {!loading && <span className='indicator-label'>Enregistrer</span>}
                        {loading && (
                            <span className='indicator-progress fs-9' style={{display: 'block'}}>
                              Patientez svp...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </Button>
                </div>
            </div>
            {/* begin::Products */}
            <div className="table-responsive">
                {isVisible && <div className="card-header border-0 pt-2">
                    <SearchHeader handleSearch={handleSearchChange}/>
                </div>}
                <div className="py-4">
                    <div className="table-responsive">
                        <Table
                            // onRow={(record) => {
                            //     return {
                            //         onDoubleClick: (event) => {
                            //             handleOpenModal(1, record.numeroCompte)
                            //         }
                            //     };
                            // }}
                            rowClassName={(record) =>
                                `${record.error && record.error?.state === true ? `text-${record.error?.color}` : ''} ${
                                    record.isModified && record.isModified ? 'bg-light-warning' : ''
                                } cursor-pointer h-3px p-0 m-0 lh-0.5`
                            }
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={filtersItems}
                            // pagination={false}
                            loading={loading || chargement}
                            // scroll={{
                            //     y: 1000,
                            // }}
                            pagination={tableParams.pagination}
                            onChange={handleTableChange}
                            rowSelection
                            size={"small"}
                            summary={(pageData) => {
                                let totalAnouveauDebit = 0;
                                let totalAnouveauCredit = 0;
                                let totalMvtDebit = 0;
                                let totalMvtCredit = 0;
                                let totalSoldeCredit = 0;
                                let totalSoldeDebit = 0;

                                pageData.forEach(({ anouveauDebit, anouveauCredit, mvtDebit, mvtCredit, soldeDebit, soldeCredit}) => {
                                    totalAnouveauDebit += anouveauDebit;
                                    totalAnouveauCredit += anouveauCredit;
                                    totalMvtDebit += mvtDebit;
                                    totalMvtCredit += mvtCredit;
                                    totalSoldeDebit += soldeDebit;
                                    totalSoldeCredit += soldeCredit;
                                });
                                return (
                                    <>
                                        <Table.Summary fixed>
                                            <Table.Summary fixed>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}>
                                                        <Text>{''}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                    <Table.Summary.Cell className="fw-boldest" index={2}>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}}>{'TOTAL'}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                                    <Table.Summary.Cell index={4} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success" >{totalAnouveauDebit.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={5} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success">{totalAnouveauCredit}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={6} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success">{totalMvtDebit.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={7} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success">{totalMvtCredit.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={8} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success">{totalSoldeDebit.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={9} className='text-end'>
                                                        <Text style={{fontSize: "14px", fontWeight: 600, fontFamily: "Oswald"}} type="success">{totalSoldeCredit.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </Table.Summary>
                                        </Table.Summary>
                                    </>
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
        {isModalOpen && selectedModalId === 1 &&
            <div
                className="top-0 bottom-0 z-index-1000 h-100"
                style={{insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)'}}
            >
                <div className="modal fade show" id="kt_modal_move_to_folder" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}>
                    <div className='modal-dialog modal-dialog-centered mw-1000px' >
                        <BalanceEdit onClose={handleModalClose} balances={balanceData} setBalanceData={setBalancesData} plans={plans} numero={numero} balance={balance} setBalance={setBalance}/>
                    </div>
                </div>
            </div>
        }
    </>
    )
}

export  {Balance}