import axios from "axios";

const AJOUTER_MATERIALITE_PERFORMANCE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-materialite-performance`
const AJOUTER_NIVEAU_MATERIALITE_PERFORMANCE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-niveau-materialite`
const AJOUTER_MATERIALITE_SPECIFIQUE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-materialite-specifique`
const AJOUTER_CARACTERE_SIGNIFICATIF = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-caractere-significatif`
const AJOUTER_ANOMALIE_SIGNIFICATIF = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-anomalie-significatif`
const AJOUTER_EVALUATION_FINALE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-evaluation-finale`
const AJOUTER_FAIT_PAR = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-fait-par`
const LISTE_SEUIL_SIGNIFICATIF = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/liste-seuil-significatif`

export const ajouterMaterialitePerformance = async (data) => {
    return axios.post(AJOUTER_MATERIALITE_PERFORMANCE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterNiveauMaterialitePerformance = async (data) => {
    return axios.post(AJOUTER_NIVEAU_MATERIALITE_PERFORMANCE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterMaterialiteSpecifique = async (data) => {
    return axios.post(AJOUTER_MATERIALITE_SPECIFIQUE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterCaractereSignificatif = async (data) => {
    return axios.post(AJOUTER_CARACTERE_SIGNIFICATIF, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterAnomalieSignificatif = async (data) => {
    return axios.post(AJOUTER_ANOMALIE_SIGNIFICATIF, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterEvaluationFinale = async (data) => {
    return axios.post(AJOUTER_EVALUATION_FINALE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterStrategieFaitPar = async (data) => {
    return axios.post(AJOUTER_FAIT_PAR, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsSeuilSignificatif = async (exercice_id) => {
    return axios.get(`${LISTE_SEUIL_SIGNIFICATIF}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

