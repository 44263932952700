import React, { useEffect, useMemo, useState} from 'react';
import {
    ajoutMaintienAffectation, ajoutMaintienQuestion, getListeMaintienQuestion,
} from "../../../../../data/exercice";
import swal from "sweetalert";
import {succesMessage} from "../../../../../helpers/AssetsHelpers";
import {Button, Divider, Input, notification, Select, Skeleton, Space, Typography} from "antd";
import {Identification} from "../../Identification";
import QuestionnaireMaintien from "./EB2/QuestionnaireMaintien";
import DecisionMaintienRefus from "./EB2/DecisionMaintienRefus";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import useUsers from "../../../../../hooks/users/useUser";
import {faIdCard, faTasks, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import FileUploadQuestionnaire from "./EB1/FileUploadQuestionnaire";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import {appTheme} from "../../../../../config/theme";
import {Spinner} from "@chakra-ui/react";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import FormLabel from "../../../../common/Form/FormLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const { Title } = Typography;

const questionAcceptations = [
    {
        id: 1,
        libelleGroupe: "Le questionnaire initial d'acceptation de la mission a-t-il été revu, actualisé et joint au présent document ?",
        libelle: "Le questionnaire initial d'acceptation de la mission a-t-il été revu, actualisé et joint au présent document ?"
    },
    {
        id: 2,
        libelleGroupe: "De manière plus spécifique, les aspects suivants ont-ils été évalués :",
        libelle: "Les travaux d’audit ont-ils révélé des problèmes d’indépendance ?\n" +
            "Plus particulièrement,\n" +
            "•\tle cabinet est-il indépendant en matière des modalités de paiement d'honoraires, incluant tout arriéré significatif d'honoraires\n" +
            "•\tqu'aucun associé ou membre de l'équipe d'audit a des relations familiales ou d'affaires avec le client\n" +
            "•\tqu'aucun associé ou membre de l'équipe d'audit n'a d'intérêts financiers auprès du client\n"
    },
    {
        id: 3,
        libelleGroupe: "De manière plus spécifique, les aspects suivants ont-ils été évalués :",
        libelle: "Le cabinet a-t-il presté des services non audit durant la période de son mandat de commissaire ?"
    },
    {
        id: 4,
        libelleGroupe: "De manière plus spécifique, les aspects suivants ont-ils été évalués :",
        libelle: "Les travaux d’audit ont-ils révélé des problèmes concernant l’intégrité du client ?"
    },
    {
        id: 5,
        libelleGroupe: "De manière plus spécifique, les aspects suivants ont-ils été évalués :",
        libelle: "Les travaux d’audit ont-ils révélé des problèmes relatifs à la compétence du cabinet de révision ?"
    },
    {
        id: 6,
        libelleGroupe: "De manière plus spécifique, les aspects suivants ont-ils été évalués :",
        libelle: "Plus particulièrement, aucun litige entre le cabinet ou un membre de l'équipe d'audit et le client n'est-il survenu ou susceptible de survenir ?"
    },
    {
        id: 7,
        libelleGroupe: "De manière plus spécifique, les aspects suivants ont-ils été évalués :",
        libelle: "Existe-t-il des raisons de supposer qu’à l’avenir surgiront des problèmes susceptibles de compromettre les travaux d’audit courants ?"
    },
    {
        id: 8,
        libelleGroupe: "De manière plus spécifique, les aspects suivants ont-ils été évalués :",
        libelle: "Faut-il tenir compte d’autres aspects afin de déterminer si un renouvellement de la mission est ou non indiquée ?"
    },
];

const questionsRefus = [
    {
        id: 1,
        libelleGroupe: "Questionnaire de refus",
        libelle: "Le questionnaire initial d'acceptation de la mission a-t-il été revu, actualisé et joint au présent document ?"
    },
    {
        id: 2,
        libelleGroupe: "Questionnaire de refus",
        libelle: "Sommes-nous toujours indépendants vis-à-vis de notre client (faire mention le cas échéant des motifs personnels graves existant) ?"
    },
    {
        id: 3,
        libelleGroupe: "Questionnaire de refus",
        libelle: "Des entraves ont-elles été mises à l’exercice de notre mission (en l’absence de motifs personnels graves) ?"
    },
    {
        id: 4,
        libelleGroupe: "Questionnaire de refus",
        libelle: "Nous voyons-nous confrontés à d’importantes divergences d’opinion avec la direction ?"
    },
];

const MaintienMission = ({
             mission,
             client,
             code,
             // modalId,
             prev
     }) => {

    const users = useUsers();
    const [activeStep, setActiveStep] = useState(1);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectYear, setSelectYear] = useState(mission?.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission);
    const [isProvisoire, setIsProvisoire] = useState('1');
    
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);

    const [affectValid, setAffectValid] = useState(false);
    const [maintenir, setMaintenir] = useState(false);
    const [acceptLoading, setAcceptLoading] = useState(true);
    const [isFileAdd, setIsFileAdd] = useState(false)
    const [associe_responsable, setAssocieResponsable] = useState(null);
    const [second_associer, setSecondAssocier] = useState(null);
    const [acceptData, setAcceptData] = useState([])
    const [refusData, setRefusData] = useState([])
    const [observation_generale, setObservation_generale] = useState("")
    const [conclusion, setConclusion] = useState(
        "Tenant compte des réponses mentionnées ci-dessus et considérant ma connaissance actuelle des faits, j’estime pouvoir offrir à nouveau nos services au client dans le cadre de la mission proposée et des conséquences prévisibles pour notre cabinet de révision.\n" +
        "\n" +
        "Nous sommes convaincus que le processus de renouvellement de la mission auprès du client peut être poursuivi.\n"
    );
    const conclusionText = "Tenant compte des réponses mentionnées ci-dessus et considérant ma connaissance actuelle des faits, j’estime pouvoir offrir à nouveau nos services au client dans le cadre de la mission proposée et des conséquences prévisibles pour notre cabinet de révision.\n" +
        "\n" +
        "Nous sommes convaincus que le processus de renouvellement de la mission auprès du client peut être poursuivi.\n"

    const codeSommaire = useMemo(() => code || 'EB2', [code]);

    useEffect(() => {
        setAffectValid(missionSelected?.etat === 2 && (missionSelected && missionSelected?.lettre_mission === 1))
        setIsFileAdd(missionSelected?.fichier_question === 1)
        setAssocieResponsable(missionSelected.associe_responsable)
        setSecondAssocier(missionSelected.second_associer)
    }, [missionSelected])

    useEffect(() => {
        if (activeStep === 0) {
            const fetchIdentifications = async () => {
                const res = await getListeIdentificationSommaire(missionSelected.id, codeSommaire);
                setIdentifications(res.result);
            };

            fetchIdentifications();
        }
    }, [activeStep, codeSommaire, missionSelected]);

    useEffect(() => {
        setLoading(true);
        const fetchMaintienData = async () => {
            getListeMaintienQuestion(missionSelected.id)
                .then((res) => {
                    if(res){
                        if(res.Lea_ExerciceMaintienQuestions && res.Lea_ExerciceMaintienQuestions.length > 0){
                            setAcceptLoading(!res.Lea_ExerciceMaintienQuestions[0]?.isAccept)
                            setMaintenir(res.Lea_ExerciceMaintienQuestions[0]?.isAccept)

                            const datas = res.Lea_ExerciceMaintienQuestions.map((item) => ({
                                question_id: item.question_id,
                                libelle: item.libelle,
                                isCheckedN: item.reponseN || false,
                                isCheckedN_1: item.reponseN_1 || false,
                                motifRequired: false,
                                motif: item.commentaire,
                            }))

                            setAcceptData(datas)

                            setConclusion(res.conclusion || "")
                            setObservation_generale(res.observation_generale || "")
                            setAssocieResponsable(res.associe_responsable || null)
                            setSecondAssocier(res.second_associer || null)
                            setLoading(false);
                        }
                        setLoading(false);
                    }
                })
                .catch((error) => {
                    console.error(error.message)
                    setLoading(false);
                })
        }

        if(missionSelected){
            fetchMaintienData()
        }
    }, [missionSelected]);

    const handleCheckboxChange = (isChecked) => {
        setLoading(true)
        setTimeout(() => {
            setMaintenir(isChecked);
            setAcceptLoading(true)
            setAcceptLoading(false)
            setLoading(false)
        }, 1000)
    };

    const handleNextStep = (e) => {
        e.preventDefault();
        if(isFileAdd){
            setActiveStep((prevStep) => prevStep + 1);
        }
    };

    const handlePrevStep = (e) => {
        e.preventDefault();
        setActiveStep((prevStep) => prevStep - 1);
    };

    const isLastStep = () => {
        return activeStep === formSteps.length - 1;
    };

    const handleSubmit = async () => {
        setLoading(true);

        const isRequired = acceptData.some(text => text.motifRequired === true);
        if(isRequired){
            notification.error({
                description: "Veiller renseigner tout les champs obligatoire !",
            });
            setLoading(false);
            return;
        }

        const dataQuestion = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            exercice_id: mission.id,
            client_id: client.id,
            datas: acceptData,
        };

        const dataAffection = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            conclusion,
            isAccept: maintenir,
            exercice_id: mission.id,
            client_id: client.id,
            associe_responsable,
            second_associer,
            observation_generale
        };

        const {status, message} = activeStep === 2 ? await ajoutMaintienQuestion(dataQuestion) : await ajoutMaintienAffectation(dataAffection)
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: <Identification identifications={identifications} mission={missionSelected} />,
        },
        {
            code: "EB2.0",
            title: `Formulaire de maintien - ${selectYear}`,
            icon: faTasks,
            content: (
                <FileUploadQuestionnaire
                    setIsFileAdd={setIsFileAdd}
                    isFileAdd={isFileAdd}
                    exercice={missionSelected}
                    client={client}
                    codeSommaire={codeSommaire}
                    chargement={loading}
                />
            ),
        },
        {
            code: "EB2.2",
            title: `Questionnaire de maintien - ${selectYear}`,
            icon: faTasks,
            content: (
                <QuestionnaireMaintien
                    mission={missionSelected}
                    questions={questionAcceptations}
                    acceptLoading={acceptLoading}
                    setQuestionData={setAcceptData}
                    conclusion={conclusion}
                    setConclusion={setConclusion}
                    questionsData={acceptData}
                    chargement={loading}
                    affectValid={affectValid}
                />
            ),
        },
        {
            code: "EB2.4",
            title: `Décision et affection du dossier - ${selectYear}`,
            icon: faTasks,
            content: (
                <>
                <div className="px-3 pt-15">
                    <div className="card-header">
                        <div className="card-title">
                            <Title style={{ display: "block", fontWeight: '600', color: appTheme.colors.primary_blue}} level={3}>EB2.2: Décision et affectation du dossier</Title>
                        </div>
                    </div>
                    <div className="my-10">
                        <div className="card-title mb-2">
                            <Title style={{ fontSize: "24px", display: "block", fontWeight: '600', color: appTheme.colors.primary}} level={5}># A mon avis nous devrions:</Title>
                        </div>
                        <div className="row g-9" data-kt-buttons="true"
                             data-kt-buttons-target="[data-kt-button='true']">
                            <div className="col">
                                <label className={`btn btn-outline btn-outline-dashed btn-outline-${maintenir ? 'primary text-hover-white' : 'default'} d-flex text-start p-6`} data-kt-button="true">
                                <span className="form-check form-check-custom form-check-sm align-items-start mt-1">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="campaign_gender_1"
                                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                        checked={maintenir}
                                        onChange={(e) =>{
                                            handleCheckboxChange(e.target.checked)
                                            setConclusion(conclusionText)
                                        }}
                                    />
                                </span>
                                    <span className="ms-5">
                                    <span style={{fontSize: "24px", fontWeight: "700", fontFamily: "Oswald"}} className={`text-gray-800 text-hover-white d-block`}>MAINTENIR</span>
                                </span>
                                </label>
                            </div>
                            <div className="col">
                                <label className={`btn btn-outline btn-outline-dashed btn-outline-${!maintenir ? 'danger' : 'default'} d-flex text-start p-6`} data-kt-button="true">
                                <span className="form-check form-check-custom form-check-sm align-items-start mt-1">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="accept"
                                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                        checked={!maintenir}
                                        onChange={(e) => {
                                            handleCheckboxChange(!e.target.checked)
                                            setConclusion("")
                                            setAssocieResponsable("")
                                            setSecondAssocier("")
                                        }}
                                    />
                                </span>
                                    <span className="ms-5">
                                    <span style={{fontSize: "24px", fontWeight: "700", fontFamily: "Oswald"}} className={`text-gray-800 text-hover-white d-block`}>REFUSER</span>
                                </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className='align-center'>
                        <div className='w-100 p-5'>
                            <div className="">
                                {loading ?
                                        <Skeleton active />
                                            :
                                    (
                                        <>
                                            {maintenir ?
                                                <div className="d-flex flex-column my-7">
                                                    <FormLabel required label="Conclusion :"/>
                                                    <Input.TextArea
                                                        size="large"
                                                        placeholder="Saisissez les raisons de refus"
                                                        required={(!maintenir && !conclusion)}
                                                        value={conclusion}
                                                        readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                                        onChange={(e) => {
                                                            setConclusion(e.target.value);
                                                        }}
                                                        style={{width: "100%", border: (!maintenir && !conclusion) ? "1px solid red" : "0"}}
                                                        showCount
                                                        maxLength={5000}
                                                        autoSize={{
                                                            minRows: 6,
                                                            maxRows: 10,
                                                        }}
                                                    />
                                                    {(!maintenir && !conclusion) && (
                                                        <div className="text-danger">Champ obligatoire *</div>
                                                    )}
                                                </div>
                                                :
                                                <DecisionMaintienRefus
                                                    mission={missionSelected}
                                                    questions={questionsRefus}
                                                    loading={loading}
                                                    acceptLoading={acceptLoading}
                                                    setQuestionData={setRefusData}
                                                    conclusion={conclusion}
                                                    setConclusion={setConclusion}
                                                    questionsData={refusData}
                                                    maintenir={maintenir}
                                                    affectValid={affectValid}
                                                />}
                                        </>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    <Divider/>
                    {maintenir && <div className="w-100">
                        <div className="card-title mb-2">
                            <Title style={{
                                fontSize: "24px",
                                display: "block",
                                fontWeight: '600',
                                color: appTheme.colors.primary
                            }} level={5}># Affectation du dossier:</Title>
                        </div>
                        <div className="d-flex">
                            <div className="col-lg-1">
                                <FontAwesomeIcon icon={faUserCircle} size={"3x"} color={appTheme.colors.primary_blue}/>
                            </div>
                            <div className="col-lg-6">
                                <Space.Compact block>
                                    <Input
                                        size="large"
                                        style={{
                                            width: '30%', fontWeight: "400"
                                        }}
                                        value="ASSOCIE RESPONSABLE"
                                        readOnly={true}
                                    />
                                    {((mission.isCloture === 1 || mission.isClotureProvisoire === 1) || affectValid) ?
                                        (
                                            <Input
                                                size="large"
                                                style={{
                                                    width: '70%', fontWeight: "700", textTransform: "uppercase"
                                                }}
                                                value={`${(users.users?.find((user) => user.id === associe_responsable)?.initial || "")} - ${(users.users?.find((user) => user.id === associe_responsable)?.nom_user || "")} ${(users.users?.find((user) => user.id === associe_responsable)?.prenom_user || "")}`}
                                                readOnly={true}
                                            />
                                        )
                                        :
                                        (
                                            <Select
                                                showSearch
                                                placeholder="Sélectionnez l'associé..."
                                                optionFilterProp="label"
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                value={associe_responsable || null}
                                                readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                                options={users?.users?.map((user) => ({
                                                    value: user.id,
                                                    label: (<span
                                                        className="fw-boldest fs-5 text-uppercase">{`${user.initial || ""} - ${user.nom_user || ""} ${user.prenom_user || ""}`}</span>),
                                                }))}
                                                onChange={(value) =>
                                                    setAssocieResponsable(value)
                                                }
                                                size="large"
                                                style={{width: "70%"}}
                                            />
                                        )
                                    }
                                </Space.Compact>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <div className="d-flex">
                            <div className="col-lg-1">
                                <FontAwesomeIcon icon={faUserCircle} size={"3x"} color={appTheme.colors.primary_blue}/>
                            </div>
                            <div className="col-lg-6">
                                <Space.Compact block>
                                    <Input
                                        size="large"
                                        style={{
                                            width: '30%', fontWeight: "400"
                                        }}
                                        value="SECOND ASSOCIE"
                                        readOnly={true}
                                    />
                                    {((mission.isCloture === 1 || mission.isClotureProvisoire === 1) || affectValid) ?
                                        (
                                            <Input
                                                size="large"
                                                style={{
                                                    width: '70%', fontWeight: "700", textTransform: "uppercase"
                                                }}
                                                value={`${(users.users?.find((user) => user.id === second_associer)?.initial || "")} - ${(users.users?.find((user) => user.id === second_associer)?.nom_user || "")} ${(users.users?.find((user) => user.id === second_associer)?.prenom_user || "")}`}
                                                readOnly={true}
                                            />
                                        )
                                        :
                                        (
                                            <Select
                                                showSearch
                                                placeholder="Sélectionnez le second associé éventuel..."
                                                optionFilterProp="label"
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                value={second_associer || null}
                                                readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                                options={users?.users?.map((user) => ({
                                                    value: user.id,
                                                    label: (<span
                                                        className="fw-boldest fs-5 text-uppercase">{`${user.initial || ""} - ${user.nom_user || ""} ${user.prenom_user || ""}`}</span>),
                                                }))}
                                                onChange={(value) =>
                                                    setSecondAssocier(value)
                                                }
                                                size="large"
                                                style={{width: "70%"}}
                                            />
                                        )
                                    }
                                </Space.Compact>
                            </div>
                        </div>
                    </div>}
                </div>
                </>
            ),
        },
    ];

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EB2 - Maintien de la mission"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                />
                <StepHeader>
                    <ModalNavBarMenu setLoading={setLoading}  setActiveTab={setActiveStep} activeTab={activeStep} steps={formSteps} />
                </StepHeader>
                <StepBody>
                    {formSteps[activeStep].content}
                </StepBody>
                <div style={{ textAlign: "center" }}>
                    {activeStep  >= 1 &&
                        <Button
                            size="large"
                            onClick={handlePrevStep}
                            style={{marginRight: "20px"}}
                        >
                            Précédent
                        </Button>}
                    {!isLastStep() && <Button
                        size="large"
                        onClick={handleNextStep}
                        style={{marginRight: "20px", backgroundColor: appTheme.colors.primary_blue, color: "white"}}
                    >
                        Suivant
                    </Button>}
                    {activeStep > 1 && ( loading ? (
                            <Spinner ml={"10px"} />
                        ) : (
                            ((mission.isCloture !== 1 || mission.isClotureProvisoire !==1) || affectValid) && <Button
                                size="large"
                                type="primary"
                                onClick={handleSubmit}
                                style={{ marginRight: "20px" }}
                            >
                                Enregistrer
                            </Button>
                        )

                    )}
                    <Button
                        size="large"
                        danger
                        onClick={() => prev()}
                    >
                        Annuler
                    </Button>
                </div>
            </div>
        </>
    );
};

export {MaintienMission};
