import React, { useState } from "react";
import { appTheme } from "../../../config/theme";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ModiferMotPasse } from "../../../data/user";
import { requestPasswordSchema } from "../../../components/utilities/Utilities";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {Input} from "antd";
import FormLabel from "../../../components/common/Form/FormLabel";

const CountEdit = () => {
  const user = useSelector(state => state.user)
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(undefined)
  const navigate = useNavigate()

  const initialValues = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  }

  const formik = useFormik({
        initialValues,
        validationSchema: requestPasswordSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setHasErrors(undefined)
            try {
                const {status, message} = await ModiferMotPasse(values)
                if (status === 'success') {
                    // navigate('/profil')
                    setHasErrors(false)
                    setLoading(false)
                    await formik.setFieldValue('current_password', '')
                    await formik.setFieldValue('new_password', '')
                    await formik.setFieldValue('confirm_password', '')
                    await swal('Mot de passe modifié avec succès!', '', 'success')

                    // window.location.reload()
                } else {
                    setHasErrors(true)
                    setLoading(false)
                    await swal(message, '', 'error')
                    setSubmitting(false)
                    setStatus(message)
                }
            } catch (error) {
                console.error(error)
                setStatus(error)
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    const validatePasswordRules = (password) => {
        return {
            rule1: /^(?=.*[a-z])/.test(password),
            rule2: /^(?=.*[A-Z])/.test(password),
            rule3: /^(?=.*\d)/.test(password),
            rule4: /^(?=.*[@$!%*?&])/.test(password),
        };
    };

    const passwordRules = validatePasswordRules(formik.values.new_password);

  return (
    <>
      <div className="mb-5 mb-xl-10 w-100">
      {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
            ) : (
                ''
            )}

        <div className="d-flex flex-column flex-lg-row">
          <div className="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
            <div className="card  h-100">

              <div className="card-body p-9">
                <div
                    className="fs-2hx fw-bold"
                  style={{ color: appTheme.colors.secondary }}
                >
                  INFORMATION GENERALE
                </div>
                <div className="fs-4 fw-semibold text-gray-500 mb-7"></div>

                <div className="fs-6 d-flex justify-content-between mb-4">
                  <div
                    className="fw-semibold"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    NOM ET PRENOMS
                  </div>
                  <div className="d-flex fw-bold">
                    <i class="ki-duotone ki-arrow-up-right fs-3 me-1 text-success">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                   <h3>{user?.nom_user?user.nom_user:'-'} {user?.prenom_user?user.prenom_user:'-'}</h3>
                  </div>
                </div>

                <div className="separator separator-dashed"></div>

                <div className="fs-6 d-flex justify-content-between my-4">
                  <div
                    className="fw-semibold"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    CIVLITE 
                  </div>

                  <div className="d-flex fw-bold">
                    <span className="path1">
                      <h3>
                        {user?.libelleCivilite?user.libelleCivilite:'-'}
                      </h3>
                    </span>
                  </div>
                </div>

                <div className="separator separator-dashed"></div>

                <div className="fs-6 d-flex justify-content-between my-4">
                  <div
                    className="fw-semibold"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    HABITATION
                  </div>

                  <div className="d-flex fw-bold">
                    <h3>{user?.habitation_user?user.habitation_user:'-'}</h3>
                  </div>
                </div>
                <div className="separator separator-dashed"></div>
                <div className="fs-4 fw-semibold text-gray-500 mb-7"></div>

                <div
                  className="fs-2hx fw-bold"
                  style={{ color: appTheme.colors.secondary }}
                >
                  INFORMATIONS PROFESSIONNELLES
                </div>
                <div className="fs-4 fw-semibold text-gray-500 mb-7"></div>

                <div className="separator separator-dashed"></div>
                <div className="fs-6 d-flex justify-content-between my-4">
                  <div
                    className="fw-semibold"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    PROFIL
                  </div>
                   <div className="d-flex fw-bold"><h3>{user?.libelleProfil?user.libelleProfil:'-'}</h3></div>
                </div>

                <div className="separator separator-dashed"></div>
                

                <div className="fs-6 d-flex justify-content-between my-4">
                  <div
                    className="fw-semibold"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    LOGIN
                  </div>

                  <div className="d-flex fw-bold"><h3>{user?.login_user?user?.login_user:'-'}</h3></div>
                </div>
                <div className="separator separator-dashed"></div>

                <div className="fs-6 d-flex justify-content-between my-4">
                  <div
                    className="fw-semibold"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    CABINET
                  </div>

                  <div className="d-flex fw-bold"><h3>{user?.nom_cabinet?user?.nom_cabinet:'-'}</h3></div>
                </div>
                <div className="separator separator-dashed"></div>

                <div className="fs-4 fw-semibold text-gray-500 mb-7"></div>

                <div
                  className="fs-2hx fw-bold"
                  style={{ color: appTheme.colors.secondary }}
                >
                  MES CONTACTS
                </div>
                <div className="fs-4 fw-semibold text-gray-500 mb-7"></div>
                <div className="fs-6 d-flex justify-content-between mb-4">
                  <div
                    className="fw-semibold"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    TELEPHONE 1
                  </div>
                  <div className="d-flex fw-bold"><h3>{user?.telephone1_user?user?.telephone1_user:'-'}</h3></div>
                </div>
                <div className="fs-6 d-flex justify-content-between mb-4">
                  <div
                    className="fw-semibold"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    TELEPHONE 2
                  </div>
                  <div className="d-flex fw-bold">{user?.telephone1_user?user?.telephone1_user:'-'}</div>
                </div>
                <div className="fs-6 d-flex justify-content-between mb-4">
                  <div
                    className="fw-semibold"
                    style={{ fontSize: "20px", fontWeight: "bold" }}
                  >
                    EMAIL
                  </div>  
                  <div className="d-flex fw-bold"><h3>{user?.email_user?user?.email_user:'-'}</h3></div>

                </div>
              </div>
            </div>
          </div>
          
          <div className="flex-column flex-lg-row-auto w-100 w-lg-350px w-xl-400px mb-10 order-1 order-lg-2">
            <div className="card card-flush pt-3 mb-0" data-kt-sticky="true" data-kt-sticky-name="subscription-summary" data-kt-sticky-offset="{default: false, lg: '200px'}" data-kt-sticky-width="{lg: '250px', xl: '300px'}" data-kt-sticky-left="auto" data-kt-sticky-top="150px" data-kt-sticky-animation="false" data-kt-sticky-zindex="95">
                {/*begin::Card header*/}
                <div className="card-header">
                    {/*begin::Card title*/}
                    <div className="card-title">
                        <h2>MODIFIER VOTRE MOT DE PASSE</h2>
                    </div>
                    {/*end::Card title*/}
                </div>
                {/*end::Card header*/}

                {/*begin::Card body*/}
                <form 
                   className="card-body pt-0 fs-6"
                   id='reset_password_form'
                   onSubmit={formik.handleSubmit}
                >

                    <div className=' mb-10'>
                        <FormLabel label="Actuel mot de passe" required />
                        <Input.Password
                            size="large"
                            placeholder="input password"
                            {...formik.getFieldProps('current_password')}
                        />
                        {formik.touched.current_password && formik.errors.current_password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.current_password}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='mb-10'>
                        <FormLabel label="Nouveau mot de passe" required />
                        <Input.Password
                            size="large"
                            type='password'
                            {...formik.getFieldProps('new_password')}
                        />
                        <div className='mb-2'>
                            <div className={`d-flex align-items-center ${passwordRules.rule1 ? 'text-success' : 'text-danger'}`}>
                                <input className="me-3" type='checkbox' checked={passwordRules.rule1} readOnly /> Contient au moins une lettre minuscule
                            </div>
                            <div className={`d-flex align-items-center ${passwordRules.rule2 ? 'text-success' : 'text-danger'}`}>
                                <input className="me-3" type='checkbox' checked={passwordRules.rule2} readOnly /> Contient au moins une lettre majuscule
                            </div>
                            <div className={`d-flex align-items-center ${passwordRules.rule3 ? 'text-success' : 'text-danger'}`}>
                                <input className="me-3" type='checkbox' checked={passwordRules.rule3} readOnly /> Contient au moins un chiffre
                            </div>
                            <div className={`d-flex align-items-center ${passwordRules.rule4 ? 'text-success' : 'text-danger'}`}>
                                <input className="me-3" type='checkbox' checked={passwordRules.rule4} readOnly /> Contient au moins un caractère spécial
                            </div>
                        </div>
                        {formik.touched.new_password && formik.errors.new_password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.new_password}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className='mb-10'>
                        <FormLabel label="Confirmez le mot de passe" required />
                        <Input.Password
                            size="large"
                            type='password'
                            {...formik.getFieldProps('confirm_password')}
                        />
                        {formik.touched.confirm_password && formik.errors.confirm_password && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formik.errors.confirm_password}</span>
                                </div>
                            </div>
                        )}
                    </div>

                  <button 
                     id='reset_password_submit'
                     className='bg-primary text-white btn btn-lg btn-active-light-sucess fw-bolder me-4'
                     // style={{backgroundColor: '#d1ec51'}}
                     type="submit" 
                    >
                      <span className='indicator-label'>Modifier</span>
                       {loading && (
                          <span className='indicator-progress'>
                            Veillez patientez...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                  </button>
                  <Link to='/profil'>
                        <button
                            type='button'
                            id='reset_password_cancel_button'
                            className='bg-danger text-white btn btn-lg btn-color-dark fw-bolder'
                            // style={{backgroundColor: '#d1ec51', opacity: '0.3'}}
                            disabled={formik.isSubmitting || !formik.isValid}
                        >
                            Annuler
                        </button>
                    </Link>{' '}
                </form>
                {/*end::Card body*/}
            </div>
          </div>
         
        </div>
      </div>
    </>
  );
};

export { CountEdit };
