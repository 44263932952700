import {Route, Routes, Outlet} from 'react-router-dom'
import {PageTitle} from "../../components/layout/pageData/PageData";
import Assertion from "./assertion/assertion";
import CivilitePage from "./civilite/CivilitePage";
import Cycle from "./cycle/Cycle";
import EvenementSourceDeRisque from "./evenement_source_de_risque/EvenementSourceDeRisque";
import Intervention from "./intervention/intervention";
import ImpactFinancier from "./impact_financier/ImpactFinancier";
import NiveauDeRisque from "./niveau_de_risque/NiveauDeRisque";
import PeriodePage from "./periode/PeriodePage";
import ProbabiliteDeSurvenance from "./probabilite_de_survenance/ProbabiliteDeSurvenance";
import QuestionnaireGeneral from "./questionnaire_generale/QuestionnaireGeneral";
import ProgrammeDeTravail from "./programme_de_travail/ProgrammeDeTravail";
import RisqueInherent from "./risque_inherent/RisqueInherent";
import SecteurActivite from "./secteur_activite/SecteurActivite";
import Sommaire from "./sommaire/sommaire";
import StatutPage from "./statut/StatutPage";
import ProfilsList from "../users/profils/profil/ProfilsList";
import TacheAutorisee from "./tache_autorisee/TacheAutorisee";
import RolesList from "./roleMission/RolesList";
import PlanComptableList from "./planComptable/PlanComptable";
import QuestionnaireAutre from "./questionnaire_generale/QuestionnaireAutre";
import {NormesISAAll} from "./normes/norme";


const ParametrePage = () => {

    const Breadcrumbs = [
        {
            title: 'Accueil',
            path: '/tableau-de-bord',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ]

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='profil-utilisateur'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des profils</PageTitle>
                            <ProfilsList />
                        </>
                    }
                />
                <Route
                    path='assertion'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des assertions</PageTitle>
                            <Assertion />
                        </>
                    }
                />
                <Route
                    path='civilite'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des civilités</PageTitle>
                            <CivilitePage/>
                        </>
                    }
                />
                <Route
                    path='secteur-activite'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des secteurs d'actvité</PageTitle>
                            <SecteurActivite/>
                        </>
                    }
                />
                <Route
                    path='statut-juridique'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des status juridique</PageTitle>
                            <StatutPage/>
                        </>
                    }
                />
                <Route
                    path='cycle'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des cycles</PageTitle>
                            <Cycle/>
                        </>
                    }
                />
                <Route
                    path='risque/evennement'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des évennements sources de risque</PageTitle>
                            <EvenementSourceDeRisque/>
                        </>
                    }
                />
                <Route
                    path='intervention'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des interventions</PageTitle>
                            <Intervention/>
                        </>
                    }
                />
                <Route
                    path='impact-financier'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des impacts finacier</PageTitle>
                            <ImpactFinancier/>
                        </>
                    }
                />
                <Route
                    path='risque/niveau'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des niveaux de risque</PageTitle>
                            <NiveauDeRisque/>
                        </>
                    }
                />
                <Route
                    path='risque-inherent'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des risques inhérent</PageTitle>
                            <RisqueInherent/>
                        </>
                    }
                />
                <Route
                    path='periode'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des périodes</PageTitle>
                            <PeriodePage/>
                        </>
                    }
                />
                <Route
                    path='probabilité'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des probabilités de survenance</PageTitle>
                            <ProbabiliteDeSurvenance/>
                        </>
                    }
                />
                <Route
                    path='programme'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des programme de travail</PageTitle>
                            <ProgrammeDeTravail/>
                        </>
                    }
                />
                <Route
                    path='questionnaire'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des questionnaire général</PageTitle>
                            <QuestionnaireGeneral/>
                        </>
                    }
                />
                <Route
                    path='questionnaire-autre'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des questionnaire d'affectation et de maintien</PageTitle>
                            <QuestionnaireAutre/>
                        </>
                    }
                />
                <Route
                    path='sommaire'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des sommaires</PageTitle>
                            <Sommaire/>
                        </>
                    }
                />
                <Route
                    path='tache_autorisee'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des tâches autorisées</PageTitle>
                            <TacheAutorisee/>
                        </>
                    }
                />
                <Route
                    path='titre_intervention'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Liste des titres d'interventions pertinence</PageTitle>
                            <RolesList/>
                        </>
                    }
                />
                <Route
                    path='plan-comptable'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>PLAN COMPTABLE SYSCOHADA REVISE</PageTitle>
                            <PlanComptableList/>
                        </>
                    }
                />
                <Route
                    path='normes-isa'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Normes ISA</PageTitle>
                            <NormesISAAll/>
                        </>
                    }
                />
            </Route>
        </Routes>
    )
}

export default ParametrePage
