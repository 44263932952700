import React, {useEffect, useState} from "react";
import { Identification } from "../../Identification";
import { TraitementIdentification } from "./identification/TraitementIdentification";
import swal from "sweetalert";
import {SOMMAIRE_RISQUE_LIBELLE} from "../../../../utilities/Utilities";
import {
    ajouterRisqueIdentification,
    getDetailsRisqueIdentificationActivite,
    getDetailsRisqueIdentificationFraude,
    getDetailsRisqueIdentificationInformatique,
} from "../../../../../data/risqueIdentification";
import { getListeIdentificationSommaire } from "../../../../../data/rateau";
import {faIdCard, faSave, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {Button, Input, notification, Tooltip} from "antd";
import {appTheme} from "../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PrintRisqueIdentification from "./identification/PrintRisqueIdentification";
import FormLabel from "../../../../common/Form/FormLabel";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const RisqueIdentification = ({
      mission,
      client,
      code,
      modalId,
      prev
    }) => {
    // const user = useSelector(state => state.user)
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [codeSommaire, setCodeSommaire] = useState(code || 'ED1.1');
    const [codeSommaireCycle, setCodeSommaireCycle] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [normeCode, setNormeCode] = useState('');
    const [selecteData, setSelectData] = useState([]);
    const [isAddMemoire, setIsAddMemoire] = useState("");
    const [libelle, setLibelle] = useState('');
    const [datas, setDatas] = useState([]);
    const [title, setTitle] = useState("");
    const [observation, setObservation] = useState("");
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setNormeCode(`ED${modalId}`)
        setCodeSommaireCycle(`ED${modalId}`)
        if(modalId === 1){
            getDetailsRisqueIdentificationActivite(missionSelected.id)
                .then((res) => {
                    setDatas(res.result)
                })

            setTitle('ED1.1 - Identification risques liés à l\'activité');
            setCodeSommaire("ED1.1")
            setLibelle(SOMMAIRE_RISQUE_LIBELLE.ACTIVITE)
        }
        else if(modalId === 2){
            getDetailsRisqueIdentificationFraude(missionSelected.id)
                .then((res) => {
                    setDatas(res.result)
                })

            setTitle('ED2.1 - Identification risques liés à la fraude et aux erreurs');
            setCodeSommaire("ED2.1");
            setLibelle(SOMMAIRE_RISQUE_LIBELLE.FRAUDE)
        }
        else if(modalId === 3){
            getDetailsRisqueIdentificationInformatique(missionSelected.id)
                .then((res) => {
                    setDatas(res.result)
                })

            setTitle('ED3.1 - Identification risques liés à l\'informatique');
            setCodeSommaire("ED3.1");
            setLibelle(SOMMAIRE_RISQUE_LIBELLE.INFORMATIQUE)
        }
    }, [missionSelected, modalId])

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(mission.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [activeTab, mission, codeSommaire])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);


    const handleSubmit = async () => {
        setLoading(true);

        const isRequired = selecteData.some(select => {
            return select.risquesDetails.some(risque => {
                return risque.risqueIncidences.some(risqueIncid => {
                    return risqueIncid.risquesAssertions.some(risqueAssert => {
                        return (!risqueIncid.incidence) || (risqueAssert.assertions.length <= 0) || (risqueAssert.postes.length <= 0);
                    });
                });
            });
        });

        if(isRequired){
            notification.error({
                description: "Veuillez renseigner tout les champs obligatoires",
            });
            setLoading(false);
            return;
        }

        const risquesData = []
        selecteData.forEach((item) => {
            item.risquesDetails.forEach((event) => {
                risquesData.push(event)
            })
        })

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            isAddMemoire,
            codeSommaire,
            codeSommaireCycle,
            observation,
            risquesData,
            isProvisoire,
            libelle,
            exercice_id: missionSelected.id,
            client_id: client.id,
        };

        const {status, message} = await ajouterRisqueIdentification(data)
        if (status === 'success') {
            setLoading(false)
            setAuth(false);
            await swal(`Opération effectuée avec succès`, '', 'success');
            setIsTaskSave(prevState => (prevState ?? []).filter((item) => item !== activeTab));
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "CB",
            title: `TRAITEMENT - ${selectYear}`,
            description: `TRAITEMENT - ${selectYear}`,
            icon: faTasks,
            content: (
                <TraitementIdentification
                    selecteData={selecteData}
                    setSelectData={setSelectData}
                    setIsAddMemoire={setIsAddMemoire}
                    setDatas={setDatas}
                    datas={datas}
                    id={modalId}
                    libelleRisque={libelle}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
        modalId === 3 && {
            code: "OG",
            title: `OBSERVATION GENERALE - ${selectYear}`,
            description: `OBSERVATION GENERALE - ${selectYear}`,
            icon: faTasks,
            content: (
                <div className="card d-flex flex-column fv-plugins-bootstrap5 pt-2 fv-plugins-framework">
                    <div className="d-flex flex-column mb-5 mx-2">
                        <div className="d-flex flex-column mb-3 mx-2">
                            <FormLabel label="Observation générales:" />
                            <Input.TextArea
                                style={{fontSize: "14px"}}
                                size="large"
                                placeholder="Saisisser l'observation..."
                                maxLength={5000}
                                showCount
                                autoSize={{
                                    minRows: 8,
                                    maxRows: 10,
                                }}
                                value={observation}
                                onChange={(e) => setObservation(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            ),
        },
    ];


    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={title}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isTaskSave={isTaskSave}
                    isExcelPrint={true}
                    isLandingScape={true}
                    printTitle={(`${title} ${selectYear}`)}
                    excelData={selecteData}
                    printContent={(
                        <PrintRisqueIdentification
                            datas={selecteData}
                        />
                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>
                        <div className="d-flex justify-content-end align-items-end mb-10">
                            <Tooltip title="Enregistrer">
                                <Button
                                    disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                                    size={"large"}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: appTheme.colors.secondary,
                                        color: "white"
                                    }}
                                    icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                    onClick={handleSubmit}
                                >
                                    {!loading &&
                                        <span className='indicator-label text-white'>Enregistrer</span>}
                                    {loading && (
                                        <span className='indicator-progress fs-9 text-white'
                                              style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </Button>
                            </Tooltip>
                        </div>
                        {formSteps[activeTab].content}
                    </>
                </StepBody>
            </div>
        </>
    );
 }

export {RisqueIdentification}