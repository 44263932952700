import React, {useEffect, useState} from "react";
import {Button, Input, Tooltip, Typography} from "antd";
import {getAideMemoire} from "../../../../../../data/exercice";
import PageChildLabel from "../../../../../common/PageLabel/PageChildLabel";
import {Spinner} from "@chakra-ui/react";
import {appTheme} from "../../../../../../config/theme";
import FormLabel from "../../../../../common/Form/FormLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

const menus = [
    { key: "EG1.1", title: "Questionnaire Général relatif aux SA: Régularité des réunions du conseil d'Administration" },
    { key: "EG1.2", title: "Questionnaire Général relatif aux SA: Régularité des AGO" },
    { key: "EG1.3", title: "Questionnaire Général relatif aux SA: Contrôle du respect du droit de communication" },
    { key: "EG1.4", title: "Questionnaire Général relatif aux SA: Contrôle des conventions réglementées" },
    { key: "EG1.5", title: "Questionnaire Général relatif aux SA: Contrôle des conventions interdites" },
    { key: "EG1.6", title: "Questionnaire Général relatif aux SA: Appréciation du rapport de Gestion" },
    { key: "EG1.7", title: "Questionnaire Général relatif aux SA: Contrôle du registre des titres nominatifs" },
    { key: "EG1.8", title: "Questionnaire Général relatif aux SA: Contrôle des pactes d'actionnaires" },
    { key: "EG1.9", title: "Questionnaire Général relatif aux SA: Procédures d'alerte" },
    { key: "EG1.10", title: "Questionnaire Général relatif aux SA: Contrôle des cessions de biens des actionnaires à la société" },
    { key: "EG2.1", title: "Questionnaire Général relatif aux SARL: Régularité des AGO" },
    { key: "EG2.2", title: "Questionnaire Général relatif aux SARL: Contrôle du respect du droit de communication" },
    { key: "EG2.3", title: "Questionnaire Général relatif aux SARL: Contrôle des conventions réglementées" },
    { key: "EG2.4", title: "Questionnaire Général relatif aux SARL: Contrôle des conventions interdites" },
    { key: "EG2.5", title: "Questionnaire Général relatif aux SARL: Appréciation du rapport de Gestion" },
    { key: "EG2.6", title: "Questionnaire Général relatif aux SARL: Contrôle des pactes d'associés" },
];

const VerificationSpecifique = ({mission, chargement, hanldeValider, missionSelected}) => {
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [codeSommaire, setCodeSommaire] = useState("EG1.1");
    const [aideMemoire, setAideMemoire] = useState(null);
    const [conclusion, setConclusion] = useState("");

    useEffect(()=> {
        setLoading(true);
        async function fetchData() {
            const response = await getAideMemoire(mission.id, codeSommaire);
            if(response && response.length > 0) {
                setAideMemoire(response[0]);
                setConclusion(response[0]?.conclusion || "");
                setLoading(false);
            }else{
                setAideMemoire(null);
                setConclusion("");
                setLoading(false);
            }
        }
        fetchData();
    }, [mission, codeSommaire])

    const handleTabChange = (index, code) => {
        setLoading(true);
        setTimeout(() => {
            setCodeSommaire(code);
            setActiveTab(index);

            setLoading(false);
        }, 1000)
    };

    return (
        <>
            <div className="w-100 pb-15">
                <PageChildLabel label={"Vérifications spécifiques"}/>
                <div className="flex-lg-row-fluid mx-lg-10">
                    <div className="d-flex justify-content-between align-center">
                        <div className="d-flex flex-column align-center">
                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-5">
                                {menus.map((item, index) => (
                                    <li className="nav-item" key={index}>
                                        <Tooltip title={item?.title}>
                                            <button
                                                className={`nav-link text-active-primary pb-4 ${activeTab === index ? 'active' : ''}`}
                                                onClick={() => handleTabChange(index, item.key)}
                                            >
                                                <span className="fs-4 fw-bolder">
                                                    {item.key}
                                                </span>
                                            </button>
                                        </Tooltip>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stepper-form mx-10 mb-15">
                {(loading || chargement) ?
                    <div className="d-flex justify-content-center align-center">
                        <Spinner/>
                    </div>
                    :
                    <div className="tab-content" id="myTabContent">
                        {<div className="d-flex justify-content-end align-items-end mb-10">
                            <Tooltip title="Enregistrer">
                                <Button
                                    disabled={(missionSelected.isCloture === 1 || missionSelected.isClotureProvisoire === 1)}
                                    size={"large"}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: appTheme.colors.secondary,
                                        color: "white"
                                    }}
                                    icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                    onClick={() => hanldeValider({code: codeSommaire, conclusion: conclusion})}
                                >
                                    {!loading &&
                                        <span className='indicator-label text-white'>Enregistrer</span>}
                                    {loading && (
                                        <span className='indicator-progress fs-9 text-white'
                                              style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </Button>
                            </Tooltip>
                        </div>}
                        {menus.map((item, index) => (
                            <div
                                key={index}
                                className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                                id={`tab-${index}`}
                                role="tabpanel"
                            >
                                <div className="card-header">
                                    <div className="card-title">
                                        <Title style={{
                                            display: "block",
                                            fontWeight: '600',
                                            color: appTheme.colors.primary,
                                            // textAlign: "center"
                                        }} level={3}>{`${item.key} - ${item.title}`}</Title>
                                    </div>
                                </div>
                                <div className="my-5"></div>
                                <FormLabel label={`Conclusion : `} />
                                <div className="my-5"></div>
                                <Input.TextArea
                                    size="large"
                                    placeholder="Conclusion"
                                    value={conclusion}
                                    maxLength={5000}
                                    showCount
                                    autoSize={{
                                        minRows: 8,
                                        maxRows: 10,
                                    }}
                                    onChange={(e) => setConclusion(e.target.value)}
                                />
                            </div>
                        ))}
                    </div>

                }
            </div>
        </>
    );
}

export {VerificationSpecifique}