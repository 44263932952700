import React, {useCallback, useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { getListeTacheActive } from "../../../data/params/tache";
import moment from "moment";
import { getListeInterventionActive } from "../../../data/params/intervention";
import { getListeUserMission } from "../../../data/exercice";
import {Button, Col, Divider, Input, Menu, message as msg, Row, Select, Space, Typography} from "antd";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {toAbsoluteUrl} from "../../../helpers/AssetsHelpers";
import clsx from "clsx";
import {ClockCircleOutlined} from "@ant-design/icons";
import FormError from "../../common/Form/FormError";
import LoadingComponent from "../../modals/LoadingComponent";
import {faEye, faIdBadge, faUsersViewfinder} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Identification} from "./Identification";
import {getListeIdentificationSommaire} from "../../../data/rateau";
import PageLabel from "../../common/PageLabel/PageLabel";
import IdImage from "../../../assets/images/user-identification.PNG";
import {appTheme} from "../../../config/theme";

const { Title } = Typography;

const items = [
    {
        label: "Identification",
        icon: <FontAwesomeIcon icon={faIdBadge} />,
        key: "0",
    },
    {
        label: "Liste des identification",
        icon: <FontAwesomeIcon icon={faUsersViewfinder} />,
        key: "1", // Updated the key to match with UserIdentification
    },
];

const UserCycleIdentification = ({
         setDate,
         dateDebut,
         setHeureEntree,
         mission,
         codeSommaire,
         loading,
         setIsAuthorized,
         isAuthorized,
         setTache_id,
         setIntervention_id,
         setUser_id,
         setActiveStep,
     }) => {
    const [current, setCurrent] = useState("0");
    const user = useSelector((state) => state.user);
    const [taches, setTaches] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [role, setRole] = useState(null);
    const [chargement, setChargement] = useState(true);
    const [authLoad, setAuthLoad] = useState(false);
    const [identifications, setIdentifications] = useState([]);

    useEffect(() => {
        const date = new Date();
        setDate(date);
        setHeureEntree(moment(date).format("HH:mm:ss"));
    }, [setDate, setHeureEntree]);

    useEffect(() => {
        if(user){
            setUser_id(user?.id);
        }
    }, [setUser_id, user]);

    useEffect(() => {
        if (codeSommaire && current === "1") {
            getListeIdentificationSommaire(mission.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result);
                })
                .catch((err) => console.error('Error fetching identifications:', err));
        }
    }, [mission.id, codeSommaire, current]);

    useEffect(() => {
        if (user && mission) {
            getListeUserMission(user.id)
                .then((res) => {
                    if (res) {
                        const opt = res.find((item) => item.exercice_id === mission.id);
                        setRole(opt?.Lea_RoleMission || null);
                    }
                })
                .catch((err) => console.error('Error fetching user mission:', err));
        }
    }, [user, mission]);

    useEffect(() => {
        const fetchListeTache = () => {
            getListeTacheActive()
                .then((res) => {
                    if (res?.result?.length > 0) {
                        const userTaches = res.result.filter((item) =>
                            role?.actions?.includes(item.id)
                        );
                        setTaches(userTaches);
                    }
                })
                .catch((err) => console.error('Error fetching tasks:', err));
        };

        const fetchIntervention = () => {
            getListeInterventionActive()
                .then((res) => {
                    if (res?.result?.length > 0) {
                        const userIntervention = res.result.filter((item) =>
                            role?.intervention?.includes(item.id)
                        );
                        setInterventions(userIntervention);
                    }
                })
                .catch((err) => console.error('Error fetching interventions:', err));
        };

        if (role) {
            fetchListeTache();
            fetchIntervention();
        }
    }, [role]);

    // const handleValidate = async (value) => {
    //     // setChargement(true)
    //     const data = {
    //         intervention_id: value.intervention_id,
    //         tache_id: value.tache_id,
    //         codeSommaire,
    //         exercice_id: mission.id,
    //     };
    //
    //     const {status, message, result} = await ajouterUserIdentification(data);
    //     if (status === 'success') {
    //         setIsAuthorized(true);
    //         setAuthId(result.id)
    //         await swal(succesMessage, '', 'success')
    //     } else {
    //         setIsAuthorized(false);
    //         // setChargement(false)
    //         await swal(message, '', 'error')
    //     }
    // }

    const handleValidate = async (value) => {
        setAuthLoad(true)
        setTimeout(() => {
            setIsAuthorized(true)
            setTache_id(value.tache_id)
            setIntervention_id(value.intervention_id)
            setActiveStep(prevState => prevState + 1);
            msg.success(`Bienvenue ${user?.codeCivilite || ""} ${user?.nom_user || ''} ${user?.prenom_user || ""}`);
            setAuthLoad(false);
        }, 1500)
    }

    const onClick = useCallback((e) => {
        setCurrent(e.key);
    }, []);

    const dataValidationScheme = Yup.object().shape({
        tache_id: Yup.number().required("Veuillez sélectionner la tâche").typeError("La tâche est obligatoire"),
        intervention_id: Yup.number().required("Veuillez sélectionner l'intervention").typeError("L'intervention est obligatoire"),
    });

    const UserIdentification = ({taches, interventions}) => (
        <Row gutter={[16, 16]}>
            <Col xs={0} sm={0} md={0} lg={10} xl={10} xxl={10} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img src={IdImage} alt="Logo" className="w-75"/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                <div>
                    <Formik
                        initialValues={{
                            tache_id: "",
                            intervention_id: ""
                        }}
                        onSubmit={(values) => handleValidate(values)}
                        validationSchema={dataValidationScheme}
                    >
                        {({ setFieldValue, touched, errors, handleSubmit }) => (
                            <Form>
                                <Divider/>
                                <Row gutter={[16, 16]}>
                                    <Col span={16}>
                                        <div className='symbol symbol-circle symbol-100px overflow-hidden me-3'>
                                            {user?.avatar ? (
                                                <div className='symbol-label'>
                                                    <img
                                                        src={toAbsoluteUrl(`/media/${user.avatar}`)}
                                                        alt={`${user.nom_user} ${user.prenom_user}`}
                                                        className='w-100'
                                                    />
                                                </div>
                                            ) : (
                                                <div className={clsx('symbol-label fs-3', 'bg-light-green')}>
                                                    <img
                                                        src={toAbsoluteUrl(user.codeCivilite === 'M.' ? '/media/avatars/male.jpg' : '/media/avatars/female.jpg')}
                                                        alt={`${user.nom_user} ${user.prenom_user}`}
                                                        className='w-100'
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex flex-column">
                                        <span className="fs-3" style={{ color: "#24247a", fontWeight: "800" }}>
                                            {`${user.nom_user || ""} ${user.prenom_user || ""}`}
                                        </span>
                                            <span className="fw-bolder fs-5 text-black"> {role?.libelle} </span>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                    <span className='chart-blue display-block fs-6' style={{ fontWeight: 700 }}>
                                        <ClockCircleOutlined style={{ fontSize: '18px' }} />{' '}
                                        {moment().format('DD/MM/YYYY à HH:mm')}
                                    </span>
                                    </Col>
                                    <Col span={24}>
                                        <Space.Compact block>
                                            <Input
                                                style={{ width: '30%' }}
                                                value="Tâches"
                                                readOnly={true}
                                            />
                                            <Select
                                                showSearch
                                                placeholder="Sélectionnez la tâche..."
                                                optionFilterProp="label"
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                options={taches.map((tache) => ({
                                                    value: tache.id,
                                                    label: tache.libelle,
                                                }))}
                                                onChange={(value) => setFieldValue("tache_id", value)}
                                                size="large"
                                                style={{ width: "70%" }}
                                            />
                                        </Space.Compact>
                                        {touched.tache_id && errors.tache_id && (
                                            <FormError fieldName="tache_id" />
                                        )}
                                    </Col>
                                    <Col span={24}>
                                        <Space.Compact block>
                                            <Input
                                                style={{ width: '30%' }}
                                                value="Intervention"
                                                readOnly={true}
                                            />
                                            <Select
                                                showSearch
                                                placeholder="Sélectionnez l'intervention..."
                                                optionFilterProp="label"
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                options={interventions.map((intervention) => ({
                                                    value: intervention.id,
                                                    label: intervention.libelle,
                                                }))}
                                                onChange={(value) => setFieldValue("intervention_id", value)}
                                                size="large"
                                                style={{ width: "70%" }}
                                            />
                                        </Space.Compact>
                                        {touched.intervention_id && errors.intervention_id && (
                                            <FormError fieldName="intervention_id" />
                                        )}
                                    </Col>
                                    <Col span={24}>
                                        <Button
                                            type="primary"
                                            block
                                            size="large"
                                            loading={loading || authLoad}
                                            disabled={loading || authLoad}
                                            onClick={handleSubmit}
                                        >
                                            {!(loading || authLoad) && <span className='indicator-label'>Enregistrer</span>}
                                            {(loading || authLoad) && (
                                                <span className="indicator-progress text-white">
                                                Veuillez patienter...
                                                <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                            </span>
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Col>
        </Row>
    );

    const showCurrentPage = useCallback(() => {
        switch (current) {
            case "0":
                return (
                    !isAuthorized ?
                        <UserIdentification taches={taches} interventions={interventions} />
                        :
                        <>
                            <div className="d-flex flex-column align-center align-center align-items-center mt-10">
                                <Title style={{fontWeight: '600', color: appTheme.colors.success, textAlign: "center"}} level={3}>
                                    <span className="text-black me-2">Bienvenue {user?.codeCivilite || ""}</span>
                                    {user?.nom_user || ""} {user?.prenom_user || ""}
                                </Title>
                                <div className="d-flex flex-column align-items-center mt-3">
                                    <span>Role: {role?.libelle}</span>
                                    <span>Date d'identification: {moment(dateDebut).format('DD/MM/YYYY HH:mm')}</span>
                                    <span>Temps passé dans le cycle: {moment().diff(moment(dateDebut), 'minutes')} minutes</span>
                                </div>
                                <br/>
                            </div>
                        </>
                )
            case "1":
                return (
                    <Identification
                        identifications={identifications}
                        mission={mission}
                    />
                );
            default:
                return null;
        }
    }, [current, identifications, interventions, isAuthorized, mission, taches, user]);

    return (
        <>
            {(chargement && !isAuthorized) ? (
                <LoadingComponent
                    loading={chargement}
                    setLoading={setChargement}
                    message="Veuillez patienter, nous chargeons les ressources nécessaires au traitement de ce cycle."
                />
            ) : (
                <div className="px-5 py-5">
                    <PageLabel label="Identification des tâches et interventions des collaborateurs" />
                    <Menu
                        onClick={onClick}
                        selectedKeys={[current]}
                        mode="horizontal"
                        items={items}
                        style={{ marginBottom: "5px", fontWeight: "bold" }}
                    />
                    {showCurrentPage()}
                </div>
            )}
        </>
    );
};

export default UserCycleIdentification;

