import React from 'react';
import {postesStat} from "./TraitementIdentification";

const PrintRisqueIdentification = ({datas}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        minHeight: "20px"
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <div className="card">
            <div className="table-responsive mb-5">
                <div className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className=" w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={thStyle} className="w-150px" rowSpan={2} >Facteurs de risque inhérent</th>
                                <th style={thStyle} className="w-300px" rowSpan={2} >Évènement / Source du risque</th>
                                <th style={thStyle} className="w-300px" rowSpan={2}>Incidence (effet) du facteur de risque</th>
                                <th style={thStyle} className="w-200px" rowSpan={2} >Postes des états financiers concernés</th>
                                <th style={thStyle} className="w-200px" rowSpan={1} colSpan={5}>Assertions</th>
                            </tr>
                            <tr style={thStyle}>
                                <th style={thStyle} className="w-40px">C</th>
                                <th style={thStyle} className="w-40px">A</th>
                                <th style={thStyle} className="w-40px">E</th>
                                <th style={thStyle} className="w-40px">V</th>
                                <th style={thStyle} className="w-40px">I</th>
                                <th style={thStyle} className="w-40px">D</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(datas ?? []).map((rubrique, rubriqueIndex) => (
                                (rubrique?.risquesDetails ?? []).map((event, eventIndex) => (
                                    (event?.risqueIncidences ?? []).map((incidence, incidenceIndex) => (
                                        (incidence?.risquesAssertions ?? []).map((assertion, assertionIndex) => {
                                            const colSpanEvent = (incidenceIndex === 0 && assertionIndex === 0) ? event.risqueIncidences.length : 1;
                                            const colSpanIncidence = (assertionIndex === 0) ? incidence.risquesAssertions.length : 1;
                                            const rubriqueLibelle = (eventIndex === 0 && incidenceIndex === 0 && assertionIndex === 0) ? rubrique.rubrique_libelle : '';
                                            const eventLibelle = (incidenceIndex === 0 && assertionIndex === 0) ? event.eventLibelle : '';
                                            const incidenceValue = incidence.incidence || '';
                                            const postesValue = assertion.postes || [''];
                                            const C = assertion.assertions.find((opt) => opt === "C");
                                            const A = assertion.assertions.find((opt) => opt === "A");
                                            const E = assertion.assertions.find((opt) => opt.includes("E"));
                                            const V = assertion.assertions.find((opt) => opt === "V");
                                            const I = assertion.assertions.find((opt) => opt === "I");
                                            const D = assertion.assertions.find((opt) => opt === "D");

                                            return (
                                                <tr style={tdStyle} className="" key={`${rubriqueIndex}-${eventIndex}-${incidenceIndex}-${assertionIndex}`}>
                                                    {eventIndex === 0 && incidenceIndex === 0 && assertionIndex === 0 && (
                                                        <td style={tdStyle} rowSpan={rubrique.assertionLenght}>
                                                            {rubriqueLibelle}
                                                        </td>
                                                    )}
                                                    {incidenceIndex === 0 && assertionIndex === 0 && (
                                                        <td className="text-start" style={tdStyle} rowSpan={colSpanEvent} colSpan={colSpanIncidence}>
                                                            {eventLibelle || ""}
                                                        </td>
                                                    )}
                                                    {assertionIndex === 0 && (
                                                        <td className="text-start" style={tdStyle} rowSpan={colSpanIncidence}>
                                                            {incidenceValue}
                                                        </td>
                                                    )}
                                                    <td style={tdStyle} >
                                                        <div className='d-flex flex-column'>
                                                            {postesValue?.map((poste, posteIndex) => (
                                                                <div key={posteIndex} className='test-start'>
                                                                    {`${poste || ""} - ${(postesStat ?? []).find( opt => opt.key === poste)?.title}`}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {C || ""}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {A || ""}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {E || ""}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {V || ""}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {I || ""}
                                                    </td>
                                                    <td style={tdStyle}>
                                                        {D || ""}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ))
                                ))
                            ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrintRisqueIdentification;
