import axios from "axios";

const AJOUTER_RISQUE_FONCTION = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/ajouter-risque-fonction-controle`
const LISTE_RISQUE_FONCTION = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-fonction-controle`

export const ajouterRisqueFonction = async (data) => {
    return axios.post(AJOUTER_RISQUE_FONCTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueFonction = async (exercice_id) => {
    return axios.get(`${LISTE_RISQUE_FONCTION}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

