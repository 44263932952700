import axios from "axios";
import {
    AJOUTER_GRANDLIVRE, SELECT_GRANDLIVRE_DEFINITIF, SELECT_GRANDLIVRE_PROVISOIRE,
} from "../../api/apiRoutes";

export const ajouterGrandLivre = async (data) => {
    return axios.post(AJOUTER_GRANDLIVRE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getGrandLivreProvisoiresDetails = async (exercice_id) => {
    return axios.get(`${SELECT_GRANDLIVRE_PROVISOIRE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getGrandLivreDefinitifDetails = async (exercice_id) => {
    return axios.get(`${SELECT_GRANDLIVRE_DEFINITIF}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}