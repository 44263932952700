import React, {useEffect, useMemo, useState} from "react";
import { Conlusions } from "./AideMemoire/Conclusions";
import {EvaluationFonctionControle} from "./AideMemoire/EvaluationFonctionControle";
import {ProcedureAuditComplementaire} from "./AideMemoire/ProcedureAuditComplementaire";
import {VerificationSpecifique} from "./AideMemoire/VerificationSpecifique";
import {TravauxProfessionnel} from "./AideMemoire/TravauxProfessionnel";
import {QuestionFinMission} from "./AideMemoire/QuestionFinMission";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import {faIdCard, faTasks} from "@fortawesome/free-solid-svg-icons";
import {Identification} from "../../Identification";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import swal from "sweetalert";
import {succesMessage} from "../../../../../helpers/AssetsHelpers";
import {ajouterAideMemoire} from "../../../../../data/exercice";
import {notification} from "antd";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const AideMemoire = ({
         mission,
         client,
         code,
         // modalId,
         prev
     }) => {
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);

    const [activeTab, setActiveTab] = useState(0);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectYear, setSelectYear] = useState(mission?.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    const codeSommaire = useMemo(() => code || 'EH1', [code]);

    useEffect(() => {
        if (activeTab === 0) {
            const fetchIdentifications = async () => {
                const res = await getListeIdentificationSommaire(missionSelected.id, codeSommaire);
                setIdentifications(res.result);
            };

            fetchIdentifications();
        }
    }, [activeTab, codeSommaire, missionSelected]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const handleSubmit = async (value) => {
        if(!value){
            notification.warning({
                message: "code sommaire ou conclusion indisponible ! "
            })
            return;
        }

        setLoading(true);

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            exercice_id: missionSelected.id,
            codeSommaire: value.code,
            conclusion: value.conclusion,
            codeSommaireCycle: code,
            client_id: missionSelected.client_id,
            isProvisoire: true
        }

        const {status, message} = await ajouterAideMemoire(data)
        if (status === 'success') {
            setLoading(false)
            setAuth(false);
            await swal(succesMessage, '', 'success')
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = useMemo(() => ([
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "SC",
            title: `Synthèse des conclusions - ${selectYear}`,
            icon: faTasks,
            content: <Conlusions missionSelected={missionSelected} hanldeValider={handleSubmit} mission={missionSelected} chargement={loading} />,
        },
        {
            code: "EF",
            title: `Evaluation du fonctionnement des contrôles - ${selectYear}`,
            icon: faTasks,
            content: <EvaluationFonctionControle missionSelected={missionSelected} hanldeValider={handleSubmit} code={codeSommaire} mission={missionSelected} chargement={loading} />,
        },
        {
            code: "PA",
            title: `Procédures d'audit complémentaire - ${selectYear}`,
            icon: faTasks,
            content: <ProcedureAuditComplementaire missionSelected={missionSelected} hanldeValider={handleSubmit} mission={missionSelected} chargement={loading} />,
        },
        {
            code: "VS",
            title: `Vérifications spécifiques - ${selectYear}`,
            icon: faTasks,
            content: <VerificationSpecifique missionSelected={missionSelected} hanldeValider={handleSubmit} mission={missionSelected} chargement={loading} />,
        },
        {
            code: "TP",
            title: `Travaux d'autres professionnels - ${selectYear}`,
            icon: faTasks,
            content: <TravauxProfessionnel missionSelected={missionSelected} hanldeValider={handleSubmit} mission={missionSelected} chargement={loading} />,
        },
        {
            code: "CF",
            title: `Contrôle de fin de mission - ${selectYear}`,
            icon: faTasks,
            content: <QuestionFinMission missionSelected={missionSelected} hanldeValider={handleSubmit} mission={missionSelected} chargement={loading} />,
        },
    ]), [identifications, missionSelected, selectYear, loading, codeSommaire]);

    return (
    <>
        <div className="mx-5">
            <PageToolbarHeader
                prev={prev}
                pageTitle={"EI.2 : Aide mémoire du contrôle des états financiers"}
                mission={missionSelected}
                setMission={setMissionSelected}
                isProvisoire={isProvisoire}
                setIsProvisoire={setIsProvisoire}
                selectYear={selectYear}
                setSelectYear={setSelectYear}
                loading={loading}
                setLoading={setLoading}
                client={client}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                setDate={setDate}
                setTache_id={setTache_id}
                tache_id={tache_id}
                setIntervention_id={setIntervention_id}
                intervention_id={intervention_id}
                setHeureEntree={setHeureEntree}
                setUser_id={setUser_id}
            />
            <StepHeader>
                <ModalNavBarMenu
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    steps={formSteps}
                    setLoading={setLoading}
                    loading={loading}
                    isTaskSave={isTaskSave}
                    auth={auth}
                />
            </StepHeader>
            <StepBody>
                {formSteps[activeTab].content}
            </StepBody>
        </div>
    </>
  );
}

export {AideMemoire}