import React, {useEffect, useState} from 'react';
import {Button, Table, Tooltip} from 'antd';
import {
    CloseOutlined, EyeOutlined,
    FilterFilled,
} from '@ant-design/icons';
import {toAbsoluteUrl} from '../../../helpers/AssetsHelpers';
import {ExportHeader, SearchHeader} from '../../../components/layout/header/HeaderList';
import {Link} from "react-router-dom";
import {getListeClients} from "../../../data/client";
import {getAllListeEquipeMission, getListeExercice} from "../../../data/exercice";
import {DateUserListe} from "../../../components/layout/pageData/DateUserListe";

const ListeEqupeMission = () => {
    const [equipeClients, setEquipeClients] = useState([]);
    const [equipeMission, setEquipeMission] = useState([]);
    const [equipes, setEquipes] = useState([]);
    const [filtersItems, setFiltersItems] = useState([]);
    const [message, setMessage] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedModal, setSelectedModal] = useState(null)
    const [open, setOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const color = ['primary', 'danger', 'warning', 'info', 'success']

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
    });

    useEffect(() => {
        fetchClientExercice();
    }, []);

    useEffect(() => {
        getAllEquipeMission();
    }, [equipes]);

    useEffect(() => {
        fetchClients();
    }, [equipeMission]);

    const fetchClientExercice = () => {
        setLoading(true);
        getListeExercice()
            .then((res) => {
                setEquipes(res)
            });
    };

    const getAllEquipeMission = () => {
        getAllListeEquipeMission()
            .then((res) => {
                const elements = []
                equipes.forEach((item) => {
                    let equipes = res.filter((opt) => opt.exercice_id === item.id);
                    item.equipes = equipes;
                    elements.push(item)
                })
                setEquipeMission(elements)
            })
    }

    const fetchClients = () => {
        setLoading(true);
        getListeClients()
            .then((res) => {
                const elements = []
                equipeMission.forEach((item) => {
                    if(item.equipes.length > 0){
                        const clts = res.result.filter((opt) => opt.id === item.client_id)[0];
                        item.client = clts
                        elements.push(item)
                    }
                })
                setEquipeClients(elements);
                setFiltersItems(elements)
                setLoading(false);
            });
    };

    const handleCancel = () => {
        setOpen(false)
        setError(false)
    }

    const handleButtonClick = (modalId) => {
        setSelectedModal(modalId)
    }

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = equipeClients.filter(
            (item) =>
                item.denomination.toLowerCase().includes(searchTerm) ||
                item.mission.toLowerCase().includes(searchTerm)
        );
        setFiltersItems(filtered);
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setEquipeClients([]);
        }
    };

    console.log("equipeClients", equipeClients)

    const columns = [
        {
            title: 'Equipes',
            dataIndex: 'equipe',
            // sorter: true,
            render: (_, record) => (
                <div className='d-flex align-center'>
                    {record?.equipes?.map((item, index) => (
                        <div key={index} className="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title=""
                             data-bs-original-title="Alan Warden">
                            {item.avatar ? (
                                <div className='symbol-label'>
                                    <img
                                        src={toAbsoluteUrl(`/media/${item.avatar}`)}
                                        alt={item.nom_user}
                                        className='w-100'
                                    />
                                </div>
                            ) : (
                                <Tooltip title={item.nom_user + ' ' + item.prenom_user}>
                                    <span className={`symbol-label bg-${color[Math.floor(Math.random() * color.length)]} text-inverse-primary fw-bolder`}>
                                        {item.nom_user[0].toUpperCase()}
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    ))}
                </div>
            ),
        },
        {
            title: 'Mission',
            dataIndex: 'mission',
            render: (_, record) => (
                <div className='d-flex align-items-center justify-content-center'>
                    {/* begin:: Avatar */}
                    <div className='d-flex flex-column'>
                        <div className='badge badge-light fw-bolder' style={{fontSize: '15px'}}>
                            {`${record?.mission} - ${record?.annee}`}
                        </div>
                        <span className='table-strong text-center'>{record.client?.sigle}</span>
                        <div className='text-gray-600 mb-1 '>
                            <DateUserListe date={record.createdAt}
                                           user={record.nom_user + " " + record.prenom_user}/>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (_, record) => (
                <>
                    {record.etat === 1 ?
                        <div className="badge badge-light-primary">En cours</div>
                        :
                        record.etat === 2 ?
                            <div className="badge badge-light-success">Accepté(e)</div>
                            :
                            <div className="badge badge-light-danger">Réfusé(e)</div>
                    }
                </>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <>
                    <Link
                        style={{marginRight: '10px'}}
                        to={`/clients/mission/exploitation/${record.id}`}
                        state={{record: record.client, mission: record}}
                    >
                        <Tooltip title="Consulter Exercices">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_add_user"
                            >
                                    <span className="svg-icon svg-icon">
                                        <EyeOutlined style={{fontSize: '2px'}}/>
                                    </span>
                                    <span className='pb-5'> Consulter</span>
                            </button>
                        </Tooltip>
                    </Link>

                    {/*<CustomMenu  menuItems={menuItems(record)}/>*/}
                </>
            ),
        },
    ];

    return (
        <>
            <div className="card w-100">
                {error ? (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{message}</div>
                    </div>
                ) : (
                    ''
                )}
                <div className="card-header border-0 pt-6">
                    <SearchHeader handleSearch={handleSearchChange}/>
                    <div className="card-toolbar">
                        <div className="d-flex justify-content-end" data-kt-record-table-toolbar="base">
                            {/*<FilterHeader clients={equipeMission.client} statuts={statuts} setFiltersItems={setFiltersItems} clients={clients}/>*/}
                            <ExportHeader/>
                        </div>
                    </div>
                </div>
                <div className="card-body py-4">
                    <div className="table-responsive">
                        <Table
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={filtersItems}
                            pagination={tableParams.pagination}
                            loading={loading}
                            onChange={handleTableChange}
                        />
                    </div>
                </div>
                {isModalOpen &&
                    <div
                        className="position-fixed top-0 bottom-0 z-index-1000 h-100"
                        style={{insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)'}}
                    >
                        {/*<div className="modal fade show" id="kt_modal_upload" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block" }}>*/}
                        {/*    <FileUploadModal onClose={handleCloseModal} client={client} fetchClients={fetchClients} />*/}
                        {/*</div>*/}
                    </div>
                }
            </div>

        </>
    );
};


export default ListeEqupeMission;
