import axios from 'axios'
import {
    AJOUTER_RISQUE,
    LISTE_RISQUE, LISTE_RISQUE_ACTIVE,  
    MODIFIER_RISQUE,
    MODIFIER_ETAT_RISQUE,  SUPPRIMER_RISQUE
} from '../../api/apiRoutes'

export const addRisque = async (data) => {
    return axios.post(AJOUTER_RISQUE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateRisque = async (data) => {
    return axios.post(MODIFIER_RISQUE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeRisque = async () => {
    return axios.get(LISTE_RISQUE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeRisqueActive = async () => {
    return axios.get(LISTE_RISQUE_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteRisque = async (data) => {
    return axios.post(SUPPRIMER_RISQUE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatRisque = async (data) => {
    return axios.post(MODIFIER_ETAT_RISQUE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}