import React, {useEffect, useState} from "react";
import { Identification } from "../../Identification";
import {SOMMAIRE_RISQUE_LIBELLE} from "../../../../utilities/Utilities";
import swal from "sweetalert";
import {
    ajouterRisqueEvaluation,
    getDetailsRisqueEvaluationActivite,
    getDetailsRisqueEvaluationFraude, getDetailsRisqueEvaluationInformatique, supprimerAllRisqueEvaluation
} from "../../../../../data/risqueEvaluation";
import { TraitementEvaluation } from "./evaluation/TraitementEvaluation";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import {faIdCard, faSave, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {Button, notification, Tooltip} from "antd";
import {appTheme} from "../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserCycleIdentification from "../../UserCycleIdentification";
import {useNavigate} from "react-router-dom";

const RisqueEvaluation = ({
          mission,
          client,
          code,
          modalId,
          prev
      }) => {
    // const user = useSelector(state => state.user)
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [codeSommaire] = useState(code || '');
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [normeCode, setNormeCode] = useState('');
    const [selecteData, setSelectData] = useState([]);
    const [isAddMemoire, setIsAddMemoire] = useState("");
    const [libelle, setLibelle] = useState('');
    const [datas, setDatas] = useState([]);
    const [title, setTitle] = useState("");
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    const fetchEvaluationActivite = async (mission_id) => {
        getDetailsRisqueEvaluationActivite(mission_id)
            .then((res) => {
                setDatas(res.result)
            })
            .catch(error => {
                notification.error({
                    description: error,
                });
            })
    }

    const fetchEvaluationfraude = async (mission_id) => {
        getDetailsRisqueEvaluationFraude(mission_id)
            .then((res) => {
                setDatas(res.result)
            })
            .catch(error => {
                notification.error({
                    description: error,
                });
            })
    }

    const fetchEvaluationInformatique = async (mission_id) => {
        getDetailsRisqueEvaluationInformatique(mission_id)
            .then((res) => {
                setDatas(res.result)
            })
            .catch(error => {
                notification.error({
                    description: error,
                });
            })
    }

    useEffect(() => {
        setNormeCode(`ED${modalId}`)
        if(modalId === 1){
            fetchEvaluationActivite(missionSelected.id);

            setTitle('ED1.2 - Evaluation risques liés à l\'activité');
            setLibelle(SOMMAIRE_RISQUE_LIBELLE.ACTIVITE)
        }
        else if(modalId === 2){
            fetchEvaluationfraude(missionSelected.id);

            setTitle('ED2.2 - Evaluation risques liés à la fraude et aux erreurs');
            setLibelle(SOMMAIRE_RISQUE_LIBELLE.FRAUDE)
        }
        else if(modalId === 3){
            fetchEvaluationInformatique(missionSelected.id);

            setTitle('ED3.2 - Evaluation risques liés à l\'informatique');
            setLibelle(SOMMAIRE_RISQUE_LIBELLE.INFORMATIQUE)
        }
    }, [missionSelected, modalId])

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(missionSelected.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [activeTab, missionSelected, codeSommaire])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const handleDeleteAllEvaluation = () => {
        setLoading(true);

        const risqueIncidences = []
        selecteData.forEach((select) => {
            select.risquesDetails.forEach(risque => {
                risque.risqueIncidences.forEach((item) => {
                    risqueIncidences.push({incidence_id: item.incidence_id})
                })
            });
        })

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            isAddMemoire,
            client: client.id,
            risqueIncidences,
            isProvisoire,
            libelle,
            exercice_id: missionSelected.id,
            client_id: client.id,
        };
        setLoading(false);

        supprimerAllRisqueEvaluation(data)
            .then(async (res) => {
                const {status, message} = res;
                if (status === 'success') {
                    setLoading(false)

                    if(modalId === 1){
                        await fetchEvaluationActivite(missionSelected.id);
                    }
                    else if(modalId === 2){
                        await fetchEvaluationfraude(missionSelected.id);
                    }
                    else if(modalId === 3){
                        await fetchEvaluationInformatique(missionSelected.id);
                    }

                    await swal(`Opération effectuée avec succès`, '', 'success')
                } else {
                    await swal(message, '', 'error')
                    setLoading(false)
                }
            })
            .catch((err) => {
                notification.error({
                    description: err,
                });
            })
    }

    const handleSubmit = async () => {
        setLoading(true);

        const isRequired = selecteData.some(select => {
            return select.risquesDetails.some(risque => {
                return risque.risqueIncidences.some(risqueIncid => {
                    return risqueIncid.risquesAssertions.some(risqueAssert => {
                        return (
                            (!risqueAssert.probabilite_id) ||
                            (!risqueAssert.niveau_id) ||
                            (!risqueAssert.impact_id)
                        );
                    });
                });
            });
        });

        if(isRequired){
            notification.error({
                description: "Veuillez renseigner tout les champs obligatoires",
            });
            setLoading(false);
            return;
        }

        const risqueIncidences = []
        selecteData.forEach((item) => {
            item.risquesDetails.forEach((event) => {
                event.risqueIncidences.forEach((incidence) => {
                    risqueIncidences.push(incidence)
                })
            })
        })

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            isAddMemoire,
            client: client.id,
            risqueIncidences,
            isProvisoire,
            libelle,
            exercice_id: missionSelected.id,
            client_id: client.id,
        };

        const {status, message} = await ajouterRisqueEvaluation(data)
        if (status === 'success') {
            setLoading(false)
            setAuth(false);
            await swal(`Opération effectuée avec succès`, '', 'success')
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "CB",
            title: `TRAITEMENT - ${selectYear}`,
            description: `TRAITEMENT - ${selectYear}`,
            icon: faTasks,
            content: (
                <TraitementEvaluation
                    selecteData={selecteData}
                    setSelectData={setSelectData}
                    setDatas={setDatas}
                    datas={datas}
                    setIsAddMemoire={setIsAddMemoire}
                    id={modalId}
                    mission={missionSelected}
                    isProvisoire={isProvisoire}
                    libelleRisque={libelle}
                    handleDeleteAllEvaluation={handleDeleteAllEvaluation}
                />
            ),
        },
    ];

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={title}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>
                        <div className="d-flex justify-content-end align-items-end mb-10">
                            <Tooltip title="Enregistrer">
                                <Button
                                    disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                                    size={"large"}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: appTheme.colors.secondary,
                                        color: "white"
                                    }}
                                    icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                    onClick={handleSubmit}
                                >
                                    {!loading &&
                                        <span className='indicator-label text-white'>Enregistrer</span>}
                                    {loading && (
                                        <span className='indicator-progress fs-9 text-white'
                                              style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </Button>
                            </Tooltip>
                        </div>
                        {formSteps[activeTab].content}
                    </>
                </StepBody>
            </div>
        </>
    );
 }

export {RisqueEvaluation}