import {CloseSquareOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {toAbsoluteUrl} from "../../../helpers/AssetsHelpers";
import clsx from "clsx";
import {getListeEquipeMission} from "../../../data/exercice";
import {getListeTacheActive} from "../../../data/params/tache";
import {getListeInterventionActive} from "../../../data/params/intervention";
import {useSelector} from "react-redux";
import TableTitle from "../../common/TableTitle";
import {Button, Modal, Space, Table, Tag, Tooltip} from "antd";
import {appTheme} from "../../../config/theme";
import {FormContainer, TagItem} from "../../common/common.components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPersonChalkboard,
    faPhoneAlt
} from "@fortawesome/free-solid-svg-icons";
import {ModalTitle} from "../../modals/modals.components";
import ModalAlert from "../../modals/Modal";

const ListeEquipeMission = ({
        mission,
        handleVisibility,
        visible,
        data

    }) => {
    const user = useSelector(state => state.user)
    const [equipes, setEquipes] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [taches, setTaches] = useState([]);
    const [indexUser, setIndexUser] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(mission){
            fetchEquipes(mission.id);
        }
    }, [mission]);

    useEffect(() => {
        fetchListeTache()
        fetchListeIntervention()
    }, []);

    const fetchEquipes = (id) => {
        setLoading(true)
        getListeEquipeMission(id)
            .then((res) => {
                setEquipes(res);
                setLoading(false)
            })
            .catch((error) => {
                console.error("Erreur", error);
                setLoading(false)
            });
    };

    const fetchListeTache = () => {
        getListeTacheActive()
            .then((res) => {
                setTaches(res.result)
            })
    };

    const fetchListeIntervention = () => {
        getListeInterventionActive()
            .then((res) => {
                setInterventions(res.result)
            })
    };

    const handleOpenModal = (opt) => {
        setIndexUser(opt);
        setIsModalOpen(prevState => !prevState);
    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Photo</TableTitle>,
            key: "photo",
            dataIndex: "photo",
            render: (_, record) => (
                <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    {record?.Lea_User?.avatar ? (
                        <div className='symbol-label'>
                            <img
                                src={toAbsoluteUrl(`/media/${record?.Lea_User?.avatar}`)}
                                alt={record.Lea_User?.nom_user + ' ' + record.Lea_User?.prenom_user}
                                className='w-100'
                            />
                        </div>
                    ) : (
                        <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                            <img
                                src={toAbsoluteUrl('/media/avatars/blank.png')}
                                alt={record.Lea_User?.nom_user + ' ' + record.Lea_User?.prenom_user}
                                className='w-100'
                            />
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Membre</TableTitle>,
            dataIndex: 'reference',
            render: (_, record) => (
                <>
                  <span style={{ color: "#000", fontWeight: "bold" }}>
                    {`${record.Lea_User?.nom_user || ""} ${record.Lea_User?.prenom_user || ""}`}
                  </span>
                    <br />
                    {record.Lea_User?.initial && <Tag prefix="@" color={appTheme.colors.primary_blue}>{record.Lea_User?.initial}</Tag>}
                    <br/>
                    {record.telephone1_user && <Tag
                        color={appTheme.colors.alter}
                        style={{fontWeight: "600"}}
                        icon={<FontAwesomeIcon icon={faPhoneAlt} style={{marginRight: "5px"}}/>}
                    >
                        {record.telephone1_user}
                    </Tag>}
                </>
            ),
        },
        {
            title: () => <TableTitle>Rôle</TableTitle>,
            dataIndex: 'libelle',
            render: (_, record) => (
                <TagItem>{record.Lea_RoleMission?.libelle}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Dossier client</TableTitle>,
            dataIndex: 'dossiers',
            render: () => (
                <div className='d-flex flex-column'>
                    <span style={{ color: "#000", fontWeight: "bold", fontSize: "12px" }}>
                        {mission.denomination}
                    </span>
                    <span className='text-gray-800 fw-bold text-hover-primary mb-1' style={{fontSize: '12px'}}>
                        {mission.mission} <span className="ms-3">{mission.annee && <TagItem color={appTheme.colors.primary}>{mission.annee}</TagItem>}</span>
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Actions</TableTitle>,
            dataIndex: 'action',
            render: (_, record, index) => (
                <Space>
                    {user.id === record.membre_id && <Tooltip color={appTheme.colors.primary} title="Voir les privilèges">
                        <Button
                            shape="circle"
                            size={"middle"}
                            style={{backgroundColor: appTheme.colors.primary}}
                            onClick={() => handleOpenModal(index)}
                            icon={<FontAwesomeIcon icon={faPersonChalkboard} color={"white"}/>}
                        />
                    </Tooltip>}
                </Space>
            ),
        },
    ];

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Equipe de mission {mission ? `pour ${mission.mission || ""} - ${mission.annee || ""}` : ""}</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 50 }}
                width={800}
            >
                <FormContainer>
                    <div className="w-100">
                        <div className="py-4">
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    rowKey={(record) => record.id}
                                    dataSource={equipes}
                                    pagination={false}
                                    loading={loading}
                                    size={"small"}
                                />
                            </div>
                        </div>
                    </div>
                </FormContainer>
            </ModalAlert>
            {isModalOpen && <Modal
                title=""
                open={isModalOpen}
                onOk={() => setIsModalOpen(false)}
                onCancel={() => setIsModalOpen(false)}
                width={800}
                maskStyle={{
                    backdropFilter: "blur(5px)",
                    background: `linear-gradient(70deg, ${appTheme.colors.primary}60, ${appTheme.colors.alter}60),${appTheme.colors.primary}60`,
                }}
            >
                <div className="modal-body py-10 px-lg-17">
                    <h1 className="mb-7">
                        <span className="fw-bolder">Privilège du role</span>
                    </h1>
                    <div className="scroll-y mh-400px me-n7 pe-7">
                        <div className="d-flex align-center">
                            <div className="col-lg-6">
                                <div className="card card-flush">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h4 className="mb-0">Tâches</h4>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="d-flex flex-column text-gray-600">
                                            {equipes[indexUser].Lea_RoleMission && equipes[indexUser].Lea_RoleMission?.actions?.map((selected, index) => (
                                                <div key={index} className="d-flex align-items-center py-2">
                                                    <span className="bullet bg-primary me-3"></span>
                                                    {taches.length > 0 && taches.find((intervention) => intervention.id === selected)?.libelle}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card card-flush">
                                    <div className="card-header">
                                        <div className="card-title">
                                            <h4 className="mb-0">Interventions</h4>
                                        </div>
                                    </div>
                                    <div className="card-body pt-0">
                                        <div className="d-flex flex-column text-gray-600">
                                            {equipes[indexUser].Lea_RoleMission && equipes[indexUser].Lea_RoleMission?.intervention?.map((selected, index) => (
                                                <div key={index} className="d-flex align-items-center py-2">
                                                    <span className="bullet bg-primary me-3"></span>
                                                    {interventions.length > 0 && interventions.find((intervention) => intervention.id === selected)?.libelle}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>}
        </>
    )
}

export {ListeEquipeMission}