import {ModalTitle} from "../modals.components";
import {
    Alert,
    Button,
    Col,
    Divider,
    Input,
    Row,
} from "antd";
import FormLabel from "../../common/Form/FormLabel";
import ModalAlert from "../Modal";
import React, {useState} from "react";
import {Form, Formik} from "formik";
import FormError from "../../common/Form/FormError";
import Swal from "sweetalert2";
import {optionsSwal} from "../../../helpers/AssetsHelpers";
import {clotureExercice} from "../../../data/exercice";
import swal from "sweetalert";
import * as Yup from "yup";

const ModalMissionClotureComment = ({
        client,
        exercice,
        fetchData,
        handleVisibility,
        visible,
    }) => {
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (value)  => {
        Swal.fire(optionsSwal).then(async (result) => {
            if (result.isConfirmed) {
                setLoading(true)
                const data = {client_id: value.client_id, isCloture: true, exercice_id: value.exercice_id, commentCloture: value.commentaire}

                const {status, message} = await clotureExercice(data)
                if (status === 'error') {
                    await swal(`${message}`, '', 'warning')
                }
                if (status === 'success') {
                    setLoading(false)
                    await swal(`Opération effectuée avec succès`, '', 'success')
                    fetchData()
                    handleVisibility()
                } else {
                    setLoading(false)
                }
            }
        })
    }

    const dataValidationScheme = Yup.object({
        // client_id: Yup.number()
        //     .required("Veuillez sélectionner le client")
        //     .nullable("Le client est obligatoire")
        //     .typeError("Le client est obligatoire"),
        // exercice_id: Yup.number()
        //     .required("Veuillez sélectionner la de mission")
        //     .nullable("La mission est obligatoire")
        //     .typeError("La mission est obligatoire"),
        commentaire: Yup.string().required("Le commentaire de cloture de la mission est obligatoire"),
    });

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Confirmation de cloture de la mission</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 50 }}
                width={600}
            >
                <Formik
                    initialValues={{
                        isCloture: true,
                        commentaire: "",
                        exercice_id: (exercice && exercice.id) || "",
                        client_id: (client && client.id) || ""
                    }}
                    onSubmit={(values) => handleSubmit(values)}
                    validationSchema={dataValidationScheme}
                >
                    {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                        <Form>
                            <Row gutter={[16, 16]}>

                                <Col span={24}>
                                    <Alert
                                        message="Warning"
                                        description="Si vous validez ce processus, un nouvel exercice sera créé et celui en cours sera considéré comme cloturé.
                                                    Cette action est irreversible !
                                                    Veuillez saisir le commentaire de cloture pour confirmation."
                                        type="warning"
                                        showIcon
                                    />
                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Commentaire" required />
                                    <Input.TextArea
                                        size="large"
                                        placeholder="Message"
                                        name="commentaire"
                                        value={values.commentaire}
                                        onChange={(e) =>
                                            setFieldValue("commentaire", e.target.value)
                                        }
                                        maxLength={2000}
                                        showCount
                                        rows={5}
                                    />
                                    {touched.commentaire && errors.commentaire && (
                                        <FormError fieldName="commentaire" />
                                    )}

                                </Col>
                                <Divider/>
                                <Col
                                    xs={{ span: 24 }}
                                    md={{ span: 24 }}
                                    lg={{ span: 24 }}
                                    xl={{ span: 24 }}
                                >
                                    <Button
                                        type="primary"
                                        block
                                        size="large"
                                        loading={loading}
                                        disabled={loading}
                                        onClick={handleSubmit}
                                    >
                                        {!loading && <span className='indicator-label'>Enregistrer</span>}
                                        {loading && (
                                            <span className="indicator-progress text-white">
                                            Veuillez patienter...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                        </span>
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </ModalAlert>
        </>
    )

}

export default ModalMissionClotureComment;