import React, {useEffect, useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import moment from "moment";
import {Tooltip} from "antd";
import {EditOutlined} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../../helpers/AssetsHelpers";
import clsx from "clsx";
import {appTheme} from "../../../config/theme";
import {FormContainer, TagItem} from "../../../components/common/common.components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBriefcase,
    faEnvelopeCircleCheck,
    faLandmark,
    faMap,
    faPhone
} from "@fortawesome/free-solid-svg-icons";
import {FicheClientMenu} from "./ClientExploitationFiche";
import DossierPermanentMenu from "../../../components/client/modals/missionSteps/EA/DossierPemanentMenu";
import {Spinner} from "@chakra-ui/react";
import {getListeExerciceClients, getListeUserMission} from "../../../data/exercice";

const InfosClient = ({client}) => {
    const user = useSelector(state => state.user)
    const isAdmin = user.libelleProfil === "Admin"
    const [activeTab, setActiveTab] = useState('general');
    const [isManager, setIsManager] = useState(false);
    const [usersMission, setUsersMission] = useState([]);

    useEffect(() => {
        const fetchUserMission = async (id) => {
            try {
                const res = await getListeUserMission(id);
                if (res) {
                    setUsersMission(res);
                }
            } catch (error) {
                console.error('Failed to fetch user missions:', error);
            }
        };

        if (user?.id) {
            fetchUserMission(user.id);
        }
    }, [user]);

    useEffect(() => {
        const fetchClientExercice = async () => {
            try {
                const res = await getListeExerciceClients(client.id);
                if (res && res.length > 0) {
                    const isOK = res.some(mission =>
                        user.id === mission.manager_mission_id ||
                        usersMission.some(
                            element => mission.id === element.exercice_id &&
                                user.id === element.membre_id &&
                                element.isManagerMission
                        )
                    );
                    setIsManager(isOK);
                }
            } catch (error) {
                console.error('Failed to fetch client exercises:', error);
            }
        };

        if (client?.id) {
            fetchClientExercice();
        }
    }, [client, user, usersMission]);


    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <>
            <div className="flex-lg-row-fluid ms-lg-15">
                {/* begin::Tabs */}
                <div className="d-flex justify-content-between">
                    <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                        {/* begin::Tab item */}
                        <li className="nav-item">
                            <button
                                className={`nav-link text-active-primary pb-4 ${activeTab === 'general' ? 'active' : ''}`}
                                onClick={() => handleTabChange('general')}
                            >
                                General
                            </button>
                        </li>
                        {/* end::Tab item */}
                        {/* begin::Tab item */}
                        <li className="nav-item">
                            <button
                                className={`nav-link text-active-primary pb-4 ${activeTab === 'siegeSocial' ? 'active' : ''}`}
                                onClick={() => handleTabChange('siegeSocial')}
                            >
                                Siege Social
                            </button>
                        </li>
                        {/* end::Tab item */}
                        {/* begin::Tab item */}
                        <li className="nav-item">
                            <button
                                className={`nav-link text-active-primary pb-4 ${activeTab === 'autresInfos' ? 'active' : ''}`}
                                onClick={() => handleTabChange('autresInfos')}
                            >
                                Autres informations
                            </button>
                        </li>
                        {/* end::Tab item */}
                    </ul>
                    {(isAdmin  || isManager) && <Tooltip title="Modifier infos" className='me-15'>
                        <Link to={`/clients/edit/${client.id}`} state={{record: client}}>
                            <button
                                className='btn btn-light-primary'
                            >
                                <span className="svg-icon"><EditOutlined/></span>
                                Modifier
                            </button>
                        </Link>
                    </Tooltip>}
                </div>

                {/* end::Tabs */}
                {/* begin::Tab content */}
                <div className="tab-content" id="myTabContent">
                    {/* begin::Tab pane */}
                    <div className={`tab-pane fade ${activeTab === 'general' ? 'show active' : ''}`} id="kt_ecommerce_settings_general" role="tabpanel">
                        {/* begin::General */}
                        <div className="card card-flush">
                            {/*begin::Card header*/}
                            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                {/*begin::Card title*/}
                                <div className="card-title">
                                    {/*begin::Title*/}
                                    <h2>Generalité</h2>
                                    {/*end::Title*/}
                                </div>
                                {/*end::Card title*/}
                            </div>
                            {/*end::Card header*/}
                            {/*begin::Card body*/}
                            <div className="card-body pt-0">
                                {/*begin::Form*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7 fv-plugins-icon-container">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Sigle du client</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="sigle"
                                            value={client.sigle}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}

                                    </div>
                                </div>
                                <div className="row fv-row mb-7 fv-plugins-icon-container">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Dénomination sociale</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="denomination"
                                            value={client.denomination}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Secteurs d'activité</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="secteur_id"
                                            value={client.libelle_secteur}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span className='required'>Forme juridique</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="statut_id"
                                            value={client.libelle_status}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}

                                <div className='row fv-row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6 text-md-end'>
                                        Date de constitution et Durée de vie statutaire
                                    </label>
                                    <div className='col-lg-9'>
                                        <div className='row'>
                                            <div className='col-lg-6 fv-row'>
                                                {/*begin::Input*/}
                                                <input
                                                    className='form-control form-control-solid'
                                                    name='date_constitution'
                                                    type='text'
                                                    disabled={true}
                                                    value={client.date_constitution ? moment(client.date_constitution).format('DD/MM/Y') : ''}
                                                />
                                                {/*end::Input*/}
                                            </div>

                                            <div className='col-lg-6 fv-row'>
                                                <input
                                                    type="number"
                                                    className='form-control form-control-solid'
                                                    name="duree_statutaire"
                                                    value={client.duree_statutaire}
                                                    disabled={true}
                                                />
                                                {/*end::Input*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7" data-select2-id="select2-data-141-byvp">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Numéro R.C.C.M</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="numero_rccm"
                                            value={client.numero_rccm}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7" data-select2-id="select2-data-141-byvp">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Numéro compte contribuable (CC)</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="numero_cc"
                                            value={client.numero_cc}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7" data-select2-id="select2-data-141-byvp">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Centre impots</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="centre_impots"
                                            value={client.centre_impots}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7" data-select2-id="select2-data-141-byvp">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>N° CNPS employeur</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="numero_cnps_employeur"
                                            value={client.numero_cnps_employeur}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7" data-select2-id="select2-data-141-byvp">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>N° agrément(Pour les secteur reglémentés)</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="numero_agrement"
                                            value={client.numero_agrement}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}
                                <div></div>
                                {/*end::Form*/}
                            </div>
                            {/*end::Card body*/}
                        </div>
                        {/* end::General */}
                    </div>
                    {/* end::Tab pane */}
                    {/* begin::Tab pane */}
                    <div className={`tab-pane fade ${activeTab === 'siegeSocial' ? 'show active' : ''}`} id="kt_ecommerce_settings_store" role="tabpanel">
                        {/* begin::Siege */}
                        <div className="card card-flush">
                            {/*begin::Card header*/}
                            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                {/*begin::Card title*/}
                                <div className="card-title">
                                    {/*begin::Title*/}
                                    <h2>Informations siège social</h2>
                                    {/*end::Title*/}
                                </div>
                                {/*end::Card title*/}
                            </div>
                            {/*end::Card header*/}
                            {/*begin::Card body*/}
                            <div className="card-body pt-0">
                                {/*begin::Form*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7 fv-plugins-icon-container">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Adresse géographique</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <textarea
                                            className="form-control form-control-solid"
                                            name="localisation"
                                            value={client.localisation}
                                            disabled={true}
                                        ></textarea>
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                <div className="row fv-row mb-7 fv-plugins-icon-container">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Adresse postale</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="adresse_postale"
                                            value={client.adresse_postale}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Téléphone 1</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="cel1"
                                            value={client.cel1}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Adresse email</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="email"
                                            value={client.email}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Site internet</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="site_internet"
                                            value={client.site_internet}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>
                                {/*end::Input group*/}
                                <div></div>
                                {/*end::Form*/}
                            </div>
                            {/*end::Card body*/}
                        </div>
                        {/* end::Siege */}
                    </div>
                    {/* end::Tab pane */}
                    {/* begin::Tab pane */}
                    <div className={`tab-pane fade ${activeTab === 'autresInfos' ? 'show active' : ''}`} id="kt_ecommerce_settings_localization" role="tabpanel">
                        {/* begin::Autre */}
                        <div className="card card-flush">
                            {/*begin::Card header*/}
                            <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                                {/*begin::Card title*/}
                                <div className="card-title">
                                    {/*begin::Title*/}
                                    <h2>Autres informations</h2>
                                    {/*end::Title*/}
                                </div>
                                {/*end::Card title*/}
                            </div>
                            {/*end::Card header*/}
                            {/*begin::Card body*/}
                            <div className="card-body pt-0">
                                {/*begin::Form*/}
                                {/*begin::Input group*/}
                                <div className="row fv-row mb-7 fv-plugins-icon-container">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Actionnaire ou Associé majoritaire</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="actionnaire_majoritaire"
                                            value={client.actionnaire_majoritaire}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                </div>

                                <div className="row fv-row mb-7 fv-plugins-icon-container">
                                    <div className="col-md-3 text-md-end">
                                        {/*begin::Label*/}
                                        <label className="fs-6 fw-bold form-label mt-3">
                                            <span>Nom du dirigeant (PCA/PDG/ Adm. GI/Gérant)</span>
                                        </label>
                                        {/*end::Label*/}
                                    </div>
                                    <div className="col-md-9">
                                        {/*begin::Input*/}
                                        <input
                                            type="text"
                                            className='form-control form-control-solid'
                                            name="nom_dirigeant"
                                            value={client.nom_dirigeant}
                                            disabled={true}
                                        />
                                        {/*end::Input*/}
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="row fv-row mb-7">
                                        <div className="col-md-3 text-md-end">
                                            {/*begin::Label*/}
                                            <label className="fs-6 fw-bold form-label mt-3">
                                                <span>Expert comptable</span>
                                            </label>
                                            {/*end::Label*/}
                                        </div>
                                        <div className="col-md-9">
                                            {/*begin::Input*/}
                                            <input
                                                type="text"
                                                className='form-control form-control-solid'
                                                name="expert_comptable"
                                                value={client.expert_comptable}
                                                disabled={true}
                                            />
                                            {/*end::Input*/}
                                        </div>
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="row fv-row mb-7">
                                        <div className="col-md-3 text-md-end">
                                            {/*begin::Label*/}
                                            <label className="fs-6 fw-bold form-label mt-3">
                                                <span>Conseil juridique</span>
                                            </label>
                                            {/*end::Label*/}
                                        </div>
                                        <div className="col-md-9">
                                            {/*begin::Input*/}
                                            <input
                                                type="text"
                                                className='form-control form-control-solid'
                                                name="conseil_juridique"
                                                value={client.conseil_juridique}
                                                disabled={true}
                                            />
                                            {/*end::Input*/}
                                        </div>
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="row fv-row mb-7">
                                        <div className="col-md-3 text-md-end">
                                            {/*begin::Label*/}
                                            <label className="fs-6 fw-bold form-label mt-3">
                                                <span>Co-Commissaire aux Comptes </span>
                                            </label>
                                            {/*end::Label*/}
                                        </div>
                                        <div className="col-md-9">
                                            {/*begin::Input*/}
                                            <input
                                                type="text"
                                                className='form-control form-control-solid'
                                                name="commissaire_compte"
                                                value={client.commissaire_compte}
                                                disabled={true}
                                            />
                                            {/*end::Input*/}
                                        </div>
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="row fv-row mb-7">
                                        <div className="col-md-3 text-md-end">
                                            {/*begin::Label*/}
                                            <label className="fs-6 fw-bold form-label mt-3">
                                                <span>Associé signataire des rapports</span>
                                            </label>
                                            {/*end::Label*/}
                                        </div>
                                        <div className="col-md-9">
                                            {/*begin::Input*/}
                                            <input
                                                type="text"
                                                className='form-control form-control-solid'
                                                name="associe_signataire"
                                                value={client.associe_signataire}
                                                disabled={true}
                                            />
                                            {/*end::Input*/}
                                        </div>
                                    </div>
                                    {/*end::Input group*/}

                                    <div></div>
                                    {/*end::Form*/}
                                </div>
                            </div>
                            {/*end::Card body*/}
                        </div>
                        {/* end::Autre */}
                    </div>
                    {/* end::Tab pane */}
                </div>
                {/* end::Tab content */}
            </div>
        </>
    )
}

const ClientDetails = () => {
    const location = useLocation()

    const client = location.state.client
    const link = location.state.link

    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setActiveTab(link || 0)
    }, [link])

    const handleTabChange = (tabName) => {
        setLoading(true);
        setTimeout(() => {
            setActiveTab(tabName);
            setLoading(false);
        }, 1000)
    };

    const steps = [
        {
            id: 1,
            title: 'Fiche Signalétique',
            description: 'La fiche signalétique du client',
            content: (
                <InfosClient client={client}/>
            ),
        },
        {
            id: 2,
            title: 'Dossier Permanent',
            description: 'Dossier permanent du client',
            content: (
                <DossierPermanentMenu
                    modalClient={client}
                />
            ),
        },
    ];

    return (
        <>
            <FicheClientMenu>
                <div className="card mb-5 mb-xl-10">
                    <div className="card-body pt-9 pb-0">
                        <div className="d-flex flex-wrap flex-sm-nowrap">
                            <div className="me-7 mb-4">
                                <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                    {client.logo_client ? (
                                        <div className='symbol-label'>
                                            <img
                                                src={toAbsoluteUrl(`/media/${client.logo_client}`)}
                                                alt={client.denomination}
                                                className='w-100'
                                            />
                                        </div>
                                    ) : (
                                        <div className={clsx('symbol-label', `bg-green`)} style={{backgroundColor: appTheme.colors.bgColor, fontSize: "40px"}}>
                                            {client.denomination[0].toUpperCase() + '' + client.denomination[1].toUpperCase()}
                                        </div>
                                    )}
                                    <div
                                        className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex align-items-center mb-2">
                                            <span className="fw-bolder text-uppercase" style={{fontFamily: "Oswald, sans-serif", fontSize: "20px"}}>{client.denomination}</span>
                                            <span className="mx-2"></span>
                                            {client.sigle && <TagItem color={appTheme.colors.primary}>{client.sigle}</TagItem>}
                                        </div>
                                        <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                            {client.libelle_status && <div className="me-5 mb-2">
                                                <TagItem><FontAwesomeIcon icon={faBriefcase}
                                                                          style={{marginRight: "5px"}}/>{client.libelle_status}
                                                </TagItem>
                                            </div>}
                                            {client.libelle_secteur && <div className="me-5 mb-2">
                                                <TagItem><FontAwesomeIcon icon={faLandmark}
                                                                          style={{marginRight: "5px"}}/>{client.libelle_secteur}
                                                </TagItem>
                                            </div>}
                                            {client.email && <div className="me-5 mb-2">
                                                <TagItem><FontAwesomeIcon icon={faEnvelopeCircleCheck}
                                                                          style={{marginRight: "5px"}}/>{client.email}
                                                </TagItem>
                                            </div>}
                                            {client.cel1 && <div className="me-5 mb-2">
                                                <TagItem>
                                                    <FontAwesomeIcon icon={faPhone} style={{marginRight: "5px"}}/>{client.cel1}
                                                    {client.cel2 && <> <span className="mx-1">/</span>
                                                        <FontAwesomeIcon icon={faPhone} style={{marginRight: "5px"}}/></>}{client.cel2}
                                                </TagItem>
                                            </div>}

                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-wrap flex-stack">
                                    <div className="d-flex flex-column flex-grow-1 pe-8">
                                        <div className="d-flex flex-wrap">
                                            <div
                                                className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                {client.adresse_geographique && <div className="me-5 mb-2">
                                                    <TagItem>
                                                        <FontAwesomeIcon icon={faPhone} style={{marginRight: "5px"}}/>{client.cel1}
                                                        {client.adresse_geographique && <> <span className="mx-1">/</span>
                                                            <FontAwesomeIcon icon={faMap} style={{marginRight: "5px"}}/></>}{client.cel2}
                                                    </TagItem>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                            <span className="fw-semibold fs-6 text-gray-500">Evolution des mission</span>
                                            <span className="fw-bold fs-6">50%</span>
                                        </div>

                                        <div className="h-5px mx-3 w-100 bg-light mb-3">
                                            <div className="bg-success rounded h-5px" role="progressbar"
                                                 aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                            {steps.map((step, index) => (
                                <li key={index} className="nav-item">
                                    <Tooltip title={step.description}>
                                        <button
                                            data-bs-toggle="tab"
                                            className={`nav-link fs-5 text-uppercase fw-bolder btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0 ${activeTab === index ? 'active' : ''}`}
                                            onClick={(e) => handleTabChange(index, e)}
                                        >
                                            {step.title}
                                        </button>
                                    </Tooltip>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </FicheClientMenu>

            <FormContainer>
                <div className="tab-content" id="myTabContent">
                    {steps.map((item, index) => (
                        <div
                            key={index}
                            className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                            id={`tab-${index}`}
                            role="tabpanel"
                        >
                            {loading ?
                                <div className="d-flex justify-content-center align-items-center">
                                    <Spinner/>
                                </div>
                                :
                                steps[activeTab].content
                            }
                        </div>
                    ))}
                </div>
            </FormContainer>
            {/*<div className='card mb-5 mb-xl-10'>*/}
            {/*    <div className='card-body pt-9 pb-0'>*/}
            {/*        <div className="card card-flush pb-0 bgi-position-y-center bgi-no-repeat mb-10">*/}
            {/*            <div className="card-header pt-10">*/}
            {/*                <div className="d-flex align-items-center">*/}
            {/*                    <div className="symbol symbol-circle me-5">*/}
            {/*                        <div*/}
            {/*                            className="symbol-label bg-transparent text-primary border border-secondary border-dashed">*/}
            {/*                        <span className="svg-icon svg-icon-2x svg-icon-primary">*/}
            {/*                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"*/}
            {/*                                 viewBox="0 0 24 24" fill="none">*/}
            {/*                                <path*/}
            {/*                                    d="M17.302 11.35L12.002 20.55H21.202C21.802 20.55 22.202 19.85 21.902 19.35L17.302 11.35Z"*/}
            {/*                                    fill="currentColor"></path>*/}
            {/*                                <path opacity="0.3"*/}
            {/*                                      d="M12.002 20.55H2.802C2.202 20.55 1.80202 19.85 2.10202 19.35L6.70203 11.45L12.002 20.55ZM11.302 3.45L6.70203 11.35H17.302L12.702 3.45C12.402 2.85 11.602 2.85 11.302 3.45Z"*/}
            {/*                                      fill="currentColor"></path>*/}
            {/*                            </svg>*/}
            {/*                        </span>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="d-flex flex-column">*/}
            {/*                        <h2 className="mb-1">{client.denomination}</h2>*/}
            {/*                        <span className='text-hover-muted fs-5 mb-2'>{client.libelle_status}</span>*/}
            {/*                        <span className='text-gray-800 fs-3 mb-2'>{client.libelle_secteur}</span>*/}
            {/*                        <div className="text-muted fw-bolder">*/}
            {/*                            <a href="#"><span style={{color: '#24247a', fontWeight: 'bold'}}>@{client.email}</span></a>*/}
            {/*                            <span className="mx-3">|</span>*/}
            {/*                            <a href="#">{client.adresse_geographique}</a>*/}
            {/*                            <span className="mx-3">|</span>{client.cel1}*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="card-body pb-0">*/}
            {/*                <div className='d-flex overflow-auto h-55px'>*/}
            {/*                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>*/}
            {/*                        <li className='nav-item'>*/}
            {/*                            <Link*/}
            {/*                                className={*/}
            {/*                                    `nav-link text-active-primary me-6 ` +*/}
            {/*                                    (location.pathname === `/clients/fiche/details/${client.id}` && 'active')*/}
            {/*                                }*/}
            {/*                                to={`/clients/fiche/details/${client.id}`}*/}
            {/*                                state={{record: client}}*/}
            {/*                            >*/}
            {/*                                Fiche du client*/}
            {/*                            </Link>*/}
            {/*                        </li>*/}
            {/*                        <li className='nav-item'>*/}
            {/*                            <Link*/}
            {/*                                className={*/}
            {/*                                    `nav-link text-active-primary me-6 ` +*/}
            {/*                                    (location.pathname === `/clients/fiche/document/${client.id}` && 'active')*/}
            {/*                                }*/}
            {/*                                to={`/clients/fiche/document/${client.id}`}*/}
            {/*                                state={{record: client}}*/}
            {/*                            >*/}
            {/*                                Dossier Permanent*/}
            {/*                            </Link>*/}
            {/*                        </li>*/}
            {/*                    </ul>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

        </>
    )
}

export { ClientDetails };
