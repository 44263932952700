import React, {useEffect, useState} from "react";
import {Button, Col, Input, Row, Select, Table, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../../helpers/AssetsHelpers";
import {
    ajouterMaterialitePerformance,
    ajouterNiveauMaterialitePerformance
} from "../../../../../../../data/seuilSignificatif";
import {getMaterialitePerformance, getMaterialitePerformanceNiveau} from "../../../../../../../data/stategieGlobale";
import {ControlInputNumber, CustomInputNumber} from "../../../../../../layout/tools/InputNumber";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {FormLabel} from "@chakra-ui/react";

const MaterialitePerformance = ({
        materialiteValue,
        mission,
        strategies,
        setMontantAnomalie,
        client,
        codeSommaire,
        date,
        heureEntree,
        tache_id,
        intervention_id,
        isProvisoire,
        materialitePerformances,
        materialiteNiveaux
    }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectItems, setSelectItems] = useState([]);
    const [taux, setTaux] = useState(0)
    const [tauxDef, setTauxDef] = useState(0)
    const [commentaire, setCommentaire] = useState('')
    const [commentaireDef, setCommentaireDef] = useState('')
    const [selectItemsControle, setSelectItemsControle] = useState([
        {
            libelle: 'Année N-2',
            montant: 0,
            commentaire: ''
        },
        {
            libelle: 'Année N-1',
            montant: 0,
            commentaire: ''
        },
        {
            libelle: 'Année N provisoire',
            montant: 0,
            commentaire: ''
        },
        {
            libelle: 'Année N définitive',
            montant: 0,
            commentaire: ''
        }
    ]);

    useEffect(() => {
        if(materialiteValue){
            const updateTables = [
                {
                    libelle: 'Année N-2',
                    montant: materialiteValue.montantN2 || 0,
                    commentaire: ''
                },
                {
                    libelle: 'Année N-1',
                    montant: materialiteValue.montantN1 || 0,
                    commentaire: ''
                },
                {
                    libelle: 'Année N provisoire',
                    montant: materialiteValue.montantNProv || 0,
                    commentaire: ""
                },
                {
                    libelle: 'Année N définitive',
                    montant: materialiteValue.montantNDef || 0,
                    commentaire: ""
                }
            ]

            setSelectItemsControle(updateTables);
        }
    }, [materialiteValue])

    useEffect(() => {
        if(strategies){
            getMaterialitePerformance(strategies.id)
                .then((res) => {
                    if(res.result){
                        setTaux(res.result?.tauxRetenu || 100);
                        setCommentaire(res.result?.commentaireTaux || '')

                        const materialites = [
                            {
                                libelle: 'Année N-2',
                                montant: res.result.montantN2 || 0,
                                commentaire: res.result.commentaireN2 ||''
                            },
                            {
                                libelle: 'Année N-1',
                                montant: res.result.montantN1 || 0,
                                commentaire: res.result.commentaireN1 ||''
                            },
                            {
                                libelle: 'Année N provisoire',
                                montant: res.result.montantProv || 0,
                                commentaire: res.result.commentaireNProv || ""
                            },
                            {
                                libelle: 'Année N définitive',
                                montant: res.result.montantDef || 0,
                                commentaire: res.result.commentaireNDef || ""
                            }
                        ]

                        setSelectItemsControle(materialites)
                    }
                })
        }
        else{
            setTaux(100);
            setCommentaire('')

            const materialites = [
                {
                    libelle: 'Année N-2',
                    montant: 0,
                    commentaire: ''
                },
                {
                    libelle: 'Année N-1',
                    montant: 0,
                    commentaire: ''
                },
                {
                    libelle: 'Année N provisoire',
                    montant: 0,
                    commentaire: ""
                },
                {
                    libelle: 'Année N définitive',
                    montant: 0,
                    commentaire: ""
                }
            ]

            setSelectItemsControle(materialites)
        }
    }, [ strategies ])

    useEffect(() => {
        if(strategies){
            getMaterialitePerformanceNiveau(strategies.id)
                .then((res) => {
                    if(res.result && res.result.length > 0){
                        const niveaux = res.result.map((niveau) => ({
                            poste: niveau.poste,
                            montantDef: niveau.montantDef,
                            montantProv: niveau.montantProv,
                            anneN1: niveau.montantN1,
                            anneN2: niveau.montantN2,
                            commentaire: niveau.commentaire
                        }))

                        setSelectItems(niveaux)
                    }
                })
        }
        else{
            setSelectItems([])
        }
    }, [ strategies ])

    // useEffect(() => {
    //     const provisoire = selectItemsControle.find((item) => item.libelle === 'Année N provisoire').montant
    //     const definitive = selectItemsControle.find((item) => item.libelle === 'Année N définitive').montant
    //     if(isProvisoire){
    //         setMontantAnomalie(provisoire )
    //     }
    //     else{
    //         setMontantAnomalie(definitive)
    //     }
    //
    // }, [selectItemsControle, isProvisoire, setMontantAnomalie])

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // setEquipeClients([]);
        }
    };

    const handleTabChange = (tabName, e) => {
        e.preventDefault();
        setActiveTab(tabName);
    };

    const handleAdd = (e) => {
        e.preventDefault()
        const newElement = {
            poste: '',
            montantProv: 0,
            montantDef: 0,
            anneN1: 0,
            anneN2: 0,
            commentaire: ''
        };

        setSelectItems([...selectItems, newElement]);
    };

    const handleMontantDefInput = (value, index) => {
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  montantDef: parseInt(value)} : item
        );

        setSelectItems(updatedList);
    }

    const handleMontantProvInput = (value, index) => {
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  montantProv: parseInt(value)} : item
        );

        setSelectItems(updatedList);
    }

    const handleAnneN1Input = (value, index) => {
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  anneN1: parseInt(value)} : item
        );

        setSelectItems(updatedList);
    }

    const handleAnneN2Input = (value, index) => {
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  anneN2: parseInt(value)} : item
        );

        setSelectItems(updatedList);
    }

    const handleMontantInput = (value, index) => {
        const indexUpdate = selectItemsControle.find((item, i) => i === index)
        const updatedList = selectItemsControle.map((item, i) =>
            i === index ? { ...item,  montant: parseInt(value) } : item
        );
        setSelectItemsControle(updatedList);

        if(isProvisoire && (indexUpdate.libelle === "Année N provisoire")){
            setMontantAnomalie(indexUpdate.montant )
        }
        else if(!isProvisoire && (indexUpdate.libelle === "Année N définitive")) {
            setMontantAnomalie(indexUpdate.montant )
        }
    }

    const handleDeleteElement = (index) => {
        const updatedList = selectItems.filter((_, i) => i !== index);
        setSelectItems(updatedList);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            exercice_id: mission?.id,
            client_id: mission?.client_id,
            tauxRetenu: taux,
            tauxRetenuDef: tauxDef,
            commentaireTaux: commentaire,
            commentaireTauxDef: commentaireDef,
            montantProv: selectItemsControle.find((item) => item.libelle === 'Année N provisoire').montant,
            commentaireNProv: selectItemsControle.find((item) => item.libelle === 'Année N provisoire').commentaire,
            monatantDef: selectItemsControle.find((item) => item.libelle === 'Année N définitive').montant,
            commentaireNDefC: selectItemsControle.find((item) => item.libelle === 'Année N définitive').commentaire,
            montantN1: selectItemsControle.find((item) => item.libelle === 'Année N-1').montant,
            commentaireN1: selectItemsControle.find((item) => item.libelle === 'Année N-1').commentaire,
            montantN2: selectItemsControle.find((item) => item.libelle === 'Année N-2').montant,
            commentaireN2: selectItemsControle.find((item) => item.libelle === 'Année N-2').commentaire,
            strategie_id: strategies?.id,
            codeSommaire ,
            heureEntree,
            date,
            tache_id,
            intervention_id,
            isProvisoire
        };

        const dataNiveau = {
            exercice_id: mission.id,
            client_id: mission?.client_id,
            // sommaire_id,
            materialiteData: selectItems,
            strategie_id: strategies?.id,
            codeSommaire ,
            heureEntree,
            date,
            tache_id,
            intervention_id,
            isProvisoire
        };

        const { status, message } = activeTab === 0 ? await ajouterMaterialitePerformance(data) : await ajouterNiveauMaterialitePerformance(dataNiveau);
        if (status === "success") {
            setLoading(false);
            await swal(succesMessage, "", "success");
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    };


    const columns = [
        {
            title: () => <TableTitle>Postes des E/F</TableTitle>,
            dataIndex: 'anneNDef',
            sorter: true,
            width: 200,
            render: (_, record, index) => (
                <Input.TextArea
                    style={{fontSize: "14px", fontWeight: "600"}}
                    size="large"
                    bordered={false}
                    placeholder="Saisisser le poste..."
                    value={record.poste}
                    maxLength={2000}
                    showCount
                    autoSize={{
                        minRows: 1,
                        maxRows: 5,
                    }}
                    onChange={(e) =>{
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  poste: e.target.value } : item
                        );

                        setSelectItems(updatedList);
                    }}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N DEF</TableTitle>,
            dataIndex: 'montantDef',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.montantDef}
                    name='montantDef'
                    onChange={handleMontantDefInput}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N PROV</TableTitle>,
            dataIndex: 'montantProv',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.montantProv}
                    name='montantProv'
                    onChange={handleMontantProvInput}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N-1</TableTitle>,
            dataIndex: 'anneN1',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.anneN1}
                    name='anneN1'
                    onChange={handleAnneN1Input}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N-2</TableTitle>,
            dataIndex: 'anneN2',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.anneN2}
                    name='anneN2'
                    onChange={handleAnneN2Input}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 300,
            render: (_, record, index) => (
                <>
                    <div className="">
                        <Input.TextArea
                            style={{fontSize: "14px", fontWeight: "600"}}
                            size="large"
                            bordered={false}
                            placeholder="Saisisser le commentaire..."
                            value={record.commentaire}
                            maxLength={2000}
                            showCount
                            autoSize={{
                                minRows: 2,
                                maxRows: 8,
                            }}
                            onChange={(e) =>{
                                const updatedList = selectItems.map((item, i) =>
                                    i === index ? { ...item,  commentaire: e.target.value } : item
                                );
                                setSelectItems(updatedList);
                            }}
                        />
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 70,
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteElement(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    const columnsConclusion = [
        {
            title: () => <TableTitle>Période</TableTitle>,
            dataIndex: 'periode',
            sorter: true,
            width: 200,
            render: (_, record) => (
                <div className='d-flex align-center fs-4 fw-bolder'>
                    {record.libelle}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Montant</TableTitle>,
            width: 200,
            dataIndex: 'montant',
            render: (_, record, index) => (
                <ControlInputNumber
                    value={record.montant}
                    name='montant'
                    onChange={handleMontantInput}
                    index={index}
                />
            )
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 300,
            render: (_, record, index) => (
                <>
                    <div className="">
                        <Input.TextArea
                            style={{fontSize: "14px", fontWeight: "600"}}
                            size="large"
                            bordered={false}
                            placeholder="Saisisser le commentaire..."
                            value={record.commentaire}
                            maxLength={2000}
                            showCount
                            autoSize={{
                                minRows: 2,
                                maxRows: 8,
                            }}
                            onChange={(e) =>{
                                const updatedList = selectItemsControle.map((item, i) =>
                                    i === index ? { ...item,  commentaire: e.target.value } : item
                                );
                                setSelectItemsControle(updatedList);
                            }}
                        />
                    </div>
                </>
            ),
        },
    ];

    return(
        <>
            <div className="py-10">
                <div className="d-flex justify-content-end align-items-end mb-10">
                    <Tooltip title="Enregistrer">
                        <Button
                            disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            size={"large"}
                            // loading={loading}
                            style={{
                                backgroundColor: appTheme.colors.secondary,
                                color: "white"
                            }}
                            icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                            onClick={handleSubmit}
                        >
                            {!loading &&
                                <span className='indicator-label text-white'>Enregistrer</span>}
                            {loading && (
                                <span className='indicator-progress fs-9 text-white'
                                      style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                    </Tooltip>
                </div>
                <div className='d-flex justify-content-between align-center'>
                    <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-4">
                        {/* begin::Tab item */}
                        <li className="nav-item bg-hover-light-primary">
                            <Tooltip title='Matérialité de performance'>
                                <button
                                    className={`nav-link text-active-primary fs-4 text-uppercase fw-bolder pb-4 ${activeTab === 0 ? 'active' : ''}`}
                                    onClick={(e) => handleTabChange(0, e)}
                                >
                                    Matérialité de performance
                                </button>
                            </Tooltip>
                        </li>
                        {/* end::Tab item */}
                        {/* begin::Tab item */}
                        <li className="nav-item bg-hover-light-primary">
                            <Tooltip title='Niveaux de matérialité des performances acquise'>
                                <button
                                    className={`nav-link text-active-primary fs-4 text-uppercase fw-bolder pb-4 ${activeTab === 1 ? 'active' : ''}`}
                                    onClick={(e) => handleTabChange(1, e)}
                                >
                                    Niveaux de matérialité
                                </button>
                            </Tooltip>
                        </li>
                        {/* end::Tab item */}
                    </ul>
                </div>
                {/* end::Tabs */}
                <div className="separator"></div>
                {/* begin::Tab content */}
                <form className="stepper-form">
                    <div className="tab-content" id="myTabContent">
                        {/* begin::Tab pane */}
                        <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`} role="tabpanel">
                            <div className="fw-bold text-gray-600 fs-5 mb-5 mt-5 mx-auto" style={{fontSize: '14px'}}>
                                (ISA 320.11, ISA 320.14)
                                <br/> Définissez la matérialité pour les performances (contrôles) sur un montant basé sur la matérialité globale mais inférieur à celle-ci
                                (par exemple entre <strong>60 %</strong>, <strong>70 %</strong> et <strong>85 %</strong>de la matérialité globale.
                                <br/>  Faites appel à votre jugement professionnel pour anticiper les inexactitudes pouvant survenir au cours de la période actuelle.
                                Prenez en compte les risques commerciaux de fraude identifiés (formulaire 502E ou équivalent), les résultats des procédures d'évaluation des anomalies
                                recensées lors des audit précédents.
                                <br/> La matérialité de la performance est calculée sur la base
                            </div>
                            <Row gutter={[16, 16]}>
                                <Col
                                    xs={24}
                                    md={24}
                                    lg={10}
                                    xl={10}
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-column me-10">
                                            <div className="text-gray-500 fw-bold fs-5 mb-3 mt-5">Taux Prov retenu:</div>
                                            <div className="d-flex flex-column me-5 mb-10">
                                                <div className="d-flex text-gray-700 fw-bold fs-7">
                                                    <Select
                                                        style={{width: "200px", fontSize: '14px' }}
                                                        placeholder="Sélectionner le taux provisoire... "
                                                        onChange={(value) => {
                                                            setTaux(parseFloat(value))
                                                            if(isProvisoire) {
                                                                setMontantAnomalie((materialiteValue.montantNProv || 0) * parseFloat(value) / 100 )

                                                                selectItemsControle.find((item) => item.libelle === 'Année N-2').montant = (materialiteValue.montantN2 || 0) * parseFloat(value) / 100
                                                                selectItemsControle.find((item) => item.libelle === 'Année N-1').montant = (materialiteValue.montantN1 || 0) * parseFloat(value) / 100
                                                            }
                                                            selectItemsControle.find((item) => item.libelle === 'Année N provisoire').montant = (materialiteValue.montantNProv || 0) * parseFloat(value) / 100
                                                        }}
                                                        value={taux ?? null}
                                                        className="me-15"
                                                        size="large"
                                                        options={[85, 70, 60].map((item) => ({ label: item, value: item }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column me-10">
                                            <div className="text-gray-500 fw-bold fs-5 mb-3 mt-5">Taux Def retenu:</div>
                                            <div className="d-flex flex-column me-5 mb-10">
                                                <div className="d-flex text-gray-700 fw-bold fs-7">
                                                    <Select
                                                        style={{width: "200px", fontSize: '14px' }}
                                                        placeholder="Sélectionner le taux définitif... "
                                                        onChange={(value) => {
                                                            setTauxDef(parseFloat(value))
                                                            if(!isProvisoire) {
                                                                setMontantAnomalie((materialiteValue.montantNDef || 0) * parseFloat(value) / 100 )

                                                                selectItemsControle.find((item) => item.libelle === 'Année N-2').montant = (materialiteValue.montantN2 || 0) * parseFloat(value) / 100
                                                                selectItemsControle.find((item) => item.libelle === 'Année N-1').montant = (materialiteValue.montantN1 || 0) * parseFloat(value) / 100
                                                            }
                                                            selectItemsControle.find((item) => item.libelle === 'Année N définitive').montant = (materialiteValue.montantNDef || 0) * parseFloat(value) / 100
                                                        }}
                                                        value={tauxDef ?? null}
                                                        className="me-15"
                                                        size="large"
                                                        options={[85, 70, 60].map((item) => ({ label: item, value: item }))}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col
                                    xs={24}
                                    md={24}
                                    lg={14}
                                    xl={14}
                                >
                                    <div className="d-flex flex-column w-100">
                                        <FormLabel label={isProvisoire ? "Commentaire provisoire :" : "Commentaire définitif :"}/>
                                        <div className="">
                                            <Input.TextArea
                                                style={{fontSize: "14px", fontWeight: "600"}}
                                                size="large"
                                                placeholder="Saisisser le commentaire..."
                                                value={isProvisoire ? commentaire : commentaireDef}
                                                maxLength={2000}
                                                showCount
                                                autoSize={{
                                                    minRows: 4,
                                                    maxRows: 8,
                                                }}
                                                onChange={(e) => {isProvisoire ? setCommentaire(e.target.value) : setCommentaireDef(e.target.value)}}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <div className="table-responsive pt-5 pb-10">
                                <Table
                                    rowClassName={() =>
                                        `h-3px p-0 m-0 lh-0.5`
                                    }
                                    columns={columnsConclusion}
                                    rowKey={(record, index) => index}
                                    dataSource={selectItemsControle ?? []}
                                    pagination={tableParams.pagination}
                                    loading={loading}
                                    onChange={handleTableChange}
                                    rowSelection
                                    size={"small"}
                                />
                            </div>
                            {/* end::Products */}
                        </div>
                        {/* end::Tab pane */}
                        {/* begin::Tab pane */}
                        <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} role="tabpanel">
                            {/* begin::Products */}
                            <div className="fw-bold text-gray-600 mb-2 mt-5 mx-auto" style={{fontSize: '14px'}}>
                                (Lorsque cela est jugé nécessaire pour faire face aux risques particuliers d'anomalies dans une catégorie d'opérations, le solde du compte ou la publication des états financiers)
                            </div>
                            <div className="table-responsive">
                                <div className="d-flex flex-column me-5 mb-3">
                                    <div className="d-flex text-gray-700 fw-bold fs-7 mt-5">
                                        <button
                                            onClick={handleAdd}
                                            className="btn btn-sm fs-5 fw-bolder btn-primary"
                                        >
                                            Ajouter un champ
                                        </button>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <Table
                                        rowClassName={() =>
                                            `h-3px p-0 m-0 lh-0.5`
                                        }
                                        columns={columns}
                                        rowKey={(record) => record.id}
                                        dataSource={selectItems}
                                        pagination={tableParams.pagination}
                                        loading={loading}
                                        onChange={handleTableChange}
                                        rowSelection
                                        size={"small"}
                                    />
                                </div>
                            </div>
                            {/* end::Products */}
                        </div>
                        {/* end::Tab pane */}
                    </div>
                </form>
                {/* end::Tab content */}
            </div>
        </>
    )
}
export {MaterialitePerformance}