import React, {useEffect, useState} from "react";
import {BilanActifEA} from "./BilanActifEA";
import {BilanPassifEA} from "./BilanPassifEA";
import {CompteResultatEA} from "./CompteResultatEA";
import {SigTab} from "./SigTabEA";
import {getDetailsEtatsFinancier} from "../../../../../../../data/balance/etatFinancier";
import {Button, notification, Tooltip} from "antd";
import {SwapOutlined} from "@ant-design/icons";
import {appTheme} from "../../../../../../../config/theme";



const SIG = ["XA", "XB", "XC", "XD", "XE", "XF", "XG", "XH", "XI"]
const MenuTabs = ["Bilan Actifs", "Bilan Passifs", "Compte Résultat", "SIG"]

const TraitementExamenAnalytiqueEF = ({
        client,
        mission,
        actifHistory,
        passifHistory,
        resultHistory,
          setActifHistory,
          setPassifHistory,
          setResultHistory,
          sigTabHistory,
          setSigTabHistory,
          isProvisoire,
          chargement,
          setIsTaskSave,
    }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [bilanActif, setBilanActif] = useState([]);
    const [bilanPassif, setBilanPassif] = useState([]);
    const [compteResult, setCompteResult] = useState([]);
    const [sigData, setSigData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchDetailsEtatsFinancier = async (id) => {
            setLoading(true)
            try {
                const res = await getDetailsEtatsFinancier(id);
                if (res.result && res.result.length > 0) {
                    const actifs = res.result[0]?.Lea_BilanActifs?.filter((item) => item.montant !== 0 && item.montantN1 !== 0 && item.isProvisoire === (parseInt(isProvisoire )=== 1));
                    const passifs = res.result[0]?.Lea_BilanPassifs?.filter((item) => item.montant !== 0 && item.montantN1 !== 0 && item.isProvisoire === (parseInt(isProvisoire) === 1));
                    const results = res.result[0]?.Lea_CompteResultats?.filter((item) => item.montant !== 0 && item.montantN1 !== 0 && item.isProvisoire === (parseInt(isProvisoire) === 1));

                    if (actifs && actifs.length > 0) {
                        setBilanActif(actifs);
                    } else{
                        setBilanActif([])
                    }

                    if (passifs && passifs.length > 0) {
                        setBilanPassif(passifs.sort((a, b) => {
                            return a.ref.localeCompare(b.ref);
                        }));
                    } else{
                        setBilanPassif([])
                    }

                    if (results && results.length > 0) {
                        const resultats = results.sort((p1, p2) => {
                            return new Date(p2.createdAt) - new Date(p1.createdAt);
                        })
                        setCompteResult(resultats);
                        const sigTab = resultats.filter((item) => SIG.includes(item.ref))
                        setSigData(sigTab)
                    } else{
                        setCompteResult([])
                        setSigData([])
                    }
                    setLoading(false)
                }
                setLoading(false)
            } catch (error) {
                console.error('Error fetching details états financier data:', error);
            }
        };

        if (mission) {
            fetchDetailsEtatsFinancier(mission.id);
        }
    }, [mission, isProvisoire]);

    const handleResultatClick = () => {
        setLoading(true);
        setTimeout(() => {
            if(actifHistory && actifHistory.length > 0 ){
                setActifHistory([]);
            }
            setActifHistory(bilanActif.map(({ ref, libelle, montant, montantN1,  }) => ({ compte: ref, libelle, soldeN: montant, soldeN_1: montantN1, commentaire: "" })));
            notification.warning({
                description: "Actifs envoyé avec succès a l'historique EF !",
            });

            if(passifHistory && passifHistory.length > 0 ){
                setPassifHistory([]);
            }
            setPassifHistory(bilanPassif.map(({ ref, libelle, montant, montantN1,  }) => ({ compte: ref, libelle, soldeN: montant, soldeN_1: montantN1, commentaire: "" })));
            notification.warning({
                description: "Passif envoyé avec succès a l'historique EF !",
            });

            if(resultHistory && resultHistory.length > 0 ){
                setResultHistory([]);
            }
            setResultHistory(compteResult.map(({ ref, libelle, montant, montantN1, }) => ({ compte: ref, libelle, soldeN: montant, soldeN_1: montantN1, commentaire: "" })));
            notification.warning({
                description: "Compte de Résultat envoyé avec succès a l'historique EF !",
            });

            if(sigTabHistory && sigTabHistory.length > 0 ){
                setSigTabHistory([]);

            }
            const sigTab = compteResult.filter((item) => SIG.includes(item.ref));
            setSigTabHistory(sigTab.map(({ ref, libelle, montant, montantN1, }) => ({ compte: ref, libelle, soldeN: montant, soldeN_1: montantN1, commentaire: "" })));

            setIsTaskSave(prevState => [...prevState, 1]);
            notification.warning({
                description: "Envoyé avec succès a l'historique EF !",
            });
            setLoading(false)
        }, 1500)
    }

    const handleTabChange = (tabName) => {
        setLoading(true);
        setTimeout(() => {
            setActiveTab(tabName);
            setLoading(false)
        }, 1000)
    };

    return(
        <div className="py-2">
            <div className="flex-lg-row-fluid">
                <div className='d-flex justify-content-between align-center'>
                    <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-3">
                        {(MenuTabs ?? []).map((menu, index) => (
                            <li key={index} className="nav-item">
                                <button
                                    style={{fontWeight: "600", fontFamily: "Oswald"}}
                                    className={`nav-link text-active-primary text-uppercase pb-4 fs-6 ${activeTab === (index + 1) ? 'active' : ''}`}
                                    onClick={() => handleTabChange(index + 1)}
                                >
                                    {menu}
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className="d-flex justify-content-center align-content-center flex-wrap">
                        <div className="d-lg-flex d-sm-grid align-lg-center flex-wrap">
                            <div className="me-10">
                                <Tooltip title="Envoyer a l'historique">
                                    <Button
                                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1) || (bilanActif.length <= 0 && bilanPassif.length <= 0 && compteResult.length <= 0)}
                                        size={"large"}
                                        loading={loading || chargement}
                                        style={{
                                            backgroundColor: appTheme.colors.primary_blue,
                                            color: "white"
                                        }}
                                        icon={<SwapOutlined
                                            style={{fontSize: '20px', marginRight: '5px'}}/>}
                                        onClick={handleResultatClick}
                                    >
                                        {!loading &&
                                            <span className='indicator-label text-white'>Résultat</span>}
                                        {loading && (
                                            <span className='indicator-progress fs-9 text-white'
                                                  style={{display: 'block'}}>
                                              Patientez svp...
                                              <span
                                                  className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <form className="stepper-form">
                    <div className="tab-content" id="myTabContent">
                        {/* begin::Tab pane */}
                        <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} role="tabpanel">
                            {/* begin::Products */}
                            <BilanActifEA
                                mission={mission}
                                client={client}
                                isProvisoire={isProvisoire}
                                bilanActif={bilanActif}
                                chargement={chargement || loading}
                            />
                            {/* end::Products */}
                        </div>
                        <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`} role="tabpanel">
                            {/* begin::Products */}
                            <BilanPassifEA
                                mission={mission}
                                client={client}
                                isProvisoire={isProvisoire}
                                bilanPassif={bilanPassif}
                                chargement={chargement || loading}
                            />
                            {/* end::Products */}
                        </div>
                        <div className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`} role="tabpanel">
                            {/* begin::Products */}
                            <CompteResultatEA
                                mission={mission}
                                client={client}
                                isProvisoire={isProvisoire}
                                compteResult={compteResult}
                                chargement={chargement || loading}
                            />
                            {/* end::Products */}
                        </div>
                        <div className={`tab-pane fade ${activeTab === 4 ? 'show active' : ''}`} role="tabpanel">
                             {/*begin::Products */}
                            <SigTab
                                mission={mission}
                                client={client}
                                isProvisoire={isProvisoire}
                                sigData={sigData}
                                chargement={chargement || loading}
                            />
                             {/*end::Products */}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default TraitementExamenAnalytiqueEF