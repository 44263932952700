import React from 'react';
import {postesStat} from "./TraitementConceptionControles";

const PrintConceptionControle = ({datas, title}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        minHeight: "20px"
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <div className="card">
            <div className="table-responsive mb-5">
                <div className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className=" w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={{...thStyle, fontSize: "11px", fontWeight: "bold"}} className="w-200px" rowSpan={2}>Incidence (effet) du facteur de risque</th>
                                <th style={{...thStyle, fontSize: "11px", fontWeight: "bold"}} className="w-100px" rowSpan={2}>Assertions <br/> (C, A, E, V, D)</th>
                                <th style={{...thStyle, fontSize: "11px", fontWeight: "bold"}} className="w-100px" rowSpan={2}>Postes</th>
                                <th style={{...thStyle, fontSize: "11px", fontWeight: "bold"}} className="w-100px" rowSpan={2} >Niveau du risque <br/> (Faible, Moyen, Elevé)</th>
                                <th style={{...thStyle, fontSize: "11px", fontWeight: "bold"}} className="w-350px" rowSpan={1} colSpan={2} >Questions/Réponses</th>
                                <th style={{...thStyle, fontSize: "11px", fontWeight: "bold"}} className="w-250px" rowSpan={2} >Procédures</th>
                            </tr>
                            <tr style={thStyle}>
                                <th style={{...thStyle, fontSize: "11px", fontWeight: "bold"}} className="w-150px">Questions</th>
                                <th style={{...thStyle, fontSize: "11px", fontWeight: "bold"}} className="w-150px">Réponses</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(datas ?? []).map((data, rubriqueIndex) => (
                                data.questions.map((question, eventIndex) => {
                                    // const colSpanEvent = (eventIndex === 0) ? data.questions.length : 1;
                                    const incidenceLibelle =  data.incidence || '';
                                    const assertions = data.assertions || [];
                                    const postes = data.postes || [];
                                    const niveauLibelle = data.niveauLibelle || "";
                                    const questionLibelle = question.questionLibelle || "";
                                    const reponseLibelle = question.reponse || "";
                                    const procedures = data.procedures || [];

                                    return (
                                        <tr style={tdStyle} className="" key={`${rubriqueIndex}-${eventIndex}`}>
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "11px", textAlign: "start"}} rowSpan={data.questions.length}>
                                                    {incidenceLibelle}
                                                </td>
                                            )}
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "11px"}} rowSpan={data.questions.length}>
                                                    <div className='d-flex align-center '>
                                                        {assertions?.map((assertion, assertionIndex) => (
                                                            <span className='fw-bolder me-5' key={assertionIndex}>
                                                              {`${assertion}, `}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </td>
                                            )}
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "11px"}} rowSpan={data.questions.length}>
                                                    <div className='d-flex flex-column align-center '>
                                                        {(postes ?? [])?.map((poste, posteIndex) => (
                                                            <span className='fw-bolder me-5' key={posteIndex}>
                                                              {`${poste || ""} - ${(postesStat ?? []).find( opt => opt.key === poste)?.title || ""} `}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </td>
                                            )}
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "11px"}} rowSpan={data.questions.length}>
                                                    {niveauLibelle}
                                                </td>
                                            )}
                                            <td style={{...tdStyle, fontSize: "11px", textAlign: "start"}}>
                                                {questionLibelle}
                                            </td>
                                            <td style={{...tdStyle, fontSize: "11px", textAlign: "start"}}>
                                                {reponseLibelle}
                                            </td>
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "11px", textAlign: "start"}} rowSpan={data.questions.length}>
                                                    {procedures.map((opt, indexOpt) => (
                                                        <div  key={indexOpt} className='d-flex flex-column'>
                                                       <span className='' style={{fontSize: '10px'}}>
                                                           <strong style={{fontSize: '13px', fontWeight: "bold", marginRight: "2%"}}>.</strong>{opt.libelleProcedure}
                                                       </span>
                                                        </div>
                                                    ))}
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })
                            ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrintConceptionControle;
