import React, { useEffect, useState } from "react";
import {
    ClockCircleOutlined,
    ReconciliationFilled,
    UserOutlined
} from "@ant-design/icons";
import {getListeNormeISA} from "../../../data/normes";
import {DOWNLOAD_FILE_CLIENT} from "../../../api/apiRoutes";
import {Button, Col, DatePicker, Input, notification, Space, Table, Tooltip} from "antd";
import moment from "moment/moment";
import TableTitle from "../../../components/common/TableTitle";
import {FormContainer, TagItem} from "../../../components/common/common.components";
import {appTheme} from "../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import FormLabel from "../../../components/common/Form/FormLabel";
import SearchRow from "../../../components/common/SearchRow";
import PageLabel from "../../../components/common/PageLabel/PageLabel";

const NormesISAAll = () => {
    const [loading, setLoading] = useState(false);
    const [filtersItems, setFiltersItems] = useState([]);
    const [normes, setNormes] = useState([]);

    const [paramsRecherche, setParamsRecherche] = useState({
        infoFile: "",
        date_debut: "",
        date_fin: "",
    });

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setNormes([]);
        }
    };

    const fetchDocuments = async () => {
        try {
            const res = await getListeNormeISA()
            if (res.result) {
                if (res.result) {
                    setNormes(res.result)
                    setFiltersItems(res.result)
                }
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des événements risques', error)
        }
    }

    useEffect(() => {
        fetchDocuments();
    }, [])

    const downloadDocumentsClient = (fichier) => {
        setLoading(true)
        setTimeout(() => {
            window.open(`${DOWNLOAD_FILE_CLIENT}/NORMES/${fichier}`, '_blank')
            setLoading(false);
            notification.success({
                description: "Télécharger avec succès",
            });
        }, 1500);
    }

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 500,
            render: (_, record) => (
                <div className='d-flex align-items-center'>
                    <span className='svg-icon svg-icon-2x svg-icon-primary me-4'>
                      <ReconciliationFilled className="text-primary me-2 fs-6"/>
                    </span>
                    <div style={{
                        fontFamily: "Oswald, sans-serif",
                        fontSize: "14px", fontWeight: '600'
                    }}>
                        {record.libelle}
                    </div>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Taille (KO)</TableTitle>,
            dataIndex: 'size',
            width: 100,
            render: (_, record) => (
                <TagItem>{((record.size || 0) / 1024).toFixed(2)}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Dernière modif.</TableTitle>,
            dataIndex: 'modif',
            width: 150,
            render: (_, record) => (
                <div className='d-flex flex-column align-center'>
                    <span className='chart-orange display-block f-size-12 pe-5' style={{fontWeight: 600, fontSize: '11px'}}>
                        <UserOutlined style={{fontSize: '15px'}} /> {(record.Lea_User?.nom_user || "") + ' ' + (record.Lea_User?.prenom_user || "")}
                    </span>
                    <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                        <ClockCircleOutlined style={{fontSize: '12px'}} />{' '}
                        {moment(record.updatedAt).format('DD/MM/YYYY à HH:mm')}
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 100,
            render: (_, record) => (
                <Space>
                    <Tooltip color={appTheme.colors.primary} title="Téléchager le fichier">
                        <Button
                            shape="circle"
                            size={"middle"}
                            style={{backgroundColor: appTheme.colors.primary}}
                            onClick={() => downloadDocumentsClient(record.fichier)}
                            icon={<FontAwesomeIcon icon={faDownload} color={"white"} />}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = normes.filter(file => {
                const referenceMatch = file.reference?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const libelleMatch = file.libelle?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const descriptionMatch = file.description?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const userMatch = paramsRecherche.user_id === "" || file.Lea_User?.id === paramsRecherche.user_id;
                const createdAtMatch = (paramsRecherche.date_debut === "" && paramsRecherche.date_fin === "") || moment(file.createdAt).isBetween(
                    moment(paramsRecherche.date_debut),
                    moment(paramsRecherche.date_fin)
                );

                return (referenceMatch || descriptionMatch || libelleMatch) && userMatch && createdAtMatch;
            });

            setFiltersItems(filteredClients);
            setLoading(false);
        }, 2000);
    };


    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            infoFile: "",
            user_id: "",
            date_debut: "",
            date_fin: "",
        });
        setFiltersItems(normes)
        setLoading(false);
    };

    return (
        <>
            <div className="w-100">
                <PageLabel label="Liste des normes" />
                <SearchRow
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    loading={loading}
                >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}
                        xl={{ span: 12 }}
                    >
                        <FormLabel label="Fichier" />
                        <Input
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Reference ou libellé ou description"
                            value={paramsRecherche.infoFile || null}
                            onChange={(e) =>
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    infoFile: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 6 }}
                        xl={{ span: 6 }}
                    >
                        <FormLabel label="Date debut" />
                        <DatePicker
                            size="large"
                            placeholder="Date début de la création"
                            style={{ width: "100%" }}
                            allowClear
                            onChange={(date, dateString) =>
                                date !== null
                                    ? setParamsRecherche({
                                        ...paramsRecherche,
                                        date_debut: date.format("YYYY-MM-DD"),
                                    })
                                    : setParamsRecherche({ ...paramsRecherche, date_debut: "" })
                            }
                            format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 6 }}
                        xl={{ span: 6 }}
                    >
                        <FormLabel label="Date fin" />
                        <DatePicker
                            size="large"
                            placeholder="Date fin  de la création"
                            style={{ width: "100%" }}
                            allowClear
                            onChange={(date, dateString) =>
                                date !== null
                                    ? setParamsRecherche({
                                        ...paramsRecherche,
                                        date_fin: date.format("YYYY-MM-DD"),
                                    })
                                    : setParamsRecherche({ ...paramsRecherche, date_fin: "" })
                            }
                            format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                        />
                    </Col>
                </SearchRow>
                <FormContainer>
                    <div className="w-100">
                        <div className="py-4">
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    rowKey={(record) => record.id}
                                    dataSource={filtersItems}
                                    pagination={tableParams.pagination}
                                    loading={loading}
                                    onChange={handleTableChange}
                                    rowSelection
                                    size={"small"}
                                />
                            </div>
                        </div>
                    </div>
                </FormContainer>
            </div>

        </>
    );
}

export {NormesISAAll}