import axios from 'axios'
import { AJOUTER_PROFIL, LISTE_PROFIL, MODIFIER_ETAT_PROFIL, MODIFIER_PROFIL, SUPPRIMER_PROFIL } from '../../../api/apiRoutes'
import {setAuthorizationHeader} from "../../../api/config";


export const addProfil = async (data) => {
    setAuthorizationHeader()
  return axios
    .post(AJOUTER_PROFIL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateProfil = async (data) => {
    setAuthorizationHeader()
  return axios
    .post(MODIFIER_PROFIL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const getListeProfil = async () => {
    setAuthorizationHeader()
  return axios
    .get(LISTE_PROFIL)
    .then((res) => {
      if (res.status === 200) {
        return res.data.result
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const deleteProfil = async (data) => {
    setAuthorizationHeader()
  return axios
    .post(SUPPRIMER_PROFIL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}

export const updateEtatProfil = async (data) => {
    setAuthorizationHeader()
  return axios
    .post(MODIFIER_ETAT_PROFIL, data)
    .then((res) => {
      if (res.status === 200) {
        return res.data
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
