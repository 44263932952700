import {
    AuditOutlined, CheckSquareOutlined,
    FolderFilled,
    FolderOpenFilled,
    ReconciliationOutlined
} from "@ant-design/icons";
import React, {useEffect, useState, useCallback} from "react";
import {getDetailsRisqueEvaluationAll} from "../../../data/risqueEvaluation";
import TreeComponent from "../../layout/tools/TreeComponents";
import {LettreMission} from "../../client/modals/missionSteps/EB/LettreMission";
import PropTypes from "prop-types";
import {CycleFyles} from "../../client/modals/missionSteps/FILES/CycleFyles";

const ProgressIndicator = ({ data, completedCount, totalCount }) => {
    const progressPercent = (completedCount / totalCount) * 100;

    return (
        <>
            <div className="bg-white d-flex border border-gray-300 border-dashed rounded p-3 mb-2">
                <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">

                    <div className='d-flex align-center'>
                        <div className="me-10">
                            <div className="symbol symbol-50px me-4">
                                <span className="symbol-label">
                                    <span
                                        className="svg-icon svg-icon-2qx svg-icon-primary">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <rect x="2" y="2" width="9"
                                                  height="9" rx="2"
                                                  fill="currentColor"></rect>
                                            <rect opacity="0.3" x="13" y="2"
                                                  width="9" height="9"
                                                  rx="2"
                                                  fill="currentColor"></rect>
                                            <rect opacity="0.3" x="13"
                                                  y="13" width="9"
                                                  height="9" rx="2"
                                                  fill="currentColor"></rect>
                                            <rect opacity="0.3" x="2" y="13"
                                                  width="9" height="9"
                                                  rx="2"
                                                  fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                </span>
                            </div>
                            <a className="text-gray-800 text-hover-primary fs-6 fw-bolder">Sommaire complètés</a>
                            {/*<a className="text-gray-800 text-hover-primary fs-6 fw-bolder"><Progress  style={{ width: 250 }} percent={progressPercent} /></a>*/}
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="text-dark fw-boldest fs-2x">{completedCount}</span>
                            <span className="fw-bold fs-2 text-gray-600 mx-1 pt-1">/</span>
                            <span className="text-gray-600 fw-bold fs-2 me-3 pt-2">{totalCount}</span>
                            <span className="badge badge-lg badge-success align-self-center px-2">{parseInt(progressPercent)} %</span>
                        </div>
                        {/*<div className="progress-indicator">*/}
                        {/*    <Progress percent={progressPercent} />*/}
                        {/*</div>*/}
                    </div>
                </div>
                { data ?
                    <div className="d-flex align-items-center bg-light-warning rounded p-0 mb-1">
                        <span className="svg-icon svg-icon-warning me-5">
                            <span className="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                     height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3"
                                          d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                                          fill="currentColor"></path>
                                    <path
                                        d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                                        fill="currentColor"></path>
                                </svg>
                            </span>
                        </span>
                        <div className="flex-grow-1 me-2">
                            <label className='col-form-label fw-bold fs-6'>
                                <span>Etape en cours: </span>
                            </label>
                            <a className="fw-bolder text-gray-800 text-hover-primary fs-6">{` ${data?.title}`}</a>
                        </div>
                    </div>
                    :
                    <div className="d-flex align-items-center bg-light-success rounded p-2 mb-1">
                        <span className="svg-icon svg-icon-2x svg-icon-primary">
                            <CheckSquareOutlined style={{color: 'green'}} />
                        </span>
                    </div>
                }

            </div>
        </>
    );
};


const MissionSommaireClient = ({
           mission,
           client,
           listQuestionAdd,
           userSommaire,
           setCode,
           next,
           setModalId,
           clientSommaire,
       }) => {
    const [isAcceptation, setIsAcceptation] = useState(false);
    const [risqueIncidences, setRisqueIncidences] = useState([]);
    const [selectedNode, setSelectedNode] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModalId, setSelectedModalId] = useState(null);
    const [codeSommaire, setCodeSommaire] = useState("");

    useEffect(() => {
        const fetchRisqueDetails = async () => {
            try {
                const res = await getDetailsRisqueEvaluationAll(mission.id);
                if (res.result.length > 0) {
                    const arrayData = res.result.flatMap(event => (
                        event.Lea_RisqueIdentificationDetails.map(detail => ({
                            id: detail.id,
                            incidence: detail.incidence,
                            postes: detail.Lea_RisqueIdentificationAssertions[0]?.postes,
                            assertions: detail.Lea_RisqueIdentificationAssertions[0]?.assertions,
                            probabiliteValue: detail.Lea_RisqueEvaluations[0]?.probabiliteValue || '',
                            impactValue: detail.Lea_RisqueEvaluations[0]?.impactValue || '',
                        }))
                    ));
                    const updateItem = arrayData.filter(data => (data.impactValue * data.probabiliteValue) > 5);
                    setRisqueIncidences(updateItem);
                }
            } catch (error) {
                console.error("Erreur", error);
            }
        };

        fetchRisqueDetails();
    }, [mission.id]);

    const isProcedure = useCallback((code) => {
        return risqueIncidences.some(item => item.postes?.includes(code));
    }, [risqueIncidences]);

    const isSommaire = useCallback((code) => {
        return clientSommaire.some(item => item.libelle.includes(code)) && userSommaire?.sommaires?.some(item => item.includes(code));
    }, [clientSommaire, userSommaire]);

    const isSommaireComplete = useCallback((code) => {
        let isComplete = true;
        let sommairesList = clientSommaire.filter(item => item.libelle.includes(code));
        if (code.includes('EE3')) {
            sommairesList = clientSommaire.filter(item => item.libelle.includes(code)) && isProcedure(code);
        }
        if (sommairesList && sommairesList.length > 0) {
            for (const element of sommairesList) {
                isComplete = !!element.is_complete;
            }
        } else {
            isComplete = false;
        }
        return isComplete;
    }, [clientSommaire, isProcedure]);

    useEffect(() => {
        const isAccept = (mission?.etat === 2 && mission?.lettre_mission === 1) || (isSommaireComplete("EB2") && mission?.lettre_mission === 1);
        setIsAcceptation(isAccept);
    }, [mission, clientSommaire, isSommaireComplete]);

    const handleOpenModal = (sommaire, selectModal = 1, isModal = false) => {
        setCode(sommaire);
        setCodeSommaire(sommaire);
        setModalId(selectModal);

        if (isModal) {
            setSelectedModalId(selectModal);
            setIsModalOpen(prevState => !prevState);
        } else {
            next();
        }
    };

    const handleNodeClick = (node) => {
        setSelectedNode(node);
        if (node.isLeaf && node.onClick) {
            node.onClick();
        }
    };

    // const treeData = missionSommaires.map((sommaire, index) => (
    //     (index >= 0 ? isAcceptation : (''|| null)) && {
    //     key: index,
    //     title: sommaire.libelle,
    //     description: sommaire.description,
    //     icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
    //     status: (isSommaireComplete(sommaire.code || "")  ? 'completed' : isSommaireComplete(missionSommaires[(index - 1) >= 0 ? (index - 1): index].code || "") && !isSommaireComplete(sommaire.code || "") ? 'current' : "") || "",
    //     children: sommaire.children.length > 0 && sommaire.children?.map((item, indexChild) => (
    //         {
    //         key: `${index}-${indexChild}`,
    //         title: item.libelle,
    //         description: item.description,
    //         icon: !item.children ? <AuditOutlined style={{color: '#009ef7'}}/> : selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
    //         onClick: () => { !item.children && handleOpenModal(item.code, item?.modalId) },
    //         leafStatus: (isSommaireComplete(item.code || "")  ? 'completed' : isSommaireComplete(missionSommaires[index].code || "") && !isSommaireComplete(item.code || "") ? 'current' : "") || "",
    //         isLeaf: !item.children || item.children?.length <= 0,
    //         children: item.children?.length > 0 && item.children?.map((opt, indexOpt) => (
    //             {
    //             key: `${opt}-${indexChild}-${indexOpt}`,
    //             title: opt.libelle,
    //             description: opt.description,
    //             icon: <AuditOutlined style={{color: '#009ef7'}}/>,
    //             onClick: () => {!opt.children && handleOpenModal(opt.code, opt?.modalId)},
    //             leafStatus: (isSommaireComplete(opt.code || "")  ? 'completed' : isSommaireComplete(missionSommaires[index].children[indexChild].code || "") && !isSommaireComplete(item.code || "") ? 'current' : "") || "",
    //             isLeaf: !opt.children || opt.children?.length <= 0,
    //             children: opt.children?.length > 0 && opt.children?.map((element, indexElement) => (
    //                 {
    //                 key: `${index}-${indexChild}-${indexOpt}-${indexElement}`,
    //                 title: element.libelle,
    //                 description: element.description,
    //                 icon: <AuditOutlined style={{color: '#009ef7'}}/>,
    //                 onClick: () => {!element.children && handleOpenModal(element?.code, element?.modalId)},
    //                 leafStatus: (isSommaireComplete(element.code || "")  ? 'completed' : isSommaireComplete(missionSommaires[index].children[indexChild].children[indexOpt].code || "") && !isSommaireComplete(element.code || "") ? 'current' : "") || "",
    //                 isLeaf: true,
    //             })),
    //         })),
    //     })),
    // }))

    // console.log("listQuestionAdd", listQuestionAdd)
    // console.log("isSommaire", isSommaire("EB1"))

    const treeData =
        [
            isSommaire("EB") &&{
                key: '1',
                title: 'EB: ACCEPTATION ET MAINTIEN DE LA MISSION',
                description: '',
                icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                status: (mission?.etat === 2 && (mission && mission?.lettre_mission === 1)) || (isSommaireComplete("EB2") && (mission?.lettre_mission === 1))  ? 'completed' : 'current',
                children: [
                    (mission?.isMaintien === 0 || mission?.isMaintien === false) && isSommaire("EB1") &&
                    {
                        key: '1-1',
                        title: (listQuestionAdd && listQuestionAdd.length > 0 && mission?.verou_question === 1) ?
                            ( <div className="d-flex align-items-center pt-4">
                                EB1 - Acceptation de la mission (Affectation)
                            </div>)
                            :
                            (<div className="d-flex align-items-center pt-4">
                                EB1 - Acceptation de la mission
                            </div>),
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {handleOpenModal("EB1", (listQuestionAdd && listQuestionAdd.length > 0 && mission?.verou_question === 1) ? 2 : 1)},
                        leafStatus: (isSommaireComplete("EB1") || listQuestionAdd.length > 0)  ? 'completed' :  'current',
                        isLeaf: true,
                    },
                    ((mission?.isMaintien === 1 || mission?.isMaintien === true) && isSommaire("EB2")) &&
                    {
                        key: '1-2',
                        title: (<div className="d-flex align-items-center pt-4">
                            EB2 - Maintien de la mission
                        </div>),
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {handleOpenModal("EB2")},
                        leafStatus: isSommaireComplete("EB2")  ? 'completed' : 'current',
                        isLeaf: true,
                    },
                    (((mission?.fichier_question === 1) &&  mission?.etat === 2) || (mission?.isMaintien === 1 || mission?.isMaintien === true)) && isSommaire("EB3") &&
                    {
                        key: '1-3',
                        title: 'EB3 - Lettre de mission',
                        icon: <ReconciliationOutlined style={{color: '#009ef7'}}/>,
                        onClick: mission?.fichier_question === 1 ? () => {handleOpenModal("EB3", 1, true)} : () => {},
                        leafStatus: mission.lettre_mission === 1 ? 'completed' :  'current',
                        isLeaf: true,
                    },
                ].filter(Boolean),
            },
            (isAcceptation) && {
                key: '2',
                title: 'EC: PLANIFICATION DE LA MISSION',
                description: '',
                icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                status: isSommaireComplete("EC")  ? 'completed' : (mission?.etat === 2 && (mission && mission?.lettre_mission === 1)) || (isSommaireComplete("EB") && !isSommaireComplete("EC")) ? 'current' : "",
                children: [
                    isSommaire("EC1") && {
                        key: '2-1',
                        title: 'EC1: Demande Initiale de document',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick:  () => {handleOpenModal("EC1", 2, true)},
                        leafStatus: isSommaireComplete("EC1")  ? 'completed' : (mission?.etat === 2 && (mission && mission?.lettre_mission === 1)) || (isSommaireComplete("EB") && !isSommaireComplete("EC1")) ? 'current' : "",
                        isLeaf: true,
                    },
                    isSommaire("EC2") && {
                        key: '2-2',
                        title: 'EC2: Importation des balances et Calcul des états financiers',
                        icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                        status: isSommaireComplete("EC2")  ? 'completed' : isSommaireComplete("EC1") && !isSommaireComplete("EC2") ? 'current' : "",
                        onClick: () => {},
                        children: [
                            isSommaire("EC2.1") && {
                                key: '2-2-1',
                                title: 'EC2.1 – Importation des balances N et N-1',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {handleOpenModal("EC2.1")},
                                leafStatus: isSommaireComplete("EC2.1")  ? 'completed' : isSommaireComplete("EC1") && !isSommaireComplete("EC2.1") ? 'current' : "",
                                isLeaf: true,
                            },
                            isSommaire("EC2.2") && {
                                key: '2-2-2',
                                title: 'EC2.2 – Calcul des états financiers',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                leafStatus: isSommaireComplete("EC2.2")  ? 'completed' : isSommaireComplete("EC2.1") && !isSommaireComplete("EC2.2") ? 'current' : "",
                                onClick: () => {handleOpenModal("EC2.2")},
                                isLeaf: true,
                            },
                        ],
                    },
                    isSommaire("EC3") && {
                        key: '2-3',
                        title: 'EC3: Procédures analytiques préliminaires et définitives',
                        icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                        status: isSommaireComplete("EC3")  ? 'completed' : isSommaireComplete("EC2") && !isSommaireComplete("EC3") ? 'current' : "",
                        children: [
                            isSommaire("EC3.1") && {
                                key: '2-3-1',
                                title: 'EC3.1 – Examen Analytique des Etats financiers',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {handleOpenModal("EC3.1")} ,
                                leafStatus: isSommaireComplete("EC3.1")  ? 'completed' : isSommaireComplete("EC2.2") && !isSommaireComplete("EC3.1") ? 'current' : "",
                                isLeaf: true,
                            },
                            isSommaire("EC3.2") && {
                                key: '2-3-2',
                                title: 'EC3.2 – Calcul des feuilles maitresses',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {handleOpenModal("EC3.2")} ,
                                leafStatus: isSommaireComplete("EC3.1")  ? 'completed' : isSommaireComplete("EC2.2") && !isSommaireComplete("EC3.1") ? 'current' : "",
                                isLeaf: true,
                            },
                            isSommaire("EC3.3") && {
                                key: '2-3-3',
                                title: 'EC3.3 – Contrôle intangibilité des balances (N et N-1)',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {handleOpenModal("EC3.3")},
                                leafStatus: isSommaireComplete("EC3.3")  ? 'completed' : isSommaireComplete("EC3.2.") && !isSommaireComplete("EC3.3") ? 'current' : "",
                                isLeaf: true,
                            },
                            isSommaire("EC3.4") && {
                                key: '2-3-4',
                                title: 'EC3.4 – Test des journaux et des évènements inhabituels',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {handleOpenModal("EC3.4")},
                                leafStatus: isSommaireComplete("EC3.4")  ? 'completed' : isSommaireComplete("EC3.3.") && !isSommaireComplete("EC3.4") ? 'current' : "",
                                isLeaf: true,
                            },
                            isSommaire("EC3.5") && {
                                key: '2-3-5',
                                title: 'EC3.5 – Contrôle balances provisoires et définitives',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                leafStatus: isSommaireComplete("EC3.5")  ? 'completed' : isSommaireComplete("EC3.4.") && !isSommaireComplete("EC3.5") ? 'current' : "",
                                onClick: () => {handleOpenModal("EC3.5")},
                                isLeaf: true,
                            },
                        ].filter(Boolean),
                    },
                    isSommaire("EC4") && {
                        key: '2-4',
                        title: ( <div className="d-flex align-items-center pt-4">
                            EC4 - Stratégie globale d’Audit
                        </div>),
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {handleOpenModal("EC4")},
                        leafStatus: isSommaireComplete("EC4")  ? 'completed' : isSommaireComplete("EC3.5.") && !isSommaireComplete("EC4") ? 'current' : "",
                        isLeaf: true,
                    },
                ].filter(Boolean),
            },
            (isAcceptation ) && {
                key: '3',
                title: 'ED: IDENTIFICATION ET EVALUATION DES RISQUES D’ANOMALIES SIGNIFICATIVE',
                description: '',
                icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                status: isSommaireComplete("ED")  ? 'completed' : isSommaireComplete("EC") && !isSommaireComplete("ED") ? 'current' : "",
                children: [
                    isSommaire("ED0") && {
                        key: '3-1',
                        title: 'ED0 – Synthèse des risques identifiés et évalués',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        leafStatus: isSommaireComplete("ED0")  ? 'completed' : isSommaireComplete("EC") && !isSommaireComplete("ED0") ? 'current' : "",
                        onClick: () => {
                            handleOpenModal("ED0")
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("ED1") && {
                        key: '3-2',
                        title: 'ED1: Risques liés à l’activité',
                        icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                        status: isSommaireComplete("ED1")  ? 'completed' : isSommaireComplete("ED0") && !isSommaireComplete("ED1") ? 'current' : "",
                        onClick: () => {},
                        children: [
                            {
                                key: '3-2-1',
                                title: 'ED1.1 - Identification risques liés à l’activité',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("ED1.1", 1)
                                },
                                leafStatus: isSommaireComplete("ED1.1")  ? 'completed' : isSommaireComplete("ED0") && !isSommaireComplete("ED1.1") ? 'current' : "",
                                isLeaf: true,
                            },
                            {
                                key: '3-2-2',
                                title: 'ED1.2 - Evaluation risques liés à l’activité',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("ED1.2",1)
                                },
                                leafStatus: isSommaireComplete("ED1.2")  ? 'completed' : isSommaireComplete("ED1.1") && !isSommaireComplete("ED1.2") ? 'current' : "",
                                isLeaf: true,
                            },
                        ].filter(Boolean),
                    },
                    isSommaire("ED2") && {
                        key: '3-3',
                        title: 'ED2: Risques liés à la fraude et aux erreurs',
                        icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                        status: isSommaireComplete("ED2")  ? 'completed' : isSommaireComplete("ED1") && !isSommaireComplete("ED2") ? 'current' : "",
                        onClick: () => {},
                        children: [
                            {
                                key: '3-3-1',
                                title: 'ED2.1 - Identification risques liés à la fraude et aux erreurs',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("ED2.1", 2)
                                },
                                leafStatus: isSommaireComplete("ED2.1")  ? 'completed' : isSommaireComplete("ED1") && !isSommaireComplete("ED2.1") ? 'current' : "",
                                isLeaf: true,
                            },
                            {
                                key: '3-3-2',
                                title: 'ED2.2 - Evaluation risques liés à la fraude et aux erreurs',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("ED2.2",2)
                                } ,
                                leafStatus: isSommaireComplete("ED2.2")  ? 'completed' : isSommaireComplete("ED2.1") && !isSommaireComplete("ED2.2") ? 'current' : "",
                                isLeaf: true,
                            },
                        ].filter(Boolean),
                    },
                    isSommaire("ED3") && {
                        key: '3-4',
                        title: 'ED3: Risques liés à l’informatique',
                        icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                        onClick: () => {},
                        children: [
                            {
                                key: '3-4-1',
                                title: 'ED3.1 - Identification risques liés à l’informatique',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("ED3.1", 3)
                                },
                                isLeaf: true,
                            },
                            {
                                key: '3-4-2',
                                title: 'ED3.2 - Evaluation risques liés à l’informatique',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("ED3.2", 3)
                                } ,
                                isLeaf: true,
                            },
                        ].filter(Boolean),
                    },
                    isSommaire("ED4") && {
                        key: '3-5',
                        title: 'ED4: Risques liés au blanchiment d’argent',
                        icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                        onClick: () => {},
                        children: [
                            {
                                key: '3-6-1',
                                title: 'ED4 - Risques liés au blanchiment d’argent',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("ED4", 1)
                                },
                                isLeaf: true,
                            },
                        ].filter(Boolean),
                    },
                    isSommaire("ED5") && {
                        key: '3-6',
                        title: 'ED5: Risques liés au respect des textes légaux et réglementaires',
                        icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                        onClick: () => {},
                        children: [
                            {
                                key: '3-6-1',
                                title: 'ED5 - Risques liés au respect des textes légaux et réglementaires',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("ED5", 2)
                                },
                                isLeaf: true,
                            },
                        ].filter(Boolean),
                    },
                ].filter(Boolean),
            },
            (isAcceptation) && {
                key: '4',
                title: 'EE: REPONSE A L’EVALUATION DES RISQUES ET COLLECTES DES ELEMENTS PROBANTS',
                description: "",
                icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                status: isSommaireComplete("EE")  ? 'completed' : isSommaireComplete("ED") && !isSommaireComplete("EE") ? 'current' : "",
                children: [
                    isSommaire("EE0") && {
                        key: '4-1',
                        title: 'EE0 – Synthèse de l’identification et de l’évaluation de la conception et du fonctionnement du système de contrôle',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EE0")
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EE1") && {
                        key: '4-2',
                        title: 'EE1 – Identification et évaluation de la conception des contrôles',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EE1")
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EE2") && {
                        key: '4-3',
                        title: 'EE2 – Evaluation du fonctionnement des contrôles',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EE2")
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EE3") && {
                        key: '4-4',
                        title: "EE3: PROCEDURES D'AUDIT COMPLEMENTAIRE",
                        icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                        onClick: () => {},
                        children: [
                            isSommaire("EE3.1") && {
                                key: '4-4-1',
                                title: (<span className={isProcedure("EE3.1") && "text-primary"}>EE3.1 – Immobilisation incorporelles </span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.1", 1)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.2") && {
                                key: '4-4-2',
                                title: (<span className={isProcedure("EE3.2") && "text-primary"}>EE3.2 – Immobilisation corporelles </span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.2", 2)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.3") && {
                                key: '4-4-3',
                                title: (<span className={isProcedure("EE3.3") && "text-primary"}>EE3.3 - Immobilisation financières</span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.3", 3)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.4") && {
                                key: '4-4-4',
                                title: (<span className={isProcedure("EE3.4") && "text-primary"}>EE3.4 - Stocks</span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.4", 4)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.5") && {
                                key: '4-4-5',
                                title: (<span className={isProcedure("EE3.5") && "text-primary"}>EE3.5 - Ventes clients</span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.5", 5)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.6") && {
                                key: '4-4-6',
                                title: (<span className={isProcedure("EE3.6") && "text-primary"}>EE3.6 - Autres créances et dettes</span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.6", 6)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.7") && {
                                key: '4-4-7',
                                title: (<span className={isProcedure("EE3.7") && "text-primary"}>EE3.7 - Trésorerie</span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.7", 7)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.8") && {
                                key: '4-4-8',
                                title: (<span className={isProcedure("EE3.8") && "text-primary"}>EE3.8 - Capitaux propres</span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.8", 8)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.9") && {
                                key: '4-4-9',
                                title: (<span className={isProcedure("EE3.9") && "text-primary"}>EE3.9 - Provisions risques</span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.9", 9)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.10") && {
                                key: '4-4-10',
                                title: (<span className={isProcedure("EE3.10") && "text-primary"}>EE3.10 - Emprunts et assimilés</span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.10", 10)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.11") && {
                                key: '4-4-11',
                                title: (<span className={isProcedure("EE3.11") && "text-primary"}>EE3.11 - Achat fournisseurs</span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.11", 11)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.12") && {
                                key: '4-4-12',
                                title: (<span className={isProcedure("EE3.12") && "text-primary"}>EE3.12 - Impôts et Taxes</span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.12", 12)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.13") && {
                                key: '4-4-13',
                                title: (<span className={isProcedure("EE3.13") && "text-primary"}>EE3.13 - Paie personnel</span>),
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.13", 13)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.14") && {
                                key: '4-4-14',
                                title: "EE3.14 - Contrôle du tableau des flux de Trésorerie (TFT)",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.14", 14)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.15") && {
                                key: '4-4-15',
                                title: "EE3.15 - Contrôle des notes annexes ",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.15",null, false)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.16") && {
                                key: '4-4-16',
                                title: "EE3.16 - Parties liées",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.16",null,false)
                                } ,
                                isLeaf: true,
                            },
                            isSommaire("EE3.17") && {
                                key: '4-4-17',
                                title: "EE3.17 - Mission d'audit initiale - Soldes d'ouvertures",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EE3.17",null,false)
                                } ,
                                isLeaf: true,
                            },
                        ].filter(Boolean),
                    },
                ],
            },
            (isAcceptation) && {
                key: '5',
                title: "EF: TRAVAUX D'AUTRES PROFESSIONNELS",
                description: "",
                icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                status: isSommaireComplete("EF")  ? 'completed' : isSommaireComplete("EE") && !isSommaireComplete("EF") ? 'current' : "",
                children: [
                    isSommaire("EF1") && {
                        key: '5-1',
                        title: 'EF1 - Revue des travaux réalisés par le Co-cac / Expert-Comptable',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EF1", 1, false)
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EF2") && {
                        key: '5-2',
                        title: 'EF2 - Revue des travaux de l’auditeur interne ',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EF2",2,false)
                        },
                        isLeaf: true,
                    },
                    isSommaire("EF3") && {
                        key: '5-3',
                        title: "EF3 - Revue des travaux d’autres Experts ",
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EF3",3,false)
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EF4") && {
                        key: '5-4',
                        title: "Revue Utilisation d’une société de services",
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EF4",4,false)
                        } ,
                        isLeaf: true,
                    },
                ].filter(Boolean),
            },
            (isAcceptation) && {
                key: '6',
                title: "EG : VERIFICATIONS SPECIFIQUES SA / SARL",
                description: "",
                icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                status: isSommaireComplete("EG")  ? 'completed' : isSommaireComplete("EF") && !isSommaireComplete("EG") ? 'current' : "",
                children: [
                    (client && client.libelle_status && client.libelle_status === "SA") && isSommaire("EG1") &&   {
                        key: '6-1',
                        title: "EG1 – Vérifications spécifiques relatives aux SA",
                        icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                        onClick: () => {},
                        children: [
                            {
                                key: '6-1-1',
                                title: 'EG1.1 - Régularité des réunions du Conseil d\'Administration',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG1.1", 1, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-1-2',
                                title: 'EG1.2 - Régularité des AGO',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG1.2", 2, false)
                                },
                                isLeaf: true,
                            },
                            {
                                key: '6-1-3',
                                title: "EG1.3 - Contrôle du respect du droit de communication",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG1.3", 3, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-1-4',
                                title: "EG1.4 - Contrôle des conventions réglementées",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG1.4", 4, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-1-5',
                                title: "EG1.5 - Contrôle des conventions interdites ",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG1.5", 5, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-1-6',
                                title: "EG1.6 - Appréciation du rapport de Gestion",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG1.6", 6, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-1-7',
                                title: "EG1.7 - Contrôle du registre des titres nominatifs",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG1.7", 7, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-1-8',
                                title: "EG1.8 - Contrôle des pactes d'actionnaires",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG1.8", 8, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-1-9',
                                title: "EG1.9 - Procédures d'alerte",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG1.9", 9, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-1-10',
                                title: "EG1.10 - Contrôle des cessions de biens des actionnaires à la société",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG1.10", 10, false)
                                } ,
                                isLeaf: true,
                            },
                        ].filter(Boolean),
                    },
                    (client && client.libelle_status && client.libelle_status === "SARL") && isSommaire("EG2") &&    {
                        key: '6-2',
                        title: "EG2 - Vérifications spécifiques relatives aux SARL",
                        icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                        onClick: () => {},
                        children: [
                            {
                                key: '6-2-1',
                                title: 'EG2.1 - Régularité des AGO',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG2.1", 11, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-2-2',
                                title: 'EG2.2 - Contrôle du respect du droit de communication',
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG2.2", 12, false)
                                },
                                isLeaf: true,
                            },
                            {
                                key: '6-2-3',
                                title: "EG2.3 - Contrôle des conventions réglementées",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG2.3", 13, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-2-4',
                                title: "EG2.4 - Contrôle des conventions interdites",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG2.4", 14, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-2-5',
                                title: "EG2.5 - Appréciation du rapport de Gestion",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG2.5", 15, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-2-6',
                                title: "EG2.6 - Contrôle des pactes d'associés",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG2.6", 16, false)
                                } ,
                                isLeaf: true,
                            },
                            {
                                key: '6-2-7',
                                title: "EG2.7 - Procédures d'alerte",
                                icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                                onClick: () => {
                                    handleOpenModal("EG2.7", 17, false)
                                } ,
                                isLeaf: true,
                            },
                        ].filter(Boolean),
                    },
                ].filter(Boolean),
            },
            (isAcceptation) && {
                key: '7',
                title: "EH – CONTRÔLE DE FIN MISSION ",
                description: "",
                icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                status: isSommaireComplete("EH")  ? 'completed' : isSommaireComplete("EG") && !isSommaireComplete("EH") ? 'current' : "",
                children: [
                    isSommaire("EH1") && {
                        key: '7-1',
                        title: 'EH1 - Questionnaire de fin de mission',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EH1", 1)
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EH2") && {
                        key: '7-2',
                        title: 'EH2 - Questionnaire des évènements postérieurs à la clôture',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EH2", 2)
                        },
                        isLeaf: true,
                    },
                    isSommaire("EH3") && {
                        key: '7-3',
                        title: "EH3 - Continuité de l’exploitation",
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EH3", 3)
                        } ,
                        isLeaf: true,
                    },
                ].filter(Boolean),
            },
            (isAcceptation) && {
                key: '8',
                title: "EI - SYNTHESE ET RAPPORTS",
                icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                onClick: () => {},
                children: [
                    isSommaire("EI1") && {
                        key: '8-1',
                        title: 'EI1 - Synthèse des ajustements',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EI1")
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EI2") && {
                        key: '8-2',
                        title: 'EI2 - Aide mémoire',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EI2")
                        },
                        isLeaf: true,
                    },
                    isSommaire("EI3") && {
                        key: '8-3',
                        title: "EI3 - Lettre d'affirmation(Déclarations de la direction)",
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EI3",2, true)
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EI4") && {
                        key: '8-4',
                        title: "EI4 - Rapport au conseil d'administration ou à l'Administrateur général",
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EI4",2,true)
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EI5") && {
                        key: '8-5',
                        title: "EI5 - Rapport général",
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EI5",2,true)
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EI6") && {
                        key: '8-6',
                        title: "EI6 - Rapport spécial",
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EI6",2,true)
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EI7") && {
                        key: '8-7',
                        title: "EI7 - Lettre à la direction ou rapport sur le contrôle interne",
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EI7",2,true)
                        } ,
                        isLeaf: true,
                    },
                    isSommaire("EI8") && {
                        key: '8-8',
                        title: "EI8 - Autres rapport",
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EI8",2,true)
                        } ,
                        isLeaf: true,
                    },
                ].filter(Boolean),
            },
            {
                key: '9',
                title: "EJ – ANNEXES ",
                description: "",
                icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
                status: isSommaireComplete("EJ")  ? 'completed' : isSommaireComplete("EI") && !isSommaireComplete("EJ") ? 'current' : "",
                children: [
                    {
                        key: '9-1',
                        title: 'EJ1 - DOC : Documents',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal("EJ1", 2, true)
                        } ,
                        isLeaf: true,
                    },
                    {
                        key: '9-2',
                        title: 'EJ2 - FAC : Factures',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal('EJ2', 2, true)
                        } ,
                        isLeaf: true,
                    },
                    {
                        key: '9-3',
                        title: 'EJ3 - COR : Correspondances',
                        icon: <AuditOutlined style={{color: '#009ef7'}}/>,
                        onClick: () => {
                            handleOpenModal('EJ3', 2, true)
                        } ,
                        isLeaf: true,
                    },
                ].filter(Boolean),
            },
        ].filter(Boolean);


    return (
        <>
            <ProgressIndicator
                data={treeData.filter((node) => node?.status === 'current')[0]}
                completedCount={treeData.filter((node) => node?.status === 'completed').length}
                totalCount={treeData.length}
            />
            <TreeComponent data={treeData} onNodeClick={handleNodeClick} />
            {isModalOpen && selectedModalId === 1 &&(
                <LettreMission
                    handleVisibility={handleOpenModal}
                    data={client}
                    mission={mission}
                    visible={isModalOpen}
                    codeSommaire={codeSommaire}
                />
            )}
            {isModalOpen && selectedModalId === 2 &&(
                <CycleFyles
                    handleVisibility={handleOpenModal}
                    client={client}
                    mission={mission}
                    visible={isModalOpen}
                    codeSommaire={codeSommaire}
                />
            )}
        </>
    )
}

MissionSommaireClient.propTypes = {
    mission: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    listQuestionAdd: PropTypes.array,
    userSommaire: PropTypes.object,
    setCode: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    setModalId: PropTypes.func.isRequired,
    clientSommaire: PropTypes.array,
    prev: PropTypes.func,
};

export default MissionSommaireClient;