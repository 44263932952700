import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageTitle} from "../../components/layout/pageData/PageData";
import UsersList from "./user/UsersList";
import {UserAdd} from "./user/UserAdd";
import {UserEdit} from "./user/UserEdit";
import {UserView} from "./user/UserView";
import ListeEqupeMission from "./equpeMission/ListeEquipeMission";


const UsersPage = () => {

    const usersBreadcrumbs = [
        {
            title: 'Accueil',
            path: '/tableau-de-bord',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
        {
            title: 'Utilisateurs',
            path: '/utilisateurs/liste',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },

    ]

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='liste'
                    element={
                        <>
                            <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des utilisateurs</PageTitle>
                            <UsersList />
                        </>
                    }
                />
                <Route
                    path='ajout'
                    element={
                        <>
                            <PageTitle breadcrumbs={usersBreadcrumbs}>Enregistrer un nouvel utilisateur</PageTitle>
                            <UserAdd/>
                        </>
                    }
                />
                <Route
                    path='edit/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={usersBreadcrumbs}>Modifier L'utilisateur</PageTitle>
                            <UserEdit/>
                        </>
                    }
                />
                <Route
                    path='view/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={usersBreadcrumbs}>Modifier L'utilisateur</PageTitle>
                            <UserView/>
                        </>
                    }
                />
                <Route
                    path='equipe-mission'
                    element={
                        <>
                            <PageTitle breadcrumbs={usersBreadcrumbs}>Liste des équipes de mission</PageTitle>
                            <ListeEqupeMission/>
                        </>
                    }
                />
    
                <Route index element={<Navigate to='/utilisateurs/liste' />} />
            </Route>
        </Routes>
    )
}

export default UsersPage
