import axios from 'axios'
import {
    AJOUTER_PROGRAMME,
    LISTE_PROGRAMME, LISTE_PROGRAMME_ACTIVE,  
    MODIFIER_PROGRAMME,
    MODIFIER_ETAT_PROGRAMME,  SUPPRIMER_PROGRAMME
} from '../../api/apiRoutes'

const LISTE_PROGRAMME_AUDIT_INITIAL = `${process.env.REACT_APP_API_URL}/params/liste-programme-audit-initial`
const LISTE_PROGRAMME_ACTIVE_AUDIT_INITIAL = `${process.env.REACT_APP_API_URL}/params/liste-programme-audit-initial-active`

const LISTE_PROGRAMME_TRAVAUX_PROFESSIONNEL = `${process.env.REACT_APP_API_URL}/params/liste-programme-travaux-professionnel`
const LISTE_PROGRAMME_TRAVAUX_PROFESSIONNEL_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-programme-travaux-professionnel-active`

const LISTE_CONTROLE_FIN_MISSION = `${process.env.REACT_APP_API_URL}/params/liste-questions-fin-mission`
const LISTE_CONTROLE_FIN_MISSION_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-questions-fin-mission-active`

export const addProgramme = async (data) => {
    return axios.post(AJOUTER_PROGRAMME, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateProgramme = async (data) => {
    return axios.post(MODIFIER_PROGRAMME, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeProgramme = async () => {
    return axios.get(LISTE_PROGRAMME).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeProgrammeActive = async () => {
    return axios.get(LISTE_PROGRAMME_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteProgramme = async (data) => {
    return axios.post(SUPPRIMER_PROGRAMME, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatProgramme = async (data) => {
    return axios.post(MODIFIER_ETAT_PROGRAMME, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}



export const getListeProgrammeAuditInitial = async () => {
    return axios
        .get(LISTE_PROGRAMME_AUDIT_INITIAL)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeProgrammeAuditInitialActive = async () => {
    return axios
        .get(LISTE_PROGRAMME_ACTIVE_AUDIT_INITIAL)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeProgrammeTravauxProfessionnel = async () => {
    return axios
        .get(LISTE_PROGRAMME_TRAVAUX_PROFESSIONNEL)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeProgrammeTravauxProfessionnelActive = async () => {
    return axios
        .get(LISTE_PROGRAMME_TRAVAUX_PROFESSIONNEL_ACTIVE)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}


export const getListeControleFinMission = async () => {
    return axios
        .get(LISTE_CONTROLE_FIN_MISSION)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeControleFinMissionActive = async () => {
    return axios
        .get(LISTE_CONTROLE_FIN_MISSION_ACTIVE)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}
