import {useEffect, useState} from 'react'
import {Space, Table, Button, Alert, Modal, Popconfirm, Tag} from 'antd'
import {
  addCivilite,
  deleteCivilite,
  getListeCivilite,
  updateCivilite,
  updateEtatCivilite,
} from '../../../data/params/civilite'
import {
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  LikeOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {DateUserListe} from "../../../components/layout/pageData/DateUserListe";

const CivilitePage = () => {
  const [civilites, setCivilites] = useState([])
  const [civilite, setCivilite] = useState([])
  const [search, setSearch] = useState('')
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [libelle, setLibelle] = useState('')
  const [description, setDescription] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [edite, setEdite] = useState(false)

  const filteredItems = civilites.filter(
    (item) =>
      item.codeCivilite.toLowerCase().includes(search.toLowerCase()) ||
      item.libelleCivilite.toLowerCase().includes(search.toLowerCase()) ||
      item.id === parseInt(search)
  )

  const columns = [
    {
      title: '#',
      render: (text, record, index) => (
        <span className='display-block chart-blue'>{index + 1}</span>
      ),
    },
    {
      title: 'Libelle',
      dataIndex: 'codeCivilite',
      key: 'codeCivilite',
      render: (text) => <span className='display-block bold'>{text}</span>,
    },
    {
      title: 'Description',
      dataIndex: 'libelleCivilite',
      key: 'libelleCivilite',
    },
    {
      title: 'Etat',
      dataIndex: 'etat_civilite',
      key: 'etat_civilite',
      render: (_, record) =>
        record.etat_civilite === 1 ? (
          <Tag color='green'>Activé</Tag>
        ) : (
          <Tag color='red'>Désactivé</Tag>
        ),
    },
    {
      title: 'Créée le / Par',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record, index) => (
        <DateUserListe
          date={record.createdAt}
          user={record.nom_user + ' ' + record.prenom_user}
          key={index}
        />
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size='middle'>
          <Button
            type='primary'
            icon={<EditOutlined />}
            onClick={() => selectCivilite(record.id)}
          />

          {record.etat_civilite === 0 && (
            <Popconfirm
              title={`Activer la civilité ${record.libelleCivilite} ?`}
              description={`Voulez vous vraiment activer la civilité ${record.libelleCivilite} ?`}
              onConfirm={() => handleUpdateCivilite(record.id, 1)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<DislikeOutlined />} className='chart-bg-orange' title='Activer ?' />
            </Popconfirm>
          )}

          {record.etat_civilite === 1 && (
            <Popconfirm
              title={`Désactiver la civilité ${record.libelleCivilite} ?`}
              description={`Voulez vous vraiment désactiver la civilité ${record.libelleCivilite} ?`}
              onConfirm={() => handleUpdateCivilite(record.id, 0)}
              okText='Oui'
              cancelText='Non'
            >
              <Button icon={<LikeOutlined />} className='chart-bg-green' title='Désactiver ?' />
            </Popconfirm>
          )}

          <Popconfirm
            title={`Supprimer la civilité ${record.libelleCivilite} ?`}
            description={`Voulez vous vraiment supprimer la civilité ${record.libelleCivilite} ?`}
            onConfirm={() => handleDeleteCivilite(record.id)}
            okText='Oui'
            cancelText='Non'
          >
            <Button title='Supprimer ?' type='primary' icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const showModal = () => {
    setOpen(true)
  }
  const handleAddCivilite = async () => {
    setLoading(true)
    const data = {codeCivilite: libelle, libelleCivilite: description}
    console.log(data)
    const {status, message} = await addCivilite(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeCivilite()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleModifierCivilite = async () => {
    setLoading(true)
    const data = {civilite_id: civilite.id, codeCivilite: libelle, libelleCivilite: description}
    console.log(data)
    const {status, message} = await updateCivilite(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      await handleListeCivilite()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setEdite(false)
    setError(false)
  }

  const selectCivilite = (civilite_id) => {
    console.log(civilite_id)

    const civil = civilites.filter((civilite) => civilite.id === civilite_id)[0]
    setCivilite(civil)
    setLibelle(civil.codeCivilite)
    setDescription(civil.libelleCivilite)
    setEdite(true)
    setOpen(true)
  }

  const handleListeCivilite = async () => {
    setLoading(true)
    const {result, status, message} = await getListeCivilite()
    setMessage(message)
    if (status === 'success') {
      setError(false)
      setCivilites(result)
      handleCancel()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleDeleteCivilite = async (civilite_id) => {
    setLoading(true)
    const data = {civilite_id}
    const {status, message} = await deleteCivilite(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      handleListeCivilite()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  const handleUpdateCivilite = async (civilite_id, etat_civilite) => {
    setLoading(true)
    const data = {civilite_id, etat_civilite}
    const {status, message} = await updateEtatCivilite(data)
    setMessage(message)
    if (status === 'success') {
      setError(false)
      handleListeCivilite()
      setLoading(false)
    } else {
      setError(true)
      setLoading(false)
    }
  }

  // const handleSearchDesCivilite = () => {
  //   const civs = civilites.filter(civilite => civilite.id == )

  // }

  useEffect(() => {
    handleListeCivilite()
  }, [])

  return (
    <>
      <div className='card mb-5 mb-lg-10 w-100'>
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message='Error' description={message} type='error' showIcon />
          </Space>
        )}

        <div className='card-header'>
          <div className='card-title'>
            <h3>Civilités enregistrées</h3>
          </div>

          <div className='card-toolbar'>
            <div className='my-1 me-4'>
              <input
                type='text'
                name='fname'
                onChange={(e) => setSearch(e.target.value)}
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Rechercher'
              />
            </div>

            <Button type='primary' icon={<PlusOutlined />} onClick={showModal} size='large'>
              Ajouter
            </Button>
            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
          </div>
        </div>

        <div className='card-body p-0'>
          <div className='table-responsive'>
            <Table
              loading={loading}
              columns={columns}
              dataSource={filteredItems}
              pagination={{current: 1, pageSize: 50}}
            />
          </div>
        </div>
      </div>

      <Modal
        open={open}
        title={!edite ? 'Enregistrer une nouvelle civilité' : 'Modifier la nouvelle civilité'}
        onOk={!edite ? handleAddCivilite : handleModifierCivilite}
        onCancel={handleCancel}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Annuler
          </Button>,
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={!edite ? handleAddCivilite : handleModifierCivilite}
          >
            Enregistrer
          </Button>,
        ]}
      >
        {error === true && (
          <Space
            direction='vertical'
            style={{
              width: '100%',
            }}
          >
            <Alert message={message} type='error' showIcon />
          </Space>
        )}
        <p>
          <label className='bold'>Libelle</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setLibelle(e.target.value)}
            value={libelle}
            placeholder='Entrez le libelle'
          />
        </p>
        <p>
          <label className='bold'>Description</label>
          <input
            type='text'
            name='fname'
            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            placeholder='Entrez la description'
          />
        </p>
      </Modal>
    </>
  )
}

export default CivilitePage
