import useSwr from "swr";
import {LISTE_PROFIL} from "../../api/apiRoutes";
import {getListeProfil} from "../../data/params/profil";


export default function useProfils() {
    const { data, error, mutate } = useSwr(
        LISTE_PROFIL,
        getListeProfil
    );

    return {
        profils: data,
        chargementProfils: !data && !error,
        erreurProfils: error,
        mutateProfils: mutate,
    };
}