import {CloseSquareOutlined, PlusCircleFilled} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, notification, Row, Select, Space, Tag, Tooltip, Table, Divider} from "antd";
import {addExerciceEquipe, getListeClientSommaire, getListeEquipeMission} from "../../../data/exercice";
import {toAbsoluteUrl} from "../../../helpers/AssetsHelpers";
import clsx from "clsx";
import swal from "sweetalert";
import {getListeTacheActive} from "../../../data/params/tache";
import {getListeInterventionActive} from "../../../data/params/intervention";
import {getListeRoleMission} from "../../../data/params/RoleMission";
import useUsers from "../../../hooks/users/useUser";
import {useSelector} from "react-redux";
import {ModalTitle} from "../../modals/modals.components";
import FormLabel from "../../common/Form/FormLabel";
import {FormContainer, TagItem} from "../../common/common.components";
import {
    Spinner,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import ModalAlert from "../../modals/Modal";
import {appTheme} from "../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCartPlus,
    faDeleteLeft,
    faPersonChalkboard,
    faPhoneAlt,
    faUserEdit
} from "@fortawesome/free-solid-svg-icons";
import TableTitle from "../../common/TableTitle";

const AddEquipeMission = ({
      client,
      exercice,
      fetchData,
      handleVisibility,
      visible,
    }) => {
    const user = useSelector(state => state.user)
    const users = useUsers()
    const [roles, setRoles] = useState([])
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [taches, setTaches] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [indexUser, setIndexUser] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModalId, setSelectedModalId] = useState(null);
    const [equipes, setEquipes] = useState([]);
    const [clientSommaire, setClientSommaire] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [userAddLoading, setUserAddLoading] = useState(false);
    const [sommairesLoading, setSommairesLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        fetchRoles()
        fetchListeTache()
        fetchListeIntervention()
    }, []);

    useEffect(() => {
        if(exercice && isModalOpen){
            fetchClientSommaireExercice(exercice.id);
        }
    }, [exercice, isModalOpen]);

    useEffect(() => {
        if(exercice && user){
            fetchEquipes(exercice.id);
        }
    }, [exercice, user]);

    useEffect(() => {
        if(equipes.length > 0){
            setLoading(true)
            setTimeout(() => {
                const equipeMission = equipes.map((equipe) => ({
                    id: equipe.membre_id,
                    avatar: equipe.Lea_User?.avatar,
                    codeCivilite: equipe.Lea_User?.Civilite?.codeCivilite,
                    nom_user: equipe.Lea_User?.nom_user,
                    prenom_user: equipe.Lea_User?.prenom_user,
                    initial: equipe.Lea_User?.initial,
                    telephone1_user: equipe.Lea_User?.telephone1_user,
                    email_user: equipe.Lea_User?.email_user,
                    profil_id: equipe.profil_id,
                    sommaires: equipe.sommaires || [],
                    actions: equipe.Lea_RoleMission?.actions || [],
                    interventions: equipe.Lea_RoleMission?.intervention || [],
                    isCreated: true
                }))

                setSelectedUsers(equipeMission);
                setLoading(false)
            }, 2000);
        }

    },  [equipes])

    const fetchEquipes = (id) => {
        setLoading(true);
        getListeEquipeMission(id)
            .then((res) => {
                if(res){
                    const teams = res.filter((item) => {
                        return !(item.Lea_RoleMission?.libelle === "Chef de Mission" || item.isMissionChief || item.isManagerMission || item.Lea_RoleMission?.libelle === "Manager");
                    });
                    setEquipes(teams);
                    setLoading(false);
                }
                else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Erreur", error);
                setLoading(false);
            });
    };

    const fetchListeTache = () => {
        setLoading(true);
        getListeTacheActive()
            .then((res) => {
                setTaches(res.result)
                setLoading(false);
            })
    };

    const fetchListeIntervention = () => {
        setLoading(true);
        getListeInterventionActive()
            .then((res) => {
                setInterventions(res.result)
                setLoading(false);
            })
    };

    const fetchRoles = () => {
        setLoading(true);
        getListeRoleMission()
            .then((res) => {
                if(res){
                    setRoles(res);
                    setLoading(false);
                }
            });
    };

    const fetchClientSommaireExercice = (exercice_id) => {
        setSommairesLoading(true)
        getListeClientSommaire(exercice_id)
            .then((res) => {
                if(res){
                    setClientSommaire(res);
                    setSommairesLoading(false);
                }else{
                    setSommairesLoading(false)
                }
            })
            .catch((error) => {
                console.error("Erreur", error);
                setSommairesLoading(false)
            });
    };

    const onChangeUser = (value) => {
        if(isNaN(parseInt(value))){
            return
        }
        const userToAdd = users.users.find((item) => item.id === parseInt(value));
        if(userToAdd){
            setUserId(value)
        }
    }

    const handleUserAdd = () => {
        setUserAddLoading(true)
        setTimeout(() => {
            const userToAdd = users.users.find((item) => item.id === parseInt(userId));

            const bool = selectedUsers.some((element) => element.id === userToAdd.id)

            if (userToAdd && !bool) {
                const newElement = {
                    id: userToAdd.id,
                    avatar: userToAdd.avatar,
                    codeCivilite: userToAdd.codeCivilite,
                    nom_user: userToAdd.nom_user,
                    prenom_user: userToAdd.prenom_user,
                    email_user: userToAdd.email_user,
                    initial: userToAdd?.initial,
                    telephone1_user: userToAdd?.telephone1_user,
                    profil_id: "",
                    actions: [],
                    interventions: [],
                    sommaires: [],
                    isCreated: false,
                    isChef: false
                };
                setSelectedUsers([...selectedUsers, newElement]);
                setUserAddLoading(false)
                notification.success({
                    description: "Membre ajouté avec succès",
                });
            }else{
                setUserAddLoading(false)
                notification.error({
                    description: "Ce membre existe déjà dans l'équipe de la mission",
                });
            }
        }, 1500)
    };

    const handleDeleteElement = (index) => {
        setDeleteLoading(true)
        setTimeout(() => {
            const updatedList = selectedUsers.filter((_, i) => i !== index);
            setSelectedUsers(updatedList);
            setDeleteLoading(false)

            notification.success({
                description: "Membre supprimé avec succès",
            });
        }, 1000)
    };

    const handleSommaireValidate = () => {
        setUserAddLoading(true);
        setSommairesLoading(true);
        setTimeout(() => {
            setIsModalOpen(false);
            setUserAddLoading(false);
            setSommairesLoading(false);
            setSelectAll(false);

            notification.success({
                description: "Sommaire ajouté avec succès",
            });
        }, 1000)
    };

    const onChangeRole = (index, value) => {
        if(isNaN(parseInt(value))){
            selectedUsers[index].actions = []
            selectedUsers[index].interventions = []
            selectedUsers[index].profil_id = ""
            return
        }

        const updatedUsers = [...selectedUsers];
        updatedUsers[index].profil_id = value;
        const role = roles.find((opt) => opt.id === parseInt(value))

        updatedUsers[index].actions = role.actions?.map((action) => (taches.find((tache) => tache.id === action)?.id))
        updatedUsers[index].interventions = role.intervention?.map((action) => (interventions.find((intervention) => intervention.id === action)?.id))
        setSelectedUsers(updatedUsers);

        setIsModalOpen(true);
        setSelectedModalId(1)
        setIndexUser(index);
    };

    const handleCheckboxChange = (code, index) => {
        const updated = [...selectedUsers];
        const isSelected = updated[index].sommaires.includes(code);

        if (isSelected) {
            const tables = updated[index].sommaires.filter((item) => !item.includes(code))
            const updatedList = updated[index].sommaires = tables
            setSelectedUsers(updated);
        } else {
            const updatedList = updated[index].sommaires.push(code)
            setSelectedUsers(updated);
        }
    };

    const onSelectedAll = (e) => {
        e.preventDefault()

        const updated = [...selectedUsers];

        const tables = [...new Set(clientSommaire.map((item) => item.libelle))]
        updated[indexUser].sommaires = tables
        setSelectedUsers(updated);
        setSelectAll(true);
    };

    const handleDeleteAllElements = (e) => {
        e.preventDefault()

        const updated = [...selectedUsers];
        updated[indexUser].sommaires = []
        setSelectedUsers(updated);
        setSelectAll(false);
    };

    const handleOpenModal = (modal_id, index) => {
        setSelectedModalId(modal_id);
        setIndexUser(index);
        setIsModalOpen(true);
    };

    const handleSubmit = async () => {
        setLoading(true);

        const members = selectedUsers.map((item) => ({
            membre_id: item.id,
            profil_id: item.profil_id,
            actions: item.actions,
            interventions: item.interventions,
            sommaires: item.sommaires
        }));

        const data = {
            membres: members,
            exercice_id: exercice.id,
            client_id: client.id,
        };

        const {status, message} = await addExerciceEquipe(data)
        if (status === 'success') {
            setLoading(false)
            await swal(`Opération effectuée avec succès`, '', 'success')
           fetchData()
            handleVisibility();
        } else {
            notification.error({
                description: message || "Erreur! Un problème est survenu",
            });
            setLoading(false)
        }
    }

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Photo</TableTitle>,
            key: "photo",
            width: 70,
            dataIndex: "photo",
            render: (_, record) => (
                <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                    {record?.avatar ? (
                        <div className='symbol-label'>
                            <img
                                src={toAbsoluteUrl(`/media/${record?.avatar}`)}
                                alt={record.nom_user + ' ' + record.prenom_user}
                                className='w-100'
                            />
                        </div>
                    ) : record.codeCivilite === 'M.' ? (
                        <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                            <img
                                src={toAbsoluteUrl('/media/avatars/male.jpg')}
                                alt={record.nom_user + ' ' + record.prenom_user}
                                className='w-100'
                            />
                        </div>
                    ) : (
                        <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                            <img
                                src={toAbsoluteUrl('/media/avatars/female.jpg')}
                                alt={record.nom_user + ' ' + record.prenom_user}
                                className='w-100'
                            />
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Membre</TableTitle>,
            dataIndex: 'reference',
            render: (_, record) => (
                <>
                  <span style={{ color: "#000", fontWeight: "bold" }}>
                    {`${record.nom_user || ""} ${record.prenom_user || ""}`}
                  </span>
                    <br />
                    {record.initial && <Tag prefix="@" color={appTheme.colors.primary_blue}>{record.initial}</Tag>}
                    <br/>
                    {record.telephone1_user && <Tag
                        color={appTheme.colors.alter}
                        style={{fontWeight: "600"}}
                        icon={<FontAwesomeIcon icon={faPhoneAlt} style={{marginRight: "5px"}}/>}
                    >
                        {record.telephone1_user}
                    </Tag>}
                </>
            ),
        },
        {
            title: () => <TableTitle>Rôle</TableTitle>,
            dataIndex: 'libelle',
            render: (_, record, index) => (
                <Select
                    showSearch
                    placeholder="Sélectionnez le role..."
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    value={record.profil_id || null}
                    size="large"
                    style={{ width: "100%" }}
                    onChange={(value) => onChangeRole(index, value)}
                    options={roles.map((role) => ({
                        value: role.id,
                        label: role.libelle
                    }))}
                />
            ),
        },
        {
            title: () => <TableTitle>Dossier client</TableTitle>,
            dataIndex: 'dossiers',
            render: () => (
                <div className='d-flex flex-column'>
                    <span style={{ color: "#000", fontWeight: "bold", fontSize: "12px" }}>
                        {exercice.denomination}
                    </span>
                    <span className='text-gray-800 fw-bold text-hover-primary mb-1' style={{fontSize: '12px'}}>
                        {exercice.mission} <span className="ms-3">{exercice.annee && <TagItem color={appTheme.colors.primary}>{exercice.annee}</TagItem>}</span>
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Actions</TableTitle>,
            dataIndex: 'action',
            align: "right",
            width: 120,
            render: (_, record, index) => (
                <>
                {deleteLoading ? (
                        <Spinner />
                    ) : (
                        <Space>
                            <Tooltip color={appTheme.colors.primary} title="Voir les privilèges">
                                <Button
                                    shape="circle"
                                    size={"middle"}
                                    style={{backgroundColor: appTheme.colors.primary}}
                                    onClick={() => handleOpenModal(2, index)}
                                    icon={<FontAwesomeIcon icon={faPersonChalkboard} color={"white"}/>}
                                />
                            </Tooltip>
                            <Tooltip color={appTheme.colors.primary_blue} title="Modifier les privilèges">
                                <Button
                                    shape="circle"
                                    size={"middle"}
                                    style={{backgroundColor: appTheme.colors.primary_blue}}
                                    onClick={() => handleOpenModal(1, index)}
                                    icon={<FontAwesomeIcon icon={faUserEdit} color={"white"}/>}
                                />
                            </Tooltip>
                            <Tooltip color={appTheme.colors.danger} title="Supprimer">
                                <DeleteIcon
                                    cursor={"pointer"}
                                    color="red"
                                    onClick={() => handleDeleteElement(index)}
                                />
                            </Tooltip>
                        </Space>
                    )}
                </>
            ),
        },
    ];

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Configurer l'équipe de mission {exercice ? `de ${exercice.mission || ""} - ${exercice.annee || ""}` : ""}</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 15 }}
                width={1000}
            >
                <FormContainer>
                    <Card>
                        <Row gutter={[16, 16]}>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}
                                xl={{ span: 24 }}
                            >
                                <FormLabel label="Ajouter un membre" />
                                <Card
                                    bordered={false}
                                    bodyStyle={{ display: "flex", flexDirection: "row" }}
                                >
                                    <Col
                                        xs={{ span: 22 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 12 }}
                                        xl={{ span: 12 }}
                                    >
                                        <FormLabel label="Membre" />
                                        <Select
                                            showSearch
                                            placeholder="Sélectionnez le membre..."
                                            optionFilterProp="label"
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            size="large"
                                            style={{ width: "100%" }}
                                            onChange={onChangeUser}
                                            options={users && users.users.map((user) => ({
                                                value: user.id,
                                                label: `${user.initial || ""} - ${user.nom_user.toUpperCase()} ${user.prenom_user ? user.prenom_user.toUpperCase() : ""}`
                                            }))}
                                        />
                                    </Col>
                                    <Col
                                        xs={{ span: 2 }}
                                        md={{ span: 2 }}
                                        lg={{ span: 2 }}
                                        xl={{ span: 2 }}
                                    >
                                        <FormLabel label="Ajouter" />
                                        {userAddLoading ? (
                                            <Spinner />
                                        ) : (
                                            <>

                                                <Tooltip title="Ajouter">
                                                    <Button
                                                        type="primary"
                                                        shape="circle"
                                                        icon={<PlusCircleFilled />}
                                                        onClick={handleUserAdd}
                                                    />
                                                </Tooltip>
                                            </>
                                        )}
                                    </Col>
                                </Card>
                            </Col>
                        </Row>
                        <div className="w-100">
                            <div className="py-2">
                                <div className="table-responsive">
                                    <Table
                                        columns={columns}
                                        rowKey={(record) => record.id}
                                        dataSource={selectedUsers}
                                        pagination={false}
                                        loading={loading || userAddLoading || deleteLoading}
                                        scroll={{
                                            y: 300,
                                        }}
                                        size={"small"}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider/>
                        <div className="d-flex justify-content-center align-center">
                            <Col
                                xs={{ span: 24 }}
                                 md={{ span: 8 }}
                                 lg={{ span: 6 }}
                                 xl={{ span: 6 }}
                            >
                                <Button
                                    type="primary"
                                    block
                                    size="large"
                                    loading={loading}
                                    disabled={loading}
                                    onClick={handleSubmit}
                                >
                                    {!loading && <span className='indicator-label'>Enregistrer</span>}
                                    {loading && (
                                        <span className="indicator-progress text-white">
                                            Veuillez patienter...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                        </span>
                                    )}
                                </Button>
                            </Col>
                        </div>
                    </Card>
                </FormContainer>
                {(isModalOpen && selectedModalId === 1) && (
                    <div
                        className="top-0 bottom-0 z-index-9999 h-100"
                        style={{ insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
                    >
                        <div className="modal fade show" id="kt_modal_move_to_folder" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}>
                            <div className='modal-dialog modal-dialog-centered mw-600px' >
                                <div className='modal-content'>
                                    <form className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#"
                                          id="kt_modal_move_to_folder_form">
                                        {/*begin::Modal header*/}
                                        <div className="modal-header">
                                            {/*begin::Modal title*/}
                                            <h2 className="fw-bolder">Affectation du sommaire</h2>
                                            {/*end::Modal title*/}
                                            {/*begin::Close*/}
                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={() => setIsModalOpen(false)}>
                                                <span className="svg-icon svg-icon-1">
                                                  <CloseSquareOutlined style={{color: 'red'}}/>
                                                </span>
                                            </div>
                                            {/*end::Close*/}
                                        </div>
                                        {/*end::Modal header*/}
                                        {/*begin::Modal body*/}
                                        <div className="modal-body pt-10 pb-15 px-lg-17">
                                            {selectAll ?  (
                                                    <div className='py-5'>
                                                        <Button
                                                            size={"middle"}
                                                            style={{backgroundColor: appTheme.colors.danger}}
                                                            onClick={handleDeleteAllElements}
                                                            icon={<FontAwesomeIcon icon={faDeleteLeft} color={"white"}/>}
                                                        >
                                                            <span className="fw-bolder text-white">Supprimer tout</span>
                                                        </Button>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div className='py-5'>
                                                        <Button
                                                            size={"middle"}
                                                            style={{backgroundColor: appTheme.colors.primary_blue}}
                                                            onClick={onSelectedAll}
                                                            icon={<FontAwesomeIcon icon={faCartPlus} color={"white"}/>}
                                                        >
                                                            <span className="fw-bolder text-white">Tout sélectionner</span>
                                                        </Button>
                                                    </div>
                                                )
                                            }
                                            {<div className='mh-350px scroll-y me-n3 pe-3'>
                                                <div className="form-group fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                    {/*begin::Item*/}
                                                    {clientSommaire.map((sommaire, index) => (
                                                        <>
                                                            <div className="d-flex" key={index}>
                                                                {/*begin::Checkbox*/}
                                                                <div className="form-check form-check-custom form-check-solid">
                                                                    {/*begin::Input*/}
                                                                    <input
                                                                        className="form-check-input me-3"
                                                                        name="move_to_folder"
                                                                        type="checkbox"
                                                                        value={sommaire.libelle}
                                                                        id={`kt_modal_move_to_folder_${index}`}
                                                                        onChange={() => handleCheckboxChange(sommaire.libelle, indexUser)}
                                                                        checked={selectedUsers[indexUser]?.sommaires?.includes(sommaire.libelle)}
                                                                    />
                                                                    {/*end::Input*/}
                                                                    {/*begin::Label*/}
                                                                    <label className="form-check-label" htmlFor="kt_modal_move_to_folder_0">
                                                                        <div className="text-gray-800 fs-15">
                                                                            {`${sommaire.libelle} - ${sommaire.description}`}
                                                                        </div>
                                                                    </label>
                                                                    {/*end::Label*/}
                                                                </div>
                                                                {/*end::Checkbox*/}
                                                            </div>
                                                            {/*end::Item*/}
                                                            <div className="separator separator-dashed my-5"></div>
                                                        </>
                                                    ))}
                                                </div>
                                                {/*end::Item*/}
                                                <div className="fv-plugins-message-container invalid-feedback"></div>
                                            </div>}
                                            {/*end::Input group*/}
                                            {/*begin::Action buttons*/}
                                            <div className=" card-footer d-flex flex-center mt-3">
                                                {/*begin::Button*/}
                                                <Button
                                                    type="primary"
                                                    block
                                                    size="large"
                                                    loading={sommairesLoading}
                                                    disabled={sommairesLoading}
                                                    onClick={handleSommaireValidate}
                                                >
                                                    {!sommairesLoading && <span className='indicator-label'>Valider</span>}
                                                    {sommairesLoading && (
                                                        <span className="indicator-progress text-white">
                                                        Veuillez patienter...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                                    </span>
                                                    )}
                                                </Button>
                                                {/*end::Button*/}
                                            </div>
                                            {/*begin::Action buttons*/}
                                        </div>
                                        {/*end::Modal body*/}
                                        <div></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {(isModalOpen && selectedModalId === 2) && (
                    <div
                        className="top-0 bottom-0 z-index-9999 h-100"
                        style={{ insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
                    >
                        <div className="modal fade show" id="kt_modal_move_to_folder" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}>
                            <div className='modal-dialog modal-dialog-centered mw-600px' >
                                <div className='modal-content'>
                                    <form className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#"
                                          id="kt_modal_move_to_folder_form">
                                        {/*begin::Modal header*/}
                                        <div className="modal-header">
                                            {/*begin::Modal title*/}
                                            <h2 className="fw-bolder">Privilèges du rôle</h2>
                                            {/*end::Modal title*/}
                                            {/*begin::Close*/}
                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={() => setIsModalOpen(false)}>
                                                <span className="svg-icon svg-icon-1">
                                                  <CloseSquareOutlined style={{color: 'red'}}/>
                                                </span>
                                            </div>
                                            {/*end::Close*/}
                                        </div>
                                        {/*end::Modal header*/}
                                        {/*begin::Modal body*/}
                                        <div className="modal-body py-10 px-lg-17">
                                            <h1 className="mb-7">
                                                <span className="fw-bolder">Privilège du role</span>
                                            </h1>
                                            <div className="scroll-y mh-400px me-n7 pe-7">
                                                <div className="d-flex align-center">
                                                    <div className="col-lg-6">
                                                        <div className="card card-flush">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h4 className="mb-0">Tâches</h4>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="d-flex flex-column text-gray-600">
                                                                    {selectedUsers[indexUser].actions && selectedUsers[indexUser].actions?.map((selected, index) => (
                                                                        <div key={index} className="d-flex align-items-center py-2">
                                                                            <span className="bullet bg-primary me-3"></span>
                                                                            {taches.length > 0 && taches.find((intervention) => intervention.id === selected)?.libelle}                                                                    </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="card card-flush">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h4 className="mb-0">Interventions</h4>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="d-flex flex-column text-gray-600">
                                                                    {selectedUsers[indexUser].interventions && selectedUsers[indexUser].interventions?.map((selected, index) => (
                                                                        <div key={index} className="d-flex align-items-center py-2">
                                                                            <span className="bullet bg-primary me-3"></span>
                                                                            {interventions.length > 0 && interventions.find((intervention) => intervention.id === selected)?.libelle}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end::Modal body*/}
                                        <div></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ModalAlert>

        </>
    )
}

export {AddEquipeMission}