import * as Yup from "yup";

export  const UserDetailSchema = Yup.object().shape({
    initial: Yup.string().required('L\'intial est requis'),
    nom_user: Yup.string().required('Le nom est requis'),
    prenom_user: Yup.string().required('Le prénom est requis'),
    email_user: Yup.string()
        .email('Mauvais format de email')
        .min(8, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Email est requis'),
    login_user: Yup.string().required('Le login est requis'),
    password_user: Yup.string()
        .min(6, 'Minimum 6 caractères')
        .max(50, 'Maximum 50 caractères')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            'Le mot de passe doit contenir au moins 1 Lettre majuscule, 1 chiffre, et 1 caractère spécial'
        ),
    telephone1_user: Yup.string().required('Le numero de telephone est requis'),
    telephone2_user: Yup.string(),
    habitation_user: Yup.string(),
})

export const requestPasswordSchema = Yup.object().shape({
    current_password: Yup.string().required("L'ancien mot de passe est requis"),
    new_password: Yup.string()
        .min(6, 'Minimum 6 caractères')
        .max(50, 'Maximum 50 caractères')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            'Le mot de passe doit contenir au moins 1 Lettre majuscule, 1 chiffre, et 1 caractère spécial'
        )
        .required('Le nouveau mot de passe est requis'),
    confirm_password: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'Les mots de passe sont différents')
        .required('La confirmation du mot de passe est requise '),
})

export const userForEdit = {
    initial: '',
    avatar: '',
    civilite_id: null,
    nom_user: '',
    prenom_user: '',
    email_user: '',
    login_user: '',
    password_user: '',
    telephone1_user: '',
    telephone2_user: '',
    habitation_user: '',
    pays_id: null,
    profil_id: null,
}

// Fonction utilitaire pour comparer deux tableaux
export function areArraysEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (!areObjectsEqual(arr1[i], arr2[i])) {
            return false;
        }
    }
    return true;
}

// Fonction utilitaire pour comparer deux objets
function areObjectsEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function isNumber(value) {
    return typeof value === 'number' && isFinite(value);
}

export function ajouterZeros(nombre, chaine) {
    if (chaine && chaine.length > 0) {
        for (let i = 0; i < chaine.length - 1; i++) {
            nombre += '0';
        }
        return parseInt(nombre, 10);
    } else {
        console.error("La chaîne doit être une chaîne de caractères non vide.");
        return null;
    }
}


export const SOMMAIRE_RISQUE_LIBELLE = {
    ACTIVITE: 'ACTIVITE',
    FRAUDE: 'FRAUDE',
    INFORMATIQUE: 'INFORMATIQUE',
    BLANCHIMENT: 'BLANCHIMENT',
    RESPECT_TEXTE: 'RESPECT_TEXTE',
}

export const cleanAndNormalizeName = (input) => {
    return input?.replace(/[\s_&'`ôâàêéè]/g, (match) => {
        if (match === ' ') return '-';
        return match === '_' ? '-' : match;
    }).toUpperCase();
};