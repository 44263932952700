import PageLabel from "../../../components/common/PageLabel/PageLabel";
import useUsers from "../../../hooks/users/useUser";
import React, {useEffect, useState} from "react";
import { supprimerFileMission} from "../../../data/file";
import {useSelector} from "react-redux";
import {cleanAndNormalizeName} from "../../../components/utilities/Utilities";
import {DOWNLOAD_FILE_CLIENT} from "../../../api/apiRoutes";
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Input,
    notification,
    Popconfirm,
    Popover,
    Select,
    Space,
    Table, Tag,
    Tooltip
} from "antd";
import TableTitle from "../../../components/common/TableTitle";
import {FormContainer, TagItem} from "../../../components/common/common.components";
import {getIconForFileType} from "../../../components/layout/menu/CustomMenu";
import {ClockCircleOutlined, UserOutlined} from "@ant-design/icons";
import moment from "moment/moment";
import {appTheme} from "../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCloudUpload,
    faDownload,
    faExclamationCircle,
    faFileEdit,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import SearchRow from "../../../components/common/SearchRow";
import FormLabel from "../../../components/common/Form/FormLabel";
import FileUploaderModeleSommaire from "../../../components/client/outils/upload/FileUploaderModeleSommaire";
import swal from "sweetalert";
import {getClientBogDetails, getClientBogListeModeleFile} from "../../../data/bogClient";

const ModelDocuments = () => {
    const user = useSelector(state => state.user)
    const users = useUsers();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [clientBog, setClientBog] = useState(null);
    const [filtersItems, setFiltersItems] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [paramsRecherche, setParamsRecherche] = useState({
        infoFile: "",
        user_id: "",
        date_debut: "",
        date_fin: "",
    });

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const fetchDocumentsClient = async (client_bog_id) => {
        setLoading(true);
        getClientBogListeModeleFile(client_bog_id)
            .then((res) => {
                const resFiltered = (res.result ?? []).sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt);
                })
                setFiles(resFiltered);
                setFiltersItems(resFiltered)
                setLoading(false);
            });
    };

    const fetchClientBog = async (client_bog_id) => {
        setLoading(true);
        getClientBogDetails(client_bog_id)
            .then((res) => {
                setClientBog(res.result);
                setLoading(false);
            });
    };

    useEffect(() => {
        if(user){
            fetchClientBog(user?.client_id);
            fetchDocumentsClient(user?.client_id);
        }
    }, [user]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setFiles([]);
        }
    };

    const handleModalOpen = () => {
        setIsModalOpen(prevState => !prevState);
    };

    const downloadDocumentsClient = (client_bog, fichier) => {
        setLoading(true)
        setTimeout(() => {
            const clientBogName = cleanAndNormalizeName(client_bog);
            window.open(`${DOWNLOAD_FILE_CLIENT}/${clientBogName}/MODELES/SOMMAIRE/${fichier}`, '_blank')

            setLoading(false);
            notification.success({
                description: "Télécharger avec succès",
            });
        }, 1500);
    }

    const handleDeleteFileSommaire = (file_id) => {
        setLoading(true);
        const data = {
            file_id
        };
        supprimerFileMission(data)
            .then(async (res) => {
                const {status, message} = res;
                if (status === 'success') {
                    setLoading(false)
                    fetchDocumentsClient(user.client_id);
                    await swal(`Opération effectuée avec succès`, '', 'success')
                } else {
                    await swal(message, '', 'error')
                    setLoading(false)
                }
            })
            .catch((err) => {
                notification.error({
                    description: err,
                });
            })
    }

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Reference</TableTitle>,
            dataIndex: 'reference',
            width: 100,
            render: (_, record) => (
                <TagItem>{record.reference}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Sommaire</TableTitle>,
            dataIndex: 'codeSommaire',
            width: 100,
            render: (_, record) => (
                <Tag color={"warning"}>{record.codeSommaire}</Tag>
            ),
        },
        {
            title: () => <TableTitle>Document</TableTitle>,
            dataIndex: 'libelle',
            width: 300,
            render: (_, record) => (
                <div className='d-flex align-items-center'>
                    <span className='svg-icon svg-icon-2x svg-icon-primary me-4'>
                      {getIconForFileType(record.extension || record.libelle)}
                    </span>
                    <div style={{
                        fontFamily: "Oswald, sans-serif",
                        fontSize: "12px", fontWeight: '700'
                    }}>
                        {record.libelle}
                    </div>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Taille (KO)</TableTitle>,
            width: 100,
            dataIndex: 'size',
            render: (_, record) => (
                <TagItem>{(record.size / 1024).toFixed(2)}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Dernière modif.</TableTitle>,
            dataIndex: 'modif',
            width: 150,
            render: (_, record) => (
                <div className='d-flex flex-column align-center'>
                    <span className='chart-orange display-block f-size-12 pe-5' style={{fontWeight: 600, fontSize: '11px'}}>
                        <UserOutlined style={{fontSize: '15px'}} /> {record.Lea_User?.nom_user + ' ' + record.Lea_User?.prenom_user}
                    </span>
                    <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                        <ClockCircleOutlined style={{fontSize: '12px'}} />{' '}
                        {moment(record.updatedAt).format('DD/MM/YYYY à HH:mm')}
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Dossier client</TableTitle>,
            dataIndex: 'dossiers',
            width: 150,
            render: () => (
                <div className='d-flex flex-column'>
                    <span style={{ color: "#000", fontWeight: "bold", fontSize: "12px" }}>
                        {clientBog?.nom_cabinet || ""}
                    </span>
                    {/*<span className='text-gray-800 fw-bold text-hover-primary mb-1' style={{fontSize: '12px'}}>*/}
                    {/*    {mission.mission} <span className="ms-3">{mission.annee && <TagItem color={appTheme.colors.primary}>{mission.annee}</TagItem>}</span>*/}
                    {/*</span>*/}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Notes</TableTitle>,
            dataIndex: 'description',
            width: 70,
            render: (_, record) => (
                <>
                    {record.description && <Popover
                        placement="top"
                        title="Notes"
                        content={
                            <>
                                <Divider/>
                                <span
                                    style={{
                                        fontFamily: "Oswald, sans-serif",
                                        fontSize: "13px",
                                    }}
                                >
                        {record.description}
                      </span>
                            </>
                        }
                    >
                        <Button
                            icon={<FontAwesomeIcon icon={faExclamationCircle}/>}
                            color={""}
                        />
                    </Popover>}
                </>
            ),
        },
        {
            title: (<span style={{fontSize: '11px'}}>Action</span>),
            dataIndex: 'action',
            width: 70,
            render: (_, record) => (
                <Space>
                    <Tooltip color={appTheme.colors.primary} title="Téléchager le fichier">
                        <Button
                            shape="circle"
                            size={"middle"}
                            style={{backgroundColor: appTheme.colors.primary}}
                            onClick={() => downloadDocumentsClient(record.Bog_Client?.nom_cabinet, record.fichier)}
                            icon={<FontAwesomeIcon icon={faDownload} color={"white"} />}
                        />
                    </Tooltip>
                    {
                        <>
                            {/*<Tooltip  title="Faire une copie dans le dossier permanent">*/}
                            {/*    <Button*/}
                            {/*        shape="circle"*/}
                            {/*        size={"middle"}*/}
                            {/*        style={{}}*/}
                            {/*        onClick={() => {}}*/}
                            {/*        icon={<FontAwesomeIcon icon={faCopy} color={""} />}*/}
                            {/*    />*/}
                            {/*</Tooltip>*/}
                            <Tooltip color='blue' title="Modifier les infos du fichier">
                                <Button
                                    shape="circle"
                                    size={"middle"}
                                    style={{backgroundColor: "blue"}}
                                    onClick={() => {}}
                                    icon={<FontAwesomeIcon icon={faFileEdit} color={"white"} />}
                                />
                            </Tooltip>
                            <Tooltip title="Supprimer ce fichier">
                                <Popconfirm
                                    title="Confirmer la suppression de ce fichier"
                                    description="Voulez-vous supprimer ce fichier?"
                                    onConfirm={() => handleDeleteFileSommaire(record.id)}
                                >
                                    <Button
                                        shape="circle"
                                        size={"middle"}
                                        style={{backgroundColor: "red"}}
                                        icon={<FontAwesomeIcon icon={faTrash} color={"white"} />}
                                    />
                                </Popconfirm>
                            </Tooltip>
                        </>
                    }
                </Space>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = files.filter(file => {
                const referenceMatch = !paramsRecherche.reference || file.reference?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const libelleMatch = file.libelle?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const descriptionMatch = file.description?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const userMatch = paramsRecherche.user_id === "" || file.Lea_User?.id === paramsRecherche.user_id;
                const createdAtMatch = (paramsRecherche.date_debut === "" && paramsRecherche.date_fin === "") || moment(file.createdAt).isBetween(
                    moment(paramsRecherche.date_debut),
                    moment(paramsRecherche.date_fin)
                );

                return referenceMatch && (descriptionMatch || libelleMatch) && userMatch && createdAtMatch;
            });

            setFiltersItems(filteredClients);
            setLoading(false);
        }, 2000);
    };


    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            reference: "",
            infoFile: "",
            user_id: "",
            date_debut: "",
            date_fin: "",
        });
        setFiltersItems(files)
        setLoading(false);
    };

  return (
    <>
      <PageLabel label="Modèle de documents" />
        <SearchRow
            handleSearch={handleSearch}
            handleReset={handleReset}
            loading={loading}
        >
            <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 4 }}
                xl={{ span: 4 }}
            >
                <FormLabel label="Référence" />
                <Input
                    style={{ width: "100%" }}
                    size="large"
                    placeholder="Référence"
                    value={paramsRecherche.reference || null}
                    onChange={(e) =>
                        setParamsRecherche({
                            ...paramsRecherche,
                            reference: e.target.value,
                        })
                    }
                />
            </Col>
            <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
            >
                <FormLabel label="Fichier" />
                <Input
                    style={{ width: "100%" }}
                    size="large"
                    placeholder="Libellé ou description"
                    value={paramsRecherche.infoFile || null}
                    onChange={(e) =>
                        setParamsRecherche({
                            ...paramsRecherche,
                            infoFile: e.target.value,
                        })
                    }
                />
            </Col>
            <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 4 }}
                xl={{ span: 4 }}
            >
                <FormLabel label="Utilisateurs" />
                <Select
                    placeholder="Utilisateur"
                    size="large"
                    style={{ width: "100%" }}
                    onSelect={(value) =>
                        setParamsRecherche({ ...paramsRecherche, user_id: value })
                    }
                    options={users && users.users.map((user) => ({
                        value: user.id,
                        label: `${user.initial || ""} - ${user.nom_user.toUpperCase()} ${user.prenom_user ? user.prenom_user.toUpperCase() : ""}`
                    }))}
                />
            </Col>
            <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 4 }}
                xl={{ span: 4 }}
            >
                <FormLabel label="Date debut" />
                <DatePicker
                    size="large"
                    placeholder="Date début de la création"
                    style={{ width: "100%" }}
                    allowClear
                    onChange={(date, dateString) =>
                        date !== null
                            ? setParamsRecherche({
                                ...paramsRecherche,
                                date_debut: date.format("YYYY-MM-DD"),
                            })
                            : setParamsRecherche({ ...paramsRecherche, date_debut: "" })
                    }
                    format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                />
            </Col>
            <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 4 }}
                xl={{ span: 4 }}
            >
                <FormLabel label="Date fin" />
                <DatePicker
                    size="large"
                    placeholder="Date fin  de la création"
                    style={{ width: "100%" }}
                    allowClear
                    onChange={(date, dateString) =>
                        date !== null
                            ? setParamsRecherche({
                                ...paramsRecherche,
                                date_fin: date.format("YYYY-MM-DD"),
                            })
                            : setParamsRecherche({ ...paramsRecherche, date_fin: "" })
                    }
                    format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                />
            </Col>
        </SearchRow>
        <FormContainer>
            <div className="w-100">
                {<div className="d-flex justify-content-end align-center my-2 me-5">
                    <Tooltip color={appTheme.colors.primary} title="Importer un fichier pour l'exercice">
                        <Button
                            size={"middle"}
                            style={{backgroundColor: appTheme.colors.primary}}
                            onClick={handleModalOpen}
                            icon={<FontAwesomeIcon icon={faCloudUpload} color={"white"}/>}
                        >
                            Importer
                        </Button>
                    </Tooltip>
                </div>}
                <div className="py-4">
                    <div className="table-responsive">
                        <Table
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={filtersItems}
                            pagination={tableParams.pagination}
                            loading={loading}
                            onChange={handleTableChange}
                            rowSelection
                            size={"small"}
                        />
                    </div>
                </div>
            </div>
        </FormContainer>
        {isModalOpen && (
            <FileUploaderModeleSommaire
                handleVisibility={handleModalOpen}
                data={null}
                client={clientBog}
                mission={null}
                codeSommaire={null}
                visible={isModalOpen}
                chargement={loading}
                fetchData={fetchDocumentsClient}
            />
        )}
    </>
  );
};

export default ModelDocuments;
