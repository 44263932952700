import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {storeUserData} from "../store/user/actions";
import {useDispatch} from "react-redux";

const InactivityLogoutTimer = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const checkForInactivity = () => {
        const expireTimeString = localStorage.getItem('expireTime');
        const expireTime = expireTimeString ? parseInt(expireTimeString) : 0;

        if (expireTime < Date.now() && location.pathname !== '/') {
            // localStorage.removeItem('accessToken');
            dispatch(storeUserData({}, ''))
            localStorage.removeItem('user')
            localStorage.removeItem('accessToken')
            navigate('/');
        }
    };

    const updateExpiryTime = () => {
        const expireTime = Date.now() + 15 * 60 * 1000; // 15 minutes en millisecondes
        localStorage.setItem('expireTime', expireTime.toString());
    };

    useEffect(() => {
        const interval = setInterval(() => {
            checkForInactivity();
        }, 5000); // Check for inactivity every 5 seconds

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        updateExpiryTime();
        window.addEventListener('click', updateExpiryTime);
        window.addEventListener('keypress', updateExpiryTime);
        window.addEventListener('scroll', updateExpiryTime);
        window.addEventListener('mousemove', updateExpiryTime);

        return () => {
            window.removeEventListener('click', updateExpiryTime);
            window.removeEventListener('keypress', updateExpiryTime);
            window.removeEventListener('scroll', updateExpiryTime);
            window.removeEventListener('mousemove', updateExpiryTime);
        };
    }, []);

    return null;
};

export default InactivityLogoutTimer;