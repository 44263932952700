import React, {useEffect, useState} from "react"
import {getListeRisqueActive} from "../../../../../../data/params/risque";
import {CloseSquareOutlined} from "@ant-design/icons";
import {getListeEvent} from "../../../../../../data/params/event";
import '../tableperso.css'
import {areArraysEqual} from "../../../../../utilities/Utilities";
import {
    Button,
    Col,
    Divider,
    Input,
    notification,
    Popover,
    Row,
    Select,
    Space,
    Table,
    Tag,
    Tooltip, Typography
} from "antd";
import {getListeAssertion} from "../../../../../../data/params/assertion";
import {ModalTitle} from "../../../../../modals/modals.components";
import {FormContainer} from "../../../../../common/common.components";
import FormLabel from "../../../../../common/Form/FormLabel";
import ModalAlert from "../../../../../modals/Modal";
import TableTitle from "../../../../../common/TableTitle";
import {Center, Spinner, Table as TableChackra, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarPlus, faCartPlus,
    faCirclePlus, faDeleteLeft,
    faToolbox,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {SearchHeader} from "../../../../../layout/header/HeaderList";

const {Title} = Typography;

export const postesStat = [
    { key: "EE3.1", title: "Immobilisations incorporelles" },
    { key: "EE3.2", title: "Immobilisations corporelles" },
    { key: "EE3.3", title: "Immobilisations financières" },
    { key: "EE3.4", title: "Stocks" },
    { key: "EE3.5", title: "Ventes-clients" },
    { key: "EE3.6", title: "Autres créance et dettes" },
    { key: "EE3.7", title: "Trésorerie" },
    { key: "EE3.8", title: "Capitaux propres" },
    { key: "EE3.9", title: "Provisions risques" },
    { key: "EE3.10", title: "Emprunts et assimilés" },
    { key: "EE3.11", title: "Achats fournisseurs" },
    { key: "EE3.12", title: "Impôts et taxes" },
    { key: "EE3.13", title: "Paie personnel" },
];

const tableHeaders = [
    {title: "Facteurs de risque", width: 200},
    {title: "Évènement / Source du risque", width: 300},
    {title: "Incidence (effet)", width: 300},
    {title: "Poste EF", width: 200},
    {title: "Assertions", width: 200},
    {title: "Action", width: 70},
    {title: "", width: 30},
]

const DynamicInputComponent = ({handleVisibility, selectData, setSelectData, tabIndex}) => {
    const [selectAll, setSelectAll] = useState(false);
    const [loading] = useState(false);
    const [filtersItems, setFiltersItems] = useState(postesStat || []);

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = postesStat.filter(
            (item) =>
                item.key?.toLowerCase().includes(searchTerm) ||
                item.title.toString()?.toLowerCase().startsWith(searchTerm)
        );
        setFiltersItems(filtered);
    };

    const handleInputChange = (posteIndex, value) => {

        const updated = [...selectData];
        const isSelected = updated[tabIndex?.rubriqueIndex].risquesDetails[tabIndex?.eventIndex].risqueIncidences[tabIndex?.incidenceIndex].risquesAssertions[tabIndex?.assertionIndex].postes?.includes(value)
        if(isSelected){
            const tables = updated[tabIndex?.rubriqueIndex].risquesDetails[tabIndex?.eventIndex].risqueIncidences[tabIndex?.incidenceIndex].risquesAssertions[tabIndex?.assertionIndex].postes?.filter(poste => !poste.includes(value))
            updated[tabIndex?.rubriqueIndex].risquesDetails[tabIndex?.eventIndex].risqueIncidences[tabIndex?.incidenceIndex].risquesAssertions[tabIndex?.assertionIndex].postes = tables;
            setSelectData(updated)
        } else {
            updated[tabIndex?.rubriqueIndex].risquesDetails[tabIndex?.eventIndex].risqueIncidences[tabIndex?.incidenceIndex].risquesAssertions[tabIndex?.assertionIndex].postes?.push(value)
            setSelectData(updated);
        }
    };

    const onSelectedAll = () => {
        const updated = [...selectData];

        const tables = [...new Set(postesStat.map((item) => item.key))]
        updated[tabIndex?.rubriqueIndex].risquesDetails[tabIndex?.eventIndex].risqueIncidences[tabIndex?.incidenceIndex].risquesAssertions[tabIndex?.assertionIndex].postes = tables
        setSelectData(updated);
        setSelectAll(true);
    };

    const handleDeleteAllElements = () => {
        const updated = [...selectData];
        updated[tabIndex?.rubriqueIndex].risquesDetails[tabIndex?.eventIndex].risqueIncidences[tabIndex?.incidenceIndex].risquesAssertions[tabIndex?.assertionIndex].postes = []
        setSelectData(updated);
        setSelectAll(false);
    };

    return (
        <div className='modal-dialog modal-dialog-centered mw-600px' >
            <div className='modal-content'>
                <form className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#"
                      id="kt_modal_move_to_folder_form">
                    {/*begin::Modal header*/}
                    <div className="modal-header">
                        {/*begin::Modal title*/}
                        <Title style={{display: "block", fontWeight: '600', color: appTheme.colors.primary}} level={3}>
                            Gestion Postes des Etats Financiers
                        </Title>
                        {/*end::Modal title*/}
                        {/*begin::Close*/}
                        <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={handleVisibility}>
                            <span className="svg-icon svg-icon-1">
                              <CloseSquareOutlined style={{color: 'red'}}/>
                            </span>
                        </div>
                        {/*end::Close*/}
                    </div>
                    {/*end::Modal header*/}
                    {/*begin::Modal body*/}
                    <div className="modal-body pt-10 pb-15 px-lg-17">
                        {selectAll ?  (
                                <div className='py-5'>
                                    <Button
                                        size={"middle"}
                                        style={{backgroundColor: appTheme.colors.danger}}
                                        onClick={handleDeleteAllElements}
                                        icon={<FontAwesomeIcon icon={faDeleteLeft} color={"white"}/>}
                                    >
                                        <span className="fw-bolder text-white">Supprimer tout</span>
                                    </Button>
                                </div>
                            )
                            :
                            (
                                <div className='py-5'>
                                    <Button
                                        size={"middle"}
                                        style={{backgroundColor: appTheme.colors.primary_blue}}
                                        onClick={onSelectedAll}
                                        icon={<FontAwesomeIcon icon={faCartPlus} color={"white"}/>}
                                    >
                                        <span className="fw-bolder text-white">Tout sélectionner</span>
                                    </Button>
                                </div>
                            )
                        }
                        {(postesStat ?? []).length > 0 && <div className="card-header border-0 pt-2">
                            <SearchHeader
                                placeholder="Rechercher par le code, le libellé..."
                                handleSearch={handleSearchChange}
                            />
                        </div>}
                        <br/>
                        {<div className='mh-400px scroll-y me-n3 pe-3'>
                            <div className="form-group fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                {/*begin::Item*/}
                                {(filtersItems ??  []).map((sommaire, index) => (
                                    <>
                                        <div className="d-flex" key={index}>
                                            {/*begin::Checkbox*/}
                                            <div className="form-check form-check-custom ">
                                                {/*begin::Input*/}
                                                <input
                                                    className="form-check-input me-3"
                                                    name="move_to_folder"
                                                    type="checkbox"
                                                    value={(selectData[tabIndex?.rubriqueIndex].risquesDetails[tabIndex?.eventIndex].risqueIncidences[tabIndex?.incidenceIndex].risquesAssertions[tabIndex?.assertionIndex].postes ?? []).find(poste => poste === sommaire.key)}
                                                    id={`kt_modal_move_to_folder_${index}`}
                                                    onChange={() => handleInputChange(index, sommaire.key)}
                                                    checked={(selectData[tabIndex?.rubriqueIndex].risquesDetails[tabIndex?.eventIndex].risqueIncidences[tabIndex?.incidenceIndex].risquesAssertions[tabIndex?.assertionIndex].postes ?? []).some(poste => poste === sommaire.key)}
                                                />
                                                {/*end::Input*/}
                                                {/*begin::Label*/}
                                                <label className="form-check-label" htmlFor="kt_modal_move_to_folder_0">
                                                    <span style={{fontSize: "16px", fontFamily: "Oswald, sans-serif", textTransform: "uppercase"}}>
                                                        {`${sommaire.key} - ${sommaire.title}`}
                                                    </span>
                                                </label>
                                                {/*end::Label*/}
                                            </div>
                                            {/*end::Checkbox*/}
                                        </div>
                                        {/*end::Item*/}
                                        <div className="separator separator-dashed my-5"></div>
                                    </>
                                ))}
                            </div>
                            {/*end::Item*/}
                            <div className="fv-plugins-message-container invalid-feedback"></div>
                        </div>}
                        {/*end::Input group*/}
                        {/*begin::Action buttons*/}
                        <div className=" card-footer d-flex flex-center mt-3">
                            {/*begin::Button*/}
                            <Button
                                type="primary"
                                block
                                size="large"
                                loading={loading}
                                disabled={loading}
                                onClick={handleVisibility}
                            >
                                {!loading && <span className='indicator-label'>Valider</span>}
                                {loading && (
                                    <span className="indicator-progress text-white">
                                        Veuillez patienter...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                    </span>
                                )}
                            </Button>
                            {/*end::Button*/}
                        </div>
                        {/*begin::Action buttons*/}
                    </div>
                    {/*end::Modal body*/}
                    <div></div>
                </form>
            </div>
        </div>
    );
};

const ModalRisqueQuestion = ({
        handleVisibility,
        visible,
        selectData,
        setSelectData,
        assertions,
        rubriques, 
        rubrique_id,
        chargement,
     }) => {
    const [selectedList, setSelectedList] = useState(selectData || []);
    const [eventRisques, setEventRisques] = useState([]);
    const [filteredItems, setFilteredItems] = useState([])
    const [assertion, setAssertion] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchEventRisques = async () => {
            try {
                const res = await getListeEvent();
                if (res.result) {
                    const events = res.result.filter((opt) => opt.risqueId === rubrique_id);
                    setEventRisques(events);
                    setFilteredItems(events);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des événements risques', error);
            }
        };
        
        fetchEventRisques();
    }, [rubrique_id]);

    const handleAssertionChange = (value) => {
        setAssertion(value)
        const updatedQuestions = filteredItems.filter((question) => question.assertions.includes(value))
        setFilteredItems(updatedQuestions)
    }

    const toggleCheckbox = (selectId, libelle, rubrique_id) => {
        const selectedIndex = selectedList.findIndex((item) => item.rubrique_id === (rubrique_id));

        const updatedRisquesDetails = [...selectedList[selectedIndex].risquesDetails];
        const existingIndex = updatedRisquesDetails.findIndex((opt) => opt.evenRisque_id === selectId);

        if (existingIndex !== -1) {
            updatedRisquesDetails.splice(existingIndex, 1);
        } else {
            updatedRisquesDetails.push({ evenRisque_id: selectId, eventLibelle: libelle, risqueIncidences: [{incidence: null, risquesAssertions: [{postes: [], assertions: []}]}] });
        }

        const updatedItem = {
            ...selectedList[selectedIndex],
            risquesDetails: updatedRisquesDetails,
        };

        const updatedList = [...selectedList];
        if (selectedIndex !== -1) {
            updatedList[selectedIndex] = updatedItem;
        } else {
            updatedList.push(updatedItem);
        }

        setSelectedList(updatedList);
    };

    const handleValidate = () => {
        if(selectedList.length > 0){
            setLoading(true);
            setTimeout(() => {
                const updatedSelect = selectedList.filter((item) => item.risquesDetails.length > 0)
                setSelectData(updatedSelect);

                handleVisibility();
                notification.success({
                    description: "Questions ajouté avec succès !",
                });

                setLoading(false);
            }, 1000)
        }

    }

    const columnsQuestions = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 50,
        },
        // {
        //     title: () => <TableTitle>Groupe</TableTitle>,
        //     dataIndex: 'groupe',
        //     width: 100,
        //     render: (_, record) => (
        //         <span className='fw-boldest text-uppercase' style={{fontSize: '13px'}}>
        //             {record.groupe}
        //         </span>
        //     ),
        // },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 300,
            render: (_, record) => (
                <span className='' style={{fontSize: '14px'}}>
                   {record.libelle}
               </span>
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 100,
            render: (_, record) => (
                <div className='d-flex align-center' >
                    {(record?.assertions ?? []).map((assertion, index) => (
                        <span className='fw-bolder me-3' key={index}>
                        {assertion}
                    </span>
                    ))}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 50,
            render: (_, record) => (
                <span className="form-check form-check-custom me-5">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectedList.some((item) => item.rubrique_id === (rubrique_id) && item.risquesDetails.some((opt) => opt.evenRisque_id === record.id))}
                        onChange={() => toggleCheckbox(record.id, record.libelle, rubrique_id)}
                    />
              </span>
            ),
        },
    ];
    // const columnsSelectQuestions = [
    //     {
    //         title: () => <TableTitle>#</TableTitle>,
    //         render: (_, record, index) => <>{index + 1}</>,
    //         width: 50,
    //     },
    //     {
    //         title: () => <TableTitle>Question</TableTitle>,
    //         dataIndex: 'libelle',
    //         width: 300,
    //         render: (_, record) => (
    //             <div className="d-flex align-center">
    //                 <div className="d-flex flex-column align-center">
    //                     <span className='' style={{fontSize: '13px'}}>
    //                        {record.eventLibelle}
    //                    </span>
    //                     <span className='fw-boldest text-uppercase mt-1' style={{fontSize: '10px'}}>
    //                         {rubriques.find(rubrique => rubrique.id === record.risqueId)?.libelle || ""}
    //                     </span>
    //                 </div>
    //             </div>
    //         ),
    //     },
    //     {
    //         title: () => <TableTitle>Assertions</TableTitle>,
    //         dataIndex: 'assertions',
    //         width: 100,
    //         render: (_, record) => (
    //             <div className='d-flex align-center' >
    //                 {(record.assertions ?? [])?.map((assertion, index) => (
    //                     <span className='fw-bolder me-3' key={index}>
    //                     {assertion}
    //                 </span>
    //                 ))}
    //             </div>
    //         ),
    //     },
    // ];
    
    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Sélectionner les questions de travail (sources) </ModalTitle>}
                footer={[]}
                modalStyles={{ top: 10 }}
                width={800}
            >
                <FormContainer>
                    <Row gutter={[16, 16]}>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                        >
                            <FormLabel label="Question" />
                            <Input
                                style={{ width: "100%" }}
                                size="large"
                                placeholder="Rechercher par le libellé"
                                onChange={(e) => {
                                    setLoading(true);
                                    setTimeout(() => {
                                        const updateQuestions = eventRisques.filter((item) =>
                                                ((item.libelle ?? []).some(opt => (opt ?? "").toLowerCase()?.includes((e.target.value).toLowerCase())))
                                        )
                                        if(updateQuestions && updateQuestions.length > 0){
                                            setFilteredItems(updateQuestions);
                                        }
                                        else {
                                            setFilteredItems(eventRisques);
                                        }
                                        setLoading(false);
                                    }, 1000)
                                }}
                            />
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                        >
                            <FormLabel label="Groupe" />
                            <Select
                                showSearch
                                placeholder="Rechercher par le risque inhérent..."
                                optionFilterProp="label"
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={rubriques.map((rubrique) => ({
                                    value: rubrique.id,
                                    label: rubrique.libelle,
                                }))}
                                value={null}
                                onChange={(value) => {
                                    setLoading(true);
                                    setTimeout(() => {
                                        const updateQuestions = eventRisques.filter((item) =>
                                            (item.risqueId === rubrique_id)
                                        )
                                        if(updateQuestions && updateQuestions.length > 0){
                                            setFilteredItems(updateQuestions);
                                        }
                                        else {
                                            setFilteredItems(eventRisques);
                                        }
                                        setLoading(false);
                                    }, 1000)
                                }}
                                size="large"
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                        >
                            <FormLabel label="Assertions" />
                            <Select
                                showSearch
                                placeholder="Rechercher par l'assertion..."
                                optionFilterProp="label"
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={assertions.map((assertion) => ({
                                    value: assertion.libelle,
                                    label: assertion.libelle,
                                }))}
                                value={assertion || null}
                                onSelect={handleAssertionChange}
                                size="large"
                                style={{ width: "100%" }}
                            />
                        </Col>
                    </Row>
                </FormContainer>
                <Row gutter={[16, 16]}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}
                        xl={{ span: 24 }}
                    >
                        <FormContainer>
                            <div className="table-responsive">
                                <Table
                                    rowClassName={() =>
                                        `h-3px p-0 m-0 lh-0.5`
                                    }
                                    columns={columnsQuestions}
                                    dataSource={filteredItems ?? []}
                                    pagination={false}
                                    scroll={{y: 400}}
                                    loading={chargement || loading}
                                    size={"small"}
                                />
                            </div>
                        </FormContainer>
                    </Col>
                    {/*<Col*/}
                    {/*    xs={{ span: 24 }}*/}
                    {/*    md={{ span: 8 }}*/}
                    {/*    lg={{ span: 10 }}*/}
                    {/*    xl={{ span: 10 }}*/}
                    {/*>*/}
                    {/*    <FormContainer>*/}
                    {/*        <div className="table-responsive">*/}
                    {/*            <Table*/}
                    {/*                rowClassName={() =>*/}
                    {/*                    `h-3px p-0 m-0 lh-0.5`*/}
                    {/*                }*/}
                    {/*                columns={columnsSelectQuestions}*/}
                    {/*                dataSource={selectedList ?? []}*/}
                    {/*                pagination={false}*/}
                    {/*                scroll={{y: 400}}*/}
                    {/*                loading={chargement || loading}*/}
                    {/*                size={"small"}*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </FormContainer>*/}
                    {/*</Col>*/}
                </Row>
                <div className="d-flex justify-content-center align-items-center mt-3">
                    <div className="w-350px">
                        <Button
                            type="primary"
                            block
                            size="large"
                            // icon={<FontAwesomeIcon icon={fa} /> }
                            loading={loading}
                            disabled={loading}
                            onClick={handleValidate}
                        >
                            Valider
                        </Button>
                    </div>
                </div>
            </ModalAlert>
        </>
    )
}

const TraitementIdentification = ({ selecteData, setSelectData, datas, libelleRisque, setIsTaskSave }) => {
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rubriques, setRubriques] = useState([]);
    const [assertions, setAssertion] = useState([]);
    const [rubrique_id, setRubriqueId] = useState(null);
    const [selectedModalId, setSelectedModalId] = useState(null);
    const [tabIndex, setTabIndex] = useState({
        rubriqueIndex: null,
        eventIndex: null,
        incidenceIndex: null,
        assertionIndex: null,
        posteIndex: null,
    });

    useEffect(() => {
        const fetchRubrique = async () => {
            try {
                setLoading(true);
                const res = await getListeRisqueActive();
                const rubriquesSelected = res.result.filter((item) => item.libelleRisque === libelleRisque)
                if(rubriquesSelected){
                    setRubriques(rubriquesSelected);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des rubriques', error);
                setLoading(false);
            }
        };
        
        fetchRubrique();
    }, [libelleRisque]);
    
    useEffect(() => {
        const fetchAssertion = async () => {
            try {
                setLoading(true);
                const res = await getListeAssertion();
                setAssertion(res.result);
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des rubriques', error);
                setLoading(false);
            }
        };
        
        fetchAssertion();
    }, [])

    useEffect(() => {
        if(datas.length > 0) {
            const risqueIds = [...new Set(datas.map((item) => item.Bog_EventSourceRisque?.risqueId))];

            const arrayData = [];
            if(risqueIds.length > 0){
                risqueIds.forEach((risque) => {
                    const filter = datas.filter(element => element.Bog_EventSourceRisque?.risqueId === risque);
                    if (filter.length > 0) {
                        const rubrique = rubriques.find((opt) => opt.id === risque)
                        const newSelectArray = {
                            rubrique_id: rubrique?.id,
                            rubrique_libelle: rubrique?.libelle,
                            risquesDetails: filter.map((event) => ({
                                evenRisque_id: event?.eventRisque_id,
                                eventLibelle: event.eventLibelle,
                                risqueIncidences: event.Lea_RisqueIdentificationDetails?.map((detail) => ({
                                    incidence: detail.incidence,
                                    risquesAssertions: detail.Lea_RisqueIdentificationAssertions?.map((assertion) => ({
                                        postes: assertion.postes || [''],
                                        assertions: assertion.assertions || []
                                    }))
                                }))
                            })),
                        }
                        arrayData.push(newSelectArray)
                    }
                })
                setSelectData(arrayData)
            }
        }
    }, [datas, rubriques, setSelectData])

    const onChange = (value) => {
        const selectedList = [...selecteData];
        const valueId = parseInt(value);

        if (isNaN(valueId)) {
            return;
        }

        const add = rubriques.find((item) => item.id === valueId);

        const isAlreadySelected = selecteData.some((element) => element.rubrique_id === add.id);

        if (!isAlreadySelected) {
            const newElement = {
                rubrique_id: add.id,
                rubrique_libelle: add.libelle,
                risquesDetails: [],
            };

            setSelectData([...selectedList, newElement]);
        }

        setRubriqueId(valueId);
        setIsModalOpen(true);
        setSelectedModalId(1);
    };

    useEffect(() => {
        const updatedItems = selecteData.map(risque => ({
            ...risque,
            assertionLenght: risque.risquesDetails.reduce((total, event) => {
                return total + event.risqueIncidences
                    .filter(incidence => incidence.risquesAssertions)
                    .length;
            }, 0),
        }));

        if (!areArraysEqual(updatedItems, selecteData)) {
            setSelectData(updatedItems);
        }
    }, [selecteData, setSelectData]);
    
    const addNewIncidence = (rubriqueIndex, eventIndex, e) => {
        e.preventDefault()
        const newData = [...selecteData];

        const selectedRubrique = newData[rubriqueIndex];
        const selectedEvent = selectedRubrique.risquesDetails[eventIndex];

        const newIncidence = {
            incidence: '',
            risquesAssertions: [{postes: [''], assertions: []}],
        };

        selectedEvent.risqueIncidences.push(newIncidence);
        setIsTaskSave(prevState => [...prevState, 1]);

        newData[rubriqueIndex] = selectedRubrique;
        setSelectData(newData);
    }

    const handleEventLibelleChange = (rubriqueIndex, eventIndex, e) => {
        const updated = [...selecteData];
        updated[rubriqueIndex].risquesDetails[eventIndex].eventLibelle = e.target.value;
        setSelectData(updated);
        setIsTaskSave(prevState => [...prevState, 2]);
    };

    const handleIncidenceChange = (rubriqueIndex, eventIndex, incidenceIndex, e) => {
        const updated = [...selecteData];
        updated[rubriqueIndex].risquesDetails[eventIndex].risqueIncidences[incidenceIndex].incidence = e.target.value;
        setSelectData(updated);
        setIsTaskSave(prevState => [...prevState, 2]);
    };

    const handleAssertionChange = (rubriqueIndex, eventIndex, incidenceIndex, assertionIndex, e) => {
        const updated = [...selecteData];
        updated[rubriqueIndex].risquesDetails[eventIndex].risqueIncidences[incidenceIndex].risquesAssertions[assertionIndex].assertions = e;
        setSelectData(updated);
        setIsTaskSave(prevState => [...prevState, 2]);
    };

    const handleDeleteElement = (rubriqueIndex, eventIndex, e) => {
        e.preventDefault()
        const updated = [...selecteData];
        updated[rubriqueIndex].risquesDetails.splice(eventIndex, 1);
        setSelectData(updated);
        setIsTaskSave(prevState => [...prevState, 2]);
    };

    const handleDeleteIncidence = (rubriqueIndex, eventIndex, incidenceIndex, e) => {
        e.preventDefault()
        const updated = [...selecteData];
        if((updated[rubriqueIndex].risquesDetails[eventIndex].risqueIncidences ?? []).length <= 1){
            notification.warning({
                description: "Impossible ! Veuillez supprimer l'evennement",
            });
            return;
        }
        updated[rubriqueIndex].risquesDetails[eventIndex].risqueIncidences.splice(incidenceIndex, 1);
        setSelectData(updated);
        setIsTaskSave(prevState => [...prevState, 2]);
    };

    const handleModalOpen = (id, rubriqueIndex, eventIndex, incidenceIndex, assertionIndex, posteIndex) => {
        setIsModalOpen(prevState => !prevState);
        setSelectedModalId(id);

        setTabIndex({
            ...tabIndex,
            rubriqueIndex,
            eventIndex,
            incidenceIndex,
            assertionIndex,
        })
    };

    return (
        <>
            <div className=''>
                <div className='d-flex align-items-center px-10 pb-10'>
                    <Select
                        style={{width: 800}}
                        size="large"
                        showSearch
                        placeholder="Sélectionner une rubrique"
                        optionFilterProp="children"
                        onChange={onChange}
                        onSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={rubriques && rubriques.map((item) => ({
                            label: (<span className="fs-6 fw-boldest text-uppercase">{item.libelle}</span>),
                            value: item.id
                        }))}
                    />
                </div>
                <Divider/>
                <div className="table-responsive">
                    {loading ? (
                        <Center>
                            <Spinner />
                        </Center>
                    ) : (
                        <TableContainer>
                            <TableChackra variant="simple">
                                <Thead>
                                    <Tr>
                                        {tableHeaders.map((item, index) => (
                                            <Th width={item.width || 100} key={index}><TableTitle>{item.title}</TableTitle> </Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {selecteData
                                        ? (selecteData ?? []).map((rubrique, rubriqueIndex) => (
                                            rubrique.risquesDetails.map((event, eventIndex) => (
                                                event.risqueIncidences.map((incidence, incidenceIndex) => (
                                                    incidence.risquesAssertions.map((assertion, assertionIndex) => {
                                                        const colSpanEvent = (incidenceIndex === 0 && assertionIndex === 0) ? event.risqueIncidences.length : 1;
                                                        const colSpanIncidence = (assertionIndex === 0) ? incidence.risquesAssertions.length : 1;
                                                        const rubriqueLibelle = (eventIndex === 0 && incidenceIndex === 0 && assertionIndex === 0) ? rubrique.rubrique_libelle : '';
                                                        const eventLibelle = (incidenceIndex === 0 && assertionIndex === 0) ? event.eventLibelle : '';
                                                        const incidenceValue = incidence.incidence || '';
                                                        const postesValue = assertion.postes || [''];
                                                        const assertionValue = assertion.assertions || [];

                                                        return (
                                                            <Tr key={`${rubriqueIndex}-${eventIndex}-${incidenceIndex}-${assertionIndex}`}>
                                                                {eventIndex === 0 && incidenceIndex === 0 && assertionIndex === 0 && (
                                                                    <Td width={200} rowSpan={rubrique.assertionLenght}>
                                                                        <Input.TextArea
                                                                            style={{fontSize: "12px", fontWeight: "700", textTransform: "uppercase"}}
                                                                            size="large"
                                                                            bordered={false}
                                                                            value={rubriqueLibelle}
                                                                            maxLength={2000}
                                                                            // showCount
                                                                            autoSize={{
                                                                                minRows: 1,
                                                                                maxRows: 8,
                                                                            }}
                                                                        />
                                                                    </Td>
                                                                )}
                                                                {incidenceIndex === 0 && assertionIndex === 0 && (
                                                                    <Td width={320} rowSpan={colSpanEvent} colSpan={colSpanIncidence}>
                                                                        <div className="">
                                                                            <Input.TextArea
                                                                                style={{fontSize: "13px"}}
                                                                                size="large"
                                                                                bordered={false}
                                                                                placeholder="Saisisser le commentaire..."
                                                                                value={eventLibelle}
                                                                                maxLength={2000}
                                                                                // showCount
                                                                                autoSize={{
                                                                                    minRows: 1,
                                                                                    maxRows: 5,
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    handleEventLibelleChange(rubriqueIndex, eventIndex, e)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Td>
                                                                )}
                                                                {assertionIndex === 0 && (
                                                                    <Td width={320} rowSpan={colSpanIncidence}>
                                                                        <div className="">
                                                                            <Input.TextArea
                                                                                style={{fontSize: "13px"}}
                                                                                size="large"
                                                                                // bordered={false}
                                                                                placeholder="Saisisser le commentaire..."
                                                                                value={incidenceValue}
                                                                                maxLength={2000}
                                                                                // showCount
                                                                                autoSize={{
                                                                                    minRows: 2,
                                                                                    maxRows: 5,
                                                                                }}
                                                                                onChange={(e) => {
                                                                                    handleIncidenceChange(rubriqueIndex, eventIndex, incidenceIndex, e)
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Td>
                                                                )}
                                                                <Td width={200}>
                                                                   <div className="d-flex flex-column">
                                                                       {postesValue.map((poste, index) => (
                                                                           poste ? <div className="mb-2" key={index}>
                                                                               <Tag
                                                                                   style={{fontSize: "10px", fontWeight: "700", textTransform: "uppercase"}}
                                                                               >
                                                                                   {`${poste} - ${(postesStat ?? []).find( opt => opt.key === poste)?.title}`}
                                                                               </Tag>
                                                                           </div>
                                                                               :
                                                                               ""
                                                                       ))}
                                                                   </div>
                                                                </Td>
                                                                <Td width={200}>
                                                                    <Select
                                                                        mode='tags'
                                                                        style={{width: '100%', fontSize: "10px"}}
                                                                        placeholder='choisir les assertions'
                                                                        value={assertionValue}
                                                                        options={assertions.map((item) => ({
                                                                            label: item.libelle,
                                                                            value: item.libelle,
                                                                        }))}
                                                                        onChange={(e) => {
                                                                            handleAssertionChange(rubriqueIndex, eventIndex, incidenceIndex, assertionIndex, e)
                                                                        }}
                                                                    />
                                                                </Td>
                                                                <Td width={100}>
                                                                    {loading ? (
                                                                        <Spinner />
                                                                    ) : (
                                                                        <>
                                                                            <Space>
                                                                                <Tooltip title='Gestion incidence'>
                                                                                    <Popover
                                                                                        title="Incidence"
                                                                                        content={
                                                                                        <>
                                                                                           <Space>
                                                                                               <Tooltip title="Ajouter une incidence">
                                                                                                   <Button
                                                                                                       shape={"circle"}
                                                                                                       style={{backgroundColor: appTheme.colors.primary, color: "white"}}
                                                                                                       icon={<FontAwesomeIcon icon={faCirclePlus} />}
                                                                                                       onClick={(e) => addNewIncidence(rubriqueIndex, eventIndex, e)}
                                                                                                   />
                                                                                               </Tooltip>
                                                                                               <Tooltip title="Supprimer une incidence">
                                                                                                   <Button
                                                                                                       shape={"circle"}
                                                                                                       title="Supprimer ?"
                                                                                                       style={{backgroundColor: "red", color: "white"}}
                                                                                                       icon={<FontAwesomeIcon icon={faTrash} />}
                                                                                                       onClick={(e) => handleDeleteIncidence(rubriqueIndex, eventIndex, incidenceIndex, e)}
                                                                                                   />
                                                                                               </Tooltip>
                                                                                           </Space>
                                                                                        </>
                                                                                        }
                                                                                        trigger="click"
                                                                                    >
                                                                                        <Button
                                                                                            shape={"circle"}
                                                                                            style={{backgroundColor: appTheme.colors.alter, color: "white"}}
                                                                                            icon={<FontAwesomeIcon icon={faToolbox} />}
                                                                                        />
                                                                                    </Popover>

                                                                                </Tooltip>
                                                                                <Tooltip title="Gestion Poste EF">
                                                                                    <Button
                                                                                        shape={"circle"}
                                                                                        style={{backgroundColor: appTheme.colors.primary_blue, color: "white"}}
                                                                                        icon={<FontAwesomeIcon icon={faCalendarPlus} />}
                                                                                        onClick={() => handleModalOpen(2, rubriqueIndex, eventIndex, incidenceIndex, assertionIndex)}
                                                                                    />
                                                                                </Tooltip>
                                                                            </Space>
                                                                        </>
                                                                    )}
                                                                </Td>
                                                                {incidenceIndex === 0 && assertionIndex === 0 && (
                                                                 <Td rowSpan={colSpanEvent} colSpan={colSpanIncidence}>
                                                                     <Tooltip title="Supprimer l'evenement source de risque">
                                                                         <Button
                                                                             shape={"circle"}
                                                                             title="Supprimer ?"
                                                                             style={{backgroundColor: "red", color: "white"}}
                                                                             icon={<FontAwesomeIcon icon={faTrash} />}
                                                                             onClick={(e) =>
                                                                                 handleDeleteElement(rubriqueIndex, eventIndex, e)
                                                                             }
                                                                         />
                                                                     </Tooltip>
                                                                 </Td>
                                                                )}
                                                            </Tr>
                                                        );
                                                    })
                                                ))
                                            ))
                                        ))
                                        : null}
                                </Tbody>
                            </TableChackra>
                        </TableContainer>
                    )}

                </div>
            </div>
            {(isModalOpen && selectedModalId === 1) && <ModalRisqueQuestion
                handleVisibility={handleModalOpen}
                visible={isModalOpen}
                selectData={selecteData}
                setSelectData={setSelectData}
                assertions={assertions}
                rubriques={rubriques}
                rubrique_id={rubrique_id}
                chargement={loading}
            />}
            {(isModalOpen && selectedModalId === 2) &&
                <div
                    className="top-0 bottom-0 z-index-9999 h-100"
                    style={{ zIndex: 9999, insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
                >
                    <div className="modal fade show" id="kt_modal_move_to_folder" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)', zIndex: 9999 }}>
                        <DynamicInputComponent
                            handleVisibility={handleModalOpen}
                            visible={isModalOpen}
                            selectData={selecteData}
                            setSelectData={setSelectData}
                            chargement={loading}
                            tabIndex={tabIndex}
                        />
                    </div>
                </div>
            }
        </>
    );
}

export {TraitementIdentification}