import React, {useEffect, useState} from "react";
import moment from "moment";
import {Col, DatePicker, Input, Select, Space, Table, Tag} from "antd";
import {getListeEquipeMission} from "../../../data/exercice";
import {appTheme} from "../../../config/theme";
import { TagItem} from "../../common/common.components";
import {ClockCircleOutlined} from "@ant-design/icons";
import PageLabel from "../../common/PageLabel/PageLabel";
import FormLabel from "../../common/Form/FormLabel";
import SearchRow from "../../common/SearchRow";
import {getListeTacheActive} from "../../../data/params/tache";
import {getListeInterventionActive} from "../../../data/params/intervention";
import {getListeRole} from "../../../data/params/role";
import TableTitle from "../../common/TableTitle";

const Identification = ({identifications, mission}) => {
    const [equipeIdentifications, setEquipeIdentifications] = useState([]);
    const [filtersItems, setFiltersItems] = useState([]);
    const [taches, setTaches] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
    });

    const [paramsRecherche, setParamsRecherche] = useState({
        infosUser: "",
        tache_id: null,
        intervention_id: null,
        role_id: null,
        date_debut: "",
        date_fin: "",
    });

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setEquipeIdentifications(identifications)
            setLoading(false)
        }, 1500)
    }, [identifications])

    useEffect(() => {
        const fetchListeTache = () => {
            getListeTacheActive().then((res) => {
                setTaches(res?.result);
            });
        };

        const fetchIntervention = () => {
            getListeInterventionActive().then((res) => {
                setInterventions(res?.result);
            });
        };

        const fetchRole = () => {
            getListeRole().then((res) => {
                setRoles(res);
            });
        };

        fetchRole();
        fetchListeTache();
        fetchIntervention();
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setEquipeIdentifications([]);
        }
    };

    useEffect(() => {
        fetchEquipes(mission.id);
    }, [mission, identifications]);

    const fetchEquipes = (id) => {
        getListeEquipeMission(id)
            .then((res) => {
                const updatedIdentifications = identifications.map((item) => {
                    const user = res.find((key) => key.membre_id === item.Lea_User?.id);
                    return user ? { ...item, role: user.Lea_RoleMission } : item;
                });
                setEquipeIdentifications(res.length > 0 ? updatedIdentifications : identifications);
                setFiltersItems(res.length > 0 ? updatedIdentifications : identifications);
            })
            .catch((error) => {
                console.error("Erreur", error);
            });
    };

    const columns = [
        {
            title: () => <TableTitle>Nom et Prénoms / Initial</TableTitle>,
            dataIndex: 'name',
            width: 150,
            render: (_, record) => (
                <>
                    <span style={{ color: "#000", fontWeight: "bold" }}>
                        {record.Lea_User?.initial ? record.Lea_User?.initial : (record.Lea_User?.nom_user + ' ' + record.Lea_User?.prenom_user)}
                    </span>
                    <br />
                    {record.Lea_User?.initial && <Tag prefix="@" color={appTheme.colors.primary_blue}>{record.Lea_User?.initial}</Tag>}
                </>
            ),
        },
        {
            title: () => <TableTitle>Titre</TableTitle>,
            dataIndex: 'titre',
            width: 150,
            render: (_, record) => (
                <TagItem color={appTheme.colors.primary_blue}>{record?.role?.libelle}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Interventions</TableTitle>,
            width: 200,
            dataIndex: 'interventionType',
            render: (_, record) => (
                <TagItem>{record.Bog_Intervention?.libelle}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Tâches</TableTitle>,
            dataIndex: 'intervention',
            width: 200,
            render: (_, record) => (
                <TagItem>{record.Bog_TacheAutorise?.libelle}</TagItem>
            )
        },
        {
            title: () => <TableTitle>Date</TableTitle>,
            dataIndex: 'date',
            width: 150,
            render: (_, record) => (
                <div className="d-flex flex-column align-items-center">
                    <span className='chart-blue display-block fs-6 pb-3' style={{fontWeight: 600}}>
                        <ClockCircleOutlined style={{fontSize: '16px'}} />{' '}
                            {moment(record.dateAdd).format('DD/MM/YYYY à HH:mm')}
                    </span>
                    <Space>
                        <Tag color="green">
                            {record?.dateAdd && <span className='fw-bolder fs-7'>
                            {moment(record.dateAdd).format("HH:mm:ss")}
                        </span>}
                        </Tag>
                        <Tag color="red">
                            {record?.heureEntree && <span className='fw-bolder fs-7'>
                            {moment(record.createdAt).format("HH:mm:ss")}
                        </span>}
                        </Tag>
                    </Space>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Temps d'interventions</TableTitle>,
            dataIndex: 'heureEntree',
            width: 100,
            render: (_, record) => (
                record.dateAdd && <span className='fw-bolder fs-7' >
                    {/*{moment(record.createdAt).diff(moment(record.dateAdd), 'minutes')} minutes{}*/}
                    {`${(moment(record.createdAt).diff(moment(record.dateAdd), 'hour'))} H ${(moment(record.createdAt).diff(moment(record.dateAdd), 'minutes') || "")} min`}
                </span>
            ),
        },
        // {
        //     title: () => <TableTitle>Heure de sortie</TableTitle>,
        //     dataIndex: 'heureSortie',
        //     width: 100,
        //     render: (_, record) => (
        //         <span className='fw-bolder fs-7' >
        //             {(moment(record.createdAt).format("HH:mm:ss"))}
        //         </span>
        //     ),
        // },
        {
            title: () => <TableTitle>Reference</TableTitle>,
            dataIndex: 'reference',
            width: 100,
            render: (_, record) => (
                record.codeSommaire && <Tag color={appTheme.colors.primary}>{record.codeSommaire}</Tag>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = equipeIdentifications.filter(ident =>
                (ident.nom_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    ident.initial?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    ident.Lea_User?.nom_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    ident.Lea_User?.prenom_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase())) &&
                (paramsRecherche.role_id === "" || ident.role_id === paramsRecherche.role_id) &&
                (paramsRecherche.tache_id === "" || ident.tache_id === paramsRecherche.tache_id) &&
                (paramsRecherche.intervention_id === "" || ident.intervention_id === paramsRecherche.intervention_id)
            );
            setFiltersItems(filteredClients)
            setLoading(false);
        }, 2000);
    };

    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            infosUser: "",
            tache_id: null,
            intervention_id: null,
            role_id: null,
            date_debut: "",
            date_fin: "",
        });
        setFiltersItems(equipeIdentifications)
        setLoading(false);
    };

    return (
        <>
            <PageLabel label="Liste des identifications"/>
            <SearchRow
                handleSearch={handleSearch}
                handleReset={handleReset}
                loading={loading}
            >
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 4 }}
                    xl={{ span: 4 }}
                >
                    <FormLabel label="Utilisateur" />
                    <Input
                        style={{ width: "100%" }}
                        size="large"
                        placeholder="Nom, prenom, initial, téléphone, email"
                        onChange={(e) =>
                            setParamsRecherche({
                                ...paramsRecherche,
                                infosUser: e.target.value,
                            })
                        }
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 4 }}
                    xl={{ span: 4 }}
                >
                    <FormLabel label="Roles" />
                    <Select
                        showSearch
                        placeholder="Sélectionnez la rôle..."
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={roles.map((role) => ({
                            value: role.id,
                            label: role.libelle,
                        }))}

                        onSelect={(value) =>
                            setParamsRecherche({ ...paramsRecherche, role_id: value })
                        }
                        size="large"
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 4 }}
                    xl={{ span: 4 }}
                >
                    <FormLabel label="Tâches" />
                    <Select
                        showSearch
                        placeholder="Sélectionnez la tâche..."
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={taches.map((tache) => ({
                            value: tache.id,
                            label: tache.libelle,
                        }))}

                        onSelect={(value) =>
                            setParamsRecherche({ ...paramsRecherche, tache_id: value })
                        }
                        size="large"
                        style={{ width: "100%" }}
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 4 }}
                    xl={{ span: 4 }}
                >
                    <FormLabel label="Interventions" />
                    <Select
                        showSearch
                        placeholder="Sélectionnez l'intervation..."
                        optionFilterProp="label"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={interventions.map((tache) => ({
                            value: tache.id,
                            label: tache.libelle,
                        }))}
                        onSelect={(value) =>
                            setParamsRecherche({ ...paramsRecherche, intervention_id: value })
                        }
                        size="large"
                        style={{ width: "100%"}}
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 4 }}
                    xl={{ span: 4 }}
                >
                    <FormLabel label="Date début" />
                    <DatePicker
                        size="large"
                        placeholder="Date début"
                        style={{ width: "100%" }}
                        allowClear
                        onChange={(date, dateString) =>
                            date !== null
                                ? setParamsRecherche({
                                    ...paramsRecherche,
                                    date_debut: date.format("YYYY-MM-DD"),
                                })
                                : setParamsRecherche({ ...paramsRecherche, date_debut: "" })
                        }
                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 4 }}
                    xl={{ span: 4 }}
                >
                    <FormLabel label="Date fin" />
                    <DatePicker
                        size="large"
                        placeholder="Date fin"
                        style={{ width: "100%" }}
                        allowClear
                        onChange={(date, dateString) =>
                            date !== null
                                ? setParamsRecherche({
                                    ...paramsRecherche,
                                    date_fin: date.format("YYYY-MM-DD"),
                                })
                                : setParamsRecherche({ ...paramsRecherche, date_fin: "" })
                        }
                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                    />
                </Col>
            </SearchRow>
            <div className="w-100">
                <div className="py-4">
                    <div className="table-responsive">
                        <Table
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={filtersItems}
                            pagination={tableParams.pagination}
                            loading={loading}
                            onChange={handleTableChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export {Identification}