import axios from "axios";
import {
    AJOUT_STRATEGIE_GLOBAL_SIGNIFICATION,
    CONTEXTE_STRATEGIE,
    ORGANISATION_STRATEGIE, STRATEGIE_ANOMALIE_POSSIBLE, STRATEGIE_GLOBAL_SIGNIFICATION,
    STRATEGIE_GLOBALE_MISSION,
    STRATEGIE_INFO_GENERALE, STRATEGIE_OBSERVATION_GENERALE, STRATEGIE_POLITQUE_COMMERCIALE, STRATEGIE_ZONE_RISQUE
} from "../../api/apiRoutes";

const AJOUT_BUDGET_TEMPS = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-budget-temps-equipe`
const AJOUT_PROGRAMME_TACHE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-programme-tache`
const AJOUT_RAPPORT_EMMETRE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-rapport`
const AJOUT_STRATEGIE_BILAN_EA = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale/enregistrer-bilan-etats-financiers`
const AJOUT_STRATEGIE_RISQUE_COMMENTAIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale/enregistrer-risque-commentaire`


const LISTE_STRATEGIE_RISQUE_COMMENTAIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale/risque-commentaire`
const LISTE_STRATEGIE_BILAN_EA = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale/bilan-etats-financiers`
const LISTE_MATERIALITE_PERRFORMANCE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/materialite-performance`
const LISTE_MATERIALITE_PERRFORMANCE_NIVEAU = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/materialite-performance-niveau`
const LISTE_MATERIALITE_SPECIFIQUE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/materialite-specifique`
const LISTE_ANOMALIE_INSIGNIFIQUE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/anomalie-insignificative`
const LISTE_CARACTERE_SIGNIFICATIF = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/caractere-significatif`
const LISTE_EVALUATION_SEUIL = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/evaluation-seuil`
const LISTE_FAIT_PAR = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/fait-par`

export const ajouterStrategieContexte = async (data) => {
    return axios.post(CONTEXTE_STRATEGIE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterStrategieOrganisation = async (data) => {
    return axios.post(ORGANISATION_STRATEGIE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterStrategiePolitiqueCom = async (data) => {
    return axios.post(STRATEGIE_POLITQUE_COMMERCIALE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterStrategiZoneRisque = async (data) => {
    return axios.post(STRATEGIE_ZONE_RISQUE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterStrategiAnomaliePossible = async (data) => {
    return axios.post(STRATEGIE_ANOMALIE_POSSIBLE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterStrategieInfoGenerale = async (data) => {
    return axios.post(STRATEGIE_INFO_GENERALE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterObservationGenerale = async (data) => {
    return axios.post(STRATEGIE_OBSERVATION_GENERALE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getStrategieGlobaleMission = async (exercice_id) => {
    return axios.get(`${STRATEGIE_GLOBALE_MISSION}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterStrategieGlobalSignification = async (data) => {
    return axios.post(AJOUT_STRATEGIE_GLOBAL_SIGNIFICATION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterBudgetTemps = async (data) => {
    return axios.post(AJOUT_BUDGET_TEMPS, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterProgrammeTache = async (data) => {
    return axios.post(AJOUT_PROGRAMME_TACHE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterRapportEmettre = async (data) => {
    return axios.post(AJOUT_RAPPORT_EMMETRE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterStrategieBilanEA = async (data) => {
    return axios.post(AJOUT_STRATEGIE_BILAN_EA, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getStrategieBilanEA = async (strategie_id) => {
    return axios.get(`${LISTE_STRATEGIE_BILAN_EA}/${strategie_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterRisqueCommentaire = async (data) => {
    return axios.post(AJOUT_STRATEGIE_RISQUE_COMMENTAIRE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getStrategieRisqueCommentaire = async (strategie_id) => {
    return axios.get(`${LISTE_STRATEGIE_RISQUE_COMMENTAIRE}/${strategie_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getStrategieGlobaleSignification = async (exercice_id) => {
    return axios.get(`${STRATEGIE_GLOBAL_SIGNIFICATION}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getMaterialitePerformance = async (strategie_id) => {
    return axios.get(`${LISTE_MATERIALITE_PERRFORMANCE}/${strategie_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getMaterialitePerformanceNiveau = async (strategie_id) => {
    return axios.get(`${LISTE_MATERIALITE_PERRFORMANCE_NIVEAU}/${strategie_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getMaterialiteSpecifique = async (strategie_id) => {
    return axios.get(`${LISTE_MATERIALITE_SPECIFIQUE}/${strategie_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getAnomalieInsignifiante = async (strategie_id) => {
    return axios.get(`${LISTE_ANOMALIE_INSIGNIFIQUE}/${strategie_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getCaractereSignificatif = async (strategie_id) => {
    return axios.get(`${LISTE_CARACTERE_SIGNIFICATIF}/${strategie_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getEvaluationFinaleSeuil = async (strategie_id) => {
    return axios.get(`${LISTE_EVALUATION_SEUIL}/${strategie_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getStrategieFaitPar = async (strategie_id) => {
    return axios.get(`${LISTE_FAIT_PAR}/${strategie_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}