import React, {useEffect, useState} from "react";
import {
    DeleteFilled,
} from "@ant-design/icons";
import {
    addFileQuestionnaireClient,
    getListeFileQuestionnaire
} from "../../../../../../data/file";
import swal from "sweetalert";
import {DOWNLOAD_FILE_CLIENT} from "../../../../../../api/apiRoutes";
import {cleanAndNormalizeName} from "../../../../../utilities/Utilities";
import {Form, Formik} from "formik";
import {Button, Col, Divider, Input, Row, Space} from "antd";
import FormLabel from "../../../../../common/Form/FormLabel";
import FormError from "../../../../../common/Form/FormError";
import * as Yup from "yup";
import {getFilenameWithoutExtension, getIconForFileType} from "../../../../../layout/menu/CustomMenu";
import { Typography } from 'antd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {appTheme} from "../../../../../../config/theme";

const { Title } = Typography;

const FileUploadQuestionnaire = ({setIsFileAdd, isFileAdd, exercice, codeSommaire }) => {
    const [loading, setLoading] = useState(false);
    const [questionnaire, setQuestionnaire] = useState("")

    const fetchDocuments = async (exercice_id, codeSommaire) => {
        try {
            const res = await getListeFileQuestionnaire(exercice_id, codeSommaire)
            if (res.result[0]) {
                setQuestionnaire(res.result[0])
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des événements risques', error)
        }
    }

    useEffect(() => {
        if(codeSommaire && exercice){
            fetchDocuments(exercice.id, codeSommaire);
        }
    }, [codeSommaire, exercice])

    const handleUploadAll = async (value) => {
        setLoading(true);

        const fileSizeLimit = 10 * 1024 * 1024;
        for (const file of value.selectedFiles) {
            if (file.size <= fileSizeLimit) {
                const extension = file.name.split(".").pop().toLowerCase();

                const formData = new FormData();
                formData.append("file", file);
                formData.append("reference", value.reference);
                formData.append("libelle", value.libelle);
                formData.append("description", value.description);
                formData.append("exercice_id", exercice.id);
                formData.append("client_id", exercice.client_id);
                formData.append("codeSommaire", codeSommaire);
                formData.append('size', file.size);
                formData.append('extension', extension);

                try {
                    const response = await addFileQuestionnaireClient(formData);
                    if (response.status === "success") {
                        await swal('Opération effectuée avec succès', '', 'success')
                        setIsFileAdd(true)
                        setLoading(false)
                    } else {
                        await swal(response.message, '', 'error')
                        setLoading(false)
                    }
                } catch (error) {
                    console.error("Error uploading file:", error);
                    console.log("Failed to upload file: " + file.name);
                    setLoading(false)
                }
            } else {
                console.log("File size exceeds the limit: " + file.name);
                setLoading(false)
            }
        }
    };

    const downloadDocumentsClient = (client_bog, client_lea, fichier) => {
        const clientBogName = cleanAndNormalizeName(client_bog);
        const clientName = cleanAndNormalizeName(client_lea);
        const missionName = cleanAndNormalizeName(exercice.mission)

        window.open(`${DOWNLOAD_FILE_CLIENT}/${clientBogName}/${clientName}/${exercice.annee}/${missionName}/${fichier}`, '_blank')
    }

    const dataValidationScheme = Yup.object({
        client_id: Yup.number()
            .required("Veuillez sélectionner le client")
            .nullable("Le client est obligatoire")
            .typeError("Le client est obligatoire"),
        mission_id: Yup.number()
            .required("Veuillez sélectionner la mission")
            .nullable("Le mission est obligatoire")
            .typeError("Le mission est obligatoire"),
        libelle: Yup.string().required("Le nom du fichier est obligatoire"),
        // code: Yup.string().required("Le code sommaire du DP est obligatoire"),
    });

    return (
        <>
            <div className="w-100">
                {(exercice?.isCloture === 1 || exercice?.isClotureProvisoire === 1) ?
                    (
                        <>
                            {!questionnaire ?
                                <Title style={{fontWeight: '600', color: "white", backgroundColor: appTheme.colors.success, textAlign: "center"}}
                                       level={3}>Cette mission a été clôturée !</Title>
                                :
                                <div className="d-flex flex-column align-center align-center align-items-center">
                                    <Title style={{fontWeight: '600', color: appTheme.colors.success, textAlign: "center"}}
                                           level={4}>Cette mission a été clôturée !</Title>
                                    <div>
                                        <Button
                                            onClick={() => downloadDocumentsClient(questionnaire?.Bog_Client?.nom_cabinet, questionnaire?.Lea_Client?.denomination, questionnaire?.fichier)}
                                            icon={<FontAwesomeIcon icon={faEye}/>}
                                            type="primary"
                                        >
                                            Consulter le formulaire soumit
                                        </Button>
                                    </div>
                                    <br/>
                                </div>
                            }
                        </>
                    )
                    :
                    (
                        <>
                            {!isFileAdd ?
                                <Formik
                                    initialValues={{
                                        selectedFiles: [],
                                        reference: `${codeSommaire}-00X`,
                                        codeSommaire: codeSommaire,
                                        libelle: '',
                                        description: "",
                                        client_id: (exercice && exercice.client_id) || "",
                                        mission_id: (exercice && exercice.id) || ""
                                    }}
                                    onSubmit={(values) => handleUploadAll(values)}
                                    validationSchema={dataValidationScheme}
                                >
                                    {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                                        <Form>
                                            <div className="card-title">
                                                <br/>
                                                <Title style={{ display: "block", fontWeight: '600', color: appTheme.colors.primary_blue, textAlign: "center"}} level={3}>Formulaire de questionnaire manuscrit</Title>
                                            </div>
                                            <Divider/>
                                            <div className="d-flex justify-content-center align-center mx-auto w-lg-600px p-10">
                                                <Row gutter={[16, 16]}>
                                                    <Col span={20}>
                                                        <FormLabel label="Fichier" required />
                                                        <div className='row fv-row mb-7 fv-plugins-icon-container'>
                                                            <div className='d-flex align-center'>
                                                                <div className=''>
                                                                    <div className='d-flex align-items-center mb-3'>
                                                                        <input
                                                                            type='file'
                                                                            style={{width: "100%"}}
                                                                            className='form-control w-lg-400px'
                                                                            // accept=".xlsx, .csv, .xls"
                                                                            onChange={(value) => {
                                                                                const files = value.target.files;
                                                                                const fileSizeLimit = 10 * 1024 * 1024;
                                                                                const validFiles = Array.from(files).filter(
                                                                                    (file) => file.size <= fileSizeLimit
                                                                                );
                                                                                setFieldValue("selectedFiles", [...values.selectedFiles, ...validFiles])
                                                                                setFieldValue("libelle", getFilenameWithoutExtension(validFiles[0].name));
                                                                            }}
                                                                            required={true}
                                                                        />
                                                                    </div>
                                                                    <span className='form-text fs-6 text-muted'>
                                                            Taille maximale de fichier 10MB.
                                                        </span>
                                                                </div>
                                                            </div>

                                                            <div className='dropzone-items'>
                                                                {/* Show the list of selected files */}
                                                                {values.selectedFiles.map((file, index) => (
                                                                    <div key={index} className='m-6'>
                                                                        {getIconForFileType(file.name)} {file.name}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        {touched.selectedFiles && errors.selectedFiles && (
                                                            <FormError fieldName="selectedFiles" />
                                                        )}
                                                    </Col>
                                                    <Col span={4}>
                                                        <div className='pt-10'>
                                                            <button
                                                                className='dropzone-remove-all btn btn-sm btn-light-primary'
                                                                onClick={() => {
                                                                    setFieldValue("selectedFiles", [])
                                                                }}
                                                            >
                                                                <DeleteFilled style={{color: 'red', fontSize: '15px'}}/>
                                                            </button>
                                                        </div>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Space.Compact block>
                                                            <Input
                                                                style={{
                                                                    width: '30%',
                                                                }}
                                                                value="Reférence"
                                                                readOnly={true}
                                                            />
                                                            <Input
                                                                style={{width: "70%"}}
                                                                size="large"
                                                                placeholder="Saisir la reférence"
                                                                name="reference"
                                                                value={values.reference}
                                                                onChange={(e) =>
                                                                    setFieldValue("reference", e.target.value)
                                                                }
                                                            />
                                                        </Space.Compact>
                                                        {touched.reference && errors.reference && (
                                                            <FormError fieldName="reference" />
                                                        )}

                                                    </Col>
                                                    <Col span={24}>
                                                        <Space.Compact block>
                                                            <Input
                                                                style={{
                                                                    width: '30%',
                                                                }}
                                                                value="Nom fichier"
                                                                readOnly={true}
                                                            />
                                                            <Input
                                                                style={{width: "70%"}}
                                                                size="large"
                                                                placeholder="Le nom du fichier"
                                                                name="libelle"
                                                                value={values.libelle}
                                                                onChange={(e) =>
                                                                    setFieldValue("libelle", e.target.value)
                                                                }
                                                            />
                                                        </Space.Compact>
                                                        {touched.libelle && errors.libelle && (
                                                            <FormError fieldName="libelle" />
                                                        )}
                                                    </Col>
                                                    <Col span={24}>
                                                        <FormLabel label="Note" />
                                                        <Input.TextArea
                                                            size="large"
                                                            placeholder="Notes..."
                                                            name="notes"
                                                            value={values.description}
                                                            onChange={(e) =>
                                                                setFieldValue("description", e.target.value)
                                                            }
                                                            showCount
                                                            maxLength={2000}
                                                            rows={4}
                                                        />
                                                        {touched.description && errors.description && (
                                                            <FormError fieldName="notes" />
                                                        )}
                                                    </Col>
                                                    <Divider/>
                                                    <Col span={24}>
                                                        <Button
                                                            type="primary"
                                                            block
                                                            size="large"
                                                            loading={loading}
                                                            disabled={loading}
                                                            onClick={handleSubmit}
                                                        >
                                                            {!loading && <span className='indicator-label'>Enregistrer</span>}
                                                            {loading && (
                                                                <span className="indicator-progress text-white">
                                            Veuillez patienter...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                        </span>
                                                            )}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                                :
                                <>
                                    <div className="d-flex flex-column align-center align-center align-items-center">
                                        <Title style={{fontWeight: '600', color: appTheme.colors.success, textAlign: "center"}} level={4}>Vous avez soumis avec succès le formulaire de questionnaire manuscrit !</Title>
                                        <div>
                                            <Button
                                                onClick={() => downloadDocumentsClient(questionnaire?.Bog_Client?.nom_cabinet, questionnaire?.Lea_Client?.denomination, questionnaire?.fichier)}
                                                icon={<FontAwesomeIcon icon={faEye}/>}
                                                type="primary"
                                            >
                                                Consulter
                                            </Button>
                                        </div>
                                        <br/>
                                    </div>
                                </>
                            }
                        </>
                    )
                }
            </div>
        </>
    );
};

export default FileUploadQuestionnaire;
