import React from "react";

const PrintHeader = ({title}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '5px',
    };

  return(
      <>
          <div className="card px-15 pt-15">
              <div className="px-10">
                  <div className="d-flex align-center">
                      <div className="col-md-9">
                          <div className="d-flex flex-column">
                              <table style={tableStyle} className="table table-bordered h-lg-15px">
                                  <thead>
                                  <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                                      <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Traitement Préliminaire</th>
                                      <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Supervision Préliminaire</th>
                                      <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Revue Associé Préliminaire</th>
                                      <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Contrôle qualité Préliminaire</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr style={tdStyle}>
                                      <td style={tdStyle} className="h-10px">
                                      </td>
                                      <td style={tdStyle} className="h-10px">
                                      </td>
                                      <td style={tdStyle} className="h-10px">

                                      </td>
                                      <td style={tdStyle} className="h-10px">
                                      </td>
                                  </tr>
                                  </tbody>
                              </table>
                              <table style={tableStyle} className="table table-bordered">
                                  <thead>
                                  <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                                      <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Traitement Final</th>
                                      <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Supervision Final</th>
                                      <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Revue Associé Final</th>
                                      <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Contrôle qualité Final</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr style={tdStyle}>
                                      <td style={tdStyle} className="h-10px">
                                      </td>
                                      <td style={tdStyle} className="h-10px">
                                      </td>
                                      <td style={tdStyle} className="h-10px">

                                      </td>
                                      <td style={tdStyle} className="h-10px">
                                      </td>
                                  </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      <div style={thStyle} className="col-md-3">
                          <div className="text-gray-600 align-middle text-center"><span>LOGO DU CABINET</span></div>
                      </div>
                  </div>
                  <div className="">
                      <h1 className="fw-bolder text-black mb-3 text-center py-10">{title || ""}</h1>

                      <div className='gx-5 d-flex align-center'>
                          <div className='col-xl-6 mb-5 mb-xl-10 px-5'>
                              <div className="d-flex flex-column">
                                  <div className="d-flex align-items-center">
                                      <div className="col-md-6">
                                          <span className="text-gray-800 fw-bold" style={{fontSize: "12px"}}>Référence du dossier :</span>
                                      </div>
                                      <div className="col-md-6">
                                          <input
                                              style={{fontSize: '11px'}}
                                              className="form-control form-control-transparent form-select-lg mb-2 mb-lg-0"
                                              type="text"
                                              // value={record.refFT}
                                              name='post'
                                              // onChange={(e) =>{}}
                                          />
                                      </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                      <div className="d-flex align-items-center">
                                          <div className="col-md-6" style={{fontSize: "12px"}}>
                                              <span className="text-gray-800 fw-bold">Exercice : </span>
                                          </div>
                                          <div className="col-md-6">
                                              <input
                                                  style={{fontSize: '11px'}}
                                                  className="form-control form-control-transparent form-select-lg mb-2 mb-lg-0"
                                                  type="text"
                                                  // value={record.refFT}
                                                  name='post'
                                                  // onChange={(e) =>{}}
                                              />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-xl-6 mb-5 mb-xl-10 px-5'>
                              <div className="d-flex flex-column">
                                  <div className="d-flex align-items-center">
                                      <div className="d-flex align-items-center">
                                          <div className="col-md-6" style={{fontSize: "12px"}}>
                                              <span className="text-gray-800 fw-bold">Référence du Fichier : </span>
                                          </div>
                                          <div className="col-md-6">
                                              <input
                                                  style={{fontSize: '11px'}}
                                                  className="form-control form-control-transparent form-select-lg mb-2 mb-lg-0"
                                                  type="text"
                                                  // value={record.refFT}
                                                  name='post'
                                                  // onChange={(e) =>{}}
                                              />
                                          </div>
                                      </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                      <div className="d-flex align-items-center">
                                          <div className="col-md-6" style={{fontSize: "12px"}}>
                                              <span className="text-gray-800 fw-bold">Date : </span>
                                          </div>
                                          <div className="col-md-6">
                                              <input
                                                  style={{fontSize: '11px'}}
                                                  className="form-control form-control-transparent form-select-lg mb-2 mb-lg-0"
                                                  type="text"
                                                  // value={record.refFT}
                                                  name='post'
                                                  // onChange={(e) =>{}}
                                              />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

const PageHeader = ({}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '5px',
    };

    return (
        <div className="d-flex align-center">
            <div className="col-md-9">
                <div className="d-flex flex-column">
                    <table style={tableStyle} className="table table-bordered h-lg-15px">
                        <thead>
                        <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                            <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Traitement Préliminaire</th>
                            <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Supervision Préliminaire</th>
                            <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Revue Associé Préliminaire</th>
                            <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Contrôle qualité Préliminaire</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={tdStyle}>
                            <td style={tdStyle} className="h-5px">
                            </td>
                            <td style={tdStyle} className="h-5px">
                            </td>
                            <td style={tdStyle} className="h-5px">

                            </td>
                            <td style={tdStyle} className="h-5px">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table style={tableStyle} className="table table-bordered">
                        <thead>
                        <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                            <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Traitement Final</th>
                            <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Supervision Final</th>
                            <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Revue Associé Final</th>
                            <th className="text-center h-5px" style={{fontSize: "7px", textDecoration: 'underline'}}>Contrôle qualité Final</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={tdStyle}>
                            <td style={tdStyle} className="h-5px">
                            </td>
                            <td style={tdStyle} className="h-5px">
                            </td>
                            <td style={tdStyle} className="h-5px">

                            </td>
                            <td style={tdStyle} className="h-5px">
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={thStyle} className="col-md-3">
                <div className="text-gray-600 align-middle text-center"><span>LOGO DU CABINET</span></div>
            </div>
        </div>
    )
}

const PrintHeaderTraitement = ({title, intervention_id, tache_id, user}) => {

  return(
      <>
          <div className="card px-15 pt-15">
              <div className="px-10">
                  <PageHeader
                      intervention_id={intervention_id}
                      tache_id={tache_id}
                      user={user}
                  />
                  <div className="">
                      <h1 className="fw-bolder text-black mb-3 text-center py-10">{title || ""}</h1>

                      <div className='gx-5 d-flex align-center'>
                          <div className='col-xl-6 mb-5 mb-xl-10 px-5'>
                              <div className="d-flex flex-column">
                                  <div className="d-flex align-items-center">
                                      <div className="col-md-6">
                                          <span className="text-gray-800 fw-bold" style={{fontSize: "12px"}}>Référence du dossier :</span>
                                      </div>
                                      <div className="col-md-6">
                                          <input
                                              style={{fontSize: '11px'}}
                                              className="form-control form-control-transparent form-select-lg mb-2 mb-lg-0"
                                              type="text"
                                              // value={record.refFT}
                                              name='post'
                                              // onChange={(e) =>{}}
                                          />
                                      </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                      <div className="d-flex align-items-center">
                                          <div className="col-md-6" style={{fontSize: "12px"}}>
                                              <span className="text-gray-800 fw-bold">Exercice : </span>
                                          </div>
                                          <div className="col-md-6">
                                              <input
                                                  style={{fontSize: '11px'}}
                                                  className="form-control form-control-transparent form-select-lg mb-2 mb-lg-0"
                                                  type="text"
                                                  // value={record.refFT}
                                                  name='post'
                                                  // onChange={(e) =>{}}
                                              />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div className='col-xl-6 mb-5 mb-xl-10 px-5'>
                              <div className="d-flex flex-column">
                                  <div className="d-flex align-items-center">
                                      <div className="d-flex align-items-center">
                                          <div className="col-md-6" style={{fontSize: "12px"}}>
                                              <span className="text-gray-800 fw-bold">Référence du Fichier : </span>
                                          </div>
                                          <div className="col-md-6">
                                              <input
                                                  style={{fontSize: '11px'}}
                                                  className="form-control form-control-transparent form-select-lg mb-2 mb-lg-0"
                                                  type="text"
                                                  // value={record.refFT}
                                                  name='post'
                                                  // onChange={(e) =>{}}
                                              />
                                          </div>
                                      </div>
                                  </div>
                                  <div className="d-flex align-items-center">
                                      <div className="d-flex align-items-center">
                                          <div className="col-md-6" style={{fontSize: "12px"}}>
                                              <span className="text-gray-800 fw-bold">Date : </span>
                                          </div>
                                          <div className="col-md-6">
                                              <input
                                                  style={{fontSize: '11px'}}
                                                  className="form-control form-control-transparent form-select-lg mb-2 mb-lg-0"
                                                  type="text"
                                                  // value={record.refFT}
                                                  name='post'
                                                  // onChange={(e) =>{}}
                                              />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

const PrintContent = ({ headerTitle, contentComponent }) => {
    return (
        <div>
            <PrintHeader title={headerTitle} />
            <div className="print-content">{contentComponent}</div>
        </div>
    );
};

export {PrintHeader, PrintContent, PrintHeaderTraitement}