import React, {useEffect, useState} from "react";
import {SwapOutlined} from "@ant-design/icons";
import {getListePlanComptable} from "../../../../../data/params/planComptable";
import {BilanActif} from "./EC3/etatsFinanciers/BilanActif";
import {BilanPassif} from "./EC3/etatsFinanciers/BilanPassif";
import {CompteResultat} from "./EC3/etatsFinanciers/CompteResultat";
import {getListeExerciceClients} from "../../../../../data/exercice";
import {getBalanceDefinitifDetails, getBalanceProvisoiresDetails} from "../../../../../data/balance/balance";
import {SearchCompte} from "./EC3/etatsFinanciers/SearchCompte";
import {ajouterBilanActif, ajouterBilanPassif, ajouterCompteResutat} from "../../../../../data/balance/etatFinancier";
import swal from "sweetalert";
import {ajouterZeros, areArraysEqual} from "../../../../utilities/Utilities";
import {
    initialTotalValuesActifs,
    initialValuesActifs,
    initialValuesPassif, initialValuesResult
} from "./EC3/etatsFinanciers/EtatsFinanciersData";
import {Button, Col, Divider, Row, Tooltip} from "antd";
import {
    faBookOpenReader,
    faIdCard,
    faSave,
    faScaleBalanced
} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {ControleEquilibre} from "./EC3/etatsFinanciers/ControlleEquilibre";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {appTheme} from "../../../../../config/theme";
import {EtatFinanciers} from "./EC3/etatsFinanciers/EtatFinanciersPrint";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const RecapTotalStyle = {
    display: "block",
    // fontSize: "1.6rem",
    letterSpacing: ".1rem",
    lineHeight: ".8rem",
    fontWeight: "700",
    marginBottom: "5px",
    color: appTheme.colors.primary,
}

const RecapTitleStyle = {
    fontSize: "0.9rem",
    fontWeight: "bold",
    color: "black",
    textAlign: "center"
}

const CalculEtatFinanciers = ({
          mission,
          client,
          code,
          // modalId,
          prev
    }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [bzActif, setBzActif] = useState(0);
    const [dzPassif, setDzPassif] = useState(0);
    const [cjPassif, setCjPassif] = useState(0);
    const [bzActifN1, setBzActifN1] = useState(0);
    const [dzPassifN1, setDzPassifN1] = useState(0);
    const [cjPassifN1, setCjPassifN1] = useState(0);
    const [xiResultat, setXiResultat] = useState(0);
    const [xiResultatN1, setXiResultatN1] = useState(0);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [plans, setPlans] = useState([]);
    const [balances, setBalances] = useState([]);
    const [missionN1, setMissionN1] = useState(null);
    const [balanceDataN1, setBalancesDataN1] = useState([])
    const [date, setDate] = useState('');
    const [tache_id, setTache_id] = useState(null);
    const [intervention_id, setIntervention_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [heureEntree, setHeureEntree] = useState('');
    const [codeSommaire] = useState(code || 'EC2.2');
    const [actifsData, setActifsData] = useState([]);
    const [passifsData, setPassifsData] = useState([]);
    const [resultsData, setResultsData] = useState([]);
    const [valuesActifsN1, setValuesActifsN1] = useState([]);
    const [valuesActifs, setValuesActifs] = useState([]);
    const [valuesPassifs, setValuesPassifs] = useState([]);
    const [valuesPassifsN1, setValuesPassifsN1] = useState([]);
    const [valuesResults, setValuesResults] = useState([]);
    const [valuesResultsN1, setValuesResultsN1] = useState([]);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (client) {
            getListeExerciceClients(client.id)
                .then((res) => {
                    const missN1 = res.find((opt) => parseInt(opt.annee) === parseInt(missionSelected?.annee) - 1)
                    setMissionN1(missN1)
                });
        }
    }, [client, missionSelected])

    const fetchPlanComptable = async () => {
        getListePlanComptable()
            .then((res) => {
                setPlans(res.result);
            });
    };

    useEffect(() => {
        fetchPlanComptable();
    }, []);

    const fetchBalanceProvisoire = async (id) => {
        setLoading(true)
        getBalanceProvisoiresDetails(id)
            .then((res) => {
                if (res.results) {
                    setBalances(res.results.Lea_BalanceDetails)
                    setLoading(false)
                } else {
                    setBalances([])
                    setLoading(false)
                }
            })
    }

    const fetchBalanceDefinitif = async (id) => {
        setLoading(true)
        getBalanceDefinitifDetails(id)
            .then((res) => {
                if (res.results) {
                    setBalances(res.results.Lea_BalanceDetails)
                    setLoading(false)
                } else {
                    setBalances([])
                    setLoading(false)
                }
            })
    }

    const fetchBalanceDefinitifN1 = async (id) => {
        setLoading(true)
        getBalanceDefinitifDetails(id)
            .then((res) => {
                if (res.results) {
                    setBalancesDataN1(res.results.Lea_BalanceDetails)
                    setLoading(false)
                } else {
                    setBalancesDataN1([])
                    setLoading(false)
                }
            })
    }

    useEffect(() => {
        if (parseInt(isProvisoire) === 1) {
            fetchBalanceProvisoire(missionSelected.id);
        } else {
            fetchBalanceDefinitif(missionSelected.id);
        }
    }, [missionSelected, isProvisoire])

    useEffect(() => {
        if (missionN1) {
            fetchBalanceDefinitifN1(missionN1.id)
        }
    }, [missionN1])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    /** begin::EA ACTIF **/
    function filterBalancesActif(updateData, item, plans, sens) {
        return updateData.filter((balance) => {
            const accountNumber = balance.numeroCompte.toString();
            const startsWithFilterValue = item.value.some((value) => accountNumber.startsWith(value));
            const startsWithException = item.except.some((exception) => accountNumber.startsWith(exception));

            let accountDirection = null;

            if(sens === "D"){
                accountDirection = balance.soldeDebit;
            }
            else if(sens === "C"){
                accountDirection = balance.soldeCredit;
            }

            // if (item.sens) {
            //     item.value.forEach((value) => {
            //         const plansExist = plans.filter((plan) => plan.numero.startsWith(value) && plan.sensCompte === item.sens);
            //         accountsPlan.push(...plansExist);
            //     });
            //
            //     const accountInPlan = accountsPlan.find((plan) => plan.numero === accountNumber.slice(0, 4));
            //     accountDirection = accountInPlan ? accountInPlan.sensCompte : 'C';
            // }
            return startsWithFilterValue && !startsWithException && (!accountDirection || accountDirection);

            // return startsWithFilterValue && !startsWithException && (!accountDirection || accountDirection === item.sens);
        });
    }

    function calculateTotalBrut(filteredBalances) {
        return filteredBalances.reduce((accumulator, balance) => accumulator + (balance.soldeDebit), 0);
    }

    function calculateTotalAmor(filteredBalances) {
        return filteredBalances.reduce((accumulator, balance) => accumulator + (balance.soldeCredit), 0);
    }
    /** end::EA ACTIF **/

    /** begin::EA PASSIF **/
    const filterBalancesPassif = (data, valueArray, exceptArray, sens) => {
        return data.filter((balance) => {
            const accountNumber = balance.numeroCompte.toString();
            const startsWithFilterValue = valueArray.some((value) => accountNumber.startsWith(value));
            const startsWithException = exceptArray.some((exception) => accountNumber.startsWith(exception));

            let accountDirection = null;

            if(sens === "D"){
                accountDirection = balance.soldeDebit;
            }
            else if(sens === "C"){
                accountDirection = balance.soldeCredit;
            }

            // if (sens) {
            //     valueArray.forEach((value) => {
            //         const plansExist = plans.filter((plan) => plan.numero.startsWith(value) && plan.sensCompte === sens);
            //         accountsPlan = [...accountsPlan, ...plansExist];
            //     });
            //
            //     const accountInPlan = accountsPlan.find((plan) => plan.numero === accountNumber.slice(0, 4));
            //
            //     accountDirection = accountInPlan ? accountInPlan.sensCompte : 'D';
            // }

            return startsWithFilterValue && !startsWithException && (!accountDirection || accountDirection);
            // return startsWithFilterValue && !startsWithException && (!accountDirection || accountDirection === sens);
        });
    };

    const calculateTotalSoldePassif = (filteredBalances, ref) => {
        if (ref === 'CH') {
            const compte_121 = filteredBalances.filter(item => item.numeroCompte.startsWith("121"));
            const compte_129 = filteredBalances.filter(item => item.numeroCompte.startsWith("129"));

            const totalCompte_121 = compte_121.reduce((accumulator, balance) => accumulator + ((balance.soldeCredit || 0)), 0)
            const totalCompte_129 = compte_129.reduce((accumulator, balance) => accumulator + (-(balance.soldeDebit || 0)), 0)
            return totalCompte_121 + totalCompte_129;
        } else {
            return filteredBalances.reduce((accumulator, balance) => accumulator + ((balance.soldeCredit || 0)), 0);
        }
    };
    /** end::EA PASSIF **/

    const handleCalculFinancier = () => {
        setLoading(true)

        if(balanceDataN1 && balanceDataN1.length > 0 ){
            let cjValueN1 = 0
            /** begin::EA ACTIF **/
            if(initialValuesActifs.length > 0 && initialTotalValuesActifs.length > 0){
                const newSelectedElementsList = [...valuesActifsN1];
                const selectedRefs = new Set(newSelectedElementsList.map((element) => element.ref));

                initialValuesActifs.forEach((item) => {
                    if (!selectedRefs.has(item.ref)) {
                        const filteredBalancesBrut = filterBalancesActif(balanceDataN1, item.brut, plans, item.brut.sens);

                        const totalSoldeBrut = calculateTotalBrut(filteredBalancesBrut);

                        const filteredBalancesAmort = filterBalancesActif(balanceDataN1, item.amortissement, plans, item.brut.sens);
                        const totalSoldeAmort = calculateTotalAmor(filteredBalancesAmort);

                        const newElement = {
                            ref: item.ref,
                            libelle: item.libelle,
                            brut: totalSoldeBrut,
                            amortissement: totalSoldeAmort,
                            exerciceN: totalSoldeBrut - totalSoldeAmort,
                            exerciceN1: 0,
                        };

                        newSelectedElementsList.push(newElement);

                    }
                });

                initialTotalValuesActifs.forEach((item) => {
                    if (!selectedRefs.has(item.ref)) {
                        const filteredValueTotal = newSelectedElementsList.filter((data) =>
                            item.references.some((value) => data.ref === value)
                        );

                        const totalBrut = filteredValueTotal.reduce((accumulator, total) => accumulator + total.brut, 0);
                        const totalAmor = filteredValueTotal.reduce((accumulator, total) => accumulator + total.amortissement, 0);

                        const newElement = {
                            ref: item.ref,
                            libelle: item.libelle,
                            brut: totalBrut,
                            amortissement: totalAmor,
                            exerciceN: totalBrut - totalAmor,
                            exerciceN1: 0,
                            color: item.color,
                        };

                        if (item.ref === 'BZ') {
                            setBzActifN1(totalBrut - totalAmor);
                        }

                        newSelectedElementsList.push(newElement);
                    }
                });

                const array = newSelectedElementsList.sort((a, b) => a.ref.localeCompare(b.ref));

                if (!areArraysEqual(array, valuesActifsN1)) {
                    setValuesActifsN1(array);
                }
            }
            /** end::EA ACTIF **/

            /** begin::EA RESULTAT **/
            if(initialValuesResult.length > 0){
                const newSelectedElementsList = [...valuesResultsN1];
                const selectedRefs = new Set(newSelectedElementsList.map((element) => element.ref));

                initialValuesResult.forEach((item) => {
                    if (
                        !selectedRefs.has(item.ref)
                    ) {
                        if(!['XA', 'XB', 'XC', 'XD', 'XE', 'XF', 'XG', 'XH', 'XI'].includes(item.ref)){
                            const filterBalances = (valueArray, exceptArray) => {
                                return balanceDataN1.filter((balance) => {
                                    const accountNumber = balance.numeroCompte.toString();
                                    const startsWithFilterValue = valueArray.some((value) => accountNumber.startsWith(value));
                                    const startsWithException = exceptArray.some((exception) =>
                                        accountNumber.startsWith(exception)
                                    );
                                    return startsWithFilterValue && !startsWithException;
                                });
                            };

                            const filteredBalances = filterBalances(item.references.value, item.references.except);
                            const bilanBalance = filteredBalances.filter((item) => parseInt(item.numeroCompte) < ajouterZeros(6, item.numeroCompte))
                            const arrayResultat  = filteredBalances.filter((item) => parseInt(item.numeroCompte) >= ajouterZeros(6, item.numeroCompte))

                            const totalSoldeBilan = bilanBalance.reduce(
                                (accumulator, balance) => accumulator + ((balance.soldeDebit || 0) + (-(balance.soldeCredit || 0))),
                                0
                            );

                            const totalSoldeResult = arrayResultat.reduce(
                                (accumulator, balance) => accumulator + ((-(balance.soldeDebit || 0)) + (balance.soldeCredit || 0)),
                                0
                            );

                            const newElement = {
                                ref: item.ref,
                                libelle: item.libelle,
                                note: '',
                                type: item.type,
                                signe: item.signe,
                                exerciceN: item.signe === '-' ? (totalSoldeBilan + totalSoldeResult) : (totalSoldeBilan + totalSoldeResult),
                                exerciceN1: 0,
                                index: item.index
                            };

                            newSelectedElementsList.push(newElement);
                        }
                        else {
                            const filteredTotal = (valueArray) => {
                                return newSelectedElementsList.filter((data) => {
                                    return valueArray.some((value) => data.ref === value);
                                });
                            };
                            const filteredValueTotal = filteredTotal(item.references.value);

                            const totalBrut = filteredValueTotal.reduce((accumulator, total) => {
                                return accumulator + parseInt(total.exerciceN);
                            }, 0);

                            const newElement = {
                                ref: item.ref,
                                libelle: item.libelle,
                                note: '',
                                type: item.type,
                                signe: item.signe,
                                exerciceN: totalBrut,
                                exerciceN1: 0,
                                index: item.index,
                                color: item.color
                            }

                            if(item.ref.includes('XI')){
                                cjValueN1 = (totalBrut || 0)
                                setCjPassifN1(totalBrut || 0)
                                setXiResultatN1(totalBrut || 0)
                            }

                            newSelectedElementsList.push(newElement);
                        }
                    }
                })

                const array = newSelectedElementsList.sort((a, b) => {
                    return a.index - b.index;
                })

                if (!areArraysEqual(array, valuesResultsN1)) {
                    setValuesResultsN1(array);
                }
            }
            /** end::EA RESULTAT **/

            /** begin::EA PASSIF **/
            if(initialValuesPassif.length > 0){
                const newSelectedElementsList = [...valuesPassifsN1];
                const selectedRefs = new Set(newSelectedElementsList.map((element) => element.ref));

                initialValuesPassif.forEach((item) => {
                    if (!selectedRefs.has(item.ref)) {
                        if (!(['CP', 'DD', 'DF', 'DP', 'DT', 'DZ'].includes(item.ref))) {
                            let newFilteredBalances = filterBalancesPassif(balanceDataN1, item.references.value, item.references.except, item.sens);
                            const totalSolde = calculateTotalSoldePassif(newFilteredBalances, item.ref);

                            const newElement = {
                                ref: item.ref,
                                libelle: item.libelle,
                                note: '',
                                exerciceN: item.ref === 'CJ' ? cjValueN1 : totalSolde,
                                exerciceN1: 0,
                                color: item.color,
                            };

                            newSelectedElementsList.push(newElement);
                        } else {
                            const filteredTotal = (valueArray) => {
                                return newSelectedElementsList.filter((data) => {
                                    return valueArray.some((value) => data.ref === value);
                                });
                            };

                            const filteredValueTotal = filteredTotal(item.references.value);
                            const totalSolde = filteredValueTotal.reduce((accumulator, balance) => accumulator + balance.exerciceN, 0);

                            const newElement = {
                                ref: item.ref,
                                libelle: item.libelle,
                                note: '',
                                exerciceN: totalSolde,
                                exerciceN1: 0,
                                color: item.color,
                            };

                            if (item.ref === 'DZ') {
                                setDzPassifN1(totalSolde);
                            }

                            newSelectedElementsList.push(newElement);
                        }

                        const array = newSelectedElementsList.sort((a, b) => {
                            return a.ref.localeCompare(b.ref);
                        });

                        if (!areArraysEqual(array, valuesPassifsN1)) {
                            setValuesPassifsN1(array);
                        }
                    }
                });
            }
            /** end::EA PASSIF **/
        }

        if(balances && balances.length > 0 ){
            let cjValue = 0
            /** begin::EA ACTIF **/
            if(initialValuesActifs.length > 0 && initialTotalValuesActifs.length > 0){
                const newSelectedElementsList = [...valuesActifs];
                const selectedRefs = new Set(newSelectedElementsList.map((element) => element.ref));

                initialValuesActifs.forEach((item) => {
                    if (!selectedRefs.has(item.ref)) {
                        const filteredBalancesBrut = filterBalancesActif(balances, item.brut, plans);

                        const totalSoldeBrut = calculateTotalBrut(filteredBalancesBrut);

                        const filteredBalancesAmort = filterBalancesActif(balances, item.amortissement, plans);
                        const totalSoldeAmort = calculateTotalAmor(filteredBalancesAmort);

                        let montant = 0;
                        const valueN1 = valuesActifsN1.find((opt) => opt.ref.includes(item.ref));
                        if (valueN1) {
                            montant = valueN1.exerciceN;
                        }

                        const newElement = {
                            ref: item.ref,
                            libelle: item.libelle,
                            brut: totalSoldeBrut,
                            amortissement: totalSoldeAmort,
                            exerciceN: totalSoldeBrut - totalSoldeAmort,
                            exerciceN1: montant,
                        };

                        newSelectedElementsList.push(newElement);

                    }
                });

                initialTotalValuesActifs.forEach((item) => {
                    if (!selectedRefs.has(item.ref)) {
                        const filteredValueTotal = newSelectedElementsList.filter((data) =>
                            item.references.some((value) => data.ref === value)
                        );

                        const totalBrut = filteredValueTotal.reduce((accumulator, total) => accumulator + total.brut, 0);
                        const totalAmor = filteredValueTotal.reduce((accumulator, total) => accumulator + total.amortissement, 0);

                        let montant = 0;

                        const valueN1 = valuesActifsN1.find((opt) => opt.ref.includes(item.ref));
                        if (valueN1) {
                            montant = valueN1.exerciceN;
                        }

                        const newElement = {
                            ref: item.ref,
                            libelle: item.libelle,
                            brut: totalBrut,
                            amortissement: totalAmor,
                            exerciceN: totalBrut - totalAmor,
                            exerciceN1: montant,
                            color: item.color,
                        };

                        if (item.ref === 'BZ') {
                            setBzActif(totalBrut - totalAmor);
                        }

                        newSelectedElementsList.push(newElement);
                    }
                });

                const array = newSelectedElementsList.sort((a, b) => a.ref.localeCompare(b.ref));

                if (!areArraysEqual(array, valuesActifs)) {
                    setValuesActifs(array);
                }
            }
            /** end::EA ACTIF **/

            /** begin::EA RESULTAT **/
            if(initialValuesResult.length > 0){
                const newSelectedElementsList = [...valuesResults];
                const selectedRefs = new Set(newSelectedElementsList.map((element) => element.ref));

                initialValuesResult.forEach((item) => {
                    if (
                        !selectedRefs.has(item.ref)
                    ) {

                        if(!['XA', 'XB', 'XC', 'XD', 'XE', 'XF', 'XG', 'XH', 'XI'].includes(item.ref)){
                            const filterBalances = (valueArray, exceptArray) => {
                                return balances.filter((balance) => {
                                    const accountNumber = balance.numeroCompte.toString();
                                    const startsWithFilterValue = valueArray.some((value) => accountNumber.startsWith(value));
                                    const startsWithException = exceptArray.some((exception) =>
                                        accountNumber.startsWith(exception)
                                    );
                                    return startsWithFilterValue && !startsWithException;
                                });
                            };

                            const filteredBalances = filterBalances(item.references.value, item.references.except);
                            const bilanBalance = filteredBalances.filter((item) => parseInt(item.numeroCompte) < ajouterZeros(6, item.numeroCompte))
                            const arrayResultat  = filteredBalances.filter((item) => parseInt(item.numeroCompte) >= ajouterZeros(6, item.numeroCompte))

                            const totalSoldeBilan = bilanBalance.reduce(
                                (accumulator, balance) => accumulator + ((balance.soldeDebit || 0) + (-(balance.soldeCredit || 0))),
                                0
                            );

                            const totalSoldeResult = arrayResultat.reduce(
                                (accumulator, balance) => accumulator + ((-(balance.soldeDebit || 0)) + (balance.soldeCredit || 0)),
                                0
                            );

                            const newElement = {
                                ref: item.ref,
                                libelle: item.libelle,
                                note: '',
                                type: item.type,
                                signe: item.signe,
                                exerciceN: item.signe === '-' ? (totalSoldeBilan + totalSoldeResult) : (totalSoldeBilan + totalSoldeResult),
                                exerciceN1: 0,
                                index: item.index
                            };

                            newSelectedElementsList.push(newElement);
                        }
                        else {
                            const filteredTotal = (valueArray) => {
                                return newSelectedElementsList.filter((data) => {
                                    return valueArray.some((value) => data.ref === value);
                                });
                            };
                            const filteredValueTotal = filteredTotal(item.references.value);

                            const totalBrut = filteredValueTotal.reduce((accumulator, total) => {
                                return accumulator + parseInt(total.exerciceN);
                            }, 0);

                            const newElement = {
                                ref: item.ref,
                                libelle: item.libelle,
                                note: '',
                                type: item.type,
                                signe: item.signe,
                                exerciceN: totalBrut,
                                exerciceN1: 0,
                                index: item.index,
                                color: item.color
                            }

                            if(item.ref.includes('XI')){
                                setXiResultat(totalBrut)
                                setCjPassif(totalBrut || 0)
                                cjValue = (totalBrut || 0)
                            }

                            newSelectedElementsList.push(newElement);
                        }
                    }
                })

                const array = newSelectedElementsList.sort((a, b) => {
                    return a.index - b.index;
                })

                if (!areArraysEqual(array, valuesResults)) {
                    setValuesResults(array);
                }
            }
            /** end::EA RESULTAT **/

            /** begin::EA PASSIF **/
            if(initialValuesPassif.length > 0){
                const newSelectedElementsList = [...valuesPassifs];
                const selectedRefs = new Set(newSelectedElementsList.map((element) => element.ref));

                initialValuesPassif.forEach((item) => {
                    if (!selectedRefs.has(item.ref)) {
                        if (!(['CP', 'DD', 'DF', 'DP', 'DT', 'DZ'].includes(item.ref))) {
                            let newFilteredBalances = filterBalancesPassif(balances, item.references.value, item.references.except, item.sens);
                            const totalSolde = calculateTotalSoldePassif(newFilteredBalances, item.ref);

                            const newElement = {
                                ref: item.ref,
                                libelle: item.libelle,
                                note: '',
                                exerciceN: item.ref === 'CJ' ? cjValue : totalSolde,
                                exerciceN1: 0,
                                color: item.color,
                            };

                            newSelectedElementsList.push(newElement);
                        } else {
                            const filteredTotal = (valueArray) => {
                                return newSelectedElementsList.filter((data) => {
                                    return valueArray.some((value) => data.ref === value);
                                });
                            };

                            const filteredValueTotal = filteredTotal(item.references.value);
                            const totalSolde = filteredValueTotal.reduce((accumulator, balance) => accumulator + balance.exerciceN, 0);

                            const newElement = {
                                ref: item.ref,
                                libelle: item.libelle,
                                note: '',
                                exerciceN: totalSolde,
                                exerciceN1: 0,
                                color: item.color,
                            };

                            if (item.ref === 'DZ') {
                                setDzPassif(totalSolde);
                            }

                            newSelectedElementsList.push(newElement);
                        }

                        const array = newSelectedElementsList.sort((a, b) => {
                            return a.ref.localeCompare(b.ref);
                        });

                        if (!areArraysEqual(array, valuesPassifs)) {
                            setValuesPassifs(array);
                        }
                    }
                });
            }
            /** end::EA PASSIF **/
        }
        setTimeout(() => {
            setLoading(false)
            setIsTaskSave([1, 2, 3])
        }, 1500);

    }

    useEffect(() => {
        const updateData = valuesActifs.map((item, index)=>({
            ref: item.ref,
            libelle: item.libelle,
            brut: item.brut,
            amortissement: item.amortissement,
            montant: item.exerciceN,
            montantN1: valuesActifsN1[index]?.exerciceN,
            isProvisoire: parseInt(isProvisoire) === 1
        }))

        setActifsData(updateData)
    }, [isProvisoire, valuesActifs, valuesActifsN1])

    useEffect(() => {
        const updateData = valuesPassifs.map((item)=>({
            ref: item.ref,
            libelle: item.libelle,
            note: item.note,
            montant: item.exerciceN,
            montantN1: valuesPassifsN1.find(val => val.ref === item.ref)?.exerciceN || 0,
            isProvisoire: parseInt(isProvisoire) === 1
        }))

        setPassifsData(updateData)
    }, [isProvisoire, valuesPassifs, valuesPassifsN1])

    useEffect(() => {
        const updateData = valuesResults.map((item, index)=>({
            ref: item.ref,
            libelle: item.libelle,
            note: item.note,
            type: item.type,
            signe: item.signe,
            montant: item.exerciceN,
            montantN1: valuesResultsN1[index]?.exerciceN,
            isProvisoire: parseInt(isProvisoire) === 1
        }))

        setResultsData(updateData)
    }, [isProvisoire, valuesResults, valuesResultsN1])


    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true);

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            isProvisoire,
            bilanData: activeTab === 1 ? actifsData : activeTab === 2 ? passifsData : resultsData,
            exercice_id: missionSelected.id,
            client_id: client.id,
        };

        const {status, message} = activeTab === 1 ? await ajouterBilanActif(data) : activeTab === 2 ? await ajouterBilanPassif(data) : await ajouterCompteResutat(data)
        if (status === 'success') {
            setLoading(false)
            setAuth(false);
            setIsTaskSave(prevState => (prevState ?? []).filter((item) => item !== activeTab));
            await swal(`Opération effectuée avec succès`, '', 'success')
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "BA",
            title: `BILAN ACTIF - ${selectYear}`,
            icon: faBookOpenReader,
            content: (
                <BilanActif
                    mission={missionSelected}
                    client={client}
                    values={valuesActifs}
                    valuesN1={valuesActifsN1}
                    loading={loading}
                />
            ),
        },
        {
            code: "BP",
            title: `BILAN PASSIF - ${selectYear}`,
            description: 'Bilan Passif',
            icon: faScaleBalanced,
            content: (
                <BilanPassif
                    mission={missionSelected}
                    client={client}
                    values={valuesPassifs}
                    valuesN1={valuesPassifsN1}
                    loading={loading}
                />
            ),
        },
        {
            code: "CR",
            title: `COMPTE DE RESULTAT - ${selectYear}`,
            description: 'Compte de Résultat',
            icon: faScaleBalanced,
            content: (
                <CompteResultat
                    mission={missionSelected}
                    client={client}
                    values={valuesResults}
                    valuesN1={valuesResultsN1}
                    loading={loading}
                />
            ),
        },
        {
            code: "RC",
            title: `CONTRÔLE DES EQUILBRES - ${selectYear}`,
            description: 'Contrôle des équilibres généraux',
            icon: faScaleBalanced,
            content: (
                <ControleEquilibre
                    cjPassif={cjPassif || 0}
                    cjPassifN1={cjPassifN1 || 0}
                    xiResultat={xiResultat || 0}
                    xiResultatN1={xiResultatN1 || 0}
                    bzActif={bzActif || 0}
                    bzActifN1={bzActifN1 || 0}
                    dzPassif={dzPassif || 0}
                    dzPassifN1={dzPassifN1 || 0}
                />
            ),
        },
        {
            code: "CE",
            title: `RECHERCHE ET CORRECTION - ${selectYear}`,
            description: 'Recherche et correction de comptes',
            icon: faScaleBalanced,
            content: (
                <SearchCompte
                    intervention_id={intervention_id}
                    date={date}
                    heureEntree={heureEntree}
                    tache_id={tache_id}
                    user_id={user_id}
                    codeSommaire={codeSommaire}
                    mission={missionSelected}
                    client={client}
                    isProvisoire={isProvisoire}
                    setBalancesData={setBalances}
                    balanceData={balances}
                    chargement={loading}
                    plans={plans}
                />
            ),
        },
    ];


    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EC2.2: Calcul des états financiers"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isTaskSave={isTaskSave}
                    isExcelPrint={true}
                    printTitle={(`EC2.2: CALCUL DES ÉTATS FINANCIERS ${selectYear}`)}
                    excelData={[
                        {id: 1, libelle: "ACTIFS", data: actifsData},
                        {id: 2, libelle: "PASSIFS", data: passifsData},
                        {id: 3, libelle: "COMPTE RESULTATS", data: resultsData},
                        {id: 4, libelle: "SIG", data: []},
                    ]}
                    isEF={true}
                    // excelData={(activeTab === 1) ? actifsData : (activeTab === 2) ? passifsData : resultsData}
                    printContent={(
                        <EtatFinanciers
                            data={(activeTab === 1) ? actifsData : (activeTab === 2) ? passifsData : resultsData}
                            isActif={true}
                            isPassif={true}
                            isResult={true}
                        />
                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>
                        <Row gutter={[16, 16]}>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 12 }}
                                xl={{ span: 12 }}
                            >
                                {(activeTab === 1 || activeTab === 2) && <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <div
                                            className="border-1 border-warning rounded-3 p-3 mx-auto cursor-pointer"
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <div style={{marginBottom: "10px"}}>
                                                <FontAwesomeIcon
                                                    style={{fontSize: "14px"}}
                                                    color={appTheme.colors.primary_blue}
                                                    icon={faScaleBalanced}
                                                />
                                            </div>
                                            <div>
                                          <span style={RecapTotalStyle}>
                                            {bzActif.toLocaleString('fr')}
                                          </span>
                                                <span style={RecapTitleStyle}>
                                            BZ(Bilan Actif N)
                                          </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div
                                            className="border-1 border-warning rounded-3 p-3 mx-auto cursor-pointer"
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <div style={{marginBottom: "10px"}}>
                                                <FontAwesomeIcon
                                                    style={{fontSize: "14px"}}
                                                    color={appTheme.colors.primary_blue}
                                                    icon={faScaleBalanced}
                                                />
                                            </div>
                                            <div>
                                          <span style={RecapTotalStyle}>
                                            {dzPassif.toLocaleString('fr')}
                                          </span>
                                                <span style={RecapTitleStyle}>
                                            DZ(Bilan Passif N)
                                          </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div
                                            className="border-1 border-warning rounded-3 p-3 mx-auto cursor-pointer"
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <div style={{marginBottom: "10px"}}>
                                                <FontAwesomeIcon
                                                    style={{fontSize: "14px"}}
                                                    color={appTheme.colors.primary_blue}
                                                    icon={faScaleBalanced}
                                                />
                                            </div>
                                            <div>
                                          <span style={RecapTotalStyle}>
                                            {(bzActif - dzPassif).toLocaleString('fr')}
                                          </span>
                                                <span style={RecapTitleStyle}>
                                            RESULTAT
                                          </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>}
                                {(activeTab === 3 || activeTab === 4) && <Row gutter={[16, 16]}>
                                    <Col span={8}>
                                        <div
                                            className="border-1 border-warning rounded-3 p-3 mx-auto cursor-pointer"
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <div style={{marginBottom: "10px"}}>
                                                <FontAwesomeIcon
                                                    style={{fontSize: "14px"}}
                                                    color={appTheme.colors.primary_blue}
                                                    icon={faScaleBalanced}
                                                />
                                            </div>
                                            <div>
                                          <span style={RecapTotalStyle}>
                                            {cjPassif.toLocaleString('fr')}
                                          </span>
                                            <span style={RecapTitleStyle}>
                                            CJ(Bilan Passif N)
                                          </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div
                                            className="border-1 border-warning rounded-3 p-3 mx-auto cursor-pointer"
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <div style={{marginBottom: "10px"}}>
                                                <FontAwesomeIcon
                                                    style={{fontSize: "14px"}}
                                                    color={appTheme.colors.primary_blue}
                                                    icon={faScaleBalanced}
                                                />
                                            </div>
                                            <div>
                                          <span style={RecapTotalStyle}>
                                            {xiResultat.toLocaleString('fr')}
                                          </span>
                                                <span style={RecapTitleStyle}>
                                            XI(Compte Résultat)
                                          </span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                        <div
                                            className="border-1 border-warning rounded-3 p-3 mx-auto cursor-pointer"
                                            style={{
                                                width: "100%",
                                            }}
                                        >
                                            <div style={{marginBottom: "10px"}}>
                                                <FontAwesomeIcon
                                                    style={{fontSize: "14px"}}
                                                    color={appTheme.colors.primary_blue}
                                                    icon={faScaleBalanced}
                                                />
                                            </div>
                                            <div>
                                          <span style={RecapTotalStyle}>
                                            {(cjPassif - xiResultat).toLocaleString('fr')}
                                          </span>
                                                <span style={RecapTitleStyle}>
                                            RESULTAT
                                          </span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>}
                            </Col>
                            {(activeTab > 0 && activeTab <= 3) && <Col
                                xs={{span: 24}}
                                md={{span: 24}}
                                lg={{span: 12}}
                                xl={{span: 12}}
                            >
                                <div className="d-flex justify-content-end align-items-end">
                                    <div className="d-flex align-items-center">
                                        <div className="me-10">
                                            <Tooltip title="Calculer les etats financiers">
                                                <Button
                                                    disabled={(missionSelected.isCloture === 1 || missionSelected.isClotureProvisoire === 1)}
                                                    size={"large"}
                                                    loading={loading}
                                                    style={{
                                                        backgroundColor: appTheme.colors.primary_blue,
                                                        color: "white"
                                                    }}
                                                    icon={<SwapOutlined
                                                        style={{fontSize: '20px', marginRight: '5px'}}/>}
                                                    onClick={handleCalculFinancier}
                                                >
                                                    {!loading &&
                                                        <span className='indicator-label text-white'>Calcul EA</span>}
                                                    {loading && (
                                                        <span className='indicator-progress fs-9 text-white'
                                                              style={{display: 'block'}}>
                                              Patientez svp...
                                              <span
                                                  className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                                    )}
                                                </Button>
                                            </Tooltip>
                                        </div>
                                        <div className="me-10">
                                            <Tooltip title="Enregistrer le cyle">
                                                <Button
                                                    size={"large"}
                                                    loading={loading}
                                                    disabled={(missionSelected.isCloture === 1 || missionSelected.isClotureProvisoire === 1)}
                                                    style={{backgroundColor: appTheme.colors.primary, color: "white"}}
                                                    icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                                    onClick={handleSubmit}
                                                >
                                                    {!loading && <span className='indicator-label'>Enregistrer</span>}
                                                    {loading && (
                                                        <span className='indicator-progress fs-9'
                                                              style={{display: 'block'}}>
                                              Patientez svp...
                                              <span
                                                  className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                                    )}
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </Col>}
                        </Row>
                        <Divider/>
                        {formSteps[activeTab].content}
                    </>
                </StepBody>
            </div>
        </>
    )
}

export {CalculEtatFinanciers}