import React, {Fragment, useEffect, useState} from "react";
import {Divider, Input, Select, Skeleton, Space, Typography} from "antd";
import {getListeAutreQuestion} from "../../../../../../data/params/question";
import {
    getListeMembreMission,
    getListeMotifAcceptationMission,
} from "../../../../../../data/exercice";
import {appTheme} from "../../../../../../config/theme";
import FormLabel from "../../../../../common/Form/FormLabel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle} from "@fortawesome/free-solid-svg-icons";
const { Title } = Typography;

const ClientModalAffectation = ({
        mission,
        setObservation_generale,
        second_associer,
        setSecondAssocier,
        associe_responsable,
        observation_generale,
        setAssocieResponsable,
        setEtat,
        affectValid,
        setSelectedIds,
        selectedIds
    }) => {
    const [usersMission, setUsersMission] = useState([]);
    const [questionsAccepation, setQuestionsAccepation] = useState([]);
    const [questionsRefus, setQuestionsRefus] = useState([]);
    // const [selectedIds, setSelectedIds] = useState([]);
    const [isAccept, setIsAccept] = useState(false);
    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //
    // }, [])

    useEffect(() => {
        getListeAutreQuestion()
            .then((res) => {
                const accepts = res.result.filter((item) => item.Bog_GroupeQuestion.libelle === "ACCEPTATION")
                const refus = res.result.filter((item) => item.Bog_GroupeQuestion.libelle === "REFUS")
                if (accepts) {
                    setQuestionsAccepation(accepts)
                }
                if(refus){
                    setQuestionsRefus(refus)
                }
            })
            .catch(error => {
                console.error("Error fetching questionnaire:", error);
            });
    }, [])

    useEffect(() => {
        getListeMotifAcceptationMission(mission.id)
            .then((res) => {
                let arrayAcceptation = []
                let arrayRefus = []

                if(questionsAccepation.length > 0) {
                    arrayAcceptation = new Set(questionsAccepation.map(question => question.id));
                    const arrayEntity = res.filter(element => arrayAcceptation.has(element.question_id));

                    if (arrayEntity.length > 0) {
                        arrayEntity.map((item) => {
                            const ids = selectedIds
                            ids.push(item.question_id)
                            setSelectedIds(ids);
                        });
                        setIsAccept(true)
                    }
                }

                if(questionsRefus.length > 0) {
                    arrayRefus = new Set(questionsRefus.map(question => question.id));
                    const arrayAuditeur = res.filter(element => arrayRefus.has(element.question_id));

                    if (arrayAuditeur.length > 0) {
                        arrayAuditeur.map((item) => {
                            const ids = selectedIds
                            ids.push(item.question_id)
                            setSelectedIds(ids);
                        });
                        setIsAccept(false)
                    }
                }
            })
    }, [mission, questionsAccepation, questionsRefus, selectedIds]);

    const fetchUserMission = async (id) => {
        setLoading(true);
        getListeMembreMission(id)
            .then((res) => {
                if(res){
                    // const usersTab = res || [];
                    const userWithNeant = [...res, {initial: "Néant", nom_user: "Néant"}]
                    // const userWithNeant = usersTab.push({initial: "Néant", nom_user: "Néant"})
                    // setUsersMission(res);
                    setUsersMission(userWithNeant);
                    setLoading(false);
                }
            })
            .catch(error => {
                console.error(error);
            })
    };

    useEffect(() => {
        if(mission){
            fetchUserMission(mission.id);
        }
    }, [mission]);

    const handleCheckboxChange = (isChecked) => {
        setLoading(true)
        setTimeout(() => {
            setIsAccept(isChecked);
            setSelectedIds([])
            if(isChecked === true){
                setEtat(2)
            } else{
                setEtat(3)
            }
            setLoading(false)
        }, 1000)
    };

    const toggleCheckbox = (id) => {
        if (selectedIds.includes(id)) {
            setSelectedIds(selectedIds.filter(selectedId => selectedId !== id));
        } else {
            setSelectedIds([...selectedIds, id]);
        }
    };

    return (
        <Fragment>
            <div className="px-3 pt-15">
                <div className="card-header">
                    <div className="card-title">
                        <Title style={{ display: "block", fontWeight: '600', color: appTheme.colors.primary_blue}} level={3}>EB1.4 - Décision et affectation du dossier</Title>
                    </div>
                </div>
                <div className=" my-10">
                    <div className="card-title mb-2">
                        <Title style={{ fontSize: "24px", display: "block", fontWeight: '600', color: appTheme.colors.primary}} level={5}># A mon avis nous devrions:</Title>
                    </div>
                    <div className="row g-9">
                        <div className="col">
                            <label className={`btn btn-outline btn-outline-dashed btn-outline-${isAccept ? 'primary text-hover-white' : 'default'} d-flex text-start p-6`} data-kt-button="true">
                                <span className="form-check form-check-custom form-check-sm align-items-start mt-1">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="campaign_gender"
                                        checked={isAccept}
                                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                        onChange={(e) =>{
                                            handleCheckboxChange(e.target.checked)
                                            setObservation_generale("")
                                        }}
                                    />
                                </span>
                                <span className="ms-5">
                                    <span style={{fontSize: "24px", fontWeight: "700", fontFamily: "Oswald"}} className={`text-gray-800 text-hover-white d-block`}>ACCEPTER</span>
                                </span>
                            </label>
                        </div>
                        <div className="col">
                            <label className={`btn btn-outline btn-outline-dashed btn-outline-${!isAccept ? 'danger' : 'default'} d-flex text-start p-6`} data-kt-button="true">
                                <span className="form-check form-check-custom form-check-sm align-items-start mt-1">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="accept"
                                        checked={!isAccept}
                                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                        onChange={(e) =>
                                            handleCheckboxChange(!e.target.checked)
                                        }
                                    />
                                </span>
                                <span className="ms-5">
                                    <span style={{fontSize: "24px", fontWeight: "700", fontFamily: "Oswald"}} className={`text-gray-800 text-hover-white d-block`}>REFUSER</span>
                                </span>
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-8 mh-400px scroll-y me-n7 pe-7 mt-15">
                        {loading ?
                            <Skeleton active />
                            :
                            (
                                isAccept ?
                                    <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                        <div className="mb-3">
                                            <h4 className="fw-boldest fs-2 text-decoration-underline">Sélectionner les motifs d'acceptation: </h4>
                                        </div>
                                        <div className='mb-5'></div>
                                        {(questionsAccepation && questionsAccepation.length > 0) && questionsAccepation.map((item, index) => (
                                            <label
                                                onChange={() => !(mission.isCloture === 1 || mission.isClotureProvisoire ===1 || affectValid) ?  toggleCheckbox(item.id) : {}}
                                                key={index}
                                                className="d-flex flex-stack bg-hover-light-primary rounded-3 py-2 px-10 cursor-pointer mb-2"
                                            >
                                        <span className="d-flex align-items-center me-20">
                                            <span className="d-flex flex-column">
                                                <span className="fw-bolder fs-5">{item.libelle}</span>
                                                <span className="fs-7 text-muted">{item.description}</span>
                                            </span>
                                        </span>
                                                <span className="form-check form-check-custom">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                disabled={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                                checked={selectedIds.includes(item.id)}
                                                onChange={() => toggleCheckbox(item.id)}
                                            />
                                        </span>
                                            </label>
                                        ))}
                                    </div>
                                    :
                                    <>
                                        <div className="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                            <div className="mb-3">
                                                <h4 className="fw-boldest fs-2 text-decoration-underline">Sélectionner les motifs de refus: </h4>
                                            </div>
                                            <div className="separator mb-2"></div>
                                            {(questionsRefus && questionsRefus.length > 0) && questionsRefus.map((item, index) => (
                                                <label
                                                    onChange={() => !(mission.isCloture === 1 || mission.isClotureProvisoire ===1 || affectValid) ?  toggleCheckbox(item.id) : {}}
                                                    key={index}
                                                    className="d-flex flex-stack bg-hover-light-primary rounded-3 py-2 px-10 cursor-pointer mb-2"
                                                >
                                            <span className="d-flex align-items-center me-20">
                                            <span className="d-flex flex-column">
                                                <span className="fw-bolder fs-5">{item.libelle}</span>
                                                <span className="fs-7 text-muted">{item.description}</span>
                                            </span>
                                        </span>
                                                    <span className="form-check form-check-custom form-check-solid">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={selectedIds.includes(item.id)}
                                                disabled={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                                onChange={() => toggleCheckbox(item.id)}
                                            />
                                        </span>
                                                </label>
                                            ))}
                                            <div className="fv-plugins-message-container invalid-feedback"></div>
                                        </div>
                                    </>
                            )
                        }
                    </div>
                    {!isAccept &&
                        (
                            <div className="d-flex flex-column my-7">
                                <FormLabel required label="Les raisons pour lesquelles le client n’a pas été accepté sont les suivantes : (à préciser)"/>
                                <Input.TextArea
                                    size="large"
                                    placeholder="Saisissez les raisons de refus"
                                    required={(!isAccept && !observation_generale)}
                                    value={observation_generale}
                                    readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || affectValid}
                                    onChange={(e) =>{
                                        setObservation_generale(e.target.value);
                                    }}
                                    style={{width: "100%", border: (!isAccept && !observation_generale) ? "1px solid red" : "0"}}
                                    showCount
                                    maxLength={5000}
                                    autoSize={{
                                        minRows: 4,
                                        maxRows: 6,
                                    }}
                                />
                                {(!isAccept && !observation_generale) && (
                                    <div className="text-danger">Champ obligatoire *</div>
                                )}
                            </div>
                        )
                    }
                </div>
                <Divider/>
                {isAccept && <div className="w-100">
                    <div className="card-title mb-2">
                        <Title style={{
                            fontSize: "24px",
                            display: "block",
                            fontWeight: '600',
                            color: appTheme.colors.primary
                        }} level={5}># Affectation du dossier:</Title>
                    </div>
                    <div className="d-flex">
                        <div className="col-lg-1">
                            <FontAwesomeIcon icon={faUserCircle} size={"3x"} color={appTheme.colors.primary_blue}/>
                        </div>
                        <div className="col-lg-6">
                            <Space.Compact block>
                                <Input
                                    size="large"
                                    style={{
                                        width: '30%', fontWeight: "400"
                                    }}
                                    value="ASSOCIE RESPONSABLE"
                                    readOnly={true}
                                />
                                {(mission.isCloture === 1 || mission.isClotureProvisoire === 1 || affectValid) ?
                                    (
                                        <Input
                                            size="large"
                                            style={{
                                                width: '70%', fontWeight: "700", textTransform: "uppercase"
                                            }}
                                            value={
                                                associe_responsable ?
                                            `${(usersMission?.find((user) => user.id === associe_responsable)?.initial || "")} - ${(usersMission?.find((user) => user.id === associe_responsable)?.nom_user || "")} ${(usersMission?.find((user) => user.id === associe_responsable)?.prenom_user || "")}`
                                            :
                                            "Néant"
                                            }
                                            readOnly={true}
                                        />
                                    )
                                    :
                                    (
                                        <Select
                                            showSearch
                                            placeholder="Sélectionnez l'associé..."
                                            optionFilterProp="label"
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            value={associe_responsable ? associe_responsable : "Néant"}
                                            readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1 || affectValid)}
                                            options={usersMission?.map((user) => ({
                                                value: user.id,
                                                label: (
                                                    <span
                                                        className="fw-boldest fs-5 text-uppercase"
                                                    >
                                                        {user.id ?
                                                            (
                                                                <span
                                                                    className="fw-boldest fs-5 text-uppercase"
                                                                >
                                                                    {`${user.initial || ""} - ${user.nom_user || ""} ${user.prenom_user || ""}`}
                                                                </span>
                                                            )
                                                            :
                                                            (
                                                                <span
                                                                    className="fw-boldest fs-5 text-uppercase"
                                                                >
                                                                    Néant
                                                                </span>
                                                            )}
                                                    </span>
                                                ),
                                            }))}
                                            onChange={(value) =>
                                                setAssocieResponsable(value)
                                            }
                                            size="large"
                                            style={{width: "70%"}}
                                        />
                                    )
                                }
                            </Space.Compact>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className="d-flex">
                        <div className="col-lg-1">
                            <FontAwesomeIcon icon={faUserCircle} size={"3x"} color={appTheme.colors.primary_blue}/>
                        </div>
                        <div className="col-lg-6">
                            <Space.Compact block>
                                <Input
                                    size="large"
                                    style={{
                                        width: '30%', fontWeight: "400"
                                    }}
                                    value="SECOND ASSOCIE"
                                    readOnly={true}
                                />
                                {(mission.isCloture === 1 || mission.isClotureProvisoire === 1 || affectValid) ?
                                    (
                                        <Input
                                            size="large"
                                            style={{
                                                width: '70%', fontWeight: "700", textTransform: "uppercase"
                                            }}
                                            value={
                                                second_associer ?
                                                    `${(usersMission?.find((user) => user.id === second_associer)?.initial || "")} - ${(usersMission?.find((user) => user.id === second_associer)?.nom_user || "")} ${(usersMission?.find((user) => user.id === second_associer)?.prenom_user || "")}`
                                                    :
                                                    "Néant"
                                            }
                                            readOnly={true}
                                        />
                                    )
                                    :
                                    (
                                        <Select
                                            showSearch
                                            placeholder="Sélectionnez le second associé éventuel..."
                                            optionFilterProp="label"
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            value={second_associer ? second_associer : "Néant"}
                                            readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1 || affectValid)}
                                            options={usersMission?.map((user) => ({
                                                value: user.id,
                                                label: (
                                                    <span
                                                        className="fw-boldest fs-5 text-uppercase"
                                                    >
                                                        {user.id ?
                                                            (
                                                                <span
                                                                    className="fw-boldest fs-5 text-uppercase"
                                                                >
                                                                    {`${user.initial || ""} - ${user.nom_user || ""} ${user.prenom_user || ""}`}
                                                                </span>
                                                            )
                                                            :
                                                            (
                                                                <span
                                                                    className="fw-boldest fs-5 text-uppercase"
                                                                >
                                                                    Néant
                                                                </span>
                                                            )}
                                                    </span>
                                                ),
                                            }))}
                                            onChange={(value) =>
                                                setSecondAssocier(value)
                                            }
                                            size="large"
                                            style={{width: "70%"}}
                                        />
                                    )
                                }
                            </Space.Compact>
                        </div>
                    </div>
                </div>}
            </div>
        </Fragment>
    );
};

export default ClientModalAffectation;
