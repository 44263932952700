import React, {useEffect, useState} from "react";
import {Button, Input, Select, Space, Table, Tooltip, Typography} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import { ajouterRapportEmettre} from "../../../../../../data/stategieGlobale";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../helpers/AssetsHelpers";
import {getListeMembreMission} from "../../../../../../data/exercice";
import TableTitle from "../../../../../common/TableTitle";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faUserCircle} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

const Rapport = ({
         mission,
         strategies,
         rapports,
         isProvisoire,
         codeSommaire,
         heureEntree,
         date,
         tache_id,
         intervention_id,
    }) => {
    const [loading, setLoading] = useState(false);
    const [selectItems, setSelectItems] = useState([]);
    const [users, setUsers] = useState([]);
    const [prepared, setPrepared] = useState('');
    const [review, setReview] = useState('');
    const [commentaire, setCommentaire] = useState('');
    const raportsData = [
        'Le rapport au conseil d’administration (Art. 715)',
        'Le rapport général du commissaire aux comptes',
        'Le rapport spécial du commissaire aux comptes',
        'Attestation d’exécution de la mission',
        'Lettre à la direction'
    ]

    useEffect(() => {
        const fetchUserMission = async (id) => {
            setLoading(true);
            getListeMembreMission(id)
                .then((res) => {
                    if(res){
                        setUsers(res);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        };

        fetchUserMission(mission.id);
    }, [mission]);

    useEffect(() => {
        if(strategies){
            setPrepared(strategies.faitParRapport)
            setReview(strategies.revuParRapport)
            setCommentaire(strategies.commentaireRapport)
        }

        if(rapports && rapports.length > 0 ){
            const taches = rapports.map((tache) => ({
                intitule: tache.intitule,
                dateLimiteProd: new Date(tache.dateLimiteProd).toISOString().split('T')[0],
                dateProd: new Date(tache.dateProd).toISOString().split('T')[0],
            }))

            setSelectItems(taches)
        }

    }, [rapports, strategies, setPrepared, setReview, setCommentaire, setSelectItems])

    const handleAdd = (e) => {
        e.preventDefault()
        const newElement = {
            intitule: '',
            dateLimiteProd: '',
            dateProd: '',
        };

        setSelectItems([...selectItems, newElement]);
    };

    const handleDeleteElement = (index) => {
        const updatedList = selectItems.filter((_, i) => i !== index);
        setSelectItems(updatedList);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        const data = {
            commentaireRapport: commentaire,
            faitParRapport: prepared,
            revuParRapport: review,
            strategie_id: strategies.id,
            codeSommaire,
            isProvisoire: parseInt(isProvisoire) === 1,
            datas: selectItems,
            exercice_id: mission.id,
            client_id: mission.client_id,
            heureEntree,
            date,
            tache_id,
            intervention_id,
        };

        const { status, message } = await ajouterRapportEmettre(data);
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    };

    const columns = [
        {
            title: () => <TableTitle>Intitulé rapport</TableTitle>,
            dataIndex: 'intitule',
            key: 'index',
            render: (_, record, index) => (
                <select
                    style={{fontSize: '14px'}}
                    name='action'
                    className="form-select"
                    onChange={(e) => {
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  intitule: e.target.value} : item
                        );

                        setSelectItems(updatedList);
                    }}
                    value={record.intitule}
                >
                    <option>Sélectionner une action...</option>
                    {raportsData.map((opt, index) => (
                        <option key={index} value={opt}>
                            {opt}
                        </option>
                    ))}
                </select>
            ),
        },
        {
            title: () => <TableTitle>Date limite de production</TableTitle>,
            dataIndex: 'dateLimiteProd',
            key: 'dateLimiteProd',
            width: 200,
            render: (_, record, index) => (
                <input
                    type='date'
                    className='form-control mb-2'
                    placeholder='Date'
                    onChange={(e) => {
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  dateLimiteProd: (e.target.value)} : item
                        );

                        setSelectItems(updatedList);
                    }}
                    value={record.dateLimiteProd}
                />
            ),
        },
        {
            title: () => <TableTitle>Date de production</TableTitle>,
            dataIndex: 'dateProd',
            key: 'dateProd',
            width: 200,
            render: (_, record, index) => (
                <input
                    type='date'
                    className='form-control mb-2'
                    placeholder='Date'
                    onChange={(e) => {
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  dateProd: (e.target.value)} : item
                        );

                        setSelectItems(updatedList);
                    }}
                    value={record.dateProd}
                />
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 70,
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteElement(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    return (
        <div className="card d-flex flex-column fv-plugins-bootstrap5 fv-plugins-framework p-5">
            {<div className="d-flex justify-content-end align-items-end mb-10">
                <Tooltip title="Enregistrer">
                    <Button
                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                        size={"large"}
                        // loading={loading}
                        style={{
                            backgroundColor: appTheme.colors.secondary,
                            color: "white"
                        }}
                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                        onClick={handleSubmit}
                    >
                        {!loading &&
                            <span className='indicator-label text-white'>Enregistrer</span>}
                        {loading && (
                            <span className='indicator-progress fs-9 text-white'
                                  style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                        )}
                    </Button>
                </Tooltip>
            </div>}
            <div className="card-header">
                <div className="card-title">
                    <Title style={{
                        display: "block",
                        fontWeight: '600',
                        color: appTheme.colors.primary,
                        // textAlign: "center"
                    }} level={4}>{"EC4.9: Rapports à émettre"}</Title>
                </div>
            </div>
            <div className="">
                <div className="d-flex flex-column me-5">
                    <div className="d-flex text-gray-700 fw-bold fs-7 mt-2">
                        <button
                            onClick={handleAdd}
                            className="btn btn-sm fs-5 btn-primary"
                        >
                            Ajouter un champ
                        </button>
                    </div>
                </div>
                <div className='table-responsive my-5'>
                    <Table
                        columns={columns}
                        rowKey={(record) => record.id}
                        dataSource={selectItems}
                        loading={loading}
                        scroll={{
                            y: 800,
                        }}
                        size={"small"}
                        rowSelection
                        pagination={false}
                    />
                </div>
                {/*<div className='mt-7'>*/}
                {/*    <textarea*/}
                {/*        className='form-control mb-2'*/}
                {/*        rows="50"*/}
                {/*        cols="150"*/}
                {/*        maxLength={1000}*/}
                {/*        style={{*/}
                {/*            height: '150px',*/}
                {/*            marginBottom: 24,*/}
                {/*        }}*/}
                {/*        placeholder="Votre texte ici..."*/}
                {/*        onChange={(e) => {*/}
                {/*            setCommentaire(e.target.value)*/}
                {/*        }}*/}
                {/*        value={commentaire}*/}
                {/*    >*/}
                {/*    </textarea>*/}
                {/*    <div className="text-muted fs-7">.</div>*/}
                {/*</div>*/}
                <div className="w-100">
                    <div className="card-title mb-2">
                        <Title style={{
                            fontSize: "24px",
                            display: "block",
                            fontWeight: '600',
                            color: appTheme.colors.primary
                        }} level={5}># Action sur le dossier::</Title>
                    </div>

                    <div className="d-flex">
                        <div className="col-lg-1">
                            <FontAwesomeIcon icon={faUserCircle} size={"3x"} color={appTheme.colors.primary_blue}/>
                        </div>
                        <div className="col-lg-6">
                            <Space.Compact block>
                                <Input
                                    size="large"
                                    style={{
                                        width: '30%', fontWeight: "400"
                                    }}
                                    value="Préparer par: "
                                    readOnly={true}
                                />
                                {(mission.isCloture === 1 || mission.isClotureProvisoire === 1) ?
                                    (
                                        <Input
                                            size="large"
                                            style={{
                                                width: '70%', fontWeight: "700", textTransform: "uppercase"
                                            }}
                                            value={`${(users?.find((user) => user.id === parseInt(prepared))?.initial || "")} - ${(users?.find((user) => user.id === prepared)?.nom_user || "")} ${(users?.find((user) => user.id === prepared)?.prenom_user || "")}`}
                                            readOnly={true}
                                        />
                                    )
                                    :
                                    (
                                        <Select
                                            showSearch
                                            placeholder="Sélectionnez l'utilisateur..."
                                            optionFilterProp="label"
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            value={parseInt(prepared) || null}
                                            readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                                            options={users?.map((user) => ({
                                                value: user.id,
                                                label: (<span
                                                    className="fw-boldest fs-5 text-uppercase">{`${user.initial || ""} - ${user.nom_user || ""} ${user.prenom_user || ""}`}</span>),
                                            }))}
                                            onChange={(value) =>
                                                setPrepared(value)
                                            }
                                            size="large"
                                            style={{width: "70%"}}
                                        />
                                    )
                                }
                            </Space.Compact>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className="d-flex">
                        <div className="col-lg-1">
                            <FontAwesomeIcon icon={faUserCircle} size={"3x"} color={appTheme.colors.primary_blue}/>
                        </div>
                        <div className="col-lg-6">
                            <Space.Compact block>
                                <Input
                                    size="large"
                                    style={{
                                        width: '30%', fontWeight: "400"
                                    }}
                                    value="Revu par"
                                    readOnly={true}
                                />
                                {(mission.isCloture === 1 || mission.isClotureProvisoire === 1) ?
                                    (
                                        <Input
                                            size="large"
                                            style={{
                                                width: '70%', fontWeight: "700", textTransform: "uppercase"
                                            }}
                                            value={`${(users?.find((user) => user.id === parseInt(review))?.initial || "")} - ${(users?.find((user) => user.id === review)?.nom_user || "")} ${(users?.find((user) => user.id === review)?.prenom_user || "")}`}
                                            readOnly={true}
                                        />
                                    )
                                    :
                                    (
                                        <Select
                                            showSearch
                                            placeholder="Sélectionnez le second utilisateur..."
                                            optionFilterProp="label"
                                            filterOption={(input, option) =>
                                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            value={parseInt(review) || null}
                                            readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                                            options={users?.map((user) => ({
                                                value: user.id,
                                                label: (<span
                                                    className="fw-boldest fs-5 text-uppercase">{`${user.initial || ""} - ${user.nom_user || ""} ${user.prenom_user || ""}`}</span>),
                                            }))}
                                            onChange={(value) =>
                                                setReview(value)
                                            }
                                            size="large"
                                            style={{width: "70%"}}
                                        />
                                    )
                                }
                            </Space.Compact>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export {Rapport}