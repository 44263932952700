import { actions } from "./actions";

const initialState = {
    user: localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')) : {},
    accessToken: localStorage.getItem('accessToken') !== null ?  JSON.parse(localStorage.getItem('accessToken')) : '',
}

export default function onlineStoreApp(state = initialState, action) {
    switch (action.type) {
        case actions.STORE_USER_DATA:
            localStorage.setItem('user', JSON.stringify(action.payload.userData))
            localStorage.setItem('accessToken', JSON.stringify(action.payload.accessToken))
            return Object.assign({}, state, { user: action.payload.userData, accessToken: action.payload.accessToken });
    
        default: return state;
    }
}