import axios from "axios";

const AJOUTER_RISQUE_CONCEPTION = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/ajouter-risque-conception-controle`
const LISTE_RISQUE_CONCEPTION = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-conception-controle`

export const ajouterRisqueConception = async (data) => {
    return axios.post(AJOUTER_RISQUE_CONCEPTION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueConception = async (exercice_id) => {
    return axios.get(`${LISTE_RISQUE_CONCEPTION}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

