import React, {useEffect, useState} from "react";
import { Identification } from "../../Identification";
import { IntangibiliteForm } from "./EC3/intangibilite/IntangibiliteForm";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import {
    ajouterControleIntangibilite, ajouterTravauxIntangibilte,
    listeControleIntangibilite, listeTravauxIntangibilite
} from "../../../../../data/balance/procedureAnalytique";
import swal from "sweetalert";
import {HistoriqueControleIntangibilite} from "./EC3/intangibilite/HistoriqueControleIntangibilite";
import TraitementIntangibilite from "./EC3/intangibilite/TraitementIntangibilite";
import {faHistory, faIdCard, faListDots, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import PrintIntangibilite from "./EC3/intangibilite/PrintIntangibilite";
import PrintTravauxIntangibilite from "./EC3/intangibilite/PrintTravauxIntangibilite";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const ControleIntangibilite = ({
       mission,
       client,
       code,
       // modalId,
       prev
   }) => {
    // const user = useSelector(state => state.user)
    const [balanceData, setBalanceData] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [codeSommaire] = useState(code || 'EC3.3');
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [conclusion, setConclusion] = useState("");
    const [traitementData, setTraitementData] = useState([]);
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(missionSelected.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [activeTab, missionSelected, codeSommaire])

    useEffect(() => {
        if(missionSelected){
            fetchControleIntangibilite(missionSelected.id);
            fetchTravauxIntangibilite(missionSelected.id);
        }
    }, [missionSelected])

    //get balance provisoire
    const fetchControleIntangibilite = async (id) => {
        setLoading(true)
        listeControleIntangibilite(id)
            .then((res) => {
                if (res.result) {
                    setBalanceData(res.result)
                    setLoading(false)
                } else {
                    setBalanceData([])
                    setLoading(false)
                }
            })
    }

    const fetchTravauxIntangibilite = async (id) => {
        listeTravauxIntangibilite(id)
            .then((res) => {
                if (res.result) {
                    const data = (res.result ?? []).map(item => ({
                        libelle: item.libelle,
                        assertions: item.assertions,
                        reponse: item.reponse,
                        commentaire: item.commentaire,
                    }))
                    setTraitementData(data || res.result)
                } else {
                    setTraitementData([])
                }
            })
    }

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true);

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            balanceData: balanceData,
            exercice_id: missionSelected.id,
            client_id: client.id,
        };

        const dataTravaux = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            travauxData: traitementData,
            conclusion,
            exercice_id: missionSelected.id,
            client_id: client.id,
        };

        const {status, message} = activeTab === 3 ? await ajouterTravauxIntangibilte(dataTravaux) : await ajouterControleIntangibilite(data)
        if (status === 'success') {
            setLoading(false)
            setAuth(false);
            await swal(`Opération effectuée avec succès`, '', 'success')
            setIsTaskSave(prevState => (prevState ?? []).filter((item) => item !== activeTab));
        } else {
            setLoading(false);
            await swal(message, '', 'error')
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "CI",
            title: `CONTRÔLE D'INTANGIBILITÉ DES BALANCE - ${selectYear}`,
            description: `CONTRÔLE D'INTANGIBILITÉ DES BALANCE (N et N-1) - ${selectYear}`,
            icon: faTasks,
            content: (
                <IntangibiliteForm
                    mission={missionSelected}
                    client={client}
                    setBalanceData={setBalanceData}
                    balanceData={balanceData}
                    chargement={loading}
                    isProvisoire={isProvisoire}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
        {
            code: "H-CI",
            title: `HISTORIQUE CONTRÔLE D'INTANGIBILITÉ - ${selectYear}`,
            description: 'HISTORIQUE CONTRÔLE D\'INTANGIBILITÉ DES BALANCE (N et N-1)',
            icon: faHistory,
            content: (
                <HistoriqueControleIntangibilite
                    balanceData={balanceData}
                    setBalanceData={setBalanceData}
                    mission={missionSelected}
                    chargement={loading}
                    isProvisoire={isProvisoire}
                    handleSubmit={handleSubmit}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
        {
            code: "PT",
            title: `PT - ${selectYear}`,
            description: 'TRAVAUX A FAIRE',
            icon: faListDots,
            content: (
                <TraitementIntangibilite
                    setConclusion={setConclusion}
                    conclusion={conclusion}
                    setTraitementData={setTraitementData}
                    traitementData={traitementData}
                    mission={missionSelected}
                    chargement={loading}
                    isProvisoire={isProvisoire}
                    handleSubmit={handleSubmit}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
    ];

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EC3.3 - Contrôle intangibilité des balances (N et N-1)"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isTaskSave={isTaskSave}
                    isExcelPrint={true}
                    isLandingScape={true}
                    printTitle={(`${(activeTab === 3) ? "TRAVAUX INTANGIBILITE" : "CONTROLE INTANGIBILITE DES BALANCES (N ET N-1)"} ${selectYear}`)}
                    excelData={(activeTab === 3) ? traitementData  : balanceData}
                    printContent={(
                        (activeTab === 3) ?
                            <PrintTravauxIntangibilite
                                datas={traitementData}
                                conclusion={conclusion}
                            />
                            :
                            <PrintIntangibilite
                                datas={balanceData}
                            />

                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>{formSteps[activeTab].content}</>
                </StepBody>
            </div>
        </>
    );
};
export {ControleIntangibilite}