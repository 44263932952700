import { Space } from "antd";
import React from "react";
import styled from "styled-components";

const PageLabelContainer = styled.div`
  width: 100%;
  font-family: "Oswald", sans-serif;
  font-weight: bolder;
  text-transform: uppercase;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.primary_blue};
  margin-bottom: 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const PageChildLabel = ({ label, counter, children }) => {
    return (
        <Container>
            <PageLabelContainer>
                {counter && counter}
                {label}
            </PageLabelContainer>
            {children && <Space>{children}</Space>}
        </Container>
    );
};

export default PageChildLabel;
