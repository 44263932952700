import {CloseSquareOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../helpers/AssetsHelpers";

const BalanceEdit = ({balances, setBalanceData, onClose, numero, plans, balance,}) => {
    const [plansSelect, setPlansSelect] = useState([])
    const [balanceSelect, setBalanceSelect] = useState(balance)

    useEffect(() => {
        const num = balanceSelect.numeroCompte.toString().slice(0, 2);
        const plansExist = plans.filter((plan) => plan.numero.startsWith(num));
        if (plansExist.length > 0) {
            setPlansSelect(plansExist);
        } else {
            setPlansSelect([]);
        }
    }, [balanceSelect, plans]);

    const handleClick = (id) => {
        const plan = plans.find((key) => key.id === id);
        const updated = {
            ...balanceSelect,
            numeroCompte: parseInt(plan.numero),
            libelle: plan.intitule,
        }
        setBalanceSelect(updated);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedList = balances.map((item) =>
            item.numeroCompte === numero ?
                {
                    ...item,
                    numeroCompte: balanceSelect.numeroCompte,
                    libelle: balanceSelect.libelle,
                    anouveauDebit: balanceSelect.anouveauDebit,
                    anouveauCredit: balanceSelect.anouveauCredit,
                    mvtDebit: balanceSelect.mvtDebit,
                    mvtCredit: balanceSelect.mvtCredit,
                    soldeDebit: balanceSelect.soldeDebit,
                    soldeCredit: balanceSelect.soldeCredit,
                    isModified: true
                } : item
        );
        setBalanceData(updatedList);
        onClose();
        await swal(succesMessage, '', 'success')
    }

    return (
        <div className="modal-content">
            <div className="modal-header pb-0 border-0 justify-content-end">
                <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={onClose}>
                    <span className="svg-icon svg-icon-1">
                      <CloseSquareOutlined style={{color: 'red'}}/>
                    </span>
                </div>
            </div>
            <div className="modal-body scroll-y pt-0 pb-7">
                <div className="mw-lg-1000px mx-auto">
                    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                        <div className='col-xl-6'>
                            <div className="card card-flush">
                                <div className="card-header pt-5">
                                    <h5 className="card-title text-gray-800">Comptes proposés pour correction</h5>
                                </div>
                                <div className="card-body pt-5 mh-200px scroll-y me-n7 pe-7">
                                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer w-100 p-5 table-bordered table-row-bordered'>
                                        <thead className='bg-primary px-2'>
                                        <tr className="text-start fw-bolder fs-7 text-uppercase gs- h-1px">
                                            <th className="sorting_disabled pt-0 pd-0 border-top-width-1 border-botton-width-1 border-left-2 h-1" rowSpan="1" colSpan="1" >
                                                <div className="dataTables_sizing h-0 overflow-hidden" style={{fontSize: '12px'}}>
                                                    N° Compte
                                                </div>
                                            </th>
                                            <th className="sorting_disabled pt-0 pd-0 border-top-width-1 border-botton-width-1 h-1" rowSpan="1" colSpan="1">
                                                <div className="dataTables_sizing h-0 overflow-hidden" style={{fontSize: '11px'}}>
                                                    Libéllé
                                                </div>
                                            </th>
                                            <th className="sorting_disabled pt-0 pd-0 border-top-width-1 border-botton-width-1 h-1" rowSpan="1" colSpan="1">
                                                <div className="dataTables_sizing h-0 overflow-hidden" style={{fontSize: '11px'}}>
                                                    Sens de compte
                                                </div>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody className='fw-bold text-gray-600'>
                                            {plansSelect &&  plansSelect.map((item, index) => (
                                                <tr className="even bg-hover-light-primary" key={index} onClick={() => handleClick(item.id)}>
                                                    <td className="">
                                                        {item.numero}
                                                    </td>
                                                    <td className='min-w-300px'>
                                                        {item.intitule}
                                                    </td>
                                                    <td>
                                                        {item.sensCompte}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div className="card card-flush">
                                <div className="card-header pt-5">
                                    <h3 className="card-title text-gray-800">Modification de compte</h3>
                                </div>
                                <div className="card-body pt-5 mh-400px">
                                    <div className="row fv-row mb-2 ">
                                        <div className='col-lg-6'>
                                            <div className="col-md-3 text-md-end">
                                                {/*begin::Label*/}
                                                <label className="fw-bold form-label mt-3" style={{fontSize: '12px'}}>
                                                    <span>Compte</span>
                                                </label>
                                                {/*end::Label*/}
                                            </div>
                                            <div className="col-md-9">
                                                <input
                                                    style={{fontSize: '11px'}}
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='numero'
                                                    value={balanceSelect?.numeroCompte.toString()}
                                                    onChange={(e) => {
                                                        const updated = { ...balanceSelect,  numeroCompte: parseInt(e.target.value)}
                                                        setBalanceSelect(updated);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='col-lg-6'>
                                            <div className="text-md-end">
                                                {/*begin::Label*/}
                                                <label className="fw-bold form-label mt-3" style={{fontSize: '12px'}}>
                                                    <span>Sens de compte</span>
                                                </label>
                                                {/*end::Label*/}
                                            </div>
                                            <div className="">
                                                <input
                                                    style={{fontSize: '11px'}}
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='sensCompte'
                                                    value={''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row fv-row mb-2 ">
                                        <div className="text-md-start">
                                            {/*begin::Label*/}
                                            <label className="fw-bold form-label mt-3" style={{fontSize: '12px'}}>
                                                <span>Libellé</span>
                                            </label>
                                            {/*end::Label*/}
                                        </div>
                                        <div className="">
                                            {/*begin::Input*/}
                                            <input
                                                style={{fontSize: '11px'}}
                                                type="text"
                                                className="form-control"
                                                name="Libellé"
                                                value={balanceSelect?.libelle}
                                                onChange={(e) => {
                                                    const updated = { ...balanceSelect,  libelle: (e.target.value)}
                                                    setBalanceSelect(updated);
                                                }}
                                            />
                                            {/*end::Input*/}
                                        </div>
                                    </div>
                                    <div className="row fv-row mb-2 ">
                                        <div className='col-lg-6'>
                                            <div className=" text-md-start">
                                                {/*begin::Label*/}
                                                <label className="fw-bold form-label mt-3" style={{fontSize: '12px'}}>
                                                    <span>A-nouveaux Débit</span>
                                                </label>
                                                {/*end::Label*/}
                                            </div>
                                            <div className="">
                                                <input
                                                    style={{fontSize: '11px'}}
                                                    type='input'
                                                    className='form-control'
                                                    placeholder='numero'
                                                    value={balanceSelect?.anouveauDebit || 0}
                                                    onChange={(e) => {
                                                        const updated = { ...balanceSelect,  anouveauDebit: parseInt(e.target.value)}
                                                        setBalanceSelect(updated);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='col-lg-6'>
                                            <div className="text-md-end">
                                                {/*begin::Label*/}
                                                <label className="fw-bold form-label mt-3" style={{fontSize: '12px'}}>
                                                    <span>A-nouveaux Crédit</span>
                                                </label>
                                                {/*end::Label*/}
                                            </div>
                                            <div className="">
                                                <input
                                                    style={{fontSize: '11px'}}
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='sensCompte'
                                                    value={balanceSelect?.anouveauCredit || 0}
                                                    onChange={(e) => {
                                                        const updated = { ...balanceSelect,  anouveauCredit: parseInt(e.target.value)}
                                                        setBalanceSelect(updated);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row fv-row mb-2 ">
                                        <div className='col-lg-6'>
                                            <div className=" text-md-start">
                                                {/*begin::Label*/}
                                                <label className="fw-bold form-label mt-3" style={{fontSize: '12px'}}>
                                                    <span>Mouvement Débit</span>
                                                </label>
                                                {/*end::Label*/}
                                            </div>
                                            <div className="">
                                                <input
                                                    style={{fontSize: '11px'}}
                                                    type='input'
                                                    className='form-control'
                                                    placeholder='numero'
                                                    value={balanceSelect?.mvtDebit || 0}
                                                    onChange={(e) => {
                                                        const updated = { ...balanceSelect,  mvtDebit: parseInt(e.target.value)}
                                                        setBalanceSelect(updated);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='col-lg-6'>
                                            <div className="text-md-end">
                                                {/*begin::Label*/}
                                                <label className="fw-bold form-label mt-3" style={{fontSize: '12px'}}>
                                                    <span>Mouvement Crédit</span>
                                                </label>
                                                {/*end::Label*/}
                                            </div>
                                            <div className="">
                                                <input
                                                    style={{fontSize: '11px'}}
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='sensCompte'
                                                    value={balanceSelect?.mvtCredit || 0}
                                                    onChange={(e) => {
                                                        const updated = { ...balanceSelect,  mvtCredit: parseInt(e.target.value)}
                                                        setBalanceSelect(updated);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row fv-row mb-2 ">
                                        <div className='col-lg-6'>
                                            <div className=" text-md-start">
                                                {/*begin::Label*/}
                                                <label className="fw-bold form-label mt-3" style={{fontSize: '12px'}}>
                                                    <span>Solde Débit</span>
                                                </label>
                                                {/*end::Label*/}
                                            </div>
                                            <div className="">
                                                <input
                                                    style={{fontSize: '11px'}}
                                                    type='input'
                                                    className='form-control'
                                                    placeholder='numero'
                                                    value={balanceSelect?.soldeDebit || 0}
                                                    onChange={(e) => {
                                                        const updated = { ...balanceSelect,  soldeDebit: parseInt(e.target.value)}
                                                        setBalanceSelect(updated);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='col-lg-6'>
                                            <div className="text-md-end">
                                                {/*begin::Label*/}
                                                <label className="fw-bold form-label mt-3" style={{fontSize: '12px'}}>
                                                    <span>Solde Crédit</span>
                                                </label>
                                                {/*end::Label*/}
                                            </div>
                                            <div className="">
                                                <input
                                                    style={{fontSize: '11px'}}
                                                    type='text'
                                                    className='form-control'
                                                    placeholder='sensCompte'
                                                    value={balanceSelect?.soldeCredit || 0}
                                                    onChange={(e) => {
                                                        const updated = { ...balanceSelect,  soldeCredit: parseInt(e.target.value)}
                                                        setBalanceSelect(updated);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mt-10">
                        <div className="flex-grow-1">
                            <div className="text-center">
                                <button type="reset" id="kt_modal_new_target_cancel"
                                        className="btn btn-light me-3" onClick={onClose}>Annuler
                                </button>
                                <button type="submit"  className="btn btn-primary" onClick={handleSubmit}>
                                    Enregistrer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export {BalanceEdit}