import React, {useEffect, useState} from "react";
import {Button, Col, DatePicker, Divider, Input, Popover, Select, Space, Table, Tooltip} from "antd";
import {AuditOutlined, ClockCircleOutlined, UserOutlined} from "@ant-design/icons";
import moment from "moment/moment";
import {getListeLettreMission} from "../../../../../../data/file";
import FileUploadLettreMissionSigne from "../../../../outils/upload/FileUploadLettreMissionSigne";
import {cleanAndNormalizeName} from "../../../../../utilities/Utilities";
import {DOWNLOAD_FILE_CLIENT} from "../../../../../../api/apiRoutes";
import useUsers from "../../../../../../hooks/users/useUser";
import TableTitle from "../../../../../common/TableTitle";
import {FormContainer, TagItem} from "../../../../../common/common.components";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUpload, faCopy, faDownload, faExclamationCircle, faFileEdit} from "@fortawesome/free-solid-svg-icons";
import FormLabel from "../../../../../common/Form/FormLabel";
import SearchRow from "../../../../../common/SearchRow";
import axios from "axios";
import {setAuthorizationHeader, setFileAuthorizationHeader} from "../../../../../../api/config";
import {getFile} from "../../../../../../data/client";

const TraitementLettre = ({
      mission,
      codeSommaire,
      date,
      heureEntree,
      user_id,
      tache_id,
      intervention_id
    }) => {
    const users = useUsers();
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lettres, setLettres] = useState([]);
    const [filtersItems, setFiltersItems] = useState([]);
    const [paramsRecherche, setParamsRecherche] = useState({
        infoFile: "",
        user_id: "",
        date_debut: "",
        date_fin: "",
    });

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 5,
        },
    });

    useEffect(() => {
        if(mission){
            fetchDocumentsClient(mission.id);
        }
    }, [mission]);

    const fetchDocumentsClient = (id) => {
        setLoading(true);
        getListeLettreMission(id)
            .then((res) => {
                setLettres(res.result.sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt);
                }));
                setFiltersItems(res.result.sort((p1, p2) => {
                    return new Date(p2.createdAt) - new Date(p1.createdAt);
                }));
                setLoading(false);
            });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setLettres([]);
        }
    };

    const handleModalOpen = () => {
        setIsModalOpen(prevState => !prevState);
    };

    const downloadDocumentsClient = (client_bog, client_lea, fichier) => {
        const clientBogName = cleanAndNormalizeName(client_bog);
        const clientName = cleanAndNormalizeName(client_lea);
        const missionName = cleanAndNormalizeName(mission.mission)
        // ${clientBogName}/${clientName}/${exercice.annee}/${missionName}`
        window.open(`${DOWNLOAD_FILE_CLIENT}/${clientBogName}/${clientName}/${mission.annee}/${missionName}/${fichier}`, '_blank')
    }

    // const downloadDocumentsClient = async (client_bog, client_lea, fichier, libelle, extension) => {
    //     const clientBogName = cleanAndNormalizeName(client_bog);
    //     const clientName = cleanAndNormalizeName(client_lea);
    //     const missionName = cleanAndNormalizeName(mission.mission)
    //
    //     // setFileAuthorizationHeader()
    //     axios.get(`${DOWNLOAD_FILE_CLIENT}/${clientBogName}/${clientName}/${mission.annee}/${missionName}/${fichier}`, {
    //     // axios.get("https://cdn.pixabay.com/photo/2024/06/24/04/05/woman-8849047_1280.jpg", {
    //         responseType: 'blob',
    //     })
    //     .then((obj) => {
    //         if (obj.status === 200) {
    //             const url = URL.createObjectURL(obj.data);
    //             const a = document.createElement('a');
    //             a.href = url;
    //             a.download = `${libelle || ""}.${extension || ""}`;
    //             a.style.display = 'none';
    //             document.body.appendChild(a);
    //             a.click();
    //             a.remove()
    //             URL.revokeObjectURL(url);
    //         } else {
    //             return {
    //                 status: 'error',
    //                 message: 'La connexion à internet est instable !',
    //             }
    //         }
    //     })
    //     .catch(err => console.error(err))
    // }

    // const downloadDocumentsClient = (client_bog, client_lea, fichier, libelle, extension) => {
    //     setLoading(true);
    //     const clientBogName = cleanAndNormalizeName(client_bog);
    //     const clientName = cleanAndNormalizeName(client_lea);
    //     const missionName = cleanAndNormalizeName(mission.mission)
    //
    //     getFile(clientBogName, clientName, missionName, mission.annee, fichier)
    //         .then((res) => {
    //             const url = URL.createObjectURL(res);
    //             const a = document.createElement('a');
    //             a.href = url;
    //             a.download = `${libelle || ""}.${extension || ""}`;
    //             a.style.display = 'none';
    //             document.body.appendChild(a);
    //             a.click();
    //             a.remove()
    //             URL.revokeObjectURL(url);
    //
    //             setLoading(false);
    //         });
    // };

    const formatFileName = (client_bog, client_lea, fichier) => {
        const clientBogName = cleanAndNormalizeName(client_bog);
        const clientName = cleanAndNormalizeName(client_lea);
        const missionName = cleanAndNormalizeName(mission.mission)
        return `${DOWNLOAD_FILE_CLIENT}/${clientBogName}/${clientName}/${mission.annee}/${missionName}/${fichier}`
    }

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Reference</TableTitle>,
            dataIndex: 'reference',
            render: (_, record) => (
                <TagItem>{record.reference}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Document</TableTitle>,
            dataIndex: 'libelle',
            render: (_, record) => (
                <div className='d-flex align-items-center'>
                    <span className='svg-icon svg-icon-2x svg-icon-primary me-4'>
                      <AuditOutlined style={{color: appTheme.colors.primary_blue}}/>
                    </span>
                    <div style={{
                        fontFamily: "Oswald, sans-serif",
                        fontSize: "12px", fontWeight: '700'
                    }}>
                        {record.libelle}
                    </div>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Taille (KO)</TableTitle>,
            dataIndex: 'size',
            render: (_, record) => (
                <TagItem>{(record.size / 1024).toFixed(2)}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Dernière modif.</TableTitle>,
            dataIndex: 'modif',
            render: (_, record) => (
                <div className='d-flex flex-column align-center'>
                    <span className='chart-orange display-block f-size-12 pe-5' style={{fontWeight: 600, fontSize: '11px'}}>
                        <UserOutlined style={{fontSize: '15px'}} /> {record.Lea_User?.nom_user + ' ' + record.Lea_User?.prenom_user}
                    </span>
                    <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                        <ClockCircleOutlined style={{fontSize: '12px'}} />{' '}
                        {moment(record.updatedAt).format('DD/MM/YYYY à HH:mm')}
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Dossier client</TableTitle>,
            dataIndex: 'dossiers',
            render: () => (
                <div className='d-flex flex-column'>
                    <span style={{ color: "#000", fontWeight: "bold", fontSize: "12px" }}>
                        {mission.denomination}
                    </span>
                    <span className='text-gray-800 fw-bold text-hover-primary mb-1' style={{fontSize: '12px'}}>
                        {mission.mission} <span className="ms-3">{mission.annee && <TagItem color={appTheme.colors.primary}>{mission.annee}</TagItem>}</span>
                    </span>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Notes</TableTitle>,
            dataIndex: 'description',
            render: (_, record) => (
                <>
                    {record.description && <Popover
                        placement="top"
                        title="Notes"
                        content={
                            <>
                                <Divider/>
                                <span
                                    style={{
                                        fontFamily: "Oswald, sans-serif",
                                        fontSize: "13px",
                                    }}
                                >
                        {record.description}
                      </span>
                            </>
                        }
                    >
                        <Button
                            icon={<FontAwesomeIcon icon={faExclamationCircle}/>}
                            color={""}
                        />
                    </Popover>}
                </>
            ),
        },
        {
            title: (<span style={{fontSize: '11px'}}>Action</span>),
            dataIndex: 'action',
            width: 100,
            render: (_, record) => (
                <Space>
                    <Tooltip color={appTheme.colors.primary} title="Téléchager le fichier">
                        {/*<Button*/}
                        {/*    shape="circle"*/}
                        {/*    size={"middle"}*/}
                        {/*    style={{backgroundColor: appTheme.colors.primary}}*/}
                        {/*    onClick={() => downloadDocumentsClient(record.Bog_Client?.nom_cabinet, record.Lea_Client?.denomination, record.fichier, record.libelle, record.extension)}*/}
                        {/*    icon={<FontAwesomeIcon icon={faDownload} color={"white"} />}*/}
                        {/*/>*/}
                        <a
                            href={formatFileName(record.Bog_Client?.nom_cabinet, record.Lea_Client?.denomination, record.fichier,)}
                            download={`${record.libelle}.${record.extension}`}
                        >
                            <FontAwesomeIcon icon={faDownload} color={"black"} />
                        </a>
                    </Tooltip>
                    {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) &&
                        <>
                            {/*<Tooltip  title="Faire une copie dans le dossier permanent">*/}
                            {/*    <Button*/}
                            {/*        shape="circle"*/}
                            {/*        size={"middle"}*/}
                            {/*        style={{}}*/}
                            {/*        onClick={() => {}}*/}
                            {/*        icon={<FontAwesomeIcon icon={faCopy} color={""} />}*/}
                            {/*    />*/}
                            {/*</Tooltip>*/}
                            <Tooltip color='blue' title="Modifier les infos du fichier">
                                <Button
                                    shape="circle"
                                    size={"middle"}
                                    style={{backgroundColor: "blue"}}
                                    onClick={() => {}}
                                    icon={<FontAwesomeIcon icon={faFileEdit} color={"white"} />}
                                />
                            </Tooltip>
                        </>
                    }
                </Space>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = lettres.filter(file => {
                const referenceMatch = file.reference?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const libelleMatch = file.libelle?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const descriptionMatch = file.description?.toLowerCase().includes(paramsRecherche?.infoFile.toLowerCase());
                const userMatch = paramsRecherche.user_id === "" || file.Lea_User?.id === paramsRecherche.user_id;
                const createdAtMatch = (paramsRecherche.date_debut === "" && paramsRecherche.date_fin === "") || moment(file.createdAt).isBetween(
                    moment(paramsRecherche.date_debut),
                    moment(paramsRecherche.date_fin)
                );

                return (referenceMatch || descriptionMatch || libelleMatch) && userMatch && createdAtMatch;
            });

            setFiltersItems(filteredClients);
            setLoading(false);
        }, 2000);
    };


    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            infoFile: "",
            user_id: "",
            date_debut: "",
            date_fin: "",
        });
        setFiltersItems(lettres)
        setLoading(false);
    };

    return(
        <>
            <SearchRow
                handleSearch={handleSearch}
                handleReset={handleReset}
                loading={loading}
            >
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Fichier" />
                    <Input
                        style={{ width: "100%" }}
                        size="middle"
                        placeholder="Reference ou libellé ou description"
                        // value={paramsRecherche.infoClient}
                        onChange={(e) =>
                            setParamsRecherche({
                                ...paramsRecherche,
                                infosClient: e.target.value,
                            })
                        }
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Utilisateurs" />
                    <Select
                        placeholder="Utilisateur"
                        size="middle"
                        style={{ width: "100%" }}
                        onSelect={(value) =>
                            setParamsRecherche({ ...paramsRecherche, user_id: value })
                        }
                        options={users && users.users.map((user) => ({
                            value: user.id,
                            label: `${user.initial || ""} - ${user.nom_user.toUpperCase()} ${user.prenom_user ? user.prenom_user.toUpperCase() : ""}`
                        }))}
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Date debut" />
                    <DatePicker
                        size="middle"
                        placeholder="Date début de la création"
                        style={{ width: "100%" }}
                        allowClear
                        onChange={(date, dateString) =>
                            date !== null
                                ? setParamsRecherche({
                                    ...paramsRecherche,
                                    date_debut: date.format("YYYY-MM-DD"),
                                })
                                : setParamsRecherche({ ...paramsRecherche, date_debut: "" })
                        }
                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                    />
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                    xl={{ span: 6 }}
                >
                    <FormLabel label="Date fin" />
                    <DatePicker
                        size="middle"
                        placeholder="Date fin  de la création"
                        style={{ width: "100%" }}
                        allowClear
                        onChange={(date, dateString) =>
                            date !== null
                                ? setParamsRecherche({
                                    ...paramsRecherche,
                                    date_fin: date.format("YYYY-MM-DD"),
                                })
                                : setParamsRecherche({ ...paramsRecherche, date_fin: "" })
                        }
                        format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                    />
                </Col>
            </SearchRow>
            <FormContainer>
                <div className="w-100">
                    {mission.isCloture !== 1 && <div className="d-flex justify-content-end align-center my-2 me-5">
                        <Tooltip color={appTheme.colors.primary} title="Importer un fichier pour l'exercice">
                            <Button
                                size={"middle"}
                                style={{backgroundColor: appTheme.colors.primary}}
                                onClick={handleModalOpen}
                                icon={<FontAwesomeIcon icon={faCloudUpload} color={"white"}/>}
                            >
                                Importer
                            </Button>
                        </Tooltip>
                    </div>}
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                rowKey={(record) => record.id}
                                dataSource={filtersItems}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                                rowSelection
                                size={"small"}
                            />
                        </div>
                    </div>
                </div>
            </FormContainer>
            {isModalOpen && (
                <FileUploadLettreMissionSigne
                    handleVisibility={handleModalOpen}
                    codeSommaire={codeSommaire}
                    exercice={mission}
                    intervention_id={intervention_id}
                    date={date}
                    heureEntree={heureEntree}
                    tache_id={tache_id}
                    user_id={user_id}
                    visible={isModalOpen}
                    chargement={loading}
                    fetchData={fetchDocumentsClient}
                />
            )}
        </>
    )
}
export {TraitementLettre}