import React, {useEffect, useState} from "react";
import {Button, Input, Tooltip, Typography} from "antd";
import {getAideMemoire} from "../../../../../../data/exercice";
import PageChildLabel from "../../../../../common/PageLabel/PageChildLabel";
import {Spinner} from "@chakra-ui/react";
import FormLabel from "../../../../../common/Form/FormLabel";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

const menus = [
    { key: "EE3.1", title: "Immobilisations incorporelles" },
    { key: "EE3.2", title: "Immobilisations corporelles" },
    { key: "EE3.3", title: "Immobilisations financières" },
    { key: "EE3.4", title: "Stocks" },
    { key: "EE3.5", title: "Ventes-clients" },
    { key: "EE3.6", title: "Autres créance et dettes" },
    { key: "EE3.7", title: "Trésorerie" },
    { key: "EE3.8", title: "Capitaux propres" },
    { key: "EE3.9", title: "Provisions risques" },
    { key: "EE3.10", title: "Emprunts et assimilés" },
    { key: "EE3.11", title: "Achats fournisseurs" },
    { key: "EE3.12", title: "Impôts et taxes" },
    { key: "EE3.13", title: "Paie personnel" },
    { key: "EE3.14", title: "Contrôle TAFIRE" },
    { key: "EE3.15", title: "Contrôle des notes annexes" },
    { key: "EE3.17", title: "Mission d'audit initiale - Soldes d'ouverture" },
];

const ProcedureAuditComplementaire = ({mission, chargement, hanldeValider, missionSelected}) => {
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [codeSommaire, setCodeSommaire] = useState("EE3.1");
    const [aideMemoire, setAideMemoire] = useState(null);
    const [conclusion, setConclusion] = useState("");

    useEffect(()=> {
        setLoading(true);
        async function fetchData() {
            const response = await getAideMemoire(mission.id, codeSommaire);
            if(response && response.length > 0) {
                setAideMemoire(response[0]);
                setConclusion(response[0]?.conclusion || "");
                setLoading(false);
            }else{
                setAideMemoire(null);
                setConclusion("");
                setLoading(false);
            }
        }
        fetchData();
    }, [mission, codeSommaire])

    const handleTabChange = (index, code) => {
        setLoading(true);
        setTimeout(() => {
            setCodeSommaire(code);
            setActiveTab(index);

            setLoading(false);
        }, 1000)
    };

    return (
        <>
            <div className="w-100 pb-15">
                <PageChildLabel label={"Procédures d'audit complémentaire"}/>
                <div className="flex-lg-row-fluid mx-lg-10">
                    <div className="d-flex justify-content-between align-center">
                        <div className="d-flex flex-column align-center">
                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-5">
                                {menus.map((item, index) => (
                                    <li className="nav-item" key={index}>
                                        <Tooltip title={item?.title}>
                                            <button
                                                className={`nav-link text-active-primary pb-4 ${activeTab === index ? 'active' : ''}`}
                                                onClick={() => handleTabChange(index, item.key)}
                                            >
                                                <span className="fs-4 fw-bolder">
                                                    {item.key}
                                                </span>
                                            </button>
                                        </Tooltip>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stepper-form mx-10 mb-15">
                {(loading || chargement) ?
                    <div className="d-flex justify-content-center align-center">
                        <Spinner/>
                    </div>
                    :
                    <div className="tab-content" id="myTabContent">
                        {<div className="d-flex justify-content-end align-items-end mb-10">
                            <Tooltip title="Enregistrer">
                                <Button
                                    disabled={(missionSelected.isCloture === 1 || missionSelected.isClotureProvisoire === 1)}
                                    size={"large"}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: appTheme.colors.secondary,
                                        color: "white"
                                    }}
                                    icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                    onClick={() => hanldeValider({code: codeSommaire, conclusion: conclusion})}
                                >
                                    {!loading &&
                                        <span className='indicator-label text-white'>Enregistrer</span>}
                                    {loading && (
                                        <span className='indicator-progress fs-9 text-white'
                                              style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </Button>
                            </Tooltip>
                        </div>}
                        {menus.map((item, index) => (
                            <div
                                key={index}
                                className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                                id={`tab-${index}`}
                                role="tabpanel"
                            >
                                <div className="card-header">
                                    <div className="card-title">
                                        <Title style={{
                                            display: "block",
                                            fontWeight: '600',
                                            color: appTheme.colors.primary,
                                            // textAlign: "center"
                                        }} level={3}>{`${item.key} - ${item.title}`}</Title>
                                    </div>
                                </div>
                                <div className="my-5"></div>
                                <FormLabel label={`Conclusion : `} />
                                <div className="my-5"></div>
                                <Input.TextArea
                                    size="large"
                                    placeholder="Conclusion"
                                    value={conclusion}
                                    maxLength={5000}
                                    showCount
                                    autoSize={{
                                        minRows: 8,
                                        maxRows: 10,
                                    }}
                                    onChange={(e) => setConclusion(e.target.value)}
                                />
                            </div>
                        ))}
                    </div>

                }
            </div>
        </>
    );
}

export {ProcedureAuditComplementaire}