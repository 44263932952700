import React from 'react';
import '../testejournaux.css';

const PrintProvisoireDefinitif = ({datas}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        minHeight: "20px"
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <div className="card">
            <div className="table-responsive mb-5">
                <div className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className="tablePerso w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={thStyle} className="thperso w-80px">Compte</th>
                                <th style={thStyle} className="thperso w-200px">Libellé</th>
                                <th style={thStyle} className="thperso w-80px">Débit</th>
                                <th style={thStyle} className="thperso w-80px">Crédit</th>
                                <th style={thStyle} className="thperso w-80px">Compte</th>
                                <th style={thStyle} className="thperso w-200px">Libellé</th>
                                <th style={thStyle} className="thperso w-80px">Débit</th>
                                <th style={thStyle} className="thperso w-80px">Crédit</th>
                                <th style={thStyle} className="thperso w-80px">DIFF_SD</th>
                                <th style={thStyle} className="thperso w-80px">DIFF_SC</th>
                                <th style={thStyle} className="thperso w-200px">Commentaire</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(datas && datas.length > 0) && datas.map((item, index) => (
                                <tr key={index} style={tdStyle} className="">
                                    <td className="" style={{...tdStyle, fontSize: '9px' }}>
                                        {item.numeroCompteProv}
                                    </td>
                                    <td className=" text-start" style={{...tdStyle, fontSize: '9px' }}>
                                        {item.libelleProv}
                                    </td>
                                    <td className=" text-center" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {(item.debitProv || 0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {(item.creditProv || 0).toLocaleString('fr') || 0}
                                        </div>
                                    </td>
                                    <td className="" style={{...tdStyle, fontSize: '9px' }}>
                                        {item.numeroCompteDef}
                                    </td>
                                    <td className=" text-start" style={{...tdStyle, fontSize: '9px' }}>
                                        {item.libelleDef}
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {(item.debitDef).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {item.creditDef.toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {(item?.debitProv - item.debitDef).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className='text-end pe-0' style={{fontSize: '9px'}}>
                                            {(item?.creditProv - item.creditDef).toLocaleString('fr')}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <div className="text-start" style={{fontSize: '9px'}}>
                                            {item.commentaire || ""}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrintProvisoireDefinitif;
