import React from 'react';

const PrintVerification = ({objectif, datas, conclusion, observation, title}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thStyle = {
        border: '1px solid #000000',
        fontWeight: "bold",
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <div className="">
            <h1 className="fw-bolder text-black mb-3 text-center">{title || ""}</h1>
            {objectif &&
                <table style={{...tableStyle, marginBottom: "5%"}} className=" ">                    <thead>
                <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                    <th
                        className="text-start"
                        style={{fontSize: "14px",
                            textDecoration: 'underline',
                            fontWeight: "bold"}}
                    >
                        Notes
                    </th>
                </tr>
                </thead>
                    <tbody>
                    <tr style={tdStyle}>
                        <td style={{...tdStyle,  textAlign: "start"}}>
                            <p style={{fontSize: "12px"}}>{objectif}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            }
            <div style={{marginBottom: "5%"}} className="table-responsive">
                <div className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className="tablePerso w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={thStyle} className="thperso w-150px">Secteur d'activité</th>
                                <th style={thStyle} className="thperso w-400px">Contrôle</th>
                                <th style={thStyle} className="thperso w-100px">Assertions</th>
                                <th style={thStyle} className="thperso w-150px">Réponse</th>
                                <th style={thStyle} className="thperso w-400px">Commentaire</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(datas && datas.length > 0) && datas.map((question, questionIndex) => (
                                question.questions.map((item, itemIndex) => {
                                    const questionLibelle = (itemIndex === 0) ? question.libelleGroupe : '';
                                    return (
                                        <tr style={tdStyle} className="" key={`${questionIndex}-${itemIndex}`}>
                                            {itemIndex === 0 && (
                                                <td className="" rowSpan={question.questions.length} style={{...tdStyle, fontSize: '10px'}}>
                                                    {questionLibelle}
                                                </td>
                                            )}
                                            <td className=" text-start" style={{...tdStyle, fontSize: '11px' }}>
                                                {
                                                    item.libelle.length > 1 ?
                                                        item.libelle.map((opt, indexOpt) => (
                                                            <div  key={indexOpt} className='d-flex flex-column'>
                                                                <p className='' style={{fontSize: '10px'}}>
                                                                    <strong className='me-2 fw-bolder' style={{fontSize: '13px'}}>.</strong>{opt}
                                                                </p>
                                                            </div>
                                                        ))
                                                        :
                                                        item.libelle.length >= 0 ?
                                                            item.libelle.map((opt, indexOpt) => (

                                                                <p className='' key={indexOpt} style={{fontSize: '10px'}}>
                                                                    {opt}
                                                                </p>
                                                            ))
                                                            :
                                                            ""
                                                }
                                            </td>
                                            <td className=" text-center" style={tdStyle}>
                                                <div className='d-flex align-center text-center'>
                                                    {item.assertions?.map((assertion) => (
                                                        <span className='fw-bolder me-5' key={assertion.id}>
                                                          {assertion}
                                                        </span>
                                                    ))}
                                                </div>
                                            </td>
                                            <td className="" style={tdStyle}>
                                                {item?.reponse === '1' ? 'S/O': item?.reponse === '2' ? 'C' : 'NC'}
                                            </td>
                                            <td className="" style={tdStyle}>
                                                <p style={{fontSize: '11px'}}>
                                                    {item.commentaire}
                                                </p>
                                            </td>
                                        </tr>
                                    );
                                })
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {conclusion &&
                <table style={{...tableStyle, marginBottom: "5%"}} className=" ">                    <thead>
                <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                    <th
                        className="text-start"
                        style={{fontSize: "14px",
                            textDecoration: 'underline',
                            fontWeight: "bold"}}
                    >
                        Conclusion
                    </th>
                </tr>
                </thead>
                    <tbody>
                    <tr style={tdStyle}>
                        <td style={{...tdStyle,  textAlign: "start"}}>
                            <p style={{fontSize: "12px"}}>{conclusion}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            }
            {observation &&
                <table style={tableStyle} className=" ">
                    <thead>
                    <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                        <th
                            className="text-start"
                            style={{fontSize: "14px",
                                textDecoration: 'underline',
                                fontWeight: "bold"}}
                        >
                            Conclusion du cycle
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={tdStyle}>
                        <td style={{...tdStyle,  textAlign: "start"}}>
                            <p style={{fontSize: "12px"}}>{observation}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            }
        </div>
    );
}

export default PrintVerification;
