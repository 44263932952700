import {
  faChessBoard,
  faEnvelope,
  faFile,
  faRuler,
} from "@fortawesome/free-solid-svg-icons";
import ModelDocuments from "./Outils/ModelDocuments";
import ModeleLN from "./Outils/ModelLN";
import NormeISA from "./Outils/NormeISA";
import PlanComptable from "./Outils/PlanComptable";
import { Menu } from "antd";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PlanComptableList from "../params/planComptable/PlanComptable";
import {NormesISAAll} from "../params/normes/norme";

const Outils = () => {
  const [current, setCurrent] = useState("model_document");
  const items = [
    {
      label: "Modèle document",
      key: "model_document",
      icon: <FontAwesomeIcon icon={faFile} />,
    },
    {
      label: "Modèle LM",
      key: "model_lm",
      icon: <FontAwesomeIcon icon={faEnvelope} />,
    },
    {
      label: "Normes ISA",
      key: "normes_isa",
      icon: <FontAwesomeIcon icon={faRuler} />,
    },
    {
      label: "Plan Comptable",
      key: "plan_comptable",
      icon: <FontAwesomeIcon icon={faChessBoard} />,
    },
  ];
  function showCurrentPage(key) {
    switch (key) {
      case "model_document":
        return <ModelDocuments></ModelDocuments>;
      case "model_lm":
        return <ModeleLN></ModeleLN>;
      case "normes_isa":
        return <NormesISAAll/>;
      case "plan_comptable":
        return <PlanComptableList/>;
      default:
        return <ModelDocuments></ModelDocuments>;
    }
  }
  return (
    <>
      <Menu
        onClick={(e) => {
          setCurrent(e.key);
        }}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
        style={{ marginBottom: "5px", fontWeight: "bold" }}
      />
      {showCurrentPage(current)}
    </>
  );
};

export default Outils;
