import React, { useEffect, useState, useMemo } from "react";
import { Identification } from "../../Identification";
import TraitementSyntheseAjustement from "./TraitementSyntheseAjustement";
import { getProcedureAudit } from "../../../../../data/procedureAudit";
import { getListeSommaire } from "../../../../../data/params/sommaire";
import { getListeIdentificationSommaire } from "../../../../../data/rateau";
import { faIdCard, faTasks } from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import { StepBody, StepHeader } from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";

const SyntheseAjustement = ({
        mission,
        client,
        code,
        // modalId,
        prev
    }) => {
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);

    const [activeTab, setActiveTab] = useState(1);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectYear, setSelectYear] = useState(mission?.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [travauxAjustementData, setTravauxAjustementData] = useState([]);
    const [sommaires, setSommaires] = useState([]);

    const codeSommaire = useMemo(() => code || 'EH1', [code]);

    useEffect(() => {
        const fetchSommaire = async () => {
            const res = await getListeSommaire();
            setSommaires(res.result);
        };

        fetchSommaire();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getProcedureAudit(missionSelected.id);
                if (response.result) {
                    const newData = response.result.map((code) => {
                        const ajustements = code.Lea_TravauxAjustements;
                        return {
                            cycle: `${code.codeSommaire} - ${sommaires.find((key) => key.libelle?.includes(code.codeSommaire))?.description || ''}`,
                            resultatProduit: ajustements.reduce((acc, opt) => acc + (opt.resultatProduit || 0), 0),
                            actif: ajustements.reduce((acc, opt) => acc + (opt.actif || 0), 0),
                            passif: ajustements.reduce((acc, opt) => acc + (opt.passif || 0), 0),
                            capitaux: ajustements.reduce((acc, opt) => acc + (opt.capitaux || 0), 0),
                            details: ajustements.map((ajustement) => ({
                                id: ajustement.id,
                                cycle: code.codeSommaire,
                                resultatProduit: ajustement.resultatProduit,
                                actif: ajustement.actif,
                                passif: ajustement.passif,
                                capitaux: ajustement.capitaux,
                                action: ajustement.action,
                            }))
                        };
                    });
                    setTravauxAjustementData(newData);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        };

        fetchData();
    }, [missionSelected, sommaires]);

    useEffect(() => {
        if (activeTab === 0) {
            const fetchIdentifications = async () => {
                const res = await getListeIdentificationSommaire(missionSelected.id, codeSommaire);
                setIdentifications(res.result);
            };

            fetchIdentifications();
        }
    }, [activeTab, codeSommaire, missionSelected]);

    const formSteps = useMemo(() => ([
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: <Identification identifications={identifications} mission={missionSelected} />,
        },
        {
            code: "SA",
            title: `SYNTHESES DES AJUSTEMENTS - ${selectYear}`,
            icon: faTasks,
            content: <TraitementSyntheseAjustement travauxData={travauxAjustementData} mission={missionSelected} chargement={loading} />,
        },
    ]), [identifications, missionSelected, selectYear, travauxAjustementData, loading]);

    return (
        <div className="mx-5">
            <PageToolbarHeader
                prev={prev}
                pageTitle={"EI.1 - SYNTHESES DES AJUSTEMENTS"}
                mission={missionSelected}
                setMission={setMissionSelected}
                isProvisoire={isProvisoire}
                setIsProvisoire={setIsProvisoire}
                selectYear={selectYear}
                setSelectYear={setSelectYear}
                loading={loading}
                setLoading={setLoading}
                client={client}
                setIsModalOpen={setIsModalOpen}
                isModalOpen={isModalOpen}
                setDate={setDate}
                setTache_id={setTache_id}
                tache_id={tache_id}
                setIntervention_id={setIntervention_id}
                intervention_id={intervention_id}
                setHeureEntree={setHeureEntree}
                setUser_id={setUser_id}
            />
            <StepHeader>
                <ModalNavBarMenu setActiveTab={setActiveTab} activeTab={activeTab} steps={formSteps} />
            </StepHeader>
            <StepBody>
                {formSteps[activeTab].content}
            </StepBody>
        </div>
    );
};

export { SyntheseAjustement };
