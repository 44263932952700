import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import clsx from 'clsx'
import {useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../../helpers/AssetsHelpers";
import {EnvironmentOutlined, IdcardOutlined, MailOutlined} from "@ant-design/icons";

export function UserView() {
    let location = useLocation()
    const currentUser = useSelector(state => state.user)
    const [user, setUser] = useState({})

    const current = location.state.record

    useEffect(() => {
        if (current) {
            setUser(current)
        } else {
            setUser(currentUser)
        }
    }, [currentUser?.id, setUser])

    return (
        <>
            <div className='card d-flex flex-column w-100'>
                <div className='card mb-5 mb-xl-10 '>
                    <div className='card-body pt-9 pb-0'>
                        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                            <div className='me-7 mb-4'>
                                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                                    {currentUser?.avatar ? (
                                        <div className='symbol-label'>
                                            <img
                                                src={toAbsoluteUrl(`/media/${user?.avatar}`)}
                                                alt={user?.nom_user + ' ' + user?.prenom_user}
                                                className='w-100'
                                            />
                                        </div>
                                    ) : currentUser?.codeCivilite === 'M.' ? (
                                        <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                                            <img
                                                src={toAbsoluteUrl('/media/avatars/male.jpg')}
                                                alt={user?.nom_user + ' ' + user?.prenom_user}
                                                className='w-100'
                                            />
                                        </div>
                                    ) : (
                                        <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                                            <img
                                                src={toAbsoluteUrl('/media/avatars/female.jpg')}
                                                alt={user?.nom_user + ' ' + user?.prenom_user}
                                                className='w-100'
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='flex-grow-1'>
                                <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                                    <div className='d-flex flex-column'>
                                        <div className='d-flex align-items-center mb-2'>
                                            <a href='#'
                                               className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                                {user?.nom_user + ' ' + user?.prenom_user}
                                            </a>
                                        </div>

                                        <div
                                            className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'
                                            style={{paddingTop: '20px'}}
                                        >
                                            <span className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                                              <IdcardOutlined style={{fontSize: '25px', paddingRight: '10px', color: 'blue'}}/>
                                                {user?.libelleProfil}
                                            </span>
                                            <span
                                                className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'>
                                              <EnvironmentOutlined style={{fontSize: '25px', paddingRight: '10px', color: 'blue'}}/>
                                                                        {user?.habitation_user}
                                            </span>
                                            <span
                                                className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                                              <MailOutlined style={{fontSize: '25px', paddingRight: '10px', color: 'blue'}}/>
                                                                        {user?.email_user}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body border-top p-9'>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Civilité</span>
                        </label>

                        <div className='col-lg-8 fv-row'>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-transparent'
                                value={user?.codeCivilite}
                                 readOnly={true}
                            />
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Initial</span>
                        </label>

                        <div className='col-lg-4 fv-row'>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-transparent'
                                value={user?.initial}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nom et Prénoms</label>

                        <div className='col-lg-8'>
                            <div className='row'>
                                <div className='col-lg-6 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-transparent  mb-3 mb-lg-0'
                                        value={user?.nom_user}
                                         readOnly={true}
                                    />
                                </div>

                                <div className='col-lg-6 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-transparent '
                                        value={user?.prenom_user}
                                         readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Lieu d'habitation</label>

                        <div className='col-lg-8 fv-row'>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-transparent '
                                value={user?.habitation_user}
                                 readOnly={true}
                            />
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Pays</label>

                        <div className='col-lg-8 fv-row'>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-transparent '
                                value={user?.nom_fr_fr}
                                 readOnly={true}
                            />
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Email</span>
                        </label>

                        <div className='col-lg-8 fv-row'>
                            <input
                                className='form-control form-control-lg form-control-transparent '
                                type='email'
                                name='email_user'
                                value={user?.email_user}
                                 readOnly={true}
                            />
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Login</span>
                        </label>

                        <div className='col-lg-8 fv-row'>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-transparent '
                                value={user?.login_user}
                                 readOnly={true}
                            />
                        </div>
                    </div>

                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span className='required'>Téléphone 1</span>
                        </label>

                        <div className='col-lg-8 fv-row'>
                            <input
                                type='text'
                                className='form-control form-control-lg  form-control-transparent'
                                value={user?.telephone1_user}
                                 readOnly={true}
                            />
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span>Téléphone 2</span>
                        </label>

                        <div className='col-lg-8 fv-row'>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-transparent '
                                value={user?.telephone2_user}
                                 readOnly={true}
                            />
                        </div>
                    </div>
                    <div className='row mb-6'>
                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                            <span>Profil</span>
                        </label>

                        <div className='col-lg-8 fv-row'>
                            <input
                                type='text'
                                className='form-control form-control-lg form-control-transparent '
                                value={user?.libelleProfil}
                                 readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
