import React, {useEffect, useState} from "react";
import {Button, Input, Space, Table, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {getStrategieGlobaleMission} from "../../../../../../../data/stategieGlobale";
import {areArraysEqual} from "../../../../../../utilities/Utilities";
import {ajouterTravauxAjustement} from "../../../../../../../data/procedureAudit";
import swal from "sweetalert";
import {CustomInputNumber} from "../../../../../../layout/tools/InputNumber";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUpload, faSave} from "@fortawesome/free-solid-svg-icons";

const Ajustements = ({
         mission, isProvisoire,
         procedure_id,
         travauxData,
         code,
         totalsData,
         setTotalsData,
         descriptionsData,
         setDescriptionsData,
         chargement,
         isTaskSave,
         setIsTaskSave,
     }) => {
    const [loading, setLoading] = useState(false);
    // const [descriptionsData, setDescriptionsData] = useState([]);
    const [globalProv, setGlobalProv] = useState(null);
    const [globalDef, setGlobalDef] = useState(null);
    const [anomalieSignificativesProv, setAnomalieSignificativesProv] = useState(null);
    const [anomalieSignificativesDef, setAnomalieSignificativesDef] = useState(null);
    const [materialitePerformancesProv, setMaterialitePerformancesProv] = useState(null);
    const [materialitePerformancesDef, setMaterialitePerformancesDef] = useState(null);
    const [message, setMessage] = useState(false);
    const [error, setError] = useState(false);
    // const [totalsData, setTotalsData] = useState([
    //     {
    //         id: 1,
    //         libelle: "Total des anomalie identifiées lors de la mission d'audit",
    //         resultatProduit: 0,
    //         actif: 0,
    //         passif: 0,
    //         capitaux: 0,
    //     },
    //     {
    //         id: 2,
    //         libelle: "Anomalies corrigées par la Direction",
    //         resultatProduit: 0,
    //         actif: 0,
    //         passif: 0,
    //         capitaux: 0,
    //     },
    //     {
    //         id: 3,
    //         libelle: "Total des anomalies non corrigées",
    //         resultatProduit: 0,
    //         actif: 0,
    //         passif: 0,
    //         capitaux: 0,
    //     },
    // ])

    useEffect(() => {
        if(travauxData){
            const updatedList = travauxData.map((item) => ({
                id: item.id,
                ref: item.ref,
                description: item.description,
                resultatProduit: item.resultatProduit,
                actif: item.actif,
                passif: item.passif,
                capitaux: item.capitaux,
                action: item.action
            }))

            setDescriptionsData(updatedList)
        }
    }, [travauxData])

    useEffect(() => {
        if(mission ){
            setLoading(true);
            getStrategieGlobaleMission(mission.id)
                .then((res) => {
                    if(res.result){
                        const strategieProv = res.result.find((item) => item.isProvisoire === true)
                        const strategieDef = res.result.find((item) => item.isProvisoire === false)
                        if(strategieProv){
                            setGlobalProv(strategieProv?.Lea_GlobalSignifications[0]);
                            setAnomalieSignificativesProv(strategieProv?.Lea_AnomalieInsignifiantes[0]);
                            setMaterialitePerformancesProv(strategieProv?.Lea_MaterialitePerformances[0]);
                            setLoading(false);
                        }
                        if(strategieDef){
                            setGlobalDef(strategieDef?.Lea_GlobalSignifications[0]);
                            setAnomalieSignificativesDef(strategieDef?.Lea_AnomalieInsignifiantes[0]);
                            setMaterialitePerformancesDef(strategieDef?.Lea_MaterialitePerformances[0]);
                            setLoading(false);
                        }

                    }
                });
        }
    },[mission]);

    const anomalieData = [
        {
            id: 1,
            libelle: "Seuil de signification global",
            montantProv: parseInt(globalProv?.seuilMaterialite) || 0,
            montantDef: parseInt(globalDef?.anneNDefC) || 0
        },
        {
            id: 2,
            libelle: "Seuil de signification au niveau des contrôles",
            montantProv: parseInt(materialitePerformancesProv?.montantProv) || 0,
            montantDef: parseInt(materialitePerformancesDef?.montantDef) || 0
        },
        {
            id: 3,
            libelle: "Seuil des anomalies insignificatif",
            montantProv: parseInt(anomalieSignificativesProv?.montantProv) || 0,
            montantDef: parseInt(anomalieSignificativesDef?.montantDef) || 0
        },
    ]

    const calculateTotal = (filteredDescriptions, id) => {
        const updatedList = totalsData.map((item) =>
            item.id === id
                ? {
                    ...item,
                    resultatProduit: filteredDescriptions.reduce((acc, opt) => acc + (opt.resultatProduit || 0), 0),
                    actif: filteredDescriptions.reduce((acc, opt) => acc + (opt.actif || 0), 0),
                    passif: filteredDescriptions.reduce((acc, opt) => acc + (opt.passif || 0), 0),
                    capitaux: filteredDescriptions.reduce((acc, opt) => acc + (opt.capitaux || 0), 0),
                }
                : item
        );

        if (!areArraysEqual(updatedList, totalsData)) {
            setTotalsData(updatedList);
        }
    };

    useEffect(() => {
        const filteredDescriptionsYes = descriptionsData.filter((item) => item.action === 'OUI');
        if (filteredDescriptionsYes.length > 0) {
            calculateTotal(filteredDescriptionsYes, 2);
        }

        const filteredDescriptionsNo = descriptionsData.filter((item) => item.action === 'NON');
        if (filteredDescriptionsNo.length > 0) {
            calculateTotal(filteredDescriptionsNo, 3);
        }

        const updatedTotal = totalsData.map((item) =>
            item.id === 1
                ? {
                    ...item,
                    resultatProduit: descriptionsData.reduce((acc, opt) => acc + (opt.resultatProduit || 0), 0),
                    actif: descriptionsData.reduce((acc, opt) => acc + (opt.actif || 0), 0),
                    passif: descriptionsData.reduce((acc, opt) => acc + (opt.passif || 0), 0),
                    capitaux: descriptionsData.reduce((acc, opt) => acc + (opt.capitaux || 0), 0),
                }
                : item
        );

        if (!areArraysEqual(updatedTotal, totalsData)) {
            setTotalsData(updatedTotal);
        }
    }, [descriptionsData, setTotalsData, totalsData]);

    const handleResultatInput = (value, index) => {
        const updatedList = descriptionsData.map((item, i) =>
            i === index ? { ...item,  resultatProduit: parseInt(value) || 0 } : item
        );
        setDescriptionsData(updatedList);

        const updatedTotal = totalsData.map((item) => {
            if (item.id === 1) {
                return {
                    ...item,
                    resultatProduit: updatedList.reduce((acc, opt) => acc + opt.resultatProduit, 0),
                };
            }
            return item;
        });
        setTotalsData(updatedTotal);
        setIsTaskSave(true);
    }

    const handleActifInput = (value, index) => {
        const updatedList = descriptionsData.map((item, i) =>
            i === index ? { ...item,  actif: parseInt(value) || 0 } : item
        );
        setDescriptionsData(updatedList);

        const updatedTotal = totalsData.map((item) => {
            if (item.id === 1) {
                return {
                    ...item,
                    actif: updatedList.reduce((acc, opt) => acc + opt.actif, 0),
                };
            }
            return item;
        });
        setTotalsData(updatedTotal);
        setIsTaskSave(true);
    }

    const handlePassifInput = (value, index) => {
        const updatedList = descriptionsData.map((item, i) =>
            i === index ? {...item, passif: parseInt(value) || 0} : item
        );
        setDescriptionsData(updatedList);

        const updatedTotal = totalsData.map((item) => {
            if (item.id === 1) {
                return {
                    ...item,
                    passif: updatedList.reduce((acc, opt) => acc + opt.passif, 0),
                };
            }
            return item;
        });
        setTotalsData(updatedTotal);
        setIsTaskSave(true);
    }

    const handleCapitauxInput = (value, index) => {
        const updatedList = descriptionsData.map((item, i) =>
            i === index ? { ...item,  capitaux: parseInt(value) || 0 } : item
        );
        setDescriptionsData(updatedList);

        const updatedTotal = totalsData.map((item) => {
            if (item.id === 1) {
                return {
                    ...item,
                    capitaux: updatedList.reduce((acc, opt) => acc + opt.capitaux, 0),
                };
            }
            return item;
        });
        setTotalsData(updatedTotal);
        setIsTaskSave(true);
    }

    const handleAdd = (e) => {
        e.preventDefault()
        const newElement = {
            description: '',
            ref: '',
            resultatProduit: 0,
            actif: 0,
            passif: 0,
            capitaux: 0,
            action: ''
        };

        setDescriptionsData([...descriptionsData, newElement]);
        setIsTaskSave(true);
    };

    const handleAction = (value, index) => {
        const updatedList = descriptionsData.map((item, i) =>
            i === index ? { ...item, action: value } : item
        );
        setDescriptionsData(updatedList);
        setIsTaskSave(true);
    };

    const handleDeleteElement = (index) => {
        const updatedList = descriptionsData.filter((_, i) => i !== index);
        setDescriptionsData(updatedList);
        setIsTaskSave(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        setLoading(true);

        const data = {
            exercice_id: mission.id,
            client_id: mission.client_id,
            procedure_id,
            ajustementData: descriptionsData,
            isProvisoire,
            // sommaire_id,
            codeSommaire: code,
        };

        const {status, message} = await ajouterTravauxAjustement(data)
        if (status === 'success') {
            setLoading(false)
            setIsTaskSave(false);
            await swal(`Opération effectuée avec succès`, '', 'success')
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const columnAnomaliesData = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Description</TableTitle>,
            dataIndex: 'description',
            width: 300,
            render: (_, record) => (
                <span className='' style={{fontSize: '14px'}}>
                  {record.libelle}
                </span>
            ),
        },

        {
            title: () => <TableTitle>Provisoire</TableTitle>,
            dataIndex: 'provisoire',
            width: 150,
            render: (_, record) => (
                <div className='text-end pe-0' style={{fontSize: '14px'}}>
                    {record.montantProv.toLocaleString('fr')}
                </div>
            ),
        },

        {
            title: () => <TableTitle>Définitive</TableTitle>,
            dataIndex: 'definitive',
            width: 150,
            render: (_, record) => (
                <div className='text-end pe-0' style={{fontSize: '14px'}}>
                    {record.montantDef.toLocaleString('fr')}
                </div>
            ),
        },
    ];

    const columnTotalData = [
        {
            title: () => <TableTitle></TableTitle>,
            dataIndex: 'description',
            width: 350,
            render: (_, record) => (
                <span className='' style={{fontSize: '14px'}}>
                  {record.libelle}
                </span>
            ),
        },

        {
            title: () => <TableTitle>Résultats produits</TableTitle>,
            dataIndex: 'resultatProduit',
            width: 150,
            render: (_, record, ) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.resultatProduit.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Actifs</TableTitle>,
            dataIndex: 'actif',
            width: 150,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.actif.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Passifs</TableTitle>,
            dataIndex: 'passif',
            width: 150,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.passif.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Capitaux propres</TableTitle>,
            dataIndex: 'capitaux',
            width: 150,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.capitaux.toLocaleString('fr')}
                </div>
            ),
        },
    ];

    const columnDescriptionData = [
        {
            title: () => <TableTitle>Description</TableTitle>,
            // key: 'description',
            width: 250,
            dataIndex: 'description',
            render: (_, record, index) => (
                <Input.TextArea
                    style={{fontSize: "14px"}}
                    size="large"
                    bordered={false}
                    placeholder="Saisisser la description..."
                    value={record.description}
                    maxLength={2000}
                    showCount
                    autoSize={{
                        minRows: 1,
                        maxRows: 4,
                    }}
                    onChange={(e) => {
                        const updatedList = descriptionsData.map((item, i) =>
                            i === index ? { ...item, description: e.target.value } : item
                        );
                        setDescriptionsData(updatedList);
                        setIsTaskSave(true);
                    }}
                />
            ),
        },

        {
            title: () => <TableTitle>Réf F/T</TableTitle>,
            key: 'ref',
            dataIndex: 'ref',
            width: 150,
            render: (_, record, index) => (
                <Input.TextArea
                    style={{fontSize: "14px"}}
                    size="large"
                    bordered={false}
                    placeholder="Saisisser la description..."
                    value={record.ref}
                    maxLength={2000}
                    showCount
                    autoSize={{
                        minRows: 1,
                        maxRows: 4,
                    }}
                    onChange={(e) =>{
                        const updatedList = descriptionsData.map((item, i) =>
                            i === index ? { ...item,  ref: e.target.value } : item
                        );

                        setDescriptionsData(updatedList);
                        setIsTaskSave(true);
                    }}
                />
            ),
        },
        {
            title: () => <TableTitle>Ajustement (+) ou (-) Résultat Produits et Charges</TableTitle>,
            key: 'resultatProduit',
            dataIndex: 'resultatProduit',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.resultatProduit}
                    name='resultatProduit'
                    onChange={handleResultatInput}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Bilan</TableTitle>,
            key: 'bilan',
            dataIndex: 'bilan',
            children: [
                {
                    title: () => <TableTitle>Actif</TableTitle>,
                    dataIndex: 'actif',
                    // key: 'actif',
                    width: 150,
                    render: (_, record, index) => (
                        <CustomInputNumber
                            value={record.actif}
                            name='actif'
                            onChange={handleActifInput}
                            index={index}
                        />
                    ),
                },
                {
                    title: () => <TableTitle>Passifs</TableTitle>,
                    dataIndex: 'passif',
                    // key: 'passif',
                    width: 150,
                    render: (_, record, index) => (
                        <CustomInputNumber
                            value={record.passif}
                            name='passif'
                            onChange={handlePassifInput}
                            index={index}
                        />
                    ),
                },
                {
                    title: () => <TableTitle>Capitaux Propres</TableTitle>,
                    dataIndex: 'capitaux',
                    // key: 'capitaux',
                    width: 150,
                    render: (_, record, index) => (
                        <CustomInputNumber
                            value={record.capitaux}
                            name='capitaux'
                            onChange={handleCapitauxInput}
                            index={index}
                        />
                    ),
                },
            ],
        },

        {
            title: () => <TableTitle>Anomalie corrigé OUI / NON</TableTitle>,
            // key: 'anomalieCorrige',
            dataIndex: 'anomalieCorrige',
            width: 150,
            render: (_, record, index) => (
                <select
                    style={{fontSize: '14px'}}
                    name='anomalieCorrige'
                    className="form-select"
                    onChange={(e) => {
                        handleAction(e.target.value, index);
                        setIsTaskSave(true);
                    }}
                    value={record.action}
                >
                    <option>Sélectionner...</option>
                    {['OUI', 'NON'].map((opt, index) => (
                        <option key={index} value={opt}>
                            {opt}
                        </option>
                    ))}
                </select>
            ),
        },
        {
            title: () => <TableTitle></TableTitle>,
            dataIndex: 'action',
            width: 40,
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteElement(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

  return(
      <div className='pb-15'>
          <div className="d-flex justify-content-end align-items-end mb-5">
              <Tooltip title="Enregistrer">
                  <Button
                      disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                      size={"large"}
                      // loading={loading}
                      style={{
                          backgroundColor: appTheme.colors.secondary,
                          color: "white"
                      }}
                      icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                      onClick={handleSubmit}
                  >
                      {!(loading || chargement) &&
                          <span className='indicator-label text-white'>Enregistrer</span>}
                      {(loading || chargement) && (
                          <span className='indicator-progress fs-9 text-white'
                                style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                      )}
                  </Button>
              </Tooltip>
          </div>
          <div className='d-flex align-center'>
              <div className='col-lg-8'>
                  <div className="table-responsive mb-5 mx-3">
                      <Table
                          rowClassName={() =>
                              `cursor-pointer h-3px p-0 m-0 lh-0`
                          }
                          columns={columnAnomaliesData}
                          dataSource={anomalieData}
                          pagination={false}
                          loading={chargement || loading}
                      />
                  </div>
              </div>
          </div>
          <div className="table-responsive mx-2">

              <div className="d-flex justify-content-end align-center my-2 me-5">
                  <div className="d-flex flex-wrap align-center">
                      <Space>
                          {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                              <Button
                                  size={"middle"}
                                  style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                  onClick={handleAdd}
                                  icon={<FontAwesomeIcon icon={faCloudUpload}/>}
                              >
                                  Ajouter une ligne
                              </Button>
                          </Tooltip>}
                      </Space>
                  </div>
              </div>

              <Table
                  rowClassName={() =>
                      'cursor-pointer h-3px p-0 m-0 lh-0.5'
                  }
                  columns={columnDescriptionData}
                  // rowKey={(record, index) => index}
                  dataSource={descriptionsData ?? []}
                  pagination={false}
                  scroll={{ y: 800}}
                  loading={chargement || loading}
              />
          </div>
          <div className='col-lg-10 mt-15'>
              <div className="table-responsive mb-5">
                  <Table
                      rowClassName={() =>
                          'cursor-pointer h-3px p-0 m-0 lh-0.5'
                      }
                      columns={columnTotalData}
                      // rowKey={(record, index) => index}
                      dataSource={totalsData ?? []}
                      pagination={false}
                      loading={chargement || loading}
                      size="small"
                  />
              </div>
          </div>
      </div>
  )
}
export {Ajustements}