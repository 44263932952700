import React from 'react';
import { InputNumber } from 'antd';

const CustomInputNumber = ({ onChange, value, name, index, min, max }) => {

    return (
        <InputNumber
            name={name}
            className="w-100 fw-bolder mb-2 fs-5 text-end"
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            onChange={(value) => onChange(value, index)}
            value={(value || 0)}
            step="0.01"
            min={min}
            max={max}
        />
    );
};

const ControlInputNumber = ({ onChange, value, name, index, min, max }) => {

    return (
        <InputNumber
            name={name}
            className="w-100 fw-bolder mb-2 fs-5 text-end"
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
            parser={(value) => value.replace(/\$\s?|( *)/g, '')}
            onChange={(value) => onChange(value, index)}
            value={(value || 0).toFixed(2)}
            step="0.01"
            min={min}
            max={max}
        />
    );
};

export { CustomInputNumber, ControlInputNumber};
