import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";

import {Button, Col, Input, Popover, Row, Select, Tag, Tooltip, Typography} from "antd";
import {ModalTitle} from "../modals.components";
import ModalAlert from "../Modal";
import FormError from "../../common/Form/FormError";
import FormLabel from "../../common/Form/FormLabel";
import useUsers from "../../../hooks/users/useUser";
import {getListeClients} from "../../../data/client";
import {getListeSommaireActive} from "../../../data/params/sommaire";
import {CloseSquareOutlined} from "@ant-design/icons";
import {faDeleteLeft, faListCheck, faNetworkWired} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTheme} from "styled-components";
import {SearchHeader} from "../../layout/header/HeaderList";
import {appTheme} from "../../../config/theme";
import {Spinner} from "@chakra-ui/react";

const Content = ({sommaires}) => {
    const theme = useTheme()
    return (
        <div className="d-flex flex-column w-500px h-200px scroll-y">
            {sommaires.map((item) => (
                <Tag
                    style={{
                        display: "flex",
                        fontSize: 14,
                        padding: 4,
                        marginBottom: 8,
                        width: "100%"
                    }}
                >
                    <Tag color={theme.colors.primary}>{item.libelle}</Tag>
                    <div style={{fontFamily: "Oswald, sans-serif"}}>
                        {item.description}
                    </div>
                </Tag>
            ))}
        </div>
    )
};

export const ModalClientExercice = ({
        visible,
        handleVisibility,
        handleValider,
        chargement, data, setSommaires, sommaires
    }) => {
    // const [loading, setLoading] = useState(false);
    const [years, setYears] = useState([]);
    const [clients, setClients] = useState([]);
    // const [sommaires, setSommaires] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [filtersItems, setFiltersItems] = useState( []);
    const users = useUsers();
    const [sommairesTable, setSommairesTable] = useState([]);
    const [isAllSommaireSelected, setIsAllSommaireSelected] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(true);

    const fetchExercicesClients = () => {
        getListeClients()
            .then((res) => {
                setClients(res.result);
            });
    };

    const fetchSommaire = async () => {
        getListeSommaireActive()
            .then((res) => {
                setSommairesTable((res.result ?? []).sort((a, b) => a.libelle.localeCompare(b.libelle)));
                setFiltersItems((res.result ?? []).sort((a, b) => a.libelle.localeCompare(b.libelle)));

                const tabs = (res.result ?? []).map(som => ({
                    sommaire_id: som.id,
                    libelle: som.libelle,
                    description: som.description,
                    isComplete: !!(som.libelle?.includes("EE0") || som.libelle?.includes("ED0") || som.libelle?.includes("EB2") || som.libelle?.includes("EC1"))
                }));
                setSommaires(tabs);
            })
            .catch((error) => {
                console.error("Erreur", error);
            });
    };

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const yearsArray = Array.from({ length: 20 }, (_, index) => currentYear - index);
        setYears(yearsArray);
        fetchExercicesClients()
    }, []);

    useEffect(() => {
        fetchSommaire()
    }, []);

    useEffect(() => {
        if(isAllSommaireSelected){
            const allSommaires = sommairesTable.map((sommaire) => ({
                sommaire_id: sommaire.id,
                libelle: sommaire.libelle,
                description: sommaire.description,
                isComplete: !!(sommaire.libelle?.includes("EE0") || sommaire.libelle?.includes("ED0") || sommaire.libelle?.includes("EB2") || sommaire.libelle?.includes("EC1"))
            }))

            setSommaires(allSommaires)
        }
        else {
            setSommaires([]);
        }
    }, [isAllSommaireSelected, setSommaires, sommairesTable])

    const handleCheckboxChange = (sommaireId) => {
        const sommaire = sommairesTable.find((item) => item.id === sommaireId);
        if(sommaire){
            console.log("sommaire", sommaire)
            console.log("sommaireId", sommaireId);
            const isSelected = sommaires.some((item) => item.id  === sommaireId);
            console.log("isSelected", isSelected)
            if (isSelected) {
                setSommaires(prevSelected => prevSelected.filter(item => item.sommaire_id !== sommaireId));
            } else {
                const element = {
                    sommaire_id: sommaire.id,
                    libelle: sommaire.libelle,
                    description: sommaire.description,
                    isComplete: !!(sommaire.libelle?.includes("EE0") || sommaire.libelle?.includes("ED0") || sommaire.libelle?.includes("EB2") || sommaire.libelle?.includes("EC1"))
                }
                setSommaires(prevSelected => [...prevSelected, element]);
            }
        }
    };

    const dataValidationScheme = Yup.object({
        chef_id: Yup.number()
            .required("Veuillez sélectionner le manager")
            .nullable("Le manager est obligatoire")
            .typeError("Le manager est obligatoire"),
        typeMission_id: Yup.number()
            .required("Veuillez sélectionner un type de mission")
            .nullable("Le type de mission est obligatoire")
            .typeError("Le type de mission est obligatoire"),
        annee: Yup.string().required("L'année d'exercice de la mission est obligatoire"),
        mission: Yup.string().required("Le nom de la mission est obligatoire"),
    });

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = sommairesTable.filter(
            (item) =>
                item.libelle?.toLowerCase().includes(searchTerm) ||
                item.description.toString()?.toLowerCase().startsWith(searchTerm)
        );
        setFiltersItems(filtered);
    };

    const onSelectedAll = () => {
        setLoading(true);
        setTimeout(() => {
            sommairesTable.forEach((som) => {
                const isSelected = sommaires.some((item) => item.sommaire_id === som.id);
                if (!isSelected) {
                    const element = {
                        sommaire_id: som.id,
                        libelle: som.libelle,
                        description: som.description,
                        isComplete: !!(som.libelle?.includes("EE0") || som.libelle?.includes("ED0") || som.libelle?.includes("EB2") || som.libelle?.includes("EC1"))
                    }
                    setSommaires(prevSelected => [...prevSelected, element]);
                }
            });
            setLoading(false);
            setSelectAll(true);
        }, 1500)
    };

    const handleDeleteAllElements = () => {
        setLoading(true);
        setTimeout(() => {
            setSommaires([])
            setLoading(false);
            setSelectAll(false);
        }, 1000)
    };

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Nouvel exercice pour {`${data.sigle && data.sigle}`}</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 30 }}
                width={600}
            >
                <Formik
                    initialValues={{
                        sommaires: sommaires,
                        chef_id: '',
                        typeMission_id: 1,
                        role_id: "",
                        annee: "",
                        mission: "",
                        client_id: (data && data.id) || ""
                    }}
                    onSubmit={(values) => handleValider(values)}
                    validationSchema={dataValidationScheme}
                >
                    {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                        <Form>
                            <Row gutter={[16, 16]}>

                                <Col span={24}>
                                    <FormLabel label="Nom de la mission" required />
                                    <Input
                                        size="large"
                                        placeholder="Mission"
                                        name="mission"
                                        value={values.mission}
                                        onChange={(e) =>
                                            setFieldValue("mission", e.target.value)
                                        }
                                    />
                                    {touched.mission && errors.mission && (
                                        <FormError fieldName="mission" />
                                    )}

                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Client" required />
                                    <Select
                                        showSearch
                                        placeholder="Sélectionnez..."
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={values.client_id}
                                        options={clients.map((client) => ({
                                            value: client.id,
                                            label: client.denomination.toUpperCase()
                                        }))}

                                        onChange={(value) =>
                                            setFieldValue("client_id", value)
                                        }
                                        size="large"
                                        style={{ width: "100%" }}
                                    />
                                    {touched.client_id && errors.client_id && (
                                        <FormError fieldName="client_id" />
                                    )}
                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Manager" required />
                                    <Select
                                        showSearch
                                        placeholder="Sélectionnez..."
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={values.chef_id ? values.chef_id : null}
                                        options={users && users.users.map((user) => ({
                                            value: user.id,
                                            label: `${user.nom_user.toUpperCase()} ${user.prenom_user ? user.prenom_user.toUpperCase() : ""} - ${user.initial ? user.initial :""}`
                                        }))}
                                        onChange={(value) =>
                                            setFieldValue("chef_id", value)
                                        }
                                        size="large"
                                        style={{ width: "100%" }}
                                    />
                                    {touched.chef_id && errors.chef_id && (
                                        <FormError fieldName="chef_id" />
                                    )}
                                </Col>
                                <Col span={12}>
                                    <FormLabel label="Année d'exercice" required />
                                    <Select
                                        placeholder="Sélectionner..."
                                        size="large"
                                        style={{ width: "100%" }}
                                        value={values.annee ? values.annee : null}
                                        options={years.map((year) => ({
                                            value: year,
                                            label: year
                                        }))}
                                        onChange={(value) =>
                                            setFieldValue("annee", value)
                                        }
                                    />
                                    {touched.annee && errors.annee && (
                                        <FormError fieldName="annee" />
                                    )}
                                </Col>
                                <Col span={12}>
                                    <FormLabel label="Type de mission" required/>
                                    <Select
                                        options={[
                                            { label: "STANDARD", value: 1 },
                                        ]}
                                        value={values.typeMission_id ? values.typeMission_id : null}
                                        onChange={(value) =>
                                            setFieldValue("typeMission_id", value)
                                        }
                                        size="large"
                                        style={{ width: "100%" }}
                                        placeholder="Type de mission"
                                    />
                                    <FormError fieldName="typeMission_id" />
                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Sélection automatique du sommaire"/>
                                    <div className="fs-7 fw-semibold text-muted">Par défaut, tout le sommaire est sélectionné</div>
                                    <div className="d-flex justify-content-between align-center pe-lg-15 ">
                                        <label className="form-check form-switch form-check-custom form-check-solid">
                                            <input
                                                onChange={() => {
                                                    setIsAllSommaireSelected(!isAllSommaireSelected)
                                                    setIsModalOpen(true)
                                                }}
                                                className="form-check-input"
                                                type="checkbox"
                                                value="1"
                                                checked={isAllSommaireSelected}
                                            />
                                            <span className="form-check-label fw-semibold text-muted">
                                                {isAllSommaireSelected === true ? 'Activé' : 'Désactivé'}
                                            </span>
                                        </label>
                                        {sommaires.length > 0 &&
                                            <Tooltip title="Sommaires sélectionnés" color={"#009EF7"}>
                                                <Popover content={<Content sommaires={sommaires} />} title="Sommaires sélectionnés." trigger="click">
                                                    <Button icon={<FontAwesomeIcon icon={faNetworkWired} color={"#009EF7"} />} />
                                                </Popover>
                                            </Tooltip>
                                        }
                                    </div>
                                    {(!isAllSommaireSelected && (sommaires.length <= 0)) && (
                                        <Typography.Text type="danger">Veuillez sélectionner les cycles du sommaire</Typography.Text>
                                    )}
                                </Col>
                                <Col span={24}>
                                    <Button
                                        type="primary"
                                        block
                                        size="large"
                                        loading={chargement}
                                        disabled={chargement}
                                        onClick={handleSubmit}
                                    >
                                        {!chargement && <span className='indicator-label'>Enregistrer</span>}
                                        {chargement && (
                                            <span className="indicator-progress text-white">
                                            Veuillez patienter...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                        </span>
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
                {(isModalOpen && !isAllSommaireSelected) && (
                    <div
                        className="top-0 bottom-0 z-index-9999 h-100"
                        style={{ insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
                    >
                        <div className="modal fade show" id="kt_modal_move_to_folder" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}>
                            <div className='modal-dialog modal-dialog-centered mw-600px' >
                                <div className='modal-content'>
                                    <form className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#"
                                          id="kt_modal_move_to_folder_form">
                                        {/*begin::Modal header*/}
                                        <div className="modal-header">
                                            {/*begin::Modal title*/}
                                            <h2 className="fw-bolder">Affectation du sommaire</h2>
                                            {/*end::Modal title*/}
                                            {/*begin::Close*/}
                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={() => setIsModalOpen(false)}>
                                                <span className="svg-icon svg-icon-1">
                                                  <CloseSquareOutlined style={{color: 'red'}}/>
                                                </span>
                                            </div>
                                            {/*end::Close*/}
                                        </div>
                                        {/*end::Modal header*/}
                                        {/*begin::Modal body*/}
                                        <div className="modal-body pt-10 pb-15 px-lg-17">
                                            {loading ?
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <Spinner/>
                                                </div>
                                                :
                                                (!isAllSommaireSelected && <div className='mh-350px scroll-y me-n3 pe-3'>
                                                {selectAll ?  (
                                                        <div className='py-5'>
                                                            <Button
                                                                size={"middle"}
                                                                style={{backgroundColor: appTheme.colors.danger}}
                                                                onClick={handleDeleteAllElements}
                                                                icon={<FontAwesomeIcon icon={faDeleteLeft} color={"white"}/>}
                                                            >
                                                                <span className="fw-bolder text-white">Supprimer tout</span>
                                                            </Button>
                                                        </div>
                                                    )
                                                    :
                                                    (
                                                        <div className='py-5'>
                                                            <Button
                                                                size={"middle"}
                                                                style={{backgroundColor: appTheme.colors.primary_blue}}
                                                                onClick={onSelectedAll}
                                                                icon={<FontAwesomeIcon icon={faListCheck} color={"white"}/>}
                                                            >
                                                                <span className="fw-bolder text-white">Tout sélectionner</span>
                                                            </Button>
                                                        </div>
                                                    )
                                                }
                                                {(filtersItems ?? []).length > 0 && <div className="card-header border-0 pt-2">
                                                    <SearchHeader
                                                        placeholder="Rechercher par le code, le libellé..."
                                                        handleSearch={handleSearchChange}
                                                    />
                                                </div>}
                                                <div className="form-group fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                    {/*begin::Item*/}
                                                    {filtersItems.map((sommaire, index) => (
                                                        <>
                                                            <div className="d-flex" key={index}>
                                                                {/*begin::Checkbox*/}
                                                                <div className="form-check form-check-custom">
                                                                    {/*begin::Input*/}
                                                                    <input
                                                                        className="form-check-input me-3"
                                                                        name="move_to_folder"
                                                                        type="checkbox"
                                                                        value={sommaire.id}
                                                                        id={`kt_modal_move_to_folder_${index}`}
                                                                        onChange={() => handleCheckboxChange(sommaire.id)}
                                                                        checked={sommaires.some(item => item.sommaire_id === sommaire.id)}
                                                                    />
                                                                    {/*end::Input*/}
                                                                    {/*begin::Label*/}
                                                                    <label className="form-check-label" htmlFor="kt_modal_move_to_folder_0">
                                                                        <div className="text-gray-800 fs-15">
                                                                            {`${sommaire.libelle} - ${sommaire.description}`}
                                                                        </div>
                                                                    </label>
                                                                    {/*end::Label*/}
                                                                </div>
                                                                {/*end::Checkbox*/}
                                                            </div>
                                                            {/*end::Item*/}
                                                            <div className="separator separator-dashed my-5"></div>
                                                        </>
                                                    ))}
                                                </div>
                                                {/*end::Item*/}
                                                <div className="fv-plugins-message-container invalid-feedback"></div>
                                            </div>)}
                                            {/*end::Input group*/}
                                            {/*begin::Action buttons*/}
                                            <div className=" card-footer d-flex flex-center mt-3">
                                                {/*begin::Button*/}
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    id="kt_modal_move_to_folder_submit"
                                                    onClick={() => setIsModalOpen(false)}
                                                >
                                                    Valider
                                                </button>
                                                {/*end::Button*/}
                                            </div>
                                            {/*begin::Action buttons*/}
                                        </div>
                                        {/*end::Modal body*/}
                                        <div></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ModalAlert>
        </>
    );
};