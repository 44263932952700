import axios from "axios"
import {
    AJOUTER_CLIENT_LEA, DOWNLOAD_FILE_CLIENT,
    LISTE_CLIENTS_LEA,
    LISTE_CLIENTS_LEA_ACTIFS,
    MODIFIER_CLIENT_LEA, MODIFIER_ETAT_CLIENT_LEA, SELECT_CLIENT, SELECTIONNER_EXERCICE_LEA, SUPPRIMER_CLIENT_LEA
} from "../../api/apiRoutes";
import {setAuthorizationHeader} from "../../api/config";
import {config} from "@fortawesome/fontawesome-svg-core";

// const DETAILS_CLIENT = `${process.env.REACT_APP_API_URL}/lea/clients/select-one-client`

export const getSelectOneClientDetails = async (client_lea_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${SELECT_CLIENT}/${client_lea_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeClients = async () => {
    setAuthorizationHeader()
    return axios
        .get(LISTE_CLIENTS_LEA)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const addClient = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(AJOUTER_CLIENT_LEA, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const ModifierClient = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(MODIFIER_CLIENT_LEA, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeActiveClients = async () => {
    setAuthorizationHeader()
    return axios
        .get(LISTE_CLIENTS_LEA_ACTIFS)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const deleteClient = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(SUPPRIMER_CLIENT_LEA, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const updateEtatClient = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(MODIFIER_ETAT_CLIENT_LEA, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}




export const getFile = async (clientBogName, clientName, missionAnnee, missionName, fichier) => {
    setAuthorizationHeader()
    return axios
        .get(
            `${DOWNLOAD_FILE_CLIENT}/${clientBogName}/${clientName}/${missionAnnee}/${missionName}/${fichier}`,
    {responseType: 'blob'},
        )
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}
