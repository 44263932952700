import React, {useEffect, useState} from "react";
import {getBalanceDefinitifDetails, getBalanceProvisoiresDetails} from "../../../../../../../data/balance/balance";
import { getListeExerciceClients} from "../../../../../../../data/exercice";
import {Button, Col, notification, Row, Table, Tooltip, Typography} from "antd";
import { SwapOutlined} from "@ant-design/icons";
import {appTheme} from "../../../../../../../config/theme";
import {faScaleBalanced} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableTitle from "../../../../../../common/TableTitle";
import {SearchHeader} from "../../../../../../layout/header/HeaderList";
import {ModalTitle} from "../../../../../../modals/modals.components";
import ModalAlert from "../../../../../../modals/Modal";

const { Text } = Typography;

const TraitementExamenAnalytiqueBilan = ({
         mission, 
         setBalanceData, 
         balanceData, 
         setIsTaskSave,
         chargement,
         isProvisoire, client
    }) => {
    const [missionN_1, setMissionN_1] = useState(null);
    const [balanceDefN_1, setBalanceDefN_1] = useState([]);
    const [resbalance, setResbalance] = useState([]);
    const [selectedBalances, setSelectedBalances] = useState([]);
    const [modalBalance, setModalBalance] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalId, setModalId] = useState(1);
    const [title, setTitle] = useState('');
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 1000,
        },
    });

    useEffect(() => {
        if(mission){
            const fetchBalanceProvisoireN = () => {
                setLoading(true)
                getBalanceProvisoiresDetails(mission.id)
                    .then((res) => {
                        if (res.results) {
                            setSelectedBalances(res.results.Lea_BalanceDetails);
                        } else {
                            setSelectedBalances([]);
                        }
                        setLoading(false)
                    })
            }

            const fetchBalanceDefinitif = () => {
                setLoading(true)
                getBalanceDefinitifDetails(mission.id)
                    .then((res) => {
                        if (res.results) {
                            setSelectedBalances(res.results.Lea_BalanceDetails);
                        } else {
                            setSelectedBalances([]);
                        }
                        setLoading(false)
                    })
            }

            fetchBalanceDefinitif();
            fetchBalanceProvisoireN();
        }
    }, [mission]);

    useEffect(() => {
        if (client) {
            getListeExerciceClients(client.id)
                .then((res) => {
                    const missN1 = res.find((opt) => parseInt(opt.annee) === parseInt(mission?.annee) - 1)
                    setMissionN_1(missN1)
                });
        }
    }, [client, mission])

    useEffect(() => {
        if(missionN_1){
            const fetchBalanceDefinitifN_1 = () => {
                setLoading(true)
                getBalanceDefinitifDetails(missionN_1.id)
                    .then((res) => {
                        if (res.results) {
                            setBalanceDefN_1(res.results.Lea_BalanceDetails);
                        } else {
                            setBalanceDefN_1([]);
                        }
                        setLoading(false)
                    })
            }

            fetchBalanceDefinitifN_1();
        }
    }, [missionN_1]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

    };

    const handleResultatClick = () => {
        setLoading(true);
        setTimeout(() => {
            if(balanceData && balanceData.length > 0){
                setBalanceData([]);
            }
            const bilanMap = new Map();

            selectedBalances.forEach((balance) => {
                const isExist = balanceDefN_1.find((item) => item.numeroCompte.includes(balance.numeroCompte));
                const soldeN = parseInt(balance.soldeDebit || 0) - parseInt(balance.soldeCredit || 0)
                const soldeN_1 = isExist ? (parseInt(isExist.soldeDebit || 0) - parseInt(isExist.soldeCredit || 0)) : 0;

                const newElement = {
                    compte: balance.numeroCompte,
                    libelle: balance.libelle,
                    soldeN,
                    soldeN_1,
                    commentaire: ""
                };

                bilanMap.set(balance.numeroCompte, newElement);
            });

            balanceDefN_1.forEach((balance) => {
                const isExist = selectedBalances.find((item) => item.numeroCompte.includes(balance.numeroCompte));

                const existingElement = bilanMap.get(balance.numeroCompte);
                if (existingElement) {
                    existingElement.soldeN_1 = parseInt(balance.soldeDebit || 0) - parseInt(balance.soldeCredit || 0);
                } else {
                    const newElement = {
                        compte: balance.numeroCompte,
                        libelle: balance.libelle,
                        soldeN: isExist ? (parseInt(isExist.soldeDebit || 0) - parseInt(isExist.soldeCredit || 0)) : 0,
                        soldeN_1: parseInt(balance.soldeDebit || 0) - parseInt(balance.soldeCredit || 0),
                        commentaire: ""
                    };

                    bilanMap.set(balance.numeroCompte, newElement);
                }
            });

            const updateBalance = [...bilanMap.values()];

            setBalanceData(updateBalance.sort((a, b) => a.compte.localeCompare(b.compte)));
            setResbalance(updateBalance.sort((a, b) => a.compte.localeCompare(b.compte)));

            setIsTaskSave(prevState => [...prevState, 2]);
            notification.warning({
                description: "Envoyé avec succès a l'historique Balances !",
            });

            setLoading(false);
        }, 1000)
    };


    const handleModalOpen = (id, balances, title) => {
        setModalId(id)
        setModalBalance(balances)
        setTitle(title)

        setIsModalOpen(prevState => !prevState)
    };


    const columnsModal = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Compte</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.numeroCompte}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.libelle}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Débit</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeDebit || 0).toLocaleString('fr')}
                </div>
            ),
        },

        {
            title: () => <TableTitle>Crédit</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeCredit || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },

        {
            title: () => <TableTitle>Solde</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {((record.soldeDebit || 0)-(record.soldeCredit || 0)).toLocaleString('fr') || 0}
                </div>
            ),
        },

    ];

    const columnResBalance = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Compte</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.compte}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.libelle}
                </span>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${mission.annee || "N"}`}</TableTitle>,
            dataIndex: 'soldeN',
            width:100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'soldeN_1',
            width:100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>N  -  N-1</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex: 'col1',
                    width:100,
                    render: (_, record) => (
                        <div
                            className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                            style={{fontSize: '14px'}}
                        >
                            {(record.soldeN - record.soldeN_1).toLocaleString('fr')}
                        </div>
                    ),
                },
                {
                    title: () => <TableTitle>% Variation</TableTitle>,
                    dataIndex: 'col1',
                    width: 70,
                    render: (_, record) => (
                        <div
                            className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                            style={{fontSize: '14px'}}
                        >
                            {( ( (record.soldeN_1)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                        </div>
                    ),
                },
            ],
        },
    ];

    return(
        <>
            <div className="py-5">
                <Row gutter={[16, 16]}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 20 }}
                        xl={{ span: 20 }}
                    >
                        <div className="d-flex flex-wrap align-items-center">
                            <div className="me-10">
                                <Tooltip title={`Balance ${parseInt(isProvisoire) === 1 ? 'provisoire' : 'définitif'} ${mission.annee || "N"}`}>
                                    <Button
                                        size={"large"}
                                        loading={loading}
                                        style={{
                                            backgroundColor: appTheme.colors.primary,
                                            color: "white"
                                        }}
                                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faScaleBalanced}/>}
                                        onClick={() => handleModalOpen(1, selectedBalances, `${parseInt(isProvisoire) === 1 ? 'provisoire' : 'définitif'}`)}
                                    >
                                        <span className='indicator-label text-white fs-6 fw-bolder'>{`Balance ${parseInt(isProvisoire) === 1 ? 'provisoire' : 'définitif'} ${mission.annee || "N"}`}</span>
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className="">
                                <Tooltip title={`Balance définitif ${parseInt(missionN_1?.annee) || 'N-1'}`}>
                                    <Button
                                        size={"large"}
                                        loading={loading}
                                        style={{
                                            backgroundColor: appTheme.colors.secondary,
                                            color: "white"
                                        }}
                                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faScaleBalanced}/>}
                                        onClick={() => handleModalOpen(2, balanceDefN_1, `${isProvisoire === 1 ? 'provisoire' : 'définitif'}`)}
                                    >
                                        <span className='indicator-label text-white'>{`Balance définitif ${parseInt(missionN_1?.annee) || "N-1"}`}</span>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <Tooltip title="Résultats">
                            <Button
                                disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                                size={"large"}
                                // loading={loading}
                                style={{
                                    backgroundColor: appTheme.colors.primary_blue,
                                    color: "white"
                                }}
                                icon={<SwapOutlined
                                    style={{fontSize: '20px', marginRight: '5px'}}/>}
                                onClick={handleResultatClick}
                            >
                                {!loading &&
                                    <span className='indicator-label text-white'>Résultats</span>}
                                {loading && (
                                    <span className='indicator-progress fs-9 text-white'
                                          style={{display: 'block'}}>
                                              Patientez svp...
                                              <span
                                                  className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                )}
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
                <div className="table-responsive">
                    {(resbalance ?? []).length > 0 && <div className="card-header border-0 pt-2">
                        <SearchHeader
                            placeholder="Rechercher par la référence, le libellé..."
                            // handleSearch={handleSearchChange}
                        />
                    </div>}
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                rowClassName={(record,) =>
                                    `${record.color ? `bg-${record?.color}` : ''} h-3px p-0 m-0 lh-0.5`
                                }
                                columns={columnResBalance}
                                rowKey={(record, index) => index}
                                dataSource={(resbalance ?? [])}
                                pagination={tableParams.pagination}
                                onChange={handleTableChange}
                                loading={chargement}
                                rowSelection
                                size={"small"}
                                summary={(pageData) => {
                                    let totalSoldeN = 0;
                                    let totalSoldeN_1 = 0;
                                    let totalVariation = 0;
                                    let totalPourcVariation = 0;
                                    pageData.forEach(({ soldeN, soldeN_1}) => {
                                        totalSoldeN += soldeN;
                                        totalSoldeN_1 += soldeN_1;
                                        totalVariation += soldeN - soldeN_1
                                        totalPourcVariation += (soldeN_1 === 0 ? 0 : (soldeN - soldeN_1) / soldeN_1  * 100)
                                    });
                                    return (
                                        <>
                                            <Table.Summary fixed>
                                                <Table.Summary.Row>
                                                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                    <Table.Summary.Cell index={2}>Total Extrait</Table.Summary.Cell>
                                                    <Table.Summary.Cell index={3} className='text-end'>
                                                        <Text type="success">{''}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={4} className='text-end'>
                                                        <Text type="success" >{totalSoldeN.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={5} className='text-end'>
                                                        <Text type="success">{totalSoldeN_1.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={6} className='text-end'>
                                                        <Text type="success">{totalVariation.toLocaleString('fr')}</Text>
                                                    </Table.Summary.Cell>
                                                    <Table.Summary.Cell index={7} className='text-end'>
                                                        <Text type="success">{totalPourcVariation.toLocaleString('fr')}%</Text>
                                                    </Table.Summary.Cell>
                                                </Table.Summary.Row>
                                            </Table.Summary>
                                        </>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ModalAlert
                closeModal={handleModalOpen}
                show={isModalOpen}
                title={<ModalTitle>Balance {title || ""} {modalId === 1 ? (mission.annee || "N") : (missionN_1.annee || "N-1")} </ModalTitle>}
                footer={[]}
                modalStyles={{ top: 30 }}
                width={1000}
            >
                <div className="py-4">
                    <div className="table-responsive">
                        <Table
                            rowClassName={(record,) =>
                                `${record.color ? `bg-${record?.color}` : ''} h-3px p-0 m-0 lh-0.5`
                            }
                            columns={columnsModal}
                            rowKey={(record, index) => index}
                            dataSource={modalBalance}
                            pagination={false}
                            scroll={{ y: 500 }}
                            loading={chargement}
                            rowSelection
                            size={"small"}
                            summary={(pageData) => {
                                let totalSoldeCredit = 0;
                                let totalSoldeDebit = 0;
                                let totalSoldeN = 0;
                                pageData.forEach(({ soldeDebit, soldeCredit}) => {
                                    totalSoldeDebit += soldeDebit;
                                    totalSoldeCredit += soldeCredit;
                                    totalSoldeN += soldeDebit - soldeCredit
                                });
                                return (
                                    <>
                                        <Table.Summary fixed>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                                <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                <Table.Summary.Cell index={2}>Total</Table.Summary.Cell>
                                                <Table.Summary.Cell index={3} className='text-end'>
                                                    <Text type="success">{""}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={4} className='text-end'>
                                                    <Text type="success" style={{fontSize: '12px'}}>{totalSoldeDebit.toLocaleString('fr')}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={5} className='text-end'>
                                                    <Text type="success" style={{fontSize: '12px'}} >{totalSoldeCredit.toLocaleString('fr')}</Text>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell index={6} className='text-end'>
                                                    <Text type="success" style={{fontSize: '12px'}}>{totalSoldeN}</Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </Table.Summary>
                                    </>
                                );
                            }}
                        />
                    </div>
                </div>
            </ModalAlert>
        </>
    )
}

export default TraitementExamenAnalytiqueBilan