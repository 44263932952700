import axios from "axios";

const AJOUTER_CONTROLE_FIN_MISSION = `${process.env.REACT_APP_API_URL}/lea/exercice/controle-mission/ajouter`
const LISTE_CONTROLE_FIN_MISSION = `${process.env.REACT_APP_API_URL}/lea/exercice/controle-mission`

export const ajouterControleFinMission = async (data) => {
    return axios.post(AJOUTER_CONTROLE_FIN_MISSION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeControleFinMission = async (exercice_id, codeSommaire) => {
    return axios.get(`${LISTE_CONTROLE_FIN_MISSION}/${exercice_id}/${codeSommaire}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}