import axios from "axios";
import {
    AJOUTER_STRATEGIE_USER_ETAT_FINANCIER,
    AJOUTER_USER_ETAT_FINANCIER, LISTE_STRATEGIE_USER_ETAT_FINANCIER, LISTE_USER_ETAT_FINANCIER,
} from "../../api/apiRoutes";

export const ajouterUserEtatFinancier = async (data) => {
    return axios.post(AJOUTER_USER_ETAT_FINANCIER, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeUserEtatFinancier = async () => {
    return axios.get(LISTE_USER_ETAT_FINANCIER).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const ajouterStrategieUserEtatFinancier = async (data) => {
    return axios.post(AJOUTER_STRATEGIE_USER_ETAT_FINANCIER, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeStrategieUserEtatFinancier = async (strategie_id) => {
    return axios.get(`${LISTE_STRATEGIE_USER_ETAT_FINANCIER}/${strategie_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}