import React, {useEffect, useState} from "react";
import {Button, Input, InputNumber, notification, Select, Table, Tooltip, Typography} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {
    ajouterStrategieGlobalSignification,
    getStrategieGlobaleSignification
} from "../../../../../../../data/stategieGlobale";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../../helpers/AssetsHelpers";
import {CustomInputNumber} from "../../../../../../layout/tools/InputNumber";
import {areArraysEqual} from "../../../../../../utilities/Utilities";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const {  Title } = Typography;

const Global = ({
        strategies,
        mission,
        setMaterialiteValue,
        isProvisoire,
        etatsFinanciers,
        codeSommaire,
        date,
        heureEntree,
        tache_id,
        intervention_id,
    }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectProvisoire, setSelectProvisoire] = useState([]);
    const [actifs, setActifs] = useState([]);
    const [passifs, setPassifs] = useState([]);
    const [resultats, setResultats] = useState([]);
    const [selectDefinitif, setSelectDefinitif] = useState([]);
    const [selectConclusion, setSelectConclusion] = useState([
        {
            libelle: 'Année N-2',
            montant: parseInt(strategies?.anneN2) || 0,
            commentaire: strategies?.commentaireN2 || ''
        },
        {
            libelle: 'Année N-1',
            montant: parseInt(strategies?.anneN1) || 0,
            commentaire: strategies?.commentaireN1 || ''
        },
        {
            libelle: 'Année N provisoire',
            montant: parseInt(strategies?.anneNProvC) || 0,
            commentaire: strategies?.commentaireNProvC || ''
        },
        {
            libelle: 'Année N définitive',
            montant: parseInt(strategies?.anneNDefC) || 0,
            commentaire: strategies?.commentaireNDefC || ''
        }
    ]);

    const [baseCacul, setBaseCalcul] = useState([
        {
            id: 1,
            nom: 'Résultat avant impôt',
            trancheMin: 3,
            trancheMax: 10,
            montant: 0,
            montantN1: 0,
            montantN2: 0,
            ref: ["XG", "XH"],
            isBrut: false,
            isNegatif: false
        },
        {
            id: 2,
            nom: 'Chiffre d\'affaire',
            trancheMin: 0.5,
            trancheMax: 2,
            montant: 0,
            montantN1: 0,
            montantN2: 0,
            ref: ["XB"],
            isBrut: false,
            isNegatif: false
        },
        {
            id: 3,
            nom: 'Taux charges',
            trancheMin: 0.5,
            trancheMax: 2,
            montant: 0,
            montantN1: 0,
            montantN2: 0,
            ref: ["RA", "RB", "TE", "RC", "RD", "RE", "RF", "RG", "RH", "RI", "RJ", "RK", "RL", "RM", "RN", "RO", "RP", "RQ", "RS"],
            isBrut: false,
            isNegatif: true
        },
        {
            id: 4,
            nom: 'Actif brut',
            trancheMin: 0.5,
            trancheMax: 2,
            montant: 0,
            montantN1: 0,
            montantN2: 0,
            ref: ["BZ"],
            isBrut: true,
            isNegatif: false
        },
        {
            id: 5,
            nom: 'Actif net',
            trancheMin: 1,
            trancheMax: 5,
            montant: 0,
            montantN1: 0,
            montantN2: 0,
            ref: ["BZ"],
            isBrut: false,
            isNegatif: false
        },
        {
            id: 6,
            nom: 'Autres',
            trancheMin: 0,
            trancheMax: 100,
            montant: 0,
            montantN1: 0,
            montantN2: 0,
            ref: [""]
        },
    ])
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
    });

    useEffect(()=> {
        setLoading(true)
        setTimeout(() => {
            if((etatsFinanciers ?? []).length > 0){
                const actifs = etatsFinanciers[0]?.Lea_BilanActifs?.filter((item) => item.montant !== 0 && item.montantN1 !== 0 && item.isProvisoire === (activeTab === 0))
                if(actifs && actifs.length > 0){
                    setActifs(actifs)
                    setLoading(false)
                }
                else{
                    setActifs([])
                    setLoading(false)
                }

                const passifs = etatsFinanciers[0]?.Lea_BilanPassifs?.filter((item) => item.montant !== 0 && item.montantN1 !== 0 && item.isProvisoire === (activeTab === 0))
                if(passifs && passifs.length > 0){
                    setPassifs(passifs);
                    setLoading(false);
                }
                else{
                    setPassifs([])
                    setLoading(false);
                }

                const results = etatsFinanciers[0]?.Lea_CompteResultats?.filter((item) => item.montant !== 0 && item.montantN1 !== 0 && item.isProvisoire === (activeTab === 0))
                if(results && results.length > 0){
                    setResultats(results)
                    setLoading(false);
                }
                else{
                    setResultats([]);
                    setLoading(false);
                }
            }
        }, 1000)
    }, [etatsFinanciers, activeTab])

    useEffect(() => {
        setMaterialiteValue(prevState => ({
            ...prevState,
            montantNProv: parseInt(strategies?.anneNProvC || 0),
            montantNDef: parseInt(strategies?.anneNDefC || 0),
            montantN1: parseInt(strategies?.anneN1 || 0),
            montantN2: parseInt(strategies?.anneN2 || 0),
        }));
    }, [strategies])

    useEffect(() => {
        if((selectProvisoire ?? []).length <= 0 && (selectDefinitif ?? []).length <= 0){
            const fetchData = async () => {
                setLoading(true);
                getStrategieGlobaleSignification(strategies.id)
                    .then((res) => {
                        if(res.result.length > 0) {
                            (res.result ?? []).forEach((item) => {
                                if(item.isProvisoire){
                                    // const isAlreadySelected = selectProvisoire.some((element) => element.baseCalcul.includes(item.baseCalcul));
                                    if((selectProvisoire.length === 0)){
                                        const value = {
                                            baseCalcul: item.baseCalcul || '',
                                            anneN: item.montant || 0,
                                            anneN1: item.montantN1 || 0,
                                            anneN2: item.montantN2 || 0,
                                            trancheMin: item.trancheMin || 0,
                                            trancheMax: item.trancheMax || 0,
                                            taux: item.tauxRetenu || 0,
                                            seuilMaterialite: item.seuilMaterialite || 0,
                                            commentaire: item.commentaire || '',
                                            isProvisoire: item.isProvisoire
                                        }
                                        setSelectProvisoire(prevState => [...prevState, value]);
                                        if(!strategies?.anneNProvC){
                                            // const updatedList = selectConclusion.map((select) =>
                                            //     (select.libelle === 'Année N provisoire') ? { ...select,  montant: (value.seuilMaterialite || 0), commentaire: ""} : select
                                            // );
                                            selectConclusion.find((item) => item.libelle === 'Année N provisoire').montant = item.seuilMaterialite || 0
                                            if(isProvisoire){
                                                selectConclusion.find((item) => item.libelle === 'Année N-1').montant = (item.montantN1 || 0) * (item.tauxRetenu || 0) / 100;
                                                selectConclusion.find((item) => item.libelle === 'Année N-2').montant = (item.montantN2 || 0) * (item.tauxRetenu || 0) / 100;

                                                setMaterialiteValue(prevState => ({
                                                    ...prevState,
                                                    montantN1: (item.montantN1 || 0) * (item.tauxRetenu || 0) / 100,
                                                    montantN2: (item.montantN2 || 0) * (item.tauxRetenu || 0) / 100,
                                                }));
                                            }

                                            setMaterialiteValue(prevState => ({
                                                ...prevState,
                                                montantNProv: item.seuilMaterialite || 0,
                                            }));
                                        }

                                    }
                                    setLoading(false);
                                }
                                else{
                                    // const isAlreadySelected = selectDefinitif.some((element) => element.baseCalcul.includes(item.baseCalcul));
                                    if((selectDefinitif.length === 0)){
                                        const value = {
                                            baseCalcul: item.baseCalcul || '',
                                            anneN: item.montant || 0,
                                            anneN1: item.montantN1 || 0,
                                            anneN2: item.montantN2 || 0,
                                            trancheMin: item.trancheMin || 0,
                                            trancheMax: item.trancheMax || 0,
                                            taux: item.tauxRetenu || 0,
                                            seuilMaterialite: item.seuilMaterialite || 0,
                                            commentaire: item.commentaire || '',
                                            isProvisoire: item.isProvisoire
                                        }
                                        setSelectDefinitif(prevState => [...prevState, value]);
                                        if(!strategies?.anneNDefC){
                                            selectConclusion.find((item) => item.libelle === 'Année N définitive').montant = item.seuilMaterialite || 0
                                            if(!isProvisoire){
                                                selectConclusion.find((item) => item.libelle === 'Année N-1').montant = (item.montantN1 || 0) * (item.tauxRetenu || 0) / 100;
                                                selectConclusion.find((item) => item.libelle === 'Année N-2').montant = (item.montantN2 || 0) * (item.tauxRetenu || 0) / 100;

                                                setMaterialiteValue(prevState => ({
                                                    ...prevState,
                                                    montantN1: (item.montantN1 || 0) * (item.tauxRetenu || 0) / 100,
                                                    montantN2: (item.montantN2 || 0) * (item.tauxRetenu || 0) / 100,
                                                }));
                                            }

                                            setMaterialiteValue(prevState => ({
                                                ...prevState,
                                                montantNDef: item.seuilMaterialite || 0,
                                            }));
                                        }
                                    }
                                    setLoading(false);
                                }
                            })
                        }
                        setLoading(false);
                    })
                    .catch((e) => {
                        // setError(true)
                        // setMessage(e.message)
                        setLoading(false);
                    })
            }
            if(strategies){
                fetchData();
            }
        }
    },[strategies, setMaterialiteValue, selectProvisoire, selectConclusion, selectDefinitif, isProvisoire]);

    useEffect(() => {
        const updatedArray = [...baseCacul];

        updatedArray.forEach((item) => {
            const postes = rechercheOccurrences(item.ref, item.isNegatif, actifs, passifs, resultats);
            if (postes && postes.length > 0) {
                const totalMontantN1 = postes.reduce((accumulator, opt) => accumulator + (opt.montantN1 || 0), 0);
                const totalMontant = postes.reduce((accumulator, opt) => accumulator + (opt.montant || 0), 0);
                const totalMontantBrut = postes.reduce((accumulator, opt) => accumulator + (opt.brut || 0), 0);

                const updatedList = baseCacul.map((base, ) =>
                    base.ref.some((key) => item.ref.includes(key)) ? {
                        ...base,
                        montant: base.isBrut ? totalMontantBrut : totalMontant,
                        montantN1: totalMontantN1
                    } : base
                )

                if (!areArraysEqual(updatedList, baseCacul)) {
                    setBaseCalcul(updatedList);
                }
            }
        });
    }, [baseCacul, actifs, passifs, resultats,])

    function rechercheOccurrences(occurrences, isNegatif, actif, passif, resultat,) {
         const tableaux = [...actif, ...passif, ...resultat]
        const results = tableaux.filter((tableau) => (isNegatif ? occurrences.includes(tableau.ref) && tableau.montant < 0 : occurrences.includes(tableau.ref)));

        return results.length > 0 ? results : null;
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // setEquipeClients([]);
        }
    };

    const handleTabChange = (tabName, e) => {
        e.preventDefault();
        setActiveTab(tabName);
    };
    const onChange = (value) => {
        if((selectProvisoire ?? []).length > 0){
            notification.error({
                description: "Impossible d'ajouter une nouvelle base de calcul"
            })
            return;
        }
        const base = baseCacul.find((item) => item.id === parseInt(value));

        const isAlreadySelected = selectProvisoire.some((element) => element.baseCalcul === base.nom);

        if (!isAlreadySelected) {
            const newElement = {
                baseCalcul: base.nom,
                anneN: base.montant,
                anneN1: base.montantN1,
                anneN2: base.montantN2 || 0,
                trancheMin: base.trancheMin,
                trancheMax: base.trancheMax,
                taux: 0,
                seuilMaterialite: 0,
                commentaire: '',
                isProvisoire: true
            };
            setSelectProvisoire([...selectProvisoire, newElement]);
        }
    };

    const onChangeDefinitive = (value) => {
        if((selectDefinitif ?? []).length > 0){
            notification.error({
                description: "Impossible d'ajouter une nouvelle base de calcul"
            })
            return;
        }

        const base = baseCacul.find((item) => item.id === parseInt(value));

        const isAlreadySelected = selectDefinitif.some((element) => element.baseCalcul === base.nom);
        if (!isAlreadySelected) {
            const newElement = {
                baseCalcul: base.nom,
                anneN: base.montant,
                anneN1: base.montantN1,
                anneN2: base.montantN2 || 0,
                trancheMin: base.trancheMin,
                trancheMax: base.trancheMax,
                taux: 0,
                seuilMaterialite: 0,
                commentaire: '',
                isProvisoire: false
            };
            setSelectDefinitif([...selectDefinitif, newElement]);
        }
    };

    const handleDeleteProvisoire = (index) => {
        const updatedList = selectProvisoire.filter((_, i) => i !== index);
        setSelectProvisoire(updatedList);
    };

    const handleDeleteDefinitif = (index) => {
        const updatedList = selectDefinitif.filter((_, i) => i !== index);
        setSelectDefinitif(updatedList);
    };

    const handleAnneeNProvisoire = (value, index) => {
        const updatedList = selectProvisoire.map((item, i) =>
            i === index ? { ...item,  anneN: parseInt(value), seuilMaterialite: parseInt(value) * parseInt(item.taux) / 100 } : item
        );

        setSelectProvisoire(updatedList);
        // selectConclusion.find((item) => item.libelle === 'Année N provisoire').montant = (selectProvisoire[index].seuilMaterialite || 0)
        const updatedConclusion = selectConclusion.map((item) =>
            (item.libelle === 'Année N provisoire') ? { ...item,  montant: (selectProvisoire[index].seuilMaterialite || 0)} : item
        );
        setSelectConclusion(updatedConclusion);

        setMaterialiteValue(prevState => ({
            ...prevState,
            montantNProv: selectProvisoire[index].seuilMaterialite || 0
        }));
    }

    const handleAnneeN1Provisoire = (value, index) => {
        const updatedList = selectProvisoire.map((item, i) =>
            i === index ? { ...item,  anneN1: parseInt(value)} : item
        );
        setSelectProvisoire(updatedList);

        if(isProvisoire){
            // selectConclusion.find((item) => item.libelle === 'Année N-1').montant = (parseInt(value) * parseInt(selectProvisoire[index].taux) / 100)
            const updatedConclusion = selectConclusion.map((item) =>
                (item.libelle === 'Année N-1') ? { ...item,  montant: (selectProvisoire[index].anneN1 || 0)} : item
            );
            setSelectConclusion(updatedConclusion);

            setMaterialiteValue(prevState => ({
                ...prevState,
                montantN1: (parseInt(value) * parseInt(selectProvisoire[index].taux) / 100) || 0
            }));
        }
    }

    const handleAnneeN2Provisoire = (value, index) => {
        const updatedList = selectProvisoire.map((item, i) =>
            i === index ? { ...item,  anneN2: parseInt(value)} : item
        );
        setSelectProvisoire(updatedList);

        if(isProvisoire){
            // selectConclusion.find((item) => item.libelle === 'Année N-2').montant = (parseInt(value) * parseInt(selectProvisoire[index].taux) / 100)
            const updatedConclusion = selectConclusion.map((item) =>
                (item.libelle === 'Année N-2') ? { ...item,  montant: (parseInt(value) * parseInt(selectProvisoire[index].taux) / 100) || 0} : item
            );
            setSelectConclusion(updatedConclusion);

            setMaterialiteValue(prevState => ({
                ...prevState,
                montantN2: (parseInt(value) * parseInt(selectProvisoire[index].taux) / 100) || 0
            }));
        }
    }

    // const handleProvisoireTauxInput = (value, index) => {
    //     const updatedList = selectProvisoire.map((item, i) =>
    //         i === index ? { ...item,  taux: parseInt(value), seuilMaterialite: parseInt(value) * parseInt(item.anneN) / 100} : item
    //     );
    //
    //     setSelectProvisoire(updatedList);
    // }

    const handleAnneeNDefinitif = (value, index) =>
     {
        const updatedList = selectDefinitif.map((item, i) =>
            i === index ? { ...item,  anneN: parseInt(value), seuilMaterialite: parseInt(value) * parseInt(item.taux) / 100 } : item
        );
        setSelectDefinitif(updatedList);

         // selectConclusion.find((item) => item.libelle === 'Année N définitive').montant = (parseInt(value) * parseInt(selectDefinitif[index].taux) / 100)
        const updatedConclusion = selectConclusion.map((item) =>
            (item.libelle === 'Année N définitive') ? { ...item,  montant: (selectDefinitif[index].seuilMaterialite || 0)} : item
        );
        setSelectConclusion(updatedConclusion);

         setMaterialiteValue(prevState => ({
             ...prevState,
             montantNDef: selectDefinitif[index].seuilMaterialite || 0
         }));
    }

    const handleAnneeN1Definitif = (value, index) => {
        const updatedList = selectDefinitif.map((item, i) =>
            i === index ? { ...item,  anneN1: parseInt(value)} : item
        );
        setSelectDefinitif(updatedList);

        if(!isProvisoire){
            // selectConclusion.find((item) => item.libelle === 'Année N-1').montant = (parseInt(value) * parseInt(selectDefinitif[index].taux) / 100)
            const updatedConclusion = selectConclusion.map((item) =>
                (item.libelle === 'Année N-1') ? { ...item,  montant: ((parseInt(value) * parseInt(selectDefinitif[index].taux) / 100))} : item
            );
            setSelectConclusion(updatedConclusion);

            setMaterialiteValue(prevState => ({
                ...prevState,
                montantN1: (parseInt(value) * parseInt(selectDefinitif[index].taux) / 100) || 0
            }));
        }
    }

    const handleAnneeN2Definitif = (value, index) => {
        const updatedList = selectDefinitif.map((item, i) =>
            i === index ? { ...item,  anneN2: parseInt(value)} : item
        );
        setSelectDefinitif(updatedList);

        if(!isProvisoire){
            // selectConclusion.find((item) => item.libelle === 'Année N-2').montant = (parseInt(value) * parseInt(selectDefinitif[index].taux) / 100)
            const updatedConclusion = selectConclusion.map((item) =>
                (item.libelle === 'Année N-2') ? { ...item,  montant: ((parseInt(value) * parseInt(selectDefinitif[index].taux) / 100))} : item
            );
            setSelectConclusion(updatedConclusion);

            setMaterialiteValue(prevState => ({
                ...prevState,
                montantN2: (parseInt(value) * parseInt(selectDefinitif[index].taux) / 100) || 0
            }));
        }
    }

    // const handleDefinitifTauxInput = (value, index) => {
    //     const updatedList = selectDefinitif.map((item, i) =>
    //         i === index ? { ...item,  taux: parseInt(value), seuilMaterialite: parseInt(value) * parseInt(item.anneN) / 100} : item
    //     );
    //
    //     setSelectDefinitif(updatedList);
    // }

    const handleConclusionMontant = (value, index) => {
        const indexUpdate = selectConclusion.find((item, i) => i === index)
        const updatedList = selectConclusion.map((item, i) =>
            i === index ? { ...item, montant: parseInt(value) } : item
        );
        setSelectConclusion(updatedList);

        if(indexUpdate.libelle === "Année N provisoire"){
            setMaterialiteValue(prevState => ({
                ...prevState,
                montantNProv: parseInt(value) || 0
            }));
        }
        else if(indexUpdate.libelle === "Année N définitive"){
            setMaterialiteValue(prevState => ({
                ...prevState,
                montantNDef: parseInt(value) || 0
            }));
        }
        else if(indexUpdate.libelle === "Année N-1"){
            setMaterialiteValue(prevState => ({
                ...prevState,
                montantN1: parseInt(value) || 0
            }));
        }
        else if(indexUpdate.libelle === "Année N-2"){
            setMaterialiteValue(prevState => ({
                ...prevState,
                montantN2: parseInt(value) || 0
            }));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            globalTableaux:[...selectProvisoire, ...selectDefinitif],
            // globalTableaux: isProvisoire === true ? selectProvisoire : selectDefinitif,
            strategie_id: strategies?.id,
            exercice_id: mission.id,
            client_id: mission.client_id,
            anneNProvC: selectConclusion.find((item) => item.libelle === 'Année N provisoire').montant,
            commentaireNProvC: selectConclusion.find((item) => item.libelle === 'Année N provisoire').commentaire,
            anneNDefC: selectConclusion.find((item) => item.libelle === 'Année N définitive').montant,
            commentaireNDefC: selectConclusion.find((item) => item.libelle === 'Année N définitive').commentaire,
            anneN1: selectConclusion.find((item) => item.libelle === 'Année N-1').montant,
            commentaireN1: selectConclusion.find((item) => item.libelle === 'Année N-1').commentaire,
            anneN2: selectConclusion.find((item) => item.libelle === 'Année N-2').montant,
            commentaireN2: selectConclusion.find((item) => item.libelle === 'Année N-2').commentaire,
            codeSommaire ,
            heureEntree,
            date,
            tache_id,
            intervention_id,
            isProvisoire,
            isProvisoireTab: activeTab === 0
        };

        const { status, message } = await ajouterStrategieGlobalSignification(data);
        if (status === "success") {
            setLoading(false);
            await swal(succesMessage, "", "success");
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    };

    const columnsProvisoire = [
        {
            title: () => <TableTitle>Base de calcul</TableTitle>,
            dataIndex: 'baseCalcul',
            sorter: true,
            width: 150,
            render: (_, record) => (
                <div className='fw-bolder fs-4'>
                    {record.baseCalcul}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Année N</TableTitle>,
            dataIndex: 'anneN',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.anneN}
                    name='anneeN'
                    onChange={handleAnneeNProvisoire}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N-1</TableTitle>,
            dataIndex: 'anneN1',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.anneN1}
                    name='anneN1'
                    onChange={handleAnneeN1Provisoire}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N-2</TableTitle>,
            dataIndex: 'anneN2',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.anneN2}
                    name='anneN2'
                    onChange={handleAnneeN2Provisoire}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Tranche (%)</TableTitle>,
            dataIndex: 'tranche',
            width: 100,
            render: (_, record) => (
                <>
                    <span className="fs-4 fw-bolder">
                        {`${record.trancheMin ? record.trancheMin : 0} à ${record.trancheMax ? record.trancheMax : 0}`}
                    </span>
                </>
            ),
        },
        {
            title: () => <TableTitle>Taux de Retenu (%)</TableTitle>,
            dataIndex: 'taux',
            width: 150,
            render: (_, record, index) => (
                <>
                    <InputNumber
                        min={record.trancheMin}
                        max={record.trancheMax}
                        step="0.01"
                        value={record.taux}
                        onChange={(value) => {
                            const updatedList = selectProvisoire.map((item, i) =>
                                i === index ? { ...item,  taux: value, seuilMaterialite: value * parseInt(item.anneN) / 100} : item
                            );

                            setSelectProvisoire(updatedList);

                            const updatedConclusion = selectConclusion.map((item) =>
                                (item.libelle.includes("Année N provisoire")) ? { ...item,  montant: (value * record.anneN) / 100} : item
                            );
                            setSelectConclusion(updatedConclusion);

                            setMaterialiteValue(prevState => ({
                                ...prevState,
                                montantNProv: (value * record.anneN) / 100
                            }));

                            if(isProvisoire){
                                selectConclusion.find((item) => item.libelle === 'Année N-1').montant = (value * record.anneN1) / 100;
                                selectConclusion.find((item) => item.libelle === 'Année N-2').montant = (value * record.anneN2) / 100;

                                setMaterialiteValue(prevState => ({
                                    ...prevState,
                                    montantN1: (value * parseInt(record.anneN1) / 100) || 0,
                                    montantN2: (value * parseInt(record.anneN2) / 100) || 0
                                }));
                            }
                        }}
                    />
                </>
            ),
        },
        {
            title: () => <TableTitle>Seuil de matérialité</TableTitle>,
            dataIndex: 'seuilMaterialite',
            width: 150,
            render: (_, record) => (
                <>
                    <div className='text-end fw-bolder fs-4'>
                        {record.seuilMaterialite.toLocaleString('fr')}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 300,
            render: (_, record, index) => (
                <>
                    <div className="">
                        <Input.TextArea
                            style={{fontSize: "14px", fontWeight: "600"}}
                            size="large"
                            bordered={false}
                            placeholder="Saisisser le commentaire..."
                            value={record.commentaire}
                            maxLength={2000}
                            showCount
                            autoSize={{
                                minRows: 2,
                                maxRows: 8,
                            }}
                            onChange={(e) =>{
                                const updatedList = selectProvisoire.map((item, i) =>
                                    i === index ? { ...item,  commentaire: e.target.value } : item
                                );
                                setSelectProvisoire(updatedList);
                            }}
                        />
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 70,
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteProvisoire(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    const columnsConclusion = [
        {
            title: () => <TableTitle>Conclusion</TableTitle>,
            dataIndex: 'conclusion',
            sorter: true,
            width: 300,
            render: (_, record) => (
                <div className='d-flex align-center fs-4 fw-bolder'>
                    {record.libelle}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Montant</TableTitle>,
            dataIndex: 'montant',
            width: 200,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.montant}
                    name='montant'
                    onChange={handleConclusionMontant}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            render: (_, record, index) => (
                <>
                    <Input.TextArea
                        style={{fontSize: "14px", fontWeight: "600"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le commentaire..."
                        value={record.commentaire}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 8,
                        }}
                        onChange={(e) =>{
                            const updatedList = selectConclusion.map((item, i) =>
                                i === index ? { ...item,  commentaire: e.target.value } : item
                            );

                            setSelectConclusion(updatedList);
                        }}
                    />
                </>
            ),
        },
    ];

    const columnsDefinitive = [
        {
            title: () => <TableTitle>Base de calcul</TableTitle>,
            dataIndex: 'baseCalcul',
            sorter: true,
            width: 150,
            render: (_, record) => (
                <div className='fw-bolder fs-4'>
                    {record.baseCalcul}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Année N</TableTitle>,
            dataIndex: 'anneN',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.anneN}
                    name='anneeN'
                    onChange={handleAnneeNDefinitif}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N-1</TableTitle>,
            dataIndex: 'anneN1',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.anneN1}
                    name='anneN1'
                    onChange={handleAnneeN1Definitif}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N-2</TableTitle>,
            dataIndex: 'anneN2',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.anneN2}
                    name='anneN2'
                    onChange={handleAnneeN2Definitif}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Tranche (%)</TableTitle>,
            dataIndex: 'tranche',
            width: 100,
            render: (_, record) => (
                <>
                    <span className="fs-4 fw-bolder">
                        {`${record.trancheMin && record.trancheMin} à ${record.trancheMax && record.trancheMax}`}
                    </span>
                </>
            ),
        },
        {
            title: () => <TableTitle>Taux de Retenu (%)</TableTitle>,
            dataIndex: 'taux',
            width: 150,
            render: (_, record, index) => (
                <>
                    <InputNumber
                        min={record.trancheMin}
                        max={record.trancheMax}
                        step="0.01"
                        value={record.taux}
                        onChange={(value) => {
                            const updatedList = selectDefinitif.map((item, i) =>
                                i === index ? { ...item,  taux: value, seuilMaterialite: value * parseInt(item.anneN) / 100} : item
                            );
                            setSelectDefinitif(updatedList);

                            const updatedConclusion = selectConclusion.map((item) =>
                                (item.libelle.includes("Année N définitive")) ? { ...item,  montant: (value * record.anneN) / 100} : item
                            );
                            setSelectConclusion(updatedConclusion);

                            setMaterialiteValue(prevState => ({
                                ...prevState,
                                montantNDef: (value * record.anneN) / 100
                            }));

                            if(!isProvisoire){
                                selectConclusion.find((item) => item.libelle === 'Année N-1').montant = (value * record.anneN1) / 100;
                                selectConclusion.find((item) => item.libelle === 'Année N-2').montant = (value * record.anneN2) / 100;

                                setMaterialiteValue(prevState => ({
                                    ...prevState,
                                    montantN1: (value * parseInt(record.anneN1) / 100) || 0,
                                    montantN2: (value * parseInt(record.anneN2) / 100) || 0
                                }));
                            }
                        }}
                    />
                </>
            ),
        },
        {
            title: () => <TableTitle>Seuil de matérialité</TableTitle>,
            dataIndex: 'seuilMaterialite',
            width: 150,
            render: (_, record) => (
                <>
                    <div className='text-end fw-bolder fs-4'>
                        {record.seuilMaterialite.toLocaleString('fr')}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 250,
            render: (_, record, index) => (
                <>
                    <div className="">
                        <Input.TextArea
                            style={{fontSize: "14px", fontWeight: "600"}}
                            size="large"
                            bordered={false}
                            placeholder="Saisisser le commentaire..."
                            value={record.commentaire}
                            maxLength={2000}
                            showCount
                            autoSize={{
                                minRows: 2,
                                maxRows: 8,
                            }}
                            onChange={(e) =>{
                                const updatedList = selectDefinitif.map((item, i) =>
                                    i === index ? { ...item,  commentaire: e.target.value } : item
                                );
                                setSelectDefinitif(updatedList);
                            }}
                        />
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteDefinitif(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    const onSearch = (value) => {
        console.log('search:', value);
    };

    return(
        <>
            <div className="pb-10">
                <div className="d-flex justify-content-end align-items-end mb-10">
                    <Tooltip title="Enregistrer">
                        <Button
                            disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            size={"large"}
                            // loading={loading}
                            style={{
                                backgroundColor: appTheme.colors.secondary,
                                color: "white"
                            }}
                            icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                            onClick={handleSubmit}
                        >
                            {!loading &&
                                <span className='indicator-label text-white'>Enregistrer</span>}
                            {loading && (
                                <span className='indicator-progress fs-9 text-white'
                                      style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                    </Tooltip>
                </div>
                <div className="card-header">
                    <div className="card-title">
                        <Title style={{
                            display: "block",
                            fontWeight: '600',
                            color: appTheme.colors.primary_blue,
                            // textAlign: "center"
                        }} level={4}>{"A. Global"}</Title>
                    </div>
                </div>
                <div className="">
                    <div className="">
                        {/* begin::Tabs */}
                        <div className='d-flex justify-content-between align-center'>
                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-4">
                                {/* begin::Tab item */}
                                <li className="nav-item bg-hover-light-primary">
                                    <Tooltip title='Provisoire'>
                                        <button
                                            className={`nav-link text-active-primary fs-4 text-uppercase fw-bolder pb-4 ${activeTab === 0 ? 'active' : ''}`}
                                            onClick={(e) => handleTabChange(0, e)}
                                        >
                                            Provisoire
                                        </button>
                                    </Tooltip>
                                </li>
                                {/* end::Tab item */}
                                {/* begin::Tab item */}
                                <li className="nav-item bg-hover-light-primary">
                                    <Tooltip title='Définitive'>
                                        <button
                                            className={`nav-link text-active-primary text-uppercase fw-bolder fs-4 pb-4 ${activeTab === 1 ? 'active' : ''}`}
                                            onClick={(e) => handleTabChange(1, e)}
                                        >
                                            Définitive
                                        </button>
                                    </Tooltip>
                                </li>
                                {/* end::Tab item */}
                                {/* begin::Tab item */}
                                <li className="nav-item bg-hover-light-primary">
                                    <Tooltip title='Conclusion'>
                                        <button
                                            className={`nav-link text-active-primary text-uppercase fw-bolder fs-4 pb-4 ${activeTab === 2 ? 'active' : ''}`}
                                            onClick={(e) => handleTabChange(2, e)}
                                        >
                                            Conclusion
                                        </button>
                                    </Tooltip>
                                </li>
                                {/* end::Tab item */}
                            </ul>
                        </div>
                        {/* end::Tabs */}
                        <div className="separator"></div>
                        {/* begin::Tab content */}
                        <form className="stepper-form">
                            <div className="fs-6 fw-bold text-gray-600 my-10 fs-4 mx-auto" style={{fontSize: '12px'}}>
                                Faites preuve de jugement professionnel en ce qui concerne le plus grand nombre d'anomalies pouvant être incluses dans les états financiers sans
                                <br/>  affecter les decisions économiques prises par un utilisateur d'états financiers (ISA 320.10, ISA 320.14)
                            </div>
                            <div className="tab-content" id="myTabContent">
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`} role="tabpanel">
                                    <div className="table-responsive">
                                        <div className="d-flex flex-column me-5 mb-3">
                                            <Select
                                                style={{ width: 500, fontSize: '14px' }}
                                                placeholder="Critère relatif à "
                                                onChange={onChange}
                                                className="me-15"
                                                showSearch
                                                size="large"
                                                optionFilterProp="children"
                                                onSearch={onSearch}
                                                filterOption={(input, option) =>
                                                    (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={baseCacul && baseCacul.map((item) => ({ label: `${item.nom} - ${item.trancheMin} à ${item.trancheMax}`, value: item.id }))}
                                            />
                                        </div>
                                        <div className="table-responsive pt-5 pb-10">
                                            <Table
                                                rowClassName={() =>
                                                    `h-3px p-0 m-0 lh-0.5`
                                                }
                                                columns={columnsProvisoire}
                                                rowKey={(record, index) => index}
                                                dataSource={selectProvisoire ?? []}
                                                // scroll={{y: 800}}
                                                loading={loading}
                                                pagination={tableParams.pagination}
                                                onChange={handleTableChange}
                                                rowSelection
                                                size={"small"}
                                            />
                                        </div>
                                    </div>
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} role="tabpanel">
                                    {/* begin::Products */}
                                    <div className="table-responsive">
                                        <div className="d-flex flex-column me-5 mb-3">
                                            <Select
                                                style={{ width: 500, fontSize: '14px' }}
                                                placeholder="Critère relatif à "
                                                onChange={onChangeDefinitive}
                                                className="me-15"
                                                showSearch
                                                size="large"
                                                optionFilterProp="children"
                                                onSearch={onSearch}
                                                filterOption={(input, option) =>
                                                    (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={baseCacul && baseCacul.map((item) => ({ label: `${item.nom} - ${item.trancheMin} à ${item.trancheMax}`, value: item.id }))}
                                            />
                                        </div>
                                        <div className="table-responsive pt-5 pb-10">
                                            <Table
                                                rowClassName={() =>
                                                    `h-3px p-0 m-0 lh-0.5`
                                                }
                                                columns={columnsDefinitive}
                                                rowKey={(record, index) => index}
                                                dataSource={selectDefinitif ?? []}
                                                // scroll={{y: 800}}
                                                loading={loading}
                                                pagination={tableParams.pagination}
                                                onChange={handleTableChange}
                                                rowSelection
                                                size={"small"}
                                            />
                                        </div>
                                    </div>
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`} id="kt_balance_settings_balance" role="tabpanel">
                                    {/* begin::Products */}
                                    <div className="table-responsive pt-5 pb-10">
                                        <Table
                                            rowClassName={() =>
                                                `h-3px p-0 m-0 lh-0.5`
                                            }
                                            columns={columnsConclusion}
                                            rowKey={(record, index) => index}
                                            dataSource={selectConclusion ?? []}
                                            // scroll={{y: 800}}
                                            loading={loading}
                                            pagination={tableParams.pagination}
                                            onChange={handleTableChange}
                                            rowSelection
                                            size={"small"}
                                        />
                                    </div>
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                            </div>
                        </form>
                        {/* end::Tab content */}
                    </div>

                </div>
            </div>
        </>
    )
}
export {Global}