import React from "react";
import {Menu, Dropdown, Space} from 'antd';
import {
    BarsOutlined, FileExcelFilled,
    FileImageOutlined,
    FileOutlined,
    FilePdfOutlined,
    FileTextFilled,
    FileWordFilled
} from "@ant-design/icons";

const CustomMenu = ({menuItems }) => {

    const onClick = ({ key }) => {
        menuItems[key].onClick();
    };

    const menu = (
        <Menu onClick={onClick}>
            {menuItems.map((item, index) => (
                <Menu.Item key={index} icon={item.icon}>
                    {item.label}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        // Overlay a modifier, depreciate with children option
        <Dropdown overlay={ menu } trigger={['click']}>
            <a onClick={(e) => e.preventDefault()}>
                <Space>
                    <BarsOutlined style={{ fontSize: '20px'}}/>
                </Space>
            </a>
        </Dropdown>
    );
};

const getIconForFileType = (filename) => {
    const extension = filename.split('.').pop().toLowerCase()
    switch (extension) {
        case 'pdf':
            return <FilePdfOutlined style={{fontSize: '25px', color: 'red'}} />
        case 'docx':
        case 'doc':
        case 'docm':
            return <FileWordFilled style={{fontSize: '25px', color: 'blue'}} />
        case 'xlsx':
        case 'xlsm':
            return <FileExcelFilled style={{fontSize: '25px', color: 'green'}} />
        case 'txt':
            return <FileTextFilled style={{fontSize: '25px', color: ''}} />
        case 'png':
        case 'jpg':
        case 'jpeg':
            return <FileImageOutlined style={{fontSize: '25px', color: 'blue'}} />
        default:
            return <FileOutlined style={{fontSize: '25px', color: 'blue'}} />
    }
}

function getFilenameWithoutExtension(file) {
    // Trouver la dernière occurrence du point (.)
    const dotIndex = file.lastIndexOf('.');

    // Si un point est trouvé et il n'est pas au début du nom
    if (dotIndex > 0) {
        // Extraire le nom sans l'extension
        return file.substring(0, dotIndex);
    }

    // Si aucun point n'est trouvé, retourner le nom tel quel
    return file;
}

export {getIconForFileType, CustomMenu, getFilenameWithoutExtension}