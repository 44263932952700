import {HStack} from "@chakra-ui/react";
import PageLabel from "../../../components/common/PageLabel/PageLabel";
import { useLocation} from "react-router-dom";
import { Tag, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBriefcase,
    faEnvelopeCircleCheck,
    faFolderTree,
    faLandmark, faPhone,
} from "@fortawesome/free-solid-svg-icons";

import {FormContainer, TagItem} from "../../../components/common/common.components";
import React, {Fragment, useState} from "react";
import styled, {useTheme} from "styled-components";
// import {useSelector} from "react-redux";
import {toAbsoluteUrl} from "../../../helpers/AssetsHelpers";
import clsx from "clsx";
import {appTheme} from "../../../config/theme";
import {faListSquares} from "@fortawesome/free-solid-svg-icons/faListSquares";
import DossierPermanentMenu from "../../../components/client/modals/missionSteps/EA/DossierPemanentMenu";
import FileManagerClient from "../../exercices/exercice/FileManagerClient";
import FileManagerExercice from "../../exercices/exercice/FileManagerExercice";

export const FicheClientMenu = styled("div")`
  transition: all 0.3s ease-in-out;
  background: #ffffff20;
  border-radius: 5px;
  margin-right: 20px;
  font-size: 15px;
  font-family: "Oswald", sans-serif;
  text-decoration: none;
  color: #000;
  

  &:active {
    transform: scale(0.99);
  }
`;

const ClientExploitationFiche = () => {
    const theme = useTheme()
    const location = useLocation()
    // const user = useSelector(state => state.user)
    // const isAdmin = user.libelleProfil === "Admin"
    const client = location.state.record;

    const [activeTab, setActiveTab] = useState(1)
    const [isMissionModalOpen, setIsMissionModalOpen] = useState(false)
    const [annee, setAnnee] = useState(false)
    const [missionLength, setMissionLength] = useState(0)

    const handleTabChange = (tabIndex, e) => {
        e.preventDefault()
        setActiveTab(tabIndex);
        setIsMissionModalOpen(false)
    };

    const steps = [
        {
            id: 1,
            title: 'Dossier Permanent',
            description: 'Dossier permanent du client',
            content: (
                <DossierPermanentMenu
                    modalClient={client}
                />
            ),
        },
        {
            id: 2,
            title: 'Exercice',
            description: 'Liste des exercice du client',
            content: (
                !isMissionModalOpen ?
                <FileManagerClient
                    client={client}
                    setIsMissionModalOpen={setIsMissionModalOpen}
                    setAnnee={setAnnee}
                    setMissionLength={setMissionLength}
                />
                :
                <FileManagerExercice
                    client={client}
                    year={annee}
                    setIsMissionModalOpen={setIsMissionModalOpen}
                    setMissionLength={setMissionLength}
                />
            ),
        },
    ];

    return (
        <>
            <Fragment>
                <HStack justifyContent="space-between" alignItems="center">
                    <PageLabel label={`Fiche exploitation ${client.denomination} - ${client.sigle}`}/>

                    {/*{isAdmin && <Link to={`/clients/ajout`}>*/}
                    {/*    <Tag*/}
                    {/*        className="fs-6 fw-bolder py-1 px-7"*/}
                    {/*        icon={<FontAwesomeIcon style={{marginRight: "5px"}} icon={faFileEdit} />}*/}
                    {/*        color={theme.colors.primary}*/}
                    {/*    >*/}
                    {/*        Nouvelle mission*/}
                    {/*    </Tag>*/}
                    {/*</Link>}*/}
                </HStack>

                <FicheClientMenu>
                    <div className="card mb-5 mb-xl-10">
                        <div className="card-body pt-9 pb-0">
                            <div className="d-flex flex-wrap flex-sm-nowrap">
                                <div className="me-7 mb-4">
                                    <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                                        {client.logo_client ? (
                                            <div className='symbol-label'>
                                                <img
                                                    src={toAbsoluteUrl(`/media/${client.logo_client}`)}
                                                    alt={client.denomination}
                                                    className='w-100'
                                                />
                                            </div>
                                        ) : (
                                            <div className={clsx('symbol-label', `bg-green`)} style={{backgroundColor: appTheme.colors.bgColor, fontSize: "40px"}}>
                                                {client.denomination[0].toUpperCase() + '' + client.denomination[1].toUpperCase()}
                                            </div>
                                        )}
                                        <div
                                            className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
                                    </div>
                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex align-items-center mb-2">
                                                <span className="fw-bolder text-uppercase" style={{fontFamily: "Oswald, sans-serif", fontSize: "20px"}}>{client.denomination}</span>
                                                <span className="mx-2"></span>
                                                {client.sigle && <TagItem color={appTheme.colors.primary}>{client.sigle}</TagItem>}
                                            </div>
                                            <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                                {client.libelle_status && <div className="me-5 mb-2">
                                                    <TagItem><FontAwesomeIcon icon={faBriefcase}
                                                                              style={{marginRight: "5px"}}/>{client.libelle_status}
                                                    </TagItem>
                                                </div>}
                                                {client.libelle_secteur && <div className="me-5 mb-2">
                                                    <TagItem><FontAwesomeIcon icon={faLandmark}
                                                                              style={{marginRight: "5px"}}/>{client.libelle_secteur}
                                                    </TagItem>
                                                </div>}
                                                {client.email && <div className="me-5 mb-2">
                                                    <TagItem><FontAwesomeIcon icon={faEnvelopeCircleCheck}
                                                                              style={{marginRight: "5px"}}/>{client.email}
                                                    </TagItem>
                                                </div>}
                                                {client.cel1 && <div className="me-5 mb-2">
                                                    <TagItem>
                                                        <FontAwesomeIcon icon={faPhone} style={{marginRight: "5px"}}/>{client.cel1}
                                                        {client.cel2 && <> <span className="mx-1">/</span>
                                                        <FontAwesomeIcon icon={faPhone} style={{marginRight: "5px"}}/></>}{client.cel2}
                                                    </TagItem>
                                                </div>}

                                            </div>
                                        </div>
                                        {/*<div className="d-flex my-4">*/}
                                        {/*    <a href="#" className="btn btn-sm btn-light me-2" id="kt_user_follow_button">*/}
                                        {/*        <i className="ki-duotone ki-check fs-3 d-none"></i>*/}
                                        {/*        <span className="indicator-label">*/}
                                        {/*Follow</span>*/}
                                        {/*        <span className="indicator-progress">*/}
                                        {/*    Please wait...    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>*/}
                                        {/*</span>*/}
                                        {/*    </a>*/}

                                        {/*    <a href="#" className="btn btn-sm btn-primary me-3" data-bs-toggle="modal"*/}
                                        {/*       data-bs-target="#kt_modal_offer_a_deal">Hire Me</a>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="d-flex flex-wrap flex-stack">
                                        <div className="d-flex flex-column flex-grow-1 pe-8">
                                            <div className="d-flex flex-wrap">
                                                {/*<div*/}
                                                {/*    className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">*/}
                                                {/*    <div className="d-flex align-items-center">*/}
                                                {/*        <Tag icon={<FontAwesomeIcon icon={faFolderTree} />} color={theme.colors.primary_blue} />*/}
                                                {/*        <div className="fs-2 fw-bold counted" data-kt-countup="true"*/}
                                                {/*             data-kt-countup-value="4500" data-kt-countup-prefix="$"*/}
                                                {/*             data-kt-initialized="1">$4,500*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}

                                                {/*    <div className="fw-semibold fs-6 text-gray-500">Earnings</div>*/}
                                                {/*</div>*/}
                                                <div
                                                    className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <Tag icon={<FontAwesomeIcon icon={faFolderTree} />} color={theme.colors.primary_blue} />
                                                        <div className="fs-2 fw-bold counted" data-kt-countup="true"
                                                             data-kt-countup-value="80" data-kt-initialized="1">{missionLength}
                                                        </div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-500">Missions</div>
                                                </div>
                                                <div
                                                    className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <Tag icon={<FontAwesomeIcon icon={faListSquares} />} color={theme.colors.primary_blue} />
                                                        <div className="fs-2 fw-bold counted" data-kt-countup="true"
                                                             data-kt-countup-value="60" data-kt-countup-prefix="%"
                                                             data-kt-initialized="1">120
                                                        </div>
                                                    </div>
                                                    <div className="fw-semibold fs-6 text-gray-500">Interventions</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                                            <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                                <span className="fw-semibold fs-6 text-gray-500">Evolution des mission</span>
                                                <span className="fw-bold fs-6">50%</span>
                                            </div>

                                            <div className="h-5px mx-3 w-100 bg-light mb-3">
                                                <div className="bg-success rounded h-5px" role="progressbar"
                                                     aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                                {steps.map((step, index) => (
                                    <li key={index} className="nav-item">
                                        <Tooltip title={step.description}>
                                            <button
                                                data-bs-toggle="tab"
                                                className={`nav-link fs-8 btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0 ${activeTab === index ? 'active' : ''}`}
                                                onClick={(e) => handleTabChange(index, e)}
                                            >
                                                {step.title}
                                            </button>
                                        </Tooltip>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </FicheClientMenu>

                <FormContainer>
                    <div className="tab-content" id="myTabContent">
                        {steps.map((item, index) => (
                            <div
                                key={index}
                                className={`tab-pane fade ${activeTab === index ? 'show active' : ''}`}
                                id={`tab-${index}`}
                                role="tabpanel"
                            >
                                {steps[activeTab].content}
                            </div>
                        ))}
                    </div>
                </FormContainer>
            </Fragment>
        </>
    )
}

export default ClientExploitationFiche