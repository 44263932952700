import React from "react";
import { useTheme } from "styled-components";
import {
    MenuBarContainer,
    MenuBarInnerContainer,
    MenuCardContainer,
} from "./header.components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {topMenuRoutes, topMenuRoutesUser} from "../../routes/topMenuRoutes";
import {useSelector} from "react-redux";


const scrollbarStyles = {
    width: "100vw",
    height: "6.5rem",
    display: "flex",
    flexDirection: "row",
};

const renderMenus = (isAdmin) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    return (isAdmin ? topMenuRoutes : topMenuRoutesUser).map((item, idx) => (
        <MenuCardContainer to={item.path} key={idx}>
            <FontAwesomeIcon icon={item.icon} size="2x" color={theme.colors.secondary} />
            <span>{item.label}</span>
        </MenuCardContainer>
    ));
};

const HeaderMenu = () => {
    const user = useSelector(state => state.user)
    const isAdmin = user.libelleProfil === "Admin"
    return (
        <MenuBarContainer style={scrollbarStyles}>
            <MenuBarInnerContainer>{renderMenus(isAdmin)}</MenuBarInnerContainer>
        </MenuBarContainer>
    );
};

export default HeaderMenu;
