import axios from "axios"
import {
    ADD_CHEF_EXERCICE,
    AJOUTER_EXERCICE_CLIENT_LEA,
    AJOUTER_FICHIER_EXERCICE,
    EQUIPE_EXERCICE,
    LISTE_EQUIPE_EXERCICE,
    LISTE_EXERCICE_LEA,
    LISTE_EXERCIE_CLIENTS_LEA,
    LISTE_FICHIER_EXERCICE, LISTE_MOTIF_ACCEPTATION_EXERCICE, LISTE_QUESTION_ACCEPATION_SUPERVISEUR,
    LISTE_REPONSE_QUESTION, LISTE_USER_EXERCICE, MODIFIER_REPONDRE_QUESTION_EXERCICE,
    OBSERVATION_GENERALE,
    REPONDRE_QUESTION_EXERCICE, REPONDRE_QUESTION_ONLY_EXERCICE, REPONSE_ACCEPTATION,
    SELECTIONNER_EXERCICE_LEA,
    VERROU_QUESTION_EXERCICE
} from "../../api/apiRoutes";
import {setAuthorizationHeader} from "../../api/config";

const EXERCICE_CLIENT_USED = `${process.env.REACT_APP_API_URL}/lea/exercice/liste-exercices-audit-client-used`
const CLOTURE_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/cloture-exercice`
const CLOTURE_PROVISOIRE_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/cloture-exercice-provisoire`
const LISTE_CLIENT_EXERCICE_SOMMAIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/client-sommaire`
const EXERCICE_BY_YEAR = `${process.env.REACT_APP_API_URL}/lea/exercice/select-exercice-by-year`

const MAINTIEN_AJOUT_QUESTION = `${process.env.REACT_APP_API_URL}/lea/exercice/maintien-ajouter-question`
const MAINTIEN_AFFECTATION = `${process.env.REACT_APP_API_URL}/lea/exercice/maintien-affectation`
const MAINTIEN_LISTE_QUESTION = `${process.env.REACT_APP_API_URL}/lea/exercice/maintien-liste-question`

const LISTE_AIDE_MEMOIRE_SOMMAIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/aide-memoire`
const AIDE_MEMOIRE_SOMMAIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/aide-memoire/sommaire`
const AJOUTER_AIDE_MEMOIRE_SOMMAIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/aide-memoire/ajouter-aide-memoire`


const MODIFIER_SOMMAIRE_CLIENT = `${process.env.REACT_APP_API_URL}/lea/exercice/modifier-sommaire-exercice`

const MEMBRE_MISSION = `${process.env.REACT_APP_API_URL}/lea/exercice/membre-mission`

const LISTE_MEMBRE_MISSION = `${process.env.REACT_APP_API_URL}/lea/exercice/mission-membre`

export const UploadFichierExercice = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(AJOUTER_FICHIER_EXERCICE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const ajouterExecice = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(AJOUTER_EXERCICE_CLIENT_LEA, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const modifierSommaireExecice = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(MODIFIER_SOMMAIRE_CLIENT, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeExerciceClients = async (client_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_EXERCIE_CLIENTS_LEA}/${client_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const clotureExercice = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(CLOTURE_EXERCICE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const clotureProvisoireExercice = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(CLOTURE_PROVISOIRE_EXERCICE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeExerciceClientUsed = async (client_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${EXERCICE_CLIENT_USED}/${client_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getExerciceDetails = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${SELECTIONNER_EXERCICE_LEA}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getExerciceParAnnee = async (client_id, annee) => {
    setAuthorizationHeader()
    return axios
        .get(`${EXERCICE_BY_YEAR}/${client_id}/${annee}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeExercice = async () => {
    setAuthorizationHeader()
    return axios
        .get(LISTE_EXERCICE_LEA)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeFichierExercice = async () => {
    setAuthorizationHeader()
    return axios
        .get(LISTE_FICHIER_EXERCICE)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeEquipeMission = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_EQUIPE_EXERCICE}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getMembreMissionDetails = async (exercice_id, user_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${MEMBRE_MISSION}/${exercice_id}/${user_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeUserMission = async (user_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_USER_EXERCICE}/${user_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeMembreMission = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_MEMBRE_MISSION}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getAllListeEquipeMission = async () => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_EQUIPE_EXERCICE}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeReponseMission = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_REPONSE_QUESTION}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeMotifAcceptationMission = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_MOTIF_ACCEPTATION_EXERCICE}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeQuestionReponse = async () => {
    setAuthorizationHeader()
    return axios
        .get(LISTE_QUESTION_ACCEPATION_SUPERVISEUR)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}


export const VerrouQestion = async (data) => {
    return axios
        .post(VERROU_QUESTION_EXERCICE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const addExerciceEquipe = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(EQUIPE_EXERCICE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const addChefMission = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(ADD_CHEF_EXERCICE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const addReponseQuestion = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(REPONDRE_QUESTION_EXERCICE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const addReponseQuestionSeul = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(REPONDRE_QUESTION_ONLY_EXERCICE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const updateReponseQuestion = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(MODIFIER_REPONDRE_QUESTION_EXERCICE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const addObservationGenerale = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(OBSERVATION_GENERALE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const addReponseAccepation = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(REPONSE_ACCEPTATION, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const ajoutMaintienQuestion = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(MAINTIEN_AJOUT_QUESTION, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const ajoutMaintienAffectation = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(MAINTIEN_AFFECTATION, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeMaintienQuestion = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${MAINTIEN_LISTE_QUESTION}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}


export const getListeClientSommaire = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_CLIENT_EXERCICE_SOMMAIRE}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeAideMemoire = async (exercice_id) => {
    setAuthorizationHeader()
    return axios
        .get(`${LISTE_AIDE_MEMOIRE_SOMMAIRE}/${exercice_id}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getAideMemoire = async (exercice_id, code) => {
    setAuthorizationHeader()
    return axios
        .get(`${AIDE_MEMOIRE_SOMMAIRE}/${exercice_id}/${code}`)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const ajouterAideMemoire = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(AJOUTER_AIDE_MEMOIRE_SOMMAIRE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}
