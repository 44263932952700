import React, { useState} from "react";
import { DeleteOutlined} from "@ant-design/icons";
import {Button, Divider, Input, Select, Space, Table, Tooltip, Typography} from "antd";
import {appTheme} from "../../../../../../config/theme";
import TableTitle from "../../../../../common/TableTitle";
import {faListCheck, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const { Title } = Typography;

const ClientModalEntite = ({ mission, setSelectedElementsList, selectedElementsList, questions, title, acceptValid}) => {
    const [allElementsAdded, setAllElementsAdded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setSelectedElementsList([]);
        }
    };

    const onChange = (value) => {
        setLoading(true);
        setTimeout(() => {
            const questId = parseInt(value);

            if (isNaN(questId)) {
                return;
            }

            const questionToAdd = questions.reduce((foundQuestion, element) => {
                if (foundQuestion) return foundQuestion;
                return element.questions.find((item) => item.id === questId);
            }, null);

            if (!questionToAdd) {
                return;
            }
            const isAlreadySelected = selectedElementsList.some((element) => element.question_id === questionToAdd.id);

            if (!isAlreadySelected) {
                const defaut = questionToAdd.reponse_defaut === "POSITIF"
                const newElement = {
                    question_id: questionToAdd.id,
                    libelle: questionToAdd.libelle,
                    isChecked: false,
                    defaultResponse: defaut,
                    motifRequired: !defaut === false,
                    mesureRequired: !defaut === false,
                    mesure_sauve: "",
                    motif: null,
                };

                setSelectedElementsList([...selectedElementsList, newElement]);
            }
            setLoading(false);
        }, 1000)
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const onSelectedAll = () => {
        setLoading(true);
        setTimeout(() => {
            const newSelectedElementsList = [...selectedElementsList];
            questions.forEach((element) => {
                element.questions.forEach((questionToAdd) => {
                    if (!newSelectedElementsList.some((element) => element.question_id === questionToAdd.id)) {
                        const defaut = questionToAdd.reponse_defaut === 'POSITIF';
                        const newElement = {
                            question_id: questionToAdd.id,
                            libelle: questionToAdd.libelle,
                            isChecked: false,
                            defaultResponse: defaut,
                            motifRequired: !defaut === false,
                            mesureRequired: !defaut === false,
                            motif: null,
                            mesure_sauve: "",
                        };
                        newSelectedElementsList.push(newElement);
                    }
                });
            });
            setSelectedElementsList(newSelectedElementsList);
            setAllElementsAdded(true);
            setLoading(false);
        }, 1500)
    }

    const handleDeleteAllElements = () => {
        setLoading(true)
        setTimeout(() => {
            setSelectedElementsList([]);
            setAllElementsAdded(false);
            setLoading(false)
        }, 1500)
    };

    const handleDeleteElement = (index) => {
        setLoading(true);
        setTimeout(() => {
            const updatedList = selectedElementsList.filter((_, i) => i !== index);
            if(updatedList.length <= 0){
                setAllElementsAdded(false);
            }
            setSelectedElementsList(updatedList);
            setLoading(false);
        }, 1000)
    };

    const handleCheckboxChange = (index, isChecked) => {
        const updatedList = selectedElementsList.map((item, i) =>
            i === index ? { ...item, isChecked, motifRequired: isChecked !== item.defaultResponse && item.motif === null, mesureRequired: isChecked !== item.defaultResponse } : item
        );
        setSelectedElementsList(updatedList);
    };

    const handleCommentChange = (index, motif) => {
        const updatedList = selectedElementsList.map((item, i) =>
            i === index ? { ...item,  motif, motifRequired: !item.motif && item.isChecked !== item.defaultResponse } : item
        );
        setSelectedElementsList(updatedList);
    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Questions</TableTitle>,
            dataIndex: 'name',
            width: 500,
            render: (_, record) => (
                <>
                   <span className="fw-bolder fs-5">
                       {record.libelle}
                   </span>
                </>
            ),
        },
        {
            title: () => <TableTitle>Positif</TableTitle>,
            dataIndex: 'reponse',
            width: 70,
            render: (_, record, index) => (
                <input
                    className="form-check-input"
                    type="radio"
                    value=""
                    disabled={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || acceptValid}
                    name={`positiveEntity_${index}`}
                    checked={record.isChecked}
                    onChange={(e) =>
                        handleCheckboxChange(index, e.target.checked)
                    }
                />
            ),
        },
        {
            title: () => <TableTitle>Négatif</TableTitle>,
            dataIndex: 'reponse',
            width: 70,
            render: (_, record, index) => (
                <input
                    className="form-check-input"
                    type="radio"
                    value=""
                    disabled={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || acceptValid}
                    name={`negativeEntity_${index}`}
                    checked={!record.isChecked}
                    onChange={(e) =>
                        handleCheckboxChange(index, !e.target.checked)
                    }
                />
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaires',
            render: (_, record, index) => (
                <>
                    <Input.TextArea
                        size="large"
                        placeholder="Saisisser le commentaire"
                        className={`${record.motifRequired !== true ? '' : 'is-invalid'}`}
                        required={record.motifRequired === true}
                        value={record.motif}
                        readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire ===1) || acceptValid}
                        onChange={(e) => {
                            handleCommentChange(index, e.target.value);
                        }}
                        bordered={false}
                        style={{width: "100%", border: record.motifRequired === true ? "1px solid red" : "0"}}
                        showCount
                        maxLength={5000}
                        autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }}
                    />
                    {record.motifRequired === true &&  (
                        <div className="invalid-feedback">Champ obligatoire</div>
                    )}
                </>
            ),
        },
        {
            title: () => <TableTitle>Actions</TableTitle>,
            width: 70,
            render: (_, record, index) => (
                <>
                    {!acceptValid && <Space>
                        {(mission.isCloture !== 1 || mission.isClotureProvisoire !== 1) && <Button
                            onClick={() => handleDeleteElement(index)}
                            title="Supprimer ?"
                            type="primary"
                            icon={<DeleteOutlined/>}
                            danger
                        />}
                    </Space>}
                </>
            ),
        },
    ];

    return (
        <>
        <div className="d-flex flex-column ">
            <div className="w-100 flex-lg-row-auto mb-4 me-4 me-lg-6">
                <div className="card card-flush py-1">
                    <div className="card-header">
                        <div className="card-title">
                            <Title style={{
                                display: "block",
                                fontWeight: '600',
                                color: appTheme.colors.primary_blue,
                                textAlign: "center"
                            }} level={3}>{title}</Title>
                        </div>
                    </div>
                    <Divider/>
                    {!acceptValid && <div className='d-flex align-center px-3'>
                        <Select
                            style={{width: 800}}
                            size="large"
                            showSearch
                            placeholder="Selectionner une question"
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={questions && questions.map((item) => ({
                                label: item.libelleGroupe,
                                options: item.questions.map((question) => ({
                                    label: question.libelle,
                                    value: question.id
                                }))
                            }))}
                        />
                        {allElementsAdded ? (
                                <div className='ms-10'>
                                    <Tooltip title="Tout supprimer">
                                        <Button
                                            size="large"
                                            onClick={handleDeleteAllElements}
                                            style={{backgroundColor: 'red'}}
                                            icon={<FontAwesomeIcon icon={faTrash} color="white"/>}
                                        >
                                        </Button>
                                    </Tooltip>
                                </div>
                            )
                            :
                            (
                                <div className='ms-10'>
                                    <Tooltip title="Tout sélectionner">
                                        <Button
                                            size="large"
                                            onClick={onSelectedAll}
                                            style={{backgroundColor: appTheme.colors.primary_blue}}
                                            icon={<FontAwesomeIcon icon={faListCheck} color="white"/>}
                                        >
                                        </Button>
                                    </Tooltip>

                                </div>
                            )
                        }
                    </div>}
                </div>
            </div>
            <div className="w-100">
                <div className="py-4">
                    <div className="table-responsive">
                        <Table
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={selectedElementsList}
                            pagination={tableParams.pagination}
                            loading={loading}
                            onChange={handleTableChange}
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default ClientModalEntite;
