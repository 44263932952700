import { Typography } from "antd";
import { ErrorMessage } from "formik";
import React from "react";

const FormError = ({ fieldName }) => {
  return (
    <ErrorMessage
      name={fieldName}
      render={(value) => (
        <Typography.Text type="danger">{value}</Typography.Text>
      )}
    />
  );
};

export default FormError;
