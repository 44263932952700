import React, {useState} from "react";
import {FeuilleMaitresse} from "./FeuilleMaitresse";
import {Button, Checkbox, Col, Divider, Input, notification, Row, Select, Space, Tag, Tooltip} from "antd";
import {ListeFileSommaire} from "../../../../../outils/files/ListeFileSommaire";
import {ModalTitle} from "../../../../../../modals/modals.components";
import ModalAlert from "../../../../../../modals/Modal";
import FormLabel from "../../../../../../common/Form/FormLabel";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faFolderOpen} from "@fortawesome/free-solid-svg-icons";

const TravauxModal = ({
          handleVisibility,
          visible,
          chargement,
          assertions,
          programme,
          setSelectData,
          selectData,
          mission,
          tabMaitresse,
          client,
          setConclusionGenerale,
          conclusionGenerale,
          procedure_id,
          menuLibelle,
          setIsAddMemoire,
          setIsTaskSave,
          isTaskSave,
          isProvisoire,
          codeSommaire
    }) => {
    const [selected, setSelected] = useState(programme)
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalId, setModalId] = useState(null);
    const [isAddConclusion, setIsAddConclusion] = useState(false);

    const handleOpenModal = (id) => {
        setModalId(id);
        setIsModalOpen(prevState => !prevState);
    };

    const handleSubmit = async () => {
        setLoading(true);
        setTimeout(() => {
            const updatedList = selectData.map((item) =>
                item.programme_id === selected.programme_id ?
                    {
                        ...item,
                        ref: selected.ref,
                        libelle: selected.libelle,
                        commentaire: selected.commentaire,
                        conclusion: selected.conclusion,
                        assertions: selected.assertions
                    } : item
            );
            setSelectData(updatedList);
            setIsTaskSave(true);

            notification.success({
                description: "Cycle modifié avec succès !",
            });
            handleOpenModal()
            setLoading(false);
        }, 1000);
    }

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> EE3.{procedure_id} - {menuLibelle}: Exécution du programme de travail</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 10 }}
                width={1000}
            >
                <div className="modal-body">
                    <div className="d-flex justify-content-end align-items-end">
                        <Space>
                            <Tooltip title='Documents du cycle'>
                                <Button
                                    shape={"circle"}
                                    size={"large"}
                                    style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                    onClick={() => handleOpenModal(1)}
                                    icon={<FontAwesomeIcon icon={faFolderOpen}/>}
                                />
                            </Tooltip>
                            <Tooltip title='Feuille maitresse'>
                                <Button
                                    shape={"circle"}
                                    size={"large"}
                                    style={{color: "white", backgroundColor: appTheme.colors.primary_blue}}
                                    onClick={() => handleOpenModal(2)}
                                    icon={<FontAwesomeIcon icon={faFileExcel}/>}
                                />
                            </Tooltip>
                        </Space>
                    </div>
                    <Divider/>
                    <Row gutter={[16, 16]}>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 16 }}
                            lg={{ span: 16 }}
                            xl={{ span: 16 }}
                        >
                            <FormLabel label="Nature du travail contrôlé" />
                            <div className="">
                                <Input.TextArea
                                    style={{fontSize: "14px"}}
                                    size="large"
                                    bordered={false}
                                    placeholder="Saisisser les travaux à faire..."
                                    value={selected.libelle}
                                    maxLength={2000}
                                    showCount
                                    autoSize={{
                                        minRows: 1,
                                        maxRows: 6,
                                    }}
                                    onChange={(e) => {
                                        const updated = { ...selected,  libelle: (e.target.value)}
                                        setSelected(updated);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col
                            xs={{ span: 20 }}
                            md={{ span: 6 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <FormLabel label="Assertions" />
                            <Select
                                mode='tags'
                                style={{width: '100%', fontSize: "14px"}}
                                placeholder='choisir les assertions'
                                value={selected.assertions}
                                options={assertions.map((item) => ({
                                    label: item.libelle,
                                    value: item.libelle,
                                }))}
                                onChange={(value) => {
                                    const updated = { ...selected,  assertions: (value)}
                                    setSelected(updated);
                                }}
                            />
                        </Col>
                        <Col
                            xs={{ span: 4 }}
                            md={{ span: 2 }}
                            lg={{ span: 2 }}
                            xl={{ span: 2 }}
                        >
                            <FormLabel label="Référence" />
                            <Tag>
                                <span className='fw-bolder' style={{fontSize: '13px'}}>
                                    {selected.ref}
                                </span>
                            </Tag>
                        </Col>
                        <Col span={24}>
                            <div className="d-flex flex-column mb-3">
                                <FormLabel label="Travaux exécutés" />
                                <Input.TextArea
                                    style={{fontSize: "14px"}}
                                    size="large"
                                    bordered={false}
                                    placeholder="Saisisser les travaux..."
                                    value={selected.commentaire}
                                    maxLength={3000}
                                    showCount
                                    autoSize={{
                                        minRows: 4,
                                        maxRows: 6,
                                    }}
                                    onChange={(e) => {
                                        const updated = { ...selected,  commentaire: (e.target.value)}
                                        setSelected(updated);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 24 }}
                            xl={{ span: 24 }}
                        >
                            <div className="d-flex flex-column mb-3 mx-2">
                                <FormLabel label="CONCLUSION" />
                                <Input.TextArea
                                    style={{fontSize: "14px"}}
                                    size="large"
                                    bordered={false}
                                    placeholder="Saisisser la conclusion..."
                                    value={selected.conclusion}
                                    maxLength={3000}
                                    showCount
                                    autoSize={{
                                        minRows: 4,
                                        maxRows: 6,
                                    }}
                                    onChange={(e) => {
                                        const updated = { ...selected,  conclusion: (e.target.value)}
                                        setSelected(updated);
                                        setIsTaskSave(true);
                                    }}
                                />
                                <div className='mt-5'>
                                    <div className="d-flex align-center">
                                        {/*<div className="">*/}
                                        {/*    <Checkbox*/}
                                        {/*        onChange={() => {*/}
                                        {/*            setIsAddMemoire(!isAddMemoire)*/}
                                        {/*        }}*/}
                                        {/*        value={isAddMemoire}*/}
                                        {/*    >*/}
                                        {/*        Aide memoire*/}
                                        {/*    </Checkbox>*/}
                                        {/*</div>*/}
                                        <div className="ms-5">
                                            <Checkbox
                                                onChange={(e) => {
                                                    const conclus = e.target.checked === true ? selected.conclusion : ''
                                                    setIsTaskSave(true);
                                                    setConclusionGenerale(`${conclusionGenerale ? `${conclusionGenerale} \n` : ""} ${conclus}`)
                                                    setIsAddConclusion(!isAddConclusion)
                                                }}
                                                value={isAddConclusion}
                                            >
                                                Envoyé a la conclusion
                                            </Checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-center align-items-center mt-3">
                        <div className="w-350px">
                            <Button
                                type="primary"
                                block
                                size="large"
                                loading={loading}
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                Valider
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalAlert>
        {isModalOpen && modalId === 2 && (
            <FeuilleMaitresse
                handleVisibility={handleOpenModal}
                visible={isModalOpen}
                mission={mission}
                programme={programme}
                isProvisoire={isProvisoire}
                tabMaitresse={tabMaitresse}
                client={client}
                menuLibelle={menuLibelle}
                codeSommaire={codeSommaire}
                chargement={chargement || loading}
            />
        )}
        {isModalOpen && modalId === 1 && (
            <ListeFileSommaire
                handleVisibility={handleOpenModal}
                client={client}
                mission={mission}
                isProvisoire={isProvisoire}
                visible={isModalOpen}
                codeSommaire={codeSommaire}
            />
        )}
    </>

    );
};

export {TravauxModal}