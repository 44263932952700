import axios from 'axios'
import {
    AJOUTER_CYCLE,
    LISTE_CYCLE, LISTE_CYCLE_ACTIVE,  
    MODIFIER_CYCLE,
    MODIFIER_ETAT_CYCLE,  SUPPRIMER_CYCLE
} from '../../api/apiRoutes'

export const addCycle = async (data) => {
    return axios.post(AJOUTER_CYCLE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const updateCycle = async (data) => {
    return axios.post(MODIFIER_CYCLE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeCycle = async () => {
    return axios.get(LISTE_CYCLE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeCycleActive = async () => {
    return axios.get(LISTE_CYCLE_ACTIVE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const deleteCycle = async (data) => {
    return axios.post(SUPPRIMER_CYCLE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


export const updateEtatCycle = async (data) => {
    return axios.post(MODIFIER_ETAT_CYCLE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}