import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getListeTacheActive } from "../../../data/params/tache";
import moment from "moment";
import { getListeInterventionActive } from "../../../data/params/intervention";
import { getListeUserMission } from "../../../data/exercice";
import {Button, Col, Divider, Input, Row, Select, Space} from "antd";
import {ModalTitle} from "../../modals/modals.components";
import {Form, Formik} from "formik";
import ModalAlert from "../../modals/Modal";
import * as Yup from "yup";
import {toAbsoluteUrl} from "../../../helpers/AssetsHelpers";
import clsx from "clsx";
import {ClockCircleOutlined} from "@ant-design/icons";
import FormError from "../../common/Form/FormError";
import LoadingComponent from "../../modals/LoadingComponent";

const IdentificationUser = ({
                                mission,
                                setDate,
                                setUser_id,
                                setHeureEntree,
                                handleVisibility,
                                handleValider,
                                visible,
                                loading,
                                prev
                            }) => {
    const user = useSelector((state) => state.user);
    const [taches, setTaches] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [role, setRole] = useState(null);
    const [chargement, setChargement] = useState(true);

    useEffect(() => {
        const date = new Date();
        setDate(date);
        setHeureEntree(moment(date).format("HH:mm:ss"));
    }, [setDate, setHeureEntree]);

    useEffect(() => {
        if(user){
            setUser_id(user?.id);
        }
    }, [setUser_id, user]);

    useEffect(() => {
        if (user && mission) {
            getListeUserMission(user.id).then((res) => {
                if (res) {
                    const opt = res.find((item) => item.exercice_id === mission?.id);
                    setRole(opt?.Lea_RoleMission);
                }})
        }
    }, [user, mission]);

    useEffect(() => {
        const fetchListeTache = () => {
            getListeTacheActive().then((res) => {
                if (res?.result && res.result.length > 0) {
                    const userTaches = res.result.filter((item) =>
                        role?.actions.some((action) => action === item.id)
                    );
                    if (userTaches && userTaches.length > 0) {
                        setTaches(userTaches);
                    }
                }
            });
        };

        const fetchIntervention = () => {
            getListeInterventionActive().then((res) => {
                if (res?.result && res.result.length > 0) {
                    const userIntervention = res.result.filter((item) =>
                        role?.intervention.some((opt) => opt === item.id)
                    );

                    if (userIntervention && userIntervention.length > 0) {
                        setInterventions(userIntervention);
                    }
                }
            });
        };

        fetchListeTache();
        fetchIntervention();
    }, [role]);

    const dataValidationScheme = Yup.object({
        tache_id: Yup.number()
            .required("Veuillez sélectionner la tâche")
            .nullable("La tâche est obligatoire")
            .typeError("La tâche est obligatoire"),
        intervention_id: Yup.number()
            .required("Veuillez sélectionner l'intervention")
            .nullable("L'intervention est obligatoire")
            .typeError("L'intervention est obligatoire"),
    });

    return (
        <>
            {chargement
                ?
                <LoadingComponent
                    loading={chargement}
                    setLoading={setChargement}
                    message="Veuillez patienter, nous chargeons les ressources nécessaires au traitement de ce cycle."
                />
                :
                <ModalAlert
                    closeModal={prev || handleVisibility}
                    show={visible}
                    title={<ModalTitle> Identification du collaborateur</ModalTitle>}
                    footer={[]}
                    modalStyles={{
                        top: 30,
                    }}
                    width={600}
                >
                    <Formik
                        initialValues={{
                            tache_id: "",
                            intervention_id: ""
                        }}
                        onSubmit={(values) => handleValider(values)}
                        validationSchema={dataValidationScheme}
                    >
                        {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                            <Form>
                                <Divider/>
                                <Row gutter={[16, 16]}>
                                    <Col span={16}>
                                        <div className='symbol symbol-circle symbol-100px overflow-hidden me-3'>
                                            {user?.avatar ? (
                                                <div className='symbol-label'>
                                                    <img
                                                        src={toAbsoluteUrl(`/media/${user?.avatar}`)}
                                                        alt={user.nom_user + ' ' + user.prenom_user}
                                                        className='w-100'
                                                    />
                                                </div>
                                            ) : user.codeCivilite === 'M.' ? (
                                                <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                                                    <img
                                                        src={toAbsoluteUrl('/media/avatars/male.jpg')}
                                                        alt={user.nom_user + ' ' + user.prenom_user}
                                                        className='w-100'
                                                    />
                                                </div>
                                            ) : (
                                                <div className={clsx('symbol-label fs-3', `bg-light-green`)}>
                                                    <img
                                                        src={toAbsoluteUrl('/media/avatars/female.jpg')}
                                                        alt={user.nom_user + ' ' + user.prenom_user}
                                                        className='w-100'
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="d-flex flex-column">








                                            <span className="fs-3" style={{color: "#24247a", fontWeight: "800"}}>
                                                {`${user.nom_user || ""} ${user.prenom_user || ""}`}
                                            </span>
                                            <span className="fw-bolder fs-5 text-black"> {role?.libelle} </span>
                                        </div>
                                    </Col>
                                    <Col span={8}>
                                    <span className='chart-blue display-block fs-6' style={{fontWeight: 700}}>
                                        <ClockCircleOutlined style={{fontSize: '18px'}} />{' '}
                                        {moment().format('DD/MM/YYYY à HH:mm')}
                                    </span>
                                    </Col>
                                    <Col span={24}>
                                        <Space.Compact block>
                                            <Input
                                                style={{
                                                    width: '30%',
                                                }}
                                                value="Tâches"
                                                readOnly={true}
                                            />
                                            <Select
                                                showSearch
                                                placeholder="Sélectionnez la tâche..."
                                                optionFilterProp="label"
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                options={taches.map((tache) => ({
                                                    value: tache.id,
                                                    label: tache.libelle,
                                                }))}

                                                onChange={(value) =>
                                                    setFieldValue("tache_id", value)
                                                }
                                                size="large"
                                                style={{ width: "70%" }}
                                            />
                                        </Space.Compact>
                                        {touched.tache_id && errors.tache_id && (
                                            <FormError fieldName="tache_id" />
                                        )}
                                    </Col>
                                    <Col span={24}>
                                        <Space.Compact block>
                                            <Input
                                                style={{
                                                    width: '30%',
                                                }}
                                                value="Intervention"
                                                readOnly={true}
                                            />
                                            <Select
                                                showSearch
                                                placeholder="Sélectionnez l'intervation..."
                                                optionFilterProp="label"
                                                filterOption={(input, option) =>
                                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                options={interventions.map((tache) => ({
                                                    value: tache.id,
                                                    label: tache.libelle,
                                                }))}
                                                onChange={(value) =>
                                                    setFieldValue("intervention_id", value)
                                                }












                                                size="large"
                                                style={{ width: "70%"}}
                                            />
                                        </Space.Compact>
                                        {touched.intervention_id && errors.intervention_id && (
                                            <FormError fieldName="intervention_id" />
                                        )}
                                    </Col>
                                    <Col span={24}>
                                        <Button
                                            type="primary"
                                            block
                                            size="large"
                                            loading={loading}
                                            disabled={loading}
                                            onClick={handleSubmit}
                                        >
                                            {!loading && <span className='indicator-label'>Enregistrer</span>}
                                            {loading && (
                                                <span className="indicator-progress text-white">
                                            Veuillez patienter...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                        </span>
                                            )}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </ModalAlert>
            }
        </>
    );
};

export { IdentificationUser };