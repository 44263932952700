import React from 'react';

const PrintFonctionnementControle = ({datas, title}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        minHeight: "20px"
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <div className="card">
            {/*<h1 className="fw-bolder text-black mb-3 text-center">{title || ""}</h1>*/}

            {/*<div className="table-responsive mb-5">*/}
            {/*    <div className='mx-auto'>*/}
            {/*        <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>*/}
            {/*            <table className=" w-100" style={tableStyle}>*/}
            {/*                <thead>*/}
            {/*                <tr style={thStyle}>*/}
            {/*                    <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px" rowSpan={2}>Incidence (effet) du facteur de risque</th>*/}
            {/*                    <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-80px" rowSpan={2}>Assertions <br/> (C, A, E, V, D)</th>*/}
            {/*                    <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-80px" rowSpan={2}>Postes</th>*/}
            {/*                    <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-80px" rowSpan={2} >Niveau du risque <br/> (Faible, Moyen, Elevé)</th>*/}
            {/*                    <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px" rowSpan={2} >Procédures</th>*/}
            {/*                    <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-350px" rowSpan={1} colSpan={2} >Descriptions/Résultats</th>*/}
            {/*                    <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-80px" rowSpan={2} >Niveau de risque résiduel <br/> (Faible, Moyen, Elevé)</th>*/}
            {/*                    <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px" rowSpan={2} >Recommandation</th>*/}
            {/*                    <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px" rowSpan={2} >Conclusion</th>*/}
            {/*                </tr>*/}
            {/*                <tr style={thStyle}>*/}
            {/*                    <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px">Description des points de contrôles</th>*/}
            {/*                    <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px">Résultat / Constatations</th>*/}
            {/*                </tr>*/}
            {/*                </thead>*/}
            {/*                <tbody>*/}
            {/*                {datas.map((data, rubriqueIndex) => (*/}
            {/*                    data.proceduresData.map((item, itemIndex) => {*/}
            {/*                        item.questions.map((question, eventIndex) => {*/}
            {/*                            // const colSpanEvent = (eventIndex === 0) ? data.questions.length : 1;*/}
            {/*                            const incidenceLibelle = data.incidence || '';*/}
            {/*                            const assertions = data.assertions || [];*/}
            {/*                            const postes = data.postes || [];*/}
            {/*                            const niveauLibelle = data.niveauLibelle || "";*/}
            {/*                            const procedure = item.procedure || [];*/}
            {/*                            const questionLibelle = question.questionLibelle || "";*/}
            {/*                            const reponseLibelle = question.reponse || "";*/}
            {/*                            const niveauRisqueResiduel = data.niveauResiduel || "";*/}
            {/*                            const recommandation = data.recommandation || "";*/}
            {/*                            const conclusion = data.conclusion || "";*/}

            {/*                            return (*/}
            {/*                                <tr style={tdStyle} className="" key={`${rubriqueIndex}-${itemIndex}-${eventIndex}`}>*/}
            {/*                                    {itemIndex === 0 && eventIndex === 0 && (*/}
            {/*                                        <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}}*/}
            {/*                                            rowSpan={item.questions.length}>*/}
            {/*                                            {incidenceLibelle}*/}
            {/*                                        </td>*/}
            {/*                                    )}*/}
            {/*                                    {itemIndex === 0 && eventIndex === 0 && (*/}
            {/*                                        <td style={{...tdStyle, fontSize: "8px"}}*/}
            {/*                                            rowSpan={item.questions.length}>*/}
            {/*                                            <div className='d-flex align-center '>*/}
            {/*                                                {assertions?.map((assertion, assertionIndex) => (*/}
            {/*                                                    <span className='fw-bolder me-5' key={assertionIndex}>*/}
            {/*                                                      {`${assertion}, `}*/}
            {/*                                                    </span>*/}
            {/*                                                ))}*/}
            {/*                                            </div>*/}
            {/*                                        </td>*/}
            {/*                                    )}*/}
            {/*                                    {itemIndex === 0 && eventIndex === 0 &&  (*/}
            {/*                                        <td style={{...tdStyle, fontSize: "8px"}}*/}
            {/*                                            rowSpan={item.questions.length}>*/}
            {/*                                            <div className='d-flex flex-column align-center '>*/}
            {/*                                                {postes?.map((poste, posteIndex) => (*/}
            {/*                                                    <span className='fw-bolder me-5' key={posteIndex}>*/}
            {/*                                                      {poste}*/}
            {/*                                                    </span>*/}
            {/*                                                ))}*/}
            {/*                                            </div>*/}
            {/*                                        </td>*/}
            {/*                                    )}*/}
            {/*                                    {itemIndex === 0 && eventIndex === 0 &&  (*/}
            {/*                                        <td style={{...tdStyle, fontSize: "8px"}}*/}
            {/*                                            rowSpan={item.questions.length}>*/}
            {/*                                            {niveauLibelle}*/}
            {/*                                        </td>*/}
            {/*                                    )}*/}
            {/*                                    {eventIndex === 0 && (*/}
            {/*                                        <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}}*/}
            {/*                                            rowSpan={item.questions.length}>*/}
            {/*                                            {procedure}*/}
            {/*                                        </td>*/}
            {/*                                    )}*/}
            {/*                                    <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}}>*/}
            {/*                                        {questionLibelle}*/}
            {/*                                    </td>*/}
            {/*                                    <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}}>*/}
            {/*                                        {reponseLibelle}*/}
            {/*                                    </td>*/}
            {/*                                    {itemIndex === 0 && eventIndex === 0 && (*/}
            {/*                                        <td style={{...tdStyle, fontSize: "8px"}}*/}
            {/*                                            rowSpan={item.questions.length}>*/}
            {/*                                            {niveauRisqueResiduel}*/}
            {/*                                        </td>*/}
            {/*                                    )}*/}
            {/*                                    {itemIndex === 0 && eventIndex === 0 && (*/}
            {/*                                        <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}}*/}
            {/*                                            rowSpan={item.questions.length}>*/}
            {/*                                            {recommandation}*/}
            {/*                                        </td>*/}
            {/*                                    )}*/}
            {/*                                    {itemIndex === 0 && eventIndex === 0 && (*/}
            {/*                                        <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}}*/}
            {/*                                            rowSpan={item.questions.length}>*/}
            {/*                                            {conclusion}*/}
            {/*                                        </td>*/}
            {/*                                    )}*/}
            {/*                                </tr>*/}
            {/*                            );*/}
            {/*                        })*/}
            {/*                    })*/}
            {/*                ))}*/}
            {/*                </tbody>*/}
            {/*            </table>*/}

            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="table-responsive mb-5">
                <div className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className=" w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px" rowSpan={2}>Incidence (effet) du facteur de risque</th>
                                <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-80px" rowSpan={2}>Assertions <br/> (C, A, E, V, D)</th>
                                <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-80px" rowSpan={2}>Postes</th>
                                <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-80px" rowSpan={2} >Niveau du risque <br/> (Faible, Moyen, Elevé)</th>
                                <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px" rowSpan={2} >Procédures</th>
                                <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-350px" rowSpan={1} colSpan={2} >Descriptions/Résultats</th>
                                <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-80px" rowSpan={2} >Niveau de risque résiduel <br/> (Faible, Moyen, Elevé)</th>
                                <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px" rowSpan={2} >Recommandation</th>
                                <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px" rowSpan={2} >Conclusion</th>
                            </tr>
                            <tr style={thStyle}>
                                <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px">Description des points de contrôles</th>
                                <th style={{...thStyle, fontSize: "9px", fontWeight: "bold"}} className="w-150px">Résultat / Constatations</th>
                            </tr>
                            </thead>
                            <tbody>
                            {datas.map((data, rubriqueIndex) => (
                                data.questions.map((question, eventIndex) => {
                                    // const colSpanEvent = (eventIndex === 0) ? data.questions.length : 1;
                                    const incidenceLibelle =  data.incidence || '';
                                    const assertions = data.assertions || [];
                                    const postes = data.postes || [];
                                    const niveauLibelle = data.niveauLibelle || "";
                                    const procedures = data.procedures || [];
                                    const questionLibelle = question.questionLibelle || "";
                                    const reponseLibelle = question.reponse || "";
                                    const niveauRisqueResiduel = data.niveauResiduel || "";
                                    const recommandation = data.recommandation || "";
                                    const conclusion = data.conclusion || "";

                                    return (
                                        <tr style={tdStyle} className="" key={`${rubriqueIndex}-${eventIndex}`}>
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}} rowSpan={data.questions.length}>
                                                    {incidenceLibelle}
                                                </td>
                                            )}
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "8px"}} rowSpan={data.questions.length}>
                                                    <div className='d-flex align-center '>
                                                        {assertions?.map((assertion, assertionIndex) => (
                                                            <span className='fw-bolder me-5' key={assertionIndex}>
                                                              {`${assertion}, `}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </td>
                                            )}
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "8px"}} rowSpan={data.questions.length}>
                                                    <div className='d-flex flex-column align-center '>
                                                        {postes?.map((poste, posteIndex) => (
                                                            <span className='fw-bolder me-5' key={posteIndex}>
                                                              {poste}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </td>
                                            )}
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "8px"}} rowSpan={data.questions.length}>
                                                    {niveauLibelle}
                                                </td>
                                            )}
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}} rowSpan={data.questions.length}>
                                                    {procedures.map((opt, indexOpt) => (
                                                        <div  key={indexOpt} className='d-flex flex-column'>
                                                       <span className='' style={{fontSize: '8px'}}>
                                                           <strong style={{fontSize: '13px', fontWeight: "bold", marginRight: "2%"}}>.</strong>{opt.procedure}
                                                       </span>
                                                        </div>
                                                    ))}
                                                </td>
                                            )}
                                            <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}}>
                                                {questionLibelle}
                                            </td>
                                            <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}}>
                                                {reponseLibelle}
                                            </td>
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "8px"}} rowSpan={data.questions.length}>
                                                    {niveauRisqueResiduel}
                                                </td>
                                            )}
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}} rowSpan={data.questions.length}>
                                                    {recommandation}
                                                </td>
                                            )}
                                            {eventIndex === 0  && (
                                                <td style={{...tdStyle, fontSize: "8px", textAlign: "start"}} rowSpan={data.questions.length}>
                                                    {conclusion}
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })
                            ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrintFonctionnementControle;
