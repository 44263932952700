import React, { useState } from "react";
import {Button, Divider, Popover, Space, Table, Tooltip} from "antd";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faExclamationCircle, faSave} from "@fortawesome/free-solid-svg-icons";
import {ModalCommentaireBalance} from "../../../../../../modals/clients/ModalCommentaireBalance";
import FormLabel from "../../../../../../common/Form/FormLabel";
import {SearchHeader} from "../../../../../../layout/header/HeaderList";

const HistoriqueBalanceProvisoireDefinitif = ({ balanceData, setBalanceData, mission, chargement, handleSubmit, setIsTaskSave}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState(null);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    const handleModalOpen = (data) => {
        setBalance(data)
        setIsModalOpen(prevState => !prevState)
    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Compte Prov</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistProv ? '' : record.numeroCompteProv}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé Prov</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistProv ? '' : record.libelleProv}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Débit Prov</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.debitProv || 0).toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Crédit Prov</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.creditProv || 0).toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Compte Def</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistDef ? '' : record.numeroCompteDef}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé Def</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                   {record.isExistDef ? '' : record.libelleDef}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Débit Def</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.debitDef || 0).toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Crédit N-1</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.creditDef || 0).toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>DIFF SD</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    { record.debitProv - record.debitDef}
                </div>
            ),
        },
        {
            title: () => <TableTitle>DIFF SC</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {  record.creditProv - record.creditDef}
                </div>
            ),
        },
        {
            title: () => <TableTitle>COM</TableTitle>,
            dataIndex: 'commentaire',
            width: 40,
            render: (_, record) => (
                <div className="">
                    <Space>
                        {record.commentaire && <Popover
                            placement="top"
                            title={<FormLabel label={"Commentaire"} /> }
                            content={
                                <>
                                    <Divider/>
                                    <span className="fw-bolder"
                                          style={{fontSize: "14px",}}
                                    >
                                   {record.commentaire || ""}
                                </span>
                                </>
                            }
                        >
                            <Button
                                icon={<FontAwesomeIcon icon={faExclamationCircle}/>}
                                color={""}
                            />
                        </Popover>}
                        <Tooltip title="Editer un commentaire">
                            <Button
                                shape="circle"
                                style={{backgroundColor: appTheme.colors.primary, color: "white"}}
                                icon={<FontAwesomeIcon icon={faComment}/>}
                                onClick={() => handleModalOpen(record)}
                            />
                        </Tooltip>
                    </Space>
                    {/*<Input.TextArea*/}
                    {/*    style={{fontSize: "12px"}}*/}
                    {/*    size="large"*/}
                    {/*    bordered={false}*/}
                    {/*    placeholder="Saisisser le commentaire..."*/}
                    {/*    value={record.commentaire}*/}
                    {/*    maxLength={2000}*/}
                    {/*    showCount*/}
                    {/*    autoSize={{*/}
                    {/*        minRows: 1,*/}
                    {/*        maxRows: 4,*/}
                    {/*    }}*/}
                    {/*    onChange={(e) => {*/}
                    {/*        const updatedList = balanceData.map((item, i) =>*/}
                    {/*            i === index ? { ...item,  commentaire: (e.target.value)} : item*/}
                    {/*        );*/}

                    {/*        setBalanceData(updatedList);*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
            ),
        },
    ];


    return (
        <>
            <div className="my-5">
                <div className="d-flex justify-content-end align-items-end pb-10">
                    <Tooltip title="Résultats">
                        <Button
                            disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            size={"large"}
                            // loading={loading}
                            style={{
                                backgroundColor: appTheme.colors.primary,
                                color: "white"
                            }}
                            icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                            onClick={handleSubmit}
                        >
                            {(!chargement || !loading) &&
                                <span className='indicator-label text-white'>Enregistrer</span>}
                            {(chargement || loading) && (
                                <span className='indicator-progress fs-9 text-white'
                                      style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                    </Tooltip>
                </div>
                {(balanceData ?? []).length > 0 && <div className="card-header border-0 pt-2">
                    <SearchHeader
                        placeholder="Rechercher par le numero de compte, le libellé..."
                        // handleSearch={handleSearchChange}
                    />
                </div>}
                <div className="table-responsive">
                    <Table
                        onRow={(record) => {
                            return {
                                onDoubleClick: () => {
                                    handleModalOpen(record)
                                }
                            };
                        }}
                        rowClassName={() =>
                            `cursor-pointer h-3px p-0 m-0 lh-0.5`
                        }
                        columns={columns}
                        rowKey={(record, index) => index}
                        dataSource={balanceData ?? []}
                        pagination={tableParams.pagination}
                        onChange={handleTableChange}
                        scroll={{ x: 1300 }}
                        loading={chargement || loading}
                        rowSelection
                        size={"small"}
                    />
                </div>
            </div>
            <ModalCommentaireBalance
                setBalanceData={setBalanceData}
                balanceData={balanceData}
                balance={balance}
                visible={isModalOpen}
                handleVisibility={handleModalOpen}
                loading={loading}
                isIntang={false}
                setLoading={setLoading}
                setIsTaskSave={setIsTaskSave}
            />
        </>
    );
 };

export { HistoriqueBalanceProvisoireDefinitif };