import React, {useEffect, useState} from 'react';
import {SearchHeader} from '../../../components/layout/header/HeaderList';
import {getListeExerciceClients, getListeUserMission} from "../../../data/exercice";
import {useSelector} from "react-redux";
import TableTitle from "../../../components/common/TableTitle";
import {appTheme} from "../../../config/theme";
import {TagItem} from "../../../components/common/common.components";
import {Button, Space, Table, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEye,
    faFolder,
} from "@fortawesome/free-solid-svg-icons";

const FileManagerClient = ({client, setIsMissionModalOpen, setAnnee, setMissionLength}) => {
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [arrayYear, setArrayYear] = useState([]);
    const [filtersItems, setFiltersItems] = useState([]);
    const [usersMission, setUsersMission] = useState([]);
    const user = useSelector(state => state.user)
    const isAdmin = user.libelleProfil === "Admin"

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setArrayYear([]);
        }
    };

    useEffect(() => {
        if(client){
            fetchClientExercice(client.id);
        }
    }, [ client, usersMission]);

    useEffect(() => {
        if(user){
            fetchUserMission(user.id);
        }
    }, [user]);

    const fetchUserMission = (id) => {
        getListeUserMission(id)
            .then((res) => {
                if(res){
                    setUsersMission(res)
                }
            });
    };

    const fetchClientExercice = (id) => {
        setLoading(true);
        getListeExerciceClients(id)
            .then((res) => {
                if(res){
                    const used = res.filter((exercice) => exercice.isUsed === 1)

                    const missions = isAdmin ? used : used.filter((opt) =>  usersMission.some((element) => opt.id === element.exercice_id && user.id === element.membre_id ))
                    setFiles(missions);
                    setMissionLength(res?.filter(item => item.isUsed)?.length || 0);
                    const years = [...new Set(missions.map((item) => item.annee))];
                    const yearsFiltered  = years.sort((a, b) => parseInt(b) - parseInt(a));

                    setArrayYear(yearsFiltered);
                    setFiltersItems(yearsFiltered)
                    setLoading(false);
                }
            });
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = arrayYear.filter(
            (item) =>
                item.toLowerCase().includes(searchTerm)
        );
        setFiltersItems(filtered);
    };

    const dataColumns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Dossier</TableTitle>,
            key: "",
            dataIndex: "",
            width: 500,
            render: (_, record) => (
                <>
                    <div className="d-flex align-items-center">
                        <span className="svg-icon svg-icon-2x svg-icon-primary me-4">
                            <FontAwesomeIcon icon={faFolder} color={appTheme.colors.primary} />
                        </span>
                        <Button
                            className="text-black fw-bolder fs-4 text-hover-primary"
                            type="link"
                            onClick={() => {
                                setIsMissionModalOpen(true)
                                setAnnee(record)
                            }}
                        >
                            {record}
                        </Button>
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Client</TableTitle>,
            dataIndex: "",
            width: 200,
            render: () => (
                <>
                    <span style={{ color: "#000", fontWeight: "bold" }}>
                    {client.denomination}
                  </span>
                    <br />
                    {client.sigle && <TagItem color={appTheme.colors.primary}>{client.sigle}</TagItem>}
                    <br/>
                </>
            ),
        },
        {
            title: () => <TableTitle>Actions</TableTitle>,
            align: "right",
            width: 150,
            render: (_, record) => (
                <Space>
                    {<Tooltip title="Voir les mission" className='me-5'>
                        <Button
                            shape="circle"
                            icon={<FontAwesomeIcon icon={faEye} color={appTheme.colors.primary_blue} />}
                            size={"middle"}
                            onClick={() => {
                                setIsMissionModalOpen(true)
                                setAnnee(record)
                            }}
                        />
                    </Tooltip>}
                </Space>
            ),
        },
    ];

    return (
        <>
            <div className='d-flex flex-column w-100'>
                <div className="card w-100">
                    <div className="card-header border-0 pt-3">
                        <SearchHeader handleSearch={handleSearchChange}/>
                    </div>
                    <div className="w-100">
                        <div className="py-4">
                            <div className="table-responsive">
                                <Table
                                    columns={dataColumns}
                                    dataSource={filtersItems}
                                    pagination={tableParams.pagination}
                                    loading={loading}
                                    onChange={handleTableChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};


export default FileManagerClient;
