import React from 'react';
import '../testejournaux.css';

const PrintTravauxIntangibilite = ({datas, conclusion}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        minHeight: "20px"
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <div className="card">
            <div style={{marginBottom: "25px"}}>
                <div className='mx-auto'>
                    <div className=''>
                        <table className="tablePerso w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={thStyle} className="w-50px">#</th>
                                <th style={thStyle} className="w-400px">TRAVAUX A FAIRE (TAF)</th>
                                <th style={thStyle} className="w-100px">ASSERTIONS</th>
                                <th style={thStyle} className="w-100px">REPONSES</th>
                                <th style={thStyle} className="w-400px">COMMENTAIRES</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(datas && datas.length > 0) && datas.map((item, index) => (
                                <tr key={index} style={tdStyle} className="">
                                    <td className="" style={{...tdStyle}}>
                                        {index + 1}
                                    </td>
                                    <td className=" text-start" style={{...tdStyle }}>
                                        {item.libelle}
                                    </td>
                                    <td className=" text-center" style={tdStyle}>
                                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                            {item.assertions?.map((assertion) => (
                                                <span style={{fontWeight: "700", marginRight: "7px", textAlign: "center"}} key={assertion.id}>
                                                  {assertion}
                                                </span>
                                            ))}
                                        </div>
                                    </td>
                                    <td className="" style={tdStyle}>
                                        <span>
                                          {item.reponse === '1' ? 'N/A': item.reponse === '2' ? 'OUI' : 'NON' }
                                      </span>
                                    </td>
                                    <td className="text-start" style={{...tdStyle}}>
                                        {item.commentaire}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {conclusion &&
                <table style={tableStyle} className=" ">
                    <thead>
                    <tr style={thStyle}>
                        <th
                            style={{fontSize: "14px",
                                textDecoration: 'underline',
                                fontWeight: "bold", textAlign: "start"}}
                        >
                            Conclusion
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={tdStyle}>
                        <td style={{...tdStyle,  textAlign: "start"}}>
                            <p style={{fontSize: "14px"}}>{conclusion}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            }
        </div>
    );
}

export default PrintTravauxIntangibilite;
