import React, {useEffect, useState} from "react"
import {getListeNiveau} from "../../../../../../data/params/niveau";
import {getListeImpact} from "../../../../../../data/params/impact";
import {getListeProbabilite} from "../../../../../../data/params/probabilite";
import {getListeRisqueActive} from "../../../../../../data/params/risque";
import {areArraysEqual} from "../../../../../utilities/Utilities";
import {Button, Input, Popconfirm, Popover, Select, Space, Tag, Tooltip} from "antd";
import {Table as TableChackra, Center, Spinner, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import TableTitle from "../../../../../common/TableTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faNetworkWired, faTrashCan,} from "@fortawesome/free-solid-svg-icons";
import {useTheme} from "styled-components";
import {appTheme} from "../../../../../../config/theme";

const tableHeaders = [
    {title: "Rubrique", width: 200},
    {title: "Évènement / Source du risque", width: 300},
    {title: "Incidence (effet)", width: 300},
    {title: "Poste", width: 50},
    {title: "Assertions", width: 100},
    {title: "Probabilité", width: 50},
    {title: "Impact financier", width: 50},
    {title: "Score C", width: 50},
    {title: "Niveau de risque", width: 50},
]

const postesStat = [
    { key: "EE3.1", title: "Immobilisations incorporelles" },
    { key: "EE3.2", title: "Immobilisations corporelles" },
    { key: "EE3.3", title: "Immobilisations financières" },
    { key: "EE3.4", title: "Stocks" },
    { key: "EE3.5", title: "Ventes-clients" },
    { key: "EE3.6", title: "Autres créance et dettes" },
    { key: "EE3.7", title: "Trésorerie" },
    { key: "EE3.8", title: "Capitaux propres" },
    { key: "EE3.9", title: "Provisions risques" },
    { key: "EE3.10", title: "Emprunts et assimilés" },
    { key: "EE3.11", title: "Achats fournisseurs" },
    { key: "EE3.12", title: "Impôts et taxes" },
    { key: "EE3.13", title: "Paie personnel" },
];

const Content = ({sommaires}) => {
    const theme = useTheme()
    return (
        <div className="d-flex flex-column scroll-y">
            {sommaires.map((item) => (
                <Tag
                    style={{
                        display: "flex",
                        fontSize: 14,
                        padding: 4,
                        marginBottom: 8,
                        width: "100%"
                    }}
                >
                    <Tag color={theme.colors.primary}>{postesStat.find(poste => poste.key === item)?.key}</Tag>
                    <div style={{fontFamily: "Oswald, sans-serif"}}>
                        {postesStat.find(poste => poste.key === item)?.title}
                    </div>
                </Tag>
            ))}
        </div>
    )
};

const  TraitementEvaluation = ({
       selecteData,
       setSelectData,
       datas, mission,
       isProvisoire,
       handleDeleteAllEvaluation,
                                   // libelleRisque
    }) => {
    const [loading, setLoading] = useState(false);
    const [rubriques, setRubriques] = useState([]);
    const [probabilites, setProbabilites] = useState([]);
    const [impacts, setImpacts] = useState([]);
    const [niveaux, setNiveaux] = useState([]);

    useEffect(() => {
        const fetchRubrique = async () => {
            try {
                setLoading(true);
                const res = await getListeRisqueActive();
                setRubriques(res.result.sort((a, b) => {
                    return a.value - b.value;
                }));
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des rubriques', error);
                setLoading(false);
            }
        };

        const fetchProbabilite = async () => {
            try {
                setLoading(true);
                const res = await getListeProbabilite();
                setProbabilites(res.result.sort((a, b) => {
                    return a.value - b.value;
                }));
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des rubriques', error);
                setLoading(false);
            }
        };

        const fetchImpact = async () => {
            try {
                setLoading(true);
                const res = await getListeImpact();
                setImpacts(res.result);
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des rubriques', error);
                setLoading(false);
            }
        };

        const fetchNiveaux = async () => {
            try {
                setLoading(true);
                const res = await getListeNiveau();
                setNiveaux(res.result);
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des rubriques', error);
                setLoading(false);
            }
        };
        
        fetchProbabilite();
        fetchImpact();
        fetchNiveaux();
        fetchRubrique();
    }, []);

    useEffect(() => {
        if(datas.length > 0) {
            const risqueIds = [...new Set(datas.map((item) => item.Bog_EventSourceRisque?.risqueId))];

            const arrayData = [];
            if(risqueIds.length > 0){
                risqueIds.map((id) => {
                    const filtredDatas = datas.filter(element => element.Bog_EventSourceRisque?.risqueId === id);
                    if (filtredDatas.length > 0) {
                        const rubrique = rubriques.find((opt) => opt.id === id)
                        const newSelectArray = {
                            rubrique_id: rubrique?.id,
                            rubrique_libelle: rubrique?.libelle,
                            risquesDetails: filtredDatas.map((event) => ({
                                evenRisque_id: event?.eventRisque_id,
                                eventLibelle: event.eventLibelle,
                                risqueIncidences: event.Lea_RisqueIdentificationDetails?.map((detail) => ({
                                    incidence_id: detail.id,
                                    incidence: detail.incidence,
                                    risquesAssertions: detail.Lea_RisqueIdentificationAssertions?.map((assertion, assertionIndex) => ({
                                        postes: assertion.postes,
                                        assertions: assertion.assertions,
                                        probabiliteValue: detail.Lea_RisqueEvaluations[assertionIndex]?.probabiliteValue || '',
                                        probabilite_id: detail.Lea_RisqueEvaluations[assertionIndex]?.probabilite_id || '',
                                        probabiliteLibelle: probabilites.find((item) => item.id === detail.Lea_RisqueEvaluations[assertionIndex]?.probabilite_id)?.libelle,
                                        impactValue: detail.Lea_RisqueEvaluations[assertionIndex]?.impactValue || '',
                                        impact_id: detail.Lea_RisqueEvaluations[assertionIndex]?.impact_id || '',
                                        impactLibelle: impacts.find((item) => item.id === detail.Lea_RisqueEvaluations[assertionIndex]?.impact_id)?.libelle,
                                        score: detail.Lea_RisqueEvaluations[assertionIndex]?.score || 0,
                                        niveau_id: detail.Lea_RisqueEvaluations[assertionIndex]?.niveau_id || '',
                                        niveauLibelle: niveaux.find((item) => item.id === detail.Lea_RisqueEvaluations[assertionIndex]?.niveau_id)?.libelle
                                    }))
                                }))
                            })),
                        }

                        arrayData.push(newSelectArray)
                    }
                })
                const updatedItems = arrayData.map(risque => ({
                    ...risque,
                    assertionLenght: risque.risquesDetails.reduce((total, event) => {
                        return total + event.risqueIncidences
                            .filter(incidence => incidence.risquesAssertions)
                            .length;
                    }, 0),
                }));

                if (!areArraysEqual(updatedItems, datas)) {
                    setSelectData(updatedItems)
                }
            }
        }
    }, [datas, impacts, niveaux, probabilites, rubriques, setSelectData])

    const handleProbabiliteChange = (rubriqueIndex, eventIndex, incidenceIndex, assertionIndex, value) => {
        const selectedProbabiliteId = parseInt(value) || 1;
        const probabilite = probabilites.find((item) => item.id === selectedProbabiliteId);

        if (!probabilite) {
            return;
        }

        const updatedData = [...selecteData];
        const current = updatedData[rubriqueIndex].risquesDetails[eventIndex].risqueIncidences[incidenceIndex].risquesAssertions[assertionIndex];

        current.probabiliteValue = probabilite.value;
        current.probabilite_id = selectedProbabiliteId;
        current.probabiliteLibelle = probabilite.libelle;
        current.score = probabilite.value * current.impactValue;

        const niveau = niveaux.find((niveau) => parseInt(current.score || 1) >= parseInt(niveau.valueMin) && parseInt(current.score || 1) <= parseInt(niveau.valueMax))

        current.niveau_id = niveau.id;
        // if(current.impact_id && probabilite){
        //     setRequired(false)
        // }

        setSelectData(updatedData);
    };

    const handleImpactChange = (rubriqueIndex, eventIndex, incidenceIndex, assertionIndex, value) => {
        const selectedImpactId = parseInt(value) || 1;
        const impact = impacts.find((item) => item.id === selectedImpactId);

        if (!impact) {
            return;
        }

        const updatedData = [...selecteData];
        const current = updatedData[rubriqueIndex].risquesDetails[eventIndex].risqueIncidences[incidenceIndex].risquesAssertions[assertionIndex];

        current.impactValue = impact.value;
        current.impact_id = selectedImpactId;
        current.impactLibelle = impact.libelle;
        current.score = impact.value * current.probabiliteValue;

        const niveau = niveaux.find((niveau) => parseInt(current.score || 1) >= parseInt(niveau.valueMin) && parseInt(current.score || 1) <= parseInt(niveau.valueMax))

        current.niveau_id = niveau.id;
        current.niveauLibelle = niveau.libelle;

        // if(current.probabilite_id && impact){
        //     setRequired(false)
        // }
        setSelectData(updatedData);
    };



    return (
        <>
            <div className=''>
                <div className="table-responsive">
                    {loading ? (
                        <Center>
                            <Spinner />
                        </Center>
                    ) : (
                        <>
                            {mission.isCloture !== 1 && <div className="d-flex justify-content-end align-center my-2 me-5">
                                <Tooltip color={appTheme.colors.danger} title="Supprimer toute les évaluations éffectuées">
                                    <Popconfirm
                                        title="Supprimer les évaluations"
                                        description="Voulez vous vraiment supprimer toutes les évaluations de ce cycle ?"
                                        onConfirm={handleDeleteAllEvaluation}
                                        // onCancel={cancel}
                                        okText="OUI"
                                        cancelText="NON"
                                    >
                                        <Button
                                            size={"middle"}
                                            style={{backgroundColor: appTheme.colors.danger}}
                                            // onClick={handleDeleteAllEvaluation}
                                            icon={<FontAwesomeIcon icon={faTrashCan} color={"white"}/>}
                                            danger
                                        />
                                    </Popconfirm>

                                </Tooltip>
                            </div>}
                            <TableContainer>
                                <TableChackra variant="simple">
                                    <Thead>
                                        <Tr>
                                            {tableHeaders.map((item, index) => (
                                                <Th width={item.width || 100} key={index}><TableTitle>{item.title}</TableTitle> </Th>
                                            ))}
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {selecteData
                                            ? (selecteData ?? []).map((rubrique, rubriqueIndex) => (
                                                rubrique.risquesDetails.map((event, eventIndex) => (
                                                    event.risqueIncidences.map((incidence, incidenceIndex) => (
                                                        incidence.risquesAssertions.map((assertion, assertionIndex) => {
                                                            const colSpanEvent = (incidenceIndex === 0 && assertionIndex === 0) ? event.risqueIncidences.length : 1;
                                                            const colSpanIncidence = (assertionIndex === 0) ? incidence.risquesAssertions.length : 1;
                                                            const rubriqueLibelle = (eventIndex === 0 && incidenceIndex === 0 && assertionIndex === 0) ? rubrique.rubrique_libelle : '';
                                                            const eventLibelle = (incidenceIndex === 0 && assertionIndex === 0) ? event.eventLibelle : '';
                                                            const incidenceValue = incidence.incidence || '';
                                                            const assertionValue = assertion.assertions || [];
                                                            const postesValue = assertion.postes || [''];
                                                            const probabiliteValue = assertion.probabilite_id || '';
                                                            const impactValue = assertion.impact_id || '';
                                                            const niveauValue = assertion.niveau_id || '';
                                                            const scoreValue = assertion.score || 1;

                                                            return (
                                                                <Tr key={`${rubriqueIndex}-${eventIndex}-${incidenceIndex}-${assertionIndex}`}>
                                                                    {eventIndex === 0 && incidenceIndex === 0 && assertionIndex === 0 && (
                                                                        <Td width={200} rowSpan={rubrique.assertionLenght}>
                                                                            <Input.TextArea
                                                                                style={{fontSize: "12px", fontWeight: "700", textTransform: "uppercase"}}
                                                                                size="large"
                                                                                bordered={false}
                                                                                value={rubriqueLibelle}
                                                                                maxLength={2000}
                                                                                // showCount
                                                                                autoSize={{
                                                                                    minRows: 1,
                                                                                    maxRows: 8,
                                                                                }}
                                                                            />
                                                                        </Td>
                                                                    )}
                                                                    {incidenceIndex === 0 && assertionIndex === 0 && (
                                                                        <Td width={300} rowSpan={colSpanEvent} colSpan={colSpanIncidence}>
                                                                            <div className="">
                                                                                <Input.TextArea
                                                                                    style={{fontSize: "13px"}}
                                                                                    size="large"
                                                                                    bordered={false}
                                                                                    placeholder="Saisisser le commentaire..."
                                                                                    value={eventLibelle}
                                                                                    maxLength={2000}
                                                                                    readOnly={true}
                                                                                    autoSize={{
                                                                                        minRows: 1,
                                                                                        maxRows: 5,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Td>
                                                                    )}
                                                                    {assertionIndex === 0 && (
                                                                        <Td width={300} rowSpan={colSpanIncidence}>
                                                                            <div className="">
                                                                                <Input.TextArea
                                                                                    style={{fontSize: "13px"}}
                                                                                    size="large"
                                                                                    bordered={false}
                                                                                    placeholder="Saisisser le commentaire..."
                                                                                    value={incidenceValue}
                                                                                    maxLength={2000}
                                                                                    readOnly={true}
                                                                                    autoSize={{
                                                                                        minRows: 1,
                                                                                        maxRows: 5,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </Td>
                                                                    )}
                                                                    <Td width={50}>
                                                                        <Tooltip title="Postes EF concernés" color={"#009EF7"}>
                                                                            <Popover content={<Content sommaires={postesValue || []} />} title="Postes EF concernés." trigger="click">
                                                                                <Button icon={<FontAwesomeIcon icon={faNetworkWired} color={"#009EF7"} />} />
                                                                            </Popover>
                                                                        </Tooltip>
                                                                    </Td>
                                                                    <Td width={50}>
                                                                        <div className='d-flex align-center' >
                                                                            {(assertionValue ?? []).map((assertion, index) => (
                                                                                <Space>
                                                                                    <Tag key={index}>
                                                                                    <span className='fw-boldest' key={index}>
                                                                                        {assertion}
                                                                                    </span>
                                                                                    </Tag>
                                                                                </Space>
                                                                            ))}
                                                                        </div>
                                                                    </Td>
                                                                    <Td width={50}>
                                                                        <Select
                                                                            placeholder="Probabilité..."
                                                                            size="middle"
                                                                            style={{ width: "100%" }}
                                                                            value={probabiliteValue || null}
                                                                            onSelect={(value) =>
                                                                                handleProbabiliteChange(rubriqueIndex, eventIndex, incidenceIndex, assertionIndex, value)
                                                                            }
                                                                            options={probabilites.map((prob) => ({
                                                                                value: prob.id,
                                                                                label: prob.libelle
                                                                            }))}
                                                                        />
                                                                    </Td>
                                                                    <Td width={50}>
                                                                        <Select
                                                                            placeholder="Impact..."
                                                                            size="middle"
                                                                            style={{ width: "100%" }}
                                                                            value={impactValue || null}
                                                                            onSelect={(value) =>
                                                                                handleImpactChange(rubriqueIndex, eventIndex, incidenceIndex, assertionIndex, value)
                                                                            }
                                                                            options={impacts.map((prob) => ({
                                                                                value: prob.id,
                                                                                label: prob.libelle
                                                                            }))}
                                                                        />
                                                                    </Td>
                                                                    <Td width={50}>
                                                                        <Tag>
                                                                            <span className="fw-bolder fs-6" style={{fontFamily: "Oswald, sans-serif"}}>{scoreValue}</span>
                                                                        </Tag>
                                                                    </Td>
                                                                    <Td width={50}>
                                                                        <label className={`rounded-3 ${scoreValue < 6 ? "bg-success" : scoreValue <= 15 ? "bg-warning" : "bg-danger"}`}>
                                                                            <Select
                                                                                placeholder="Niveau..."
                                                                                size="middle"
                                                                                variant="borderless"
                                                                                bordered={false}
                                                                                style={{ width: "100%" }}
                                                                                value={niveauValue || null}
                                                                                // onChange={(value) => {
                                                                                //     handleNiveauChange(rubriqueIndex, eventIndex, incidenceIndex, assertionIndex, value)
                                                                                // }}
                                                                                disabled={true}
                                                                                options={niveaux.map((prob) => ({
                                                                                    value: prob.id,
                                                                                    label: (<span className="text-white">{prob.libelle}</span>)
                                                                                }))}
                                                                            />
                                                                        </label>
                                                                    </Td>
                                                                </Tr>
                                                            );
                                                        })
                                                    ))
                                                ))
                                            ))
                                            : null}
                                    </Tbody>
                                </TableChackra>
                            </TableContainer>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};


export {TraitementEvaluation}