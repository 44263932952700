
// Gestion des Utilisateurs
export const LOGIN = `${process.env.REACT_APP_API_URL}/auth/login-lea`
export const CREATE_USER_URL = `${process.env.REACT_APP_API_URL}/lea/users/add-user`
export const EDITED_USER_URL = `${process.env.REACT_APP_API_URL}/lea/users/modifier-user`
export const GET_USER_BY_ID = `${process.env.REACT_APP_API_URL}/lea/users/select-user/:user_id`
export const GET_USERS_URL = `${process.env.REACT_APP_API_URL}/lea/users/list-user`
export const DELETE_USERS_URL = `${process.env.REACT_APP_API_URL}/lea/users/supprimer-user`
export const UPDATE_USERS_STATE_URL = `${process.env.REACT_APP_API_URL}/lea/users/modifier-etat-user`
export const UPDATE_USERS_ACCES_DISTAN_URL = `${process.env.REACT_APP_API_URL}/lea/users/modifier-etat-acces-distant-user`
export const UPDATE_USERS_ACCES_MOBILE_URL = `${process.env.REACT_APP_API_URL}/lea/users/modifier-etat-version-mobile-user`
export const UPDATE_USERS_ACCES_WEB_URL = `${process.env.REACT_APP_API_URL}/lea/users/modifier-etat-version-web-user`
export const GENERATE_USERS_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/lea/users/generer-mot-de-passe-user`
export const UPDATE_USERS_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/lea/users/modifier-mot-de-passe-user`


// GESTION DES PROFILS
export const AJOUTER_PROFIL = `${process.env.REACT_APP_API_URL}/params/ajouter-profils-lea`
export const MODIFIER_PROFIL = `${process.env.REACT_APP_API_URL}/params/modifier-profils-lea`
export const MODIFIER_ETAT_PROFIL = `${process.env.REACT_APP_API_URL}/params/modifier-etat-profils-lea`
export const SUPPRIMER_PROFIL = `${process.env.REACT_APP_API_URL}/params/supprimer-profils-lea`
export const LISTE_PROFIL = `${process.env.REACT_APP_API_URL}/params/liste-profils-lea`


// GESTION DES ROLE DE MISSION
export const AJOUTER_ROLE = `${process.env.REACT_APP_API_URL}/lea/params/ajouter-role`
export const MODIFIER_ROLE = `${process.env.REACT_APP_API_URL}/lea/params/modifier-role`
export const MODIFIER_ETAT_ROLE = `${process.env.REACT_APP_API_URL}/lea/params/modifier-etat-role`
export const SUPPRIMER_ROLE = `${process.env.REACT_APP_API_URL}/lea/params/supprimer-role`
export const LISTE_ROLE = `${process.env.REACT_APP_API_URL}/lea/params/liste-role`

// GESTION PLAN COMPTABLE
export const LISTE_PLAN_COMPTABLE = `${process.env.REACT_APP_API_URL}/params/liste-plan-comptable`
export const LISTE_PLAN_COMPTABLE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-plan-comptable-active`
export const AJOUTER_PLAN_COMPTABLE = `${process.env.REACT_APP_API_URL}/params/ajouter-plan-comptable`
export const IMPORTER_PLAN_COMPTABLE = `${process.env.REACT_APP_API_URL}/params/importer-plan-comptable`
export const MODIFIER_PLAN_COMPTABLE = `${process.env.REACT_APP_API_URL}/params/modifier-plan-comptable/`

// GESTION DES ROLES
// export const AJOUTER_PROFIL = `${process.env.REACT_APP_API_URL}/lea/params/ajouter-profils`
// export const MODIFIER_PROFIL = `${process.env.REACT_APP_API_URL}/lea/params/modifier-profils`
// export const MODIFIER_ETAT_PROFIL = `${process.env.REACT_APP_API_URL}/lea/params/modifier-etat-profils`
// export const SUPPRIMER_PROFIL = `${process.env.REACT_APP_API_URL}/lea/params/supprimer-profils`
export const LISTE_ROLE_INTERVENTION = `${process.env.REACT_APP_API_URL}/params/liste-des-role-equipe-mission-active`

// Gestion des civilités
export const LISTE_CIVILITE = `${process.env.REACT_APP_API_URL}/params/liste-civilite`
export const AJOUTER_CIVILITE = `${process.env.REACT_APP_API_URL}/params/ajouter-civilite`
export const MODIFIER_CIVILITE = `${process.env.REACT_APP_API_URL}/params/modifier-civilite`
export const SUPPRIMER_CIVILITE = `${process.env.REACT_APP_API_URL}/params/supprimer-civilite`
export const MODIFIER_ETAT_CIVILITE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-civilite`

// Gestion des période
export const LISTE_PERIODE = `${process.env.REACT_APP_API_URL}/params/liste-periode`
export const AJOUTER_PERIODE = `${process.env.REACT_APP_API_URL}/params/ajouter-periode`
export const MODIFIER_PERIODE = `${process.env.REACT_APP_API_URL}/params/modifier-periode`
export const SUPPRIMER_PERIODE = `${process.env.REACT_APP_API_URL}/params/supprimer-periode`
export const MODIFIER_ETAT_PERIODE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-periode`

// Gestion des Categorie
export const CREATE_CATEGORIE_URL = `${process.env.REACT_APP_API_URL}/params/ajouter-categorie`
export const UPDATE_CATEGORIE_URL = `${process.env.REACT_APP_API_URL}/params/modifier-categorie`
// const GET_PROFIL_BY_ID = `${process.env.REACT_APP_API_URL}/params/select-user/`
export const GET_CATEGORIE_URL = `${process.env.REACT_APP_API_URL}/params/liste-categorie`
export const DELETE_CATEGORIE_URL = `${process.env.REACT_APP_API_URL}/params/supprimer-categorie`
export const MODIFIER_ETAT_CATEGORIE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-categorie`

// Gestion des privilèges
export const LISTE_PRIVILEGE = `${process.env.REACT_APP_API_URL}/params/liste-privilege`
export const AJOUTER_PRIVILEGE = `${process.env.REACT_APP_API_URL}/params/ajouter-privilege`
export const MODIFIER_PRIVILEGE = `${process.env.REACT_APP_API_URL}/params/modifier-privilege`
export const SUPPRIMER_PRIVILEGE = `${process.env.REACT_APP_API_URL}/params/supprimer-privilege`
export const MODIFIER_ETAT_PRIVILEGE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-privilege`

// Gestion des secteurs d'activités
export const LISTE_SECTEUR_ACTIVITE = `${process.env.REACT_APP_API_URL}/params/liste-secteur-activite`
export const AJOUTER_SECTEUR_ACTIVITE = `${process.env.REACT_APP_API_URL}/params/ajouter-secteur-activite`
export const MODIFIER_SECTEUR_ACTIVITE = `${process.env.REACT_APP_API_URL}/params/modifier-secteur-activite`
export const SUPPRIMER_SECTEUR_ACTIVITE = `${process.env.REACT_APP_API_URL}/params/supprimer-secteur-activite`
export const MODIFIER_ETAT_SECTEUR_ACTIVITE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-secteur-activite`

// Gestion des banques
export const LISTE_BANQUE = `${process.env.REACT_APP_API_URL}/params/liste-banque`
export const AJOUTER_BANQUE = `${process.env.REACT_APP_API_URL}/params/ajouter-banque`
export const MODIFIER_BANQUE = `${process.env.REACT_APP_API_URL}/params/modifier-banque`
export const SUPPRIMER_BANQUE = `${process.env.REACT_APP_API_URL}/params/supprimer-banque`
export const MODIFIER_ETAT_BANQUE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-banque`

// Gestion des unites
export const LISTE_UNITE = `${process.env.REACT_APP_API_URL}/params/liste-unite`
export const LISTE_UNITE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-unite-active`
export const AJOUTER_UNITE = `${process.env.REACT_APP_API_URL}/params/ajouter-unite`
export const MODIFIER_UNITE = `${process.env.REACT_APP_API_URL}/params/modifier-unite`
export const SUPPRIMER_UNITE = `${process.env.REACT_APP_API_URL}/params/supprimer-unite`
export const MODIFIER_ETAT_UNITE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-unite`

// Gestion des statuts
export const LISTE_STATUT = `${process.env.REACT_APP_API_URL}/params/liste-status-juridique`
export const AJOUTER_STATUT = `${process.env.REACT_APP_API_URL}/params/ajouter-status-juridique`
export const MODIFIER_STATUT = `${process.env.REACT_APP_API_URL}/params/modifier-status-juridique`
export const SUPPRIMER_STATUT = `${process.env.REACT_APP_API_URL}/params/supprimer-status-juridique`
export const MODIFIER_ETAT_STATUT = `${process.env.REACT_APP_API_URL}/params/modifier-etat-status-juridique`

// Gestion des statuts
export const LISTE_MODE_PAIEMENT = `${process.env.REACT_APP_API_URL}/params/liste-mode-de-paiement`
export const AJOUTER_MODE_PAIEMENT = `${process.env.REACT_APP_API_URL}/params/ajouter-mode-de-paiement`
export const MODIFIER_MODE_PAIEMENT = `${process.env.REACT_APP_API_URL}/params/modifier-mode-de-paiement`
export const SUPPRIMER_MODE_PAIEMENT = `${process.env.REACT_APP_API_URL}/params/supprimer-mode-de-paiement`
export const MODIFIER_ETAT_MODE_PAIEMENT = `${process.env.REACT_APP_API_URL}/params/modifier-etat-mode-de-paiement`

// Gestion des statuts
export const LISTE_OPERATEUR = `${process.env.REACT_APP_API_URL}/params/liste-operateur-mobile`
export const AJOUTER_OPERATEUR = `${process.env.REACT_APP_API_URL}/params/ajouter-operateur-mobile`
export const MODIFIER_OPERATEUR = `${process.env.REACT_APP_API_URL}/params/modifier-operateur-mobile`
export const SUPPRIMER_OPERATEUR = `${process.env.REACT_APP_API_URL}/params/supprimer-operateur-mobile`
export const MODIFIER_ETAT_OPERATEUR = `${process.env.REACT_APP_API_URL}/params/modifier-etat-operateur-mobile`


// Gestion Assertion
export const LISTE_ASSERTION = `${process.env.REACT_APP_API_URL}/params/liste-assertion`
export const LISTE_ASSERTION_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-assertion-active`
export const AJOUTER_ASSERTION = `${process.env.REACT_APP_API_URL}/params/ajouter-assertion`
export const MODIFIER_ASSERTION = `${process.env.REACT_APP_API_URL}/params/modifier-assertion`
export const SUPPRIMER_ASSERTION = `${process.env.REACT_APP_API_URL}/params/supprimer-assertion`
export const MODIFIER_ETAT_ASSERTION = `${process.env.REACT_APP_API_URL}/params/modifier-etat-assertion`

// Gestion Cycle
export const LISTE_CYCLE = `${process.env.REACT_APP_API_URL}/params/liste-cycle`
export const LISTE_CYCLE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-cycle-active`
export const AJOUTER_CYCLE = `${process.env.REACT_APP_API_URL}/params/ajouter-cycle`
export const MODIFIER_CYCLE = `${process.env.REACT_APP_API_URL}/params/modifier-cycle`
export const SUPPRIMER_CYCLE = `${process.env.REACT_APP_API_URL}/params/supprimer-cycle`
export const MODIFIER_ETAT_CYCLE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-cycle`

// Gestion Evenement
export const LISTE_EVENT = `${process.env.REACT_APP_API_URL}/params/liste-event`
export const LISTE_EVENT_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-event-active`
export const AJOUTER_EVENT = `${process.env.REACT_APP_API_URL}/params/ajouter-event`
export const MODIFIER_EVENT = `${process.env.REACT_APP_API_URL}/params/modifier-event`
export const SUPPRIMER_EVENT = `${process.env.REACT_APP_API_URL}/params/supprimer-event`
export const MODIFIER_ETAT_EVENT = `${process.env.REACT_APP_API_URL}/params/modifier-etat-event`

// Gestion Impact
export const LISTE_IMPACT = `${process.env.REACT_APP_API_URL}/params/liste-impact`
export const LISTE_IMPACT_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-impact-active`
export const AJOUTER_IMPACT = `${process.env.REACT_APP_API_URL}/params/ajouter-impact`
export const MODIFIER_IMPACT = `${process.env.REACT_APP_API_URL}/params/modifier-impact`
export const SUPPRIMER_IMPACT = `${process.env.REACT_APP_API_URL}/params/supprimer-impact`
export const MODIFIER_ETAT_IMPACT = `${process.env.REACT_APP_API_URL}/params/modifier-etat-impact`

// Gestion Niveau de risque
export const LISTE_NIVEAU = `${process.env.REACT_APP_API_URL}/params/liste-niveau`
export const LISTE_NIVEAU_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-niveau-active`
export const AJOUTER_NIVEAU = `${process.env.REACT_APP_API_URL}/params/ajouter-niveau`
export const MODIFIER_NIVEAU = `${process.env.REACT_APP_API_URL}/params/modifier-niveau`
export const SUPPRIMER_NIVEAU = `${process.env.REACT_APP_API_URL}/params/supprimer-niveau`
export const MODIFIER_ETAT_NIVEAU = `${process.env.REACT_APP_API_URL}/params/modifier-etat-niveau`

// Gestion Probabilite
export const LISTE_PROBABILITE = `${process.env.REACT_APP_API_URL}/params/liste-probabilite`
export const LISTE_PROBABILITE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-probabilite-active`
export const AJOUTER_PROBABILITE = `${process.env.REACT_APP_API_URL}/params/ajouter-probabilite`
export const MODIFIER_PROBABILITE = `${process.env.REACT_APP_API_URL}/params/modifier-probabilite`
export const SUPPRIMER_PROBABILITE = `${process.env.REACT_APP_API_URL}/params/supprimer-probabilite`
export const MODIFIER_ETAT_PROBABILITE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-probabilite`

// Gestion risque
export const LISTE_RISQUE = `${process.env.REACT_APP_API_URL}/params/liste-risque`
export const LISTE_RISQUE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-risque-active`
export const AJOUTER_RISQUE = `${process.env.REACT_APP_API_URL}/params/ajouter-risque`
export const MODIFIER_RISQUE = `${process.env.REACT_APP_API_URL}/params/modifier-risque`
export const SUPPRIMER_RISQUE = `${process.env.REACT_APP_API_URL}/params/supprimer-risque`
export const MODIFIER_ETAT_RISQUE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-risque`

// Gestion sommaire
export const LISTE_SOMMAIRE = `${process.env.REACT_APP_API_URL}/params/liste-sommaire`
export const LISTE_SOMMAIRE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-sommaire-active`
export const AJOUTER_SOMMAIRE = `${process.env.REACT_APP_API_URL}/params/ajouter-sommaire`
export const MODIFIER_SOMMAIRE = `${process.env.REACT_APP_API_URL}/params/modifier-sommaire`
export const SUPPRIMER_SOMMAIRE = `${process.env.REACT_APP_API_URL}/params/supprimer-sommaire`
export const MODIFIER_ETAT_SOMMAIRE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-sommaire`

// Gestion intervention
export const LISTE_INTERVENTION = `${process.env.REACT_APP_API_URL}/params/liste-intervention`
export const LISTE_INTERVENTION_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-intervention-active`
export const AJOUTER_INTERVENTION = `${process.env.REACT_APP_API_URL}/params/ajouter-intervention`
export const MODIFIER_INTERVENTION = `${process.env.REACT_APP_API_URL}/params/modifier-intervention`
export const SUPPRIMER_INTERVENTION = `${process.env.REACT_APP_API_URL}/params/supprimer-intervention`
export const MODIFIER_ETAT_INTERVENTION = `${process.env.REACT_APP_API_URL}/params/modifier-etat-intervention`

// Gestion programme
export const LISTE_PROGRAMME = `${process.env.REACT_APP_API_URL}/params/liste-programme`
export const LISTE_PROGRAMME_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-programme-active`
export const AJOUTER_PROGRAMME = `${process.env.REACT_APP_API_URL}/params/ajouter-programme`
export const MODIFIER_PROGRAMME = `${process.env.REACT_APP_API_URL}/params/modifier-programme`
export const SUPPRIMER_PROGRAMME = `${process.env.REACT_APP_API_URL}/params/supprimer-programme`
export const MODIFIER_ETAT_PROGRAMME = `${process.env.REACT_APP_API_URL}/params/modifier-etat-programme`

// Gestion question
export const LISTE_QUESTION = `${process.env.REACT_APP_API_URL}/params/liste-question`
export const LISTE_GROUPE_QUESTION = `${process.env.REACT_APP_API_URL}/params/liste-groupe-question`
export const LISTE_TYPE_QUESTION = `${process.env.REACT_APP_API_URL}/params/liste-type-question`
export const LISTE_AUTRE_QUESTION = `${process.env.REACT_APP_API_URL}/params/liste-question-autres`
export const LISTE_QUESTION_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-question`
export const AJOUTER_QUESTION = `${process.env.REACT_APP_API_URL}/params/ajouter-question`
export const AJOUTER_TYPE_QUESTION = `${process.env.REACT_APP_API_URL}/params/ajouter-type-question`
export const MODIFIER_QUESTION = `${process.env.REACT_APP_API_URL}/params/modifier-question`
export const SUPPRIMER_QUESTION = `${process.env.REACT_APP_API_URL}/params/supprimer-question`
export const MODIFIER_ETAT_QUESTION = `${process.env.REACT_APP_API_URL}/params/modifier-etat-question`
export const AJOUT_QUESTION_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/ajouter-question-exercie`
export const LISTE_QUESTION_ACCEPTATION = `${process.env.REACT_APP_API_URL}/lea/exercice/liste-questionnaire-acceptation`


// Gestion tache
export const LISTE_TACHE = `${process.env.REACT_APP_API_URL}/params/liste-tache`
export const LISTE_TACHE_ACTIVE = `${process.env.REACT_APP_API_URL}/params/liste-tache-active`
export const AJOUTER_TACHE = `${process.env.REACT_APP_API_URL}/params/ajouter-tache`
export const MODIFIER_TACHE = `${process.env.REACT_APP_API_URL}/params/modifier-tache`
export const SUPPRIMER_TACHE = `${process.env.REACT_APP_API_URL}/params/supprimer-tache`
export const MODIFIER_ETAT_TACHE = `${process.env.REACT_APP_API_URL}/params/modifier-etat-tache`

// Listes Pays
export const LISTE_PAYS = `${process.env.REACT_APP_API_URL}/params/liste-pays`

// Gestion CLIENTS
export const LISTE_CLIENTS_LEA = `${process.env.REACT_APP_API_URL}/lea/clients/liste-client`
export const SELECT_CLIENT = `${process.env.REACT_APP_API_URL}/lea/clients/select-one-client`
export const LISTE_CLIENTS_LEA_ACTIFS = `${process.env.REACT_APP_API_URL}/lea/clients/liste-active`
export const AJOUTER_CLIENT_LEA = `${process.env.REACT_APP_API_URL}/lea/clients/add-client`
export const MODIFIER_CLIENT_LEA = `${process.env.REACT_APP_API_URL}/lea/clients/modifier-client`
export const SUPPRIMER_CLIENT_LEA = `${process.env.REACT_APP_API_URL}/lea/clients/supprimer-client`
export const MODIFIER_ETAT_CLIENT_LEA = `${process.env.REACT_APP_API_URL}/lea/clients/modifier-etat-client`

// Gestion EXERCICE
export const LISTE_EXERCICE_LEA = `${process.env.REACT_APP_API_URL}/lea/exercice/liste-exercices-audit-client`
export const LISTE_EXERCIE_CLIENTS_LEA = `${process.env.REACT_APP_API_URL}/lea/exercice/selectionner-exercices-audit-client`
export const AJOUTER_EXERCICE_CLIENT_LEA = `${process.env.REACT_APP_API_URL}/lea/exercice/ajouter-exercice-client`
export const SELECTIONNER_EXERCICE_LEA = `${process.env.REACT_APP_API_URL}/lea/exercice/selectionner-un-exercice`
export const AJOUTER_FICHIER_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/upload-fichier-exercice`
export const LISTE_FICHIER_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/liste-fichier-exercice`
export const LISTE_EQUIPE_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/membre-equipe-mission`
export const MEMBRE_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/membre-mission`
export const LISTE_USER_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/user-mission`
export const EQUIPE_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/constituer-equipe-mission`
export const ADD_CHEF_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/ajouter-chef-mission`
export const REPONDRE_QUESTION_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/repondre-question-exercice`
export const REPONDRE_QUESTION_ONLY_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/repondre-question-exercice-only`
export const MODIFIER_REPONDRE_QUESTION_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/modifier/repondre-question-exercice`
export const LISTE_REPONSE_QUESTION = `${process.env.REACT_APP_API_URL}/lea/exercice/liste-reponses-questions-exercice`
export const LISTE_MOTIF_ACCEPTATION_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/liste-motif-acceptation`
export const LISTE_QUESTION_ACCEPATION_SUPERVISEUR = `${process.env.REACT_APP_API_URL}/lea/exercice/liste-question-accepter-refuser-mission`
export const VERROU_QUESTION_EXERCICE = `${process.env.REACT_APP_API_URL}/lea/exercice/modifier-verou-question-mission`
export const OBSERVATION_GENERALE = `${process.env.REACT_APP_API_URL}/lea/exercice/enregistrer-observation-generale-mission`
export const REPONSE_ACCEPTATION = `${process.env.REACT_APP_API_URL}/lea/exercice/modifier-etat-mission`

// Gestion STRATEGIE GLOBALE
export const STRATEGIE_GLOBALE_MISSION = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale`
export const CONTEXTE_STRATEGIE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale/enregistrer-contexte`
export const ORGANISATION_STRATEGIE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale/enregistrer-organisation`
export const STRATEGIE_POLITQUE_COMMERCIALE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale/enregistrer-politique-commerciale`
export const STRATEGIE_ZONE_RISQUE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale/enregistrer-zone-rique`
export const STRATEGIE_INFO_GENERALE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale/enregistrer-info-generale`
export const STRATEGIE_ANOMALIE_POSSIBLE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale/enregistrer-anomalie-possible`
export const STRATEGIE_OBSERVATION_GENERALE = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie-globale/enregistrer-observation-generale`
export const STRATEGIE_GLOBAL_SIGNIFICATION = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/liste-global-signification`
export const AJOUT_STRATEGIE_GLOBAL_SIGNIFICATION = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-global-signification`

// Gestion FICHIER
export const UPLOAD_FILE = `${process.env.REACT_APP_API_URL}/lea/clients/upload-client-file`
export const UPLOAD_MODELE_LETTRE_MISSION = `${process.env.REACT_APP_API_URL}/lea/exercice/upload-model-lettre-mission`
export const UPLOAD_LETTRE_MISSION_SIGNE = `${process.env.REACT_APP_API_URL}/lea/exercice/upload-lettre-mission-signe`
export const UPLOAD_FORMULAIRE_QUESTION_MISSION = `${process.env.REACT_APP_API_URL}/lea/exercice/upload-questionnaire-exercice`
export const LISTE_FICHE_CLIENT_LEA = `${process.env.REACT_APP_API_URL}/lea/clients/liste-fichier-client`
export const LISTE_LETTRE_MISSION_SIGNE = `${process.env.REACT_APP_API_URL}/lea/exercice/liste-lettre-mission-signe`
export const LISTE_DOCUMENT_MISSION = `${process.env.REACT_APP_API_URL}/lea/exercice/liste-fichier-exercice`
export const LISTE_MODELE_LETTRE_MISSION = `${process.env.REACT_APP_API_URL}/lea/exercice/liste-modele-lettre-mission`
export const DOWNLOAD_DOCUMENT_CLIENT = `${process.env.REACT_APP_API_URL}`
export const DOWNLOAD_FILE_CLIENT = `${process.env.REACT_APP_API_URL}/files`
// export const DOWNLOAD_FILE_CLIENT = `${process.env.REACT_APP_API_URL}/api/files/download`



// GESTION BALANCE
// export const LISTE_PLAN_COMPTABLE = `${process.env.REACT_APP_API_URL}/lea/exercice/enregistrer/balance`
// export const LISTE_PLAN_COMPTABLE_ACTIVE = `${process.env.REACT_APP_API_URL}/lea/exercice/enregistrer/balance`
export const AJOUTER_BALANCE = `${process.env.REACT_APP_API_URL}/lea/exercice/balance/enregistrer`
export const SELECT_BALANCE_PROVISOIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/balance/provisoire`
export const SELECT_BALANCE_DEFINITIF = `${process.env.REACT_APP_API_URL}/lea/exercice/balance/definitif`


// GRAND LIVRE
export const AJOUTER_GRANDLIVRE = `${process.env.REACT_APP_API_URL}/lea/exercice/grand-livre/enregistrer`
export const SELECT_GRANDLIVRE_PROVISOIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/grand-livre/provisoire`
export const SELECT_GRANDLIVRE_DEFINITIF = `${process.env.REACT_APP_API_URL}/lea/exercice/grand-livre/definitif`

// GRAND LIVRE
export const AJOUTER_RATEAU = `${process.env.REACT_APP_API_URL}/lea/exercice/rateau/enregistrer`
export const LISTE_RATEAUX = `${process.env.REACT_APP_API_URL}/lea/exercice/rateau/liste`
export const LISTE_RATEAUX_NEXT_CONTROLE = `${process.env.REACT_APP_API_URL}/lea/exercice/rateau-controle/liste`

// USERS ETAT FINANCIER
export const AJOUTER_USER_ETAT_FINANCIER = `${process.env.REACT_APP_API_URL}/lea/params/etats-financier/ajouter-utilisateur`
export const LISTE_USER_ETAT_FINANCIER = `${process.env.REACT_APP_API_URL}/lea/params/etats-financier/liste-utilisateurs`
export const AJOUTER_STRATEGIE_USER_ETAT_FINANCIER = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/ajouter-principaux-utilisateur`
export const LISTE_STRATEGIE_USER_ETAT_FINANCIER = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/liste-principaux-utilisateur`
export const LISTE_STRATEGIE_GLOBAL_SIGNIFICATION = `${process.env.REACT_APP_API_URL}/lea/exercice/strategie/liste-global-signification`


export const LISTE_IDENTIFICATION_SOMMAIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/identification/sommaire`
export const LISTE_IDENTIFICATION_MISSION = `${process.env.REACT_APP_API_URL}/lea/exercice/identification`
