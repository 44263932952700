import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/oswald/400.css";
import "@fontsource/oswald/500.css";
import "@fontsource/oswald/600.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as StoreProvider } from "react-redux";
import App from "./App";
import { ThemeProvider } from "styled-components";
import locale from "antd/locale/fr_FR";
import "simplebar-react/dist/simplebar.min.css";
import "antd/dist/reset.css";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";

import { store } from './store/user/store';
import { antAppToken, appTheme } from "./config/theme";
import { ConfigProvider } from "antd";
import InactivityLogoutTimer from "./config/InactivityLogoutTimer";

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <StoreProvider store={store}>
            <ThemeProvider theme={appTheme}>
                <ConfigProvider locale={locale} theme={{ token: antAppToken }}>
                    <ChakraProvider>
                        <App />
                        {/*<InactivityLogoutTimer/>*/}
                    </ChakraProvider>
                </ConfigProvider>
            </ThemeProvider>
        </StoreProvider>
    </React.StrictMode>
);
