const PROCEDURE_AUDIT = {
    ImIncorporelles: {
        travaux: [
            {
                code: '21',
                libelle: 'IMMOBILISATIONS INCORPORELLES'
            },
            {
                code: '281',
                libelle: 'AMORTISSEMENTS DES IMMOBILISATION INCORPORELLES'
            },
            {
                code: '6812',
                libelle: 'DOTATIONS AUX AMORTISSEMENTS DES IMMOBILISATION INCORPORELLES'
            },
        ],
        objectifs: [
            {
                code: 'A',
                libelle: "S'assurer que tous les frais engagés par la société et répondant au définitions des postes listés ci-dessous sont inscrit s à l'actifs de façon constante et conformément aux principes comptables généralement admis"
            },
            {
                code: 'B',
                libelle: "S'assurer que l'amortissement des éléments susceptibles d'être amortis est calculé de façon constante."
            },
            {
                code: 'C',
                libelle: "S'assurer qu'il n'existe aucune perte latente par rapports aux valeurs nettes comptables des immobilisations incorporelles."
            },
            {
                code: 'D',
                libelle: "A la fin des contrôles effectués, poter un jugement sur les postes principaux suivants."
            },
        ],
        bilan: [
            {
                code: '21',
                libelle: 'Immobilisation incorporelles'
            },
            {
                code: '281',
                libelle: 'Amortissement des immobilisations incorporelles'
            },
        ],
        compteResultat: [
            {
                code: '811',
                libelle: 'Valeurs comptables des immobilisations incorporelles cédées'
            },
            {
                code: '6812',
                libelle: 'Dotation aux amortissements des immobilisations incorporelles'
            },
            {
                code: '6813',
                libelle: 'Dotation aux provisions pour dépréciation des immobilisations incorporelles'
            },
            {
                code: '7076',
                libelle: 'Redevances pour concessions, brevets, licences...'
            },
            {
                code: '821',
                libelle: 'Produits de cession des immobilisations incorporelles'
            },
            {
                code: '7913',
                libelle: 'Reprise sur amortissements des immobilisations incorporelles'
            },
            {
                code: '721',
                libelle: 'Production d\'immobilisations incorporelles',
            },
        ],
    },
    Corporelles: {
        travaux: [
            {
                code: '23',
                libelle: 'BÂTIMENTS, INSTALLATION TECHNIQUES'
            },
            {
                code: '283',
                libelle: 'AMORTISSEMENTS DES BÂTIMENTS, INSTALLATIONS TECHNIQUES ET AGENCEMENTS'
            },
            {
                code: '24',
                libelle: 'MATERIEL, MOBILIER ET ACTIFS BIOLOGIQUES'
            },
            {
                code: '284',
                libelle: 'AMORTISSEMENTS DU MATERIEL'
            },
            {
                code: '6813',
                libelle: 'DOTATIONS AUX AMORTISSEMENTS DES IMMOBILISATION CORPORELLES'
            },
            {
                code: '822',
                libelle: 'PRODUITS DES CESSIONS D\'IMMOBILISATIONS CORPORELLES'
            },
        ],
        objectifs: [
            {
                code: 'A',
                libelle: "S'assurer les montant inscrits au postes d'immobilisation reflêtent l'intégralité : des biens dont l'entreprise est proritaire et qu'elle utilise, des coûts encourus pour l'acquisition ou la création de ces biens."
            },
            {
                code: 'B',
                libelle: "S'assurer que les montants figurant en dotation aux amortissements cumulés reflètent l'intégralité des amortissements calculés conformément aux principes comptables généralement admis, appliqués de façon constante."
            },
            {
                code: 'C',
                libelle: "À la fin  des contrôles effecutés, porter un jugement sur les postes principaux suivants."
            },
        ],
        bilan: [
            {
                code: '22',
                libelle: 'Terrains'
            },
            {
                code: '23',
                libelle: 'Batiments, Installations techniques et engencement'
            },
            {
                code: '24',
                libelle: 'Matériel'
            },
            {
                code: '25',
                libelle: "Avances et acomptes sur commandes d'immobilisations"
            },
            {
                code: '282',
                libelle: "Amortissements des terrains"
            },
            {
                code: '283',
                libelle: "Amortissements des bâtiments, installations techniques et agencements"
            },
            {
                code: '284',
                libelle: "Amortissements du matériel"
            },
            {
                code: '292',
                libelle: "Provisions pour dépréciation des terrains"
            }
        ],
        compteResultat: [
            {
                code: '6813',
                libelle:'Dotation aux amortissements des immobilisations corporelles'
            },
            {
                code: '6814',
                libelle:'Dotation aux provisions pour dépréciation des immobilisations corporelles...'
            },
            {
                code: '7073',
                libelle:'Location des immeubles non affectés aux activités professionnelles'
            },
            {
                code: '722',
                libelle:'Production immobilisée : Immobilisations corporelles'
            },
            {
                code: '78',
                libelle:'Transferts de charges'
            },
            {
                code: '7914',
                libelle:'Reprise sur provisions pour dépréciation des immobilisations corporelles'
            },
            {
                code: '812',
                libelle:"Valeurs comptables des cessions d'immobilisations corporelles"
            },
            {
                code: '822',
                libelle:"Produits de cession des immobilisations corporelles"
            },
        ],
        etatAnnexe: [

        ]
    },
    ImFinanciere: {
        travaux : [
            {
                code: '27',
                libelle: 'AUTRES IMMOBILISATIONS FINANCIERES'
            },
            {
                code: '50',
                libelle: 'TITRES DE PLACEMENT'
            },
            {
                code: '677',
                libelle: 'PERTES SUR TITRES DE PLACEMENT'
            },
            {
                code: '774',
                libelle: 'REVENUS DE PLACEMENT'
            },
        ],
        objectifs: [
            {
                code: 'A',
                libelle: "S'assurer les montants inscrits au bilan en titres de participation, en titres de placement : Et en comptes courant reflètent l'ensemble des titres apprtenant à la société, valorisés. En accord avec les principes comptables applicables à chacun de ces actifs"
            },
            {
                code:'B',
                libelle:"S'assurer que les montants inscrits au de résultat représentent bien l'intégralité des produits, gains ou pertes imputables à l'exercice et correspondant à des transactions. Effectués à des conditions normales"
            }
        ],
        bilan:[
            {
                code: '26',
                libelle: "Titres de participations"
            },
            {
                code: '271',
                libelle: "Prêts et créances non commerciales"
            },
            {
                code: '272',
                libelle: "Prêts au personnel"
            },
            {
                code: '273',
                libelle: "Créances sur l'Etat"
            },
            {
                code: '274',
                libelle: "Titres immobilisés"
            },
            {
                code: '275',
                libelle: "Dépôts et cautionnement versés"
            },
            {
                code: '276',
                libelle: "Intérêts courus"
            },
            {
                code: '277',
                libelle: "Créances rattachés à des participations et avances à des GIE"
            },
        ],
        compteResultat:[
            {
                code: '677',
                libelle: "Pertes sur cession de titres de placement"
            },
            {
                code: '6972',
                libelle: "Dotation aux provisions pour dépréciation des éléments financiers"
            },
            {
                code: '772',
                libelle: "Revenus de titres de participation"
            },
        ],
        etatAnnexe:[]
    },

    Stocks:{
        travaux : [],
        objectifs: [
            {
                code: 'A',
                libelle: "S'assurer les montants inscrits dans les comptes annuels représentent toutes les produits"
            },
            {
                code:'B',
                libelle:"S'assurer que ces produits sont évalués au plus bas du prix de revient ou de la valeur réaliste"
            },
            {
                code:'C',
                libelle:"S'assurer que les différences constatées entre l'inventaire permanent d'une part et l'inventaire physsique d'autre part"
            }
        ],
        bilan:[
            {
                code: '31',
                libelle: "Marchandises"
            },
           
            {
                code: '32',
                libelle: "Matières prémières et fournitures liées"
            },

            {
                code: '33',
                libelle: "Autres approvisionnement (matières consommables, fournitures, divers)"
            },

            {
                code: '34',
                libelle: "Produits en cours"
            },

            {
                code: '35',
                libelle: "Services en cours"
            },

            {
                code: '36',
                libelle: "Stocks de produits finis"
            },

            
            {
                code: '37',
                libelle: "Produits intermédiaires et résiduels"
            },

            {
                code: '38',
                libelle: "Stocks en cours de route à réceptionner, en consignation"
            },

            {
                code: '39',
                libelle: "Provision pour dépréciation des stocks et en cours"
            },
        ],

        compteResultat:[
            {
                code: '6031',
                libelle: "Variation des stocksde marchandises"
            },

            {
                code: '6032',
                libelle: "Variation des stocks de matières"
            },

            {
                code: '6033',
                libelle: "Variation des stocks des autres apporovisionnements"
            },

            {
                code: '6593',
                libelle: "Charges provisionnés sur stocks et en cours"
            },

            {
                code: '734',
                libelle: "Variation des stocks de la production stockée"
            },

            {
                code: '735',
                libelle: "Variation des en cours de services"
            },

            {
                code: '736',
                libelle: "Variation des stocks de la production stockée"
            },

            {
                code: '737',
                libelle: "Variation des stocks de produits intermédiaires et résiduels"
            },

            {
                code: '7593',
                libelle: "Reprise sur charges provisionnés sur stocks et en cours"
            },
        ],
        etatAnnexe:[]
    },

    Ventes_client:{
        travaux : [
            {
                code: '41',
                libelle: "Clients et comptes rattachés"
            },
            {
                code: '70',
                libelle: "Ventes"
            },
            {
                code: '651',
                libelle: "PERTES SUR CREANCES CLIENTS ET AUTRES DEBITEURS CLIENTS"
            },
            {
                code: '6594',
                libelle: "CHARGES POUR DEPRECIATIONS ET PROVISIONS POUR RISQUES"
            },
            {
                code: '7594',
                libelle: "PERTES SUR CREANCES CLIENTS ET AUTRES DEBITEURS CLIENTS"
            },
        ],

        objectifs: [
            {
                code: 'A',
                libelle: "S'assurer que les produits et charges inscrits de résultat et provenant des opérations de ventes résultent uniquement : de l'enregistrement intégral des transactions réalisées dans l'exercice comptable considéré"
            },
            {
                code:'B',
                libelle:"S'assurer que les comptes de tiers inscrits au bilan et provenant des opérations de ventes sont correctement évalués et bien classifiés"
            },
            {
                code:'C',
                libelle:"A la fin des contrôles effectués, porter un jugement sur les postes principaux suivants."
            }
        ],
        bilan:[
            {
                code: '41',
                libelle: "Clients et comptes rattachés"
            },
           
            {
                code: '491',
                libelle: "Provisions pour dépréciation des comptes de clients"
            }
        ],

        compteResultat:[
            {
                code: '70',
                libelle: "Ventes"
            },

            {
                code: '6594',
                libelle: "Dotation aux provisions pour dépréciation des actifs circulants (créances)"
            },

            {
                code: '7594',
                libelle: "Reprise sur provisions pour dépréciation des actifs circulants (créances)"
            },

           
        ],
        etatAnnexe:[]
    },

    creance_dette:{
        travaux : [
            {
                code: '47',
                libelle: "Débiteurs et créditeurs divers"
            },
            {
                code: '65',
                libelle: "Autres charges"
            },
            {
                code: '75',
                libelle: "Autres produits"
            }
        ],

        objectifs: [
            {
                code: 'A',
                libelle: "S'assurer que les soldes figurant à l'actif du bilan concernent des montants à recevoir entièrement recouvrables."
            },
            {
                code:'B',
                libelle:"S'assurer que toutes les dettes diverses de l'entreprise sont comptabilisées ou approvisionnées"
            },
            {
                code:'C',
                libelle:"S'assurer les produits et charges divers des gestion sont comptabilisées"
            },
            {
                code:'D',
                libelle:"A la fin des contrôles effectués, porter un jugement sur les postes principaux suivants"
            }
        ],

        bilan:[
            {
                code: '45',
                libelle: "Organismes internationaux"
            },
           
            {
                code: '46',
                libelle: "Apporteurs, Associés et groupe "
            },

            {
                code: '47',
                libelle: "Débiteurs divers et créditeurs divers"
            },

            {
                code: '48',
                libelle: "Créances et dettes HAO"
            },

            {
                code: '495',
                libelle: "Dépréciations des comptes des organismes internationaux "
            },

            
            {
                code: '497',
                libelle: "Dépréciations des comptes des orgnisemes des compte débteurs et créditeurs divers"
            },

            {             
                code: '498',
                libelle: "Dépréciations des comptes des créances (HAO)"
            },
        ],

        compteResultat:[
            {
                code: '65',
                libelle: "Autres charges"
            },

            {
                code: '75',
                libelle: "Autres produits"
            },

            {
                code: '707',
                libelle: "Produit accessoires"
            },

            {
                code: '71',
                libelle: "Subventions d’exploitation "
            },
           
        ],
        etatAnnexe:[]
    },

    tresorerie:{
        travaux : [
            {
                code: '47',
                libelle: "Débiteurs et créditeurs divers"
            },
            {
                code: '65',
                libelle: "Autres charges"
            },
            {
                code: '75',
                libelle: "Autres produits"
            }
        ],

        objectifs: [
            {
                code: 'A',
                libelle: "S'assurer que la situation de la trésoreire l'entreprise à la clôture de l'exercice est reflétée de façon exacte par les montants"
            },
            {
                code:'B',
                libelle:"S'assurer que les frais et produits financiers concernant les opérations de trésorerie inscrits au compte de résultat"
            },
            {
                code:'C',
                libelle:"S'assurer que tous les produits et charges divers de gestion sont comptabilisés"
            },
            {
                code:'D',
                libelle:"A la fin des contrôles effectués, porter un jugement sur les postes principaux suivants"
            }
        ],

        bilan:[
            {
                code: '51',
                libelle: "Valeurs à encaisser"
            },
           
            {
                code: '52',
                libelle: "Banque"
            },

            {
                code: '53',
                libelle: "Etablissement financiers et assimiliés"
            },

            {
                code: '54',
                libelle: "Instrument de trésorerie"
            },

            {
                code: '56',
                libelle: "Banque, crédits de trésorerie et d’escompte "
            },

            
            {
                code: '57',
                libelle: "Caisse"
            },

            {             
                code: '58',
                libelle: "Régie d'avances, accréditifs et virements internes"
            },
            
            {             
                code: '599',
                libelle: "Provisions pour risque à court terme à caractère financier"
            },
        ],

        compteResultat:[
            {
                code: '6',
                libelle: "Tout compte de charges décaissables"
            },

            {
                code: '7',
                libelle: "Tout compte de produits encaissables"
            },
           
        ],
        etatAnnexe:[]
    },

    capitaux_propres:{
        travaux:[
            {
                code:'10',
                libelle:'Provisions pour risques et charges'
            },

            {
                code:'11',
                libelle:"Dotations aux provisions et aux dépréciations d'exploitation pour risques et charges"
            }
        ],
        objectifs:[
            {
                code: 'A',
                libelle: "S'assurer que les comptes annuels présentent: le capital, les réserves, les subventions et les provisions réglementées, ainsi que l'affectation des résultats, conformément aux règles et principes généralement admis"
            },
            {
                code :'B',
                libelle:"S'assurer que la loi sur les sociétés et les status de la société ont été respectés"
            },
            {
                code :'C',
                libelle:"A la fin des contrôles effecutés, porter un jugement sur les postes principaux suivants:"
            }
        ],
        bilan:[
            {
                code:'101',
                libelle:'Capital social'
            },
            {
                code:'102',
                libelle:'Fonds de dotation'
            },
            {
                code:'103',
                libelle:"Capital personnel"
            },
            {
                code:'104',
                libelle:"Compte de l'exploitation"
            },
            {
                code:'105',
                libelle:"Primes liées au capital social (émission, apport, fusion,scission)"
            },
            {
                code:'106',
                libelle:'Ecart de réévaluation',
            },
            {
                code :'109',
                libelle:'Actionnaire capital souscrit non appelé'
            },
            {
                code:'11',
                libelle:'Réserves'
            },
            {
                code :'12',
                libelle:'Report à nouveau (solde créditeur ou débiteur)'
            },
            {
                code :'13',
                libelle:"Résultat de l'exercice (bénéficiaire ou perte)"
            },
            {
                code:'14',
                libelle:"Subvention d'investissement"
            }
        ],
        compteResultat:[],
        etatAnnexe:[]
    },

    provision_risques:{
        travaux:[
            {
                
            }
        ],
        objectifs:[
            {
                code:'A',
                libelle:"S'assurer que les montants figurant au bilan sont justifiés et suffisants pour couvrir les risques encourus par l'exercice"
            },
            {
                code:'B',
                libelle:"S'assurer que les dotations et reprises, au compte de résultat sont correctement comptabilisées"
            },
            {
                code:'C',
                libelle:'A la fin des contrôles effectués, porter un jugement sur les principaux comptes suivants'
            }
        ],
        bilan:[
            {
                code:'19',
                libelle:'Provisions financières pour risques et charges'
            }
        ],
        compteResultat:[
            {
                code:'6911',
                libelle:"Dotation aux provisions pour risques et charges d'exploitation"
            },
            {
                code:'6912',
                libelle:"Dotation aux provisions pour grosses réparations"
            },
            {
                code:'6971',
                libelle:'Dotations provisions pour risques et charges financiers'
            },
            {
                code:'7911',
                libelle:"Reprises sur provisions pour risques et charges d'exploitation"
            },
            {
                code :'7912',
                libelle:"Reprises sur provisions pour risques et charges financiers"
            }
        ],
        etatAnnexe:[]
    },

    emprunt_assimiles:{
        travaux:[
            {
                code:'16',
                libelle:'Emprunts et dettes assimiliées'
            }
        ],
        objectifs:[
            {
                code:'A',
                libelle:"S'assurer que les montants inscrits au bilan sont correctement évalués et bien classifiés, et qu'ils reflètent l'intégralité des emprunts et dettes"
            },
            {
                code:'B',
                libelle:"S'assurer que les montants inscrits au compte de résultat reflètent des charges aux produits pour l'année"
            },
            {
                code:'C',
                libelle:"A la fin des contrôles effectués, porter un jugement sur les principaux postes suivants:"
            }
        ],
        bilan:[
            {
                code:'16',
                libelle:'Emprunts et dettes assimilées'
            },
            {
                code:'17',
                libelle:'Dettes de crédit-bail et contrats assimilés'
            }
        ],
        compteResultat:[
            
            {
                code:'67',
                libelle:"Autres comptes en relation avec les compes 16 et 17"
            },
            {
                code:'671',
                libelle:'Intérêts et emprunts de dettes'
            },
            {
                code:'672',
                libelle:"Intérêts des loyers de crédit-bail et contrats assimilés"
            },
            {
                code:'6781',
                libelle:'Pertes sur risques financiers sur rentes viagères'
            }
            
        ],
        etatAnnexe:[]
    },

    achat_fournisseur :{
        travaux:[
            {
                code:"40",
                libelle:"Fournisseurs et comptes rattachés"
            },
            {
                code:"60",
                libelle:"Achats et variation de stocks"
            },
            {
                code:"61",
                libelle:"Transports"
            },
            {
                code:"62",
                libelle:"Transport services extérieurs"
            },
            {
                code:"63",
                libelle:"Autres services extérieurs"
            },
            {
                code:"65",
                libelle:"Autres charges"
            }
        ],
        objectifs:[
            {
                code:'A',
                libelle:"S'assurer que les charges et produits inscrits au compte de résultat et provenant des opérations d'achats résultent uniquement de l'enregistrement intégral des transactions réalisées dans l'exercice comptable considéré"
            },
            {
                code:'B',
                libelle:"S'assurer que les comptes de tiers inscrits au bilan et provenant des opérations d'achats sont correctement évalués et bien classifiés"
            },
            {
                code:'C',
                libelle:"A la fin des contrôles effectués, porter un jugement sur les principaux postes suivants."
            }
        ],
        bilan:[
            {
                code:"40",
                libelle:"Fournisseurs d'approvisionnement et comptes rattachés"
            },
            {
                code:"482",
                libelle:"Fournisseurs d'investissement et effets à payer"
            }
        ],
        compteResultat:[
            {
                code:"60",
                libelle:"Achats"
            },
            {
                code:'61',
                libelle:'Transports'
            },
            {
                code:'62',
                libelle:"Services extérieurs A"
            },
            {
                code:'63',
                libelle:"Service extérieurs B"
            },
            {
                code:"65",
                libelle:"Autres charges"
            }
        ],
        etatAnnexe:[]
    },

    impot_taxes:{
        travaux: [
            {
                code:"44",
                libelle:"Etat et collectivité publiques"
            },
            {
                code:"64",
                libelle:"Impôts et Taxes"
            },
            {
                code:"89",
                libelle:"Impôts sur le résultat"
            }
        ],
        objectifs:[
            {
                code:"A",
                libelle:"S'assurer que les déclarations sont faites et déposées dans les détails légaux"
            },
            {
                code:"B",
                libelle:"Apprécier le risque fiscal encouru par la société"
            },
            {
                code:"C",
                libelle:"A la fin des contrôles, faire des observations et formuler des recommandations aux fins d'amélioration de la situation fiscale de la société"
            }
        ],
        bilan:[],
        compteResultat:[],
        etatAnnexe:[],
        docs:[
            {
              code:"A",
              libelle:"Les déclarations des périodes sous-contrôle"  
            },
            {
                code:"B",
                libelle:"La balance générale"
            },
            {
                code:"C",
                libelle:"La liasse fiscale"
            }
        ]
    },

    paie_personnel:{
        travaux:[],
        objectifs:[
            {
                code:"A",
                libelle:"S'assurer que les charges et produits inscrits au compte de résultat et ayant pour origines les droits et obligations de l'entreprise : vis-à-vis de son personnel, résultent uniquement et l'enregistrement de intégral des transactions réalisées dans l'exercice comptable considéré"
            },
            {
                code:"B",
                libelle:"S'assurer que les comptes de tiers inscrits au bilan et provenant des transactions avec le personnel sont correctement évalués et bien classifiés"
            },
            {
                code:"C",
                libelle:"A la fin des contrôles effectués, porter un jugement sur les postes principaux suiviants:"
            }
        ],
        bilan:[
            {
                code:"42",
                libelle:"Personnel"
            },
            {
                code:"43",
                libelle:"Organismes sociaux"
            },
            {
                code:"66",
                libelle:"Charges de personnel"
            }
        ],
        compteResultat:[
            {
                code:"66",
                libelle:"Charges de personnel"
            }
        ],
        etatAnnexe:[]
    },

    controle_tft:{
        objectifs : [
            {
                code:"A",
                libelle:"Chacun des tableaux du TAFIRE doit être vérifié, il s'agira surtout de vérification de concordance avec les autres états financiers"
            }
        ]
    }


}

const questionnaireCycles = [
    {
        id: 1,
        procedureAudit: 'Auditeur précédent / Comptable A-TON OBTENU DES REPONSES AUX INTERROGATIONS/PREOCUPATIONS CI DESSOUS ',
        ref: '1.a',
        procedure: ['Depuis combien d\'année environ l\'entité a t-elle fait l\'objet d\'un audit ou d\'un examen?'],
        refActivite: '',
        assertions: ["A", "C"]
    },
    {
        id: 2,
        procedureAudit: 'Auditeur précédent / Comptable A-TON OBTENU DES REPONSES AUX INTERROGATIONS/PREOCUPATIONS CI DESSOUS ',
        ref: '1.b',
        procedure: ["Quels sont le non et l'adresse du dernier auditeur/comptable ?"],
        refActivite: '',
        assertions: ["V", "E/R"]
    },
    {
        id: 3,
        procedureAudit: 'Auditeur précédent / Comptable A-TON OBTENU DES REPONSES AUX INTERROGATIONS/PREOCUPATIONS CI DESSOUS ',
        ref: '1.c',
        procedure: ["L'opinion d'audit ou du rapport de mission d'examen a-t-elle été modifiée ou les états financiers ont-ils été retraités au cours des cinq dernières années? Dans l'affirmative veuillez fournir des détails."],
        refActivite: '',
        assertions: ["A", "E/R"]
    },
    {
        id: 4,
        procedureAudit: 'Auditeur précédent / Comptable A-TON OBTENU DES REPONSES AUX INTERROGATIONS/PREOCUPATIONS CI DESSOUS ',
        ref: '1.d',
        procedure: ["Un paragraphe d'observation ou d'autres informations ont-elles été ajoutées aux rapports d'audit ou examen précédent ?"],
        refActivite: '',
        assertions: ["V", "E/R"]
    },
    {
        id: 5,
        procedureAudit: 'Dossier de travail de l\'auditeur/comptable précédent',
        ref: '2.a',
        procedure: [
            "Analyse des soldes d'ouverture, y compris les informations à fournit et les questions relatives à la qualité des pièce justificative de ces soldes d'ouverture",
            "Pour les estimations et les jugements comptables essentiels",
            "Pour les soldes dont le contrôle interne présente des déficiences qui ont été signalées aux personnes en charge de la gouvernance",
            "Lorsqu'un contrôle plus approfondi est nécessaire pendant l'année en cours",
            "Lorsque le solde de clôture peut être vérifié par des événements subséquents"
        ],
        refActivite: '',
        assertions: ["V", "E/R"]
    },
    {
        id: 6,
        procedureAudit: "Dossier de travail de l'auditeur/comptable précédent",
        ref: '2.b',
        procedure: ["Application des méthodes comptables"],
        refActivite: '',
        assertions: ["C", "A"]
    },
    {
        id: 7,
        procedureAudit: "Dossier de travail de l'auditeur/comptable précédent",
        ref: '2.c',
        procedure: ["Anomalies corrigées et non corrigées"],
        refActivite: '',
        assertions: ["A"]
    },
    {
        id: 8,
        procedureAudit: "Dossier de travail de l'auditeur/comptable précédent",
        ref: '2.d',
        procedure: ["Résultats d'audit significatifs"],
        refActivite: '',
        assertions: ["A"]
    },
]

const processData = [
    {
        id: 1,
        reference: "1",
        codeSommaire: "EE3.1",
        libelle: [
            "A partir de la feuille maîtresse, sélectionner les comptes à contrôler. Pour chaque compte sélectionné, tirer l’extrait du grand livre, sélectionner et vérifier les mouvements significatifs ou présentant un caractère particulier de l’exercice avec les justificatifs.. ",
            "S’assurer que les montants passés dans le compte répondent à la définition juridique et comptable des immobilisations incorporelles.  (Se référer impérativement au référentiel comptable applicable) Prêter une attention aux libellés des écritures"
        ],
        assertions: [
            "A",
            "C",
            "E/R"
        ],
    }
]

export {
    PROCEDURE_AUDIT, questionnaireCycles
}