import axios from "axios";

const AJOUTER_RISQUE_IDENTIFICATION = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/ajouter-risque-identification`
const MODIFIER_RISQUE_IDENTIFICATION = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/modifier-risque-identification`
const LISTE_RISQUE_IDENTIFICATION_ACTIVITE = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-identification/activite`
const LISTE_RISQUE_IDENTIFICATION_FRAUDE = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-identification/fraude`
const LISTE_RISQUE_IDENTIFICATION_INFO = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-identification/info`
const LISTE_RISQUE_IDENTIFICATION_BLANCHIMENT = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-identification/blanchiment`
const LISTE_RISQUE_IDENTIFICATION_RESPECT = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-identification/respect`
// const LISTE_RISQUE_IDENTIFICATION_All = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/liste-risque-identification/liste`

export const ajouterRisqueIdentification = async (data) => {
    return axios.post(AJOUTER_RISQUE_IDENTIFICATION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const modifierRisqueIdentification = async (data) => {
    return axios.post(MODIFIER_RISQUE_IDENTIFICATION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueIdentificationActivite = async (exercice_id) => {
    return axios.get(`${LISTE_RISQUE_IDENTIFICATION_ACTIVITE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueIdentificationFraude = async (exercice_id) => {
    return axios.get(`${LISTE_RISQUE_IDENTIFICATION_FRAUDE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueIdentificationInformatique = async (exercice_id) => {
    return axios.get(`${LISTE_RISQUE_IDENTIFICATION_INFO}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueIdentificationBlanchiment = async (exercice_id) => {
    return axios.get(`${LISTE_RISQUE_IDENTIFICATION_BLANCHIMENT}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsRisqueIdentificationRespect = async (exercice_id) => {
    return axios.get(`${LISTE_RISQUE_IDENTIFICATION_RESPECT}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

