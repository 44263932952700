import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Input, notification, Row, Select, Space, Table, Tag, Tooltip} from "antd";
import { DeleteOutlined} from "@ant-design/icons";
import {getListeAssertionActive} from "../../../../../../../data/params/assertion";
import {dataTestJournaux} from "../../../ED/risquesData";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faCloudUpload} from "@fortawesome/free-solid-svg-icons";
import {ModalTitle} from "../../../../../../modals/modals.components";
import ModalAlert from "../../../../../../modals/Modal";
import FormLabel from "../../../../../../common/Form/FormLabel";
import {FormContainer} from "../../../../../../common/common.components";

const TraitementTestJournaux = ({
        selectItems,
        setSelectItems,
        conclusion,
        setConclusion,
        setIsAddMemoire,
        chargement,
        isAddMemoire,
        mission,
        setIsTaskSave,
    }) => {
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [assertions, setAssertions] = useState([]);
    const [questions] = useState(dataTestJournaux || []);
    const [questionsFiltered, setQuestionsFiltered] = useState(dataTestJournaux || []);
    const [assertion, setAssertion] = useState('');
    const [questionsTravail, setQuestionsTravail] = useState([]);
    const [groupQuestions, setGroupQuestions] = useState([]);

    useEffect(() => {
        fetchAssertion();
    }, []);

    const fetchAssertion = async () => {
        try {
            const res = await getListeAssertionActive();
            setAssertions(res.result);
        } catch (error) {
            console.error('Erreur lors de la récupération des rubriques', error);
        }
    };

    useEffect(() => {
        const libelleGroup = [...new Set(questions.map((item) => item.groupe))];
        setGroupQuestions(libelleGroup)
    }, [setGroupQuestions, questions])

    const handleAdd = () => {
        setLoading(true);
        setTimeout(() => {
            const newElement = {
                question_id: null,
                groupe: '',
                ref: '',
                libelle: [''],
                reponse: '1',
                commentaire: '',
                assertions: [],
                isEdited: true
            };

            setSelectItems([...selectItems, newElement]);

            setIsTaskSave(prevState => [...prevState, 2]);
            setLoading(false);
            notification.success({
                description: "Question ajouté avec succès !",
            });
        }, 1000)
    };

    const handleDeleteElement = (index) => {
        setLoading(true);
        setTimeout(() => {
            const updatedList = selectItems.filter((_, i) => i !== index);
            setSelectItems(updatedList);

            setIsTaskSave(prevState => [...prevState,2]);

            notification.success({
                description: "Question supprimé avec succès !",
            });
            setLoading(false)
        }, 1000)
    }

    const handleAssertionChange = (value) => {
        setAssertion(value)
        const updatedQuestions = questionsFiltered.filter((question) => question.assertions.includes(value))
        setQuestionsFiltered(updatedQuestions)
    }

    const toggleCheckbox = (id) => {
        const selectedList = [...questionsTravail];
        const valueId = parseInt(id);

        if (isNaN(valueId)) {
            return;
        }

        const add = questions.find((item) => item.id === valueId);

        const isAlreadySelected = questionsTravail.some((element) => element.question_id === add.id);
        if (!isAlreadySelected) {
            const newElement = {
                question_id: add.id,
                ref: add.ref,
                groupe: add.groupe,
                libelle: add.libelle,
                reponse: "1",
                commentaire: '',
                assertions: add.assertions
            };

            setQuestionsTravail([...selectedList, newElement]);
        }
        else {
            const selectedIndex = selectedList.findIndex((item) => item.question_id === (add.id));

            const updatedList = selectedList.filter((_, i) => i !== selectedIndex);
            setQuestionsTravail(updatedList)
        }
    };

    const handleValidate = () => {
        if(questionsTravail.length > 0 ){
            setLoading(true);
            setTimeout(() => {
                const updateList = []
                questionsTravail.forEach((programme) => {

                    const newElement = {
                        programme_id: programme.id,
                        ref: programme.ref,
                        groupe: programme.groupe,
                        libelle: programme.libelle,
                        reponse: "1",
                        commentaire: '',
                        assertions: programme.assertions,
                        isEdited: false
                    };

                    updateList.push(newElement)
                })

                setSelectItems([...selectItems, ...updateList]);

                handleModalOpen();
                notification.success({
                    description: "Questions ajouté avec succès !",
                });

                setLoading(false);
            }, 1500)
        }
    }

    const columnsQuestions = [
        {
            title: () => <TableTitle>REF</TableTitle>,
            dataIndex: 'reference',
            width: 40,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '13px'}}>
                        {record.ref}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Groupe</TableTitle>,
            dataIndex: 'groupe',
            width: 100,
            render: (_, record) => (
                <span className='fw-boldest text-uppercase' style={{fontSize: '13px'}}>
                    {record.groupe}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 150,
            render: (_, record) => (
                record.libelle.length > 1 ?
                    record.libelle.map((item, index) => (
                        <div  key={index} className='d-flex flex-column'>
                       <span className='' style={{fontSize: '13px'}}>
                           <strong className='me-2 fw-boldest' style={{fontSize: '15px'}}>.</strong>{item}
                       </span>
                        </div>
                    ))
                    :
                    record.libelle.length >= 0 ?
                        record.libelle.map((item, index) => (
                            <span className='' key={index} style={{fontSize: '13px'}}>
                        {item}
                    </span>
                        ))
                        :
                        ""
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 50,
            render: (_, record) => (
                <div className='d-flex align-center' >
                    {record.assertions?.map((assertion, index) => (
                        <span className='fw-bolder me-3' key={index}>
                        {assertion}
                    </span>
                    ))}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 50,
            render: (_, record) => (
                <span className="form-check form-check-custom me-5">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={
                            questionsTravail.some((item) => item.question_id === record.id)
                        }
                        onChange={() => {toggleCheckbox(record.id)}}
                    />
              </span>
            ),
        },
    ];

    const columnsSelectQuestions = [
        {
            title: () => <TableTitle>Question</TableTitle>,
            dataIndex: 'libelle',
            width: 450,
            render: (_, record) => (
                <div className="d-flex align-center">
                    <div className="me-2">
                        <Tag>
                            <span className='fw-boldest' style={{fontSize: '12px'}}>
                                {record.ref}
                            </span>
                        </Tag>
                    </div>
                    <div className="d-flex flex-column align-center">
                        <div>
                            {record.libelle.length > 1 ?
                                record.libelle.map((item, index) => (
                                    <div  key={index} className='d-flex flex-column'>
                                       <span className='' style={{fontSize: '14px'}}>
                                           <strong className='me-2 fw-boldest' style={{fontSize: '15px'}}>.</strong>{item}
                                       </span>
                                    </div>
                                ))
                                :
                                record.libelle.length >= 0 ?
                                    record.libelle.map((item, index) => (
                                        <span className='' key={index} style={{fontSize: '14px'}}>
                                            {item}
                                        </span>
                                    ))
                                    :
                                    ""}
                        </div>
                        <span className='fw-boldest text-uppercase mt-1' style={{fontSize: '10px'}}>
                            {record.groupe}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 100,
            render: (_, record) => (
                <div className='d-flex align-center' >
                    {record.assertions?.map((assertion, index) => (
                        <span className='fw-bolder me-3' key={index}>
                        {assertion}
                    </span>
                    ))}
                </div>
            ),
        },
    ];

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>REF</TableTitle>,
            dataIndex: 'ref',
            width: 70,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '14px'}}>
                        {record.ref}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Groupe</TableTitle>,
            dataIndex: 'groupe',
            width: 200,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px", fontWeight: "600"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le groupe..."
                        value={record.groupe}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        onChange={(e) => {
                            const updatedList = selectItems.map((item, i) =>
                                i === index ? { ...item,  groupe: (e.target.value)} : item
                            );

                            setSelectItems(updatedList);
                            setIsTaskSave(prevState => [...prevState, 2]);
                        }}
                    />
                </div>
            ),
        },
        {
            title: () => <TableTitle>Travaux à faire (TAF)</TableTitle>,
            dataIndex: 'libelle',
            width: 300,
            render: (_, record, index) => (
                // record.libelle.length > 1 ?
                //     record.libelle.map((opt, indexOpt) => (
                //         <div  key={indexOpt} className='d-flex flex-column'>
                //            <span className='' style={{fontSize: '10px'}}>
                //                <strong className='me-2 fw-bolder' style={{fontSize: '15px'}}>.</strong>{opt}
                //            </span>
                //         </div>
                //     ))
                //     :
                //     record.libelle.length >= 0 ?
                //         record.isEdited ? (
                //                 <textarea
                //                     style={{ fontSize: '12px' }}
                //                     className="form-control w-100"
                //                     value={record.libelle}
                //                     name="libelle"
                //                     onChange={(e) => {
                //                         const updatedList = selectItems.map((item, i) =>
                //                             i === index ? { ...item, libelle: [e.target.value] } : item
                //                         );
                //                         setSelectItems(updatedList);
                //                     }}
                //                 />
                //             )
                //             :
                //             record.libelle.map((opt, indexOpt) => (
                //                 <span className='' key={indexOpt} style={{fontSize: '10px'}}>
                //                 {opt}
                //             </span>
                //             ))
                //         : ""
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser les travaux à faire..."
                        value={record.libelle}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        onChange={(e) => {
                            const updatedList = selectItems.map((item, i) =>
                                i === index ? { ...item,  libelle: (e.target.value)} : item
                            );

                            setSelectItems(updatedList);
                            setIsTaskSave(prevState => [...prevState, 2]);
                        }}
                    />
                </div>
            ),
        },
        {
            title: () => <TableTitle>ASSERTIONS</TableTitle>,
            dataIndex: 'assertions',
            width: 170,
            render: (_, record, index) => (
                <Select
                    mode='tags'
                    style={{width: '100%', fontSize: "12px"}}
                    placeholder='choisir les assertions'
                    value={record.assertions}
                    options={assertions.map((item) => ({
                        label: item.libelle,
                        value: item.libelle,
                    }))}
                    onChange={(value) => {
                        const updated = [...selectItems];
                        const newInputValues = updated[index].assertions = value;
                        setSelectItems(updated);
                        setIsTaskSave(prevState => [...prevState, 2]);
                    }}
                />
            ),
        },
        {
            title: () => <TableTitle>Réponses</TableTitle>,
            dataIndex: 'reponse',
            width: 100,
            render: (_, record, index) => (
                <select
                    style={{fontSize: '12px'}}
                    name='reponse'
                    className="form-select text-center"
                    onChange={(e) => {
                        const updated = [...selectItems];
                        const newInputValues = updated[index].reponse = e.target.value === 'N/A' ? '1' : e.target.value === 'OUI' ? '2' : '3';
                        setSelectItems(updated);
                        setIsTaskSave(prevState => [...prevState, 2]);
                    }}
                    value={record.reponse === '1' ? 'N/A': record.reponse === '2' ? 'OUI' : 'NON'  }
                >
                    {['N/A', 'OUI', 'NON'].map((opt, indexOpt) => (
                        <option key={indexOpt} value={opt}>
                            {opt}
                        </option>
                    ))}
                </select>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'action',
            width: 300,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le commentaire..."
                        value={record.commentaire}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        onChange={(e) => {
                            const updatedList = selectItems.map((item, i) =>
                                i === index ? { ...item,  commentaire: (e.target.value)} : item
                            );

                            setSelectItems(updatedList);
                            setIsTaskSave(prevState => [...prevState, 2]);
                        }}
                    />
                </div>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 60,
            render: (_, record, index) => (
                <Tooltip title='Supprimer une queston'>
                    <Button
                        onClick={() => handleDeleteElement(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </Tooltip>
            ),
        },
    ];

    const handleModalOpen = () => {
        setIsModalOpen(prevState => !prevState)
    };

    return (
        <>
            <div className="d-flex justify-content-end align-center my-2 me-5">
                <div className="d-flex flex-wrap align-center">
                    <Space>
                        {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                            <Button
                                size={"middle"}
                                style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                onClick={handleAdd}
                                icon={<FontAwesomeIcon icon={faCartPlus}/>}
                            >
                                Ajouter
                            </Button>
                        </Tooltip>}
                        {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                            <Button
                                size={"middle"}
                                style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                onClick={handleModalOpen}
                                icon={<FontAwesomeIcon icon={faCloudUpload}/>}
                            >
                                Charger
                            </Button>
                        </Tooltip>}
                    </Space>
                </div>
            </div>
            <div className="table-responsive pt-5 pb-10">
                <Table
                    rowClassName={() =>
                        `h-3px p-0 m-0 lh-0.5`
                    }
                    columns={columns}
                    // rowKey={(record, index) => index}
                    dataSource={selectItems ?? []}
                    pagination={false}
                    scroll={{y: 800}}
                    loading={chargement || loading}
                    // rowSelection
                    size={"small"}
                />
            </div>
            <br/>
            <br/>
            <div className="d-flex flex-column mb-3 mx-2">
                <FormLabel label="CONCLUSION" />
                <Input.TextArea
                    style={{fontSize: "14px"}}
                    size="large"
                    placeholder="Saisisser la conclusion..."
                    value={conclusion}
                    maxLength={5000}
                    showCount
                    autoSize={{
                        minRows: 6,
                        maxRows: 8,
                    }}
                    onChange={(e) => {
                        setConclusion(e.target.value);
                        setIsTaskSave(prevState => [...prevState, 2]);
                    }}
                />
                <div className='mt-5'>
                    <div className="">
                        <Checkbox
                            onChange={() => setIsAddMemoire(!isAddMemoire)}
                            value={isAddMemoire}
                        >
                            Aide memoire
                        </Checkbox>
                    </div>
                </div>
            </div>
            <ModalAlert
                closeModal={handleModalOpen}
                show={isModalOpen}
                title={<ModalTitle> Sélectionner les questions de travail (sources) </ModalTitle>}
                footer={[]}
                modalStyles={{ top: 10 }}
                width={1300}
            >
                <FormContainer>
                    <Row gutter={[16, 16]}>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                        >
                            <FormLabel label="Question" />
                            <Input
                                style={{ width: "100%" }}
                                size="large"
                                placeholder="Rechercher par le libellé"
                                onChange={(e) => {
                                    setLoading(true);
                                    setTimeout(() => {
                                        const updateQuestions = questions.filter((item) =>
                                            ((item.libelle ?? []).some(opt => (opt ?? "").toLowerCase()?.includes((e.target.value).toLowerCase())))
                                            // (item.groupe?.toLowerCase()?.includes((e.target.value).toLowerCase()))
                                        )
                                        if(updateQuestions && updateQuestions.length > 0){
                                            setQuestionsFiltered(updateQuestions);
                                        }
                                        else {
                                            setQuestionsFiltered(questions);
                                        }
                                        setLoading(false);
                                    }, 1000)
                                }}
                            />
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                        >
                            <FormLabel label="Groupe" />
                            <Select
                                showSearch
                                placeholder="Rechercher par le groupe..."
                                optionFilterProp="label"
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={groupQuestions.map((group) => ({
                                    value: group,
                                    label: group,
                                }))}
                                value={null}
                                onChange={(value) => {
                                    setLoading(true);
                                    setTimeout(() => {
                                        const updateQuestions = questions.filter((item) =>
                                            (item.groupe?.toLowerCase()?.includes((value).toLowerCase()))
                                        )
                                        if(updateQuestions && updateQuestions.length > 0){
                                            setQuestionsFiltered(updateQuestions);
                                        }
                                        else {
                                            setQuestionsFiltered(questions);
                                        }
                                        setLoading(false);
                                    }, 1000)
                                }}
                                size="large"
                                style={{ width: "100%" }}
                            />
                        </Col>
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                        >
                            <FormLabel label="Assertions" />
                            <Select
                                showSearch
                                placeholder="Rechercher par l'assertion..."
                                optionFilterProp="label"
                                filterOption={(input, option) =>
                                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                options={assertions.map((assertion) => ({
                                    value: assertion.libelle,
                                    label: assertion.libelle,
                                }))}
                                value={assertion || null}
                                onSelect={handleAssertionChange}
                                size="large"
                                style={{ width: "100%" }}
                            />
                        </Col>
                    </Row>
                </FormContainer>
                <Row gutter={[16, 16]}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 16 }}
                        lg={{ span: 14 }}
                        xl={{ span: 14 }}
                    >
                        <FormContainer>
                            <div className="table-responsive">
                                <Table
                                    rowClassName={() =>
                                        `h-3px p-0 m-0 lh-0.5`
                                    }
                                    columns={columnsQuestions}
                                    dataSource={questionsFiltered ?? []}
                                    pagination={false}
                                    scroll={{y: 400}}
                                    loading={chargement || loading}
                                    size={"small"}
                                />
                            </div>
                        </FormContainer>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 10 }}
                        xl={{ span: 10 }}
                    >
                        <FormContainer>
                            <div className="table-responsive">
                                <Table
                                    rowClassName={() =>
                                        `h-3px p-0 m-0 lh-0.5`
                                    }
                                    columns={columnsSelectQuestions}
                                    dataSource={questionsTravail ?? []}
                                    pagination={false}
                                    scroll={{y: 400}}
                                    loading={chargement || loading}
                                    size={"small"}
                                />
                            </div>
                        </FormContainer>
                    </Col>
                </Row>
                <div className="d-flex justify-content-center align-items-center mt-3">
                    <div className="w-350px">
                        <Button
                            type="primary"
                            block
                            size="large"
                            // icon={<FontAwesomeIcon icon={fa} /> }
                            loading={loading}
                            disabled={loading}
                            onClick={handleValidate}
                        >
                            Valider
                        </Button>
                    </div>
                </div>
            </ModalAlert>
        </>
    );
}

export {TraitementTestJournaux}