import { Button, Col, Row, Spin } from "antd";
import React from "react";
import { ButtonDanger } from "./button.components";
import { FormContainer } from "./common.components";

const SearchRow = ({
  visible,
  children,
  handleSearch,
  handleReset,
  loading,
}) => {
  return (
    <Spin spinning={loading}>
      <FormContainer>
        <Row gutter={[16, 16]}>{children}</Row>
        <Row
          align="middle"
          justify="center"
          gutter={[16, 16]}
          style={{ marginTop: 10 }}
        >
          <Col span={8}>
            <Button size="large" type="primary" block onClick={handleSearch}>
              Rechercher
            </Button>
          </Col>
          <Col>
            <ButtonDanger size="large" block onClick={handleReset}>
              Réinitialiser
            </ButtonDanger>
          </Col>
        </Row>
      </FormContainer>
    </Spin>
  );
};

export default SearchRow;
