import React, {useState} from "react";
import {toAbsoluteUrl} from "../../../helpers/AssetsHelpers";
import {useFormik} from "formik";
import {requestPasswordSchema} from "../../../components/utilities/Utilities";
import {ModiferMotPasse} from "../../../data/user";
import {useNavigate} from "react-router";
import {Link} from "react-router-dom";
import clsx from "clsx";
import backGroundImage from "../../../assets/images/login_fond.jpg";
import {Input} from "antd";

const PasswordChange = ({  }) => {
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState(undefined)
    const navigate = useNavigate()

    const initialValues = {
        current_password: '',
        new_password: '',
        confirm_password: '',
    }

    const formik = useFormik({
        initialValues,
        validationSchema: requestPasswordSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setHasErrors(undefined)
            try {
                const {status} = await ModiferMotPasse(values)
                if (status === 'success') {
                    navigate('/login')
                    setHasErrors(false)
                    setLoading(false)
                    window.location.reload()
                } else {
                    setHasErrors(true)
                    setLoading(false)
                    setSubmitting(false)
                    setStatus('Echec de réinitialisation du mot de passe')
                }
            } catch (error) {
                console.error(error)
                setStatus('Les informations de connexion sont incorrectes')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    return (
        <>
            <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
                <div className='d-flex flex-column flex-lg-row-fluid py-10' style={{background: `url(${backGroundImage})`, width: '100%', height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                    <div className='d-flex flex-center flex-column flex-column-fluid' >
                        <a href='#' className='mb-12'>
                            <img
                                alt='Logo'
                                src={toAbsoluteUrl('/media/logos/logo_egouexpert.png')}
                                className='h-45px'
                            />
                        </a>
                        {/*end::Logo*/}
                        {/*begin::Wrapper*/}
                        <div className='w-lg-500px p-10 p-lg-15 mx-auto'>
                            <form
                                className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                                noValidate
                                id='reset_password_form'
                                onSubmit={formik.handleSubmit}
                            >
                                <div className='text-center mb-10'>
                                    <h1 className='text-dark mb-3'>Réinitialiser le mot de passe</h1>
                                    <div className='text-gray-400 fw-bold fs-4'>
                                        Entrez l'ancien mot de passe et le nouveau mot de passe.
                                    </div>
                                </div>

                                {hasErrors === true && (
                                    <div className='mb-lg-15 alert alert-danger'>
                                        <div className='alert-text font-weight-bold'>
                                            Désolé, il y a des erreurs détectées. Réessayez encore !.
                                        </div>
                                    </div>
                                )}

                                {hasErrors === false && (
                                    <div className='mb-10 bg-light-info p-8 rounded'>
                                        <div className='text-info'>Mot de passe réinitialisé avec succès!</div>
                                    </div>
                                )}

                                <div className='fv-row mb-10'>
                                    <label className='form-label fw-bolder text-gray-900 fs-6'>
                                        Actuel mot de passe
                                    </label>
                                    <Input.Password
                                        placeholder="input password"
                                        {...formik.getFieldProps('current_password')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid':
                                                    formik.touched.current_password && formik.errors.current_password,
                                            },
                                            {
                                                'is-valid':
                                                    formik.touched.current_password && !formik.errors.current_password,
                                            }
                                        )}
                                    />
                                    {/*<input*/}
                                    {/*    type='password'*/}
                                    {/*    autoComplete='off'*/}
                                    {/*    {...formik.getFieldProps('current_password')}*/}
                                    {/*    className={clsx(*/}
                                    {/*        'form-control form-control-lg form-control-solid',*/}
                                    {/*        {*/}
                                    {/*            'is-invalid':*/}
                                    {/*                formik.touched.current_password && formik.errors.current_password,*/}
                                    {/*        },*/}
                                    {/*        {*/}
                                    {/*            'is-valid':*/}
                                    {/*                formik.touched.current_password && !formik.errors.current_password,*/}
                                    {/*        }*/}
                                    {/*    )}*/}
                                    {/*/>*/}
                                    {formik.touched.current_password && formik.errors.current_password && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.current_password}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className='fv-row mb-10'>
                                    <label className='form-label fw-bolder text-gray-900 fs-6'>
                                        Nouveau mot de passe
                                    </label>
                                    <Input.Password
                                        type='password'
                                        {...formik.getFieldProps('new_password')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid': formik.touched.new_password && formik.errors.new_password,
                                            },
                                            {
                                                'is-valid': formik.touched.new_password && !formik.errors.new_password,
                                            }
                                        )}
                                    />
                                    {/*<input*/}
                                    {/*    type='password'*/}
                                    {/*    autoComplete='off'*/}
                                    {/*    {...formik.getFieldProps('new_password')}*/}
                                    {/*    className={clsx(*/}
                                    {/*        'form-control form-control-lg form-control-solid',*/}
                                    {/*        {*/}
                                    {/*            'is-invalid': formik.touched.new_password && formik.errors.new_password,*/}
                                    {/*        },*/}
                                    {/*        {*/}
                                    {/*            'is-valid': formik.touched.new_password && !formik.errors.new_password,*/}
                                    {/*        }*/}
                                    {/*    )}*/}
                                    {/*/>*/}
                                    {formik.touched.new_password && formik.errors.new_password && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.new_password}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className='fv-row mb-10'>
                                    <label className='form-label fw-bolder text-gray-900 fs-6'>
                                        Confirmez le mot de passe
                                    </label>
                                    <Input.Password
                                        type='password'
                                        {...formik.getFieldProps('confirm_password')}
                                        className={clsx(
                                            'form-control form-control-lg form-control-solid',
                                            {
                                                'is-invalid':
                                                    formik.touched.confirm_password && formik.errors.confirm_password,
                                            },
                                            {
                                                'is-valid':
                                                    formik.touched.confirm_password && !formik.errors.confirm_password,
                                            }
                                        )}
                                    />
                                    {/*<input*/}
                                    {/*    type='password'*/}
                                    {/*    autoComplete='off'*/}
                                    {/*    {...formik.getFieldProps('confirm_password')}*/}
                                    {/*    className={clsx(*/}
                                    {/*        'form-control form-control-lg form-control-solid',*/}
                                    {/*        {*/}
                                    {/*            'is-invalid':*/}
                                    {/*                formik.touched.confirm_password && formik.errors.confirm_password,*/}
                                    {/*        },*/}
                                    {/*        {*/}
                                    {/*            'is-valid':*/}
                                    {/*                formik.touched.confirm_password && !formik.errors.confirm_password,*/}
                                    {/*        }*/}
                                    {/*    )}*/}
                                    {/*/>*/}
                                    {formik.touched.confirm_password && formik.errors.confirm_password && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.confirm_password}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                                    <button
                                        type='submit'
                                        id='reset_password_submit'
                                        className='btn btn-lg btn-active-light-sucess fw-bolder me-4'
                                        // style={{backgroundColor: '#d1ec51'}}
                                    >
                                        <span className='indicator-label'>Enregistrer</span>
                                        {loading && (
                                            <span className='indicator-progress'>
                                                Veillez patientez...
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                              </span>
                                        )}
                                    </button>
                                    <Link to='/auth/login'>
                                        <button
                                            type='button'
                                            id='reset_password_cancel_button'
                                            className='btn btn-lg btn-color-dark fw-bolder'
                                            // style={{backgroundColor: '#d1ec51', opacity: '0.3'}}
                                            disabled={formik.isSubmitting || !formik.isValid}
                                        >
                                            Annuler
                                        </button>
                                    </Link>{' '}
                                </div>
                            </form>
                        </div>

                        {/*end::Wrapper*/}
                    </div>
                </div>
            </div>
        </>
    )
};

export default PasswordChange;
