import React, {useEffect, useState} from "react";
import {ModalTitle} from "../modals.components";
import {Button, Col, Input, notification, Row} from "antd";
import FormLabel from "../../common/Form/FormLabel";
import ModalAlert from "../Modal";

export const ModalCommentaireBalance = ({
        setBalanceData,
        balanceData,
        balance,
        visible,
        handleVisibility,
        loading,
        setLoading,
        isIntang,
        setIsTaskSave
    }) => {
    const [commentaire, setCommentaire] = useState("")

    useEffect(() => {
        if(balance && balance?.commentaire){
            setCommentaire(balance.commentaire);
        }
        else{
            setCommentaire("")
        }
    }, [balance])

    const handleValidate = async () => {
        setLoading(true);
        setTimeout(() => {
            const updatedList = balanceData.map((item) =>
                (isIntang ? (item.compte_Prov === balance?.compte_Prov) : (item.numeroCompteProv === balance?.numeroCompteProv)) ?
                    {
                        ...item,
                        commentaire: commentaire,
                    } : item
            );
            setBalanceData(updatedList);
            handleVisibility()

            setIsTaskSave(prevState => [...prevState, 2]);
            notification.success({
                description: "Commentaire ajouté avec succès !",
            });
            setLoading(false)
        }, 1000);
    }

    return (
        <ModalAlert
            closeModal={handleVisibility}
            show={visible}
            title={<ModalTitle>Commentaire du compte {balance?.numeroCompte || ""}</ModalTitle>}
            footer={[]}
            modalStyles={{ top: 30 }}
            width={600}
        >
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <FormLabel label="Commentaire" required />
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le commentaire..."
                        value={commentaire ?? ""}
                        maxLength={5000}
                        showCount
                        autoSize={{
                            minRows: 8,
                            maxRows: 10,
                        }}
                        onChange={(e) => {
                            setCommentaire(e.target.value)
                        }}
                    />
                </Col>
                <br/>
                <Col span={24}>
                    <Button
                        type="primary"
                        block
                        size="large"
                        loading={loading}
                        disabled={loading}
                        onClick={handleValidate}
                    >
                        {!loading && <span className='indicator-label'>Valider</span>}
                        {loading && (
                            <span className="indicator-progress text-white">
                                Veuillez patienter...
                                <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                            </span>
                        )}
                    </Button>
                </Col>
            </Row>
        </ModalAlert>
    )
}