import React, {useEffect, useState} from "react";
import {Button, Input, notification, Select, Table, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {getListeAssertionActive} from "../../../../../../../data/params/assertion";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faSave} from "@fortawesome/free-solid-svg-icons";

const TraitementIntangibilite = ({
         mission,
         traitementData,
         setTraitementData,
         setConclusion,
         conclusion,
         chargement,
         setIsTaskSave,
         handleSubmit,
    }) => {
    const [loading, setLoading] = useState(false);
    const [assertions, setAssertions] = useState([]);
    const [datas, setDatas] = useState(traitementData || []);
    const [questionIndex, setQuestionIndex] = useState(null);
    const [questionsData, setQuestionsData] = useState([{
      id: 1,
      libelle: "Intangibilité des Etats Financiers (N et N-1\n" +
          "Vérifier la concordance entre la colonne N des états financiers définitifs de l’exercice N-1 et la colonne N-1 des états financiers de l’exercice N.\n",
      assertions: ['A']
  }]);

    useEffect(() => {
        fetchAssertion();
    }, []);

    const fetchAssertion = async () => {
        try {
            const res = await getListeAssertionActive();
            setAssertions(res.result);
        } catch (error) {
            console.error('Erreur lors de la récupération des rubriques', error);
        }
    };

    useEffect(() => {
        if (!traitementData || traitementData.length > 0)
            return;

        setLoading(true);
        const updateData = [...datas];

        setTimeout(() => {
            questionsData.forEach((question) => {
                const isAlreadySelected = updateData.some((element) => element.question_id === question.id);
                if (!isAlreadySelected) {
                    const newElement = {
                        question_id: question.id,
                        libelle: question.libelle,
                        reponse: '1',
                        assertions: question.assertions,
                        commentaire: '',
                        isEdited: true
                    };

                    updateData.push(newElement);
                }
            });

            setDatas(updateData);
            setTraitementData(updateData);
            setQuestionIndex(questionsData.length > 0 ? questionsData[0].id + 1 : 1);
            setLoading(false);
        }, 1000);
    }, [datas, questionsData, setTraitementData, traitementData]);
    
    useEffect(() => {
        setConclusion(mission.conclusionIntangilite || "");
    }, [mission])


    const handleAdd = () => {
        setLoading(true);
        setTimeout(() => {
            const newElement = {
                question_id: questionIndex,
                libelle: '',
                reponse: '1',
                assertions: [],
                commentaire: '',
                isEdited: false
            };

            setDatas([...datas, newElement]);
            setTraitementData([...datas, newElement]);
            setQuestionIndex(prevState => prevState + 1);

            setIsTaskSave(prevState => [...prevState, 3]);
            setLoading(false);
            notification.success({
                description: "Question ajouté avec succès !",
            });
        }, 1000)
    };

    const handleDeleteElement = (index) => {
        setLoading(true);
        setTimeout(() => {
            const updatedList = datas.filter((_, i) => i !== index);
            setDatas(updatedList);
            setTraitementData(updatedList);

            setIsTaskSave(prevState => [...prevState, 3]);

            notification.success({
                description: "Question supprimé avec succès !",
            });
            setLoading(false)
        }, 1000)
    }

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Travaux à faire (TAF)</TableTitle>,
            dataIndex: 'libelle',
            width: 400,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser les travaux à faire..."
                        value={record.libelle}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 6,
                        }}
                        onChange={(e) => {
                            const updatedList = datas.map((item, i) =>
                                i === index ? { ...item,  libelle: (e.target.value)} : item
                            );

                            setDatas(updatedList);
                            setTraitementData(updatedList);

                            setIsTaskSave(prevState => [...prevState, 3]);
                        }}
                    />
                </div>
            ),
        },
        {
            title: () => <TableTitle>ASSERTION</TableTitle>,
            dataIndex: 'assertions',
            width: 150,
            render: (_, record, index) => (
                record.isEdited ? <div className='d-flex align-item-center ' >
                    {record.assertions?.map((assertion, index) => (
                        <span className='fw-bolder me-3 text-center' key={index}>
                            {assertion}
                        </span>
                    ))}
                    </div>
                    :
                    <Select
                        mode='tags'
                        style={{width: '100%'}}
                        placeholder='choisir les assertions'
                        value={record.assertions}
                        options={assertions.map((item) => ({
                            label: item.libelle,
                            value: item.libelle,
                        }))}
                        onChange={(value) => {
                            const updated = [...datas];
                            const newInputValues = updated[index].assertions = value;
                            setDatas(updated);
                            setTraitementData(updated);

                            setIsTaskSave(prevState => [...prevState, 3]);
                        }}
                    />
            ),
        },
        {
            title: () => <TableTitle>Réponses</TableTitle>,
            dataIndex: 'reponse',
            width: 100,
            render: (_, record, index) => (
                <select
                    style={{fontSize: '12px'}}
                    name='reponse'
                    className="form-select text-center"
                    onChange={(e) => {
                        const updated = [...datas];
                        const newInputValues = updated[index].reponse = e.target.value === 'N/A' ? '1' : e.target.value === 'OUI' ? '2' : '3';
                        setDatas(updated);
                        setTraitementData(updated);

                        setIsTaskSave(prevState => [...prevState, 3]);
                    }}
                    value={record.reponse === '1' ? 'N/A': record.reponse === '2' ? 'OUI' : 'NON'  }
                >
                    {['N/A', 'OUI', 'NON'].map((opt, indexOpt) => (
                        <option key={indexOpt} value={opt}>
                            {opt}
                        </option>
                    ))}
                </select>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 400,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le commentaire..."
                        value={record.commentaire}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        onChange={(e) => {
                            const updatedList = datas.map((item, i) =>
                                i === index ? { ...item,  commentaire: (e.target.value)} : item
                            );

                            setDatas(updatedList)
                            setTraitementData(updatedList)

                            setIsTaskSave(prevState => [...prevState, 3]);
                        }}
                    />
                </div>
            ),
        },
        {
            title: (<span style={{fontSize: '11px'}}>Action</span>),
            dataIndex: 'action',
            width: 50,
            render: (_, record, index) => (
                !record.isEdited && <Tooltip title='Supprimer une queston'>
                    <Button
                        onClick={() => handleDeleteElement(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </Tooltip>
            ),
        },
    ];

  return (
      <>
        <div><div className="d-flex justify-content-end align-items-end mb-10">
            <Tooltip title="Enregistrer">
                <Button
                    disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                    size={"large"}
                    // loading={loading}
                    style={{
                        backgroundColor: appTheme.colors.secondary,
                        color: "white"
                    }}
                    icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                    onClick={handleSubmit}
                >
                    {!chargement &&
                        <span className='indicator-label text-white'>Enregistrer</span>}
                    {chargement && (
                        <span className='indicator-progress fs-9 text-white'
                              style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                    )}
                </Button>
            </Tooltip>
        </div>
            <div className="d-flex justify-content-end align-center my-2 me-5">
                {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter un nouvelle question">
                    <Button
                        size={"middle"}
                        style={{color: "white", backgroundColor: appTheme.colors.primary}}
                        onClick={handleAdd}
                        icon={<FontAwesomeIcon icon={faCartPlus}/>}
                    >
                        Ajouter
                    </Button>
                </Tooltip>}
            </div>
            <div className="table-responsive pt-5 pb-10">
                <Table
                    rowClassName={() =>
                        `h-3px p-0 m-0 lh-0.5`
                    }
                    columns={columns}
                    rowKey={(record, index) => index}
                    dataSource={datas ?? []}
                    pagination={false}
                    scroll={{y: 800}}
                    loading={chargement || loading}
                    rowSelection
                    size={"small"}
                />
            </div>
            <div className="d-flex flex-column mb-3 mx-2">
                <label className="fw-bold form-label" style={{fontSize: '15px'}}>
                    <span>Conclusion: </span>
                </label>
                <Input.TextArea
                    style={{fontSize: "14px"}}
                    size="large"
                    placeholder="Saisisser la conclusion..."
                    value={conclusion}
                    maxLength={5000}
                    showCount
                    autoSize={{
                        minRows: 4,
                        maxRows: 6,
                    }}
                    onChange={(e) => {
                        setConclusion(e.target.value);
                        setIsTaskSave(prevState => [...prevState, 3]);
                    }}
                />
            </div>
        </div>
      </>
  )
}

export default TraitementIntangibilite;