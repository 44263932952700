import React from "react";
import FormLabel from "../../../../../../common/Form/FormLabel";
import {Input} from "antd";

const AuditInitialConclusion = ({conclusionGenerale, setConclusionGenerale}) => {

    return (
        <div className="card d-flex flex-column fv-plugins-bootstrap5 pt-2 fv-plugins-framework">
            <div className="d-flex flex-column mb-5 mx-2">
                <div className="d-flex flex-column mb-3 mx-2">
                    <FormLabel label="Conclusion:" />
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        placeholder="Saisisser la conclusion..."
                        maxLength={5000}
                        showCount
                        autoSize={{
                            minRows: 8,
                            maxRows: 10,
                        }}
                        value={conclusionGenerale}
                        onChange={(e) => setConclusionGenerale(e.target.value)}
                    />
                </div>
            </div>
        </div>
    )
}

export {AuditInitialConclusion}