import axios from 'axios'
import {LISTE_PAYS} from "../../api/apiRoutes";


export const getListePays = async () => {
  return axios
    .get(LISTE_PAYS)
    .then((res) => {
      if (res.status === 200) {
        return res.data.result
      } else {
        return {
          status: 'error',
          message: 'La connexion à internet est instable !',
        }
      }
    })
    .catch((err) => console.log(err))
}
