import React, {useEffect, useState} from "react";
import { ajouterStrategieInfoGenerale} from "../../../../../../data/stategieGlobale";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../helpers/AssetsHelpers";
import {StrategieHeader} from "../Strategieglobale";
import {Button, Col, DatePicker, Input, Row, Tooltip, Typography} from "antd";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import FormLabel from "../../../../../common/Form/FormLabel";
import moment from "moment";

const { Title } = Typography;

const InfosGenerales = ({ mission, strategies, isProvisoire }) => {
    const [specificiteAction, setSpecificiteAction] = useState('')
    const [planificationEquipe, setPlanificationEquipe] = useState('')
    const [principauxMembres, setPrincipauxMembres] = useState( '')
    const [principauxInterlocuteur, setPrincipauxInterlocuteur] = useState('')
    const [dateCloture, setDateCloture] = useState('')
    const [loading, setLoading] = useState(false)
    const date = new Date();

    useEffect(() => {
        if(strategies){
            setSpecificiteAction(strategies?.specificiteAction)
            setPlanificationEquipe(strategies?.planificationEquipe)
            setPrincipauxMembres(strategies?.principauxMembres)
            setPrincipauxInterlocuteur(strategies?.principauxInterlocuteur)
            setDateCloture(strategies?.dateCloture)
        }
        else{
            setSpecificiteAction('')
            setPlanificationEquipe('')
            setPrincipauxMembres('')
            setPrincipauxInterlocuteur('')
            setDateCloture('')
        }
    }, [strategies])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            specificiteAction,
            planificationEquipe,
            principauxMembres,
            principauxInterlocuteur,
            dateCloture,
            isProvisoire: parseInt(isProvisoire) === 1,
            exercice_id: mission.id,
            client_id: mission.client_id
        }

        const {status, message} = await ajouterStrategieInfoGenerale(data)
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    }


    return(
        <>
            <div className="d-flex flex-column fv-plugins-bootstrap5 fv-plugins-framework pb-10">
                {<div className="d-flex justify-content-end align-items-end mb-10">
                    <Tooltip title="Enregistrer">
                        <Button
                            disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            size={"large"}
                            // loading={loading}
                            style={{
                                backgroundColor: appTheme.colors.secondary,
                                color: "white"
                            }}
                            icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                            onClick={handleSubmit}
                        >
                            {!loading &&
                                <span className='indicator-label text-white'>Enregistrer</span>}
                            {loading && (
                                <span className='indicator-progress fs-9 text-white'
                                      style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                    </Tooltip>
                </div>}
                <div className="card-header">
                    <div className="card-title">
                        <Title style={{
                            display: "block",
                            fontWeight: '600',
                            color: appTheme.colors.primary,
                            // textAlign: "center"
                        }} level={4}>{"EC4.2 : Informations générales"}</Title>
                    </div>
                </div>
                <div className="mt-10"></div>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <FormLabel label={"Spécificité de l’actionnariat et ou changements majeurs : "}/>
                        <Input.TextArea
                            size="large"
                            placeholder="Saissiser le spécificité de l’actionnariat"
                            value={specificiteAction}
                            readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            maxLength={3000}
                            showCount
                            autoSize={{
                                minRows: 2,
                                maxRows: 6,
                            }}
                            onChange={(e) => {
                                setSpecificiteAction(e.target.value)
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <FormLabel label={"Discussion de planification de l’équipe d’audit :"}/>
                        <Input.TextArea
                            size="large"
                            placeholder="Saissiser la discussion de planification"
                            readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            maxLength={5000}
                            showCount
                            autoSize={{
                                minRows: 4,
                                maxRows: 8,
                            }}
                            value={planificationEquipe}
                            onChange={(e) => {
                                setPlanificationEquipe(e.target.value)
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <FormLabel label={"Principaux membres de la direction et coordonnées :"}/>
                        <Input.TextArea
                            size="large"
                            placeholder="Saissiser les principaux membres"
                            readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            maxLength={5000}
                            showCount
                            autoSize={{
                                minRows: 4,
                                maxRows: 8,
                            }}
                            value={principauxMembres}
                            onChange={(e) => {
                                setPrincipauxMembres(e.target.value)
                            }}
                        />
                    </Col>
                    <Col span={24}>
                        <FormLabel label={"Principaux interlocuteurs :"}/>
                        <Input.TextArea
                            size="large"
                            placeholder="Saissiser les principaux interlocuteurs"
                            readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            maxLength={5000}
                            showCount
                            autoSize={{
                                minRows: 4,
                                maxRows: 8,
                            }}
                            value={principauxInterlocuteur}
                            onChange={(e) => {
                                setPrincipauxInterlocuteur(e.target.value)
                            }}
                        />
                    </Col>
                    <Col span={12}>
                        <FormLabel label="Date de clôture de l’exercice" />
                        <DatePicker
                            size="large"
                            placeholder="Date de clôture de l’exercice"
                            style={{ width: "100%" }}
                            allowClear
                            value={dateCloture ? moment(dateCloture, "YYYY-MM-DD") : null}
                            onChange={(date, dateString) => setDateCloture(date ? date.format("YYYY-MM-DD") : null)}
                            format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}
export {InfosGenerales}