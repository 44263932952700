import React, {useEffect, useState} from 'react';
import '../testejournaux.css';

const TableJournaux = ({datas, objectif, conclusion, title}) => {
    const [selects, setSelects] = useState([])

    useEffect(() => {
        if(datas && datas.length > 0){
            const updateList = []
            datas.forEach((item) => {
                const libelleExist = updateList.find((question) => question.libelleGroupe === item.groupe)

                if(libelleExist){
                    libelleExist.questions.push(item)
                }
                else {
                    const newElement = {
                        libelleGroupe: item.groupe,
                        questions: [item],
                    }
                    updateList.push(newElement)
                }
            })
            setSelects(updateList)
        }
    }, [datas])

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
        minHeight: "20px"
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <div className="card px-20">
            <h1 className="fw-bolder text-black mb-3 text-center">{title || ""}</h1>

            {objectif &&
                <div className="mb-5">
                    <table style={tableStyle} className="tablePerso">
                        <thead>
                        <tr style={thStyle} >
                            <th
                                className="text-start"
                                style={{fontSize: "14px",
                                    textDecoration: 'underline',
                                    fontWeight: "bold"}}
                            >
                                Objectif
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr style={tdStyle}>
                            <td style={tdStyle}>
                                <p style={{fontSize: "12px"}}>{objectif}</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            }

            <div className="table-responsive mb-5">
                <div className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className="tablePerso w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={thStyle} className="thperso w-150px">Groupe</th>
                                <th style={thStyle} className="thperso w-500px">Travaux à faire (TAF)</th>
                                <th style={thStyle} className="thperso w-100px">Assertions</th>
                                <th style={thStyle} className="thperso w-150px">Réponse</th>
                                <th style={thStyle} className="thperso w-500px">Commentaire</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(selects && selects.length > 0) && selects.map((question, questionIndex) => (
                                question.questions.map((item, itemIndex) => {
                                    const questionLibelle = (itemIndex === 0) ? question.libelleGroupe : '';
                                    return (
                                        <tr style={tdStyle} className="" key={`${questionIndex}-${itemIndex}`}>
                                            {itemIndex === 0 && (
                                                <td className="" rowSpan={question.questions.length} style={{...tdStyle, fontSize: '10px'}}>
                                                    {questionLibelle}
                                                </td>
                                            )}
                                            <td className=" text-start" style={{...tdStyle, fontSize: '11px' }}>
                                                {

                                                    item.libelle.length > 1 ?
                                                        item.libelle.map((opt, indexOpt) => (
                                                            <div  key={indexOpt} className='d-flex flex-column'>
                                                               <p className='' style={{fontSize: '10px'}}>
                                                                    <span className="me-2 fw-bolder fs-9">{item.ref}</span>
                                                                   <strong className='me-2 fw-bolder' style={{fontSize: '13px'}}>.</strong>{opt}
                                                               </p>
                                                            </div>
                                                        ))
                                                        :
                                                        item.libelle.length >= 0 ?
                                                            item.libelle.map((opt, indexOpt) => (

                                                                <p className='' key={indexOpt} style={{fontSize: '10px'}}>
                                                                    <span className="me-2 fw-bolder fs-9">{item.ref}</span>
                                                                    {opt}
                                                                </p>
                                                            ))
                                                            :
                                                            ""
                                                }
                                            </td>
                                            <td className=" text-center" style={tdStyle}>
                                                <div className='d-flex justify-content-center align-center '>
                                                    {item.assertions?.map((assertion) => (
                                                        <span className='fw-bolder text-center me-5' key={assertion.id}>
                                                          {assertion}
                                                        </span>
                                                    ))}
                                                </div>
                                            </td>
                                            <td className="" style={tdStyle}>
                                                {item.reponse === '1' ? 'NON': 'OUI'}
                                            </td>
                                            <td className="" style={tdStyle}>
                                                <p style={{fontSize: '11px', textAlign: "start"}}>
                                                    {item.commentaire}
                                                </p>
                                            </td>
                                        </tr>
                                    );
                                })
                            ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            {conclusion &&
                <table style={tableStyle} className="tablePerso ">
                    <thead>
                    <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                        <th
                            className="text-start"
                            style={{fontSize: "14px",
                                textDecoration: 'underline',
                                fontWeight: "bold"}}
                        >
                            Conclusion
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={tdStyle}>
                        <td style={tdStyle}>
                            <p style={{fontSize: "12px", textAlign: "start"}}>{conclusion}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            }
        </div>
    );
}

export default TableJournaux;
