export const initialValuesActifs = [
    {
        ref: 'AE',
        libelle: 'Frais de développement et de prospection',
        brut: {
            value: ['211', '2181', '2191'],
            except: []
        },
        amortissement: {
            value: ['2811', '2818', '2911', '2918', '2919'],
            except: []
        },
    },
    {
        ref: 'AF',
        libelle: 'Brevets, licences, logiciels et droits similaires',
        brut: {
            value: ['212', '213', '2193'],
            except: []
        },
        amortissement: {
            value: ['2812', '2813', '2814', '2912', '2913', '2914', '2919'],
            except: []
        },
    },
    {
        ref: 'AG',
        libelle: 'Fonds commercial et droit au bail',
        brut: {
            value: ['215', '216'],
            except: []
        },
        amortissement: {
            value: ['2815', '2816', '2915', '2916'],
            except: []
        },
    },
    {
        ref: 'AH',
        libelle: 'Autres immobilisations incorporelles',
        brut: {
            value: ['217', '218', '2198'],
            except: ['2181']
        },
        amortissement: {
            value: ['2817', '2818', '2917', '2918', '2919'],
            except: []
        },
    },
    {
        ref: 'AJ',
        libelle: 'Terrains (dont placement en Net)',
        brut: {
            value: ['22'],
            except: []
        },
        amortissement: {
            value: ['282', '292'],
            except: []
        },
    },
    {
        ref: 'AK',
        libelle: 'Bâtiments (dont placement en Net)',
        brut: {
            value: ['231', '232', '233', '233', '237', '2391', '2392', '2393'],
            except: []
        },
        amortissement: {
            value: ['2831', '2832', '2833', '2837', '2931', '2932', '2933', '2937', '2939'],
            except: []
        },
    },
    {
        ref: 'AL',
        libelle: 'Aménagements, agencement et installations',
        brut: {
            value: ['234', '235', '238', '2394', '2395', '2398'],
            except: []
        },
        amortissement: {
            value: ['2834', '2835', '2838', '2934', '2935', '2938', '2939'],
            except: []
        },
    },
    {
        ref: 'AM',
        libelle: 'Matériel, mobilier et actifs biologiques',
        brut: {
            value: ['24'],
            except: ['245', '2495']
        },
        amortissement: {
            value: ['284', '294', '2949'],
            except: ['2845', '2945', '2949']
        },
    },
    {
        ref: 'AN',
        libelle: 'Matériel de transport',
        brut: {
            value: ['245', '2495'],
            except: []
        },
        amortissement: {
            value: ['2845', '2945', '2949'],
            except: []
        },
    },
    {
        ref: 'AP',
        libelle: 'AVANCES ET ACOMPTES VERSES SUR IMMOBILISATIONS',
        brut: {
            value: ['251', '252'],
            except: []
        },
        amortissement: {
            value: ['2951', '2952'],
            except: []
        },
    },
    {
        ref: 'AR',
        libelle: 'Titres de participation',
        brut: {
            value: ['26'],
            except: []
        },
        amortissement: {
            value: ['296'],
            except: []
        },
    },
    {
        ref: 'AS',
        libelle: 'Autres immobilisations financières',
        brut: {
            value: ['27'],
            except: []
        },
        amortissement: {
            value: ['297'],
            except: []
        },
    },
    {
        ref: 'BA',
        libelle: 'ACTIF CIRCULANT HAO',
        brut: {
            value: ['485', '488'],
            except: []
        },
        amortissement: {
            value: ['498'],
            except: []
        },
    },
    {
        ref: 'BB',
        libelle: 'STOCKS ET ENCOURS',
        brut: {
            value: ['31', '32', '33', '34', '35', '36', '37', '38'],
            except: []
        },
        amortissement: {
            value: ['39'],
            except: []
        },
    },
    {
        ref: 'BH',
        libelle: 'Fournisseurs avancés versées',
        brut: {
            value: ['409'],
            except: []
        },
        amortissement: {
            value: ['490'],
            except: []
        },
    },
    {
        ref: 'BI',
        libelle: 'Clients',
        brut: {
            value: ['41'],
            except: ['419']
        },
        amortissement: {
            value: ['491'],
            except: []
        },
    },
    {
        ref: 'BJ',
        libelle: 'Autres créances',
        brut: {
            value: ['185', '42', '43', '44', '45', '46', '47'],
            except: ['478'],
            sens: 'D'
        },
        amortissement: {
            value: ['492', '493', '494', '495', '496', '497'],
            except: []
        },
    },
    {
        ref: 'BQ',
        libelle: 'Titres de placement',
        brut: {
            value: ['50'],
            except: []
        },
        amortissement: {
            value: ['590'],
            except: []
        },
    },
    {
        ref: 'BR',
        libelle: 'Valeur à encaisser',
        brut: {
            value: ['51'],
            except: []
        },
        amortissement: {
            value: ['591'],
            except: []
        },
    },
    {
        ref: 'BS',
        libelle: 'Banques, chêques postaux, caisse et assimillés',
        brut: {
            value: ['52', '53', '54', '55', '57', '581', '582'],
            except: [],
            sens: "D"
        },
        amortissement: {
            value: ['592', '593', '594'],
            except: []
        },
    },
    {
        ref: 'BU',
        libelle: 'Ecart de conversion-Actif',
        brut: {
            value: ['478'],
            except: []
        },
        amortissement: {
            value: [],
            except: []
        },
    },
]

export const initialTotalValuesActifs = [
    {
        ref: 'AD',
        libelle: 'IMMOBILISATIONS INCORPORELLES',
        references: ['AE', 'AF', 'AG', 'AH'],
        note: '3',
        color: 'primary'
    },
    {
        ref: 'AI',
        libelle: 'IMMOBILISATIONS CORPORELLES',
        references: ['AJ', 'AK', 'AL', 'AM', 'AN', 'AP'],
        note: '3',
        color: 'info'
    },
    {
        ref: 'AQ',
        libelle: 'IMMOBILISATIONS FINANCIERES',
        references: ['AR', 'AS'],
        note: '3',
        color: 'info'
    },
    {
        ref: 'AZ',
        libelle: 'TOTAL ACTIF IMMOBILISE',
        references: ['AD', 'AI', 'AQ'],
        note: '3'
    },
    {
        ref: 'BG',
        libelle: 'CREANCES ET EMPLOIS ASSIMILES',
        references: ['BH', 'BI', 'BJ'],
    },
    {
        ref: 'BK',
        libelle: 'TOTAL ACTIF CIRCULANT',
        references: ['BA', 'BB', 'BG'],
        note: '',
        color: 'primary'
    },
    {
        ref: 'BT',
        libelle: 'TOTAL TRÉSORERIE-ACTIF',
        references: ['BQ', 'BR', 'BS'],
        note: '3',
        color: 'primary'
    },
    {
        ref: 'BZ',
        libelle: 'TOTAL GENERAL',
        references: ['AD', 'AI', 'AQ', 'BK', 'BT'],
        note: '3',
        color: 'success'
    },
]

export const initialValuesPassif = [
    {
        ref: 'CA',
        libelle: 'Capital',
        references: {
            value: ['101', '102', '103', '104'],
            except: []
        },
    },
    {
        ref: 'CB',
        libelle: 'Apporteurs capital non appelé',
        references: {
            value: ['109'],
            except: []
        }
    },
    {
        ref: 'CD',
        libelle: 'Primes liées au capital social',
        references: {
            value: ['105'],
            except: []
        },
    },
    {
        ref: 'CE',
        libelle: 'Ecarts de réévaluation',
        references: {
            value: ['106'],
            except: []
        }
    },
    {
        ref: 'CF',
        libelle: 'Réserves indisponible',
        references: {
            value: ['111', '112', '113'],
            except: []
        }
    },
    {
        ref: 'CG',
        libelle: 'Réserves libres',
        references: {
            value: ['118'],
            except: []
        }
    },
    {
        ref: 'CH',
        libelle: 'Report à nouveau',
        references: {
            value: ['12'],
            except: []
        }
    },
    {
        ref: 'CJ',
        libelle: 'Résultat net de l\'exercice',
        references: {
            value: ['13'],
            except: []
        }
    },
    {
        ref: 'CL',
        libelle: 'Subventions d\'investissement',
        references: {
            value: ['14'],
            except: []
        }
    },
    {
        ref: 'CM',
        libelle: 'Provisions réglementées',
        references: {
            value: ['15'],
            except: []
        }
    },
    {
        ref: 'DA',
        libelle: 'Emprunts et dettes financières diverse',
        references: {
            value: ['16', '181', '182', '183', '184'],
            except: []
        }
    },
    {
        ref: 'DB',
        libelle: 'Dettes de location acquisition',
        references: {
            value: ['17'],
            except: []
        }
    },
    {
        ref: 'DC',
        libelle: 'Provisions pour risques et charges',
        references: {
            value: ['19'],
            except: []
        }
    },
    {
        ref: 'DH',
        libelle: 'Dettes circulantes HAO',
        references: {
            value: ['481', '482', '484', '4998'],
            except: []
        }
    },
    {
        ref: 'DI',
        libelle: 'Clients, avances reçues',
        references: {
            value: ['419'],
            except: []
        }
    },
    {
        ref: 'DJ',
        libelle: 'Fournisseurs d\'exploitation',
        references: {
            value: ['40'],
            except: ['409']
        }
    },
    {
        ref: 'DK',
        libelle: 'Dettes fiscales et sociales',
        references: {
            value: ['42', '43', '44'],
            except: []
        },
        sens: 'C'
    },
    {
        ref: 'DM',
        libelle: 'Autres dettes',
        references: {
            value: ['185', '45', '46', '47'],
            except: ['479']
        },
        sens: 'C'
    },
    {
        ref: 'DN',
        libelle: 'Provisions pour risques à court terme',
        references: {
            value: ['499', '599'],
            except: ['4998']
        }
    },
    {
        ref: 'DQ',
        libelle: 'Banques, crédits d\'escompte',
        references: {
            value: ['564', '565'],
            except: []
        }
    },
    {
        ref: 'DR',
        libelle: 'Banques, établissements financiers et crédits de trésorerie',
        references: {
            value: ['52', '53', '561', '566'],
            except: []
        },
        sens: 'C'
    },
    {
        ref: 'DV',
        libelle: 'Ecart de conversion-Passif',
        references: {
            value: ['479'],
            except: []
        }
    },
    {
        ref: 'CP',
        libelle: 'TOTAL CAPITAUX PROPRES ET RESSOURCES ASSIMILEES',
        references: {
            value: ['CA', 'CB', 'CD', 'CE', 'CF', 'CG', 'CH', 'CJ', 'CL', 'CM'],
            except: []
        },
        color: 'primary'
    },
    {
        ref: 'DD',
        libelle: 'TOTAL DETTES FINANCIÈRES ET RESSOURCES ASSIMILIEES',
        references: {
            value: ['DA', 'DB', 'DC'],
            except: []
        },
        color: 'primary'
    },
    {
        ref: 'DF',
        libelle: 'TOTAL RESSOURCES STABLES',
        references: {
            value: ['CP', 'DD'],
            except: []
        },
        color: 'primary'
    },
    {
        ref: 'DP',
        libelle: 'TOTAL PASSIF CIRCULANT',
        references: {
            value: ['DH', 'DI', 'DJ', 'DK', 'DM', 'DN'],
            except: []
        },
        color: 'primary'
    },
    {
        ref: 'DT',
        libelle: 'TOTAL TRESORERIE-PASSIF',
        references: {
            value: ['DQ', 'DR'],
            except: []
        },
        color: 'primary'
    },
    {
        ref: 'DZ',
        libelle: 'TOTAL GENERAL',
        references: {
            value: ['CP', 'DD', 'DP', 'DT'],
            except: []
        },
        color: 'success'
    },
]

export const initialValuesResult = [
    {
        ref: 'TA',
        libelle: 'Ventes de marchandise',
        signe: '+',
        type: 'A',
        references: {
            value: ['701'],
            except: []
        },
        index: 1
    },
    {
        ref: 'RA',
        libelle: 'Achats de marchandises',
        signe: '-',
        references: {
            value: ['601'],
            except: []
        },
        index: 2
    },
    {
        ref: 'RB',
        libelle: 'Variations de stocks de marchandises',
        signe: '+/-',
        references: {
            value: ['6031'],
            except: []
        },
        index: 3
    },
    {
        ref: 'XA',
        libelle: 'MARGES COMMERCIALES (Somme TA à RB)',
        signe: '',
        references: {
            value: ['TA', 'RA', 'RB'],
            except: []
        },
        index: 4,
        color: 'primary'
    },
    {
        ref: 'TB',
        libelle: 'Ventes de produits fabriqués',
        signe: '+',
        type: 'B',
        references: {
            value: ['702', '703', '704'],
            except: []
        },
        index: 5
    },
    {
        ref: 'TC',
        libelle: 'Travaux, services vendus',
        signe: '+',
        type: 'C',
        references: {
            value: ['705', '706'],
            except: []
        },
        index: 6
    },
    {
        ref: 'TD',
        libelle: 'Produits accessoires',
        signe: '+',
        type: 'D',
        references: {
            value: ['707'],
            except: []
        },
        index: 7
    },
    {
        ref: 'XB',
        libelle: 'CHIFFRE D\'AFFAIRES (A + B + C + D)',
        signe: '',
        references: {
            value: ['TA', 'TB', 'TC', 'TD'],
            except: []
        },
        index: 8,
        color: 'primary'
    },
    {
        ref: 'TE',
        libelle: 'Production stockée (ou déstockage)',
        signe: '+/-',
        references: {
            value: ['73'],
            except: []
        },
        index: 9
    },
    {
        ref: 'TF',
        libelle: 'Production immobilisée',
        signe: '+',
        references: {
            value: ['72'],
            except: []
        },
        index: 10
    },
    {
        ref: 'TG',
        libelle: 'Subventions d\'exploitation',
        signe: '+',
        references: {
            value: ['71'],
            except: []
        },
        index: 11
    },
    {
        ref: 'TH',
        libelle: 'Autres produits',
        signe: '+',
        references: {
            value: ['75'],
            except: []
        },
        index: 12
    },
    {
        ref: 'TI',
        libelle: 'Transferts de charges d\'exploitation',
        signe: '+',
        references: {
            value: ['781'],
            except: []
        },
        index: 13
    },
    {
        ref: 'RC',
        libelle: 'Achats de matières premières et founitures liées',
        signe: '-',
        references: {
            value: ['602'],
            except: []
        },
        index: 14
    },
    {
        ref: 'RD',
        libelle: 'Variation de stocks de matières premières et fournitures liées',
        signe: '+/-',
        references: {
            value: ['6032'],
            except: []
        },
        index: 15
    },
    {
        ref: 'RE',
        libelle: 'Autres achats',
        signe: '-',
        references: {
            value: ['604', '605', '608'],
            except: []
        },
        index: 16
    },
    {
        ref: 'RF',
        libelle: 'Variation de stocks d\'autres approvisionnements',
        signe: '+/-',
        references: {
            value: ['6033'],
            except: []
        },
        index: 17
    },
    {
        ref: 'RG',
        libelle: 'Transports',
        signe: '-',
        references: {
            value: ['61'],
            except: []
        },
        index: 18
    },
    {
        ref: 'RH',
        libelle: 'Services extérieurs',
        signe: '-',
        references: {
            value: ['62', '63'],
            except: []
        },
        index: 19
    },
    {
        ref: 'RI',
        libelle: 'Impôts et taxes',
        signe: '-',
        references: {
            value: ['64'],
            except: []
        },
        index: 20
    },
    {
        ref: 'RJ',
        libelle: 'Autres charges',
        signe: '-',
        references: {
            value: ['65'],
            except: []
        },
        index: 21
    },
    {
        ref: 'XC',
        libelle: 'VALEUR AJOUTEE (XB + RA + RB) + (somme TE à RJ)',
        signe: '+',
        references: {
            value: ['XB', 'RA', 'RB', 'TE', 'TF', 'TG', 'TH', 'TI', 'RC', 'RD', 'RE', 'RF', 'RG', 'RH', 'RI', 'RJ'],
            except: []
        },
        index: 22,
        color: 'primary'
    },
    {
        ref: 'RK',
        libelle: 'Charges de personnel',
        signe: '-',
        references: {
            value: ['66'],
            except: []
        },
        index: 23
    },
    {
        ref: 'XD',
        libelle: 'EXCEDENT BRUT D\'EXPLOITATION (XC + RK)',
        signe: '',
        references: {
            value: ['XC', 'RK'],
            except: []
        },
        index: 24,
        color: 'primary'
    },
    {
        ref: 'TJ',
        libelle: 'Reprises d\'amortissements, aux provisions et dépréciations',
        signe: '+',
        references: {
            value: ['791', '798', '799'],
            except: []
        },
        index: 25
    },
    {
        ref: 'RL',
        libelle: 'Dotations aux amortissements, aux provisions et dépréciations',
        signe: '-',
        references: {
            value: ['681', '691'],
            except: []
        },
        index: 26
    },
    {
        ref: 'XE',
        libelle: 'RESULTAT D\'EXPLOITATION (XD + TJ + RL)',
        signe: '',
        references: {
            value: ['XD', 'TJ', 'RL'],
            except: []
        },
        index: 27,
        color: 'primary'
    },
    {
        ref: 'TK',
        libelle: 'Revenus financiers et assimilés',
        signe: '+',
        references: {
            value: ['77'],
            except: []
        },
        index: 28
    },
    {
        ref: 'TL',
        libelle: 'Reprises de provisions et dépréciations financières',
        signe: '+',
        references: {
            value: ['797'],
            except: []
        },
        index: 29
    },
    {
        ref: 'TM',
        libelle: 'Transferts de charges financières',
        signe: '+',
        references: {
            value: ['787'],
            except: []
        },
        index: 30
    },
    {
        ref: 'RM',
        libelle: 'Frais financiers et charges assimilées',
        signe: '-',
        references: {
            value: ['67'],
            except: []
        },
        index: 31
    },
    {
        ref: 'RN',
        libelle: 'Dotations aux provisions et aux dépréciations financières',
        signe: '-',
        references: {
            value: ['697'],
            except: []
        },
        index: 32
    },
    {
        ref: 'XF',
        libelle: 'RESULTAT FINANCIER (somme TK à RN)',
        signe: '',
        references: {
            value: ['TK', 'TL', 'TM', 'RM', 'RN'],
            except: []
        },
        index: 33,
        color: 'primary'
    },
    {
        ref: 'XG',
        libelle: 'RESULTAT DES ACTIVITES ORDINAIRES (XE + XF)',
        signe: '',
        references: {
            value: ['XE', 'XF'],
            except: []
        },
        index: 34,
        color: 'primary'
    },
    {
        ref: 'TN',
        libelle: 'Produits des cessions d\'immobilisations',
        signe: '+',
        references: {
            value: ['82'],
            except: []
        },
        index: 35
    },
    {
        ref: 'TO',
        libelle: 'Autres produits HAO',
        signe: '+',
        references: {
            value: ['84', '86', '88'],
            except: []
        },
        index: 36
    },
    {
        ref: 'RO',
        libelle: 'Valeurs comptables des cessions d\'immobilisations',
        signe: '-',
        references: {
            value: ['81'],
            except: []
        },
        index: 37
    },
    {
        ref: 'RP',
        libelle: 'Autres charges HAO',
        signe: '-',
        references: {
            value: ['83', '85'],
            except: []
        },
        index: 38
    },
    {
        ref: 'XH',
        libelle: 'RESULTAT HORS ACTIVITES ORDINAIRES (somme TN à RP)',
        signe: '',
        references: {
            value: ['TN', 'TO', 'RO', 'RP'],
            except: []
        },
        index: 39,
        color: 'primary'
    },
    {
        ref: 'RQ',
        libelle: 'Participation des travailleurs',
        signe: '-',
        references: {
            value: ['87'],
            except: []
        },
        index: 40
    },
    {
        ref: 'RS',
        libelle: 'Impôts sur le resultat',
        signe: '-',
        references: {
            value: ['89'],
            except: []
        },
        index: 41
    },
    {
        ref: 'XI',
        libelle: 'RESULTAT NET (XG + XH + RQ + RS)',
        signe: '',
        references: {
            value: ['XG', 'XH', 'RQ', 'RS'],
            except: []
        },
        index: 42,
        color: 'success'
    },
]