export const appTheme = {
  colors: {
    primary: "#FDB100",
    primary_blue: "#009EF7",
    secondary: "#EC6200",
    alter: "#A9C700",
    bgColor: "#fff7e3",
    bgColorMenu: "#faf7f0",
    success: "#04BF7B",
    danger: "#F22233",
  },

};

export const antAppToken = {
  colorPrimary: "#FDB100",
};