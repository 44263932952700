import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Input, notification, Row, Space, Table, Tag, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faCloudUpload} from "@fortawesome/free-solid-svg-icons";
import FormLabel from "../../../../../../common/Form/FormLabel";
import PageChildLabel from "../../../../../../common/PageLabel/PageChildLabel";
import ModalAlert from "../../../../../../modals/Modal";
import {ModalTitle} from "../../../../../../modals/modals.components";
import {FormContainer} from "../../../../../../common/common.components";
import SearchRow from "../../../../../../common/SearchRow";
import {NotesData} from "./NotesData";

const ModaQuestionNotes = ({
         handleVisibility,
         visible,
         incidence,
         setSelectItems,
         selectItems,
         chargement,
     }) => {
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState([]);

    const [questionsFiltered, setQuestionsFiltered] = useState( []);
    const [questionsTravail, setQuestionsTravail] = useState([]);

    const [paramsRecherche, setParamsRecherche] = useState({
        question: "",
        // assertion: "",
    });

    useEffect(() => {
        const notes = NotesData.map((item) => ({
            id: item.id,
            ref: item.note,
            libelle: item.intitule,
        }))
        setQuestions(notes)
        setQuestionsFiltered(notes)
    }, [])

    const toggleCheckbox = (id) => {
        const selectedList = [...questionsTravail];
        const valueId = parseInt(id);

        if (isNaN(valueId)) {
            return;
        }

        const add = questions.find((item) => item.id === valueId);

        const isAlreadySelected = questionsTravail.some((element) => element.question_id === add.id);
        if (!isAlreadySelected) {
            const newElement = {
                question_id: add.id,
                ref: add.ref,
                questionLibelle: add.libelle,
                assertions: add.assertions,
            };

            setQuestionsTravail([...selectedList, newElement]);
        }
        else {
            const selectedIndex = selectedList.findIndex((item) => item.question_id === (add.id));

            const updatedList = selectedList.filter((_, i) => i !== selectedIndex);
            setQuestionsTravail(updatedList)
        }
    };

    const handleValidate = () => {
        if(questionsTravail.length > 0 ){
            setLoading(true);
            setTimeout(() => {
                const updateList = []
                questionsTravail.forEach((programme) => {

                    const newElement = {
                        // id: programme.id,
                        note: programme.ref,
                        question_id: programme.question_id,
                        libelle: programme.questionLibelle,
                        reponse: '',                   };

                    updateList.push(newElement)
                })

                setSelectItems([...selectItems, ...updateList]);

                handleVisibility();
                notification.success({
                    description: "Question ajouté avec succès !",
                });

                setLoading(false);
            }, 1500)
        }
    }
    const columnsQuestions = [
        {
            title: () => <TableTitle>REF</TableTitle>,
            dataIndex: 'reference',
            width: 40,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '13px'}}>
                        {record.ref}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 250,
            render: (_, record, index) => (
                <span className='' key={index} style={{fontSize: '13px'}}>
                    {record.libelle}
                </span>
            ),
        },
        // {
        //     title: () => <TableTitle>Assertions</TableTitle>,
        //     dataIndex: 'assertions',
        //     width: 50,
        //     render: (_, record) => (
        //         <Space>
        //             {record.assertions?.map((assertion, index) => (
        //                 <Tag key={index}>
        //                     <span className='fw-bolder' >
        //                         {assertion}
        //                     </span>
        //                 </Tag>
        //             ))}
        //         </Space>
        //     ),
        // },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 50,
            render: (_, record) => (
                <span className="form-check form-check-custom me-5">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        checked={
                            questionsTravail.some((item) => item.question_id === record.id)
                        }
                        onChange={() => {toggleCheckbox(record.id)}}
                    />
              </span>
            ),
        },
    ];

    const columnsSelectQuestions = [
        {
            title: () => <TableTitle>Question</TableTitle>,
            dataIndex: 'libelle',
            width: 450,
            render: (_, record) => (
                <div className="d-flex align-center">
                    <div className="me-2">
                        <Tag>
                            <span className='fw-boldest' style={{fontSize: '12px'}}>
                                {record.ref}
                            </span>
                        </Tag>
                    </div>
                    <span className='' style={{fontSize: '13px'}}>
                        {record.questionLibelle}
                    </span>
                </div>
            ),
        },
        // {
        //     title: () => <TableTitle>Assertions</TableTitle>,
        //     dataIndex: 'assertions',
        //     width: 100,
        //     render: (_, record) => (
        //         <Space>
        //             {record.assertions?.map((assertion, index) => (
        //                 <Tag key={index}>
        //                     <span className='fw-bolder' >
        //                         {assertion}
        //                     </span>
        //                 </Tag>
        //             ))}
        //         </Space>
        //     ),
        // },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = questions.filter((ident) => {
                const {
                    question,
                    // assertion,
                } = paramsRecherche;

                const lowercaseQuestion = question?.toLowerCase();
                // const lowercaseAssertion = assertion?.toLowerCase();


                const matchQuestion =
                    !question ||
                    (ident.libelle
                            ?.toLowerCase()
                            ?.includes(lowercaseQuestion) ||
                        ident.ref
                            ?.toLowerCase()
                            ?.includes(lowercaseQuestion) );

                // const matchAssertion =
                //     !assertion ||
                //     (ident.assertions ?? []).some(ass =>
                //         ass?.toLowerCase()
                //             ?.includes(lowercaseAssertion)
                //     );



                return (
                    matchQuestion
                    // matchAssertion &&
                );
            });

            setQuestionsFiltered(filteredClients)

            setLoading(false);
        }, 1000);
    };

    const handleReset = () => {
        setLoading(true);
        setParamsRecherche({
            question: "",
        });
        setQuestionsFiltered(questions);
        setLoading(false);
    };

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Sélectionner les questions de travail (sources) </ModalTitle>}
                footer={[]}
                modalStyles={{ top: 10 }}
                width={1300}
            >
                <FormContainer>
                    <SearchRow
                        handleSearch={handleSearch}
                        handleReset={handleReset}
                        loading={loading}
                    >
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                        >
                            <FormLabel label="Question" />
                            <Input
                                style={{ width: "100%" }}
                                size="large"
                                placeholder="Rechercher par la référence, libellé"
                                value={paramsRecherche.question || null}
                                onChange={(e) => {
                                    setParamsRecherche({
                                        ...paramsRecherche,
                                        question: e.target.value,
                                    })
                                }}
                            />
                        </Col>
                    </SearchRow>
                </FormContainer>
                <Row gutter={[16, 16]}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 16 }}
                        lg={{ span: 14 }}
                        xl={{ span: 14 }}
                    >
                        <FormContainer>
                            <div className="table-responsive">
                                <Table
                                    rowClassName={() =>
                                        `h-3px p-0 m-0 lh-0.5`
                                    }
                                    columns={columnsQuestions}
                                    dataSource={questionsFiltered ?? []}
                                    pagination={false}
                                    scroll={{y: 400}}
                                    loading={chargement || loading}
                                    size={"small"}
                                />
                            </div>
                        </FormContainer>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 10 }}
                        xl={{ span: 10 }}
                    >
                        <FormContainer>
                            <div className="table-responsive">
                                <Table
                                    rowClassName={() =>
                                        `h-3px p-0 m-0 lh-0.5`
                                    }
                                    columns={columnsSelectQuestions}
                                    dataSource={questionsTravail ?? []}
                                    pagination={false}
                                    scroll={{y: 400}}
                                    loading={chargement || loading}
                                    size={"small"}
                                />
                            </div>
                        </FormContainer>
                    </Col>
                </Row>
                <div className="d-flex justify-content-center align-items-center mt-3">
                    <div className="w-350px">
                        <Button
                            type="primary"
                            block
                            size="large"
                            // icon={<FontAwesomeIcon icon={fa} /> }
                            loading={loading}
                            disabled={loading}
                            onClick={handleValidate}
                        >
                            Valider
                        </Button>
                    </div>
                </div>
            </ModalAlert>
        </>
    )
}

const FicheR4 = ({
     mission,
     selectItems,
     setSelectItems,
     conclusion,
     setConclusion,
     setIsAddMemoire,
     isAddMemoire,
     chargement,
     setIsTaskSave,
    }) => {
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleAdd = () => {
        setLoading(true);
        setTimeout(() => {
            const newElement = {
                note: '',
                libelle: '',
                reponse: '1',
                commentaire: '',
            };

            setSelectItems([...selectItems, newElement]);
            setLoading(false);
            setIsTaskSave(false);
            notification.success({
                description: "Question ajouté avec succès !",
            });
        }, 1000);
    };

    const handleDeleteElement = (index) => {
        setLoading(true);
        setTimeout(() => {
            const updatedList = selectItems.filter((_, i) => i !== index);
            setSelectItems(updatedList);
            notification.success({
                description: "Question supprimé avec succès !",
            });
            setLoading(false)
        }, 1000)
    }

    const columns = [
        {
            title: () => <TableTitle>Notes</TableTitle>,
            dataIndex: 'note',
            sorter: true,
            width: 150,
            render: (_, record, index) => (
                // <Input.TextArea
                //     style={{fontSize: "14px"}}
                //     size="large"
                //     bordered={false}
                //     placeholder="Saisisser la note..."
                //     value={record.note}
                //     maxLength={1000}
                //     showCount
                //     autoSize={{
                //         minRows: 1,
                //         maxRows: 2,
                //     }}
                //     onChange={(e) =>{
                //         const updatedList = selectItems.map((item, i) =>
                //             i === index ? { ...item,  note: e.target.value } : item
                //         );
                //
                //         setSelectItems(updatedList);
                //         setIsTaskSave(true);
                //     }}
                // />
                <div className="fw-bolder fs-4">{record.note}</div>
            )
        },
        {
            title: () => <TableTitle>Intitulé</TableTitle>,
            dataIndex: 'note',
            sorter: true,
            width: 300,
            render: (_, record, index) => (
                <Input.TextArea
                    style={{fontSize: "14px"}}
                    size="large"
                    bordered={false}
                    placeholder="Saisisser l'intitulé..."
                    value={record.libelle}
                    maxLength={2000}
                    // showCount
                    autoSize={{
                        minRows: 1,
                        maxRows: 4,
                    }}
                    onChange={(e) =>{
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  libelle: e.target.value } : item
                        );

                        setSelectItems(updatedList);
                        setIsTaskSave(true);
                    }}
                />
            ),
        },
        {
            title: () => <TableTitle>Réponse (A / AN)</TableTitle>,
            dataIndex: 'choix',
            width: 100,
            render: (_, record, index) => (
                <select
                    style={{fontSize: '12px'}}
                    name='reponse'
                    className="form-select"
                    onChange={(e) => {
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  reponse: e.target.value === 'A' ? '1' : '2'} : item
                        );

                        setSelectItems(updatedList);
                        setIsTaskSave(true);
                    }}
                    value={record.reponse === '1' ? 'A' : 'N/A'}
                >
                    <option>Sélectionner...</option>
                    {['A', 'N/A'].map((opt, index) => (
                        <option key={index} value={opt}>
                            {opt}
                        </option>
                    ))}
                </select>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 400,
            render: (_, record, index) => (
                <>
                    <div className="">
                        <Input.TextArea
                            style={{fontSize: "14px"}}
                            size="large"
                            bordered={false}
                            placeholder="Saisisser le commentaire..."
                            value={record.commentaire}
                            maxLength={3000}
                            // showCount
                            autoSize={{
                                minRows: 1,
                                maxRows: 6,
                            }}
                            onChange={(e) =>{
                                const updatedList = selectItems.map((item, i) =>
                                    i === index ? { ...item,  commentaire: e.target.value } : item
                                );
                                setSelectItems(updatedList);
                                setIsTaskSave(true);
                            }}
                        />
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 70,
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteElement(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
            responsive: ['sm']
        },
    ];

    const handleModalOpen = () => {
        setIsModalOpen(prevState => !prevState);
    }

    return (
        <>
            <div className="modal-body">
                <PageChildLabel label={"Fiche Récapitulative des notes annexes présentées"} />
                <div className="d-flex justify-content-end align-center my-2 me-5">
                    <div className="d-flex flex-wrap align-center">
                        <Space>
                            {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                                <Button
                                    size={"middle"}
                                    style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                    onClick={handleAdd}
                                    icon={<FontAwesomeIcon icon={faCartPlus}/>}
                                >
                                    Ajouter
                                </Button>
                            </Tooltip>}
                            {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                                <Button
                                    size={"middle"}
                                    style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                    onClick={handleModalOpen}
                                    icon={<FontAwesomeIcon icon={faCloudUpload}/>}
                                >
                                    Charger
                                </Button>
                            </Tooltip>}
                        </Space>
                    </div>
                </div>
                <div className="table-responsive pt-5 pb-10">
                    <Table
                        rowClassName={() =>
                            `h-3px p-0 m-0 lh-0.5`
                        }
                        columns={columns}
                        // rowKey={(record, index) => index}
                        dataSource={selectItems ?? []}
                        pagination={false}
                        // scroll={{y: 800}}
                        loading={chargement || loading}
                        // rowSelection
                        size={"small"}
                    />
                </div>
                <br/>
                <br/>
                <div className="d-flex flex-column mb-3 mx-2">
                    <FormLabel label="CONCLUSION" />
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        placeholder="Saisisser la conclusion..."
                        value={conclusion}
                        maxLength={5000}
                        showCount
                        autoSize={{
                            minRows: 8,
                            maxRows: 10,
                        }}
                        onChange={(e) => {
                            setConclusion(e.target.value)
                            setIsTaskSave(true);
                        }}
                    />
                    <div className='mt-5'>
                        <div className="">
                            <Checkbox
                                onChange={() => setIsAddMemoire(!isAddMemoire)}
                                value={isAddMemoire}
                            >
                                Aide memoire
                            </Checkbox>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen &&
                <ModaQuestionNotes
                    handleVisibility={handleModalOpen}
                    visible={isModalOpen}
                    selectItems={selectItems}
                    setSelectItems={setSelectItems}
                    chargement={loading || chargement}
                />
            }
        </>
    );
}

export {FicheR4}