import React, {useEffect, useState} from "react";
import moment from "moment";
import {Col, DatePicker, Input, Select, Table, Tag} from "antd";
import {getListeEquipeMission} from "../../../data/exercice";
import {getListeIdentificationMission} from "../../../data/rateau";
import {ClockCircleOutlined} from "@ant-design/icons";
import {getListeTacheActive} from "../../../data/params/tache";
import {getListeInterventionActive} from "../../../data/params/intervention";
import {getListeRole} from "../../../data/params/role";
import TableTitle from "../../common/TableTitle";
import {appTheme} from "../../../config/theme";
import {FormContainer, TagItem} from "../../common/common.components";
import PageLabel from "../../common/PageLabel/PageLabel";
import SearchRow from "../../common/SearchRow";
import FormLabel from "../../common/Form/FormLabel";
import {getListeSommaireActive} from "../../../data/params/sommaire";

const Identification = ({mission, client}) => {
    const [identifications, setIdentifications] = useState([]);
    const [equipeIdentifications, setEquipeIdentifications] = useState([]);
    const [filtersItems, setFiltersItems] = useState([]);
    const [taches, setTaches] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sommairesTable, setSommairesTable] = useState([]);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const [paramsRecherche, setParamsRecherche] = useState({
        infosUser: "",
        tache_id: null,
        intervention_id: null,
        role_id: null,
        reference: "",
        date_debut: "",
        date_fin: "",
    });

    useEffect(() => {
        if(mission){
            setLoading(true);
            getListeIdentificationMission(mission.id)
                .then((res) => {
                    setIdentifications(res.result.sort((p1, p2) => {
                        return new Date(p2.createdAt) - new Date(p1.createdAt);
                    }))
                    setFiltersItems(res.result.sort((p1, p2) => {
                        return new Date(p2.createdAt) - new Date(p1.createdAt);
                    }))
                    setLoading(false)
                })
                .catch(err => {
                    console.error(err)
                    setLoading(false);
                })
        }
    }, [mission])

    const fetchListeTache = async () => {
        setLoading(true)
        getListeTacheActive().then((res) => {
            setTaches(res?.result);
            setLoading(false)
        })
            .catch(err => {
                console.error(err);
                setLoading(false)
            });
    };

    const fetchIntervention = async () => {
        setLoading(true)
        getListeInterventionActive().then((res) => {
            setInterventions(res?.result);
            setLoading(false)
        }).catch(err => {
            console.error(err);
            setLoading(false)
        });
    };

    const fetchRole = async () => {
        setLoading(true)
        getListeRole().then((res) => {
            setRoles(res);
        }).catch(err => {
            console.error(err);
            setLoading(false)
        });
    };

    useEffect(() => {
        fetchRole();
        fetchListeTache();
        fetchIntervention();
    }, []);

    const fetchEquipes = async (id) => {
        setLoading(true)
        getListeEquipeMission(id)
            .then((res) => {
                const updatedIdentifications = identifications.map((item) => {
                    const user = res.find((key) => key.membre_id === item.Lea_User?.id);
                    return user ? { ...item, role: user.Lea_RoleMission } : item;
                });
                setEquipeIdentifications(res.length > 0 ? updatedIdentifications : identifications);
                setFiltersItems(res.length > 0 ? updatedIdentifications : identifications);
                setLoading(false)
            })
            .catch((error) => {
                console.error("Erreur", error);
                setLoading(false)
            });
    };

    useEffect(() => {
        fetchEquipes(mission.id);
    }, [mission, identifications]);

    const fetchSommaire = () => {
        getListeSommaireActive()
            .then((res) => {
                setSommairesTable(res.result);
            });
    };

    useEffect(() => {
        fetchSommaire();
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setEquipeIdentifications([]);
        }
    };

    const columns = [
        {
            title: () => <TableTitle>Nom et Prénoms / Initial</TableTitle>,
            dataIndex: 'name',
            width: 150,
            render: (_, record) => (
                <>
                    <span style={{ color: "#000", fontWeight: "bold" }}>
                        {record.Lea_User?.initial ? record.Lea_User?.initial : (record.Lea_User?.nom_user + ' ' + record.Lea_User?.prenom_user)}
                    </span>
                    <br />
                    {record.Lea_User?.initial && <Tag prefix="@" color={appTheme.colors.primary_blue}>{record.Lea_User?.initial}</Tag>}
                </>
            ),
        },
        {
            title: () => <TableTitle>Titre</TableTitle>,
            dataIndex: 'titre',
            width: 150,
            render: (_, record) => (
                <TagItem color={appTheme.colors.primary_blue}>{record?.role?.libelle}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Interventions</TableTitle>,
            width: 200,
            dataIndex: 'interventionType',
            render: (_, record) => (
                <TagItem>{record.Bog_Intervention?.libelle}</TagItem>
            ),
        },
        {
            title: () => <TableTitle>Date</TableTitle>,
            dataIndex: 'date',
            width: 150,
            render: (_, record) => (
                <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                    <ClockCircleOutlined style={{fontSize: '15px'}} />{' '}
                    {moment(record.dateAdd).format('DD/MM/YYYY à HH:mm')}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Heure d'entrée</TableTitle>,
            dataIndex: 'heureEntree',
            width: 100,
            render: (_, record) => (
                <span className='fw-bolder fs-7' >
                    {record?.heureEntree}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Heure de sortie</TableTitle>,
            dataIndex: 'heureSortie',
            width: 100,
            render: (_, record) => (
                <span className='fw-bolder fs-7' >
                    {(record?.heureSortie)}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Tâches</TableTitle>,
            dataIndex: 'intervention',
            width: 200,
            render: (_, record) => (
                <TagItem>{record.Bog_TacheAutorise?.libelle}</TagItem>
            )
        },
        {
            title: () => <TableTitle>Reference</TableTitle>,
            dataIndex: 'reference',
            width: 100,
            render: (_, record) => (
                record.codeSommaire && <Tag color={appTheme.colors.primary}>{record.codeSommaire}</Tag>
            ),
        },
        {
            title: () => <TableTitle>Dossier client</TableTitle>,
            dataIndex: 'dossiers',
            render: () => (
                <div className='d-flex flex-column'>
                    <span style={{ color: "#000", fontWeight: "bold", fontSize: "12px" }}>
                        {client?.sigle || ""}
                    </span>
                    <span className='text-gray-800 fw-bold text-hover-primary mb-1' style={{fontSize: '12px'}}>
                        {mission.mission} <span className="ms-3">{mission.annee && <TagItem color={appTheme.colors.primary}>{mission.annee}</TagItem>}</span>
                    </span>
                </div>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = equipeIdentifications.filter(ident =>
                (!paramsRecherche.infosClient || (ident.nom_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    ident.initial?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    ident.Lea_User?.nom_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()) ||
                    ident.Lea_User?.prenom_user?.toLowerCase()?.includes(paramsRecherche.infosClient?.toLowerCase()))) &&
                (!paramsRecherche.role_id || ident.role_id === paramsRecherche.role_id) &&
                (!paramsRecherche.tache_id || ident.tache_id === paramsRecherche.tache_id) &&
                (!paramsRecherche.reference || ident.codeSommaire === paramsRecherche.reference) &&
                (!paramsRecherche.intervention_id || ident.intervention_id === paramsRecherche.intervention_id)
                ((!paramsRecherche.date_debut && !paramsRecherche.date_fin) || moment(ident.createdAt).isBetween(
                    moment(paramsRecherche.date_debut),
                    moment(paramsRecherche.date_fin)
                ))

            );
            setFiltersItems(filteredClients)
            setLoading(false);
        }, 1000);
    };

    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            infosUser: "",
            tache_id: null,
            intervention_id: null,
            role_id: null,
            reference: "",
            date_debut: "",
            date_fin: "",
        });
        setFiltersItems(equipeIdentifications)
        setLoading(false);
    };

    return (
        <>
            <div className="mx-5">
                <PageLabel label="Liste des identifications"/>
                <SearchRow
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    loading={loading}
                >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Utilisateur" />
                        <Input
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Nom, prenom, initial, téléphone, email"
                            onChange={(e) =>
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    infosUser: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Sommaire" />
                        <Select
                            placeholder="Code du sommaire"
                            size="middle"
                            style={{ width: "100%" }}
                            onSelect={(value) =>
                                setParamsRecherche({ ...paramsRecherche, reference: value })
                            }
                            options={sommairesTable.map((statut) => ({
                                value: statut.libelle,
                                label: `${statut.libelle} - ${statut.description}`,
                            }))}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Roles" />
                        <Select
                            showSearch
                            placeholder="Sélectionnez la rôle..."
                            optionFilterProp="label"
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={roles.map((role) => ({
                                value: role.id,
                                label: role.libelle,
                            }))}

                            onSelect={(value) =>
                                setParamsRecherche({ ...paramsRecherche, role_id: value })
                            }
                            size="large"
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Tâches" />
                        <Select
                            showSearch
                            placeholder="Sélectionnez la tâche..."
                            optionFilterProp="label"
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={taches.map((tache) => ({
                                value: tache.id,
                                label: tache.libelle,
                            }))}

                            onSelect={(value) =>
                                setParamsRecherche({ ...paramsRecherche, tache_id: value })
                            }
                            size="large"
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Interventions" />
                        <Select
                            showSearch
                            placeholder="Sélectionnez l'intervation..."
                            optionFilterProp="label"
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={interventions.map((tache) => ({
                                value: tache.id,
                                label: tache.libelle,
                            }))}
                            onSelect={(value) =>
                                setParamsRecherche({ ...paramsRecherche, intervention_id: value })
                            }
                            size="large"
                            style={{ width: "100%"}}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Date début" />
                        <DatePicker
                            size="large"
                            placeholder="Date début"
                            style={{ width: "100%" }}
                            allowClear
                            onChange={(date, dateString) =>
                                date !== null
                                    ? setParamsRecherche({
                                        ...paramsRecherche,
                                        date_debut: date.format("YYYY-MM-DD"),
                                    })
                                    : setParamsRecherche({ ...paramsRecherche, date_debut: "" })
                            }
                            format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Date fin" />
                        <DatePicker
                            size="large"
                            placeholder="Date fin"
                            style={{ width: "100%" }}
                            allowClear
                            onChange={(date, dateString) =>
                                date !== null
                                    ? setParamsRecherche({
                                        ...paramsRecherche,
                                        date_fin: date.format("YYYY-MM-DD"),
                                    })
                                    : setParamsRecherche({ ...paramsRecherche, date_fin: "" })
                            }
                            format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                        />
                    </Col>
                </SearchRow>
                <FormContainer>
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                rowKey={(record) => record.id}
                                dataSource={filtersItems}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </FormContainer>
            </div>
        </>
    );
}

export {Identification}