import axios from 'axios'
import { AJOUTER_ROLE, LISTE_ROLE, MODIFIER_ETAT_ROLE, MODIFIER_ROLE, SUPPRIMER_ROLE } from '../../api/apiRoutes'
import {setAuthorizationHeader} from "../../api/config";


export const addRoleMission = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(AJOUTER_ROLE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const updateRoleMission = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(MODIFIER_ROLE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeRoleMission = async () => {
    setAuthorizationHeader()
    return axios
        .get(LISTE_ROLE)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const deleteRoleMission = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(SUPPRIMER_ROLE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const updateEtatRoleMission = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(MODIFIER_ETAT_ROLE, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}
