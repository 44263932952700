import {Button, Col, Divider, notification, Row, Table, Tooltip, Typography} from "antd";
import React, { useEffect, useState } from "react";
import { getBalanceProvisoiresDetails , getBalanceDefinitifDetails} from "../../../../../../../data/balance/balance";
import { getListeExerciceClients } from "../../../../../../../data/exercice";
import { SwapOutlined} from "@ant-design/icons";
import {ajouterZeros} from "../../../../../../utilities/Utilities";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faScaleBalanced} from "@fortawesome/free-solid-svg-icons";
import {SearchHeader} from "../../../../../../layout/header/HeaderList";
import {ModalTitle} from "../../../../../../modals/modals.components";
import ModalAlert from "../../../../../../modals/Modal";

const { Text, Title } = Typography;
const MenuTabs = ["COMPTE BILAN", "COMPTE RESULTATS"]

const IntangibiliteForm = ({
       mission,
       setBalanceData,
       balanceData,
       chargement,
       setIsTaskSave,
       isProvisoire, client
    }) => {
    const [missionN_1, setMissionN_1] = useState(null);
    const [balanceDefN_1, setBalanceDefN_1] = useState([]);
    const [compteBilan, setCompteBilan] = useState([]);
    const [compteResultat, setCompteResultat] = useState([]);
    const [diffDebitBilan, setDiffDebitBilan] = useState(0);
    const [diffCreditBilan, setDiffCreditBilan] = useState(0);
    const [diffDebitResultat, setDiffDebitResultat] = useState(0);
    const [diffCreditResultat, setDiffCreditResultat] = useState(0);
    const [calculBilan, setCalculBilan] = useState(0);
    const [calculResultat, setCalculResultat] = useState(0);
    const [activeTab, setActiveTab] = useState(0);
    const [selectedBalances, setSelectedBalances] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalId, setModalId] = useState(1);
    const [title, setTitle] = useState('');
    const [modalBalance, setModalBalance] = useState([]);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
        },
    });

    useEffect(() => {
        if(mission){
            const fetchBalanceProvisoireN = () => {
                setLoading(true)
                getBalanceProvisoiresDetails(mission.id)
                    .then((res) => {
                        if (res.results) {
                            setSelectedBalances(res.results.Lea_BalanceDetails);
                        } else {
                            setSelectedBalances([]);
                        }
                        setLoading(false)
                    })
            }

            const fetchBalanceDefinitif = () => {
                setLoading(true)
                getBalanceDefinitifDetails(mission.id)
                    .then((res) => {
                        if (res.results) {
                            setSelectedBalances(res.results.Lea_BalanceDetails);
                        } else {
                            setSelectedBalances([]);
                        }
                        setLoading(false)
                    })
            }

            fetchBalanceDefinitif();
            fetchBalanceProvisoireN();
        }
    }, [mission]);

    useEffect(() => {
        if (client) {
            getListeExerciceClients(client.id)
                .then((res) => {
                    const missN1 = res.find((opt) => parseInt(opt.annee) === parseInt(mission?.annee) - 1)
                    setMissionN_1(missN1)
                });
        }
    }, [client, mission])

    useEffect(() => {
        if(missionN_1){
            const fetchBalanceDefinitifN_1 = () => {
                setLoading(true)
                getBalanceDefinitifDetails(missionN_1.id)
                    .then((res) => {
                        if (res.results) {
                            setBalanceDefN_1(res.results.Lea_BalanceDetails);
                        } else {
                            setBalanceDefN_1([]);
                        }
                        setLoading(false)
                    })
            }

            fetchBalanceDefinitifN_1();
        }
    }, [missionN_1]);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

    };

    //Action on a click
    const handleCalculClick = () => {
        setLoading(true);
        setTimeout(() => {
            if(balanceData && balanceData.length > 0){
                setBalanceData([]);
            }

            const bilanMap = new Map();

            selectedBalances.forEach((balance) => {
                const isExist = balanceDefN_1.find((item) => item.numeroCompte.includes(balance.numeroCompte));

                const newElement = {
                    isExistDef: !isExist,
                    isExistProv: false,
                    compte_Prov: balance.numeroCompte,
                    libelle_Prov: balance.libelle,
                    // SD_RAN: totalANDebit > 0 ? totalANDebit : 0,
                    // SC_RAN: totalANCredit > 0 ? totalANCredit : 0,
                    SD_RAN: balance.anouveauDebit || 0,
                    SC_RAN: balance.anouveauCredit || 0,
                    compte_Def: balance.numeroCompte,
                    libelle_Def: balance.libelle,
                    SD_N1: isExist ? (isExist.soldeDebit || 0) : 0,
                    SC_N1: isExist ? (isExist.soldeCredit || 0) : 0,
                    // SD_N1: totalSoldeN_1 > 0 ? totalSoldeN_1 : 0,
                    // SC_N1: totalCreditN_1 > 0 ? totalCreditN_1 : 0,
                    // DIFF_SD: ((totalDebit > 0 ? totalDebit : 0) - parseInt(isExist ? (isExist.soldeDebit || 0) : 0)),
                    // DIFF_SC: ((totalCredit > 0 ? totalCredit : 0) - parseInt(isExist ? (isExist.soldeCredit || 0) : 0)),
                    DIFF_SD: (parseInt(balance.anouveauDebit || 0) - parseInt(isExist ? (isExist.soldeDebit || 0) : 0)),
                    DIFF_SC: (parseInt(balance.anouveauCredit || 0) - parseInt(isExist ? (isExist.soldeCredit || 0) : 0)),
                };

                bilanMap.set(balance.numeroCompte, newElement);
            });

            balanceDefN_1.forEach((balance) => {
                const isExist = selectedBalances.find((item) => item.numeroCompte.includes(balance.numeroCompte));

                const existingElement = bilanMap.get(balance.numeroCompte);
                if (existingElement) {
                    existingElement.SD_N1 = balance.soldeDebit || 0;
                    existingElement.SC_N1 = balance.soldeCredit || 0;
                    existingElement.DIFF_SD = (parseInt(isExist ? (isExist.anouveauDebit || 0) : 0) - parseInt(balance.soldeDebit || 0));
                    existingElement.DIFF_SC = (parseInt(isExist ? (isExist.anouveauCredit || 0) : 0) - parseInt(balance.soldeCredit || 0));
                } else {
                    const newElement = {
                        isExistDef: false,
                        isExistProv: !isExist,
                        compte_Prov: balance.numeroCompte,
                        libelle_Prov: balance.libelle,
                        SD_RAN: isExist ? (isExist.anouveauDebit || 0) : 0,
                        SC_RAN: isExist ? (isExist.anouveauCredit || 0) : 0,
                        compte_Def: balance.numeroCompte,
                        libelle_Def: balance.libelle,
                        SD_N1: balance.soldeDebit || 0,
                        SC_N1: balance.soldeCredit || 0,
                        DIFF_SD: (parseInt(isExist ? (isExist.anouveauDebit || 0) : 0) - parseInt(balance.soldeDebit || 0)),
                        DIFF_SC: (parseInt(isExist ? (isExist.anouveauCredit || 0) : 0) - parseInt(balance.soldeCredit || 0)),
                    };

                    bilanMap.set(balance.numeroCompte, newElement);
                }
            });

            const updateBalance = [...bilanMap.values()];

            setBalanceData(updateBalance);

            const arrayBalance = updateBalance.filter((item) => parseInt(item.compte_Prov) < ajouterZeros(6, item.compte_Prov))
            setCompteBilan(arrayBalance.sort((a, b) => a.compte_Prov?.localeCompare(b.compte_Prov)));

            const debitBilan = arrayBalance.reduce((total, bilanItem) => total + parseInt(bilanItem.DIFF_SD || 0), 0);
            const creditBilan = arrayBalance.reduce((total, bilanItem) => total + parseInt(bilanItem.DIFF_SC || 0), 0);

            setDiffDebitBilan(debitBilan);
            setDiffCreditBilan(creditBilan);
            setCalculBilan(debitBilan - creditBilan);

            const arrayResultat  = updateBalance.filter((item) => parseInt(item.compte_Prov) >= ajouterZeros(6, item.compte_Prov))
            setCompteResultat(arrayResultat.sort((a, b) => a.compte_Prov?.localeCompare(b.compte_Prov)));

            const debitResultat = arrayResultat.reduce((total, bilanItem) => total + parseInt(bilanItem.DIFF_SD || 0), 0);
            const creditResultat = arrayResultat.reduce((total, bilanItem) => total + parseInt(bilanItem.DIFF_SC || 0), 0);

            setDiffDebitResultat(debitResultat);
            setDiffCreditResultat(creditResultat);
            setCalculResultat(debitResultat - creditResultat);
            setLoading(false)

            setIsTaskSave(prevState => [...prevState, 1]);
            notification.warning({
                description: "Envoyé avec succès a l'historique Balances !",
            });
        }, 1000)
    };

    //change sous-menu
    const handleTabChange = (tabName) => {
        setLoading(true);
        setTimeout(() => {
            setActiveTab(tabName);
            setLoading(false)
        }, 1000)
    };

    const handleModalOpen = (id, balances, title) => {
        setModalId(id)
        setModalBalance(balances || [])
        setTitle(title || "")

        setIsModalOpen(prevState => !prevState)
    };

    const columnsNModal = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Compte</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.numeroCompte}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.libelle}
                </span>
            ),
        },
        {
            title: () => <TableTitle>SD_RAN</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.anouveauDebit || 0).toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>SC_RAN</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.anouveauCredit || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Solde</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {((record.anouveauDebit || 0)-(record.anouveauCredit || 0)).toLocaleString('fr') || 0}
                </div>
            ),
        },
    ];

    const columnsN_1Modal = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Compte</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.numeroCompte}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.libelle}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Débit N-1</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeDebit || 0).toLocaleString('fr')}
                </div>
            ),
        },

        {
            title: () => <TableTitle>Crédit N-1</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeCredit || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },

        {
            title: () => <TableTitle>Solde N-1</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {((record.soldeDebit || 0)-(record.soldeCredit || 0)).toLocaleString('fr') || 0}
                </div>
            ),
        },
    ];


    const columnsResultat = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Compte</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistProv ? '' : record.compte_Prov}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistProv ? '' : record.libelle_Prov}
                </span>
            ),
        },
        {
            title: () => <TableTitle>{`SD_RAN ${mission.annee || "N"}`}</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.SD_RAN.toLocaleString('fr')}
                </div>
            ),
        },

        {
            title: () => <TableTitle>{`SC_RAN ${(mission.annee) || "N"} `}</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.SC_RAN.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Compte</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistDef ? '' : record.compte_Def}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistDef ? '' : record.libelle_Def}
                </span>
            ),
        },
        {
            title: () => <TableTitle>{`SD ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.SD_N1.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>{`SC ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.SC_N1.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>DIFF SD</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.DIFF_SD.toLocaleString('fr')}
                </div>
            ),
        },

        {
            title: () => <TableTitle>DIFF SC</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.DIFF_SC.toLocaleString('fr')}
                </div>
            ),
        },
    ];

    return (
        <>
            <div className="py-2">
                <div className="flex-lg-row-fluid">
                    <div className='d-flex justify-content-between align-center'>
                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-3">
                            {(MenuTabs ?? []).map((menu, index) => (
                                <li key={index} className="nav-item">
                                    <button
                                        style={{fontWeight: "600", fontFamily: "Oswald"}}
                                        className={`nav-link text-active-primary text-uppercase pb-4 fs-6 ${activeTab === (index) ? 'active' : ''}`}
                                        onClick={() => handleTabChange(index)}
                                    >
                                        {menu}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <Title className="mt-5 mb-10" style={{display: "block", fontWeight: '600', color: appTheme.colors.primary}} level={4}># {MenuTabs[activeTab]}</Title>
                    <form className="stepper-form">
                        <Row gutter={[16, 16]}>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 20 }}
                                xl={{ span: 20 }}
                            >
                                {<Row gutter={[16, 16]}>
                                    <Col
                                        xs={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 12 }}
                                        xl={{ span: 12 }}
                                    >
                                        <div className="card card-dashed h-xl-50 flex-row flex-stack flex-wrap me-9">
                                            <div className="d-flex flex-column py-2 mx-2">
                                                <h1 className="d-flex align-items-center fw-bold" style={{fontSize: '14px'}}>
                                                    COMPTE DE BILAN
                                                </h1>
                                                <div className="d-flex align-center">
                                                    <div>
                                                        <div className=" fw-bolder text-dark" style={{fontSize: '14px'}}>
                                                            Diff Débit <b>({diffDebitBilan.toLocaleString('fr')})</b> - Diff crédit <b>({diffCreditBilan.toLocaleString('fr')})</b> = <b className="text-success">{calculBilan.toLocaleString('fr')}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col
                                        xs={{ span: 24 }}
                                        md={{ span: 24 }}
                                        lg={{ span: 12 }}
                                        xl={{ span: 12 }}
                                    >
                                        <div className="card card-dashed h-xl-50 flex-row flex-stack flex-wrap">
                                            <div className="d-flex flex-column py-2 mx-2">
                                                <h1 className="d-flex align-items-center fw-bold" style={{fontSize: '14px'}}>
                                                    COMPTE DE RESULTAT
                                                </h1>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <div className="fw-bolder text-dark" style={{fontSize: '14px'}}>
                                                            Diff Débit <b>({diffDebitResultat.toLocaleString('fr')})</b> - Diff crédit <b>({diffCreditResultat.toLocaleString('fr')})</b> = <b className="text-success">{calculResultat.toLocaleString('fr')}</b>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>}
                            </Col>
                            {<Col
                                xs={{span: 24}}
                                md={{span: 24}}
                                lg={{span: 4}}
                                xl={{span: 4}}
                            >
                                <div className="d-flex justify-content-end align-items-end">
                                    <div className="d-flex align-items-center">
                                        <div className="me-10">
                                            <Tooltip title="Calculer les etats financiers">
                                                <Button
                                                    disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                                                    size={"large"}
                                                    loading={loading}
                                                    style={{
                                                        backgroundColor: appTheme.colors.primary_blue,
                                                        color: "white"
                                                    }}
                                                    icon={<SwapOutlined
                                                        style={{fontSize: '20px', marginRight: '5px'}}/>}
                                                    onClick={handleCalculClick}
                                                >
                                                    {!loading &&
                                                        <span className='indicator-label text-white'>RESULTATS</span>}
                                                    {loading && (
                                                        <span className='indicator-progress fs-9 text-white'
                                                              style={{display: 'block'}}>
                                              Patientez svp...
                                              <span
                                                  className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                                    )}
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </Col>}
                        </Row>
                        <Divider/>
                        <div className="d-flex flex-wrap align-items-center py-5">
                            <div className="me-10">
                                <Tooltip title={`Balance ${parseInt(isProvisoire) === 1 ? 'provisoire' : 'définitif'} ${mission.annee || "N"}`}>
                                    <Button
                                        size={"large"}
                                        loading={loading}
                                        style={{
                                            backgroundColor: appTheme.colors.primary,
                                            color: "white"
                                        }}
                                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faScaleBalanced}/>}
                                        onClick={() => handleModalOpen(1, selectedBalances, `${parseInt(isProvisoire) === 1 ? 'provisoire' : 'définitif'}`)}
                                    >
                                        <span className='indicator-label text-white fs-6 fw-bolder'>{`Balance ${parseInt(isProvisoire) === 1 ? 'provisoire' : 'définitif'} ${mission.annee || "N"}`}</span>
                                    </Button>
                                </Tooltip>
                            </div>
                            <div className="">
                                <Tooltip title={`Balance définitif ${parseInt(missionN_1?.annee) || 'N-1'}`}>
                                    <Button
                                        size={"large"}
                                        loading={loading}
                                        style={{
                                            backgroundColor: appTheme.colors.secondary,
                                            color: "white"
                                        }}
                                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faScaleBalanced}/>}
                                        onClick={() => handleModalOpen(2, balanceDefN_1, `définitif`)}
                                    >
                                        <span className='indicator-label text-white'>{`Balance définitif ${parseInt(missionN_1?.annee) || "N-1"}`}</span>
                                    </Button>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            {/* begin::Tab pane */}
                            <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`} role="tabpanel">
                                {/* begin::Products */}
                                <div className="table-responsive">
                                    {(compteBilan ?? []).length > 0 && <div className="card-header border-0 pt-2">
                                        <SearchHeader
                                            placeholder="Rechercher par la référence, le libellé..."
                                            // handleSearch={handleSearchChange}
                                        />
                                    </div>}
                                    <div className="py-4">
                                        <div className="table-responsive">
                                            <Table
                                                rowClassName={(record,) =>
                                                    `${record.color ? `bg-${record?.color}` : ''} h-3px p-0 m-0 lh-0.5`
                                                }
                                                columns={columnsResultat}
                                                rowKey={(record, index) => index}
                                                dataSource={(compteBilan ?? [])}
                                                pagination={tableParams.pagination}
                                                onChange={handleTableChange}
                                                loading={chargement || loading}
                                                rowSelection
                                                size={"small"}
                                                summary={(pageData) => {
                                                    let totalSDRan = 0;
                                                    let totalSCRan = 0;
                                                    let totalSDN_1 = 0;
                                                    let totalSCN_1 = 0;
                                                    let totalDiffSD = 0;
                                                    let totalDiffSC = 0;
                                                    pageData.forEach(({ SD_RAN, SC_RAN, SD_N1, SC_N1, DIFF_SD, DIFF_SC}) => {
                                                        totalSDRan += SD_RAN;
                                                        totalSCRan += SC_RAN;
                                                        totalSDN_1 += SD_N1
                                                        totalSCN_1 += SC_N1
                                                        totalDiffSD += DIFF_SD
                                                        totalDiffSC += DIFF_SC
                                                    });
                                                    return (
                                                        <>
                                                            <Table.Summary fixed>
                                                                <Table.Summary.Row>
                                                                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={2}>Total</Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={3} className='text-end'>
                                                                        <Text type="success">{''}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={4} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSDRan.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={5} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSCRan.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={6} className='text-end'>
                                                                        <Text type="success">{''}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={7} className='text-end'>
                                                                        <Text type="success">{''}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={8} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSDN_1.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={9} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSCN_1.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={10} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalDiffSD.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={11} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalDiffSC.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                </Table.Summary.Row>
                                                            </Table.Summary>
                                                        </>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* end::Products */}
                            </div>
                            <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} role="tabpanel">
                                {/* begin::Products */}
                                <div className="table-responsive">
                                    {(compteResultat ?? []).length > 0 && <div className="card-header border-0 pt-2">
                                        <SearchHeader
                                            placeholder="Rechercher par la référence, le libellé..."
                                            // handleSearch={handleSearchChange}
                                        />
                                    </div>}
                                    <div className="py-4">
                                        <div className="table-responsive">
                                            <Table
                                                rowClassName={(record,) =>
                                                    `${record.color ? `bg-${record?.color}` : ''} h-3px p-0 m-0 lh-0.5`
                                                }
                                                columns={columnsResultat}
                                                rowKey={(record, index) => index}
                                                dataSource={(compteResultat ?? [])}
                                                pagination={tableParams.pagination}
                                                onChange={handleTableChange}
                                                loading={chargement || loading}
                                                rowSelection
                                                size={"small"}
                                                summary={(pageData) => {
                                                    let totalSDRan = 0;
                                                    let totalSCRan = 0;
                                                    let totalSDN_1 = 0;
                                                    let totalSCN_1 = 0;
                                                    let totalDiffSD = 0;
                                                    let totalDiffSC = 0;
                                                    pageData.forEach(({ SD_RAN, SC_RAN, SD_N1, SC_N1, DIFF_SD, DIFF_SC}) => {
                                                        totalSDRan += SD_RAN;
                                                        totalSCRan += SC_RAN;
                                                        totalSDN_1 += SD_N1
                                                        totalSCN_1 += SC_N1
                                                        totalDiffSD += DIFF_SD
                                                        totalDiffSC += DIFF_SC
                                                    });
                                                    return (
                                                        <>
                                                            <Table.Summary fixed>
                                                                <Table.Summary.Row>
                                                                    <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={2}>Total</Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={3} className='text-end'>
                                                                        <Text type="success">{''}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={4} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSDRan.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={5} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSCRan.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={6} className='text-end'>
                                                                        <Text type="success">{''}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={7} className='text-end'>
                                                                        <Text type="success">{''}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={8} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSDN_1.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={9} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSCN_1.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={10} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalDiffSD.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                    <Table.Summary.Cell index={11} className='text-end'>
                                                                        <Text type="success" style={{fontSize: '12px'}}>{totalDiffSC.toLocaleString('fr')}</Text>
                                                                    </Table.Summary.Cell>
                                                                </Table.Summary.Row>
                                                            </Table.Summary>
                                                        </>
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* end::Products */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ModalAlert
                closeModal={handleModalOpen}
                show={isModalOpen}
                title={<ModalTitle>Balance {title || ""} {modalId === 1 ? (mission.annee || "N") : (missionN_1.annee || "N-1")} </ModalTitle>}
                footer={[]}
                modalStyles={{ top: 30 }}
                width={1000}
            >
                <div className="py-4">
                    <div className="table-responsive">
                        <Table
                            rowClassName={(record,) =>
                                `${record.color ? `bg-${record?.color}` : ''} h-3px p-0 m-0 lh-0.5`
                            }
                            columns={modalId === 1 ? columnsNModal : columnsN_1Modal}
                            rowKey={(record, index) => index}
                            dataSource={modalBalance}
                            pagination={false}
                            scroll={{ y: 500 }}
                            loading={chargement}
                            rowSelection
                            size={"small"}
                        />
                    </div>
                </div>
            </ModalAlert>
            {/*{(isModalOpen && modalId === 1) && (*/}
            {/*    <div*/}
            {/*        className="top-0 bottom-0 z-index-1000 h-100"*/}
            {/*        style={{ insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}*/}
            {/*    >*/}
            {/*        <div className="modal fade show" id="kt_modal_move_to_folder" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}>*/}
            {/*            <div className='modal-dialog modal-dialog-centered mw-1000px' >*/}
            {/*                <div className='modal-content'>*/}
            {/*                    <div className="modal-header">*/}
            {/*                        <h2 className="fw-bolder">{`Balance ${title}`}</h2>*/}
            {/*                        <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={handleModalClose}>*/}
            {/*                            <span className="svg-icon svg-icon-1">*/}
            {/*                              <CloseSquareOutlined style={{color: 'red'}}/>*/}
            {/*                            </span>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="card w-100">*/}
            {/*                        <div className='card-body'>*/}
            {/*                            <Table*/}
            {/*                                rowClassName={() =>*/}
            {/*                                    `bg-hover-light-primary h-3px p-0 m-0 lh-0.5`*/}
            {/*                                }*/}
            {/*                                columns={columnsNModal}*/}
            {/*                                rowKey={(record) => record.id}*/}
            {/*                                dataSource={selectedBalances}*/}
            {/*                                // loading={loading}*/}
            {/*                                pagination={false}*/}
            {/*                                size={"small"}*/}
            {/*                                scroll={{*/}
            {/*                                    y: 240,*/}
            {/*                                }}*/}
            {/*                                bordered*/}
            {/*                                summary={(pageData) => {*/}
            {/*                                    let totalSoldeCredit = 0;*/}
            {/*                                    let totalSoldeDebit = 0;*/}
            {/*                                    let totalSoldeN = 0;*/}
            {/*                                    pageData.forEach(({ anouveauDebit, anouveauCredit}) => {*/}
            {/*                                        totalSoldeDebit += anouveauDebit;*/}
            {/*                                        totalSoldeCredit += anouveauCredit;*/}
            {/*                                        totalSoldeN += anouveauDebit - anouveauCredit*/}
            {/*                                    });*/}
            {/*                                    return (*/}
            {/*                                        <>*/}
            {/*                                            <Table.Summary fixed>*/}
            {/*                                                <Table.Summary.Row>*/}
            {/*                                                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>*/}
            {/*                                                    <Table.Summary.Cell index={1} className='text-end'>*/}
            {/*                                                        <Text type="success">{""}</Text>*/}
            {/*                                                    </Table.Summary.Cell>*/}
            {/*                                                    <Table.Summary.Cell index={2} className='text-end'>*/}
            {/*                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSoldeDebit.toLocaleString('fr')}</Text>*/}
            {/*                                                    </Table.Summary.Cell>*/}
            {/*                                                    <Table.Summary.Cell index={3} className='text-end'>*/}
            {/*                                                        <Text type="success" style={{fontSize: '12px'}} >{totalSoldeDebit.toLocaleString('fr')}</Text>*/}
            {/*                                                    </Table.Summary.Cell>*/}
            {/*                                                    <Table.Summary.Cell index={3} className='text-end'>*/}
            {/*                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSoldeN}</Text>*/}
            {/*                                                    </Table.Summary.Cell>*/}
            {/*                                                </Table.Summary.Row>*/}
            {/*                                            </Table.Summary>*/}
            {/*                                        </>*/}
            {/*                                    );*/}
            {/*                                }}*/}
            {/*                            />*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{(isModalOpen && modalId === 2) && (*/}
            {/*    <div*/}
            {/*        className="top-0 bottom-0 z-index-1000 h-100"*/}
            {/*        style={{ insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}*/}
            {/*    >*/}
            {/*        <div className="modal fade show" id="kt_modal_move_to_folder" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}>*/}
            {/*            <div className='modal-dialog modal-dialog-centered mw-1000px' >*/}
            {/*                <div className='modal-content'>*/}
            {/*                    <div className="modal-header">*/}
            {/*                        <h2 className="fw-bolder">{`Balance ${title}`}</h2>*/}
            {/*                        <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={handleModalClose}>*/}
            {/*                            <span className="svg-icon svg-icon-1">*/}
            {/*                              <CloseSquareOutlined style={{color: 'red'}}/>*/}
            {/*                            </span>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="card w-100">*/}
            {/*                        <div className='card-body'>*/}
            {/*                            <Table*/}
            {/*                                rowClassName={() =>*/}
            {/*                                    `bg-hover-light-primary h-3px p-0 m-0 lh-0.5`*/}
            {/*                                }*/}
            {/*                                columns={columnsN_1Modal}*/}
            {/*                                rowKey={(record) => record.id}*/}
            {/*                                dataSource={balanceDefN_1}*/}
            {/*                                // loading={loading}*/}
            {/*                                pagination={false}*/}
            {/*                                size={"small"}*/}
            {/*                                scroll={{*/}
            {/*                                    y: 240,*/}
            {/*                                }}*/}
            {/*                                bordered*/}
            {/*                                summary={(pageData) => {*/}
            {/*                                    let totalSoldeCredit = 0;*/}
            {/*                                    let totalSoldeDebit = 0;*/}
            {/*                                    let totalSoldeN = 0;*/}
            {/*                                    pageData.forEach(({ soldeDebit, soldeCredit}) => {*/}
            {/*                                        totalSoldeDebit += soldeDebit;*/}
            {/*                                        totalSoldeCredit += soldeCredit;*/}
            {/*                                        totalSoldeN += soldeDebit - soldeCredit*/}
            {/*                                    });*/}
            {/*                                    return (*/}
            {/*                                        <>*/}
            {/*                                            <Table.Summary fixed>*/}
            {/*                                                <Table.Summary.Row>*/}
            {/*                                                    <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>*/}
            {/*                                                    <Table.Summary.Cell index={1} className='text-end'>*/}
            {/*                                                        <Text type="success">{""}</Text>*/}
            {/*                                                    </Table.Summary.Cell>*/}
            {/*                                                    <Table.Summary.Cell index={2} className='text-end'>*/}
            {/*                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSoldeDebit.toLocaleString('fr')}</Text>*/}
            {/*                                                    </Table.Summary.Cell>*/}
            {/*                                                    <Table.Summary.Cell index={3} className='text-end'>*/}
            {/*                                                        <Text type="success" style={{fontSize: '12px'}} >{totalSoldeDebit.toLocaleString('fr')}</Text>*/}
            {/*                                                    </Table.Summary.Cell>*/}
            {/*                                                    <Table.Summary.Cell index={3} className='text-end'>*/}
            {/*                                                        <Text type="success" style={{fontSize: '12px'}}>{totalSoldeN}</Text>*/}
            {/*                                                    </Table.Summary.Cell>*/}
            {/*                                                </Table.Summary.Row>*/}
            {/*                                            </Table.Summary>*/}
            {/*                                        </>*/}
            {/*                                    );*/}
            {/*                                }}*/}
            {/*                            />*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
        </>
    );
}

export {IntangibiliteForm}