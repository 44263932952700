import React, { useState } from 'react';
import { Table } from 'antd';

const DynamicTable = ({ columns, dataSource, loading: externalLoading, onRowClickHandler, tableLength }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);

    const onRowClick = (record, index) => {
        setLoading(true);
        onRowClickHandler(record).finally(() => {
            setLoading(false);
        });

        setSelectedRowKeys([index]);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys) => {
            setSelectedRowKeys(selectedRowKeys);
        },
    };

    return (
        <div className="table-responsive">
            <Table
                onRow={(record, index) => {
                    return {
                        onClick: () => onRowClick(record, index),
                    };
                }}
                rowClassName={(record, index) =>
                    selectedRowKeys.includes(index) ? 'cursor-pointer h-3px p-0 m-0 lh-0.5 highlighted-row' : 'cursor-pointer h-3px p-0 m-0 lh-0.5'
                }
                columns={columns}
                rowKey={(record, index) => index}
                dataSource={dataSource ?? []}
                pagination={false}
                // scroll={{ y: tableLength || 400 }}
                loading={externalLoading || loading}
                rowSelection={{
                    type: 'radio',
                    ...rowSelection,
                }}
            />
        </div>
    );
};

export default DynamicTable;
