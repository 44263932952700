import React, {useEffect, useState} from 'react';
import { GeneraliteInfos } from "../../../components/client/GeneraliteInfos";
import { SiegeSocialInfos } from "../../../components/client/SiegeSocial";
import { AutresInfos } from "../../../components/client/AutresInfos";
import {useFormik} from "formik";
import * as Yup from "yup";
import {addClient} from "../../../data/client";
import {useNavigate} from "react-router-dom";
import {getListeSecteur} from "../../../data/params/secteur";
import {getListeStatut} from "../../../data/params/statut";
import swal from "sweetalert";

const initialValues = {
    sigle: '',
    denomination: '',
    forme_juridique: '',
    libelle_exercie: '',
    date_constitution: null,
    numero_rccm: '',
    duree_statutaire: null,
    numero_cc: '',
    centre_impots: '',
    numero_cnps_employeur: '',
    numero_agrement: '',
    localisation: '',
    adresse_postale: '',
    cel1: '',
    cel2: '',
    email: '',
    site_internet: '',
    actionnaire_majoritaire: '',
    nom_dirigeant: '',
    expert_comptable: '',
    conseil_juridique: '',
    commissaire_compte: '',
    associe_signataire: '',
    statut_id: null,
    secteur_id: null
};


const ClientAdd = () => {
    const [activeTab, setActiveTab] = useState('general');
    const navigate = useNavigate()
    const [statuts, setStatuts] = useState([])
    const [secteurs, setSecteurs] = useState([])
    const [loading, setLoading] = useState(false)

    const fetchSecteurs = () => {
        setLoading(true);
        getListeSecteur()
            .then((res) => {
                setSecteurs(res.result);
                setLoading(false);
            });
    };

    const fetchStatuts = () => {
        setLoading(true);
        getListeStatut()
            .then((res) => {
                setStatuts(res.result);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchStatuts();
        fetchSecteurs()
    }, [setStatuts, setSecteurs]);

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };

    const validationSchema = Yup.object().shape({
        sigle: Yup.string().required('Le sigle du client est requis'),
        denomination: Yup.string().required('La dénomination sociale est requise'),
        statut_id: Yup.number().required('La forme juridique est requise'),
        secteur_id: Yup.number().required('Le secteur d\'activité est requis'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setSubmitting(true)
            try {
                const {message, status} =  await addClient(values)
                if (status === 'error') {
                    setStatus(message)
                    await swal(message, '', 'error')
                }
                else{
                    await swal(`Opération effectuée avec succès`, '', 'success')
                    if(activeTab === "autresInfos"){
                        navigate('/clients/liste')
                    }
                }
            } catch (error) {
                console.error('Erreur:', error);
            }
        },
    })

    return (
        <>
            <div className="flex-lg-row-fluid ms-lg-15">
                {/* begin::Tabs */}
                <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
                    {/* begin::Tab item */}
                    <li className="nav-item">
                        <button
                            className={`nav-link text-active-primary pb-4 ${activeTab === 'general' ? 'active' : ''}`}
                            onClick={() => handleTabChange('general')}
                        >
                            General
                        </button>
                    </li>
                    {/* end::Tab item */}
                    {/* begin::Tab item */}
                    <li className="nav-item">
                        <button
                            className={`nav-link text-active-primary pb-4 ${activeTab === 'siegeSocial' ? 'active' : ''}`}
                            onClick={() => handleTabChange('siegeSocial')}
                        >
                            Siege Social
                        </button>
                    </li>
                    {/* end::Tab item */}
                    {/* begin::Tab item */}
                    <li className="nav-item">
                        <button
                            className={`nav-link text-active-primary pb-4 ${activeTab === 'autresInfos' ? 'active' : ''}`}
                            onClick={() => handleTabChange('autresInfos')}
                        >
                            Autres informations
                        </button>
                    </li>
                    {/* end::Tab item */}
                </ul>
                {/* end::Tabs */}
                {/* begin::Tab content */}

                {formik.status ? (
                    <div className='mb-lg-15 alert alert-danger'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                ) : (
                    ''
                )}
                <form className="stepper-form" onSubmit={formik.handleSubmit}>

                    <div className="tab-content" id="myTabContent">
                        {/* begin::Tab pane */}
                        <div className={`tab-pane fade ${activeTab === 'general' ? 'show active' : ''}`} id="kt_ecommerce_settings_general" role="tabpanel">
                            {/* begin::Products */}
                            <GeneraliteInfos formik={formik} statuts={statuts} secteurs={secteurs} />
                            {/* end::Products */}
                        </div>
                        {/* end::Tab pane */}
                        {/* begin::Tab pane */}
                        <div className={`tab-pane fade ${activeTab === 'siegeSocial' ? 'show active' : ''}`} id="kt_ecommerce_settings_store" role="tabpanel">
                            {/* begin::Products */}
                            <SiegeSocialInfos formik={formik} />
                            {/* end::Products */}
                        </div>
                        {/* end::Tab pane */}
                        {/* begin::Tab pane */}
                        <div className={`tab-pane fade ${activeTab === 'autresInfos' ? 'show active' : ''}`} id="kt_ecommerce_settings_localization" role="tabpanel">
                            {/* begin::Products */}
                            <AutresInfos formik={formik} />
                            {/* end::Products */}
                        </div>
                        {/* end::Tab pane */}
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-users-modal-action='submit'
                            disabled={formik.isSubmitting || !formik.touched}
                        >
                            <span className='indicator-label'>Enregistrer</span>
                            {formik.isSubmitting && (
                                <span className='indicator-progress'>
                                    Veillez patientez...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                        <button
                            type='submit'
                            className='btn btn-danger ms-5'
                            onClick={() => navigate('/clients/liste')}
                        >
                            <span className='indicator-label'>Quitter</span>
                        </button>
                    </div>
                </form>

                {/* end::Tab content */}
            </div>
        </>
    )
}

export { ClientAdd };
