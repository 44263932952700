import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageTitle} from "../../components/layout/pageData/PageData";
import ClientExploitationFiche from "../clients/client/ClientExploitationFiche";
import React from "react";
import MissionExploitationPage from "./exercice/MissionExploitationPage";

const ExercicesPage = () => {

    const Breadcrumbs = [
        {
            title: 'Accueil',
            path: '/tableau-de-bord',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
        {
            title: 'Clients',
            path: '/clients/liste',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },

    ]

    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route
                    path='exploitation/fiche/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Fiche d'exploitation client</PageTitle>
                            <ClientExploitationFiche/>
                        </>
                    }
                />
                <Route
                    path='mission/exploitation/:id'
                    element={
                        <>
                            <PageTitle breadcrumbs={Breadcrumbs}>Dossiers de la mission</PageTitle>
                            <MissionExploitationPage/>
                        </>
                    }
                />
                <Route index element={<Navigate to='/client/exercice/exploitation/fiche/:id' />} />
            </Route>
        </Routes>
    )
}

export default ExercicesPage
