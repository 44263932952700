import React, {Fragment, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import PageLabel from "../../../components/common/PageLabel/PageLabel";
import {HStack} from "@chakra-ui/react";
import {ClientExploitationMission} from "../../clients/client/ClientExploitationMission";
import MissionSommaireTypePage from "./MissionSommaireTypePage";
import {FormContainer} from "../../../components/common/common.components";
import {getListeClientSommaire} from "../../../data/exercice";
import ModalToolbarHeader from "../../../components/modals/ModalToolbarHeader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Tag, Typography} from "antd";
import {faCheckCircle, faExclamationCircle, faWarning} from "@fortawesome/free-solid-svg-icons";
import {appTheme} from "../../../config/theme";

const {Title} = Typography;

const MissionExploitationPage = () => {
    const location = useLocation();

    const client = location.state.record;
    const mission = location.state.mission;

    const [activeStep, setActiveStep] = useState(0);
    const [clientSommaire, setClientSommaire] = useState([]);
    const [code, setCode] = useState("");
    const [modalId, setModalId] = useState(null);


    const fetchSommaireClient = async () =>{
        getListeClientSommaire(mission.id)
            .then((res) => {
                if(res){
                    setClientSommaire(res);
                }
            })
            .catch((error) => {
                console.error("Erreur", error);
            });
    }

    useEffect(() => {
        if(mission){
            fetchSommaireClient()
        }
    }, [mission])

    const HandleNextStep = () => {
        setActiveStep(activeStep + 1);
    };

    const handlePreviousStep = () => {
        setActiveStep(activeStep - 1);

        if(mission){
            fetchSommaireClient()
        }
    };

    const steps = [
        {
            id: 1,
            content: (
                <ClientExploitationMission
                    client={client}
                    missionSelected={mission}
                    current={activeStep}
                    next={HandleNextStep}
                    prev={handlePreviousStep}
                    setCode={setCode}
                    setModalId={setModalId}
                    clientSommaire={clientSommaire}
                />
            ),
        },
        {
            id: 2,
            content: (
                <MissionSommaireTypePage
                    mission={mission}
                    code={code}
                    client={client}
                    modalId={modalId}
                    prev={handlePreviousStep}
                    next={HandleNextStep}
                />
            ),
        },
    ];

    return (
        <Fragment>
            <div className="d-flex flex-wrap align-center justify-content-lg-between">
                <HStack justifyContent="space-between" alignItems="center">
                    <div className="d-flex">
                        <div className="">
                            <Title
                                style={{fontSize: "40px", fontWeight: "700", fontFamily: "Oswald, sans-serif", color: appTheme.colors.primary_blue}}
                            >
                                {(client?.sigle || "")}
                            </Title>
                        </div>
                        <span className="fs-1 fw-boldest mx-2 text-warning">-</span>
                        <PageLabel label={`Fiche exploitation ${mission.mission} - ${mission.annee}`}/>
                        {(mission.isCloture === 1 || mission.isClotureProvisoire === 1) && <div className="pt-2">
                            <Tag icon={<FontAwesomeIcon icon={mission.isCloture === 1 ? faCheckCircle : faExclamationCircle} style={{marginRight: "5px"}}/>}
                                 color={mission.isCloture === 1 ? "green" : "blue"} style={{marginLeft: "15px", fontSize: "16px", fontWeight: "600"}}>
                                {mission.isCloture === 1 ? "Clôturé" : "Cloture provisoire"}
                            </Tag>
                        </div>}
                    </div>
                </HStack>
                {code && <ModalToolbarHeader codeSommaire={code} client={client} mission={mission}/>}
            </div>
            <Col span={24}>
                {(mission.isCloture === 1 || mission.isClotureProvisoire === 1) && <div className="d-flex align-items-center">
                    <div className="bg-warning fs-5 p-2 w-100">
                        <span style={{fontFamily: "Oswald", fontWeight: 600, marginRight: "15px"}}>
                            <FontAwesomeIcon icon={faWarning} style={{marginRight: "5px"}}/> MISSION CLÔTUREE :
                        </span>
                        <span className="text-decoration-underlined">Aucun traitement ou action ne sera pris en compte en dehors de l'accès aux fichiers et documents associés à cette mission.</span>
                    </div>
                </div>}
            </Col>
            <FormContainer>
                <div className="tab-content" id="myTabContent">
                    {steps.map((item, index) => (
                        <div
                            key={index}
                            className={`tab-pane fade ${activeStep === index ? 'show active' : ''}`}
                            id={`tab-${index}`}
                            role="tabpanel"
                        >
                            {steps[activeStep].content}
                        </div>
                    ))}
                </div>
            </FormContainer>
        </Fragment>
    )
}

export default MissionExploitationPage;