import React, {useEffect, useState} from "react";
import {appTheme} from "../../../config/theme";
import {Button, notification} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faDeleteLeft, faListCheck} from "@fortawesome/free-solid-svg-icons";
import {SearchHeader} from "../../layout/header/HeaderList";
import {getListeSommaireActive} from "../../../data/params/sommaire";
import {getListeClientSommaire, modifierSommaireExecice} from "../../../data/exercice";
import {ModalTitle} from "../modals.components";
import ModalAlert from "../Modal";
import {Spinner} from "@chakra-ui/react";
import swal from "sweetalert";

const ChangeMissionSommaire = ({
       handleVisibility,
       visible,
       mission,
       fetchData,
       chargement
    }) => {
    const [loading, setLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [filtersItems, setFiltersItems] = useState( []);
    const [sommaires, setSommaires] = useState( []);
    const [sommairesTables, setSommairesTables] = useState( []);

    const fetchSommaire = async () => {
        getListeSommaireActive()
            .then((res) => {
                // setSommaires((res.result ?? []).sort((a, b) => a.libelle.localeCompare(b.libelle)));
                // setFiltersItems((res.result ?? []).sort((a, b) => a.libelle.localeCompare(b.libelle)));
                setSommaires((res.result ?? []).sort((a, b) => parseInt(a.libelle) - parseInt(b.libelle)));
                setFiltersItems((res.result ?? []).sort((a, b) => parseInt(a.libelle) - parseInt(b.libelle)));

            })
            .catch((error) => {
                console.error("Erreur", error);
            });
    };

    useEffect(() => {
        fetchSommaire()
    }, []);
    const fetchMissionSommaire = async (mission_id) => {
        getListeClientSommaire(mission_id)
            .then((res) => {
                if(res)
                setSommairesTables(res);
            })
            .catch((error) => {
                console.error("Erreur", error);
            });
    };

    useEffect(() => {
        if(mission){
            fetchMissionSommaire(mission.id)
        }
    }, [mission]);

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = sommaires.filter(
            (item) =>
                item.libelle?.toLowerCase().includes(searchTerm) ||
                item.description.toString()?.toLowerCase().startsWith(searchTerm)
        );
        setFiltersItems(filtered);
    };

    const handleCheckboxChange = (sommaireId) => {
        const sommaire = sommaires.find((item) => item.id === sommaireId);
        if(sommaire){
            const isSelected = sommairesTables.some((item) => item.sommaire_id === sommaireId);
            if (isSelected) {
                setSommairesTables(prevSelected => prevSelected.filter(item => item.sommaire_id !== sommaireId));
            } else {
                const element = {
                    sommaire_id: sommaire.id,
                    libelle: sommaire.libelle,
                    description: sommaire.description,
                    isComplete: !!(sommaire.libelle?.includes("EE0") || sommaire.libelle?.includes("ED0") || sommaire.libelle?.includes("EB2") || sommaire.libelle?.includes("EC1"))
                }
                setSommairesTables(prevSelected => [...prevSelected, element]);
            }
        }
    };

    const onSelectedAll = () => {

        setLoading(true);
        setTimeout(() => {
            sommaires.forEach((som) => {
                const isSelected = sommairesTables.some((item) => item.sommaire_id === som.id);
                if (!isSelected) {
                    const element = {
                        sommaire_id: som.id,
                        libelle: som.libelle,
                        description: som.description,
                        isComplete: !!(som.libelle?.includes("EE0") || som.libelle?.includes("ED0") || som.libelle?.includes("EB2") || som.libelle?.includes("EC1"))
                    }
                    setSommairesTables(prevSelected => [...prevSelected, element]);
                }
            })
            setLoading(false);
            setSelectAll(true);
        }, 1000)
    };

    const handleDeleteAllElements = () => {
        setLoading(true);
        setTimeout(() => {
            setSommairesTables([])
            setSelectAll(false);
            setLoading(false);
        }, 1000)
    };

    const handleMofifierSommaireMission = () => {
        setLoading(true);
        const data = {
            exercice_id: mission.id,
            sommaires: sommairesTables,
        };

        modifierSommaireExecice(data)
            .then(async (res) => {
                const {status, message} = res;
                if (status === 'success') {
                    setLoading(false)
                    fetchData(mission.id);
                    handleVisibility();
                    await swal(`Opération effectuée avec succès`, '', 'success')
                } else {
                    await swal(message, '', 'error')
                    setLoading(false)
                }
            })
            .catch((err) => {
                notification.error({
                    description: err,
                });
                setLoading(false)
            })
    }

    return (
        <ModalAlert
            closeModal={handleVisibility}
            show={visible}
            title={<ModalTitle> Gestion des sommaire de l'exercice {mission.mission || ""} - {mission.annee || ""}</ModalTitle>}
            footer={[]}
            modalStyles={{ top: 15 }}
            width={600}
        >
            <div className='' >
                <div className=''>
                    <form className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#"
                          id="kt_modal_move_to_folder_form">
                        <div className="modal-body pt-5 pb-5 px-lg-5">
                            {selectAll ?  (
                                    <div className='py-5'>
                                        <Button
                                            size={"middle"}
                                            style={{backgroundColor: appTheme.colors.danger}}
                                            onClick={handleDeleteAllElements}
                                            icon={<FontAwesomeIcon icon={faDeleteLeft} color={"white"}/>}
                                        >
                                            <span className="fw-bolder text-white">Supprimer tout</span>
                                        </Button>
                                    </div>
                                )
                                :
                                (
                                    <div className='py-5'>
                                        <Button
                                            size={"middle"}
                                            style={{backgroundColor: appTheme.colors.primary_blue}}
                                            onClick={onSelectedAll}
                                            icon={<FontAwesomeIcon icon={faListCheck} color={"white"}/>}
                                        >
                                            <span className="fw-bolder text-white">Tout sélectionner</span>
                                        </Button>
                                    </div>
                                )
                            }
                            {(filtersItems ?? []).length > 0 && <div className="card-header border-0 pt-2">
                                <SearchHeader
                                    placeholder="Rechercher par le code, le libellé..."
                                    handleSearch={handleSearchChange}
                                />
                            </div>}
                            <br/>
                            {(loading || chargement) ?
                                <div className="d-flex justify-content-center align-items-center">
                                    <Spinner/>
                                </div>
                                :
                                <div className='mh-400px scroll-y me-n3 pe-3'>
                                <div className="form-group fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                    {/*begin::Item*/}
                                    {(filtersItems ??  []).map((sommaire, index) => (
                                        <>
                                            <div className="d-flex" key={index}>
                                                {/*begin::Checkbox*/}
                                                <div className="form-check form-check-custom">
                                                    {/*begin::Input*/}
                                                    <input
                                                        className="form-check-input me-3"
                                                        name="move_to_folder"
                                                        type="checkbox"
                                                        value={sommaire.id}
                                                        id={`kt_modal_move_to_folder_${index}`}
                                                        onChange={() => handleCheckboxChange(sommaire.id)}
                                                        checked={(sommairesTables ?? []).some(item => item.sommaire_id === sommaire.id)}
                                                    />
                                                    {/*end::Input*/}
                                                    {/*begin::Label*/}
                                                    <label className="form-check-label" htmlFor="kt_modal_move_to_folder_0">
                                                        <div className="text-gray-800 fs-15">
                                                            {`${sommaire.libelle} - ${sommaire.description}`}
                                                        </div>
                                                    </label>
                                                    {/*end::Label*/}
                                                </div>
                                                {/*end::Checkbox*/}
                                            </div>
                                            {/*end::Item*/}
                                            <div className="separator separator-dashed my-5"></div>
                                        </>
                                    ))}
                                </div>
                                {/*end::Item*/}
                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>}
                            {/*end::Input group*/}
                            {/*begin::Action buttons*/}
                            <div className=" card-footer d-flex flex-center mt-3">
                                {/*begin::Button*/}
                                <Button
                                    type="primary"
                                    block
                                    size="large"
                                    loading={loading || chargement}
                                    disabled={(loading || chargement)}
                                    onClick={handleMofifierSommaireMission}
                                >
                                    {!(loading || chargement) && <span className='indicator-label'>Valider</span>}
                                    {(loading || chargement) && (
                                        <span className="indicator-progress text-white">
                                        Veuillez patienter...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                    </span>
                                    )}
                                </Button>
                                {/*end::Button*/}
                            </div>
                            {/*begin::Action buttons*/}
                        </div>
                        {/*end::Modal body*/}
                        <div></div>
                    </form>
                </div>
            </div>
        </ModalAlert>

    );
};

export {ChangeMissionSommaire}