import axios from "axios";

const AJOUTER_RISQUE_BLANCHIMENT = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/texte-blanchiment/ajouter`
const LISTE_RISQUE_TEXTE_BLANCHIMENT = `${process.env.REACT_APP_API_URL}/lea/exercice/risque-anomalie/texte-blanchiment`

export const ajouterRisqueTextBlanchiment = async (data) => {
    return axios.post(AJOUTER_RISQUE_BLANCHIMENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeRisqueTextBlanchiment = async (exercice_id) => {
    return axios.get(`${LISTE_RISQUE_TEXTE_BLANCHIMENT}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}