import React, {Fragment, useState} from "react";
import {Button, Divider, Space, Tooltip} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBook,
    faCalendarDay,
    faFolderOpen,
    faPaste, faSheetPlastic,
    faSliders
} from "@fortawesome/free-solid-svg-icons";
import {appTheme} from "../../config/theme";
import {ModeleSommaire} from "../client/outils/files/ModèleSommaire";
import {ListeFileSommaire} from "../client/outils/files/ListeFileSommaire";
import {NormesISA} from "../client/outils/files/NormesISA";
import {RateauxSommaire} from "../client/outils/RateauxSommaire";
import {NextControleSommaire} from "../client/outils/NextControleSommaire";
import {ModalPlanComptable} from "../client/outils/ModalPlanComptable";

const ButtonStyles = {
    backgroundColor: "white",
    border: "none",
    marginRight: "15px",
    color: appTheme.colors.primary_blue
}

const ModalToolbarHeader = ({codeSommaire, client, mission}) => {
    const [modalId, setModalId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleModalOpen = (id) => {
        setModalId(id);
        setIsModalOpen(prevState => !prevState);
    }

    return (
        <Fragment>
            <div
                style={{borderRadius: "15px", backgroundColor: "white", padding: "20px"}}
            >
                <Space>
                    <Tooltip color={appTheme.colors.primary_blue} title={`Modèles du cycle ${codeSommaire || ""}`}>
                        <Button
                            className="button-toolbar-left"
                            icon={<FontAwesomeIcon size={"2x"} icon={faPaste}/> }
                            style={ButtonStyles}
                            onClick={() => handleModalOpen(1)}
                        />
                    </Tooltip>
                    <Tooltip color={appTheme.colors.primary_blue} title={`Documents du cycle ${codeSommaire || ""}`}>
                        <Button
                            className="button-toolbar-left"
                            icon={<FontAwesomeIcon size={"2x"} icon={faFolderOpen}/> }
                            style={ButtonStyles}
                            onClick={() => handleModalOpen(2)}
                        />
                    </Tooltip>
                    <Tooltip color={appTheme.colors.primary_blue} title={`Normes ISA du cycle ${codeSommaire || ""}`}>
                        <Button
                            className="button-toolbar-left"
                            icon={<FontAwesomeIcon size={"2x"} icon={faBook}/> }
                            style={ButtonStyles}
                            onClick={() => handleModalOpen(3)}
                        />
                    </Tooltip>
                    <Tooltip color={appTheme.colors.primary_blue} title={`Plan comptable`}>
                        <Button
                            className="button-toolbar-left"
                            icon={<FontAwesomeIcon size={"2x"} icon={faSheetPlastic}/> }
                            style={ButtonStyles}
                            onClick={() => handleModalOpen(6)}
                        />
                    </Tooltip>
                    <Divider type={"vertical"} style={{ fontSize: "30px", marginRight: "5px" }} />
                    <Tooltip color={appTheme.colors.primary} title={`Râteaux du cycle ${codeSommaire || ""}`}>
                        <Button
                            className="button-toolbar-right"
                            icon={<FontAwesomeIcon size={"2x"} icon={faSliders}/> }
                            style={{...ButtonStyles, color: appTheme.colors.primary}}
                            onClick={() => handleModalOpen(4)}
                        />
                    </Tooltip>
                    <Tooltip color={appTheme.colors.primary} title={`Prochain contrôle du cycle ${codeSommaire || ""}`}>
                        <Button
                            className="button-toolbar-right"
                            icon={<FontAwesomeIcon size={"2x"} icon={faCalendarDay}/> }
                            style={{...ButtonStyles, color: appTheme.colors.primary}}
                            onClick={() => handleModalOpen(5)}
                        />
                    </Tooltip>
                </Space>
            </div>
            {isModalOpen && modalId === 1 &&(
                <ModeleSommaire
                    handleVisibility={handleModalOpen}
                    client={client}
                    mission={mission}
                    visible={isModalOpen}
                    codeSommaire={codeSommaire}
                />
            )}
            {isModalOpen && modalId === 2 &&(
                <ListeFileSommaire
                    handleVisibility={handleModalOpen}
                    client={client}
                    mission={mission}
                    visible={isModalOpen}
                    codeSommaire={codeSommaire}
                />
            )}
            {isModalOpen && modalId === 3 &&(
                <NormesISA
                    handleVisibility={handleModalOpen}
                    client={client}
                    mission={mission}
                    visible={isModalOpen}
                    codeSommaire={codeSommaire}
                />
            )}
            {isModalOpen && modalId === 4 &&(
                <RateauxSommaire
                    handleVisibility={handleModalOpen}
                    client={client}
                    mission={mission}
                    visible={isModalOpen}
                    codeSommaire={codeSommaire}
                />
            )}
            {isModalOpen && modalId === 5 &&(
                <NextControleSommaire
                    handleVisibility={handleModalOpen}
                    client={client}
                    mission={mission}
                    visible={isModalOpen}
                    codeSommaire={codeSommaire}
                />
            )}
            {isModalOpen && modalId === 6 &&(
                <ModalPlanComptable
                    handleVisibility={handleModalOpen}
                    client={client}
                    mission={mission}
                    visible={isModalOpen}
                    codeSommaire={codeSommaire}
                />
            )}
        </Fragment>
    )
}

export default ModalToolbarHeader;