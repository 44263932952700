import axios from "axios";

/** Debut::Contrôle balance */
const AJOUTER_CONTROLE_BALANCE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/ajouter-controle-balance`
const AJOUTER_CONTROLE_BALANCE_COMMENTAIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/ajouter-commentaire-controle-balance`
const LISTE_CONTROLE_BALANCE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/liste-controle-balance`
/** Fin::Contrôle balance*/

/** Debut::FEUILLE MAITRESSE */
const AJOUTER_FEUILLE_MAITRESSE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/ajouter-feuille-maitresse`
const AJOUTER_FEUILLE_MAITRESSE_COMMENTAIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/ajouter-commentaire-feuille-maitresse`
const LISTE_FEUILLE_MAITRESSE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/liste-feuille-maitresse`
/** Fin::FEUILLE MAITRESSE*/

/** Debut::EXAMEN_ANALYTIQUE */
const AJOUTER_EXAMEN_ANALYTIQUE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/ajouter-examen-analytique`
const LISTE_EXAMEN_ANALYTIQUE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/liste-examen-analytique`
/** Fin::FEUILLE MAITRESSE*/

/** Debut::CONTRÔLE INTANGIBILITE */
const AJOUTER_CONTROLE_INTANGIBILITE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/ajouter-controle-intangibilite`
const AJOUTER_TRAVAUX_INTANGIBILITE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/ajouter-travaux-controle-intangibilite`
const LISTE_CONTROLE_INTANGIBILITE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/liste-controle-intangibilite`
const LISTE_TRAVAUX_INTANGIBILITE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/liste-travaux-intangibilite`
/** Fin::CONTRÔLE INTANGIBILITE*/

/** Debut::CONTRÔLE TESTS JOURNAUX */
const AJOUTER_CONTROLE_TEST_JOURNAUX = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/test-journaux/ajouter`
const AJOUTER_CONTROLE_TEST_JOURNAUX_COMMENTAIRE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/ajouter-commentaire-test-journaux`
const LISTE_CONTROLE_TEST_JOURNAUX = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-analytique/test-journaux`
/** Fin::CONTRÔLE TESTS JOURNAUX*/

/** Debut::Contrôle balance */
export const ajouterControleBalanceProvDef = async (data) => {
    return axios.post(AJOUTER_CONTROLE_BALANCE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterControleBalanceCommentaire = async (data) => {
    return axios.post(AJOUTER_CONTROLE_BALANCE_COMMENTAIRE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const listeControleBalanceProvDef = async (exercice_id) => {
    return axios.get(`${LISTE_CONTROLE_BALANCE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}
/** Fin::Contrôle balance */


/** Debut::EXAMEN_ANALYTIQUE */
export const ajouterExamenAnalytique = async (data) => {
    return axios.post(AJOUTER_EXAMEN_ANALYTIQUE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const listeExamenAnalytique = async (exercice_id) => {
    return axios.get(`${LISTE_EXAMEN_ANALYTIQUE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}
/** Fin::EXAMEN_ANALYTIQUE*/

/** Debut::FEUILLE MAITRESSE */
export const ajouterFeuilleMaitresse = async (data) => {
    return axios.post(AJOUTER_FEUILLE_MAITRESSE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterFeuilleMaitresseCommentaire = async (data) => {
    return axios.post(AJOUTER_FEUILLE_MAITRESSE_COMMENTAIRE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const listeFeuilleMaitresse = async (exercice_id) => {
    return axios.get(`${LISTE_FEUILLE_MAITRESSE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}
/** Fin::FEUILLE MAITRESSE*/


/** Debut::CONTRÔLE INTANGIBILITE  */
export const ajouterControleIntangibilite = async (data) => {
    return axios.post(AJOUTER_CONTROLE_INTANGIBILITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterTravauxIntangibilte = async (data) => {
    return axios.post(AJOUTER_TRAVAUX_INTANGIBILITE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const listeControleIntangibilite = async (exercice_id) => {
    return axios.get(`${LISTE_CONTROLE_INTANGIBILITE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const listeTravauxIntangibilite = async (exercice_id) => {
    return axios.get(`${LISTE_TRAVAUX_INTANGIBILITE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}
/** Fin::CONTRÔLE INTANGIBILITE*/

/** Debut::CONTRÔLE TEST_JOURNAUX  */
export const ajouterControleTestJournaux = async (data) => {
    return axios.post(AJOUTER_CONTROLE_TEST_JOURNAUX, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

// export const ajouterControleTestJournauxCommentaire = async (data) => {
//     return axios.post(AJOUTER_CONTROLE_TEST_JOURNAUX_COMMENTAIRE, data).then(res => {
//         if (res.status === 200) {
//             return res.data
//         } else {
//             return {
//                 status: 'error',
//                 message: "La connexion à internet est instable !"
//             }
//         }
//     }).catch(err => console.log(err))
// }

export const listeControleTestJournaux = async (exercice_id) => {
    return axios.get(`${LISTE_CONTROLE_TEST_JOURNAUX}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}
/** Fin::CONTRÔLE TEST_JOURNAUX*/

