import axios from "axios";
import {
    AJOUTER_RATEAU, LISTE_IDENTIFICATION_MISSION,
    LISTE_IDENTIFICATION_SOMMAIRE,
    LISTE_RATEAUX,
    LISTE_RATEAUX_NEXT_CONTROLE
} from "../../api/apiRoutes";

const API_URL = process.env.REACT_APP_API_URL

const AJOUTER_USER_IDENTIFICATION = `${process.env.REACT_APP_API_URL}/lea/exercice/user-identification/ajouter`
const VALIDATE_RATEAU_SOMMAIRE = `${API_URL}/lea/exercice/rateau/validate`

export const ajouterRateau = async (data) => {
    return axios.post(AJOUTER_RATEAU, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const validateRateau = async (data) => {
    return axios.post(VALIDATE_RATEAU_SOMMAIRE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterUserIdentification = async (data) => {
    return axios.post(AJOUTER_USER_IDENTIFICATION, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeRateau = async () => {
    return axios.get(LISTE_RATEAUX).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeRateauNextControle = async () => {
    return axios.get(LISTE_RATEAUX_NEXT_CONTROLE).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeRateauSommaire = async (exercice_id, codeSommaire) => {
    return axios.get(`${LISTE_RATEAUX}/${exercice_id}/${codeSommaire}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeNextControleSommaire = async (exercice_id, codeSommaire) => {
    return axios.get(`${LISTE_RATEAUX_NEXT_CONTROLE}/${exercice_id}/${codeSommaire}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeIdentificationSommaire = async (exercice_id, codeSommaire) => {
    return axios.get(`${LISTE_IDENTIFICATION_SOMMAIRE}/${exercice_id}/${codeSommaire}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeIdentificationMission = async (exercice_id) => {
    return axios.get(`${LISTE_IDENTIFICATION_MISSION}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}