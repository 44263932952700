import {Button, notification, Table, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import * as XLSX from "xlsx";
import {SearchHeader} from "../../../../../layout/header/HeaderList";
import {
    ajouterGrandLivre,
    getGrandLivreDefinitifDetails,
    getGrandLivreProvisoiresDetails
} from "../../../../../../data/balance/grandLivre";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../helpers/AssetsHelpers";
import moment from "moment/moment";
import {isNumber} from "../../../../../utilities/Utilities";
import TableTitle from "../../../../../common/TableTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faSave} from "@fortawesome/free-solid-svg-icons";
const GrandLivre = ({
        isProvisoire,
        missionSelected,
        // plans,
        client,
        livreData,
        setLivreData,
        heureEntree,
        date,
        codeSommaire,
        intervention_id,
        tache_id,
        user_id, chargement
    }) => {
    const [excelData, setExcelData] = useState([])
    const [filtersItems, setFiltersItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isVisible, setIsVisible] = useState(false)
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
        },
    });

    useEffect(() =>{
        if(parseInt(isProvisoire) === 1){
            fetchGrandLivreProvisoire();
        }
        else{
            fetchGrandLivreDefinitif();
        }
    }, [missionSelected, isProvisoire])
    //
    const fetchGrandLivreProvisoire = async () => {
        setLoading(true)
        await getGrandLivreProvisoiresDetails(missionSelected.id)
            .then((res) => {
                if (res && res?.results) {
                    setLivreData(res?.results?.Lea_GrandLivreDetails || [])
                    // setFiltersItems(res.results.Lea_GrandLivreDetails)
                    setLoading(false)
                } else {
                    setLivreData([])
                    // setFiltersItems([])
                    setLoading(false)
                }
            })
    }

    const fetchGrandLivreDefinitif = async () => {
        setLoading(true)
        await getGrandLivreDefinitifDetails(missionSelected.id)
            .then((res) => {
                if (res && res?.results) {
                    setLivreData(res?.results?.Lea_GrandLivreDetails || [])
                    // setFiltersItems(res.results.Lea_GrandLivreDetails)
                    setLoading(false)
                } else {
                    setLivreData([])
                    // setFiltersItems([])
                    setLoading(false)
                }
            })
    }

    useEffect(() =>{
        const LivreDetails = excelData.map((item) => ({
            numeroCompte: item[0].toString(),
            libelle: item[1].toString(),
            date: new Date(Date.UTC(0, 0, item[2] - 1)),
            codeJournal: item[3].toString(),
            piece: item[4].toString(),
            libelleECR: item[5].toString(),
            soldeDebit: item[6] === 0 ? parseInt(item[6]) : item[6],
            soldeCredit: item[7] === 0 ? parseInt(item[7]) : item[7]
        }));

        setLivreData(LivreDetails)
        setFiltersItems(LivreDetails)
    }, [excelData, setLivreData])

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            if(isVisible){
                setFiltersItems(livreData)
                if((livreData ?? []).length <= 0){
                    notification.warning({
                        description: "Aucun grand livre disponible !",
                    });
                }
            }
            else{
                setFiltersItems([])
            }
            setLoading(false)
        }, 500)
    }, [isVisible, livreData])

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setLivreData([]);
        }
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        const filtered = (livreData ?? []).filter(
            (item) =>
                item?.libelle.toLowerCase().includes(searchTerm) ||
                item?.libelleECR.toLowerCase().includes(searchTerm) ||
                item?.numeroCompte.toString().toLowerCase().includes(searchTerm) ||
                item?.piece.toLowerCase().includes(searchTerm) ||
                // item.piece.toLowerCase().includes(searchTerm) ||
                item?.codeJournal.toLowerCase().includes(searchTerm)
        );
        setFiltersItems(filtered);
    };

    const handleFile = (e) => {
        setLoading(true)
        const fileTypes = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'text/csv',
        ];
        const selectedFile = e.target.files[0];

        if (selectedFile && missionSelected) {
            if (selectedFile && fileTypes.includes(selectedFile.type)) {

                const reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);

                reader.onload = (e) => {
                    const arrayBuffer = e.target.result;
                    const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                    setExcelData(rawData);
                    setIsVisible(true)
                    setLoading(false)
                    // const filledData = rawData.map((row) =>
                    //     initialColumns.map((col, index) =>
                    //         typeof row[index] !== 'undefined' ? row[index] : ''
                    //     )
                    // );
                    //
                    // setExcelData(filledData);
                    notification.success({
                        description: "Grand livre importé avec succès.",
                    });
                };
            } else {
                setExcelData([]);
                setLoading(false)
            }
        }
    };

    const handleImporterGrandLivre = async (e) => {
        e.preventDefault()
        setLoading(true)

        const datas = (livreData ?? [])?.map((item) => ({
            numeroCompte: item?.numeroCompte,
            libelle: item?.libelle,
            date: item?.date,
            codeJournal: item?.codeJournal,
            piece: item?.piece,
            libelleECR: item?.libelleECR,
            soldeDebit: isNumber(item?.soldeDebit) ? item?.soldeDebit : parseInt(item?.soldeDebit.replace(/\$\s?|( )/g, '')),
            soldeCredit: isNumber(item?.soldeCredit) ? item?.soldeCredit : parseInt(item?.soldeCredit.replace(/\$\s?|( )/g, ''))
        }))

        if(livreData.length > 0){
            const data = {
                intervention_id,
                date,
                heureEntree,
                tache_id,
                user_id,
                codeSommaire,
                exercice_id: missionSelected.id,
                libelle: missionSelected.annee,
                client_id: client.id,
                isProvisoire: parseInt(isProvisoire) === 1,
                livreData: datas
            }

            const {status, message} = await ajouterGrandLivre(data)
            // setMessage(message)
            if (status === 'success') {
                setLoading(false)
                setIsVisible(false)
                await swal(succesMessage, '', 'success')
            } else {
                setLoading(false)
                await swal(message, '', 'error')
            }
        }
    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>N° de compte</TableTitle>,
            dataIndex: 'numeroCompte',
            sorter: true,
            width: 70,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <span className="fw-bolder" style={{fontSize: '14px'}}>
                        {record.numeroCompte}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelleCompte',
            sorter: true,
            width: 300,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <span className="fw-bolder text-uppercase" style={{fontSize: '14px'}}>
                        {record.libelle}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Date</TableTitle>,
            dataIndex: 'date',
            width: 100,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <span className="fw-bolder" style={{fontSize: '14px'}}>
                        {moment(record.date).format('DD/MM/YYYY')}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Code journal</TableTitle>,
            dataIndex: 'codeJournal',
            width: 50,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <span className="fw-bolder" style={{fontSize: '14px'}}>
                        {record.codeJournal}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>N° Pièce</TableTitle>,
            dataIndex: 'piece',
            width: 50,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {record.piece}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Libellé ECR</TableTitle>,
            dataIndex: 'libelle',
            width: 300,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <span className="fw-bolder text-uppercase" style={{fontSize: '14px'}}>
                        {record.libelleECR}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Solde Débit</TableTitle>,
            dataIndex: 'soldeDebit',
            width: 100,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {isNumber(record.soldeDebit) ? record.soldeDebit.toLocaleString('fr') : record.soldeDebit}
                    </div>
                </Tooltip>
            ),
        },
        {
            title: () => <TableTitle>Solde Crédit</TableTitle>,
            dataIndex: 'soldeCredit',
            width: 100,
            render: (_, record) => (
                <Tooltip title={`${record.error !== true ? '' : record.errorMessage}`}>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {isNumber(record.soldeCredit) ? record.soldeCredit.toLocaleString('fr') : record.soldeCredit}
                    </div>
                </Tooltip>
            ),
        },
    ];

    return (
        <>
            <div className='d-flex justify-content-lg-between align-items-center'>
                <div className="d-flex">
                    <div className='w-lg-700px cursor-pointer w-150px d-flex align-items-center mb-2 mt-2'>
                        <input
                            readOnly={missionSelected.isCloture !== 1 || missionSelected.isClotureProvisoire !== 1}
                            type="file"
                            className='form-control me-5'
                            accept=".xlsx, .csv, .xls"
                            onChange={handleFile}
                            required={true}
                        />
                    </div>
                    <div className="pt-3">
                        <Tooltip color='blue'
                                 title={isVisible ? "Masquer la balance enregistrée" : "Afficher la balance enregistrée"}>
                            <Button
                                size={"large"}
                                shape={"circle"}
                                className=""
                                icon={<FontAwesomeIcon icon={isVisible ? faEye : faEyeSlash}/>}
                                onClick={() => {
                                    setIsVisible(!isVisible)
                                }}
                            />
                        </Tooltip>
                    </div>
                </div>
                <div className="">
                    {/*Verifier si la balance existe deja pour l'état et confirmer l'enregistrement*/}
                    {/*<Popconfirm*/}
                    {/*    title="Editer le bon ?"*/}
                    {/*    onConfirm={() => {*/}
                    {/*        setCurrentAvisID(record.id);*/}
                    {/*        showModal();*/}
                    {/*    }}*/}
                    {/*    okText="Oui"*/}
                    {/*    cancelText="Non"*/}
                    {/*>*/}
                    {/*    <Button*/}
                    {/*        style={{ backgroundColor: "#aaf13a", color: "#fff" }}*/}
                    {/*        icon={<FontAwesomeIcon icon={faFileMedicalAlt} />}*/}
                    {/*    />*/}
                    {/*</Popconfirm>*/}
                    <Button
                        size="large"
                        type="primary"
                        // onClick={handleImporterGrandLivre}
                        onClick={handleImporterGrandLivre}
                        disabled={(missionSelected.isCloture === 1 || missionSelected.isClotureProvisoire ===1)}
                        style={{ marginRight: "20px", color: "white" }}
                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave} /> }
                    >
                        {!loading && <span className='indicator-label'>Enregistrer</span>}
                        {loading && (
                            <span className='indicator-progress fs-9' style={{display: 'block'}}>
                              Patientez svp...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                        )}
                    </Button>
                </div>
            </div>
            {/* begin::Products */}
            <div className="table-responsive">
                {isVisible && <div className="card-header border-0 pt-2">
                    <SearchHeader
                        placeholder="Rechercher par le libellé, libelléECR, numéro, pièce, code, journal"
                        handleSearch={handleSearchChange}
                    />
                </div>}
                <div className="py-4">
                    <div className="table-responsive">
                        <Table
                            rowClassName={(record,) =>
                                `${record.error && record.error === true ? `text-info` : ''} cursor-pointer h-3px p-0 m-0 lh-0.5`
                            }
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={filtersItems}
                            pagination={tableParams.pagination}
                            loading={loading || chargement}
                            // scroll={{
                            //     y: 1000,
                            // }}
                            onChange={handleTableChange}
                            rowSelection
                            size={"small"}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export {GrandLivre}