import React, { useEffect, useState } from "react";
import { Identification } from "../../Identification";
import {TraitementTravauxExpertComptable} from "./EF1/TraitementTravauxExpertComptable";
import {
    ajouterTravauxProfessionnel,
    getListeTravauxProfessionnelSommaire
} from "../../../../../data/travauxProfessionnel";
import {getListeIdentificationSommaire} from "../../../../../data/rateau";
import swal from "sweetalert";
import {succesMessage} from "../../../../../helpers/AssetsHelpers";
import {faIdCard, faSave, faTasks} from "@fortawesome/free-solid-svg-icons";
import {ObservationTravauxExpert} from "./EF1/ObservationTravauxExpert";
import {ConclusionTravauxExpert} from "./EF1/ConclusionTravauxExpert";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {Button, Tooltip} from "antd";
import {appTheme} from "../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PrintTravauxProfessionnel from "./PrintTravauxProfessionnel";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const ControleTravauxExpertComptable = ({
            mission,
            client,
            code,
            modalId,
            prev
    }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [codeSommaire] = useState(code || 'EF1');
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [identifications, setIdentifications] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [isTaskSave, setIsTaskSave] = useState([]);

    const [travauxData, setTravauxData] = useState([]);
    const [datas, setDatas] = useState([]);
    const [conclusion, setConclusion] = useState('')
    const [conclusionGenerale, setConclusionGenerale] = useState('')
    const [observation, setObservation] = useState('')
    const [isAddMemoire, setIsAddMemoire] = useState(false)
    const [title, setTitle] = useState("");
    const [libelle, setLibelle] = useState("");
    const [objectif, setObjectif] = useState(null);
    const [excelPrintData, setExcelPrintData] = useState([]);
    const [auth, setAuth] = useState(false);

    const navigate = useNavigate();

    useEffect(()=> {
        getListeTravauxProfessionnelSommaire(missionSelected.id, codeSommaire)
            .then((res) => {
                if(res.result){
                    // const noteFetch = res.result.codeSommaire === codeSommaire
                    if(res.result){
                        setObservation(res.result.observation)
                        setConclusion(res.result.conclusion)
                        setConclusionGenerale(res.result.conclusionGenerale)
                        setDatas(res.result?.Lea_TravauxProfessionnelQuestions);
                    }
                    else{
                        setObservation('')
                        setConclusion('')
                        setConclusionGenerale('')
                        setDatas([]);
                    }
                }
            });

        if(modalId === 1) {
            setTitle("EF1 - Revue des travaux réalisés par le Co-cac / Expert-Comptable");
            setLibelle("TRAVAUX_COMMISSAIRE");
            setObjectif("");
        }
        else if(modalId === 2) {
            setTitle("EF2 - Revue des travaux de l’auditeur interne ");
            setLibelle("TRAVAUX_INTERNE");
            setObjectif(
                <>
                    <div className="card mb-6 mb-xl-9">
                        <div className="card-body">
                            <div className="fs-6 fw-bold text-gray-600 mb-5">
                                <p>
                                    L'objectif de la présente check-list est d'évaluer le travail de l'auditeur interne et de determiner dans quelle mesures ces travaux sont adéquats pour le besoin de l'audit.<br/>
                                    En cas de réponse affirmative, l'auditeur doit évaluer l'incidence sur la nature, le calendrier ou l'étendue des procédures d'audit.
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        else if(modalId === 3) {
            setTitle("EF3 - Revue des travaux d’autres Experts");
            setLibelle("TRAVAUX_EXPERT");
            setObjectif(
                <>
                    <div className="card mb-6 mb-xl-9">
                        <div className="card-body">
                            <div className="fs-6 fw-bold text-gray-600 mb-5">
                                <p>
                                    Le questionnaire est rempli par le(s) membre(s) de l'équipe de l'équipe chargé(s) de la revue. Toute réponse "non" devrait être assortie de commentaire. <br/>
                                    Le présent questionnaire a pour objectif d'apprécier si les travaux mis en oeuvres par les co-commissaires aux comptes : <br/>
                                    <br/>
                                    <strong>-</strong> correspondent à ceux définis lors de la répartition ou décidés lors de la réévaluation du risque d'anomalie significatives au niveau des assertions;
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else {
            setTitle("EF4 - Revue Utilisation d’une société de services");
            // setLibelle("TRAVAUX_EXPERT_COMPTABLE");
            setLibelle("TRAVAUX_SOCIETE");
            setObjectif(
                <>
                    <div className="card mb-6 mb-xl-9">
                        <div className="card-body">
                            <div className="fs-6 fw-bold text-gray-600 mb-5">
                                <p>
                                    Le questionnaire est rempli par le(s) membre(s) de l'équipe de l'équipe chargé(s) de la revue. Toute réponse "non" devrait être assortie de commentaire. <br/>
                                    Le présent questionnaire a pour objectif d'apprécier si les travaux mis en oeuvres par les co-commissaires aux comptes : <br/>
                                    <br/>
                                    <strong>-</strong> correspondent à ceux définis lors de la répartition ou décidés lors de la réévaluation du risque d'anomalie significatives au niveau des assertions;
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            )
        }

    }, [codeSommaire, missionSelected, modalId])

    useEffect(()=> {
        if(activeTab === 0){
            getListeIdentificationSommaire(missionSelected.id, codeSommaire)
                .then((res) => {
                    setIdentifications(res.result)
                })
        }
    }, [activeTab, codeSommaire, missionSelected])

    useEffect(() => {
        if((travauxData ?? []).length > 0){
            const data = travauxData.flatMap((item) =>
                item.questions.map((question) => ({
                    question_id: question.question_id,
                    groupe: question.groupe,
                    ref: question.ref,
                    libelle: question.libelle,
                    assertions: question.assertions,
                    reponse: question.reponse === '1' ? 'S/O': question.reponse === '2' ? 'OUI' : 'NON',
                    commentaire: question.commentaire,
                }))
            );
            setExcelPrintData(data)
        }
    }, [travauxData])

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault()

        let travaux = travauxData.flatMap((item) =>
            item.questions.map((question) => ({
                question_id: question.question_id,
                groupe: question.groupe,
                ref: question.ref,
                libelle: question.libelle,
                assertions: question.assertions,
                reponse: question.reponse,
                commentaire: question.commentaire,
            }))
        );

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            travauxData: travaux,
            conclusion,
            conclusionGenerale,
            isAddMemoire,
            isProvisoire,
            observation,
            codeSommaire: codeSommaire,
            exercice_id: mission.id,
            client_id: mission.client_id
        }

        const {status, message} = await ajouterTravauxProfessionnel(data)
        if (status === 'success') {
            setLoading(false)
            setAuth(false);
            await swal(succesMessage, '', 'success')
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "ON",
            title: "Objectifs/Notes",
            icon: faTasks,
            content: (objectif),
        },
        {
            code: "CR",
            title: "Cycles revus",
            icon: faTasks,
            content: (
                <>
                    <TraitementTravauxExpertComptable
                        client={client}
                        isProvisoire={isProvisoire}
                        setIsAddMemoire={setIsAddMemoire}
                        isAddMemoire={isAddMemoire}
                        setConclusion={setConclusion}
                        conclusion={conclusion}
                        selectItems={travauxData}
                        setSelectItems={setTravauxData}
                        datas={datas}
                        chargement={loading}
                        mission={missionSelected}
                        libelleCycle={libelle}
                        setIsTaskSave={setIsTaskSave}
                    />
                </>
            ),
        },
        {
            code: "OG",
            title: `Observation générale - ${selectYear}`,
            description: `Observation générale - ${selectYear}`,
            icon: faTasks,
            content: (
                <ObservationTravauxExpert
                    observation={observation}
                    setObservation={setObservation}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
        {
            code: "CC",
            title: `Conclusion - ${selectYear}`,
            description: `Conclusion - ${selectYear}`,
            icon: faTasks,
            content: (
                <ConclusionTravauxExpert
                    setConclusionGenerale={setConclusionGenerale}
                    conclusionGenerale={conclusionGenerale}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
    ];

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={title}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isExcelPrint={true}
                    isLandingScape={true}
                    printTitle={(`${title} - ${selectYear}`)}
                    excelData={excelPrintData}
                    printContent={(
                        <PrintTravauxProfessionnel
                            objectif={objectif}
                            datas={travauxData}
                            conclusion={conclusion}
                            observation={conclusionGenerale}
                        />
                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>
                        {activeTab >= 2 && <div className="d-flex justify-content-end align-items-end mb-10">
                            <Tooltip title="Enregistrer">
                                <Button
                                    disabled={(missionSelected.isCloture === 1 || missionSelected.isClotureProvisoire === 1)}
                                    size={"large"}
                                    // loading={loading}
                                    style={{
                                        backgroundColor: appTheme.colors.secondary,
                                        color: "white"
                                    }}
                                    icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                                    onClick={handleSubmit}
                                >
                                    {!loading &&
                                        <span className='indicator-label text-white'>Enregistrer</span>}
                                    {loading && (
                                        <span className='indicator-progress fs-9 text-white'
                                              style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                                    )}
                                </Button>
                            </Tooltip>
                        </div>}
                        {formSteps[activeTab].content}
                    </>
                </StepBody>
            </div>
        </>
    );
}

export {ControleTravauxExpertComptable}