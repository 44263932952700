import React, { useState } from 'react';
import {Menu, Typography} from 'antd';
import {
    faHistory, faTasks,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTheme} from "styled-components";
import TraitementExamenAnalytiqueEF from "./TraitementExamenAnalytiqueEF";
import {HistoriqueExamenAnalytique} from "./HistoriqueExamenAnalytique";
import {appTheme} from "../../../../../../../config/theme";

const {  Title } = Typography;

const MenuEA = ({
        actifHistory,
        setActifHistory,
        passifHistory,
        setPassifHistory,
        resultHistory,
        setResultHistory,
        sigTabHistory,
        setSigTabHistory,
        mission,
        client,
        isProvisoire,
        chargement,
        setIsTaskSave,
        handleSubmit,
    }) => {
    const theme = useTheme();

    const [current, setCurrent] = useState('0');
    const onClick = (e) => {
        setCurrent(e.key);
    };

    const items = [
        {
            label: 'EA-EF',
            description:"EXAMEN ANALYTIQUE DES ETATS FINANCIERS",
            key: '0',
            icon: <FontAwesomeIcon icon={faTasks} color={theme.colors.primary_blue} />,
        },
        {
            label: 'HISTORIQUE EF',
            description:"HISTORIQUE DES EXAMENS ANALYTIQUE DES ETATS FINANCIERS",
            key: '1',
            icon: <FontAwesomeIcon icon={faHistory} color={theme.colors.primary_blue} />,
        },
    ];

    const showCurrentPage = () => {
        switch (current) {
            case '0':
                return (
                    <TraitementExamenAnalytiqueEF
                        client={client}
                        actifHistory={actifHistory}
                        passifHistory={passifHistory}
                        resultHistory={resultHistory}
                        setActifHistory={setActifHistory}
                        setPassifHistory={setPassifHistory}
                        setResultHistory={setResultHistory}
                        sigTabHistory={sigTabHistory}
                        setSigTabHistory={setSigTabHistory}
                        mission={mission}
                        isProvisoire={isProvisoire}
                        chargement={chargement}
                        setIsTaskSave={setIsTaskSave}
                    />
                )
            case '1':
                return (
                    <HistoriqueExamenAnalytique
                        actifHistory={actifHistory}
                        setActifHistory={setActifHistory}
                        passifHistory={passifHistory}
                        setPassifHistory={setPassifHistory}
                        resultHistory={resultHistory}
                        setResultHistory={setResultHistory}
                        sigTabHistory={sigTabHistory}
                        setSigTabHistory={setSigTabHistory}
                        mission={mission}
                        isProvisoire={isProvisoire}
                        chargement={chargement}
                        handleSubmit={handleSubmit}
                        setIsTaskSave={setIsTaskSave}
                    />
                )
            default:
                return (
                    <TraitementExamenAnalytiqueEF
                        client={client}
                        actifHistory={actifHistory}
                        passifHistory={passifHistory}
                        resultHistory={resultHistory}
                        setActifHistory={setActifHistory}
                        setPassifHistory={setPassifHistory}
                        setResultHistory={setResultHistory}
                        sigTabHistory={sigTabHistory}
                        setSigTabHistory={setSigTabHistory}
                        mission={mission}
                        isProvisoire={isProvisoire}
                        chargement={chargement}
                        setIsTaskSave={setIsTaskSave}
                    />
                )
        }
    }

    return (
        <>
            <div className="p-2">
                <Menu
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                    items={items}
                    style={{marginBottom: '5px', fontWeight: 'bold'}}
                />
                <br/>
                <Title className="my-5" style={{display: "block", fontWeight: '600', color: appTheme.colors.primary}} level={4}># {items[current].description}</Title>
                {showCurrentPage()}
            </div>
        </>
    )
}

export default MenuEA;