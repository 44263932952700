import {Table} from "antd";
import React, { useState} from "react";
import TableTitle from "../../../../../../common/TableTitle";
import {SearchHeader} from "../../../../../../layout/header/HeaderList";


const BilanActifEA = ({chargement, bilanActif, mission}) => {
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Référence</TableTitle>,
            dataIndex: 'ref',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.ref}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 400,
            render: (_, record) => (
                <>
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                     {record.libelle}
                </span>
                </>
            ),
            responsive: ['sm']
        },
        {
            title: () => <TableTitle>{`Exercice ${mission.annee || "N"}`}</TableTitle>,
            dataIndex: 'exerciceN',
            width: 100,
            render: (_, record) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {record.montant && record.montant.toLocaleString('fr')}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.montantN1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>N  -  N-1</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width:100,
                    render: (_, record) => (
                        <>
                            <div
                                className={`text-end p-1 fw-bolder 
                                        ${( ( (record.montant)=== 0 ? 0 : (record.montant - record.montantN1) / record.montantN1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.montant)=== 0 ? 0 : (record.montant - record.montantN1) / record.montantN1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                                style={{fontSize: '14px'}}
                            >
                                {(record.montant - record.montantN1).toLocaleString('fr')}
                            </div>
                        </>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    width:70,
                    key:'taux',
                    render: (_, record) => (
                        <div
                            className={`text-end p-1 fw-bolder 
                                        ${( ( (record.montant)=== 0 ? 0 : (record.montant - record.montantN1) / record.montantN1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.montant)=== 0 ? 0 : (record.montant - record.montantN1) / record.montantN1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                            style={{fontSize: '14px'}}
                        >
                            {( ( (record.montant)=== 0 ? 0 : (record.montant - record.montantN1) / record.montantN1 ) * 100).toFixed(2).toLocaleString('fr')}%
                        </div>
                    ),
                }
            ],
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exercice_N_1',
            width:100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.montantN1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 2) || "N-2"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: () => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {0}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>N1  -  N-2</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width: 100,
                    render: (_, record) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(record.montantN1 || 0).toLocaleString('fr') || 0}
                        </div>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    key:'taux',
                    width: 70,
                    render: (_, record) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(0).toLocaleString('fr') || 0}%
                        </div>
                    ),
                }
            ],
        },
    ];

    return(
        <div className="table-responsive">
            {(bilanActif ?? []).length > 0 && <div className="card-header border-0 pt-2">
                <SearchHeader
                    placeholder="Rechercher par la référence, le libellé..."
                    // handleSearch={handleSearchChange}
                />
            </div>}
            <div className="py-4">
                <div className="table-responsive">
                    <Table
                        rowClassName={(record,) =>
                            `${record.color ? `bg-${record?.color}` : ''} h-3px p-0 m-0 lh-0.5`
                        }
                        columns={columns}
                        rowKey={(record, index) => index}
                        dataSource={(bilanActif ?? [])}
                        pagination={tableParams.pagination}
                        onChange={handleTableChange}
                        loading={chargement}
                        rowSelection
                        size={"small"}
                    />
                </div>
            </div>
        </div>
    )
}

export {BilanActifEA}
