import React, {useEffect, useState} from "react";
import { Tooltip, Typography} from "antd";
import BudgetTemps from "./EquipeAudit/BudgetTemps";
import {ProgrammeControle1} from "./EquipeAudit/ProgrammeControle1";
import {appTheme} from "../../../../../../config/theme";

const { Title } = Typography;

const EquipeAudit = ({
         mission,
         isProvisoire,
         client,
         programmeTaches,
         budgetTemps,
         strategies,
         etatsFinanciers,
         chargement
    }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedMap, setSelectedMap] = useState([]);

    const [isProvisoireTemp, setIsProvisoireTemp] = useState(false);

    useEffect(() => {
        setIsProvisoireTemp((parseInt(isProvisoire) === 1) || true);
    }, [isProvisoire])

    const handleTabChange = (tabName) => {
       setLoading(true);
       setTimeout(() => {
           setActiveTab(tabName);
           setLoading(false);
       }, 1000)
    };

    return(
        <>
            <div className="w-100 pb-15">
                <div className="card-header">
                    <div className="card-title">
                        <Title style={{
                            display: "block",
                            fontWeight: '600',
                            color: appTheme.colors.primary,
                            // textAlign: "center"
                        }} level={4}>{"EC4.8 : Equipe d'Audit"}</Title>
                    </div>
                </div>
                <div className="mt-10"></div>
                <div className="flex-lg-row-fluid">
                    <div className="d-flex justify-content-between align-center">
                        <div className="d-flex flex-column align-center">
                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-5">
                                {["Budget Temps", "Programme contrôle et repartition des tâches 1"].map((item, index) => (
                                    <li className="nav-item" key={index}>
                                        <Tooltip title={item}>
                                            <button
                                                className={`nav-link text-active-primary pb-4 ${activeTab === index ? 'active' : ''}`}
                                                onClick={() => handleTabChange(index)}
                                            >
                                                <span className="fs-4 fw-bolder text-uppercase">
                                                    {(item ?? "")}
                                                </span>
                                            </button>
                                        </Tooltip>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <form className="stepper-form pt-10">
                    <div className="tab-content" id="myTabContent">
                        {/* begin::Tab pane */}
                        <div className={`tab-pane fade ${activeTab === 0 ? 'show active' : ''}`} role="tabpanel">
                            {/* begin::Products */}
                            <BudgetTemps
                                mission={mission}
                                isProvisoire={isProvisoireTemp}
                                client={client}
                                budgetTemps={budgetTemps}
                                strategies={strategies}
                                selectedMap={selectedMap}
                                chargement={loading}
                                etatsFinanciers={etatsFinanciers}
                            />
                            {/* end::Products */}
                        </div>
                        {/* end::Tab pane */}
                        {/* begin::Tab pane */}
                        <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} role="tabpanel">
                            {/* begin::Products */}
                            <ProgrammeControle1
                                mission={mission}
                                isProvisoire={isProvisoireTemp}
                                programmeTaches={programmeTaches}
                                strategies={strategies}
                                setSelectedMap={setSelectedMap}
                                chargement={loading}
                            />
                            {/* end::Products */}
                        </div>
                        {/* end::Tab pane */}
                    </div>
                </form>
            </div>
        </>
    )
}
export {EquipeAudit}