import axios from 'axios'
import {
    AJOUTER_BALANCE, SELECT_BALANCE_DEFINITIF, SELECT_BALANCE_PROVISOIRE
} from "../../api/apiRoutes";

export const ajouterBalance = async (data) => {
    return axios.post(AJOUTER_BALANCE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getBalanceProvisoiresDetails = async (exercice_id) => {
    return axios.get(`${SELECT_BALANCE_PROVISOIRE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getBalanceDefinitifDetails = async (exercice_id) => {
    return axios.get(`${SELECT_BALANCE_DEFINITIF}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}