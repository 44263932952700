import axios from "axios";

const AJOUTER_TRAVAUX_PROFESSIONNEL = `${process.env.REACT_APP_API_URL}/lea/exercice/travaux-professionnel/ajouter`
const LISTE_TRAVAUX_PROFESSIONNEL = `${process.env.REACT_APP_API_URL}/lea/exercice/travaux-professionnel`

export const ajouterTravauxProfessionnel = async (data) => {
    return axios.post(AJOUTER_TRAVAUX_PROFESSIONNEL, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeTravauxProfessionnel = async (exercice_id) => {
    return axios.get(`${LISTE_TRAVAUX_PROFESSIONNEL}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getListeTravauxProfessionnelSommaire = async (exercice_id, codeSommaire) => {
    return axios.get(`${LISTE_TRAVAUX_PROFESSIONNEL}/${exercice_id}/${codeSommaire}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}