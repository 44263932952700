import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../helpers/AssetsHelpers";
import {ajouterObservationGenerale} from "../../../../../../data/stategieGlobale";
import {Button, Input, Tooltip, Typography} from "antd";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

const ObservationStrategie = ({
      mission,
      strategies,
      codeSommaire,
      date,
      heureEntree,
      tache_id,
      intervention_id,
      isProvisoire,
    }) => {
    const [observation_generale, setObservationGenerale] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(strategies){
            setObservationGenerale(strategies.observation_generale)
        }
        else {
            setObservationGenerale('')
        }
    }, [strategies])

    const onChange = (e) => {
        setObservationGenerale(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = {
            observation_generale,
            exercice_id: mission.id,
            client_id: mission.client_id,
            isProvisoire: parseInt(isProvisoire) === 1,
            codeSommaire ,
            heureEntree,
            date,
            tache_id,
            intervention_id,
        }

        const {status, message} = await ajouterObservationGenerale(data)
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    }

    return (
        <div className="d-flex flex-column fv-plugins-bootstrap5 fv-plugins-framework pb-10">
            {<div className="d-flex justify-content-end align-items-end mb-10">
                <Tooltip title="Enregistrer">
                    <Button
                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                        size={"large"}
                        // loading={loading}
                        style={{
                            backgroundColor: appTheme.colors.secondary,
                            color: "white"
                        }}
                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                        onClick={handleSubmit}
                    >
                        {!loading &&
                            <span className='indicator-label text-white'>Enregistrer</span>}
                        {loading && (
                            <span className='indicator-progress fs-9 text-white'
                                  style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                        )}
                    </Button>
                </Tooltip>
            </div>}
            <div className="card-header">
                <div className="card-title">
                    <Title style={{
                        display: "block",
                        fontWeight: '600',
                        color: appTheme.colors.primary,
                        // textAlign: "center"
                    }} level={4}>{"EC4.10: Observation générale"}</Title>
                </div>
            </div>
            <div className="mt-10"></div>
            <Input.TextArea
                size="large"
                placeholder="Saissiser le contexte"
                readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                maxLength={5000}
                showCount
                autoSize={{
                    minRows: 8,
                    maxRows: 25,
                }}
                value={observation_generale}
                onChange={onChange}
            />
        </div>
    );
};

export default ObservationStrategie;
