import React from "react";
import clsx from "clsx";

const GeneraliteInfos = ({formik, statuts, secteurs}) => {

    
    return (
        <>
            <div className="card card-flush">
                {/*begin::Card header*/}
                <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                    {/*begin::Card title*/}
                    <div className="card-title">
                        {/*begin::Title*/}
                        <h2>Generalité</h2>
                        {/*end::Title*/}
                    </div>
                    {/*end::Card title*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body pt-0">
                    {/*begin::Form*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7 fv-plugins-icon-container">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span className="required">Sigle du client</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('sigle')}
                                    type="text"
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.sigle && formik.errors.sigle},
                                        {
                                            'is-valid': formik.touched.sigle && !formik.errors.sigle,
                                        }
                                    )}
                                    name="sigle"
                                    value={formik.values.sigle}
                                />
                                {/*end::Input*/}
                                {formik.touched.sigle && formik.errors.sigle && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.sigle}</div>
                                )}
                            </div>
                        </div>
                        <div className="row fv-row mb-7 fv-plugins-icon-container">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span className="required">Dénomination sociale</span>
                                    <i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title=""
                                       data-bs-original-title="Définir la dénomination sociale du client."
                                       aria-label="Définir la dénomination sociale du client."></i>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('denomination')}
                                    type="text"
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.denomination && formik.errors.denomination},
                                        {
                                            'is-valid': formik.touched.denomination && !formik.errors.denomination,
                                        }
                                    )}
                                    name="denomination"
                                    value={formik.values.denomination}
                                />
                                {/*end::Input*/}
                                {formik.touched.denomination && formik.errors.denomination && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.denomination}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span className='required'>Secteurs d'activité</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <select
                                    {...formik.getFieldProps('secteur_id')}
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.secteur_id && formik.errors.secteur_id},
                                        {
                                            'is-valid': formik.touched.secteur_id && !formik.errors.secteur_id,
                                        }
                                    )}
                                    autoComplete='off'
                                    name="secteur_id"
                                    disabled={formik.isSubmitting}
                                    value={formik.values.secteur_id}
                                >
                                    <option value=''>Selectionner...</option>
                                    {secteurs.map((opt) => (
                                        <option value={opt.id} key={opt.id}>
                                            {opt.libelle}
                                        </option>
                                    ))}
                                </select>
                                {/*end::Input*/}
                                {formik.touched.secteur_id && formik.errors.secteur_id && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.secteur_id}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span className='required'>Forme juridique</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <select
                                    {...formik.getFieldProps('secteur_id')}
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.statut_id && formik.errors.statut_id},
                                        {
                                            'is-valid': formik.touched.statut_id && !formik.errors.statut_id,
                                        }
                                    )}
                                    autoComplete='off'
                                    name="statut_id"
                                    disabled={formik.isSubmitting}
                                    value={formik.values.statut_id}
                                >
                                    <option value=''>Selectionner...</option>
                                    {statuts.map((opt) => (
                                        <option value={opt.id} key={opt.id}>
                                            {opt.libelle}
                                        </option>
                                    ))}
                                </select>
                                {/*end::Input*/}
                                {formik.touched.statut_id && formik.errors.statut_id && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.statut_id}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}

                        <div className='row fv-row mb-6'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6 text-md-end'>
                                Date de constitution et Durée de vie statutaire
                            </label>
                            <div className='col-lg-9'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        {/*begin::Input*/}
                                        <input
                                            {...formik.getFieldProps('date_constitution')}
                                            className={clsx(
                                                'form-control form-control-solid',
                                                {'is-invalid': formik.touched.date_constitution && formik.errors.date_constitution},
                                                {
                                                    'is-valid': formik.touched.date_constitution && !formik.errors.date_constitution,
                                                }
                                            )}
                                            placeholder='Date de constitution'
                                            name='date_constitution'
                                            type='date'
                                            value={formik.values.date_constitution}
                                        />
                                        {/*end::Input*/}
                                        {formik.touched.date_constitution && formik.errors.date_constitution && (
                                            <div className="fv-plugins-message-container invalid-feedback">{formik.errors.date_constitution}</div>
                                        )}
                                    </div>

                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            {...formik.getFieldProps('duree_statutaire')}
                                            className={clsx(
                                                'form-control form-control-solid',
                                                {'is-invalid': formik.touched.duree_statutaire && formik.errors.duree_statutaire},
                                                {
                                                    'is-valid': formik.touched.duree_statutaire && !formik.errors.duree_statutaire,
                                                }
                                            )}
                                            placeholder='Durée de vie statutaire'
                                            type="number"
                                            name="duree_statutaire"
                                            value={formik.values.duree_statutaire}
                                        />
                                        {/*end::Input*/}
                                        {formik.touched.duree_statutaire && formik.errors.duree_statutaire && (
                                            <div className="fv-plugins-message-container invalid-feedback">{formik.errors.duree_statutaire}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7" data-select2-id="select2-data-141-byvp">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Numéro R.C.C.M</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('numero_rccm')}
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.numero_rccm && formik.errors.numero_rccm},
                                        {
                                            'is-valid': formik.touched.date_constitution && !formik.errors.numero_rccm,
                                        }
                                    )}
                                    type="text"
                                    name="numero_rccm"
                                    value={formik.values.numero_rccm}
                                />
                                {/*end::Input*/}
                                {formik.touched.numero_rccm && formik.errors.numero_rccm && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.numero_rccm}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7" data-select2-id="select2-data-141-byvp">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Numéro compte contribuable (CC)</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('numero_cc')}
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.numero_cc && formik.errors.numero_cc},
                                        {
                                            'is-valid': formik.touched.numero_cc && !formik.errors.numero_cc,
                                        }
                                    )}
                                    type="text"
                                    name="numero_cc"
                                    value={formik.values.numero_cc}
                                />
                                {/*end::Input*/}
                                {formik.touched.numero_cc && formik.errors.numero_cc && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.numero_cc}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7" data-select2-id="select2-data-141-byvp">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>Centre impots</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('centre_impots')}
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.centre_impots && formik.errors.centre_impots},
                                        {
                                            'is-valid': formik.touched.centre_impots && !formik.errors.centre_impots,
                                        }
                                    )}
                                    type="text"
                                    name="centre_impots"
                                    value={formik.values.centre_impots}
                                />
                                {/*end::Input*/}
                                {formik.touched.centre_impots && formik.errors.centre_impots && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.centre_impots}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7" data-select2-id="select2-data-141-byvp">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>N° CNPS employeur</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('numero_cnps_employeur')}
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.numero_cnps_employeur && formik.errors.numero_cnps_employeur},
                                        {
                                            'is-valid': formik.touched.numero_cnps_employeur && !formik.errors.numero_cnps_employeur,
                                        }
                                    )}
                                    type="text"
                                    name="numero_cnps_employeur"
                                    value={formik.values.numero_cnps_employeur}
                                />
                                {/*end::Input*/}
                                {formik.touched.numero_cnps_employeur && formik.errors.numero_cnps_employeur && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.numero_cnps_employeur}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        {/*begin::Input group*/}
                        <div className="row fv-row mb-7" data-select2-id="select2-data-141-byvp">
                            <div className="col-md-3 text-md-end">
                                {/*begin::Label*/}
                                <label className="fs-6 fw-bold form-label mt-3">
                                    <span>N° agrément(Pour les secteur reglémentés)</span>
                                </label>
                                {/*end::Label*/}
                            </div>
                            <div className="col-md-9">
                                {/*begin::Input*/}
                                <input
                                    {...formik.getFieldProps('numero_cnps_employeur')}
                                    className={clsx(
                                        'form-control form-control-solid',
                                        {'is-invalid': formik.touched.numero_agrement && formik.errors.numero_agrement},
                                        {
                                            'is-valid': formik.touched.numero_agrement && !formik.errors.numero_agrement,
                                        }
                                    )}
                                    type="text"
                                    name="numero_agrement"
                                    value={formik.values.numero_agrement}
                                />
                                {/*end::Input*/}
                                {formik.touched.numero_agrement && formik.errors.numero_agrement && (
                                    <div className="fv-plugins-message-container invalid-feedback">{formik.errors.numero_agrement}</div>
                                )}
                            </div>
                        </div>
                        {/*end::Input group*/}
                        <div></div>
                    {/*end::Form*/}
                </div>
                {/*end::Card body*/}
            </div>
        </>
    )
}

export {GeneraliteInfos}