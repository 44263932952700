import React, {useEffect, useRef, useState} from "react";
import swal from "sweetalert";
import { succesMessage } from "../../../../../../../helpers/AssetsHelpers";
import {Button, Divider, Input, Select, Space, Table, notification, Typography, Tooltip} from "antd";
import {DeleteOutlined, PlusOutlined, UsergroupAddOutlined} from "@ant-design/icons";
import {
    ajouterStrategieUserEtatFinancier,
    getListeStrategieUserEtatFinancier,
    getListeUserEtatFinancier
} from "../../../../../../../data/userEtatFinancier";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const {  Title } = Typography;

const PrincipauxUtilisateurs = ({
        mission,
        strategies,
        codeSommaire,
        date,
        heureEntree,
        tache_id,
        intervention_id,
        isProvisoire,
    }) => {
    const [libelle, setLibelle] = useState('');
    const [seletedUsers, setSeletedUsers] = useState([])
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);
    const [allElementsAdded, setAllElementsAdded] = useState(false);

    useEffect(() => {
        if(strategies){
            getListeStrategieUserEtatFinancier(strategies.id)
                .then((res) => {

                    if(items.length > 0) {
                        let mainArray = [];
                        mainArray = new Set(items.map(item => item));
                        const array = res.result.filter(element => mainArray.has(element.Lea_UserEtatFinancier.libelle));

                        if (array.length > 0) {
                            const array1 = array.map((item) => ({
                                libelle: item.Lea_UserEtatFinancier.libelle,
                                commentaire: item.commentaire,
                            }));
                            setSeletedUsers(array1);
                        }
                    }
                    setLoading(false);
                })
                .catch((e) => {
                    notification.error({
                        description: e.message
                    })
                    setLoading(false);
                })
        }
    },[items, strategies]);

    const fetchUsers = async () => {
        setLoading(true);
        getListeUserEtatFinancier()
            .then((res) => {
                const elements = [...new Set(res.result.map((item) => item.libelle.toUpperCase()))];
                setItems(elements);
                setLoading(false);
            })
            .catch((e) => {
                notification.error({
                    description: e.message
                })
                setLoading(false);
            })
    };

    useEffect(() => {
        fetchUsers();
    },[]);

    const onNameChange = (event) => {
        setLibelle(event.target.value);
    };
    
    const addItem = (e) => {
        e.preventDefault();
        setItems([...items, libelle]);
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const onChange = (value) => {
        // const quest_id = value;
        const userAdd = items.find((item) => item.toUpperCase() === value.toUpperCase());
        const bool = seletedUsers.some((element) => element === userAdd)

        if (userAdd && !bool) {
            const newElement = {
                libelle: userAdd.toUpperCase(),
                commentaire: '',
            };
            setSeletedUsers([...seletedUsers, newElement]);
        }
    };

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const onSelectedAll = (e) => {
        e.preventDefault()
        setLoading(true);
        setTimeout(() => {
            const updatedSelectedUsers = [...seletedUsers];

            items.forEach((item) => {
                const bool = seletedUsers.some((element) => element.libelle === item.toUpperCase());

                if (!bool) {
                    const newElement = {
                        libelle: item.toUpperCase(),
                        commentaire: '',
                    };

                    updatedSelectedUsers.push(newElement);
                }
            });

            setSeletedUsers(updatedSelectedUsers);
            setAllElementsAdded(true);
            setLoading(false);
        }, 1000)
    }

    const handleDeleteAllElements = (e) => {
        e.preventDefault()
        setLoading(true);
        setTimeout(() => {
            setSeletedUsers([]);
            setAllElementsAdded(false);
            setLoading(false);
        }, 1000)
    };

    const handleDeleteElement = (index) => {
        setLoading(true);
        setTimeout(() => {
            const updatedList = seletedUsers.filter((_, i) => i !== index);
            setSeletedUsers(updatedList);
            setLoading(false);
        }, 1000)
    };

    const handleCommentChange = (index, commentaire) => {
        const updatedList = seletedUsers.map((item, i) =>
            i === index ? { ...item,  commentaire } : item
        );
        setSeletedUsers(updatedList);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            userInfos: seletedUsers,
            strategie_id: strategies?.id,
            exercice_id: mission.id,
            client_id: mission.client_id,
            codeSommaire ,
            heureEntree,
            date,
            tache_id,
            intervention_id,
            isProvisoire
        };

        const { status, message } = await ajouterStrategieUserEtatFinancier(data);
        if (status === "success") {
            setLoading(false);
            await swal(succesMessage, "", "success");
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    };

    const columns = [
        {
            title: () => <TableTitle>Utilisateurs</TableTitle>,
            dataIndex: 'users',
            key: 'users',
            width: 300,
            render: (_, record) => (
                <span className="fw-bolder fs-4 text-uppercase">
                    {record.libelle}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            key: 'commentaire',
            width: 500,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px", fontWeight: "600"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le commentaire..."
                        value={record.commentaire}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 2,
                            maxRows: 8,
                        }}
                        onChange={(e) => {
                            handleCommentChange(index, e.target.value);
                        }}
                    />
                </div>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            width: 100,
            key: 'action',
            render: (_, record, index) => (
                <Button
                    onClick={() => handleDeleteElement(index)}
                    title="Supprimer ?"
                    type="primary"
                    icon={<DeleteOutlined />}
                    danger
                />
            ),
        },
    ];

    return (
        <div className="d-flex flex-column fv-plugins-bootstrap5 fv-plugins-framework mt-8 mb-15">
            <div className="d-flex justify-content-end align-items-end mb-10">
                <Tooltip title="Enregistrer">
                    <Button
                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                        size={"large"}
                        // loading={loading}
                        style={{
                            backgroundColor: appTheme.colors.secondary,
                            color: "white"
                        }}
                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                        onClick={handleSubmit}
                    >
                        {!loading &&
                            <span className='indicator-label text-white'>Enregistrer</span>}
                        {loading && (
                            <span className='indicator-progress fs-9 text-white'
                                  style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                        )}
                    </Button>
                </Tooltip>
            </div>
            <div className="w-100 flex-lg-row-auto mb-4 me-4 me-lg-6">
                <div className="card card-flush py-1">
                    <div className="card-header">
                        <div className="card-title">
                            <Title style={{
                                display: "block",
                                fontWeight: '600',
                                color: appTheme.colors.primary_blue,
                                // textAlign: "center"
                            }} level={4}>{"Identifications des principaux utilisateurs"}</Title>
                        </div>
                    </div>
                    <div className="bg-white p-1">
                        <div
                            className="d-flex flex-column"
                            data-select2-id="select2-data-128-mofg"
                        >
                            <div
                                className="fv-row fv-plugins-icon-container"
                                data-select2-id="select2-data-127-m2cb"
                            >
                                <div className='d-flex align-center px-3'>
                                    <Select
                                        style={{ width: 500, fontSize: '14px' }}
                                        placeholder="Critère relatif à "
                                        onChange={onChange}
                                        className="me-15"
                                        showSearch
                                        size="large"
                                        optionFilterProp="children"
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            (option?.title ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        dropdownRender={(menu) => (
                                            <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Space style={{ padding: '0 8px 4px' }}>
                                                    <Input
                                                        placeholder="Entrer le critère"
                                                        ref={inputRef}
                                                        value={libelle}
                                                        onChange={onNameChange}
                                                    />
                                                    <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                        Ajouter un nom
                                                    </Button>
                                                </Space>
                                            </>
                                        )}
                                        options={items && items.map((item) => ({ label: item, value: item }))}
                                    />
                                    {allElementsAdded ?  (
                                            <div className="">
                                                <button
                                                    onClick={handleDeleteAllElements}
                                                    className="btn btn-sm btn-flex btn-secondary fs-5 fw-bold"
                                                >
                                                    <DeleteOutlined className="text-danger me-5 fs-4" /> Supprimer tout
                                                </button>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="">
                                                <button
                                                    onClick={onSelectedAll}
                                                    className="btn btn-sm btn-flex btn-primary fs-5 fw-bold"
                                                >
                                                    <UsergroupAddOutlined className="text-white me-5 fs-4" /> Tout sélectionner
                                                </button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="table-responsive pt-5 pb-10 mx-10">
                <Table
                    rowClassName={() =>
                        `h-3px p-0 m-0 lh-0.5`
                    }
                    columns={columns}
                    // rowKey={(record, index) => index}
                    dataSource={seletedUsers ?? []}
                    pagination={false}
                    scroll={{y: 800}}
                    loading={loading}
                    // rowSelection
                    size={"small"}
                />
            </div>
        </div>
    );
};

export default PrincipauxUtilisateurs;
