import axios from "axios"
import {
    CREATE_USER_URL, DELETE_USERS_URL,
    EDITED_USER_URL, GENERATE_USERS_PASSWORD_URL,
    GET_USERS_URL,
    LOGIN, UPDATE_USERS_ACCES_DISTAN_URL, UPDATE_USERS_ACCES_MOBILE_URL, UPDATE_USERS_ACCES_WEB_URL,
    UPDATE_USERS_PASSWORD_URL, UPDATE_USERS_STATE_URL
} from "../../api/apiRoutes"
import {setAuthorizationHeader} from "../../api/config";



export const AuthUser = async (login, password) => {
    const data = { login, password }
    return axios.post(LOGIN, data).then(result => {
        if (result.status === 200) {
            if (result.data.status === 'success') {
                return result.data
            } else {
                return {
                    result: [],
                    status: 'error',
                    message: result.data.message,
                    accessToken: ''
                }
            }
        } else {
            return {
                result: [],
                status: 'error',
                message: 'Problème de connexion détecté !',
                accessToken: ''
            }
        }
    }).catch(err => console.log(err))
}


export const verifAuthUser = (token) => {
    return (localStorage.getItem('accessToken') !== null && JSON.parse(localStorage.getItem('accessToken')) === token)
}

export const addUser = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(CREATE_USER_URL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const ModifierUser = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(EDITED_USER_URL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const ModiferMotPasse = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(UPDATE_USERS_PASSWORD_URL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getUsers = async () => {
    setAuthorizationHeader()
    return axios
        .get(GET_USERS_URL)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const deleteUser = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(DELETE_USERS_URL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const updateEtatUser = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(UPDATE_USERS_STATE_URL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const updateEtatAcessDistantUser = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(UPDATE_USERS_ACCES_DISTAN_URL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const updateEtatAcessWebUser = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(UPDATE_USERS_ACCES_WEB_URL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const updateEtatAcessMobileUser = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(UPDATE_USERS_ACCES_MOBILE_URL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const GenererPasswordUser = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(GENERATE_USERS_PASSWORD_URL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}
