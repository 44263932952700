import React, { useState} from "react";
import {Button, Input, Table, Tooltip} from "antd";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const MenuTabs = ["Bilan Actifs", "Bilan Passifs", "Compte Résultat", "SIG"]

const HistoriqueExamenAnalytique = ({
        sigTabHistory,
        chargement,
        mission,
        actifHistory,
        passifHistory,
        resultHistory,
        setActifHistory,
        setPassifHistory,
        setResultHistory,
        setSigTabHistory,
        setIsTaskSave,
        handleSubmit,
    }) => {
    const [activeTab, setActiveTab] = useState(1);
    const [loading, setLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    const handleTabChange = (tabName) => {
        setLoading(true);
        setTimeout(() => {
            setActiveTab(tabName);
            setLoading(false)
        }, 1000)
    };

    const columnsActifs = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Référence</TableTitle>,
            dataIndex: 'ref',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.compte}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 400,
            render: (_, record) => (
                <>
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                     {record.libelle}
                </span>
                </>
            ),
            responsive: ['sm']
        },
        {
            title: () => <TableTitle>{`Exercice ${mission.annee || "N"}`}</TableTitle>,
            dataIndex: 'exerciceN',
            width: 100,
            render: (_, record) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {record.soldeN && record.soldeN.toLocaleString('fr')}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>N  -  N-1</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width:100,
                    render: (_, record) => (
                        <>
                            <div
                                className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                                style={{fontSize: '14px'}}
                            >
                                {(record.soldeN - record.soldeN_1).toLocaleString('fr')}
                            </div>
                        </>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    width:70,
                    key:'taux',
                    render: (_, record) => (
                        <div
                            className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                            style={{fontSize: '14px'}}
                        >
                            {( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                        </div>
                    ),
                }
            ],
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exercice_N_1',
            width:100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 2) || "N-2"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: () => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {0}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>N1  -  N-2</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width: 100,
                    render: (_, record) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                        </div>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    key:'taux',
                    width: 70,
                    render: (_, record) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(0).toLocaleString('fr') || 0}%
                        </div>
                    ),
                }
            ],
        },
        {
            title: () => <TableTitle>COMMENTAIRE</TableTitle>,
            dataIndex: 'commentaire',
            width: 400,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "12px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le commentaire..."
                        value={record.commentaire}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        onChange={(e) => {
                            const updatedList = actifHistory.map((item, i) =>
                                i === index ? { ...item,  commentaire: (e.target.value)} : item
                            );

                            setActifHistory(updatedList)
                            setIsTaskSave(prevState => [...prevState, 1]);
                        }}
                    />
                </div>
            ),
        },
    ];

    const columnsPassif = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Référence</TableTitle>,
            dataIndex: 'ref',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.compte}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 400,
            render: (_, record) => (
                <>
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                     {record.libelle}
                </span>
                </>
            ),
            responsive: ['sm']
        },
        {
            title: () => <TableTitle>{`Exercice ${mission.annee || "N"}`}</TableTitle>,
            dataIndex: 'exerciceN',
            width: 100,
            render: (_, record) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {record.soldeN && record.soldeN.toLocaleString('fr')}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>N  -  N-1</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width:100,
                    render: (_, record) => (
                        <>
                            <div
                                className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                                style={{fontSize: '14px'}}
                            >
                                {(record.soldeN - record.soldeN_1).toLocaleString('fr')}
                            </div>
                        </>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    width:70,
                    key:'taux',
                    render: (_, record) => (
                        <div
                            className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                            style={{fontSize: '14px'}}
                        >
                            {( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                        </div>
                    ),
                }
            ],
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exercice_N_1',
            width:100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 2) || "N-2"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: () => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {0}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>N1  -  N-2</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width: 100,
                    render: (_, record) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                        </div>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    key:'taux',
                    width: 70,
                    render: (_, record) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(0).toLocaleString('fr') || 0}%
                        </div>
                    ),
                }
            ],
        },
        {
            title: () => <TableTitle>COMMENTAIRE</TableTitle>,
            dataIndex: 'commentaire',
            width: 400,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "12px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le commentaire..."
                        value={record.commentaire}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        onChange={(e) => {
                            const updatedList = passifHistory.map((item, i) =>
                                i === index ? { ...item,  commentaire: (e.target.value)} : item
                            );

                            setPassifHistory(updatedList)
                            setIsTaskSave(prevState => [...prevState, 1]);
                        }}
                    />
                </div>
            ),
        },
    ];

    const columnsResultats = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Référence</TableTitle>,
            dataIndex: 'ref',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.compte}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 400,
            render: (_, record) => (
                <>
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                     {record.libelle}
                </span>
                </>
            ),
            responsive: ['sm']
        },
        {
            title: () => <TableTitle>{`Exercice ${mission.annee || "N"}`}</TableTitle>,
            dataIndex: 'exerciceN',
            width: 100,
            render: (_, record) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {record.soldeN && record.soldeN.toLocaleString('fr')}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>N  -  N-1</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width:100,
                    render: (_, record) => (
                        <>
                            <div
                                className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                                style={{fontSize: '14px'}}
                            >
                                {(record.soldeN - record.soldeN_1).toLocaleString('fr')}
                            </div>
                        </>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    width:70,
                    key:'taux',
                    render: (_, record) => (
                        <div
                            className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                            style={{fontSize: '14px'}}
                        >
                            {( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                        </div>
                    ),
                }
            ],
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exercice_N_1',
            width:100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 2) || "N-2"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: () => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {0}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>N1  -  N-2</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width: 100,
                    render: (_, record) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                        </div>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    key:'taux',
                    width: 70,
                    render: (_, record) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(0).toLocaleString('fr') || 0}%
                        </div>
                    ),
                }
            ],
        },
        {
            title: () => <TableTitle>COMMENTAIRE</TableTitle>,
            dataIndex: 'commentaire',
            width: 400,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "12px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le commentaire..."
                        value={record.commentaire}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        onChange={(e) => {
                            const updatedList = resultHistory.map((item, i) =>
                                i === index ? { ...item,  commentaire: (e.target.value)} : item
                            );

                            setResultHistory(updatedList)
                            setIsTaskSave(prevState => [...prevState, 1]);
                        }}
                    />
                </div>
            ),
        },
    ];

    const columnsSIG = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Référence</TableTitle>,
            dataIndex: 'ref',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.compte}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 400,
            render: (_, record) => (
                <>
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                     {record.libelle}
                </span>
                </>
            ),
            responsive: ['sm']
        },
        {
            title: () => <TableTitle>{`Exercice ${mission.annee || "N"}`}</TableTitle>,
            dataIndex: 'exerciceN',
            width: 100,
            render: (_, record) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {record.soldeN && record.soldeN.toLocaleString('fr')}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>N  -  N-1</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width:100,
                    render: (_, record) => (
                        <>
                            <div
                                className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                                style={{fontSize: '14px'}}
                            >
                                {(record.soldeN - record.soldeN_1).toLocaleString('fr')}
                            </div>
                        </>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    width:70,
                    key:'taux',
                    render: (_, record) => (
                        <div
                            className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                            style={{fontSize: '14px'}}
                        >
                            {( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                        </div>
                    ),
                }
            ],
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'exercice_N_1',
            width:100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 2) || "N-2"} `}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: () => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {0}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>N1  -  N-2</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex:'variation',
                    key:'variation',
                    width: 100,
                    render: (_, record) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                        </div>
                    ),
                },
                {
                    title: () => <TableTitle>TAUX</TableTitle>,
                    dataIndex:'Taux',
                    key:'taux',
                    width: 70,
                    render: (_, record) => (
                        <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                            {(0).toLocaleString('fr') || 0}%
                        </div>
                    ),
                }
            ],
        },
        {
            title: () => <TableTitle>COMMENTAIRE</TableTitle>,
            dataIndex: 'commentaire',
            width: 400,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "12px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le commentaire..."
                        value={record.commentaire}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        onChange={(e) => {
                            const updatedList = sigTabHistory.map((item, i) =>
                                i === index ? { ...item,  commentaire: (e.target.value)} : item
                            );

                            setSigTabHistory(updatedList)
                            setIsTaskSave(prevState => [...prevState, 1]);
                        }}
                    />
                </div>
            ),
        },
    ];

    return (
        <>
            <div className="py-2">
                <div className="d-flex justify-content-end align-items-end">
                    <Tooltip title="Résultats">
                        <Button
                            disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            size={"large"}
                            // loading={loading}
                            style={{
                                backgroundColor: appTheme.colors.primary,
                                color: "white"
                            }}
                            icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                            onClick={handleSubmit}
                        >
                            {!chargement &&
                                <span className='indicator-label text-white'>Enregistrer</span>}
                            {chargement && (
                                <span className='indicator-progress fs-9 text-white'
                                      style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                    </Tooltip>
                </div>
                <div className="flex-lg-row-fluid">
                    <div className='d-flex flex-column align-center'>
                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-3">
                            {(MenuTabs ?? []).map((menu, index) => (
                                <li key={index} className="nav-item">
                                    <button
                                        style={{fontWeight: "600", fontFamily: "Oswald"}}
                                        className={`nav-link text-active-primary text-uppercase pb-4 fs-6 ${activeTab === (index + 1) ? 'active' : ''}`}
                                        onClick={() => handleTabChange(index + 1)}
                                    >
                                        {menu}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <form className="stepper-form">
                        <div className="tab-content" id="myTabContent">
                            {/* begin::Tab pane */}
                            <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`} role="tabpanel">
                                {/* begin::Products */}
                                <div className="mb-10">
                                    <div className="table-responsive">
                                        <Table
                                            rowClassName={() =>
                                                `h-3px p-0 m-0 lh-0.5`
                                            }
                                            columns={columnsActifs}
                                            rowKey={(record, index) => index}
                                            dataSource={(actifHistory ?? [])}
                                            pagination={tableParams.pagination}
                                            scroll={{x: 1300}}                                            onChange={handleTableChange}
                                            loading={chargement || loading}
                                            rowSelection
                                            size={"small"}
                                        />
                                    </div>
                                </div>
                                {/* end::Products */}
                            </div>
                            <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`} role="tabpanel">
                                {/* begin::Products */}
                                <div className="mb-10">
                                    <div className="table-responsive">
                                        <Table
                                            rowClassName={() =>
                                                `h-3px p-0 m-0 lh-0.5`
                                            }
                                            columns={columnsPassif}
                                            rowKey={(record, index) => index}
                                            dataSource={passifHistory}
                                            pagination={tableParams.pagination}
                                            scroll={{x: 1300}}                                            onChange={handleTableChange}
                                            loading={chargement || loading}
                                            rowSelection
                                            size={"small"}
                                        />
                                    </div>
                                </div>
                                {/* end::Products */}
                            </div>
                            <div className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`} role="tabpanel">
                                {/* begin::Products */}
                                <div className="mb-10">
                                    <div className="table-responsive">
                                        <Table
                                            rowClassName={() =>
                                                `h-3px p-0 m-0 lh-0.5`
                                            }
                                            columns={columnsResultats}
                                            rowKey={(record, index) => index}
                                            dataSource={resultHistory}
                                            pagination={tableParams.pagination}
                                            scroll={{x: 1300}}                                            onChange={handleTableChange}
                                            loading={chargement || loading}
                                            rowSelection
                                            size={"small"}
                                        />
                                    </div>
                                </div>
                                {/* end::Products */}
                            </div>
                            <div className={`tab-pane fade ${activeTab === 4 ? 'show active' : ''}`} role="tabpanel">
                                <div className="mb-10">
                                    <div className="table-responsive">
                                        <Table
                                            rowClassName={() =>
                                                `h-3px p-0 m-0 lh-0.5`
                                            }
                                            columns={columnsSIG}
                                            rowKey={(record, index) => index}
                                            dataSource={sigTabHistory}
                                            pagination={tableParams.pagination}
                                            scroll={{x: 1300}}                                            onChange={handleTableChange}
                                            loading={chargement || loading}
                                            rowSelection
                                            size={"small"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export { HistoriqueExamenAnalytique };