import axios from 'axios'
import {
    AJOUTER_PROFIL,
    LISTE_PROFIL, LISTE_ROLE_INTERVENTION,
    MODIFIER_ETAT_PROFIL,
    MODIFIER_PROFIL,
    SUPPRIMER_PROFIL
} from "../../api/apiRoutes";
import {setAuthorizationHeader} from "../../api/config";


export const addRole = async (data) => {
    const token = JSON.parse(localStorage.getItem('accessToken'))
    axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
    };
    return axios
        .post(AJOUTER_PROFIL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const updateRole = async (data) => {
    const token = JSON.parse(localStorage.getItem('accessToken'))
    axios.defaults.headers.common = {
        Authorization: `Bearer ${token}`,
    };
    return axios
        .post(MODIFIER_PROFIL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const getListeRole = async () => {
    setAuthorizationHeader()
    return axios
        .get(LISTE_ROLE_INTERVENTION)
        .then((res) => {
            if (res.status === 200) {
                return res.data.result
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const deleteRole = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(SUPPRIMER_PROFIL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}

export const updateEtatRole = async (data) => {
    setAuthorizationHeader()
    return axios
        .post(MODIFIER_ETAT_PROFIL, data)
        .then((res) => {
            if (res.status === 200) {
                return res.data
            } else {
                return {
                    status: 'error',
                    message: 'La connexion à internet est instable !',
                }
            }
        })
        .catch((err) => console.log(err))
}