import { AuditOutlined, FileDoneOutlined, FolderOpenOutlined, FolderOutlined, ReconciliationOutlined } from '@ant-design/icons';
import { CircularProgressbar } from 'react-circular-progressbar';
import {getListeExercice, getListeUserMission} from "../../data/exercice";
import React, {useEffect, useState} from "react";
import {getListeClients} from "../../data/client";
import {Link} from "react-router-dom";
import {getClientAbonnement, getClientDashboard} from "../../data/bogClient";
import moment from "moment";
import {getUsers} from "../../data/user";
import {useSelector} from "react-redux";

const DashBoardPage = () => {
    const user = useSelector(state => state.user)
    const [clients, setClients] = useState([]);
    const [clientBog, setBogClient] = useState(null);
    const [abonnement, setAbonnement] = useState(null);
    const [clientsMission, setClientsMission] = useState([]);
    const [exercices, setExercices] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const [users, setUsers] = useState([]);
    const [usersMission, setUsersMission] = useState([]);
    const isAdmin = user.libelleProfil === "Admin"

    console.log("user", user);

    useEffect(() => {
        fetchClients();
        fetchUsers();
    }, []);

    useEffect(() => {
        fetchClientExercice();
    }, [clients]);

    useEffect(() => {
       if(user){
           fetchUserMission(user.id);
       }
    }, [user]);

    useEffect(() => {
        getClientDashboard()
            .then((res) => {
                if(res.result){
                    console.log("Abonnement", res.result)
                }
            })
            .catch((error) => {
                console.error("Erreur lors de la récupération de l'abonnement du client :", error);
            });
    }, []);

    const fetchUsers = () => {
        getUsers()
            .then((res) => {
                setUsers(res);
            });
    };

    const fetchClients = () => {
        getListeClients()
            .then((res) => {
                if(res.result && res.result.length > 0){
                    setClients(res.result);
                    setBogClient(res.result[0].client_bog_id)
                }
            });
    };

    const fetchUserMission = (id) => {
        getListeUserMission(id)
            .then((res) => {
                if(res){
                    setUsersMission(res)
                }
            });
    };

    const fetchClientExercice = () => {
        getListeExercice()
            .then((res) => {
                if(res){
                    const used = res.filter((exercice) => exercice.isUsed === 1)

                    const color = ['primary', 'danger', 'warning', 'success']
                    used.map((item, index) => {
                        item.color = color[index] ? color[index] : ""
                        item.client = clients.find((opt) => opt.id === used[0].client_id);
                        item.chef = users.find((user) => user.id === item.chefMission)
                        item.isVisble = usersMission.some((mission) => mission.exercice_id === item.id && user.id === mission.membre_id )
                    })

                    const missions = isAdmin ? used : used.filter((opt) => opt.isVisble === true)

                    setExercices(missions)
                    let newArray = missions.slice(0,4)

                    setClientsMission(newArray)
                }
            });
    };
    useEffect(() => {
        if (clientBog) {
            getClientAbonnement(clientBog)
                .then((res) => {
                    if(res.result){
                        setAbonnement(res.result);

                        const debutAbonnement = moment(res.result.date_debut_abon);
                        const finAbonnement = moment(res.result.date_fin_abon);
                        const pourcentage = Math.max(0, Math.min(100,
                            ((moment() - debutAbonnement) / (finAbonnement - debutAbonnement)) * 100
                        ));

                        setPercentage(parseInt(pourcentage));
                    }
                })
                .catch((error) => {
                    console.error("Erreur lors de la récupération de l'abonnement du client :", error);
                });
        }
    }, [clientBog]);




    return (
        <>

            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div id="kt_content_container" className="container-xxl">


                    <div className="row g-5 g-xl-12">
                        <div className="col-xl-3">

                            <a href="#" className="card bg-warning hoverable card-xl-stretch mb-xl-8">

                                <div className="card-body">

                                    <FileDoneOutlined style={{ fontSize: '4rem', color: '#fff' }} />

                                    <div className="text-white fw-bolder fs-2 mb-2 mt-5">Exercices Terminés</div>
                                    <div className="fw-bold text-white">0 Exercices Terminés</div>
                                </div>

                            </a>

                        </div>
                        <div className="col-xl-3">

                            <a href="#" className="card bg-danger hoverable card-xl-stretch mb-xl-8">

                                <div className="card-body">
                                    <ReconciliationOutlined style={{fontSize: '4rem', color: '#fff'}} />
                                    <div className="text-white fw-bolder fs-2 mb-2 mt-5">Exercices en cours</div>
                                    {exercices.length > 0 ?
                                        <div className="fw-bold text-white">{`${exercices?.length} Exercices en cours de traitements`}</div>
                                        :
                                        ""
                                    }
                                </div>

                            </a>

                        </div>
                        <div className="col-xl-3">

                            <Link to="/clients/liste" className="card bg-primary hoverable card-xl-stretch mb-xl-8">

                                <div className="card-body">

                                    <AuditOutlined style={{ fontSize: '4rem', color: '#fff' }} />


                                    <div className="text-white fw-bolder fs-2 mb-2 mt-5">Mes Clients</div>
                                    <div className="fw-bold text-white">{`${clients.length} Clients enregistrés`}</div>
                                </div>

                            </Link>

                        </div>
                        <div className="col-xl-3">

                            <a href="#" className="card bg-success hoverable card-xl-stretch mb-5 mb-xl-8">

                                <div className="card-body">

                                    <FolderOutlined style={{ fontSize: '4rem', color: '#fff' }} />

                                    <div className="text-white fw-bolder fs-2 mb-2 mt-5">Mes Dossiers</div>
                                    {exercices.length > 0 ?
                                        <div className="fw-bold text-white">{`${exercices?.length}`}</div>
                                        :
                                        ""
                                    }
                                </div>

                            </a>

                        </div>
                    </div>

                    <div className="row gy-5 g-xl-8">
                        {isAdmin && <div className="col-xl-4">
                            <div className="card card-xl-stretch">

                                <div className="card-header border-0 py-5">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bolder fs-3 mb-1">Abonnement</span>
                                        <span className="text-muted fw-bold fs-7">Progression de votre abonnement</span>
                                    </h3>
                                    <div className="card-toolbar" style={{margin: 'auto'}}>
                                        <CircularProgressbar value={percentage} text={`${percentage}%`}/>
                                    </div>
                                </div>


                                <div className="card-body d-flex flex-column">
                                    <div className="pt-5">
                                        <p className="text-center fs-6 pb-5">
                                            <span className="badge badge-light-danger fs-8">Notes:</span>&#160; Votre
                                            abonnement sera bientôt épuisé.
                                            <br/>Cliquez ici pour faire une demande de réabonnement.</p>
                                        <a href="#" className="btn btn-primary w-100 py-3">Demande de réabonnement</a>
                                    </div>
                                </div>

                            </div>

                        </div>}


                        <div className="col-xxl-8">

                            <div className="card card-xxl-stretch mb-5 mb-xl-8">

                                <div className="card-header border-0 pt-5">
                                    <h3 className="card-title align-items-start flex-column">
                                        <span className="card-label fw-bolder fs-3 mb-1">Dossiers en cours</span>
                                        <span className="text-muted mt-1 fw-bold fs-7">Etat des dossiers en cours</span>
                                    </h3>
                                </div>
                                <div className="card-body py-3">
                                    <div className="table-responsive">
                                        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                            <thead>
                                            <tr className="fw-bolder text-muted">

                                                <th className="min-w-150px">Exercice</th>
                                                <th className="min-w-140px">Chef de mission</th>
                                                <th className="min-w-120px">Progression</th>
                                                <th className="min-w-100px text-end">Actions</th>
                                            </tr>
                                            </thead>


                                            <tbody>
                                            {clientsMission.length > 0 &&  clientsMission.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="symbol symbol-45px me-5">
                                                                <FolderOpenOutlined
                                                                    style={{fontSize: '3rem', color: 'rgb(250, 152, 28)'}}/>
                                                            </div>
                                                            <div className="d-flex justify-content-start flex-column">
                                                                <Link className="text-gray-800 text-hover-primary"
                                                                      to={`/clients/mission/exploitation/${item.id}`}
                                                                      state={{record: item?.client, mission: item}}
                                                                >
                                                                    <span className="text-dark fw-bolder text-hover-primary fs-6">{item.mission}</span>
                                                                    <span className="text-muted fw-bold text-muted d-block fs-7">{item.denomination}</span>
                                                                </Link>

                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span className="text-dark fw-bolder text-hover-primary d-block fs-6">
                                                         {item.chef ? `${item.chef?.nom_user} ${item.chef?.prenom_user && item.chef?.prenom_user}` : ""}
                                                        </span>
                                                        {/* <span className="text-muted fw-bold text-muted d-block fs-7">Web, UI/UX Design</span> */}
                                                    </td>
                                                    <td className="text-end">
                                                        <div className="d-flex flex-column w-100 me-2">
                                                            <div className="d-flex flex-stack mb-2">
                                                                <span className="text-muted me-2 fs-7 fw-bold">10%</span>
                                                            </div>
                                                            <div className="progress h-6px w-100">
                                                                <div className={`progress-bar bg-${item.color}`}
                                                                     role="progressbar" style={{width: " 10%"}}
                                                                     aria-valuenow="50" aria-valuemin="0"
                                                                     aria-valuemax="100"></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex justify-content-end flex-shrink-0">
                                                            <a href="#"
                                                               className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">

                                                                    <span className="svg-icon svg-icon-3">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                                             height="24" viewBox="0 0 24 24" fill="none">
                                                                            <path
                                                                                d="M17.5 11H6.5C4 11 2 9 2 6.5C2 4 4 2 6.5 2H17.5C20 2 22 4 22 6.5C22 9 20 11 17.5 11ZM15 6.5C15 7.9 16.1 9 17.5 9C18.9 9 20 7.9 20 6.5C20 5.1 18.9 4 17.5 4C16.1 4 15 5.1 15 6.5Z"
                                                                                fill="black"/>
                                                                            <path opacity="0.3"
                                                                                  d="M17.5 22H6.5C4 22 2 20 2 17.5C2 15 4 13 6.5 13H17.5C20 13 22 15 22 17.5C22 20 20 22 17.5 22ZM4 17.5C4 18.9 5.1 20 6.5 20C7.9 20 9 18.9 9 17.5C9 16.1 7.9 15 6.5 15C5.1 15 4 16.1 4 17.5Z"
                                                                                  fill="black"/>
                                                                        </svg>
                                                                    </span>

                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashBoardPage;