import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {FloatButton, notification, Tooltip} from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import HeaderBar from "../components/header/HeaderBar";
import {MainLayoutContentWrapper} from "./layouts.components";
import {storeUserData} from "../store/user/actions";
import axios from "axios";
import InactivityLogoutTimer from "../config/InactivityLogoutTimer";
const MainLayout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(storeUserData({}, ''))
        localStorage.removeItem('user')
        localStorage.removeItem('accessToken')
        axios.defaults.headers.common = {
            Authorization: ``,
        };
        notification.info({ description: "Vous avez été déconnecté" });
        navigate('/')
    }

    return (
        <MainLayoutContentWrapper
            style={{ maxHeight: "100vh", position: "relative" }}
        >
            <HeaderBar />
            <Outlet />
            <InactivityLogoutTimer/>
            <FloatButton.Group shape="square" style={{ right: 25 }}>
                <Tooltip title="Déconnexion" placement="left">
                    <FloatButton
                        type="primary"
                        icon={<FontAwesomeIcon icon={faSignOut} />}
                        onClick={() => handleLogout()}
                    />
                </Tooltip>
            </FloatButton.Group>
        </MainLayoutContentWrapper>
    );
};

export default MainLayout;