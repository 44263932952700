import React, {useEffect, useState} from 'react';
import {getListePlanComptable} from "../../../../../data/params/planComptable";
import {Balance} from "./EC2/Balance";
import {GrandLivre} from "./EC2/GrandLivre";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {
    faBookOpenReader,
    faIdCard,
    faScaleBalanced,
} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import UserCycleIdentification from "../../UserCycleIdentification";

const ImportationBalance = ({
        mission,
        client,
        code,
        // modalId,
        prev
    }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [plans, setPlans] = useState([])
    const [balanceData, setBalancesData] = useState([])
    const [livreData, setLivreData] = useState([])
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [loading, setLoading] = useState(false);
    const [auth, setAuth] = useState(false);

    const codeSommaire = code || 'EC2.1'

    const fetchPlanComptable = () => {
        getListePlanComptable()
            .then((res) => {
                setPlans(res.result);
            });
    };

    useEffect(() => {
        fetchPlanComptable();
    }, []);

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATION UTILISATEUR",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "GL",
            title: `GRAND LIVRE - ${selectYear}`,
            icon: faBookOpenReader,
            content: (
                <GrandLivre
                    livreData={livreData}
                    client={client}
                    setLivreData={setLivreData}
                    isProvisoire={isProvisoire}
                    missionSelected={missionSelected}
                    plans={plans}
                    intervention_id={intervention_id}
                    date={date}
                    heureEntree={heureEntree}
                    tache_id={tache_id}
                    user_id={user_id}
                    chargement={loading}
                    codeSommaire={codeSommaire}
                />
            ),
        },
        {
            code: "BL",
            title: `BALANCE - ${selectYear}`,
            icon: faScaleBalanced,
            content: (
                <Balance
                    balanceData={balanceData}
                    client={client}
                    setBalancesData={setBalancesData}
                    isProvisoire={isProvisoire}
                    missionSelected={missionSelected}
                    plans={plans}
                    livreData={livreData}
                    intervention_id={intervention_id}
                    date={date}
                    heureEntree={heureEntree}
                    tache_id={tache_id}
                    user_id={user_id}
                    chargement={loading}
                    codeSommaire={codeSommaire}
                />
            ),
        },
    ];

    return (
        <>
            <div className="">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EC2.1 – Importation des balances N et N-1"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>{formSteps[activeTab].content}</StepBody>
            </div>
        </>
    );
};


export default ImportationBalance;
