import React from "react";

const PageHeader = ({tache_id, intervention_id, user, isProvisoire}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '5px',
    };

    return (
        <div className="">
            <table style={tableStyle}>
                <thead>
                    <tr style={thStyle}>
                        <th style={{fontSize: "7px", textDecoration: 'underline'}}>Traitement Préliminaire</th>
                        <th style={{fontSize: "7px", textDecoration: 'underline'}}>Supervision Préliminaire</th>
                        <th style={{fontSize: "7px", textDecoration: 'underline'}}>Revue Associé Préliminaire</th>
                        <th style={{fontSize: "7px", textDecoration: 'underline'}}>Contrôle qualité Préliminaire</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={tdStyle}>
                        <td style={tdStyle}>
                        </td>
                        <td style={tdStyle}>
                        </td>
                        <td style={tdStyle}>

                        </td>
                        <td style={tdStyle}>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table style={tableStyle}>
                <thead>
                    <tr style={thStyle}>
                        <th style={{fontSize: "7px", textDecoration: 'underline'}}>Traitement Final</th>
                        <th style={{fontSize: "7px", textDecoration: 'underline'}}>Supervision Final</th>
                        <th style={{fontSize: "7px", textDecoration: 'underline'}}>Revue Associé Final</th>
                        <th style={{fontSize: "7px", textDecoration: 'underline'}}>Contrôle qualité Final</th>
                    </tr>
                </thead>
                <tbody>
                    <tr style={tdStyle}>
                        <td style={tdStyle}>
                        </td>
                        <td style={tdStyle}>
                        </td>
                        <td style={tdStyle}>
                        </td>
                        <td style={tdStyle}>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const PrintHeaderTraitement = ({title, intervention_id, tache_id, user, client, exercice, isProvisoire}) => {

  return(
      <>
          <div >
              <div style={{padding: "5px 10px"}}>
                  <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                      <div style={{display: "flex", flexDirection: "column"}}>
                          <div style={{display: "block"}}>
                              <span style={{fontSize: "12px", textDecoration: "underline", marginRight: "10px"}}>
                                  DOSSIER
                              </span>
                              <span style={{fontSize: "14px", fontWeight: "700", textTransform: "uppercase"}}>
                                  {client?.sigle || ""}
                              </span>
                          </div>
                          <div style={{display: "block"}}>
                              <span style={{fontSize: "12px", textDecoration: "underline", marginRight: "10px"}}>
                                  EXERCICE
                              </span>
                              <span style={{fontSize: "14px", fontWeight: "700", textTransform: "uppercase"}}>
                                  {`${exercice?.mission || ""} - ${exercice?.annee}`}
                              </span>
                          </div>
                      </div>
                      <PageHeader
                          intervention_id={intervention_id}
                          tache_id={tache_id}
                          user={user}
                          isProvisoire={parseInt(isProvisoire) === 1}
                      />
                  </div>
              </div>
              <div style={{marginTop: "25px", marginBottom: "20px"}}>
                  <h1 style={{fontSize: "18px", textAlign: "center"}}><strong>{title}</strong></h1>
              </div>
          </div>
      </>
  )
}

export {PrintHeaderTraitement}