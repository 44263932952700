import React, {useEffect, useState} from "react";
import {
    FileTextTwoTone,
    FolderFilled,
    FolderOpenFilled,
} from "@ant-design/icons";
import {Link, useLocation} from "react-router-dom";
import TreeComponentsDossierPermanent from "../../../../layout/tools/TreeComponentsDossierPermanent";
import {Divider, Select} from "antd";
import DossierPermanentModal from "./DossierPermanentModal";
import PageLabel from "../../../../common/PageLabel/PageLabel";
import {ModalTitle} from "../../../../modals/modals.components";
import ModalAlert from "../../../../modals/Modal";
import FormLabel from "../../../../common/Form/FormLabel";
import {SyntheseIdentification} from "../ED/SyntheseIdentification";
import {SyntheseConceptionControles} from "../EE1/SyntheseConceptionControles";
import {getListeExerciceClients} from "../../../../../data/exercice";
import {areArraysEqual} from "../../../../utilities/Utilities";

const sommaires = [
    {
        code: "PA",
        libelle: "PA – GENERALITES",
        description: "",
        children: [
            {
                code: "PA1",
                libelle: "PA1 – Fiche Signalétique",
                description: "",
                isLink: true
            },
            {
                code: "PA2",
                libelle: "PA2 – Autres documents (Extrait journal, Manuel procédures………..)",
                description: "",
            },
        ]
    },
    {
        code: "PB",
        libelle: "PB – EVALUATION ET APPRECIATION DU CONTROLE INTERNE",
        description: "",
        children: [
            {
                code: "PB1",
                libelle: "PB1- Synthèse des risques identifiés et évalués",
                description: "",
            },
            {
                code: "PB2",
                libelle: "PB2 – Synthèse de l’identification et de l’évaluation de la conception et du fonctionnement du système de contrôle",
                description: "",
            },
        ]
    },
    {
        code: "PC",
        libelle: "PC – RAPPORTS",
        description: "",
        children: [
            {
                code: "PC1",
                libelle: "PC1 – Rapport au CA ou à l’Administrateur Général",
                description: "",
            },
            {
                code: "PC2",
                libelle: "PC2 – Rapport général et spécial du CAC",
                description: "",
            },
            {
                code: "PC3",
                libelle: "PC3 – Rapport sur le contrôle interne ou Lettre à la direction",
                description: "",
            },
            {
                code: "PC4",
                libelle: "PC4 – Rapport spécial du CAC",
                description: "",
            },
            {
                code: "PC5",
                libelle: "PC5 – Rapport spécial du CAC sur le registre des titres nominatifs",
                description: "",
            },
            {
                code: "PC6",
                libelle: "PC6 – Autres rapports",
                description: "",
            },
        ]
    },
    {
        code: "PD",
        libelle: "PD – DOCUMENTATION FISCALE ET SOCIALE",
        description: "",
        children: [
            {
                code: "PD1",
                libelle: "PD1 - Documentation Fiscale",
                description: "",
                children: [
                    {
                        code: "PD1.1",
                        libelle: "PD1.1 – Déclaration Fiscale d’Existence (DFE)",
                        description: "",
                    },
                    {
                        code: "PD1.2",
                        libelle: "PD1.2 – Documentation Fiscale",
                        description: "",
                    },
                ]
            },
            {
                code: "PD2",
                libelle: "PD2 - Documentation Sociale",
                description: "",
                children: [
                    {
                        code: "PD2.1",
                        libelle: "PD2.1 – Immatriculation à la CNPS",
                        description: "",
                    },
                    {
                        code: "PD2.2",
                        libelle: "PD2.2 – Liste des délégués du personnel",
                        description: "",
                    },
                    {
                        code: "PD2.3",
                        libelle: "PD2.3 – Règlement intérieur",
                        description: "",
                    },
                    {
                        code: "PD2.4",
                        libelle: "PD2.4 – Documentation sociale",
                        description: "",
                    },
                ]
            },
        ]
    },
    {
        code: "PE",
        libelle: "PE – DOCUMENTATION JURIDIQUE",
        description: "",
        children: [
            {
                code: "PE1",
                libelle: "PE1 – Statuts ou extrait des statuts",
                description: "",
            },
            {
                code: "PE2",
                libelle: "PE2 – RCCM",
                description: "",
            },
            {
                code: "PE3",
                libelle: "PE3 – Liste des associés ou actionnaires ",
                description: "",
            },
            {
                code: "PE4",
                libelle: "PE4 – Agrément ou autorisation d’exercer (si secteur d’activité réglementé)",
                description: "",
            },
            {
                code: "PE5",
                libelle: "PE5 – Extrait des PV du Conseil d’Administration (CA) ou de l’Administrateur Général ",
                description: "",
            },
            {
                code: "PE6",
                libelle: "PE6 – Extrait des PV des Assemblées",
                description: "",
            },
            {
                code: "PE7",
                libelle: "PE7 – Tableau des membres des organes de direction (Gérant, CA) et des CAC",
                description: "",
            },
            {
                code: "PE8",
                libelle: "PE8 – Conventions réglementées ",
                description: "",
            },
            {
                code: "PE9",
                libelle: "PE9 – Brevets, licences",
                description: "",
            },
            {
                code: "PE10",
                libelle: "PE10 – Baux et avenants",
                description: "",
            },
            {
                code: "PE11",
                libelle: "PE11 – Polices d’assurances",
                description: "",
            },
            {
                code: "PE12",
                libelle: "PE12 – Autres contrats, conventions",
                description: "",
            },
            {
                code: "PE13",
                libelle: "PE13 – Autres documentations à spécifier",
                description: "",
            },
        ]
    },
]

const DossierPermanentMenu = ({modalClient}) => {
    const location = useLocation();
    // const navigate = useNavigate();

    const locationClient = location.state.record;

    const [loading, setLoading] = useState(null);
    const [selectedNode, setSelectedNode] = useState(null);
    const [client, setClient] = useState(null);
    const [selectCode, setSelectCode] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mission, setMission] = useState(null);
    const [missions, setMissions] = useState([]);
    const [arrayYear, setArrayYear] = useState([]);
    const [filteredMissions, setFilteredMissions] = useState([]);
    const [selectYear, setSelectYear] = useState("");

    useEffect(() => {
        if(modalClient){
            setClient(modalClient)
        }
        else {
            setClient(locationClient)
        }
    }, [modalClient, locationClient])

    useEffect(() => {
        if(client !== null){
            getListeExerciceClients(client?.id)
                .then((res) => {
                    setMissions(res);
                    const years = [...new Set(res.map((item) => item.annee))];
                    const yearsFiltered  = years.sort((a, b) => parseInt(b) - parseInt(a));

                    setArrayYear(yearsFiltered);
                });
        }
    }, [client]);

    useEffect(()=>{
        if((filteredMissions && filteredMissions.length <= 0) && selectYear){
            const miss = missions.filter((item) => parseInt(item.annee) === parseInt(selectYear));
            if(miss && miss.length > 0) {
                if (!areArraysEqual(miss, filteredMissions)) {
                    setFilteredMissions(miss);
                    setMission(miss[0])
                }
            }else{
                setFilteredMissions([])
            }
        }
    }, [filteredMissions, missions, selectYear, setMission])

    const onChangeFiltered = (values) => {
        setLoading(true);
        setTimeout(() => {
            setSelectYear(values)

            const miss = missions.filter((item) => parseInt(item.annee) === parseInt(values));
            if(miss && miss.length > 0) {
                setFilteredMissions(miss)
                setMission(miss[0])
            }else{
                setFilteredMissions([])
            }
            setLoading(false)
        }, 1000);
    }

    const onChangeMission = (values) => {
        setLoading(true);
        setTimeout(() => {
            const miss = missions.find((item) => item.id === parseInt(values));
            if(miss){
                setMission(miss);
            }else {
                setMission(mission);
            }
            setLoading(false)
        }, 1000)
    }

    const handleOpenModal = (code) => {
        setIsModalOpen((state) => !state);
        setSelectCode(code);
        setSelectYear("");
        setMission(null);
    };

    const handleNodeClick = (node) => {
        setSelectedNode(node);
        if (node.isLeaf && node.onClick) {
            node.onClick();
        }
    };

    const treeData = sommaires.map((sommaire, index) => ({
        key: index,
        title: sommaire.libelle,
        description: sommaire.description,
        icon: selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
        children: sommaire.children.length > 0 && sommaire.children?.map((item, indexChild) => ({
            key: `${index}-${indexChild}`,
            title: !item.isLink ? item.libelle :
                <Link style={{marginRight: '10px'}} to={`/clients/details/${client?.id}`} state={{client: client, link: 0}}>
                    {item.libelle}
                </Link>
                ,
            description: item.description,
            icon: !item.children ? <FileTextTwoTone style={{color: '#009ef7'}}/> : selectedNode ? <FolderOpenFilled style={{color: "yellow", fontSize: '30px'}}/> :  <FolderFilled style={{color: "yellow", fontSize: '30px'}}/>,
            onClick: () => {
                if (item.isLink) {
                    // Code à exécuter si item.isLink est vrai
                } else if (!item.children) {
                    handleOpenModal(item);
                }
            },
            isLeaf: !item.children || item.children?.length <= 0,
            children: item.children?.length > 0 && item.children?.map((opt, indexOpt) => ({
                key: `${index}-${indexChild}-${indexOpt}`,
                title: opt.libelle,
                description: opt.description,
                icon: <FileTextTwoTone style={{color: '#009ef7'}}/>,
                onClick: () => {!opt.children && handleOpenModal(opt)},
                isLeaf: true,
            })),
        })),
    }))

    const SyntheseModal = (code) => {

        return (
            <div className='w-100'>
                <div className="d-flex align-center">
                    <div className="d-flex flex-column align-center me-15 w-150px">
                        <FormLabel label="Exercice" />
                        <Select
                            placeholder="Sélectionner l'exercice"
                            onChange={onChangeFiltered}
                            value={selectYear ?? null}
                            size="large"
                            style={{
                                flex: 1,
                            }}
                            options={(arrayYear ?? []).map((year) => ({
                                    label: (<span style={{fontWeight: "700", fontFamily: "Oswald"}}>{year}</span>),
                                    value: year
                                }
                            ))}
                        />
                    </div>
                    <div className="d-flex flex-column align-center w-400px">
                        <FormLabel label="Mission" />
                        <Select
                            placeholder="Mission"
                            onChange={onChangeMission}
                            value={mission?.id || null}
                            size="large"
                            style={{
                                flex: 1,
                            }}
                            options={(filteredMissions ?? []).map((mission) => ({
                                    label: (<span style={{fontWeight: "700", fontFamily: "Oswald"}}>{`${mission.mission} - ${mission.annee}`}</span>),
                                    value: mission.id
                                }
                            ))}
                        />
                    </div>
                </div>
                <Divider/>
                {mission ?
                    (code.code === "PB1" ?
                        (<SyntheseIdentification
                            mission={mission}
                            client={client}
                            modalId={null}
                            code={"ED0"}
                            prev={handleOpenModal}
                        />)
                    :
                        (<SyntheseConceptionControles
                            mission={mission}
                            client={client}
                            modalId={null}
                            code={"EE0"}
                            prev={handleOpenModal}
                        />))
                    :
                    ""
                }
            </div>
        )
    }

  return (
      <>
          <div className="card p-9 w-100">
              <PageLabel label="SOMMAIRE LEA - DP"/>
              <div className='container'>
                  <TreeComponentsDossierPermanent data={treeData} onNodeClick={handleNodeClick} />
              </div>
          </div>
          <ModalAlert
              closeModal={handleOpenModal}
              show={isModalOpen}
              title={<ModalTitle> Dossier permanent : {selectCode?.libelle || ""} </ModalTitle>}
              footer={[]}
              modalStyles={{ top: 10 }}
              width={"100%"}
          >
              {["PB1", "PB2"].includes(selectCode?.code) ?
                  <SyntheseModal code={selectCode?.code} />
                  :
                  <DossierPermanentModal client={client} code={selectCode?.code || ""} />
              }
          </ModalAlert>
      </>
  )
}

export default DossierPermanentMenu