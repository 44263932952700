/// DEFINIR LES ACTIONS

export const actions = {
    STORE_USER_DATA: 'STORE_USER_DATA',
    STORE_USER_ACCESS_TOKEN: 'STORE_USER_ACCESS_TOKEN'
}


/// CREER LES ACTIONS

export function storeUserData(userData, accessToken) {
    return {
        type: actions.STORE_USER_DATA,
        payload: {userData, accessToken}
    }
}

export function storeUserAccessToken(accessToken) {
    return {
        type: actions.STORE_USER_ACCESS_TOKEN,
        payload: accessToken
    }
}