import React, {useEffect, useState} from "react";
import {
    ajouterFeuilleMaitresse,
     listeFeuilleMaitresse
} from "../../../../../data/balance/procedureAnalytique";
import swal from "sweetalert";
import {HistoriqueFeuilleMaitresse} from "./EC3/feuilleMaitresse/HistoriqueFeuilleMaitresse";
import TraitementExamenAnalytiqueBilan from "./EC3/examenAnalytique/TraitementExamenAnalytiqueBilan";
import { faHistory, faIdCard, faTasks} from "@fortawesome/free-solid-svg-icons";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {ExamenAnalytiqueBalancePrint} from "./EC3/examenAnalytique/ExamenAnalytiqueBalancePrint";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const ControleFeuilleMairesse = ({
         mission,
         client,
         code,
         // modalId,
         prev
    }) => {
    // const user = useSelector(state => state.user)
    const [activeTab, setActiveTab] = useState(0);
    const [balanceData, setBalanceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [codeSommaire] = useState(code || 'EC3.2');
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(mission){
            fetchFeuilleMaitresse(mission.id);
        }
    }, [mission])

    //get balance provisoire
    const fetchFeuilleMaitresse = async (id) => {
        listeFeuilleMaitresse(id)
            .then((res) => {
                if (res.result) {
                    setBalanceData(res.result.sort((a, b) => a.compte.localeCompare(b.compte)))
                } else {
                    setBalanceData([])
                }
            })
    }

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true);

        const datas = balanceData.map((item) => ({
            compte: item.compte,
            libelle: item.libelle,
            soldeN: item.soldeN,
            soldeN_1: item.soldeN_1,
            variation: item.soldeN - item.soldeN_1,
            percentVariation: (((item.soldeN_1)=== 0 ? 0 : (item.soldeN - item.soldeN_1) / item.soldeN_1 ) * 100),
            commentaire: item.commentaire,
        }))

        const data = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            codeSommaire,
            balanceData: datas,
            exercice_id: mission.id,
            client_id: client.id,
        };

        const {status, message} = await ajouterFeuilleMaitresse(data)
        if (status === 'success') {
            setLoading(false)
            setAuth(false);
            setIsTaskSave(prevState => (prevState ?? []).filter((item) => item !== activeTab));
            await swal(`Opération effectuée avec succès`, '', 'success')
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    }

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "FM",
            title: `FEUILLE MAITRESSE - ${selectYear}`,
            icon: faTasks,
            content: (
                <TraitementExamenAnalytiqueBilan
                    mission={mission}
                    client={client}
                    setBalanceData={setBalanceData}
                    balanceData={balanceData}
                    chargement={loading}
                    isProvisoire={isProvisoire}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
        {
            code: "H-FM",
            title: `HISTORIQUE FEUILLE MAITRESSE - ${selectYear}`,
            description: 'HISTORIQUE FEUILLE MAITRESSE',
            icon: faHistory,
            content: (
                <HistoriqueFeuilleMaitresse
                    balanceData={balanceData}
                    setBalanceData={setBalanceData}
                    mission={mission}
                    chargement={loading}
                    isProvisoire={isProvisoire}
                    handleSubmit={handleSubmit}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
    ];

    return (
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EC3.2 - Calcul des feuilles maitresses"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isTaskSave={isTaskSave}
                    isExcelPrint={false}
                    isLandingScape={true}
                    printTitle={(`FEUILLES MAITRESSE  ${selectYear}`)}
                    excelData={balanceData}
                    printContent={(
                        <ExamenAnalytiqueBalancePrint
                            data={balanceData}
                        />
                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>{formSteps[activeTab].content}</>
                </StepBody>
            </div>
        </>
    );
};
export {ControleFeuilleMairesse}