import styled from "styled-components";
import SimpleBar from "simplebar-react";

export const MainLayoutContentWrapper = styled(SimpleBar)`
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  /* background-color: #f50000; */
  background-color: ${(props) => props.theme.colors.bgColor};
  padding-top: 14rem;
  padding-bottom: 7rem;
  padding-left: 3rem;
  padding-right: 3rem;
`;

export const AuthLayoutContentWrapper = styled(SimpleBar)`
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  /* background-color: #f50000; */
  background-color: ${(props) => props.theme.colors.bgColor};
`;
