import { Tag } from "antd";
import styled from "styled-components";

export const TagItem = styled(Tag)`
  font-weight: bold;
`;

export const FormContainer = styled.div`
  padding: 10px 10px;
  background: #fff;
  box-shadow: 0px 0px 25px #00000020;
  margin-top: 30px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
`;