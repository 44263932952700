import {CloseSquareOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Divider, Input, notification, Row, Select, Skeleton, Space, Tooltip} from "antd";
import {addChefMission, getListeClientSommaire, getListeEquipeMission} from "../../../data/exercice";
import swal from "sweetalert";
import {getListeTacheActive} from "../../../data/params/tache";
import {getListeInterventionActive} from "../../../data/params/intervention";
import {getListeRoleMission} from "../../../data/params/RoleMission";
import {ModalTitle} from "../../modals/modals.components";
import {FormContainer} from "../../common/common.components";
import FormLabel from "../../common/Form/FormLabel";
import {appTheme} from "../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faDeleteLeft, faPersonChalkboard, faUserEdit} from "@fortawesome/free-solid-svg-icons";
import ModalAlert from "../../modals/Modal";
import useUsers from "../../../hooks/users/useUser";

const AddChefMission = ({
        client,
        exercice,
        fetchData,
        isManager,
        handleVisibility,
        visible,
    }) => {
    const users = useUsers()
    // const [roles, setRoles] = useState([])
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [loading, setLoading] = useState(false);
    const [taches, setTaches] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [chefMission, setChefMission] = useState(null);
    const [role, setRole] = useState(null);
    const [clientSommaire, setClientSommaire] = useState([]);
    const [selectedModalId, setSelectedModalId] = useState(null);
    const [sommairesLoading, setSommairesLoading] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        fetchRoles()
        fetchListeTache()
        fetchListeIntervention()
    }, [isManager]);

    useEffect(() => {
        if(exercice){
            fetchEquipes(exercice.id);
        }
    }, [exercice, isManager]);

    useEffect(() => {
        if(chefMission){
            setLoading(true)
            setTimeout(() => {
                const chef = {
                    id: chefMission.membre_id,
                    avatar: chefMission.Lea_User?.avatar,
                    codeCivilite: chefMission.Lea_User?.Civilite?.codeCivilite,
                    nom_user: chefMission.Lea_User?.nom_user,
                    prenom_user: chefMission.Lea_User?.prenom_user,
                    initial: chefMission.Lea_User?.initial,
                    telephone1_user: chefMission.Lea_User?.telephone1_user,
                    email_user: chefMission.Lea_User?.email_user,
                    profil_id: chefMission.profil_id || role.id,
                    role_libelle: chefMission.Lea_RoleMission?.libelle || role.libelle,
                    sommaires: chefMission.sommaires || [],
                    actions: chefMission.Lea_RoleMission?.actions,
                    interventions: chefMission.Lea_RoleMission?.intervention,
                }

                setSelectedUsers(chef);
                setLoading(false)
            }, 2000);
        }
    },  [chefMission, role])

    useEffect(() => {
        if(exercice){
            fetchClientSommaireExercice(exercice.id);
        }
    }, [exercice]);

    const fetchEquipes = async (id) => {
        setLoading(true);
        getListeEquipeMission(id)
            .then((res) => {
                if(res){
                    const chefMission = res.find((item) => {
                        return isManager ? (item.Lea_RoleMission?.libelle === "Manager") : (item.Lea_RoleMission?.libelle === "Chef de Mission");
                    });
                    setChefMission(chefMission);
                }
            })
            .catch((error) => {
                console.error("Erreur", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const fetchClientSommaireExercice = async (exercice_id) => {
        setSommairesLoading(true)
        getListeClientSommaire(exercice_id)
            .then((res) => {
                if(res){
                    setClientSommaire(res);
                    setSommairesLoading(false)
                }else{
                    setSommairesLoading(true)
                }
            })
            .catch((error) => {
                console.error("Erreur", error);
            });
    };

    const fetchListeTache = async () => {
        setLoading(true);
        getListeTacheActive()
            .then((res) => {
                setTaches(res.result)
                setLoading(false);
            })
            .catch((error) => {
                console.error("Erreur", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchListeIntervention = async () => {
        setLoading(true);
        getListeInterventionActive()
            .then((res) => {
                setInterventions(res.result)
                setLoading(false);
            })
            .catch((error) => {
                console.error("Erreur", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchRoles = async () => {
        getListeRoleMission()
            .then((res) => {
                if(res){
                    const isChef = res.find((item) => {
                        return isManager ? (item?.libelle === "Manager") : (item?.libelle === "Chef de Mission");
                    })
                    if(isChef){
                        setRole(isChef)
                    }
                }
            })
            .catch((error) => {
                console.error("Erreur", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onChange = (value) => {
        setLoading(true)
        setTimeout(() => {
            const userToAdd = users.users.find((item) => item.id === parseInt(value));
            if (userToAdd) {
                const tables = [...new Set(clientSommaire.map((item) => item.libelle))]

                const newElement = {
                    id: userToAdd.id,
                    avatar: userToAdd.avatar,
                    codeCivilite: userToAdd.codeCivilite,
                    nom_user: userToAdd.nom_user,
                    prenom_user: userToAdd.prenom_user,
                    email_user: userToAdd.email_user,
                    profil_id: role.id,
                    sommaires: tables,
                    role_libelle: role?.libelle,
                    actions: role.actions,
                    interventions: role.intervention,
                };
                setSelectedUsers(newElement);

                setLoading(false)
                notification.success({
                    description: "Membre ajouté avec succès",
                });
            }
            setLoading(false)
        }, 1500)
    };

    const handleSommaireValidate = () => {
        setLoading(true)
        setSommairesLoading(true)
        setTimeout(() => {
            setIsModalOpen(false)
            setLoading(false)
            setSommairesLoading(false)

            notification.success({
                description: "Sommaire ajouté avec succès",
            });
        }, 1000)
    };

    const handleCheckboxChange = (code) => {
        const isSelected = selectedUsers.sommaires.includes(code);

        if (isSelected) {
            selectedUsers.sommaires = selectedUsers.sommaires.filter((item) => !item.includes(code))
            // setSelectedUsers(updated);
        } else {
            selectedUsers.sommaires.push(code)
            // setSelectedUsers(updated);
        }
    };

    const onSelectedAll = (e) => {
        e.preventDefault()
        const tables = [...new Set(clientSommaire.map((item) => item.libelle))]
        selectedUsers.sommaires = tables
        // setSelectedUsers(updatedList);
        setSelectAll(true);
    };

    const handleDeleteAllElements = (e) => {
        e.preventDefault()

        // const updated = [...selectedUsers];
        // const updatedList = updated[indexUser].sommaires = []
        // setSelectedUsers(updated);
        selectedUsers.sommaires = [];
        setSelectAll(false);
    };

    const handleOpenModal = (modal_id) => {
        setSelectedModalId(modal_id);

        setIsModalOpen(true);
    };

    const handleSubmit = async () => {
        setLoading(true);

        // const tables = [...new Set(clientSommaire.map((item) => item.libelle))]

        const data = {
            chef_id: selectedUsers.id,
            exercice_id: exercice.id,
            sommaires: selectedUsers.sommaires,
            client_id: client.id,
        };

        const {status} = await addChefMission(data)
        if (status === 'success') {
            setLoading(false)
            await swal(`Opération effectuée avec succès`, '', 'success')
            fetchData(exercice.id)
        } else {
            setLoading(false)
        }
    }

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Modifier le {isManager ? "Manager" : "Chef"} de mission {exercice ? `de ${exercice.mission || ""} - ${exercice.annee || ""}` : ""}</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 15 }}
                width={1000}
            >
                <FormContainer>
                    <Card>
                        <Row gutter={[16, 16]}>
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 24 }}
                                xl={{ span: 24 }}
                            >
                                <FormLabel label={`Ajouter le ${isManager ? "Manager" : "Chef" } de mission`} />
                                {loading ?
                                    (<Skeleton  active avatar/>)
                                    :
                                    (
                                        <Card
                                            bordered={false}
                                            bodyStyle={{ display: "flex", flexDirection: "row" }}
                                        >
                                            <Col
                                                xs={{ span: 12 }}
                                                md={{ span: 12 }}
                                                lg={{ span: 12 }}
                                                xl={{ span: 12 }}
                                            >
                                                {/*<FormLabel label="Membre" />*/}
                                                <Select
                                                    showSearch
                                                    placeholder="Sélectionnez le membre..."
                                                    optionFilterProp="label"
                                                    filterOption={(input, option) =>
                                                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    size="large"
                                                    value={selectedUsers?.id || null}
                                                    style={{ width: "100%" }}
                                                    onChange={onChange}
                                                    options={users && users.users.map((user) => ({
                                                        value: user.id,
                                                        label: `${user.initial || ""} - ${user.nom_user.toUpperCase()} ${user.prenom_user ? user.prenom_user.toUpperCase() : ""}`
                                                    }))}
                                                />
                                            </Col>
                                            <Col
                                                xs={{ span: 8 }}
                                                md={{ span: 8 }}
                                                lg={{ span: 8 }}
                                                xl={{ span: 8 }}
                                            >
                                                {/*<FormLabel label="Rôle" />*/}
                                                <Input size={"large"} addonBefore="Rôle" value={selectedUsers?.role_libelle} readOnly />
                                            </Col>
                                            <Col
                                                xs={{ span: 4 }}
                                                md={{ span: 4 }}
                                                lg={{ span: 4 }}
                                                xl={{ span: 4 }}
                                            >
                                                <Space>
                                                    <Tooltip color={appTheme.colors.primary} title="Voir les privilèges">
                                                        <Button
                                                            shape="circle"
                                                            size={"middle"}
                                                            style={{backgroundColor: appTheme.colors.primary}}
                                                            onClick={() => handleOpenModal(2)}
                                                            icon={<FontAwesomeIcon icon={faPersonChalkboard} color={"white"}/>}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip color={appTheme.colors.primary_blue} title="Modifier les privilèges">
                                                        <Button
                                                            shape="circle"
                                                            size={"middle"}
                                                            style={{backgroundColor: appTheme.colors.primary_blue}}
                                                            onClick={() => handleOpenModal(1)}
                                                            icon={<FontAwesomeIcon icon={faUserEdit} color={"white"}/>}
                                                        />
                                                    </Tooltip>
                                                </Space>
                                            </Col>
                                        </Card>
                                    )
                                }
                            </Col>
                        </Row>

                        <Divider/>
                        <div className="d-flex justify-content-center align-center">
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 8 }}
                                lg={{ span: 6 }}
                                xl={{ span: 6 }}
                            >
                                <Button
                                    type="primary"
                                    block
                                    size="large"
                                    loading={loading}
                                    disabled={loading}
                                    onClick={handleSubmit}
                                >
                                    {!loading && <span className='indicator-label'>Enregistrer</span>}
                                    {loading && (
                                        <span className="indicator-progress text-white">
                                            Veuillez patienter...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                        </span>
                                    )}
                                </Button>
                            </Col>
                        </div>
                    </Card>
                </FormContainer>
                {(isModalOpen && selectedModalId === 1) && (
                    <div
                        className="top-0 bottom-0 z-index-9999 h-100"
                        style={{ insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
                    >
                        <div className="modal fade show" id="kt_modal_move_to_folder" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}>
                            <div className='modal-dialog modal-dialog-centered mw-600px' >
                                <div className='modal-content'>
                                    <form className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#"
                                          id="kt_modal_move_to_folder_form">
                                        {/*begin::Modal header*/}
                                        <div className="modal-header">
                                            {/*begin::Modal title*/}
                                            <h2 className="fw-bolder">Affectation du sommaire</h2>
                                            {/*end::Modal title*/}
                                            {/*begin::Close*/}
                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={() => setIsModalOpen(false)}>
                                                <span className="svg-icon svg-icon-1">
                                                  <CloseSquareOutlined style={{color: 'red'}}/>
                                                </span>
                                            </div>
                                            {/*end::Close*/}
                                        </div>
                                        {/*end::Modal header*/}
                                        {/*begin::Modal body*/}
                                        <div className="modal-body pt-10 pb-15 px-lg-17">
                                            {selectAll ?  (
                                                    <div className='py-5'>
                                                        <Button
                                                            size={"middle"}
                                                            style={{backgroundColor: appTheme.colors.danger}}
                                                            onClick={handleDeleteAllElements}
                                                            icon={<FontAwesomeIcon icon={faDeleteLeft} color={"white"}/>}
                                                        >
                                                            <span className="fw-bolder text-white">Supprimer tout</span>
                                                        </Button>
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div className='py-5'>
                                                        <Button
                                                            size={"middle"}
                                                            style={{backgroundColor: appTheme.colors.primary_blue}}
                                                            onClick={onSelectedAll}
                                                            icon={<FontAwesomeIcon icon={faCartPlus} color={"white"}/>}
                                                        >
                                                            <span className="fw-bolder text-white">Tout sélectionner</span>
                                                        </Button>
                                                    </div>
                                                )
                                            }
                                            {<div className='mh-350px scroll-y me-n3 pe-3'>
                                                <div className="form-group fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
                                                    {/*begin::Item*/}
                                                    {clientSommaire.map((sommaire, index) => (
                                                        <>
                                                            <div className="d-flex" key={index}>
                                                                {/*begin::Checkbox*/}
                                                                <div className="form-check form-check-custom form-check-solid">
                                                                    {/*begin::Input*/}
                                                                    <input
                                                                        className="form-check-input me-3"
                                                                        name="move_to_folder"
                                                                        type="checkbox"
                                                                        value={sommaire.libelle}
                                                                        id={`kt_modal_move_to_folder_${index}`}
                                                                        onChange={() => handleCheckboxChange(sommaire.libelle)}
                                                                        checked={selectedUsers?.sommaires?.includes(sommaire.libelle)}
                                                                    />
                                                                    {/*end::Input*/}
                                                                    {/*begin::Label*/}
                                                                    <label className="form-check-label" htmlFor="kt_modal_move_to_folder_0">
                                                                        <div className="text-gray-800 fs-15">
                                                                            {`${sommaire.libelle} - ${sommaire.description}`}
                                                                        </div>
                                                                    </label>
                                                                    {/*end::Label*/}
                                                                </div>
                                                                {/*end::Checkbox*/}
                                                            </div>
                                                            {/*end::Item*/}
                                                            <div className="separator separator-dashed my-5"></div>
                                                        </>
                                                    ))}
                                                </div>
                                                {/*end::Item*/}
                                                <div className="fv-plugins-message-container invalid-feedback"></div>
                                            </div>}
                                            {/*end::Input group*/}
                                            {/*begin::Action buttons*/}
                                            <div className=" card-footer d-flex flex-center mt-3">
                                                {/*begin::Button*/}
                                                <Button
                                                    type="primary"
                                                    block
                                                    size="large"
                                                    loading={sommairesLoading}
                                                    disabled={sommairesLoading}
                                                    onClick={handleSommaireValidate}
                                                >
                                                    {!sommairesLoading && <span className='indicator-label'>Valider</span>}
                                                    {sommairesLoading && (
                                                        <span className="indicator-progress text-white">
                                                        Veuillez patienter...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                                    </span>
                                                    )}
                                                </Button>
                                                {/*end::Button*/}
                                            </div>
                                            {/*begin::Action buttons*/}
                                        </div>
                                        {/*end::Modal body*/}
                                        <div></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {(isModalOpen && selectedModalId === 2) && (
                    <div
                        className="top-0 bottom-0 z-index-9999 h-100"
                        style={{ insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}
                    >
                        <div className="modal fade show" id="kt_modal_move_to_folder" tabIndex="-1" aria-modal="true" role="dialog" style={{ display: "block", insetInlineEnd: '0', insetInlineStart: '0', backgroundColor: 'rgba(0, 0, 0, 0.45)' }}>
                            <div className='modal-dialog modal-dialog-centered mw-600px' >
                                <div className='modal-content'>
                                    <form className="form fv-plugins-bootstrap5 fv-plugins-framework" action="#"
                                          id="kt_modal_move_to_folder_form">
                                        {/*begin::Modal header*/}
                                        <div className="modal-header">
                                            {/*begin::Modal title*/}
                                            <h2 className="fw-bolder">Privilèges du rôle</h2>
                                            {/*end::Modal title*/}
                                            {/*begin::Close*/}
                                            <div className="btn btn-icon btn-sm btn-active-icon-primary" onClick={() => setIsModalOpen(false)}>
                                                <span className="svg-icon svg-icon-1">
                                                  <CloseSquareOutlined style={{color: 'red'}}/>
                                                </span>
                                            </div>
                                            {/*end::Close*/}
                                        </div>
                                        {/*end::Modal header*/}
                                        {/*begin::Modal body*/}
                                        <div className="modal-body py-10 px-lg-17">
                                            {/*<h1 className="mb-7">*/}
                                            {/*    <span className="fw-bolder">Privilège du role</span>*/}
                                            {/*</h1>*/}
                                            <div className="scroll-y mh-400px me-n7 pe-7">
                                                <div className="d-flex align-center">
                                                    <div className="col-lg-6">
                                                        <div className="card card-flush">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h4 className="mb-0">Tâches</h4>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="d-flex flex-column text-gray-600">
                                                                    {selectedUsers?.actions && selectedUsers?.actions?.map((selected, index) => (
                                                                        <div key={index} className="d-flex align-items-center py-2">
                                                                            <span className="bullet bg-primary me-3"></span>
                                                                            {taches.length > 0 && taches.find((intervention) => intervention.id === selected)?.libelle}                                                                    </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="card card-flush">
                                                            <div className="card-header">
                                                                <div className="card-title">
                                                                    <h4 className="mb-0">Interventions</h4>
                                                                </div>
                                                            </div>
                                                            <div className="card-body pt-0">
                                                                <div className="d-flex flex-column text-gray-600">
                                                                    {selectedUsers?.interventions && selectedUsers?.interventions?.map((selected, index) => (
                                                                        <div key={index} className="d-flex align-items-center py-2">
                                                                            <span className="bullet bg-primary me-3"></span>
                                                                            {interventions.length > 0 && interventions.find((intervention) => intervention.id === selected)?.libelle}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*end::Modal body*/}
                                        <div></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </ModalAlert>
        </>
    )
}

export default AddChefMission