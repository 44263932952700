import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../../helpers/AssetsHelpers";
import {ajouterAnomalieSignificatif} from "../../../../../../../data/seuilSignificatif";
import {getAnomalieInsignifiante} from "../../../../../../../data/stategieGlobale";
import {Button, Input, InputNumber, Tooltip, Typography} from "antd";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

const AnomaliesSignificative = ({
        strategies,
        montantAnomalie,
        mission,
        codeSommaire,
        date,
        heureEntree,
        tache_id,
        intervention_id,
        isProvisoire,
    }) => {
    const [taux, setTaux] = useState(5)
    const [montantTaux, setMontantTaux] = useState(montantAnomalie)
    const [commentaire, setCommentaire] = useState('')
    const [loading, setLoading] = useState(false);

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '8px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '15px',
    };

    useEffect(() => {
        if(strategies && isProvisoire){
            getAnomalieInsignifiante(strategies.id)
                .then((res) => {
                    if(res.result){
                        setTaux(isProvisoire ? res.result.tauxProv : res.result.tauxDef)
                        setMontantTaux((isProvisoire ? res.result.montantProv : res.result.montantDef) || 0)
                        setCommentaire(res.result.commentaire)
                    }
                })
        }
        else{
            setTaux(5)
            setMontantTaux(0)
            setCommentaire('')
        }
    }, [ isProvisoire, strategies ])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            tauxProv: isProvisoire === true ? taux : 0,
            tauxDef: isProvisoire === false ? taux : 0,
            monatantDef: isProvisoire === false ? montantTaux : 0,
            montantProv: isProvisoire === true ? montantTaux : 0,
            commentaire,
            strategie_id: strategies.id,
            exercice_id: mission.id,
            client_id: mission.client_id,
            codeSommaire ,
            heureEntree,
            date,
            tache_id,
            intervention_id,
            isProvisoire
        };

        const { status, message } = await ajouterAnomalieSignificatif(data);
        if (status === "success") {
            setLoading(false);
            await swal(succesMessage, "", "success");
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    };

    return (
        <>
          <div className='pb-15'>
              {<div className="d-flex justify-content-end align-items-end mb-10">
                  <Tooltip title="Enregistrer">
                      <Button
                          disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                          size={"large"}
                          // loading={loading}
                          style={{
                              backgroundColor: appTheme.colors.secondary,
                              color: "white"
                          }}
                          icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                          onClick={handleSubmit}
                      >
                          {!loading &&
                              <span className='indicator-label text-white'>Enregistrer</span>}
                          {loading && (
                              <span className='indicator-progress fs-9 text-white'
                                    style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                          )}
                      </Button>
                  </Tooltip>
              </div>}
              <div className="card-header">
                  <div className="card-title">
                      <Title style={{
                          display: "block",
                          fontWeight: '600',
                          color: appTheme.colors.primary,
                          // textAlign: "center"
                      }} level={4}>{"F. Anomalies insignifiante"}</Title>
                  </div>
              </div>
              <div className="fw-bold text-gray-600 mb-5 mt-5 mx-auto" style={{fontSize: '14px'}}>
                  (ISA 450.15) <br/>
                  Les anomalies insignifiantes sont calculées sur la base du taux retenu.
                  <br/>
                  Montant en dessous duquel les anomalies seraient clairement insignifiantes et ne figuraient pas sur des ajustements identifiés
              </div>
              <div className='row gy-5 g-xl-10'>
                  <div className='col-xl-4 mb-xl-4'>
                      <div className='d-flex align-center mb-4'>
                          <div className='me-10 mb-5'>
                              <div className="text-gray-500 fw-bold fs-4 mb-3 mt-5">Taux retenu (%):</div>
                              <InputNumber
                                  min={0}
                                  max={5}
                                  step="0.01"
                                  value={taux}
                                  onChange={(value) => {
                                      setTaux(parseFloat(value))

                                      const mount = parseFloat(value) * (montantAnomalie || 0) / 100
                                      setMontantTaux(mount || 0)
                                  }}
                              />
                          </div>

                      </div>

                      <div className="row mb-7">
                          <div className='card-body'>
                              <table style={tableStyle} className=''>
                                  <thead className='bg-primary px-2'>
                                      <tr style={thStyle} className="text-start fw-bolder fs-7 text-uppercase gs- h-1px">
                                          <th style={thStyle} className="" rowSpan="2" colSpan="1">
                                              <div className="dataTables_sizing h-0 overflow-hidden">{`Taux  ${isProvisoire === true ? 'provisoire' : 'définitif'}`} </div>
                                          </th>
                                          <th style={thStyle} className="" rowSpan="1" colSpan="1">
                                              <div className="dataTables_sizing h-0 overflow-hidden">
                                                  {`Montant ${isProvisoire === true ? 'provisoire' : 'définitif'}`}
                                              </div>
                                          </th>
                                      </tr>
                                  </thead>
                                  <tbody className='fw-bold'>
                                  <tr style={tdStyle} className="even ">
                                      <td style={tdStyle} className="min-w-175px fw-bolder">
                                          <span>{taux} %</span>
                                      </td>
                                      <td style={tdStyle} className='text-end fw-bolder'>
                                          <span>
                                             {montantTaux.toLocaleString('fr')}
                                          </span>
                                      </td>
                                  </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                  </div>
                  <div className='col-xl-8 mb-5 mb-xl-4 mt-10'>
                      <Input.TextArea
                          size="large"
                          placeholder="Saissiser le contexte"
                          readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                          maxLength={5000}
                          showCount
                          autoSize={{
                              minRows: 4,
                              maxRows: 25,
                          }}
                          value={commentaire}
                          onChange={(e) => {
                              setCommentaire(e.target.value)
                          }}
                      />
                  </div>
              </div>
          </div>
        </>
    )
}

export {AnomaliesSignificative}