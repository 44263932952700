import React from 'react';

const PrintPartieLiee = ({datas, conclusion, observation, title}) => {

    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thStyle = {
        border: '1px solid #000000',
        fontWeight: "bold",
        textAlign: 'center',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'center',
        padding: '2px',
    };

    return (
        <div className="">
            <h1 className="fw-bolder text-black mb-3 text-center">{title || ""}</h1>

            <div style={{marginTop: '10px', marginBottom: "5%"}} className="table-responsive">
                <div className='mx-auto'>
                    <div className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                        <table className="tablePerso w-100" style={tableStyle}>
                            <thead>
                            <tr style={thStyle}>
                                <th style={thStyle} className="thperso w-400px">Nature</th>
                                <th style={thStyle} className="thperso w-100px">Réponse</th>
                                <th style={thStyle} className="thperso w-500px">Commentaire</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(datas && datas.length > 0) && datas.map((item, index) => (
                                <tr key={index} style={tdStyle} className="">
                                    <td className="" style={{...tdStyle, fontSize: '11px'}}>
                                        <p className='text-start' style={{fontSize: '11px'}}>
                                            {item.libelle}
                                        </p>
                                    </td>
                                    <td className=" text-center" style={{...tdStyle, fontSize: '11px' }}>
                                        {item.reponse === '1' ? 'S/O': item.choix === '2' ? 'OUI' : 'NON' }
                                    </td>
                                    <td className="text-start" style={tdStyle}>
                                        <p style={{fontSize: '11px'}}>
                                            {item.commentaire}
                                        </p>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

            {conclusion &&
                <table style={{...tableStyle, marginBottom: "5%"}} className=" ">
                    <thead>
                    <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                        <th
                            className="text-start"
                            style={{fontSize: "14px",
                                textDecoration: 'underline',
                                fontWeight: "bold"}}
                        >
                            Conclusion
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={tdStyle}>
                        <td style={{...tdStyle,  textAlign: "start"}}>
                            <p style={{fontSize: "12px"}}>{conclusion}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            }
            {observation &&
                <table style={tableStyle} className=" ">
                    <thead>
                    <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                        <th
                            className="text-start"
                            style={{fontSize: "14px",
                                textDecoration: 'underline',
                                fontWeight: "bold"}}
                        >
                            Observation Générale
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style={tdStyle}>
                        <td style={{...tdStyle,  textAlign: "start"}}>
                            <p style={{fontSize: "12px"}}>{observation}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            }
        </div>
    );
}

export default PrintPartieLiee;
