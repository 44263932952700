import axios from "axios";

const AJOUTER_BILAN_ACTIF = `${process.env.REACT_APP_API_URL}/lea/exercice/etats-fincanier/ajouter-bilan-actif`
const AJOUTER_BILAN_PASSIF = `${process.env.REACT_APP_API_URL}/lea/exercice/etats-fincanier/ajouter-bilan-passif`
const AJOUTER_COMPTE_RESULTAT = `${process.env.REACT_APP_API_URL}/lea/exercice/etats-fincanier/ajouter-compte-resultat`
const DETAILS_ETATS_FINANCIER = `${process.env.REACT_APP_API_URL}/lea/exercice/etats-fincanier/details`

export const ajouterBilanActif = async (data) => {
    return axios.post(AJOUTER_BILAN_ACTIF, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterBilanPassif = async (data) => {
    return axios.post(AJOUTER_BILAN_PASSIF, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterCompteResutat = async (data) => {
    return axios.post(AJOUTER_COMPTE_RESULTAT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getDetailsEtatsFinancier = async (exercice_id) => {
    return axios.get(`${DETAILS_ETATS_FINANCIER}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

