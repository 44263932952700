import React, { useState } from 'react';
import {Menu, Typography} from 'antd';
import {
    faHistory, faTasks,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTheme} from "styled-components";
import TraitementExamenAnalytiqueBilan from "./TraitementExamenAnalytiqueBilan";
import {HistoriqueFeuilleMaitresse} from "../feuilleMaitresse/HistoriqueFeuilleMaitresse";
import {appTheme} from "../../../../../../../config/theme";

const {  Title } = Typography;

const MenuBC = ({
        setBalanceData,
        balanceData,
        mission,
        client,
        chargement,
        isProvisoire,
        setIsTaskSave,
        handleSubmit,
    }) => {
    const theme = useTheme();

    const [current, setCurrent] = useState('0');
    const onClick = (e) => {
        setCurrent(e.key);
    };

    const items = [
        {
            label: 'EA-BL',
            description:"EXAMEN ANALYTIQUE DES BALANCES",
            key: '0',
            icon: <FontAwesomeIcon icon={faTasks} color={theme.colors.primary_blue} />,
        },
        {
            label: 'HISTORIQUE BL',
            description: "HISTORIQUE DES EXAMENS ANALYTIQUE DES BALANCES",
            key: '1',
            icon: <FontAwesomeIcon icon={faHistory} color={theme.colors.primary_blue} />,
        },
    ];

    const showCurrentPage = () => {
        switch (current) {
            case '0':
                return (
                    <TraitementExamenAnalytiqueBilan
                        mission={mission}
                        client={client}
                        setBalanceData={setBalanceData}
                        balanceData={balanceData}
                        chargement={chargement}
                        isProvisoire={isProvisoire}
                        setIsTaskSave={setIsTaskSave}
                    />
                )
            case '1':
                return (
                    <HistoriqueFeuilleMaitresse
                        balanceData={balanceData}
                        setBalanceData={setBalanceData}
                        mission={mission}
                        chargement={chargement}
                        isProvisoire={isProvisoire}
                        handleSubmit={handleSubmit}
                        setIsTaskSave={setIsTaskSave}
                    />
                )
            default:
                return (
                    <TraitementExamenAnalytiqueBilan
                        mission={mission}
                        client={client}
                        setBalanceData={setBalanceData}
                        balanceData={balanceData}
                        chargement={chargement}
                        isProvisoire={isProvisoire}
                        setIsTaskSave={setIsTaskSave}
                    />
                )
        }
    }

    return (
        <>
            <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="horizontal"
                items={items}
                style={{marginBottom: '5px', fontWeight: 'bold'}}
            />
            <br/>
            <Title className="my-5" style={{display: "block", fontWeight: '600', color: appTheme.colors.primary}} level={4}># {items[current].description}</Title>
            {showCurrentPage()}
        </>
    )
}

export default MenuBC;