import {
    faBuilding, faGauge, faGear, faInfo, faTools, faUserGear,
    faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
export const topMenuRoutes = [
    {
        label: "Tableau de bord",
        path: "/tableau-de-bord",
        icon: faGauge,
    },
    {
        label: "Utilisateurs",
        path: "/utilisateurs/liste",
        icon: faUserGroup,
    },
    {
      label: "Dossiers clients",
      path: "/clients-menu",
      icon: faBuilding,
    },
    {
        label: "Outils",
        path: "outils",
        icon: faTools,
    },
    {
        label: "Profil",
        path: "/profil",
        icon: faUserGear,
    },
    {
        label: "Paramètres",
        path: "",
        icon: faGear,
    },
    {
        label: "Guide utilisateur",
        path: "/guide-utilisateur",
        icon: faInfo,
    },
];

export const topMenuRoutesUser = [
    {
        label: "Tableau de bord",
        path: "/tableau-de-bord",
        icon: faGauge,
    },
    // {
    //     label: "Utilisateurs",
    //     path: "/utilisateurs/liste",
    //     icon: faUserGroup,
    // },
    {
      label: "Dossiers clients",
      path: "/clients-menu",
      icon: faBuilding,
    },
    {
        label: "Outils",
        path: "outils",
        icon: faTools,
    },
    {
        label: "Profil",
        path: "/profil",
        icon: faUserGear,
    },
    // {
    //     label: "Paramètres",
    //     path: "",
    //     icon: faGear,
    // },
    {
        label: "Guide utilisateur",
        path: "/guide-utilisateur",
        icon: faInfo,
    },
];
