import React, {useEffect, useRef, useState} from 'react';
import {Space, Table, Button, Alert, Modal, Popconfirm, Tag, Divider, Input, Select} from 'antd';
import {
    DeleteOutlined,
    DislikeOutlined,
    EditOutlined,
    LikeOutlined,
    PlusOutlined
} from '@ant-design/icons';
import {
    addQuestion, addTypeQuestion,
    deleteQuestion, getListeAutreQuestion,
    getListeGroupeQuestion,
    getListeTypeQuestion,
    updateEtatQuestion,
    updateQuestion
} from '../../../data/params/question';
import {DateUserListe} from "../../../components/layout/pageData/DateUserListe";

const QuestionnaireAutre = () => {
    const [questions, setQuestions] = useState([])
    const [question, setQuestion] = useState([])
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [libelle, setLibelle] = useState('')
    const [description, setDescription] = useState('')
    const [reponse_defaut, setReponse_Defaut] = useState('')
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [edite, setEdite] = useState(false);
    const [libelleGroupe, setLibelleGroupe] = useState('');
    const [types, setTypes] = useState([]);
    const [type_id, setTypeId] = useState(null);
    const [type_libelle, setTypeLibelle] = useState();
    const [groupes, setGroupes] = useState([]);
    const inputRef = useRef(null);
    const [search, setSearch] = useState('')
    const [libelleType, setLibelleType] = useState('')
    const [descriptionType, setDescriptionType] = useState('')
    const [typeAll, setTypeAll] = useState('')
    const [createdType, setCreatedType] = useState(false)

    useEffect(() => {
        fetchTypeQuestion()
        handleListeQuestion()
    }, [])

    useEffect(() => {
        fetchGroupeQuestion()
    }, [])

    const fetchGroupeQuestion = () => {
        setLoading(true);
        getListeGroupeQuestion()
            .then((res) => {
                if(res.result){
                    const groupesQuestions = res.result.filter((item) => item.Bog_TypeQuestion.libelle !== 'ACCEPTATION')
                    const criteres = [...new Set(groupesQuestions.map((item) => item.libelle))];
                    setGroupes(criteres);
                }
                setLoading(false);
            });
    };

    const fetchTypeQuestion = () => {
        setLoading(true);
        getListeTypeQuestion()
            .then((res) => {
                if(res.result){
                    const typesQuestion = res.result.filter((item) => item.libelle !== 'ACCEPTATION')
                    setTypeAll(res.result)
                    const typesP = [...new Set(typesQuestion.map((item) => item.libelle))];
                    setTypes(typesP);
                }
                setLoading(false);
            });
    };

    const handleListeQuestion = async () => {
        setLoading(true)
        const { result, status, message } = await getListeAutreQuestion()
        setMessage(message)
        if (status === 'success') {
            setError(false)
            setQuestions(result)
            handleCancel()
            setLoading(false)
        } else {
            setError(true)
            setLoading(false)
        }
    }

    const onNameChangeGroup = (event) => {
        setLibelleGroupe(event.target.value);
    };

    const onChangeGroup = (value) => {
        console.log('libelleGroup', value)
        setLibelleGroupe(value)
    }

    const onChangeQuestion = (value) => {
        console.log('Type', value)
        setTypeLibelle(value)
        const typeAff = typeAll.find((item) => ( item.libelle === value )).id
        setTypeId(typeAff)
    }

    const addItem = (e) => {
        e.preventDefault();
        setGroupes([...groupes, libelleGroupe]);
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    const filteredItems = questions.filter(
        (item) =>
            item.libelle.toLowerCase().includes(search.toLowerCase()) ||
            item.description.toLowerCase().includes(search.toLowerCase()) ||
            item.reponse_defaut.toLowerCase().includes(search.toLowerCase()) ||
            item.Bog_GroupeQuestion.critereGroupe.toLowerCase().includes(search.toLowerCase()) ||
            item.Bog_GroupeQuestion.libelle.toLowerCase().includes(search.toLowerCase()) ||
            item.id === parseInt(search)
    )

    const showModal = () => {
        setOpen(true);
    };

    const handleAddType = async () => {
        setLoading(true);
        const data = { libelle: libelleType.toUpperCase(), description: descriptionType.toUpperCase() }
        // console.log('data', data)
        const { status, message } = await addTypeQuestion(data)
        setMessage(message)
        if (status === 'success') {
            setError(false)
            setLibelleType('')
            setDescriptionType('')
            setCreatedType(false)
            handleListeQuestion()
            setLoading(false)
        } else {
            setError(true)
            setLoading(false)
        }
    };

    const handleAddQuestion = async () => {
        setLoading(true);
        const data = { libelle, description, reponse_defaut, libelleGroupe, critereGroupe: libelleGroupe, type_id }
        const { status, message } = await addQuestion(data)
        setMessage(message)
        if (status === 'success') {
            setError(false)
            setLibelle('')
            setDescription('')
            setReponse_Defaut('')
            setLibelleGroupe('')
            setTypeId(null)
            handleListeQuestion()
            setLoading(false)
        } else {
            setError(true)
            setLoading(false)
        }
    };

    const handleModifierQuestion = async () => {
        setLoading(true);
        const data = { question_id:question.id, libelle, description, reponse_defaut, libelleGroupe, type_id  }

        const { status, message } = await updateQuestion({ data, question_id: question.id})
        setMessage(message)
        if (status === 'success') {
            setError(false)
            setLibelle('')
            setDescription('')
            setReponse_Defaut('')
            handleListeQuestion()
            setLoading(false)
        } else {
            setError(true)
            setLoading(false)
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setEdite(false)
        setLibelle('')
        setDescription('')
        setReponse_Defaut('')
        setTypeId(null)
        setError(false)
    };

    const selectQuestion = (question_id) => {
        const privil = questions.filter(periode => periode.id === question_id)[0]
        setQuestion(privil)
        setLibelle(privil.libelle)
        setDescription(privil.description)
        setReponse_Defaut(privil.reponse_defaut)
        setEdite(true)
        setOpen(true);
    }

    const handleDeleteQuestion = async (question_id) => {
        setLoading(true)
        const data = {question_id}
        const { status, message } = await deleteQuestion(data)
        setMessage(message)
        if (status === 'success') {
            setError(false)
            handleListeQuestion()
            setLoading(false)
        } else {
            setError(true)
            setLoading(false)
        }
    }

    const handleUpdateEtatQuestion = async (question_id, etat) => {
        setLoading(true)
        const data = {question_id, etat}
        const { status, message } = await updateEtatQuestion(data)
        setMessage(message)
        if (status === 'success') {
            setError(false)
            setLibelle('')
            setDescription('')
            handleListeQuestion()
            setLoading(false)
        } else {
            setError(true)
            setLoading(false)
        }
    }

    const columns = [
        {
            title: '#',
            render: (text, record, index) => <span className='display-block chart-blue'>{ index+1 }</span>
        },
        {
            title: 'Question',
            dataIndex: 'libelle',
            key: 'libelle',
            render: (text) => <span className='display-block bold'>{text}</span>,
        },
        {
            title: 'Groupe',
            dataIndex: 'libelleGroupe',
            key: 'libelleGroupe',
            render: (_, record,) => <span className='display-block bold'>{record.Bog_GroupeQuestion?.libelle}</span>,
        },
        {
            title: 'Type de question',
            dataIndex: 'type',
            key: 'type',
            render: (_, record) => <span className='display-block bold'>{record.Bog_GroupeQuestion?.Bog_TypeQuestion?.libelle}</span>,
        },
        {
            title: 'Créée le / Par',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record, index) => <DateUserListe date={record.createdAt} user={record.nom_user+" "+record.prenom_user} key={index} />
        },
        {
            title: 'Etat',
            dataIndex: 'etat',
            key: 'etat',
            render: (_, record) =>  record.etat === 1 ? <Tag color="green">Activé</Tag> : <Tag color="red">Désactivé</Tag>
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => selectQuestion(record.id)}
                    />

                    {record.etat === 0 && <Popconfirm
                        title={`Activer la période ${record.libelle} ?`}
                        description={`Voulez vous vraiment activer la période ${record.libelle} ?`}
                        onConfirm={() => handleUpdateEtatQuestion(record.id, 1)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            icon={<DislikeOutlined />}
                            className='chart-bg-orange'
                            title='Activer ?'
                        />
                    </Popconfirm>}

                    {record.etat === 1 && <Popconfirm
                        title={`Désactiver la période ${record.libelle} ?`}
                        description={`Voulez vous vraiment désactiver la période ${record.libelle} ?`}
                        onConfirm={() => handleUpdateEtatQuestion(record.id, 0)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            icon={<LikeOutlined />}
                            className='chart-bg-green'
                            title='Désactiver ?'
                        />
                    </Popconfirm>}

                    <Popconfirm
                        title={`Supprimer la période ${record.libelle} ?`}
                        description={`Voulez vous vraiment supprimer la période ${record.libelle} ?`}
                        onConfirm={() => handleDeleteQuestion(record.id)}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button
                            title='Supprimer ?'
                            type="primary"
                            icon={<DeleteOutlined />}
                            danger
                        />
                    </Popconfirm>

                </Space>
            ),
        },
    ];

    return (
        <>
            { type_id || createdType === false ?
                <div className="card mb-5 mb-lg-10 w-100">
                    {error === true &&
                        <Space
                            direction="vertical"
                            style={{
                                width: '100%',
                            }}
                        >
                            <Alert
                                message="Error"
                                description={message}
                                type="error"
                                showIcon
                            />
                        </Space>
                    }

                    <div className="card-header">

                        <div className="card-title">
                            <h3>Liste des questionnaire d'affectation et de maintien</h3>
                        </div>

                        <div className="card-toolbar">
                            <div className="my-1 me-4">
                                <input type="text" name="fname" onChange={e => setSearch(e.target.value)} className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Rechercher" />
                            </div>
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={showModal}
                                size='large'
                            >
                                Ajouter
                            </Button>

                            <Button
                                className='mx-4'
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setCreatedType(true)
                                }}
                                size='large'
                            >
                                Ajouter Type
                            </Button>
                            {/* <a href="#" className="btn btn-sm btn-primary my-1">View All</a> */}
                        </div>
                    </div>

                    <div className="card-body p-0">

                        <div className="table-responsive">
                            <Table
                                loading={loading}
                                columns={columns} dataSource={filteredItems} pagination= { {current: 1, pageSize: 50} } />
                        </div>
                    </div>
                </div>
                :
                <>
                    <div className='d-flex flex-column align-center justify-content-center mx-auto'>
                        <div>
                            <div
                                className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6 mb-10">
                                <div className="d-flex flex-stack flex-grow-1">
                                    <div className="fw-bold">
                                        <h4 className="text-gray-900 fw-bolder">Notice!</h4>
                                        <div className="fs-6 text-gray-700">Les types de questions créés concernent les types qui seront utilisé pour regrouper les question lors de l'affectation et maintien d'une mission
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className='mb-5'>
                                <label className='bold'>Libelle du type</label>
                                <select
                                    name='provisoire'
                                    className="form-select"
                                    onChange={(e) => {
                                       setLibelleType(e.target.value)
                                    }}
                                    value={libelleType}
                                >
                                    {['ACCEPTATION', 'AFFECTATION', 'MAINTIEN'].map((opt, index) => (
                                        <option key={index} value={opt}>
                                            {opt}
                                        </option>
                                    ))}
                                </select>
                            </p>
                            <p>
                                <label className='bold'>Description du type</label>
                                <input type="text" name="fname" className="form-control mb-3 mb-lg-0" onChange={e => setDescriptionType(e.target.value)} value={descriptionType} placeholder="Entrez la description" />
                            </p>
                        </div>

                        <div>
                            <Button
                                className='me-10'
                                type="primary"
                                onClick={handleAddType}
                                size='large'
                            >
                                Enregistrer
                            </Button>

                            <Button
                                className=''
                                type="danger"
                                onClick={(e) => {
                                    e.preventDefault()
                                    setCreatedType(false)
                                }}
                                size='large'
                            >
                                Annuler
                            </Button>
                        </div>
                    </div>
                </>

            }
            <Modal
                open={open}
                title={!edite ? "Enregistrer une nouvelle question" : "Modifier la question" }
                onOk={ !edite ? handleAddQuestion : handleModifierQuestion }
                onCancel={handleCancel}
                width={1000}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Annuler
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={ !edite ? handleAddQuestion : handleModifierQuestion }>
                        Enregistrer
                    </Button>,
                ]}
            >

                {error === true && <Space
                    direction="vertical"
                    style={{
                        width: '100%',
                    }}
                >
                    <Alert message={message} type="error" showIcon />

                </Space>}
                <p>
                    <label className='bold'>Question</label>
                    <textarea
                        className='form-control mb-2'
                        rows="50"
                        cols="150"
                        maxLength={1000}
                        style={{
                            height: '100px',
                            marginBottom: 24,
                        }}
                        onChange={e => setLibelle(e.target.value)}
                        value={libelle}
                        placeholder="Entrez la question"
                    >
                    </textarea>
                </p>
                <p>
                    <label className='bold'>Libelle du groupe</label>
                    <div>
                        <div className="d-flex mt-3">
                            <Select
                                style={{ width: 500 }}
                                placeholder="Critère relatif à "
                                onChange={onChangeGroup}
                                dropdownRender={(menu) => (
                                    <>
                                        {menu}
                                        <Divider style={{ margin: '8px 0' }} />
                                        <Space style={{ padding: '0 8px 4px' }}>
                                            <Input
                                                placeholder="Entrer le critère"
                                                ref={inputRef}
                                                value={libelleGroupe}
                                                onChange={onNameChangeGroup}
                                            />
                                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                                Ajouter un groupe
                                            </Button>
                                        </Space>
                                    </>
                                )}
                                options={groupes && groupes.map((item) => ({ label: item, value: item }))}
                            />
                        </div>
                    </div>
                </p>
                <p>
                    <label className='bold'>Type de question</label>
                    <div>
                        <div className="d-flex mt-3">
                            <select
                                name='mission'
                                className="form-control w-500px me-5"
                                onChange={(e) => onChangeQuestion(e.target.value)}
                                value={type_libelle}
                            >
                                <option value=''>Selection...</option>
                                {types.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </p>
            </Modal>
        </>
    )

}

export default QuestionnaireAutre
