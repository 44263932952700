import React, {useEffect, useState} from 'react';
import { Col, DatePicker, Input, Select, Table, Tag} from 'antd';
import {
    ClockCircleOutlined,
} from '@ant-design/icons';
import {
    // ajouterPlanComptable,
    getListePlanComptable,
    // importerPlanComptable,
    // modifierPlanComptable
} from "../../../data/params/planComptable";
// import * as XLSX from "xlsx";
// import swal from "sweetalert";
import TableTitle from "../../../components/common/TableTitle";
import moment from "moment";
import {FormContainer} from "../../../components/common/common.components";
import FormLabel from "../../../components/common/Form/FormLabel";
import SearchRow from "../../../components/common/SearchRow";
import PageLabel from "../../../components/common/PageLabel/PageLabel";

const PlanComptableList = () => {
    const [plans, setPlans] = useState([])
    // const [planComptables, setPlanComptables] = useState([])
    const [loading, setLoading] = useState(false)
    const [filtersItems, setFiltersItems] = useState([]);

    const [paramsRecherche, setParamsRecherche] = useState({
        numero: "",
        intitule: "",
        sensCompte: "",
        date_debut: "",
        date_fin: "",
    });

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const fetchPlanComptable = async () => {
        try {
            setLoading(true)
            const res = await getListePlanComptable()
            if (res.result) {
                if (res.result) {
                    setPlans(res.result);
                    setFiltersItems(res.result);
                    setLoading(false)
                }
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des événements risques', error)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPlanComptable();
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setPlans([]);
        }
    };



    // const handleFile = (e) => {
    //     const fileTypes = [
    //         'application/vnd.ms-excel',
    //         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //         'text/csv',
    //     ];
    //     const selectedFile = e.target.files[0];
    //
    //     if (selectedFile) {
    //         if (selectedFile && fileTypes.includes(selectedFile.type)) {
    //
    //             const reader = new FileReader();
    //             reader.readAsArrayBuffer(selectedFile);
    //
    //             reader.onload = (e) => {
    //                 const arrayBuffer = e.target.result;
    //                 const workbook = XLSX.read(arrayBuffer, { type: 'buffer' });
    //                 const sheetName = workbook.SheetNames[0];
    //                 const worksheet = workbook.Sheets[sheetName];
    //                 const rawData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    //
    //                 setPlanComptables(rawData);
    //             };
    //         } else {
    //             notification.error({
    //                 description: "Choisissez seulement les fichiers types excel (xlsx, csv)",
    //             });
    //             setPlanComptables([]);
    //         }
    //     }
    // };
    //
    // const handleImporterPlanComptable = async (e) => {
    //     e.preventDefault()
    //     const plansImport = planComptables.map((item) => ({
    //         numero: item[0],
    //         intitule: item[1],
    //         sensCompte: item[2]
    //     }));
    //
    //     if(plansImport.length > 0){
    //         setLoading(true);
    //         const data = {planComptables: plansImport}
    //         // console.log('Data: ', data)
    //         const {status, message} = await importerPlanComptable(data)
    //         if (status === 'success') {
    //             setPlanComptables([])
    //             await fetchPlanComptable()
    //             setLoading(false)
    //         } else {
    //             setLoading(false)
    //             await swal(message, '', 'error')
    //         }
    //     }
    // };
    //
    // const handleAddPlanComptable = async (value) => {
    //     setLoading(true);
    //     const data = {numero: value.numero, intitule: value.intitule, sensCompte: value.sensCompte}
    //
    //     const {status, message} = await ajouterPlanComptable(data)
    //     if (status === 'success') {
    //         setLoading(false)
    //         await swal(`Opération effectuée avec succès`, '', 'success')
    //         await fetchPlanComptable()
    //     } else {
    //         setLoading(false)
    //         await swal(message, '', 'error')
    //     }
    // };
    //
    // const handleModifierPlan = async (value) => {
    //     setLoading(true);
    //     const data = {plan_id: value.plan_id, numero: value.numero, intitule: value.intitule, sensCompte: value.sensCompte}
    //     const {status, message} = await modifierPlanComptable(data)
    //     if (status === 'success') {
    //         await swal(`Opération effectuée avec succès`, '', 'success')
    //         setLoading(false)
    //         await fetchPlanComptable()
    //     } else {
    //         await swal(message, '', 'error')
    //         setLoading(false)
    //     }
    // };

    // const handleDeletePlan = async (profil_id) => {
    //     Swal.fire(optionsSwal).then(async (result) => {
    //         if (result.isConfirmed) {
    //             setLoading(true)
    //             const {status, message} = await deleteProfil({profil_id})
    //             if (status === 'error') {
    //                 await swal(`${message}`, '', 'warning')
    //             }
    //             if (status === 'success') {
    //                 setLoading(false)
    //                 await fetchPlanComptable()
    //             } else {
    //                 setLoading(false)
    //             }
    //         }
    //     })
    // }

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Numéro Compte</TableTitle>,
            dataIndex: 'numero',
            width: 100,
            render: (_, record) => (
                <Tag>{record.numero}</Tag>
            ),
        },
        {
            title: () => <TableTitle>Intitule</TableTitle>,
            dataIndex: 'intitule',
            width: 500,
            render: (_, record) => (
                <div style={{
                    fontFamily: "Oswald, sans-serif",
                    fontSize: "14px", fontWeight: '600'
                }}>
                    {record.intitule}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Sens Compte</TableTitle>,
            dataIndex: 'sensCompte',
            width: 60,
            render: (_, record) => (
                <Tag color={record.sensCompte === "C" ? "red" : record.sensCompte === "D" ? "success" : "" }>{record.sensCompte}</Tag>
            ),
        },
        {
            title: () => <TableTitle>Dernière modif.</TableTitle>,
            dataIndex: 'modif',
            width: 150,
            render: (_, record) => (
                <div className='d-flex flex-column align-center'>
                    <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                        <ClockCircleOutlined style={{fontSize: '12px'}} />{' '}
                        {moment(record.updatedAt).format('DD/MM/YYYY à HH:mm')}
                    </span>
                </div>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = plans.filter(file => {
                const numeroMatch = !paramsRecherche.numero || file.numero.startsWith(paramsRecherche.numero);

                const intituleMatch = !paramsRecherche.intitule || file.intitule.toLowerCase().includes(paramsRecherche.intitule.toLowerCase());

                const sensCompteMatch = !paramsRecherche.sensCompte || file.sensCompte.toLowerCase() === paramsRecherche.sensCompte.toLowerCase();

                const createdAtMatch = (!paramsRecherche.date_debut && !paramsRecherche.date_fin) || moment(file.createdAt).isBetween(
                    moment(paramsRecherche.date_debut),
                    moment(paramsRecherche.date_fin)
                );

                return numeroMatch && intituleMatch && sensCompteMatch && createdAtMatch;
            });

            setFiltersItems(filteredClients);
            setLoading(false);
        }, 1000);
    };

    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            numero: "",
            intitule: "",
            sensCompte: "",
            date_debut: "",
            date_fin: "",
        });
        setFiltersItems(plans)
        setLoading(false);
    };

    return (
        <>
            <div className="w-100">
                <PageLabel label="Plan comptable" />
                <SearchRow
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    loading={loading}
                >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Numéro de compte" />
                        <Input
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Rechercher par le numéro de compte"
                            value={paramsRecherche.numero || null}
                            onChange={(e) =>
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    numero: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xl={{ span: 8 }}
                    >
                        <FormLabel label="Intitulé de compte" />
                        <Input
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Rechercher par l'intitulé du compte"
                            value={paramsRecherche.intitule || null}
                            onChange={(e) =>
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    intitule: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 4 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Sens de compte" />
                        <Select
                            placeholder="Rechercher par sens du compte"
                            size="large"
                            style={{ width: "100%" }}
                            value={paramsRecherche.sensCompte || null}
                            onSelect={(value) =>
                                setParamsRecherche({ ...paramsRecherche, sensCompte: value })
                            }
                            options={[
                                {label: "D", value: "D"},
                                {label: "C", value: "C"},
                                {label: "DC", value: "DC"},
                            ]}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 4 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Date debut" />
                        <DatePicker
                            size="large"
                            placeholder="Date début de la création"
                            style={{ width: "100%" }}
                            allowClear
                            onChange={(date, dateString) =>
                                date !== null
                                    ? setParamsRecherche({
                                        ...paramsRecherche,
                                        date_debut: date.format("YYYY-MM-DD"),
                                    })
                                    : setParamsRecherche({ ...paramsRecherche, date_debut: "" })
                            }
                            format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 4 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Date fin" />
                        <DatePicker
                            size="large"
                            placeholder="Date fin  de la création"
                            style={{ width: "100%" }}
                            allowClear
                            onChange={(date, dateString) =>
                                date !== null
                                    ? setParamsRecherche({
                                        ...paramsRecherche,
                                        date_fin: date.format("YYYY-MM-DD"),
                                    })
                                    : setParamsRecherche({ ...paramsRecherche, date_fin: "" })
                            }
                            format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                        />
                    </Col>
                </SearchRow>
                <FormContainer>
                    <div className="py-4">
                        <div className="table-responsive">
                            <Table
                                columns={columns}
                                rowKey={(record) => record.id}
                                dataSource={filtersItems}
                                pagination={tableParams.pagination}
                                loading={loading}
                                onChange={handleTableChange}
                                rowSelection
                                size={"small"}
                            />
                        </div>
                    </div>
                </FormContainer>
            </div>

        </>
    );
};


export default PlanComptableList;
