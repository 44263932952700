import React, { useEffect, useState } from "react";
import {
    ClockCircleOutlined,
} from "@ant-design/icons";
import {Col, DatePicker, Input, Select, Table, Tag} from "antd";
import TableTitle from "../../common/TableTitle";
import {FormContainer} from "../../common/common.components";
import moment from "moment/moment";
import {ModalTitle} from "../../modals/modals.components";
import SearchRow from "../../common/SearchRow";
import FormLabel from "../../common/Form/FormLabel";
import ModalAlert from "../../modals/Modal";
import {getListePlanComptable} from "../../../data/params/planComptable";

const ModalPlanComptable = ({ handleVisibility, visible}) => {
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const [filtersItems, setFiltersItems] = useState([]);
    const [paramsRecherche, setParamsRecherche] = useState({
        numero: "",
        intitule: "",
        sensCompte: "",
        date_debut: "",
        date_fin: "",
    });

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });

    const fetchPlanComptable = async () => {
        try {
            setLoading(true)
            const res = await getListePlanComptable()
            if (res.result) {
                if (res.result) {
                    setPlans(res.result);
                    setFiltersItems(res.result);
                    setLoading(false)
                }
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des événements risques', error)
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPlanComptable();
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setPlans([]);
        }
    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Numéro Compte</TableTitle>,
            dataIndex: 'numero',
            width: 100,
            render: (_, record) => (
                <Tag>{record.numero}</Tag>
            ),
        },
        {
            title: () => <TableTitle>Intitule</TableTitle>,
            dataIndex: 'intitule',
            width: 500,
            render: (_, record) => (
                <div style={{
                    fontFamily: "Oswald, sans-serif",
                    fontSize: "14px", fontWeight: '600'
                }}>
                    {record.intitule}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Sens Compte</TableTitle>,
            dataIndex: 'sensCompte',
            width: 60,
            render: (_, record) => (
                <Tag color={record.sensCompte === "C" ? "red" : record.sensCompte === "D" ? "success" : "" }>{record.sensCompte}</Tag>
            ),
        },
        {
            title: () => <TableTitle>Dernière modif.</TableTitle>,
            dataIndex: 'modif',
            width: 150,
            render: (_, record) => (
                <div className='d-flex flex-column align-center'>
                    <span className='chart-blue display-block f-size-12' style={{fontWeight: 600}}>
                        <ClockCircleOutlined style={{fontSize: '12px'}} />{' '}
                        {moment(record.updatedAt).format('DD/MM/YYYY à HH:mm')}
                    </span>
                </div>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = plans.filter(file => {
                const numeroMatch = !paramsRecherche.numero || file.numero.startsWith(paramsRecherche.numero);

                const intituleMatch = !paramsRecherche.intitule || file.intitule.toLowerCase().includes(paramsRecherche.intitule.toLowerCase());

                const sensCompteMatch = !paramsRecherche.sensCompte || file.sensCompte.toLowerCase() === paramsRecherche.sensCompte.toLowerCase();

                const createdAtMatch = (!paramsRecherche.date_debut && !paramsRecherche.date_fin) || moment(file.createdAt).isBetween(
                    moment(paramsRecherche.date_debut),
                    moment(paramsRecherche.date_fin)
                );

                return numeroMatch && intituleMatch && sensCompteMatch && createdAtMatch;
            });

            setFiltersItems(filteredClients);
            setLoading(false);
        }, 1000);
    };

    const handleReset = () => {
        setLoading(true)
        setParamsRecherche({
            numero: "",
            intitule: "",
            sensCompte: "",
            date_debut: "",
            date_fin: "",
        });
        setFiltersItems(plans)
        setLoading(false);
    };

    return (
        <>
            <ModalAlert
            closeModal={handleVisibility}
            show={visible}
            title={<ModalTitle> Plan comptable</ModalTitle>}
            footer={[]}
            modalStyles={{ top: 15 }}
            width={1200}
            >
                <SearchRow
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    loading={loading}
                >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Numéro de compte" />
                        <Input
                            style={{ width: "100%" }}
                            size="middle"
                            placeholder="Rechercher par le numéro de compte"
                            value={paramsRecherche.numero || null}
                            onChange={(e) =>
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    numero: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xl={{ span: 8 }}
                    >
                        <FormLabel label="Intitulé de compte" />
                        <Input
                            style={{ width: "100%" }}
                            size="middle"
                            placeholder="Rechercher par l'intitulé du compte"
                            value={paramsRecherche.intitule || null}
                            onChange={(e) =>
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    intitule: e.target.value,
                                })
                            }
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 4 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Sens de compte" />
                        <Select
                            placeholder="Rechercher par sens du compte"
                            size="middle"
                            style={{ width: "100%" }}
                            value={paramsRecherche.sensCompte || null}
                            onSelect={(value) =>
                                setParamsRecherche({ ...paramsRecherche, sensCompte: value })
                            }
                            options={[
                                {label: "D", value: "D"},
                                {label: "C", value: "C"},
                                {label: "DC", value: "DC"},
                            ]}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 4 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Date debut" />
                        <DatePicker
                            size="middle"
                            placeholder="Date début de la création"
                            style={{ width: "100%" }}
                            allowClear
                            onChange={(date, dateString) =>
                                date !== null
                                    ? setParamsRecherche({
                                        ...paramsRecherche,
                                        date_debut: date.format("YYYY-MM-DD"),
                                    })
                                    : setParamsRecherche({ ...paramsRecherche, date_debut: "" })
                            }
                            format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 4 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Date fin" />
                        <DatePicker
                            size="middle"
                            placeholder="Date fin  de la création"
                            style={{ width: "100%" }}
                            allowClear
                            onChange={(date, dateString) =>
                                date !== null
                                    ? setParamsRecherche({
                                        ...paramsRecherche,
                                        date_fin: date.format("YYYY-MM-DD"),
                                    })
                                    : setParamsRecherche({ ...paramsRecherche, date_fin: "" })
                            }
                            format={["DD-MM-YYYY", "YYYY-MM-DD"]}
                        />
                    </Col>
                </SearchRow>
                <FormContainer>
                    <div className="w-100">
                        <div className="py-4">
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    rowKey={(record) => record.id}
                                    dataSource={filtersItems}
                                    pagination={tableParams.pagination}
                                    loading={loading}
                                    onChange={handleTableChange}
                                    rowSelection
                                    size={"small"}
                                />
                            </div>
                        </div>
                    </div>
                </FormContainer>
        </ModalAlert>
        </>
    );
}

export {ModalPlanComptable}