import {Table} from "antd";
import React, {useState} from "react";
import TableTitle from "../../../../../../common/TableTitle";
import {SearchHeader} from "../../../../../../layout/header/HeaderList";

const CompteResultat = ({values, valuesN1, mission, loading}) => {
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Référence</TableTitle>,
            dataIndex: 'ref',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.ref}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'libelle',
            width: 400,
            render: (_, record) => (
                <>
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                     {record.libelle}
                </span>
                </>
            ),
            responsive: ['sm']
        },
        {
            title: (<span style={{fontSize: '13px'}}></span>),
            dataIndex: 'signe',
            width: 50,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                {record.type}
            </span>
            ),
        },
        {
            title: (<span style={{fontSize: '12px'}}></span>),
            dataIndex: 'signe',
            width: 50,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                {record.signe}
            </span>
            ),
        },
        {
            title: () => <TableTitle>Notes</TableTitle>,
            dataIndex: 'note',
            width: 50,
            render: () => (
                <>
                <span className="fw-bolder" style={{fontSize: '12px'}}>

                </span>
                </>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${mission.annee} net`}</TableTitle>,
            dataIndex: 'exerciceN',
            width: 100,
            render: (_, record, ) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {record.exerciceN && record.exerciceN.toLocaleString('fr')}
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${parseInt(mission.annee) - 1} net`}</TableTitle>,
            dataIndex: 'exerciceN1',
            width: 100,
            render: (_, record, index) => (
                <>
                    <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                        {valuesN1[index]?.exerciceN && valuesN1[index].exerciceN.toLocaleString('fr')}
                    </div>
                </>
            ),
        },
    ];

    return(
        <div className="table-responsive">
            {(values ?? []).length > 0 && <div className="card-header border-0 pt-2">
                <SearchHeader
                    placeholder="Rechercher par la référence, le libellé..."
                    // handleSearch={handleSearchChange}
                />
            </div>}
            <div className="py-4">
                <div className="table-responsive">
                    <Table
                        rowClassName={(record,) =>
                            `${record.color ? `bg-${record?.color} text-white` : ''} h-3px text-hover-gray-800 p-0 m-0 lh-0.5`
                        }
                        columns={columns}
                        rowKey={(record, index) => index}
                        dataSource={(values ?? [])}
                        pagination={tableParams.pagination}
                        onChange={handleTableChange}
                        loading={loading}
                        rowSelection
                        size={"small"}
                    />
                </div>
            </div>
        </div>

    )
}

export {CompteResultat}
