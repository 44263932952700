import './App.css';
import './assets/plugins/global/plugins.bundle.css';
import './assets/css/style.bundle.css';
import '../node_modules/react-circular-progressbar/dist/styles.css';
import AppRoutes from "./routes/routes";

function App() {
  return (
    <>
        <AppRoutes />
    </>
  );
}

export default App;


