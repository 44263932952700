import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import LoginPage from './../views/login/LoginPage';
import NotFoundPage from "../views/not_found/NotFoundPage";
import {useSelector} from "react-redux";
import {PrivateRoutes} from "./PrivateRoutes";
import PasswordChange from "../views/users/user/PasswordChange";

const AppRoutes = () => {
    const user = useSelector(state => state.user)
    return (
        <BrowserRouter>
            <Routes>
                {Object.keys(user).length > 0  ?
                    (
                        <>
                            {user.update_password && user.update_password === 0 ? (
                                <Route path='/*' element={<PasswordChange />} />
                            ) : (
                                <>
                                    <Route index element={<Navigate to='/tableau-de-bord' />} />
                                    <Route path='/*' element={<PrivateRoutes />} />
                                </>
                            )}
                        </>
                    )
                    :
                    (
                        <>
                            <Route path='*' element={<Navigate to='/login' />} />
                            <Route path="login/*" element={<LoginPage />} />
                        </>
                    )
                }
                {/* Page Not Found */}
                <Route path='*' element={<NotFoundPage />} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;