import React, {useState} from "react";
import {Table, Tooltip, Button, Popover, Divider, Space} from "antd";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faExclamationCircle, faSave} from "@fortawesome/free-solid-svg-icons";
import {ModalCommentaireBalance} from "../../../../../../modals/clients/ModalCommentaireBalance";
import {SearchHeader} from "../../../../../../layout/header/HeaderList";
import FormLabel from "../../../../../../common/Form/FormLabel";

const HistoriqueControleIntangibilite = ({ balanceData, setBalanceData, mission, chargement, handleSubmit, setIsTaskSave}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [balance, setBalance] = useState(null);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 200,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    const handleModalOpen = (data) => {
        setBalance(data)
        setIsModalOpen(prevState => !prevState)
    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Compte</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistProv ? '' : record.compte_Prov}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'col1',
            width: 300,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistProv ? '' : record.libelle_Prov}
                </span>
            ),
        },
        {
            title: () => <TableTitle>{`SD_RAN ${mission.annee || "N"}`}</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.SD_RAN.toLocaleString('fr')}
                </div>
            ),
        },

        {
            title: () => <TableTitle>{`SC_RAN ${(mission.annee) || "N"} `}</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.SC_RAN.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Compte</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistDef ? '' : record.compte_Def}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'col1',
            width: 300,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.isExistDef ? '' : record.libelle_Def}
                </span>
            ),
        },
        {
            title: () => <TableTitle>{`SD ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.SD_N1.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>{`SC ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.SC_N1.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>DIFF SD</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.DIFF_SD.toLocaleString('fr')}
                </div>
            ),
        },

        {
            title: () => <TableTitle>DIFF SC</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {record.DIFF_SC.toLocaleString('fr')}
                </div>
            ),
        },
        {
            title: () => <TableTitle>Com</TableTitle>,
            dataIndex: 'commentaire',
            width: 40,
            render: (_, record) => (
                <div className="">
                    <Space>
                        {record.commentaire && <Popover
                            placement="top"
                            title={<FormLabel label={"Commentaire"} /> }
                            content={
                                <>
                                    <Divider/>
                                    <span className="fw-bolder"
                                          style={{fontSize: "14px",}}
                                    >
                                   {record.commentaire || ""}
                                </span>
                                </>
                            }
                        >
                            <Button
                                icon={<FontAwesomeIcon icon={faExclamationCircle}/>}
                                color={""}
                            />
                        </Popover>}
                        <Tooltip title="Editer un commentaire">
                            <Button
                                shape="circle"
                                style={{backgroundColor: appTheme.colors.primary, color: "white"}}
                                icon={<FontAwesomeIcon icon={faComment}/>}
                                onClick={() => handleModalOpen(record)}
                            />
                        </Tooltip>
                    </Space>
                    {/*<Input.TextArea*/}
                    {/*    style={{fontSize: "12px"}}*/}
                    {/*    size="large"*/}
                    {/*    bordered={false}*/}
                    {/*    placeholder="Saisisser le commentaire..."*/}
                    {/*    value={record.commentaire}*/}
                    {/*    maxLength={2000}*/}
                    {/*    showCount*/}
                    {/*    autoSize={{*/}
                    {/*        minRows: 1,*/}
                    {/*        maxRows: 4,*/}
                    {/*    }}*/}
                    {/*    onChange={(e) => {*/}
                    {/*        const updatedList = balanceData.map((item, i) =>*/}
                    {/*            i === index ? { ...item,  commentaire: (e.target.value)} : item*/}
                    {/*        );*/}

                    {/*        setBalanceData(updatedList);*/}
                    {/*    }}*/}
                    {/*/>*/}
                </div>
            ),
        },
    ];

    return (
        <div className="my-5">
            <div className="d-flex justify-content-end align-items-end pb-10">
                <Tooltip title="Résultats">
                    <Button
                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                        size={"large"}
                        // loading={loading}
                        style={{
                            backgroundColor: appTheme.colors.primary,
                            color: "white"
                        }}
                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                        onClick={handleSubmit}
                    >
                        {!chargement &&
                            <span className='indicator-label text-white'>Enregistrer</span>}
                        {chargement && (
                            <span className='indicator-progress fs-9 text-white'
                                  style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                        )}
                    </Button>
                </Tooltip>
            </div>
            {(balanceData ?? []).length > 0 && <div className="card-header border-0 pt-2">
                <SearchHeader
                    placeholder="Rechercher par le numero de compte, le libellé..."
                    // handleSearch={handleSearchChange}
                />
            </div>}
            <div className="table-responsive">
                <Table
                    onRow={(record) => {
                        return {
                            onDoubleClick: () => {
                                handleModalOpen(record)
                            }
                        };
                    }}
                    rowClassName={() =>
                        `cursor-pointer h-3px p-0 m-0 lh-0.5`
                    }
                    columns={columns}
                    rowKey={(record, index) => index}
                    dataSource={balanceData ?? []}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    scroll={{ x: "1300" }}
                    loading={chargement}
                    rowSelection
                    size={"small"}
                />
            </div>
            <ModalCommentaireBalance
                setBalanceData={setBalanceData}
                balanceData={balanceData}
                balance={balance}
                visible={isModalOpen}
                handleVisibility={handleModalOpen}
                loading={loading}
                isIntang={true}
                setLoading={setLoading}
                setIsTaskSave={setIsTaskSave}
            />
        </div>
    );
};

export { HistoriqueControleIntangibilite };
