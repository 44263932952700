import React, {useEffect, useState} from "react";
import {Button, Table, Tooltip, Typography} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {succesMessage} from "../../../../../../../helpers/AssetsHelpers";
import swal from "sweetalert";
import {ajouterStrategieFaitPar} from "../../../../../../../data/seuilSignificatif";
import {getListeMembreMission} from "../../../../../../../data/exercice";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

const FaitPar = ({
         strategies,
         mission,
         faitsPar,
         codeSommaire,
         date,
         heureEntree,
         tache_id,
         intervention_id,
         isProvisoire,
    }) => {
    const [selectItems, setSelectItems] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchUserMission = async (id) => {
            setLoading(true);
            getListeMembreMission(id)
                .then((res) => {
                    if(res){
                        setUsers(res);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        };

        fetchUserMission(mission.id);
    }, [mission]);

    useEffect(() => {
        if(faitsPar && faitsPar.length > 0 ){
            const faits = faitsPar.map((niveau) => ({
                action: niveau.action,
                seuilSignification: niveau.seuilSignification,
                user: niveau.user,
                date: new Date(niveau.date).toISOString().split('T')[0],
            }))

            setSelectItems(faits)
        }
    }, [faitsPar, setSelectItems])


    const handleAdd = (e) => {
        e.preventDefault()
        const newElement = {
            action: '',
            seuilSignification: false,
            user: null,
            date: '',
        };

        setSelectItems([...selectItems, newElement]);
    };

    const handleDeleteElement = (index) => {
        const updatedList = selectItems.filter((_, i) => i !== index);
        setSelectItems(updatedList);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            usersDatas: selectItems,
            strategie_id: strategies.id,
            exercice_id: mission.id,
            client_id: mission.client_id,
            codeSommaire ,
            heureEntree,
            date,
            tache_id,
            intervention_id,
            isProvisoire
        };

        const { status, message } = await ajouterStrategieFaitPar(data);
        if (status === "success") {
            setLoading(false);
            await swal(succesMessage, "", "success");
        } else {
            await swal(message, '', 'error')
            setLoading(false)
        }
    };

    const columns = [
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            sorter: true,
            render: (_, record, index) => (
                <select
                    style={{fontSize: '14px'}}
                    name='action'
                    className="form-select"
                    onChange={(e) => {
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  action: e.target.value} : item
                        );

                        setSelectItems(updatedList);
                    }}
                    value={record.action}
                >
                    <option>Selectionner une action...</option>
                    {['Fait par', 'Revu par', 'Associé', 'Assurance qualité'].map((opt, index) => (
                        <option key={index} value={opt}>
                            {opt}
                        </option>
                    ))}
                </select>
            ),
        },
        {
            title: () => <TableTitle>Seuil de signification</TableTitle>,
            dataIndex: 'seuilSignification',
            render: (_, record, index) => (
                <select
                    style={{fontSize: '14px'}}
                    name='seuilSignification'
                    className="form-select"
                    onChange={(e) => {
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  seuilSignification: e.target.value === 'PROVISOIRE'} : item
                        );

                        setSelectItems(updatedList);
                    }}
                    value={record.seuilSignification ? 'PROVISOIRE' : 'DEFINITIF'}
                >
                    {['PROVISOIRE', 'DEFINITIF'].map((opt, index) => (
                        <option key={index} value={opt}>
                            {opt}
                        </option>
                    ))}
                </select>
            ),
        },
        {
            title: () => <TableTitle>Nom et Prénoms</TableTitle>,
            dataIndex: 'user',
            render: (_, record, index) => (
                <select
                    style={{fontSize: '14px'}}
                    name='user'
                    className="form-select"
                    onChange={(e) => {
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  user: e.target.value} : item
                        );

                        setSelectItems(updatedList);
                    }}
                    value={record.user}
                >
                    <option>Sélectionner utilisateur...</option>
                    {users?.map((opt, index) => (
                        <option key={index} value={opt.id}>
                            {`${opt.initial || ""} - ${opt.nom_user.toUpperCase() || ""} ${opt.prenom_user.toUpperCase() || ""}`}
                        </option>
                    ))}
                </select>
            ),
        },
        {
            title: () => <TableTitle>Date</TableTitle>,
            dataIndex: 'date',
            render: (_, record, index) => (
                <input
                    style={{fontSize: '12px'}}
                    className="form-control mb-2"
                    type="date"
                    value={record.date}
                    name='date'
                    onChange={(e) =>{
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  date: e.target.value} : item
                        );

                        setSelectItems(updatedList);
                    }}
                />
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteElement(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <div className='pb-15'>
                {<div className="d-flex justify-content-end align-items-end mb-10">
                    <Tooltip title="Enregistrer">
                        <Button
                            disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            size={"large"}
                            // loading={loading}
                            style={{
                                backgroundColor: appTheme.colors.secondary,
                                color: "white"
                            }}
                            icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                            onClick={handleSubmit}
                        >
                            {!loading &&
                                <span className='indicator-label text-white'>Enregistrer</span>}
                            {loading && (
                                <span className='indicator-progress fs-9 text-white'
                                      style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                    </Tooltip>
                </div>}
                <div className="card-header">
                    <div className="card-title">
                        <Title style={{
                            display: "block",
                            fontWeight: '600',
                            color: appTheme.colors.primary,
                            // textAlign: "center"
                        }} level={4}>{"Fait par"}</Title>
                    </div>
                </div>
                <div className=''>
                    <div className="tab-content" id="myTabContent">
                        <div className="table-responsive">
                            <div className="d-flex flex-column me-5 mb-3">
                                <div className="d-flex text-gray-700 fw-bold fs-4 mt-3">
                                    <button
                                        onClick={handleAdd}
                                        className="btn btn-sm btn-primary"
                                    >
                                        Ajouter un champ
                                    </button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    rowClassName={() =>
                                        `h-3px p-0 m-0 lh-0.5`
                                    }
                                    columns={columns}
                                    rowKey={(record) => record.id}
                                    dataSource={selectItems}
                                    loading={loading}
                                    rowSelection
                                    size={"small"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export {FaitPar}