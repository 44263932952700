export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname

export function getCurrentUrl(pathname) {
    return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname, url) {
    const current = getCurrentUrl(pathname)
    if (!current || !url) {
        return false
    }

    if (current === url) {
        return true
    }

    return current.indexOf(url) > -1;


}


export const optionsSwal = {
    title: 'Êtes vous sûre ?',
    text: ``,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d1ec51',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Oui',
    cancelButtonText: 'Annuler',
}

export  const succesMessage = 'Opération effectuée avec succès'

export const optionsPasswordSwal = {
    title: 'Êtes vous sûre de vouloir modifier le mot de passe ?',
    text: ``,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d1ec51',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Oui',
    cancelButtonText: 'Annuler',
}
