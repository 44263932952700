import React, {useEffect, useState} from "react";
import {Button, Input, Table, Tooltip, Typography} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../../helpers/AssetsHelpers";
import {ajouterMaterialiteSpecifique} from "../../../../../../../data/seuilSignificatif";
import {getMaterialiteSpecifique} from "../../../../../../../data/stategieGlobale";
import {CustomInputNumber} from "../../../../../../layout/tools/InputNumber";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

const MaterialitePerformanceSepcifique = ({
          mission,
          strategies,
          // materialiteSpecifiques,
          isProvisoire,
          codeSommaire ,
          heureEntree,
          date,
          tache_id,
          intervention_id,
    }) => {
    const [selectItems, setSelectItems] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [selectItemsControle, setSelectItemsControle] = useState([
    //     {
    //         libelle: 'Année N-2',
    //         montant: 0,
    //         commentaire: ''
    //     },
    //     {
    //         libelle: 'Année N-1',
    //         montant: 0,
    //         commentaire: ''
    //     },
    //     {
    //         libelle: 'Année N provisoire',
    //         montant: 0,
    //         commentaire: ''
    //     },
    //     {
    //         libelle: 'Année N définitive',
    //         montant: 0,
    //         commentaire: ''
    //     }
    // ]);

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 50,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            // setEquipeClients([]);
        }
    };

    useEffect(() => {
        if(strategies && isProvisoire){
            getMaterialiteSpecifique(strategies.id)
                .then((res) => {
                    if(res.result && res.result.length > 0){
                        const niveaux = res.result.map((niveau) => ({
                            poste: niveau.poste,
                            montantDef: niveau.montantDef || 0,
                            montantProv: niveau.montantProv || 0,
                            anneN1: niveau.montantN1 || 0,
                            anneN2: niveau.montantN1 || 0,
                            commentaire: niveau.commentaire
                        }))

                        setSelectItems(niveaux)
                    }
                })
        }
        else{
            setSelectItems([])
        }
    }, [ isProvisoire, strategies ])

    const handleAdd = (e) => {
        e.preventDefault()
        const newElement = {
            poste: '',
            montantProv: 0,
            montantDef: 0,
            anneN1: 0,
            anneN2: 0,
            commentaire: ''
        };

        setSelectItems([...selectItems, newElement]);
    };

    const handleMontantDefInput = (value, index) => {
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  montantDef: parseInt(value)} : item
        );

        setSelectItems(updatedList);
    }

    const handleMontantProvInput = (value, index) => {
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  montantProv: parseInt(value)} : item
        );

        setSelectItems(updatedList);
    }

    const handleAnneN1Input = (value, index) => {
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  anneN1: parseInt(value)} : item
        );

        setSelectItems(updatedList);
    }

    const handleAnneN2Input = (value, index) => {
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  anneN2: parseInt(value)} : item
        );

        setSelectItems(updatedList);
    }

    const handleDeleteElement = (index) => {
        const updatedList = selectItems.filter((_, i) => i !== index);
        setSelectItems(updatedList);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            materialiteData: selectItems,
            strategie_id: strategies.id,
            exercice_id: mission.id,
            client_id: mission.client_id,
            codeSommaire ,
            heureEntree,
            date,
            tache_id,
            intervention_id,
            isProvisoire
        };

        const { status, message } = await ajouterMaterialiteSpecifique(data);
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    };

    const columns = [
        {
            title: () => <TableTitle>Postes des E/F</TableTitle>,
            dataIndex: 'anneNDef',
            sorter: true,
            width: 200,
            render: (_, record, index) => (
                <Input.TextArea
                    style={{fontSize: "14px", fontWeight: "600"}}
                    size="large"
                    bordered={false}
                    placeholder="Saisisser le poste..."
                    value={record.poste}
                    maxLength={2000}
                    showCount
                    autoSize={{
                        minRows: 1,
                        maxRows: 5,
                    }}
                    onChange={(e) =>{
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  poste: e.target.value } : item
                        );

                        setSelectItems(updatedList);
                    }}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N DEF</TableTitle>,
            dataIndex: 'montantDef',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.montantDef}
                    name='montantDef'
                    onChange={handleMontantDefInput}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N PROV</TableTitle>,
            dataIndex: 'montantProv',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.montantProv}
                    name='montantProv'
                    onChange={handleMontantProvInput}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N-1</TableTitle>,
            dataIndex: 'anneN1',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.anneN1}
                    name='anneN1'
                    onChange={handleAnneN1Input}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Année N-2</TableTitle>,
            dataIndex: 'anneN2',
            width: 150,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.anneN2}
                    name='anneN2'
                    onChange={handleAnneN2Input}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 300,
            render: (_, record, index) => (
                <>
                    <div className="">
                        <Input.TextArea
                            style={{fontSize: "14px", fontWeight: "600"}}
                            size="large"
                            bordered={false}
                            placeholder="Saisisser le commentaire..."
                            value={record.commentaire}
                            maxLength={2000}
                            showCount
                            autoSize={{
                                minRows: 2,
                                maxRows: 8,
                            }}
                            onChange={(e) =>{
                                const updatedList = selectItems.map((item, i) =>
                                    i === index ? { ...item,  commentaire: e.target.value } : item
                                );
                                setSelectItems(updatedList);
                            }}
                        />
                    </div>
                </>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 70,
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteElement(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    return(
        <>
            <div className="mb-15">
                <div className="card-header">
                    <div className="card-title">
                        <Title style={{
                            display: "block",
                            fontWeight: '600',
                            color: appTheme.colors.primary,
                            // textAlign: "center"
                        }} level={4}>{"Matérialité pour les circonstances spécifiques"}</Title>
                    </div>
                </div>
                <div className="d-flex justify-content-end align-items-end mb-10">
                    <Tooltip title="Enregistrer">
                        <Button
                            disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            size={"large"}
                            // loading={loading}
                            style={{
                                backgroundColor: appTheme.colors.secondary,
                                color: "white"
                            }}
                            icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                            onClick={handleSubmit}
                        >
                            {!loading &&
                                <span className='indicator-label text-white'>Enregistrer</span>}
                            {loading && (
                                <span className='indicator-progress fs-9 text-white'
                                      style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                    </Tooltip>
                </div>
                <form className="">
                    <div className="fw-bold text-gray-600 mb-5 mt-5 mx-auto" style={{fontSize: '14px'}}>
                        (ISA 320.11, ISA 320.14)
                        Attentes spécifiques des utilisateurs nécessitant un niveau de signification inférieur à la matérialité globale pour certains domaines.
                        Reporter-vous aux normes ISA 320.10 et A.11 A.12. Quelles attentes, sensibilité et exigences /réglementaires spécifiques influenceraient les décisions économique d'un utlisateur ?
                    </div>
                    <div className="tab-content" id="myTabContent">
                        <div className="table-responsive">
                            <div className="d-flex flex-column me-5 mb-4">
                                <div className="d-flex text-gray-700 fw-bold fs-7 mt-3">
                                    <button
                                        onClick={handleAdd}
                                        className="btn btn-sm btn-primary fs-4 fw-bolder"
                                    >
                                        Ajouter un champ
                                    </button>
                                </div>
                            </div>
                            <div className="table-responsive">
                                <Table
                                    rowClassName={() =>
                                        `h-3px p-0 m-0 lh-0.5`
                                    }
                                    columns={columns}
                                    rowKey={(record) => record.id}
                                    dataSource={selectItems}
                                    pagination={tableParams.pagination}
                                    loading={loading}
                                    onChange={handleTableChange}
                                    rowSelection
                                    size={"small"}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
export {MaterialitePerformanceSepcifique}