import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import {succesMessage} from "../../../../../helpers/AssetsHelpers";
import {
    ajouterExamenAnalytique,
    ajouterFeuilleMaitresse,
    listeExamenAnalytique, listeFeuilleMaitresse
} from "../../../../../data/balance/procedureAnalytique";
import PageToolbarHeader from "../../../../modals/PageToolbarHeader";
import {StepBody, StepHeader} from "../../../../common/Step.components";
import ModalNavBarMenu from "../../../../modals/ModalNavBarMenu";
import {faBookOpenReader, faIdCard, faScaleBalanced} from "@fortawesome/free-solid-svg-icons";
import MenuEA from "./EC3/examenAnalytique/MenuEA";
import MenuBC from "./EC3/examenAnalytique/MenuBC";
import {ExamenAnalytiqueEFPrint} from "./EC3/examenAnalytique/ExamenAnalytiqueEFPrint";
import {ExamenAnalytiqueBalancePrint} from "./EC3/examenAnalytique/ExamenAnalytiqueBalancePrint";
import {useNavigate} from "react-router-dom";
import UserCycleIdentification from "../../UserCycleIdentification";

const ExamenAnalytique = ({
          mission,
          client,
          code,
          // modalId,
          prev
      }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [tache_id, setTache_id] = useState(null);
    const [user_id, setUser_id] = useState(null);
    const [date, setDate] = useState('');
    const [heureEntree, setHeureEntree] = useState('');
    const [intervention_id, setIntervention_id] = useState(null);
    const [codeSommaire] = useState(code || 'EC3.1');
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [actifHistory, setActifHistory] = useState([]);
    const [passifHistory, setPassifHistory] = useState([]);
    const [resultHistory, setResultHistory] = useState([]);
    const [sigTabHistory, setSigTabHistory] = useState([]);
    const [balanceData, setBalanceData] = useState([]);
    const [selectYear, setSelectYear] = useState(mission.annee || "");
    const [missionSelected, setMissionSelected] = useState(mission || null);
    const [isProvisoire, setIsProvisoire] = useState('1');
    const [isTaskSave, setIsTaskSave] = useState([]);
    const [auth, setAuth] = useState(false);
    const navigate = useNavigate();

    const fetchFeuilleMaitresse = (id) => {
        listeFeuilleMaitresse(id)
            .then((res) => {
                if (res.result) {
                    setBalanceData(res.result.sort((a, b) => a.compte.localeCompare(b.compte)))
                } else {
                    setBalanceData([])
                }
            })
    }

    const fetchAnalyticExam = async (id) => {
        try {
            const res = await listeExamenAnalytique(id);
            if (res.result && res.result.length > 0) {
                const actifs = res.result.filter((item) => item.libelleBilan?.includes("ACTIFS"));
                const passifs = res.result.filter((item) => item.libelleBilan?.includes("PASSIFS"));
                const results = res.result.filter((item) => item.libelleBilan?.includes("RESULTATS"));
                const sigs = res.result.filter((item) => item.libelleBilan?.includes("SIG"));

                setActifHistory(actifs.map(({ compte, libelle, soldeN, soldeN_1, commentaire }) => ({ compte, libelle, soldeN, soldeN_1, commentaire })));
                setPassifHistory(passifs.map(({ compte, libelle, soldeN, soldeN_1, commentaire }) => ({ compte, libelle, soldeN, soldeN_1, commentaire })));
                setResultHistory(results.map(({ compte, libelle, soldeN, soldeN_1, commentaire }) => ({ compte, libelle, soldeN, soldeN_1, commentaire })));
                setSigTabHistory(sigs.map(({ compte, libelle, soldeN, soldeN_1, commentaire }) => ({ compte, libelle, soldeN, soldeN_1, commentaire })));
            }
        } catch (error) {
            console.error('Error fetching analytic exam data:', error);
        }
    };

    useEffect(() => {
        if (missionSelected) {
            fetchAnalyticExam(missionSelected.id);
            fetchFeuilleMaitresse(missionSelected.id);
        }
    }, [missionSelected]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (auth || (isTaskSave ?? []).length > 0) {
                e.preventDefault();
                e.returnValue = ""; // required for chrome
                return true; // Return a truthy value
            }
            return null; // Allow navigation if no conditions met
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload)
        };
    }, [auth, isTaskSave, navigate]);

    const formatData = (items, libelleBilan) => {
        return items.map((item) => ({
            libelleBilan,
            compte: item.compte,
            libelle: item.libelle,
            soldeN: item.soldeN,
            soldeN_1: item.soldeN_1,
            variation: item.soldeN - item.soldeN_1,
            percentVariation: (((item.soldeN_1) === 0 ? 0 : (item.soldeN - item.soldeN_1) / item.soldeN_1) * 100),
            commentaire: item.commentaire,
        }));
    };

    const formatBalanceData = (items) => {
        return items.map((item) => ({
            compte: item.compte,
            libelle: item.libelle,
            soldeN: item.soldeN,
            soldeN_1: item.soldeN_1,
            variation: item.soldeN - item.soldeN_1,
            percentVariation: (((item.soldeN_1) === 0 ? 0 : (item.soldeN - item.soldeN_1) / item.soldeN_1) * 100),
            commentaire: item.commentaire,
        }));
    };

    const handleSubmit = async () => {
        setLoading(true);

        if (activeTab === 0) {
            setLoading(false);
            return;
        }

        const actifs = formatData(actifHistory, "ACTIFS");
        const passif = formatData(passifHistory, "PASSIFS");
        const resultats = formatData(resultHistory, "RESULTATS");
        const sigData = formatData(resultHistory, "SIG");

        const datasE = [...actifs, ...passif, ...resultats, ...sigData];
        const datasB = formatBalanceData(balanceData);

        const dataBilan = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            isProvisoire,
            codeSommaire,
            balanceData: datasB,
            exercice_id: missionSelected.id,
            client_id: client.id
        };

        const dataEF = {
            intervention_id,
            date,
            heureEntree,
            tache_id,
            user_id,
            isProvisoire,
            codeSommaire,
            balanceData: datasE,
            exercice_id: missionSelected.id,
            client_id: client.id
        };

        const { status, message } = activeTab === 1 ? await ajouterExamenAnalytique(dataEF) : activeTab === 2 ? await ajouterFeuilleMaitresse(dataBilan) : {};
        if (status === 'success') {
            setLoading(false);
            setAuth(false);
            setIsTaskSave(prevState => (prevState ?? []).filter((item) => item !== activeTab));
            await swal(succesMessage, '', 'success');
        } else {
            await swal(message, '', 'error');
            setLoading(false);
        }
    };

    const formSteps = [
        {
            code: "ID",
            title: "IDENTIFICATIONS",
            icon: faIdCard,
            content: (
                <UserCycleIdentification
                    setDate={setDate}
                    dateDebut={date}
                    setTache_id={setTache_id}
                    setIntervention_id={setIntervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    mission={mission}
                    prev={prev}
                    loading={loading}
                    codeSommaire={codeSommaire}
                    setIsAuthorized={setAuth}
                    isAuthorized={auth}
                    setActiveStep={setActiveTab}
                />
            ),
        },
        {
            code: "EA-EF",
            title: `EXAMEN ANALYTIQUE DES ETATS FINANCIERS - ${selectYear}`,
            icon: faBookOpenReader,
            content: (
                <MenuEA
                    client={client}
                    actifHistory={actifHistory}
                    passifHistory={passifHistory}
                    resultHistory={resultHistory}
                    setActifHistory={setActifHistory}
                    setPassifHistory={setPassifHistory}
                    setResultHistory={setResultHistory}
                    sigTabHistory={sigTabHistory}
                    setSigTabHistory={setSigTabHistory}
                    mission={missionSelected}
                    isProvisoire={isProvisoire}
                    chargement={loading}
                    handleSubmit={handleSubmit}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
        {
            code: "EA-BC",
            title: `EXAMEN ANALYTIQUE DES BALANCES - ${selectYear}`,
            description: 'Bilan Passif',
            icon: faScaleBalanced,
            content: (
                <MenuBC
                    mission={missionSelected}
                    client={client}
                    setBalanceData={setBalanceData}
                    balanceData={balanceData}
                    chargement={loading}
                    isProvisoire={isProvisoire}
                    handleSubmit={handleSubmit}
                    setIsTaskSave={setIsTaskSave}
                />
            ),
        },
    ];

    return ( 
        <>
            <div className="mx-5">
                <PageToolbarHeader
                    prev={prev} codeSommaire={codeSommaire}
                    pageTitle={"EC3.1 - Examen Analytique"}
                    mission={missionSelected}
                    setMission={setMissionSelected}
                    isProvisoire={isProvisoire}
                    setIsProvisoire={setIsProvisoire}
                    selectYear={selectYear}
                    setSelectYear={setSelectYear}
                    loading={loading}
                    setLoading={setLoading}
                    client={client}
                    setIsModalOpen={setIsModalOpen}
                    isModalOpen={isModalOpen}
                    setDate={setDate}
                    setTache_id={setTache_id}
                    tache_id={tache_id}
                    setIntervention_id={setIntervention_id}
                    intervention_id={intervention_id}
                    setHeureEntree={setHeureEntree}
                    setUser_id={setUser_id}
                    isTaskSave={isTaskSave}
                    isExcelPrint={true}
                    isLandingScape={true}
                    isEF={(activeTab === 1)}
                    printTitle={(`${(activeTab === 1) ? "EXAMEN ANALYTIQUE DES EF" : "EXAMEN ANALYTIQUE DES BALANCES"} ${selectYear}`)}
                    // excelData={(activeTab === 1) ? [...actifHistory, ...passifHistory, ...resultHistory, ...sigTabHistory] : balanceData}
                    excelData={(activeTab === 1) ? [
                        {id: 1, libelle: "ACTIFS", data: actifHistory},
                        {id: 2, libelle: "PASSIFS", data: passifHistory},
                        {id: 3, libelle: "COMPTE RESULTATS", data: resultHistory},
                        {id: 4, libelle: "SIG", data: sigTabHistory},
                    ] : balanceData}
                    printContent={(
                        (activeTab === 1) ?
                            <ExamenAnalytiqueEFPrint
                                dataActifs={actifHistory}
                                dataPassifs={passifHistory}
                                dataResults={resultHistory}
                                dataSig={sigTabHistory}
                            />
                            :
                            <ExamenAnalytiqueBalancePrint
                                data={balanceData}
                            />

                    )}
                />
                <StepHeader>
                    <ModalNavBarMenu
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                        steps={formSteps}
                        setLoading={setLoading}
                        loading={loading}
                        isTaskSave={isTaskSave}
                        auth={auth}
                    />
                </StepHeader>
                <StepBody>
                    <>{formSteps[activeTab].content}</>
                </StepBody>
            </div>
        </>
    )
}

export {ExamenAnalytique}