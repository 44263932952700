import React, {useEffect, useState} from "react";
import swal from "sweetalert";
import { succesMessage } from "../../../../../../../helpers/AssetsHelpers";
import {Button, Input, Table, Tag, Tooltip} from "antd";
import {ajouterBudgetTemps} from "../../../../../../../data/stategieGlobale";
import {CustomInputNumber} from "../../../../../../layout/tools/InputNumber";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import FormLabel from "../../../../../../common/Form/FormLabel";

const BudgetTemps = ({ mission, strategies, isProvisoire, budgetTemps, selectedMap, chargement, etatsFinanciers}) => {
    const [loading, setLoading] = useState(false);
    const [selectItems, setSelectItems] = useState([]);
    const [totalBilan, setTotalBilan] = useState(0);
    const [totalProduit, setTotalProduit] = useState(0);
    const [conclusionEquipe, setConclusionEquipe] = useState('');
    const [tranches, setTranches] = useState([
        {
            id: 1,
            min: 0,
            max: 100000000,
            heureMin: 50,
            heureMax: 79
        },
        {
            id: 2,
            min: 100000001,
            max: 200000000,
            heureMin: 80,
            heureMax: 119
        },
        {
            id: 3,
            min: 200000001,
            max: 500000000,
            heureMin: 120,
            heureMax: 199
        },
        {
            id: 4,
            min: 500000001,
            max: 1000000000,
            heureMin: 200,
            heureMax: 249
        },
        {
            id: 5,
            min: 1000000001,
            max: 2500000000,
            heureMin: 250,
            heureMax: 349
        },
        {
            id: 6,
            min: 2500000001,
            max: 5000000000,
            heureMin: 350,
            heureMax: 499
        },
        {
            id: 7,
            min: 5000000001,
            max: 10000000000,
            heureMin: 500,
            heureMax: 699
        },
        {
            id: 8,
            min: 10000000001 ,
            max: 30000000000,
            heureMin: 700,
            heureMax: 799
        },
        {
            id: 9,
            min: 30000000001,
            max: 80000000000,
            heureMin: 800,
            heureMax: 899
        },
        {
            id: 10,
            min: 80000000001,
            max: 100000000000,
            heureMin: 900,
            heureMax: 999
        },
        {
            id: 11,
            min: 100000000001,
            max: 200000000000,
            heureMin: 1000,
            heureMax: 1199
        },
        {
            id: 12,
            min: 200000000001,
            max: 500000000000,
            heureMin: 1200,
            heureMax: 2000
        },
    ])

    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });

    };

    useEffect(() => {
        setLoading(true);

        if (strategies) {
            const { totalBilanEquipe, totalProduitEquipe, conclusionEquipe } = strategies;
            setTotalBilan(totalBilanEquipe || 0);
            setTotalProduit(totalProduitEquipe || 0);
            setConclusionEquipe(conclusionEquipe || "");
        }

        if ((budgetTemps ?? []).length > 0) {
            const updateArray = [];
            budgetTemps.forEach((item) => {
                const { tranche_id, heureMin, heureMinRetenu, heureMax, heureMaxRetenu } = item;
                const filteredTranche = tranches.find((tranche) => tranche.id === tranche_id);

                if (filteredTranche) {
                    const { min, max } = filteredTranche;
                    const budget = {
                        tranche_id,
                        tranche: `${Math.round(min / 1000000).toLocaleString('fr')} - ${Math.round(max / 1000000).toLocaleString('fr')}`,
                        heureMin,
                        heureMinRetenu,
                        heureMax,
                        heureMaxRetenu
                    };
                    updateArray.push(budget);
                }
            });

            setSelectItems(updateArray);
            setLoading(false);
        } else {
            if ((etatsFinanciers ?? []).length > 0) {
                const actifs = etatsFinanciers[0]?.Lea_BilanActifs?.filter(
                    (item) => item.montant !== 0 && item.montantN1 !== 0 && item.isProvisoire === isProvisoire
                );
                const results = etatsFinanciers[0]?.Lea_CompteResultats?.filter(
                    (item) => item.montant !== 0 && item.montantN1 !== 0 && item.isProvisoire === isProvisoire
                );

                const totalBZ = (actifs ?? []).find((actif) => actif.ref?.includes("BZ"));
                const totalXB = (results ?? []).find((actif) => actif.ref?.includes("XB"));

                setTotalBilan(totalBZ?.montant || 0);
                setTotalProduit(totalXB?.montant || 0);

                const total = (totalBZ?.montant ?? 0) + (totalXB?.montant ?? 0);
                const filtered = tranches.find(
                    (item) => item.min <= total && item.max >= total
                );

                if (filtered) {
                    const updated = {
                        tranche_id: filtered.id,
                        tranche: `${Math.round(filtered.min / 1000000).toLocaleString('fr')} - ${Math.round(filtered.max / 1000000).toLocaleString('fr')}`,
                        heureMin: filtered.heureMin,
                        heureMinRetenu: 0,
                        heureMax: filtered.heureMax,
                        heureMaxRetenu: 0
                    };
                    setSelectItems([updated]);
                }
            }
            setLoading(false);
        }

    }, [mission, isProvisoire, etatsFinanciers, budgetTemps, strategies]);

    const handleTotalProduit = (value) => {
        setTotalProduit(parseInt(value))

        const bilan = totalBilan || 0;
        const total = (parseInt(value) + bilan) || 0;

        const filtered = tranches.find((item) =>
            item.min <= total && item.max >= total
        );

        if (filtered) {
            const updated = {
                tranche_id: filtered.id,
                tranche: `${Math.round(filtered.min / 1000000).toLocaleString('fr')} - ${Math.round(filtered.max / 1000000).toLocaleString('fr')}`,
                heureMin: filtered.heureMin,
                heureMinRetenu: 0,
                heureMax: filtered.heureMax,
                heureMaxRetenu: 0
            };
            setSelectItems([updated]);
        }
    }

    const handleTotalBilan = (value) => {
        setTotalBilan(parseInt(value))

        const produit = totalProduit || 0;
        const total = (parseInt(value) + produit) || 0;

        const filtered = tranches.find((item) =>
            item.min <= total && item.max >= total
        );

        if (filtered) {
            const updated = {
                tranche_id: filtered.id,
                tranche: `${Math.round(filtered.min / 1000000).toLocaleString('fr')} - ${Math.round(filtered.max / 1000000).toLocaleString('fr')}`,
                heureMin: filtered.heureMin,
                heureMinRetenu: 0,
                heureMax: filtered.heureMax,
                heureMaxRetenu: 0
            };
            setSelectItems([updated]);
        }
    }


    const handleHeureMinInput = (value, index) => {
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  heureMin: value} : item
        );

        setSelectItems(updatedList);
    }

    const handleHeureMaxInput = (value, index) => {
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  heureMax: value} : item
        );

        setSelectItems(updatedList);
    }

    const handleHeureMaxRetenuInput = (value, index) => {
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  heureMaxRetenu: value} : item
        );

        setSelectItems(updatedList);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            totalBilanEquipe: totalBilan,
            totalProduitEquipe: totalProduit,
            resultTotalEquipe: totalBilan + totalProduit,
            conclusionEquipe,
            datas: selectItems,
            exercice_id: mission.id,
            client_id: mission.client_id,
            strategie_id: strategies?.id,
            isProvisoire
        };

        const { status, message } = await ajouterBudgetTemps(data);
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    };

    const columns = [
        {
            title: () => <TableTitle>N° Ordre</TableTitle>,
            dataIndex: 'index',
            width: 70,
            key: 'index',
            render: (_, record) => (
                <Tag>{record.tranche_id}</Tag>
            ),
        },
        {
            title: () => <TableTitle>Tranche (millions)</TableTitle>,
            dataIndex: 'users',
            width: 200,
            key: 'users',
            render: (_, record) => (
                <span className="fs-4">
                    {record.tranche}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Nbre Minimal d'heures de travail</TableTitle>,
            dataIndex: 'heureMin',
            width: 200,
            key: 'heureMin',
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.heureMin}
                    name='heureMax'
                    onChange={handleHeureMinInput}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Nbre Maximal d'heures de travail</TableTitle>,
            dataIndex: 'heureMax',
            width: 200,
            key: 'heureMax',
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.heureMax}
                    name='heureMax'
                    onChange={handleHeureMaxInput}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Nbre d'heures de travail retenu</TableTitle>,
            dataIndex: 'heureMaxRetenu',
            width: 200,
            key: 'heureMaxRetenu',
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.heureMaxRetenu}
                    name='heureMaxRetenu'
                    onChange={handleHeureMaxRetenuInput}
                    index={index}
                />
            ),
        },
    ];

    const columnsProgrammes = [
        {
            title: () => <TableTitle>Initial/Noms</TableTitle>,
            dataIndex: 'initial',
            key: 'initial',
            width: 150,
            render: (_, record) => (
                <Tag>{record.initial}</Tag>
            ),
        },
        {
            title: () => <TableTitle>Programme</TableTitle>,
            dataIndex: 'programme',
            width: 300,
            key: 'index',
            render: (_, record,) => (
                <span className="fw-bolder fs-4">
                    {record.programme}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Total Honoraire Provisoire</TableTitle>,
            dataIndex: 'totalProv',
            key: 'totalProv',
            width: 120,
            render: (_, record) => (
                <Tag>
                    <div style={{fontSize: 14}} className="text-end fw-bolder">
                        {record.totalProv.toLocaleString("fr")}
                    </div>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Total Honoraire Définitif</TableTitle>,
            dataIndex: 'totalDef',
            key: 'totalDef',
            width: 120,
            render: (_, record) => (
                <Tag>
                    <div style={{fontSize: 14}} className="text-end fw-bolder">
                        {record.totalDef.toLocaleString("fr")}
                    </div>
                </Tag>
            ),
        },
    ];


    return (
        <div className="pb-10">
            {<div className="d-flex justify-content-end align-items-end mb-10">
                <Tooltip title="Enregistrer">
                    <Button
                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                        size={"large"}
                        // loading={loading}
                        style={{
                            backgroundColor: appTheme.colors.secondary,
                            color: "white"
                        }}
                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                        onClick={handleSubmit}
                    >
                        {!loading &&
                            <span className='indicator-label text-white'>Enregistrer</span>}
                        {loading && (
                            <span className='indicator-progress fs-9 text-white'
                                  style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                        )}
                    </Button>
                </Tooltip>
            </div>}
            <div className=''>
                <div className="card card-dashed h-xl-50 w-lg-100 flex-row flex-stack flex-wrap p-2 mt-3">
                    <div className="d-flex flex-column py-2">
                        <h1 className="d-flex align-items-center fw-bold fs-4" style={{ fontSize: '15px' }}>
                            Total BZ + XB
                        </h1>
                        <div className="d-flex align-items-center">
                            <div className="col-md-4 me-5">
                                <div className="text-md-start">
                                    <label className="fw-bold form-label mt-3" style={{ fontSize: '14px' }}>
                                        <span>Total Bilan</span>
                                    </label>
                                    <CustomInputNumber
                                        value={totalBilan}
                                        name="totalBilan"
                                        onChange={handleTotalBilan}
                                        index={null}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 me-5">
                                <div className="text-md-start">
                                    <label className="fw-bold form-label mt-3" style={{ fontSize: '12px' }}>
                                        <span>Chiffres d'affaire</span>
                                    </label>
                                    <CustomInputNumber
                                        value={totalProduit}
                                        name="totalProduit"
                                        onChange={handleTotalProduit}
                                        index={null}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-md-end">
                                    <label className="fw-bold form-label mt-3" style={{ fontSize: '12px' }}>
                                        <span>Résultat</span>
                                    </label>
                                    <input
                                        style={{ fontSize: '14px' }}
                                        type="text"
                                        className="form-control"
                                        placeholder="Saisissez..."
                                        value={((totalProduit || 0) + (totalBilan || 0)).toLocaleString('fr')}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='table-responsive my-5'>
                    <Table
                        rowClassName={() =>
                            `cursor-pointer h-3px p-0 m-0 lh-0.5`
                        }
                        columns={columns}
                        rowKey={(record, index) => index}
                        dataSource={selectItems}
                        pagination={tableParams.pagination}
                        onChange={handleTableChange}
                        loading={chargement || loading}
                        rowSelection
                    />
                </div>

                <div className='table-responsive mt-15 mb-15'>
                    <FormLabel label="Programme contrôle et repartition des tâches : " />
                    <Table
                        rowClassName={() =>
                            `cursor-pointer h-3px p-0 m-0 lh-0.5`
                        }
                        columns={columnsProgrammes}
                        rowKey={(record, index) => index}
                        dataSource={selectedMap}
                        pagination={false}
                        scroll={{y: 600}}
                        loading={chargement || loading}
                        rowSelection
                    />
                </div>
                <div className='mt-1'>
                    <FormLabel label="Conclusion sur l'équipe : " />
                    <Input.TextArea
                        size="large"
                        placeholder="Saissiser le contexte"
                        readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                        maxLength={3000}
                        showCount
                        autoSize={{
                            minRows: 5,
                            maxRows: 15,
                        }}
                        value={conclusionEquipe}
                        onChange={(e) => {
                            setConclusionEquipe(e.target.value)
                        }}
                    />
                </div>
            </div>

        </div>
    );
};

export default BudgetTemps;
