import React, {useEffect, useState} from "react";
import {Tooltip, Typography} from "antd";
import {Global} from "./SeuilMaterialite/Global";
import {MaterialitePerformance} from "./SeuilMaterialite/MaterialitePerformance";
import {MaterialitePerformanceSepcifique} from "./SeuilMaterialite/MaterialitePerformanceSepcifique";
import {CaractereSignificatif} from "./SeuilMaterialite/CaractereSignificatif";
import {AnomaliesSignificative} from "./SeuilMaterialite/AnomaliesSignificative";
import {Evaluation} from "./SeuilMaterialite/Evaluation";
import PrincipauxUtilisateurs from "./SeuilMaterialite/PrincipauxUtilisateurs";
import AnomaliePossible from "./SeuilMaterialite/AnomaliePossible";
import {FaitPar} from "./SeuilMaterialite/FaitPar";
import {getStrategieGlobaleMission} from "../../../../../../data/stategieGlobale";
import {appTheme} from "../../../../../../config/theme";
import FormLabel from "../../../../../common/Form/FormLabel";

const {  Title } = Typography;

const menus = [
    {
        id: '1',
        title: 'Objectifs',
        description: 'Objectifs',
        children: []
    },
    {
        id: '2',
        title: 'Identification',
        description: 'Identification',
        children: []
    },
    {
        id: '3',
        title: 'A. Global',
        description: 'A. Global',
        children:  []
    },
    {
        id: '4',
        title: 'B. Anomalies',
        description: 'B. Anomalies',
        children:  []
    },
    {
        id: '5',
        title: ' C. Matérialité',
        description: ' C. Matérialité',
        children:  []
    },
    {
        id: '6',
        title: 'D. Matérialité spécifique',
        description: 'D. Matérialité spécifique',
        children:  []
    },
    {
        id: '7',
        title: 'E. Caractères',
        description: 'E. Caractères',
        children:  []
    },
    {
        id: '8',
        title: 'F. Anomalies insignifiantes',
        description: 'F. Anomalies insignifiantes',
        children:  []
    },
    {
        id: '9',
        title: 'G. Evaluation',
        description: 'G. Evaluation',
        children:  []
    },
    {
        id: '10',
        title: 'Fait par',
        description: 'Fait par',
        children:  []
    },
];

const SeuilSignification = ({
        mission,
        client,
        activeTab,
        isProvisoire,
        strategieFetch,
        etatsFinanciers,
        codeSommaire,
        date,
        heureEntree,
        tache_id,
        intervention_id,
    }) => {
    const [loading, setLoading] = useState(false)
    const [activeTabSeuil, setActiveTabSeuil] = useState(0);
    const [materialiteValue, setMaterialiteValue] = useState({
        montantNProv: 0,
        montantNDef: 0,
        montantN1: 0,
        montantN2: 0,
    });
    const [montantAnomalie, setMontantAnomalie] = useState(0);
    const [strategies, setStrategies] = useState(null);
    const [anomalieSignificatives, setAnomalieSignificatives] = useState(null);
    const [materialiteNiveaux, setMaterialiteNiveaux] = useState([]);
    const [materialitePerformances, setMaterialitePerformances] = useState([]);
    const [materialiteSpecifiques, setMaterialiteSpecifiques] = useState([]);
    const [caractereSignificatifs, setCaractereSignificatifs] = useState([]);
    const [evaluationsFinale, setEvaluationsFinale] = useState([]);
    const [faitsPar, setFaitsPar] = useState([]);
    const [isProvisoireTemp, setIsProvisoireTemp] = useState(false);

    useEffect(() => {
        setIsProvisoireTemp((parseInt(isProvisoire) === 1) || true);
    }, [isProvisoire])

    useEffect(() => {
        if(mission){
            setLoading(true);
            getStrategieGlobaleMission(mission.id)
                .then((res) => {
                    if(res.result){
                        const strategy = res.result.find((item) => item.isProvisoire === isProvisoireTemp)
                        if(strategy){
                            setStrategies(strategy);
                            setAnomalieSignificatives(strategy?.Lea_AnomalieInsignifiantes[0]);
                            setMaterialiteNiveaux(strategy?.Lea_MaterialitePerformanceNiveaus);
                            setMaterialitePerformances(strategy?.Lea_MaterialitePerformances);
                            setMaterialiteSpecifiques(strategy?.Lea_MaterialiteSpecifiques);
                            setCaractereSignificatifs(strategy?.Lea_CaractereSignificatifs);
                            setEvaluationsFinale(strategy?.Lea_EvaluationFinaleSeuils);
                            setFaitsPar(strategy?.Lea_StrategieFaitPars);
                            setLoading(false);
                        }
                        else{
                            setStrategies(null);
                            setAnomalieSignificatives(null);
                            setMaterialiteNiveaux([]);
                            setMaterialitePerformances([]);
                            setMaterialiteSpecifiques([]);
                            setCaractereSignificatifs([]);
                            setEvaluationsFinale([]);
                            setFaitsPar([]);
                        }
                    }
                });
        }
    },[mission, activeTab, isProvisoireTemp]);

    const handleTabChangeSeuil = (tabName, e) => {
        setLoading(true);
        setTimeout(() => {
            e.preventDefault();
            setActiveTabSeuil(tabName);
            setLoading(false)
        }, 1000);
    };

    return(
        <>
            <div className="card d-flex flex-column fv-plugins-bootstrap5 fv-plugins-framework">
                <div className="card-header">
                    <div className="card-title">
                        <Title style={{
                            display: "block",
                            fontWeight: '600',
                            color: appTheme.colors.primary,
                            // textAlign: "center"
                        }} level={2}>{"EC4.7 : Seuil de signification au niveau des comptes et pris dans leur ensemble"}</Title>
                    </div>
                </div>
                <div className="">
                    <div className="flex-lg-row-fluid">
                        {/* begin::Tabs */}
                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-2">
                            {menus.map((step, index) => (
                                <li key={index} className="nav-item bg-hover-light-primary">
                                    <Tooltip title={step.description}>
                                        <button
                                            className={`nav-link text-active-primary fs-4 text-uppercase fw-bolder pb-2 ${activeTabSeuil === index ? 'active' : ''}`}
                                            onClick={(e) => handleTabChangeSeuil(index, e)}
                                        >
                                            {step.title}
                                        </button>
                                    </Tooltip>
                                </li>
                            ))}
                        </ul>
                        {/* end::Tabs */}
                        <div className="separator"></div>
                        {/* begin::Tab content */}
                        <form className="stepper-form">
                            <div className="tab-content" id="myTabContent">
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTabSeuil === 0 ? 'show active' : ''}`} role="tabpanel">
                                    <div className="card mb-4 mb-xl-6">
                                        <div className="card-body">

                                            <div className="mb-4">
                                                <FormLabel label='Objectifs: '/>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                                <Title className="my-5 mx-auto text-center" style={{display: "block", fontWeight: '600', }} level={5}>
                                                    Déterminer l'importance relative globale sur la base des utilisateurs des états financiers
                                                    (norme ISA 320.10); la matérialité des performances requise pour réduire
                                                    (à un niveau acceptable) la probabilité que l'ensemble des inexactitudes non corrigées / non détectées
                                                    dépasse la matérialité globale (ISA 320.11); et la matérialité spécifique et la matérialité de performance
                                                    spécifique le cas échéant (ISA 320.10 et A.11 A.12).
                                                </Title>
                                            </div>
                                        </div>
                                    </div>
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTabSeuil === 1 ? 'show active' : ''}`} role="tabpanel">
                                    {/* begin::Products */}
                                    <PrincipauxUtilisateurs
                                        mission={mission}
                                        strategies={strategies || strategieFetch}
                                        isProvisoire={isProvisoireTemp}
                                        date={date}
                                        intervention_id={intervention_id}
                                        tache_id={tache_id}
                                        heureEntree={heureEntree}
                                        codeSommaire={codeSommaire}
                                        chargement={loading}
                                    />
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTabSeuil === 2 ? 'show active' : ''}`} id="kt_balance_settings_balance" role="tabpanel">
                                    {/* begin::Products */}
                                    <Global
                                        mission={mission}
                                        strategies={strategies || strategieFetch}
                                        setMaterialiteValue={setMaterialiteValue}
                                        materialiteValue={materialiteValue}
                                        isProvisoire={isProvisoireTemp}
                                        date={date}
                                        intervention_id={intervention_id}
                                        tache_id={tache_id}
                                        heureEntree={heureEntree}
                                        codeSommaire={codeSommaire}
                                        chargement={loading}
                                        etatsFinanciers={etatsFinanciers}
                                    />
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTabSeuil === 3 ? 'show active' : ''}`} id="kt_balance_settings_balance" role="tabpanel">
                                    {/* begin::Products */}
                                    <AnomaliePossible
                                        mission={mission}
                                        strategies={strategies || strategieFetch}
                                        isProvisoire={isProvisoireTemp}
                                        date={date}
                                        intervention_id={intervention_id}
                                        tache_id={tache_id}
                                        heureEntree={heureEntree}
                                        codeSommaire={codeSommaire}
                                        chargement={loading}
                                    />
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTabSeuil === 4 ? 'show active' : ''}`} id="kt_balance_settings_balance" role="tabpanel">
                                    {/* begin::Products */}
                                    <MaterialitePerformance
                                        mission={mission}
                                        strategies={strategies || strategieFetch}
                                        materialiteValue={materialiteValue}
                                        setMontantAnomalie={setMontantAnomalie}
                                        client={client}
                                        materialitePerformances={materialitePerformances}
                                        materialiteNiveaux={materialiteNiveaux}
                                        isProvisoire={isProvisoireTemp}
                                        date={date}
                                        intervention_id={intervention_id}
                                        tache_id={tache_id}
                                        heureEntree={heureEntree}
                                        codeSommaire={codeSommaire}
                                        chargement={loading}
                                    />
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTabSeuil === 5 ? 'show active' : ''}`} id="kt_balance_settings_balance" role="tabpanel">
                                    {/* begin::Products */}
                                    <MaterialitePerformanceSepcifique
                                        mission={mission}
                                        strategies={strategies || strategieFetch}
                                        setMaterialiteValue={setMaterialiteValue}
                                        materialiteValue={materialiteValue}
                                        materialiteSpecifiques={materialiteSpecifiques}
                                        isProvisoire={isProvisoireTemp}
                                        date={date}
                                        intervention_id={intervention_id}
                                        tache_id={tache_id}
                                        heureEntree={heureEntree}
                                        codeSommaire={codeSommaire}
                                        chargement={loading}
                                    />
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTabSeuil === 6 ? 'show active' : ''}`} id="kt_balance_settings_balance" role="tabpanel">
                                    {/* begin::Products */}
                                    <CaractereSignificatif
                                        mission={mission}
                                        strategies={strategies || strategieFetch}
                                        caractereSignificatifs={caractereSignificatifs}
                                        isProvisoire={isProvisoireTemp}
                                        date={date}
                                        intervention_id={intervention_id}
                                        tache_id={tache_id}
                                        heureEntree={heureEntree}
                                        codeSommaire={codeSommaire}
                                        chargement={loading}
                                    />
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTabSeuil === 7 ? 'show active' : ''}`} id="kt_balance_settings_balance" role="tabpanel">
                                    {/* begin::Products */}
                                    <AnomaliesSignificative
                                        mission={mission}
                                        strategies={strategies || strategieFetch}
                                        montantAnomalie={montantAnomalie}
                                        anomalieSignificatives={anomalieSignificatives}
                                        isProvisoire={isProvisoireTemp}
                                        date={date}
                                        intervention_id={intervention_id}
                                        tache_id={tache_id}
                                        heureEntree={heureEntree}
                                        codeSommaire={codeSommaire}
                                        chargement={loading}
                                    />
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTabSeuil === 8 ? 'show active' : ''}`} id="kt_balance_settings_balance" role="tabpanel">
                                    {/* begin::Products */}
                                    <Evaluation
                                        mission={mission}
                                        strategies={strategies || strategieFetch}
                                        evaluationFinale={evaluationsFinale}
                                        isProvisoire={isProvisoireTemp}
                                        date={date}
                                        intervention_id={intervention_id}
                                        tache_id={tache_id}
                                        heureEntree={heureEntree}
                                        codeSommaire={codeSommaire}
                                        chargement={loading}
                                    />
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                                {/* begin::Tab pane */}
                                <div className={`tab-pane fade ${activeTabSeuil === 9 ? 'show active' : ''}`} id="kt_balance_settings_balance" role="tabpanel">
                                    {/* begin::Products */}
                                    <FaitPar
                                        mission={mission}
                                        strategies={strategies || strategieFetch}
                                        faitsPar={faitsPar}
                                        isProvisoire={isProvisoireTemp}
                                        date={date}
                                        intervention_id={intervention_id}
                                        tache_id={tache_id}
                                        heureEntree={heureEntree}
                                        codeSommaire={codeSommaire}
                                        chargement={loading}
                                    />
                                    {/* end::Products */}
                                </div>
                                {/* end::Tab pane */}
                            </div>
                        </form>
                        {/* end::Tab content */}
                    </div>

                </div>
            </div>
        </>
    )
}
export {SeuilSignification}