import React from "react";

const AcceptationPrint = ({dataEntite, datasAuditeur, associe_responsable, second_associer}) => {
    const tableStyle = {
        borderCollapse: 'collapse',
        width: '100%',
    };

    const thStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '5px',
    };

    const tdStyle = {
        border: '1px solid #000000',
        textAlign: 'left',
        padding: '5px',
    };
  return(
      <>
          <div>
              <div className="card px-10">
                  <div className="mb-10">
                      <h2 className="fw-bolder text-black mb-3 text-center py-5">{"EB1. 1: Acceptation Mission - Critère relatifs à l’Entité"}</h2>

                      <div className="table-responsive">
                          <table className="tablePerso w-100" style={tableStyle}>
                              <thead>
                              <tr style={thStyle} >
                                  <th
                                      className="min-w-200px sorting w-300px"
                                      style={{ border: "1px solid #7e8299", padding: "8px", fontSize: "12px" }}
                                  >
                                      {"EB1. 1: Acceptation Mission - Critères relatifs à l’Entité"}
                                  </th>
                                  <th
                                      className="min-w-15px text-end pe-5 sorting w-15px"
                                      style={{ border: "1px solid #7e8299", padding: "8px" }}
                                  >
                                      Réponse
                                  </th>
                                  <th
                                      className="min-w-200px text-end pe-5 sorting w-300px"
                                      style={{ border: "1px solid #7e8299", padding: "8px" }}
                                  >
                                      Commentaires
                                  </th>
                              </tr>
                              </thead>
                              <tbody className="fw-bold text-gray-600">
                              {(dataEntite && dataEntite.length > 0) && dataEntite.map((item, index) => (
                                  <tr style={tdStyle} key={`${index}`}>
                                      <td style={{ ...tdStyle, fontSize: '10px' }} className="text-start" colSpan={1}>
                                          {item.libelle}

                                      </td>
                                      <td style={{ ...tdStyle, fontSize: '10px' }} className="text-start" colSpan={1}>
                                          <div className="text-gray-800 fs-11 me-5">
                                              {item.isChecked ? "OUI" : "NON"}
                                          </div>
                                      </td>
                                      <td style={{ ...tdStyle, fontSize: '10px' }} className="text-start" colSpan={1}>
                                          <div className="text-gray-800 fs-11 me-5">
                                              {item.motif}
                                          </div>
                                      </td>
                                  </tr>
                              ))}
                              </tbody>
                          </table>
                      </div>
                  </div>

                  <div className="my-15">
                      <h2 className="fw-bolder text-black mb-3 text-center py-5">{"EB1. 2: Acceptation Mission - Critères relatifs à l’Auditeur"}</h2>

                      <div className="table-responsive">
                          <table className="tablePerso w-100" style={tableStyle}>
                              <thead>
                              <tr style={thStyle} >
                                  <th
                                      className="min-w-200px sorting w-300px"
                                      style={{ border: "1px solid #7e8299", padding: "8px", fontSize: "12px" }}
                                  >
                                      {"EB1. 2: Acceptation Mission - Critères relatifs à l’Auditeur"}
                                  </th>
                                  <th
                                      className="min-w-15px text-end pe-5 sorting w-15px"
                                      style={{ border: "1px solid #7e8299", padding: "8px" }}
                                  >
                                      Réponse
                                  </th>
                                  <th
                                      className="min-w-200px text-end pe-5 sorting w-300px"
                                      style={{ border: "1px solid #7e8299", padding: "8px" }}
                                  >
                                      Commentaires
                                  </th>
                              </tr>
                              </thead>
                              <tbody className="fw-bold text-gray-600">
                              {(datasAuditeur && datasAuditeur.length > 0) && datasAuditeur.map((item, index) => (
                                  <tr style={tdStyle} key={`${index}`}>
                                      <td style={{ ...tdStyle, fontSize: '10px' }} className="text-start" colSpan={1}>
                                          {item.libelle}

                                      </td>
                                      <td style={{ ...tdStyle, fontSize: '10px' }} className="text-start" colSpan={1}>
                                          <div className="text-gray-800 fs-11 me-5">
                                              {item.isChecked ? "OUI" : "NON"}
                                          </div>
                                      </td>
                                      <td style={{ ...tdStyle, fontSize: '10px' }} className="text-start" colSpan={1}>
                                          <div className="text-gray-800 fs-11 me-5">
                                              {item.motif}
                                          </div>
                                      </td>
                                  </tr>
                              ))}
                              </tbody>
                          </table>
                      </div>
                  </div>
                  <div className="my-10">
                      <h2 className="fw-bolder text-black mb-3 text-center py-5">{"EB1.3: Mesures de sauvegarde"}</h2>
                      <div className="">
                          {((dataEntite ?? []).some(question => (question.isChecked !== question.defaultResponse)) ) && <div className="table-responsive mb-5">
                              <div
                                  id='kt_file_manager_listwrapper'
                                  className='dataTables_wrapper dt-bootstrap no-footer'
                              >
                                  <div className='card-body mx-auto'>
                                      <div
                                          className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                                          <table style={tableStyle}>
                                              <thead>
                                              <tr style={thStyle} className="fw-bold fs-6 text-gray-800">
                                                  <th style={thStyle}
                                                      className="text-center min-w-150px w-lg-200px">Critères à prendre
                                                      en consideration relatifs à l'entite
                                                  </th>
                                                  <th style={thStyle}
                                                      className="text-center min-w-20px w-lg-20px">Réponse
                                                  </th>
                                                  <th style={thStyle}
                                                      className="text-center min-w-150px w-lg-150px">Commentaire
                                                  </th>
                                                  <th style={thStyle}
                                                      className="text-center min-w-200px w-lg-250px">Mesure sauvegarde
                                                  </th>
                                              </tr>
                                              </thead>
                                              <tbody>
                                              {(dataEntite && dataEntite.length > 0) && dataEntite.map((question, questionIndex) => (
                                                  (question.isChecked !== question.defaultResponse) &&
                                                  <tr style={tdStyle} key={`${questionIndex}`}>
                                                      <td style={{...tdStyle, fontSize: '10px'}} className="text-start"
                                                          colSpan={1}>
                                                          {question.libelle}
                                                      </td>
                                                      <td style={{...tdStyle, fontSize: '11px'}} colSpan={1}>
                                                          <div className="text-gray-800 me-5">
                                                              {question.isChecked ? "OUI" : "NON"}
                                                          </div>
                                                      </td>
                                                      <td style={{...tdStyle, fontSize: '11px'}} colSpan={1}>
                                                          <div className="text-gray-800 me-5">
                                                              {question.motif}
                                                          </div>
                                                      </td>
                                                      <td style={{...tdStyle, fontSize: '11px'}} colSpan={1}>
                                                          <div className="text-gray-800 me-5">
                                                              {question.mesure_sauve}
                                                          </div>
                                                      </td>
                                                  </tr>
                                              ))}
                                              </tbody>
                                          </table>
                                      </div>
                                  </div>
                              </div>
                          </div>}
                          {((datasAuditeur ?? []).some(question => (question.isChecked !== question.defaultResponse)) ) && <div className="table-responsive mb-5">
                              <div className='card-body mx-auto'>
                                  <div
                                      className='dataTables_scrollHead overflow-hidden position-relative border-0 w-100'>
                                      <table style={tableStyle}>
                                          <thead>
                                          <tr style={thStyle}>
                                              <th style={thStyle}
                                                  className="text-center min-w-150px w-lg-300px">Critères à prendre en
                                                  consideration relatifs à l'auditeur
                                              </th>
                                              <th style={thStyle} className="text-center min-w-20px w-lg-20px">Réponse
                                              </th>
                                              <th style={thStyle}
                                                  className="text-center min-w-150px w-lg-150px">Commentaire
                                              </th>
                                              <th style={thStyle} className="text-center min-w-200px w-lg-250px">Mesure
                                                  sauvegarde
                                              </th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {(datasAuditeur && datasAuditeur.length > 0) && datasAuditeur.map((question, questionIndex) => (
                                              (question.isChecked !== question.defaultResponse) &&
                                              <tr style={tdStyle} key={`${questionIndex}`}>
                                                  <td style={{...tdStyle, fontSize: '10px'}} className="text-start"
                                                      colSpan={1}>
                                                      {question.libelle}
                                                  </td>
                                                  <td style={{...tdStyle, fontSize: '11px'}} colSpan={1}>
                                                      <div className="text-gray-800 me-5">
                                                          {question.isChecked ? "OUI" : "NON"}
                                                      </div>
                                                  </td>
                                                  <td style={{...tdStyle, fontSize: '11px'}} colSpan={1}>
                                                      <div className="text-gray-800 me-5">
                                                          {question.motif}
                                                      </div>
                                                  </td>
                                                  <td style={{...tdStyle, fontSize: '11px'}} colSpan={1}>
                                                      <div className="text-gray-800 me-5">
                                                          {question.mesure_sauve}
                                                      </div>
                                                  </td>
                                              </tr>
                                          ))}
                                          </tbody>
                                      </table>
                                  </div>
                              </div>
                          </div>}
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

export {AcceptationPrint}