import { useEffect, useState } from 'react'
import backGroundImage from '../../assets/images/login_fond.jpg'
import { AuthUser, verifAuthUser } from '../../data/user'
import { Alert, Space } from 'antd';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { storeUserData } from '../../store/user/actions';
import { message as msg } from 'antd';
import {EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";


const LoginPage = () => {

    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [messageTxt, setMessageTxt] = useState('')
    const [messageType, setMessageType] = useState('')
    const [showMessage, setShowMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const token = useSelector(state => state.accessToken)


    const handleVerifAuthUser = () => {
        const auth = verifAuthUser(token)
        if (auth) {
            navigate('/tableau-de-bord') 
        }
    }

    useEffect(() => {
        handleVerifAuthUser()
    }, [])

    const handleAuthUser = async (e) => {
        e.preventDefault()
        setLoading(true)
        const { result, status, message, accessToken } = await AuthUser(login, password)
        setMessageTxt(message)
        if (status === 'success') {
            dispatch(storeUserData(result, accessToken))
            msg.success(message);
            setMessageType('success')
            setShowMessage(true)
            setLoading(false)
            navigate('/tableau-de-bord')
        } else {
            setMessageType('error')
            setShowMessage(true)
            setLoading(false)
        }
    }
    return (
        <>
            <div id="kt_body" className="bg-body">
                
                <div className="d-flex flex-column flex-root">
                    
                    <div className="d-flex flex-column flex-lg-row flex-column-fluid" style={{ height: "100vh" }}>
                        
                        <div className="d-flex flex-column flex-lg-row-fluid py-10" style={{background: `url(${backGroundImage})`, width: '100%', height: '100vh', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                            
                            <div className="d-flex flex-center flex-column flex-column-fluid">
                                
                                <div className="w-lg-500px p-10 p-lg-15 mx-auto" style={{background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`, borderRadius: '50px'}}>
                                    
                                    <form onSubmit={e => handleAuthUser(e)} className="form w-100" noValidate="novalidate" id="kt_sign_in_form" action="#" >
                                        
                                        <div className="text-center mb-10">
                                            
                                            <h1 className="text-light mb-3">Connectez-vous</h1>
                                            <Space
                                                direction="vertical"
                                                style={{
                                                width: '100%',
                                                }}
                                            > 
                                                {showMessage && <Alert message={messageTxt} type={messageType} showIcon />}

                                            </Space>
                                        </div>
                                        
                                        <div className="fv-row mb-10">
                                            
                                            <label className="form-label fs-6 fw-bolder text-light">Login</label>
                                            
                                            <input onChange={e => {
                                                setShowMessage(false)
                                                setLogin(e.target.value)
                                            }} className="form-control form-control-lg form-control-solid" type="text" name="email" autoComplete="off" />
                                            
                                        </div>

                                        <div className="fv-row mb-10">
                                            <div className="d-flex flex-stack mb-2">
                                                <label className="form-label fw-bolder text-light fs-6 mb-0">Mot de passe</label>
                                                <a href="/" className="link-orange fs-6 fw-bolder">Mot de passe oublié ?</a>
                                            </div>
                                            <div className="position-relative">
                                                <input
                                                    onChange={e => {
                                                        setShowMessage(false);
                                                        setPassword(e.target.value);
                                                    }}
                                                    className="form-control form-control-lg form-control-solid"
                                                    type={passwordVisible ? 'text' : 'password'}
                                                    name="password"
                                                    autoComplete="off"
                                                />
                                                <span
                                                    onClick={() => setPasswordVisible(!passwordVisible)}
                                                    className="password-toggle-button"
                                                    style={{
                                                        background: 'none',
                                                        border: 'none',
                                                        color: '#FA981B',
                                                        cursor: 'pointer',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: '10px',
                                                        transform: 'translateY(-50%)'

                                                    }}
                                                >
                                                    {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                                </span>
                                            </div>
                                        </div>
                                        
                                        <div className="text-center">
                                            
                                            <button disabled={loading} type="submit" id="kt_sign_in_submit" className="btn btn-lg btn-orange w-100 mb-5" style={{backgroundColor: "#FA981B !important"}}>
                                                <span className="indicator-label">{!loading ? <span>Se connecter</span> : <span>Traitement en cours ...</span>}</span>
                                                <span className="indicator-progress"> 
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                            
                                        </div>
                                        
                                    </form>
                                    
                                </div>
                                
                            </div>
                    
                        </div>
                        
                        <div className="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative" style={{backgroundColor: "#CADB2B"}}>
                            
                            <div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
                                
                                <div className="d-flex flex-row-fluid flex-column text-center p-10 pt-lg-20" >
                                    
                                    <a href="/" className="py-9 mb-5" style={{marginTop: '25vh'}}>
                                        <img alt="Logo" src={require('../../assets/images/logo_egouexpert.png')} className="h-60px" />
                                    </a>
                                    
                                    <span style={{color: "#FFF", display: 'block', fontSize: '2rem', fontWeight: 'bold'}}>BIENVENUE SUR EGOUEXPERT AUDIT</span>
                                    
                                </div>
                                
                            </div>
                            
                        </div>
                        
                    </div>

                </div>


            </div>
        </>
    )
} 

export default LoginPage;