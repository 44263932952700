import useSwr from "swr";
import {GET_USERS_URL} from "../../api/apiRoutes";
import {getUsers} from "../../data/user";


export default function useUsers() {
    const { data, error, mutate } = useSwr(
        GET_USERS_URL,
        getUsers
    );

    return {
        users: (data && data.length > 0) ? data.filter((item) => item?.libelleProfil !== "Admin") : [],
        chargementUsers: !data && !error,
        erreurUsers: error,
        mutateUsers: mutate,
    };
}