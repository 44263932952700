export const dossierSommaires = [
    {
        code: "PA1",
        libelle: "PA1 – Fiche Signalétique",
    },
    {
        code: "PA2",
        libelle: "PA2 – Autres documents (Extrait journal, Manuel procédures………..)",
    },
    {
        code: "PB1",
        libelle: "PB1- Synthèse des risques identifiés et évalués",
    },
    {
        code: "PB2",
        libelle: "PB2 – Synthèse de l’identification et de l’évaluation de la conception et du fonctionnement du système de contrôle",
    },
    {
        code: "PC1",
        libelle: "PC1 – Rapport au CA ou à l’Administrateur Général",
    },
    {
        code: "PC2",
        libelle: "PC2 – Rapport général et spécial du CAC",
    },
    {
        code: "PC3",
        libelle: "PC3 – Rapport sur le contrôle interne ou Lettre à la direction",
    },
    {
        code: "PC4",
        libelle: "PC4 – Rapport spécial du CAC",
    },
    {
        code: "PC5",
        libelle: "PC5 – Rapport spécial du CAC sur le registre des titres nominatifs",
    },
    {
        code: "PC6",
        libelle: "PC6 – Autres rapports",
    },
    {
        code: "PD1.1",
        libelle: "PD1.1 – Déclaration Fiscale d’Existence (DFE)",
    },
    {
        code: "PD1.2",
        libelle: "PD1.2 – Documentation Fiscale",
    },
    {
        code: "PD2.1",
        libelle: "PD2.1 – Immatriculation à la CNPS",
    },
    {
        code: "PD2.2",
        libelle: "PD2.2 – Liste des délégués du personnel",
    },
    {
        code: "PD2.3",
        libelle: "PD2.3 – Règlement intérieur",
    },
    {
        code: "PD2.4",
        libelle: "PD2.4 – Documentation sociale",
    },
    {
        code: "PE1",
        libelle: "PE1 – Statuts ou extrait des statuts",
    },
    {
        code: "PE2",
        libelle: "PE2 – RCCM",
    },
    {
        code: "PE3",
        libelle: "PE3 – Liste des associés ou actionnaires ",
    },
    {
        code: "PE4",
        libelle: "PE4 – Agrément ou autorisation d’exercer (si secteur d’activité réglementé)",
    },
    {
        code: "PE5",
        libelle: "PE5 – Extrait des PV du Conseil d’Administration (CA) ou de l’Administrateur Général ",
    },
    {
        code: "PE6",
        libelle: "PE6 – Extrait des PV des Assemblées",
    },
    {
        code: "PE7",
        libelle: "PE7 – Tableau des membres des organes de direction (Gérant, CA) et des CAC",
    },
    {
        code: "PE8",
        libelle: "PE8 – Conventions réglementées ",
    },
    {
        code: "PE9",
        libelle: "PE9 – Brevets, licences",
    },
    {
        code: "PE10",
        libelle: "PE10 – Baux et avenants",
    },
    {
        code: "PE11",
        libelle: "PE11 – Polices d’assurances",
    },
    {
        code: "PE12",
        libelle: "PE12 – Autres contrats, conventions",
    },
    {
        code: "PE13",
        libelle: "PE13 – Autres documentations à spécifier",
    },
]