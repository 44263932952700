import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion, useAnimation } from 'framer-motion';

// Styled components for the loading component
const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(253, 177, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Message = styled.p`
  font-family: 'Helvetica', sans-serif;
  font-size: 24px;
  color: white;
  text-align: center;
`;

const ProgressBar = styled.div`
  width: 300px;
  height: 15px;
  background-color: #ccc;
  border-radius: 5px;
  margin-top: 20px;
  overflow: hidden;
`;

const Progress = styled(motion.div)`
  height: 100%;
  background-color: #009EF7
`;

const LoadingComponent = ({ message, loading, setLoading }) => {
    // const [loading, setLoading] = useState(true);
    const controls = useAnimation();

    useEffect(() => {
        let timeout;

        if (loading) {
            timeout = setTimeout(() => setLoading(false), 7000);
            controls.start({ width: '100%', transition: { duration: 8 } });
        }

        return () => clearTimeout(timeout);
    }, [loading, controls, setLoading]);

    return loading ? (
        <Container>
            <Content>
                <Message>{message}</Message>
                <ProgressBar>
                    <Progress
                        initial={{ width: 0 }}
                        animate={{ width: '100%' }}
                        transition={{ duration: 7, ease: 'easeInOut' }}
                    />
                </ProgressBar>
            </Content>
        </Container>
    ) : null;
};

export default LoadingComponent;
