import React, {useEffect, useState} from "react";
import {ajouterEvaluationFinale} from "../../../../../../../data/seuilSignificatif";
import swal from "sweetalert";
import {succesMessage} from "../../../../../../../helpers/AssetsHelpers";
import {Button, Input, Table, Tooltip, Typography} from "antd";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";

const { Title } = Typography;

const questions = [
    {
        id: 1,
        libelle: `1. Des révisions (le cas échéant) de l'importance relative des performances globales et des performances associés ont-elles été enregistrées ci-dessus et les raisons
            indiquées ?
            Prenez en compte les changements dans les opérations / résultats, les nouvelles informations obtenues et les anomalies identifiées lors de l'audit.`,
        reponse_defaut: false,
    },
    {
        id: 2,
        libelle: `2. L'évaluation des risques, la nature, l'ampleur et le calendrier des procédures d'audit supplémentaires requise ont-ils pris en compte l'impact des révisions sur l'importance
            globale et sur la performance ?`,
        reponse_defaut: false,
    }
]

const Evaluation = ({evaluationFinale, isProvisoire, mission, strategies}) => {
    const [loading, setLoading] = useState(false);
    const [questionsValues, setQuestionsValue] = useState([])


    useEffect(() => {
        setQuestionsValue((evaluationFinale ?? []).map((item) => ({
            id: item.question_id,
            question_id: item.question_id,
            question: questions.find(q => q.id === item.question_id)?.libelle || "",
            reponse: item.reponse,
            defaultResponse: false,
            commentaire: item.commentaire,
            refFT: item.refFT,
            motifRequired: false,
            isProvisoire: isProvisoire,
        })))
    }, [evaluationFinale, isProvisoire])

    useEffect(() => {
        if(questions.length > 0 && evaluationFinale.length <= 0){
            questions.forEach((item) => {
                const isAlreadySelected = questionsValues.some((element) => element.id === item.id);

                if (!isAlreadySelected) {
                    const defaut = item.reponse_defaut === false

                    const newElement = {
                        id: item.id,
                        question_id: item.id,
                        question: item.libelle,
                        reponse: 'NON',
                        defaultResponse: defaut,
                        commentaire: null,
                        refFT: '',
                        motifRequired: !defaut === true,
                    };

                    const questionnaire = [...questionsValues, newElement]

                    setQuestionsValue(questionnaire.sort((a, b) => {
                        return a.id - b.id;
                    }))
                }
            })
        }
    }, [questionsValues])


    const handleCommentChange = (index, motif) => {
        const updatedList = questionsValues.map((item, i) =>
            i === index ? { ...item,  commentaire: motif, motifRequired: !item.commentaire && item.reponse !== item.defaultResponse } : item
        );

        setQuestionsValue(updatedList);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            exercice_id: mission.id,
            client_id: mission.client_id,
            strategie_id: strategies.id,
            evaluationData: questionsValues,
            isProvisoire
        };

        const { status, message } = await ajouterEvaluationFinale(data);
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    };

    const columns = [
        {
            title: () => <TableTitle>Révision des seuil de signification</TableTitle>,
            dataIndex: 'revision',
            width: 300,
            key: 'revision',
            render: (_, record) => (
                <span className="fs-4 fw-bolder">
                    {record.question}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Oui / Non</TableTitle>,
            dataIndex: 'reponse',
            key: 'reponse',
            width: 150,
            render: (_, record, index) => (
                <select
                    name='provisoire'
                    className="form-select"
                    onChange={(e) => {
                        const updatedList = questionsValues.map((item, i) =>
                            i === index ? { ...item, reponse: e.target.value, motifRequired: ((e.target.value === 'NON') === item.defaultResponse) && item.commentaire === null } : item
                        );

                        setQuestionsValue(updatedList);
                    }}
                    value={record.reponse}
                >
                    {['OUI', 'NON'].map((opt, index) => (
                        <option key={index} value={opt}>
                            {opt}
                        </option>
                    ))}
                </select>
            ),
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            key: 'commentaire',
            width: 300,
            render: (_, record, index) => (
                <>
                    <div className="">
                        <Input.TextArea
                            className={`form-control mb-2 ${record.motifRequired !== true ? '' : 'is-invalid'}`}
                            style={{fontSize: "14px", fontWeight: "600"}}
                            size="large"
                            bordered={false}
                            placeholder="Saisisser le commentaire..."
                            value={record.commentaire}
                            required={record.motifRequired === true}
                            maxLength={2000}
                            showCount
                            autoSize={{
                                minRows: 2,
                                maxRows: 8,
                            }}
                            onChange={(e) => {
                                handleCommentChange(index, e.target.value);
                            }}
                        />
                    </div>
                    {record.motifRequired === true &&  (
                        <div className="invalid-feedback">Champ obligatoire</div>
                    )}
                </>
            ),
        },
        {
            title: () => <TableTitle>Ref_FT</TableTitle>,
            dataIndex: 'ref',
            key: 'ref',
            width: 120,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "14px", fontWeight: "600"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le refrence..."
                        value={record.refFT}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        onChange={(e) =>{
                            const updatedList = questionsValues.map((item, i) =>
                                i === index ? { ...item, refFT: e.target.value } : item);

                            setQuestionsValue(updatedList);
                        }}
                    />
                </div>
            ),
        },
    ];

    return (
        <>
          <div className='card pt-5'>
              {<div className="d-flex justify-content-end align-items-end mb-10">
                  <Tooltip title="Enregistrer">
                      <Button
                          disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                          size={"large"}
                          // loading={loading}
                          style={{
                              backgroundColor: appTheme.colors.secondary,
                              color: "white"
                          }}
                          icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                          onClick={handleSubmit}
                      >
                          {!loading &&
                              <span className='indicator-label text-white'>Enregistrer</span>}
                          {loading && (
                              <span className='indicator-progress fs-9 text-white'
                                    style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                          )}
                      </Button>
                  </Tooltip>
              </div>}
              <div className="card-header">
                  <div className="card-title">
                      <Title style={{
                          display: "block",
                          fontWeight: '600',
                          color: appTheme.colors.primary,
                          // textAlign: "center"
                      }} level={4}>{"G. Evaluation finale du seuil de signification"}</Title>
                  </div>
              </div>
              <div className=''>
                  <div className="fw-bold text-gray-600 mb-5 mt-5 mx-auto ps-15" style={{fontSize: '14px'}}>
                      (ISA 320.12, ISA 320.13, ISA 320.14, ISA 450.10) <br/>
                  </div>
                  <div className="table-responsive pt-5 pb-10 mx-10">
                      <Table
                          rowClassName={() =>
                              `h-3px p-0 m-0 lh-0.5`
                          }
                          columns={columns}
                          rowKey={(record, index) => index}
                          dataSource={questionsValues ?? []}
                          pagination={false}
                          scroll={{y: 800}}
                          loading={loading}
                          rowSelection
                          size={"small"}
                      />
                  </div>
              </div>
          </div>
        </>
    )
}

export {Evaluation}