import React, {useState} from "react";
import {Button, Input, Table, Tooltip} from "antd";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const HistoriqueFeuilleMaitresse = ({ balanceData, setBalanceData, mission, chargement, handleSubmit, setIsTaskSave }) => {
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 1000,
        },
    });

    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    };

    const columns = [
        {
            title: () => <TableTitle>#</TableTitle>,
            render: (_, record, index) => <>{index + 1}</>,
            width: 40,
        },
        {
            title: () => <TableTitle>Compte</TableTitle>,
            dataIndex: 'col1',
            width: 100,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.compte}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Libellé</TableTitle>,
            dataIndex: 'col1',
            width: 400,
            render: (_, record) => (
                <span className="fw-bolder" style={{fontSize: '14px'}}>
                    {record.libelle}
                </span>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${mission.annee || "N"}`}</TableTitle>,
            dataIndex: 'soldeN',
            width:100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>{`Exercice ${(parseInt(mission.annee) - 1) || "N-1"} `}</TableTitle>,
            dataIndex: 'soldeN_1',
            width:100,
            render: (_, record) => (
                <div className='text-end pe-0 fw-bolder' style={{fontSize: '14px'}}>
                    {(record.soldeN_1 || 0).toLocaleString('fr') || 0}
                </div>
            ),
        },
        {
            title: () => <TableTitle>N  -  N-1</TableTitle>,
            key:'brut',
            dataIndex: 'brut',
            children: [
                {
                    title: () => <TableTitle>VARIATION</TableTitle>,
                    dataIndex: 'col1',
                    width:100,
                    render: (_, record) => (
                        <div
                            className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                            style={{fontSize: '14px'}}
                        >
                            {(record.soldeN - record.soldeN_1).toLocaleString('fr')}
                        </div>
                    ),
                },
                {
                    title: () => <TableTitle>% Variation</TableTitle>,
                    dataIndex: 'col1',
                    width: 70,
                    render: (_, record) => (
                        <div
                            className={`text-end p-1 fw-bolder 
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) >= 10 ? "bg-danger text-white" : ""}
                                        ${( ( (record.soldeN)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2) <= -10 ? "bg-danger text-white" : ""}
                                       `}
                            style={{fontSize: '14px'}}
                        >
                            {( ( (record.soldeN_1)=== 0 ? 0 : (record.soldeN - record.soldeN_1) / record.soldeN_1 ) * 100).toFixed(2).toLocaleString('fr')}%
                        </div>
                    ),
                },
            ],
        },
        {
            title: () => <TableTitle>Commentaire</TableTitle>,
            dataIndex: 'commentaire',
            width: 400,
            render: (_, record, index) => (
                <div className="">
                    <Input.TextArea
                        style={{fontSize: "12px"}}
                        size="large"
                        bordered={false}
                        placeholder="Saisisser le commentaire..."
                        value={record.commentaire}
                        maxLength={2000}
                        showCount
                        autoSize={{
                            minRows: 1,
                            maxRows: 4,
                        }}
                        onChange={(e) => {
                            const updatedList = balanceData.map((item, i) =>
                                i === index ? { ...item,  commentaire: (e.target.value)} : item
                            );

                            setBalanceData(updatedList);
                            setIsTaskSave(prevState => [...prevState, 2]);
                        }}
                    />
                </div>
            ),
        },
    ];

    return (
        <>
            <div className="my-5">
                <div className="d-flex justify-content-end align-items-end">
                    <Tooltip title="Enregistrer">
                        <Button
                            disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                            size={"large"}
                            // loading={loading}
                            style={{
                                backgroundColor: appTheme.colors.primary,
                                color: "white"
                            }}
                            icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                            onClick={handleSubmit}
                        >
                            {!chargement &&
                                <span className='indicator-label text-white'>Enregistrer</span>}
                            {chargement && (
                                <span className='indicator-progress fs-9 text-white'
                                      style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                    </Tooltip>
                </div>
                <div className="table-responsive">
                    <Table
                        rowClassName={() =>
                            `h-3px p-0 m-0 lh-0.5`
                        }
                        columns={columns}
                        rowKey={(record, index) => index}
                        dataSource={balanceData ?? []}
                        pagination={tableParams.pagination}
                        scroll={{x: 1300}}                                            onChange={handleTableChange}
                        loading={chargement}
                        rowSelection
                        size={"small"}
                    />
                </div>
            </div>
        </>
    );
 };

export { HistoriqueFeuilleMaitresse };