import React, { useState } from 'react';
import './TreeComponent.css';
import {CheckCircleOutlined, FileDoneOutlined} from "@ant-design/icons";

const TreeNode = ({ node, onNodeClick }) => {
    const [expanded, setExpanded] = useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const handleNodeClick = () => {
        onNodeClick(node);
    };
    return (
        <div className={`tree-node ${(node?.status === 'current') ? 'current' : ''}`}>
            <div className={`tree-node-header ${expanded ? 'expanded' : ''}`} onClick={toggleExpanded}>
                <span className="tree-node-icon">{node?.icon}</span>
                <span className="tree-node-title" onClick={handleNodeClick}>
                    <div className="stepper-label">
                        <h3 className="stepper-title text-gray-800">{node?.title}</h3>
                        <div className="stepper-desc fw-bold">{node?.description}</div>
                    </div>
                    {node?.isLeaf && node?.leafStatus === 'completed' && <CheckCircleOutlined className="completed-icon fs-9" />}
                    {node?.status === 'completed' && <FileDoneOutlined className="completed-icon fs-4" />}
                </span>
            </div>
            {node?.children && expanded && (
                <div className="tree-node-children">
                    {node.children.map((child) => (
                       child && Object.keys(child).length > 0 ?
                           <TreeNode key={child?.key} node={child} onNodeClick={onNodeClick} />
                           :
                           null
                    ))}
                </div>
            )}
        </div>
    );
};

const TreeComponent = ({ data, onNodeClick }) => {
    return (
        <div className="tree-container">
            {data.map((node) => (
                <TreeNode key={node?.key} node={node} onNodeClick={onNodeClick} />
            ))}
        </div>
    );
};

export default TreeComponent;
