import axios from "axios";

const AJOUTER_TRAVAUX_AUDIT = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-audit/ajouter-travaux-audit`
const AJOUTER_PROCEDURE_AUDIT = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-audit/ajouter`
const AJOUTER_TRAVAUX_AJUSTEMENT = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-audit/ajouter-travaux-ajustement`
const LISTE_PROCEDURE_AUDIT = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-audit`

const AJOUTER_PROCEDURE_NOTE_ANNEXE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-audit/ajouter-note-annexe`
const LISTE_PROCEDURE_NOTE_ANNEXE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-audit/note-annexe`

const AJOUTER_PROCEDURE_PARTIE_LIEE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-audit/ajouter-partie-liee`
const LISTE_PROCEDURE_PARTIE_LIEE = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-audit/partie-liee`

const AJOUTER_PROCEDURE_MISSION_AUDIT = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-audit/ajouter-mission-audit`
const LISTE_PROCEDURE_MISSION_AUDIT = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-audit/mission-audit`


const PROCEDURE_FM_REF = `${process.env.REACT_APP_API_URL}/lea/exercice/procedure-audit/feuille-maitresse-ref`

export const ajouterTravauxAudit = async (data) => {
    return axios.post(AJOUTER_TRAVAUX_AUDIT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterTravauxAjustement = async (data) => {
    return axios.post(AJOUTER_TRAVAUX_AJUSTEMENT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const ajouterProcedureAudit = async (data) => {
    return axios.post(AJOUTER_PROCEDURE_AUDIT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getProcedureAudit = async (exercice_id) => {
    return axios.get(`${LISTE_PROCEDURE_AUDIT}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}


/* Debut::NOTE ANNEXE */
export const ajouterProcedureNoteAnnexe = async (data) => {
    return axios.post(AJOUTER_PROCEDURE_NOTE_ANNEXE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getProcedureNoteAnnexe = async (exercice_id) => {
    return axios.get(`${LISTE_PROCEDURE_NOTE_ANNEXE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}
/* Fin::NOTE ANNEXE */

/* Debut::PARTIE LIEE */
export const ajouterProcedurePartieLiee = async (data) => {
    return axios.post(AJOUTER_PROCEDURE_PARTIE_LIEE, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getProcedurePartieLiee = async (exercice_id) => {
    return axios.get(`${LISTE_PROCEDURE_PARTIE_LIEE}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}
/* Fin::PARTIE LIEE */

/* Debut::MISSION AUDIT INITIAL */
export const ajouterProcedureMissionAudit = async (data) => {
    return axios.post(AJOUTER_PROCEDURE_MISSION_AUDIT, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getProcedureMissionAudit = async (exercice_id) => {
    return axios.get(`${LISTE_PROCEDURE_MISSION_AUDIT}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}
/* Fin::MISSION AUDIT INITIAL */


/* Debut::MISSION AUDIT FEUILLE MAITRESSE REFERENCE */
export const ajouterProcedureFeuilleMaitresseRef = async (data) => {
    return axios.post(PROCEDURE_FM_REF, data).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}

export const getProcedureFeuilleMaitresseRef = async (exercice_id) => {
    return axios.get(`${PROCEDURE_FM_REF}/${exercice_id}`).then(res => {
        if (res.status === 200) {
            return res.data
        } else {
            return {
                status: 'error',
                message: "La connexion à internet est instable !"
            }
        }
    }).catch(err => console.log(err))
}
/* Fin::MISSION AUDIT FEUILLE MAITRESSE REFERENCE */