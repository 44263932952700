import styled, { keyframes } from "styled-components";
import { CloseOutlined } from "@ant-design/icons";

const modalFade = keyframes`
  from {opacity:0.4;bottom: -50px;}
  to {opacity:1;bottom: 0px;}
`;

const modalContainerFade = keyframes`
  from {opacity:0; transform: scale(2)}
  to {opacity:1;transform: scale(1);}
`;

/**
 * Modal de commentaires de réclamation
 */
export const ModalCommentairesHeader = styled.div``;

export const ModalDivider = styled.hr`
  position: relative;
  margin-top: -5px;
  width: 100%;
  background: ${({ theme }) => theme.colors._primary_color || "#000000"};
  border: ${({ theme }) => `1.5px solid ${theme.colors._alter_color}`};
`;
export const ModalCloseIcon = styled(CloseOutlined)``;

export const ModalCloseIconContainer = styled(CloseOutlined)`
  transition: 700ms ease;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 10px;
  right: 15px;
  cursor: pointer;
  background: #ececec;
  height: 30px;
  width: 30px;
  border-radius: 10px;

  &:hover {
    transition: 150ms ease-in-out;
    background: transparent;
    border: 2px solid #ff0000;
    border-radius: 10px;
    color: #000;
    transform: scale(1.1);
  }

  &:active {
    transition: 150ms ease-in-out;
    background: #ff0000;
    border-radius: 10px;
    color: #ffffff;
    transform: scale(0.9);
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
export const ModalTitle = styled.h2`
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.primary};
`;
export const ModalBody = styled.div``;
export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => props.width ?? "50vw"};
`;

export const ModalContainer = styled.div`
  -webkit-animation-name: ${modalContainerFade};
  -webkit-animation-duration: 0.3s;
  animation-name: ${modalContainerFade};
  animation-duration: 0.3s;
  position: relative;
  padding: 24px;
  background: #ffffff;
  border-radius: 10px;
  border-bottom: ${({ theme }) => `5px solid ${theme.colors._primary_color}`};
  box-shadow: 0 0px 15px rgb(0 0 0 / 23%);
  transition: all 0.3s ease;
  color: #000;
`;

export const ModalPadding = styled.div`
  margin: auto 0;
`;

export const ModalMask = styled.div`
  -webkit-animation-name: ${modalFade};
  -webkit-animation-duration: 0.4s;
  animation-name: ${modalFade};
  animation-duration: 0.4s;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.9);
  z-index: 990001;
  transition: opacity 500ms ease;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
`;

// export const FileContainer = styled.div`
//   transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
//   width: 150px;
//   height: 100px;
//   background-color: #ffffff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex: 1 1 150;
//   margin: 0.5rem;
//   cursor: pointer;
//   border: 0.25px solid #00000020;
//   overflow: hidden;
//
//   &:hover {
//     transition: 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
//     z-index: 5000;
//     transform: scale(1.1);
//     box-shadow: 0px 0px 10px #00000020;
//     border-radius: 10px;
//   }
// `;

export const FilesGroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;
