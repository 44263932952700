export const dataBlanchiment = [
    {
        id: 1,
        ref: '1.1',
        libelle: [
            'Les obligations en matière d’identification et évaluation des risques de blanchiment de capitaux ont-elles été accomplies avant l’acceptation du client ? ',
        ],
        assertions: []
    },
    {
        id: 2,
        ref: '1.2',
        libelle: [
            'Les obligations en matière d’identification et évaluation des risques de blanchiment de capitaux ont-elles été accomplies au cours de la relation pour le client existant ?',
        ],
        assertions: []
    },
    {
        id: 3,
        ref: '1.3',
        libelle: [
            'Demander et consulter le registre des bénéficiaires effectifs',
        ],
        assertions: []
    },
    {
        id: 4,
        ref: '1.4',
        libelle: [
            'Renseigner et joindre la Matrice LAB de classification des risques de blanchissement des capitaux ',
        ],
        assertions: []
    },
    {
        id: 5,
        ref: '1.5',
        libelle: [
            'Renseigner et joindre la FT LAB',
        ],
        assertions: []
    },
]

export const dataTexteLegaux = [
    {
        id: 1,
        ref: '1',
        libelle: [
            'Lors de la planification de la mission, avons-nous pris connaissance du cadre légal et réglementaire de l’entité et de son secteur d’activité ?',
            "Avons-nous interrogé la direction sur les procédures visant :" ,
            "A garantir le respect des textes légaux et réglementaires ?" ,
            "Et à identifier, évaluer et suivre les litiges et pénalités ?" ,
            "Avons-nous interrogé la direction pour savoir si l’entité disposait de toutes les autorisations d’exercer (code importateur, agrément en douane, etc …)"
        ],
        assertions: []
    },
    {
        id: 2,
        ref: '2',
        libelle: [
            'Au cours de notre mission, avons-nous eu connaissance de situations pouvant indiquer le non-respect des textes légaux et réglementaires, telles que :',
            "Des amendes significatives récemment payées ?",
            "Des paiements sans remise de justificatif probant ?",
            "Des articles de presse mettant en cause le non-respect par l’entité des textes légaux et réglementaires ?",
            "Une notification de redressement fiscal ou social ?",
            "Des paiements de montants importants en espèces."

        ],
        assertions: []
    },
    {
        id: 3,
        ref: '3',
        libelle: [
            'Avons-nous obtenu une déclaration écrite des dirigeants confirmant que nous avons été informés de tous les cas survenus ou potentiels de non-respect des textes légaux et réglementaires qu’il conviendrait de considérer lors de l’établissement des comptes ?',
            "Avons-nous pris connaissance de la correspondance reçue de l’administration fiscale ou sociale ?"
        ],
        assertions: []
    },
    {
        id: 4,
        ref: '4',
        libelle: [
            'Dans le cas où nous avons relevé un risque de non-respect des textes, avons-nous documenté nos constatations et notre évaluation de son effet potentiel sur les comptes ?',
            "En avons-nous informé la Direction ?"
        ],
        assertions: []
    },
    {
        id: 5,
        ref: '5',
        libelle: [
            'Dans le cas où nous avons relevé un cas de non-respect des textes, avons-nous communiqué cette irrégularité :',
            "à la Direction ?",
            "Au Gouvernement d’entreprise, selon la gravité des faits ?",
            "Au procureur de la République si les faits relevés sont délictueux ?",
            "Au procureur de la République si les faits relevés sont délictueux ?",
        ],
        assertions: []
    },
    {
        id: 6,
        ref: '6',
        libelle: [
            "Avons-nous tiré les conséquences de ce non-respect des textes lors de la rédaction du rapport général :" ,
            "Avons-nous certifié avec réserve ou refusé de certifier en cas de conséquences significatives sur les comptes ?" ,
            "Avons-nous fait mention de cette irrégularité dans notre rapport ?",
            ],
        assertions: []
    },
    ]

export const dataTestJournaux = [
    {
        id: 1,
        ref: '1.1',
        groupe: "Prise de connaissance du processus d’établissement de l’information financière",
        libelle: [
            "Obtenir la liste exhaustive des Journaux utilisés par l’entité"
        ],
        assertions: []
    },
    {
        id: 2,
        ref: '1.2',
        groupe: "Prise de connaissance du processus d’établissement de l’information financière",
        libelle: [
            "Déterminer les journaux qui sont interfacés avec la comptabilité"
        ],
        assertions: []
    },
    {
        id: 3,
        ref: '1.3',
        groupe: "Prise de connaissance du processus d’établissement de l’information financière",
        libelle: [
            "Pour les journaux non interfacés (journaux manuels), obtenir la procédure de saisie des écritures en comptabilité."
        ],
        assertions: []
    },
    {
        id: 4,
        ref: '1.4',
        groupe: "Prise de connaissance du processus d’établissement de l’information financière",
        libelle: [
            "Obtenir le grand livre et les journaux comptables en version électronique sous Excel ou en version non modifiable en PDF"
        ],
        assertions: []
    },
    {
        id: 5,
        ref: '2.1',
        groupe: "Travaux d’audit",
        libelle: [
            "Reconstituer les journaux comptables à partir du grand livre ",
        ],
        assertions: []
    },
    {
        id: 6,
        ref: '2.2',
        groupe: "Travaux d’audit",
        libelle: [
            "Les tests de recherche et d’appréciation des évènements inhabituels ont-ils été réalisés ?",
            "Si oui, joindre les résultats des différents tests",
            "Si non, pourquoi ? Justifier formellement"
        ],
        assertions: []
    },

]