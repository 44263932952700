import './not.css'
const NotFoundPage = () => {
    return (
        <>
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <div></div>
                        <h1>404</h1>
                    </div>
                    <h2>Page non disponible</h2>
                </div>
            </div>
        </>
    )
}

export default NotFoundPage;