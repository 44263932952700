import React from "react";
import * as Yup from "yup";
import ModalAlert from "../Modal";
import {ModalTitle} from "../modals.components";
import {Form, Formik} from "formik";
import {Button, Col, Row, Select,} from "antd";
import FormLabel from "../../common/Form/FormLabel";
import FormError from "../../common/Form/FormError";

const UserProfilChange = ({
        visible,
        handleVisibility,
        handleValider,
        chargement, data, profils,
    }) => {
    const dataValidationScheme = Yup.object({
        profil_id: Yup.number()
            .required("Veuillez sélectionner le profil")
            .nullable("Le profil est obligatoire")
            .typeError("Le profil est obligatoire"),
    });

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Modifier le profil de {`${data.codeCivilite || ""} ${data.nom_user || ""} ${data.prenom_user || ""}`}</ModalTitle>}
                footer={[]}
                modalStyles={{ top: 30 }}
                width={600}
            >
                <Formik
                    initialValues={{
                        user: data || null,
                        user_id: (data && data.id) || "",
                        profil: (data && data.profil) || ""
                    }}
                    onSubmit={(values) => handleValider(values)}
                    validationSchema={dataValidationScheme}
                >
                    {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                        <Form>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <FormLabel label="Profil" required />
                                    <Select
                                        showSearch
                                        placeholder="Sélectionnez le profil..."
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        value={values.profil_id}
                                        options={profils?.map((profil) => ({
                                            value: profil.id,
                                            label: profil.libelleProfil,
                                        }))}

                                        onChange={(value) =>
                                            setFieldValue("profil_id", value)
                                        }
                                        size="large"
                                        style={{ width: "100%" }}
                                    />
                                    {touched.profil_id && errors.profil_id && (
                                        <FormError fieldName="client_id" />
                                    )}
                                </Col>
                                <Col span={24}>
                                    <Button
                                        type="primary"
                                        block
                                        size="large"
                                        loading={chargement}
                                        disabled={chargement}
                                        onClick={handleSubmit}
                                    >
                                        {!chargement && <span className='indicator-label'>Enregistrer</span>}
                                        {chargement && (
                                            <span className="indicator-progress text-white">
                                            Veuillez patienter...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                        </span>
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </ModalAlert>
        </>
    );
};

export default UserProfilChange;