import React from "react";

const TableTitle = ({ children }) => {
  return (
    <h6
      // className="text-white"
      style={{
        textTransform: "uppercase",
        fontFamily: "Oswald, sans-serif",
        fontWeight: "bold",
      }}
    >
      {children}
    </h6>
  );
};

export default TableTitle;
