import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Input, notification, Row, Select, Space, Table, Tag, Tooltip} from "antd";
import { DeleteOutlined} from "@ant-design/icons";
import {getListeAssertion} from "../../../../../../data/params/assertion";
import {
    getListeProgrammeTravauxProfessionnel
} from "../../../../../../data/params/programme";
import {areArraysEqual} from "../../../../../utilities/Utilities";
import PageChildLabel from "../../../../../common/PageLabel/PageChildLabel";
import {appTheme} from "../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faCloudUpload} from "@fortawesome/free-solid-svg-icons";
import {Table as TableChackra, Center, Spinner, TableContainer, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import TableTitle from "../../../../../common/TableTitle";
import FormLabel from "../../../../../common/Form/FormLabel";
import {ModalTitle} from "../../../../../modals/modals.components";
import {FormContainer} from "../../../../../common/common.components";
import SearchRow from "../../../../../common/SearchRow";
import ModalAlert from "../../../../../modals/Modal";

const tableHeaders = [
    {title: "Groupe", width: 200},
    {title: "Diligences", width: 300},
    {title: "Assertions", width: 150},
    {title: "Réponse", width: 100},
    {title: "Commentaire", width: 350},
    {title: "Action", width: 70},
]

const ModalQuestions = ({
            handleVisibility,
            visible,
            selectData,
            setSelectData,
            chargement,
            assertions,
            libelleCycle,
        }) => {
    const [loading, setLoading] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [questionsFiltered, setQuestionsFiltered] = useState([]);
    const [questionsTravail, setQuestionsTravail] = useState([]);
    const [groupQuestions, setGroupQuestions] = useState([])

    const [paramsRecherche, setParamsRecherche] = useState({
        question: "",
        groupe: null,
        assertion: "",
    });

    useEffect(() => {
        fetchProgramme();
    }, []);


    useEffect(() => {
        const libelleGroup = [...new Set(questions.map((item) => item.groupe))];
        setGroupQuestions(libelleGroup)
    }, [setGroupQuestions, questions])

    const fetchProgramme = async () => {
        try {
            const res = await getListeProgrammeTravauxProfessionnel();
            if (res.result) {
                if(res.result){
                    const updateList = res.result.filter((item) => item.libelle === (libelleCycle || ""))
                    if(updateList){
                        setQuestions(updateList);
                        setQuestionsFiltered(updateList);
                    }
                }
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des événements risques', error);
        }
    };

    const toggleCheckbox = (id) => {
        const selectedList = [...questionsTravail];
        const valueId = parseInt(id);

        if (isNaN(valueId)) {
            return;
        }

        const add = questions.find((item) => item.id === valueId);

        const isAlreadySelected = questionsTravail.some((element) => element.question_id === add.id);
        if (!isAlreadySelected) {
            const newElement = {
                question_id: add.id,
                ref: add.ref,
                groupe: add.groupe,
                libelle: add.diligence,
                reponse: '1',
                assertions: add.assertions,
                commentaire: '',
                isAdd: false
            };

            setQuestionsTravail([...selectedList, newElement]);
        }
        else {
            const selectedIndex = selectedList.findIndex((item) => item.question_id === (add.id));

            const updatedList = selectedList.filter((_, i) => i !== selectedIndex);
            setQuestionsTravail(updatedList)
        }
    };

    const handleValidate = (e) => {
        e.preventDefault()
        if(questionsTravail.length > 0 ){
            setLoading(true);
            setTimeout(() => {
                const updateList = [...selectData]

                questionsTravail.forEach((item) => {
                    const libelleExist = updateList.find((question) => question.libelleGroupe === item.groupe)

                    if(libelleExist){
                        libelleExist.questions.push(item)
                    }
                    else {
                        const newElement = {
                            libelleGroupe: item.groupe,
                            questions: [item],
                        }
                        updateList.push(newElement)
                    }
                })
                setSelectData(updateList);

                handleVisibility();
                notification.success({
                    description: "Question ajouté avec succès !",
                });

                setLoading(false);
            }, 1000)
        }
    }

    const columnsQuestions = [
        {
            title: () => <TableTitle>Groupe</TableTitle>,
            dataIndex: 'groupe',
            width: 150,
            render: (_, record) => (
                <span className='fw-boldest text-uppercase' style={{fontSize: '12px'}}>
                    {record.groupe}
                </span>
            ),
        },
        {
            title: () => <TableTitle>REF</TableTitle>,
            dataIndex: 'reference',
            width: 40,
            render: (_, record) => (
                <Tag>
                    <span className='fw-bolder' style={{fontSize: '13px'}}>
                        {record.ref}
                    </span>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Diligences</TableTitle>,
            dataIndex: 'diligence',
            width: 150,
            render: (_, record) => (
                record.diligence.length > 1 ?
                    record.diligence.map((item, index) => (
                        <div  key={index} className='d-flex flex-column'>
                       <span className='' style={{fontSize: '13px'}}>
                           <strong className='me-2 fw-bolder' style={{fontSize: '15px'}}>.</strong>{item}
                       </span>
                        </div>
                    ))
                    :
                    record.diligence.length >= 0 ?
                        record.diligence.map((item, index) => (
                            <span className='' key={index} style={{fontSize: '13px'}}>
                        {item}
                    </span>
                        ))
                        :
                        ""
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 50,
            render: (_, record) => (
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 50,
            render: (_, record) => (
                <span className="form-check form-check-custom me-5">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="product"
                        data-kt-product-name="Basic"
                        checked={
                            questionsTravail.some((item) => item.question_id === record.id)
                        }
                        onChange={() => {toggleCheckbox(record.id)}}
                    />
              </span>
            ),
        },
    ];

    const columnsSelectQuestions = [
        {
            title: () => <TableTitle>Procédure Audit Initiale</TableTitle>,
            dataIndex: 'procedureAudit',
            width: 100,
            render: (_, record) => (
                <span className='fw-boldest text-uppercase' style={{fontSize: '12px'}}>
                    {record.groupe}
                </span>
            ),
        },
        {
            title: () => <TableTitle>Question</TableTitle>,
            dataIndex: 'libelle',
            width: 300,
            render: (_, record) => (
                <div className="d-flex align-center">
                    <div className="me-2">
                        <Tag>
                            <span className='fw-boldest' style={{fontSize: '12px'}}>
                                {record.ref}
                            </span>
                        </Tag>
                    </div>
                    <div className="d-flex flex-column">
                        {record.libelle.length > 1 ?
                            record.libelle.map((item, index) => (
                                <div  key={index} className='d-flex flex-column'>
                               <span className='' style={{fontSize: '13px'}}>
                                   <strong className='me-2 fw-bolder' style={{fontSize: '15px'}}>.</strong>{item}
                               </span>
                                </div>
                            ))
                            :
                            record.libelle.length >= 0 ?
                                record.libelle.map((item, index) => (
                                    <span className='' key={index} style={{fontSize: '13px'}}>
                                    {item}
                                </span>
                                ))
                                :
                                ""}
                    </div>
                </div>
            ),
        },
        {
            title: () => <TableTitle>Assertions</TableTitle>,
            dataIndex: 'assertions',
            width: 100,
            render: (_, record) => (
                <Space>
                    {record.assertions?.map((assertion, index) => (
                        <Tag key={index}>
                            <span className='fw-bolder' >
                                {assertion}
                            </span>
                        </Tag>
                    ))}
                </Space>
            ),
        },
    ];

    const handleSearch = () => {
        setLoading(true);

        setTimeout(() => {
            const filteredClients = questions.filter((ident) => {
                const {
                    question,
                    groupe,
                    assertion,
                } = paramsRecherche;

                const lowercaseQuestion = question?.toLowerCase();
                const lowercaseGroupe = groupe?.toLowerCase();
                const lowercaseAssertion = assertion?.toLowerCase();

                const matchQuestion =
                    !question ||
                    (ident.diligence
                            ?.toLowerCase()
                            ?.includes(lowercaseQuestion) ||
                        ident.ref
                            ?.toLowerCase()
                            ?.includes(lowercaseQuestion) );

                const matchAssertion =
                    !assertion ||
                    (ident.assertions ?? []).some(ass =>
                        ass?.toLowerCase()
                            ?.includes(lowercaseAssertion)
                    );

                const matchGroupe =
                    !groupe ||
                    ident.groupe
                        ?.toLowerCase()
                        ?.includes(lowercaseGroupe);

                return (
                    matchQuestion &&
                    matchAssertion &&
                    matchGroupe
                );
            });

            setQuestionsFiltered(filteredClients)

            setLoading(false);
        }, 1000);
    };

    const handleReset = () => {
        setLoading(true);
        setParamsRecherche({
            question: "",
            groupe: null,
            assertion: "",
        });
        setQuestionsFiltered(questions);
        setLoading(false);
    };

    return (
        <ModalAlert
            closeModal={handleVisibility}
            show={visible}
            title={<ModalTitle> Sélectionner les questions de travail (sources) </ModalTitle>}
            footer={[]}
            modalStyles={{ top: 10 }}
            width={1300}
        >
            <FormContainer>
                <SearchRow
                    handleSearch={handleSearch}
                    handleReset={handleReset}
                    loading={loading}
                >
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 10 }}
                        xl={{ span: 10 }}
                    >
                        <FormLabel label="Question" />
                        <Input
                            style={{ width: "100%" }}
                            size="large"
                            placeholder="Rechercher par la référence, libellé"
                            value={paramsRecherche.question || null}
                            onChange={(e) => {
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    question: e.target.value,
                                })
                            }}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 10 }}
                        xl={{ span: 10 }}
                    >
                        <FormLabel label="Cycle" />
                        <Select
                            showSearch
                            placeholder="Rechercher par la procédure d'audit initiale..."
                            optionFilterProp="label"
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={groupQuestions.map((group) => ({
                                value: group,
                                label: group,
                            }))}
                            value={paramsRecherche.groupe || null}
                            onSelect={(value) =>
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    groupe: value,
                                })
                            }
                            size="large"
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 4 }}
                        xl={{ span: 4 }}
                    >
                        <FormLabel label="Assertions" />
                        <Select
                            showSearch
                            placeholder="Rechercher par l'assertion..."
                            optionFilterProp="label"
                            filterOption={(input, option) =>
                                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            options={assertions.map((assertion) => ({
                                value: assertion.libelle,
                                label: assertion.libelle,
                            }))}
                            value={paramsRecherche.assertion || null}
                            onSelect={(value) =>
                                setParamsRecherche({
                                    ...paramsRecherche,
                                    assertion: value,
                                })
                            }
                            size="large"
                            style={{ width: "100%" }}
                        />
                    </Col>
                </SearchRow>
            </FormContainer>
            <Row gutter={[16, 16]}>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 16 }}
                    lg={{ span: 14 }}
                    xl={{ span: 14 }}
                >
                    <FormContainer>
                        <div className="table-responsive">
                            <Table
                                rowClassName={() =>
                                    `h-3px p-0 m-0 lh-0.5`
                                }
                                columns={columnsQuestions}
                                dataSource={questionsFiltered ?? []}
                                pagination={false}
                                scroll={{y: 400}}
                                loading={chargement || loading}
                                size={"small"}
                            />
                        </div>
                    </FormContainer>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 10 }}
                    xl={{ span: 10 }}
                >
                    <FormContainer>
                        <div className="table-responsive">
                            <Table
                                rowClassName={() =>
                                    `h-3px p-0 m-0 lh-0.5`
                                }
                                columns={columnsSelectQuestions}
                                dataSource={questionsTravail ?? []}
                                pagination={false}
                                scroll={{y: 400}}
                                loading={chargement || loading}
                                size={"small"}
                            />
                        </div>
                    </FormContainer>
                </Col>
            </Row>
            <div className="d-flex justify-content-center align-items-center mt-3">
                <div className="w-350px">
                    <Button
                        type="primary"
                        block
                        size="large"
                        // icon={<FontAwesomeIcon icon={fa} /> }
                        loading={loading}
                        disabled={loading}
                        onClick={handleValidate}
                    >
                        Valider
                    </Button>
                </div>
            </div>
        </ModalAlert>
    );
};

const TraitementTravauxExpertComptable = ({
          mission,
          selectItems, 
          setSelectItems, 
          setConclusion, 
          conclusion, 
          isAddMemoire, 
          setIsAddMemoire, 
          datas,
          chargement,
          libelleCycle,
    }) => {
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [assertions, setAssertions] = useState([]);

    const fetchAssertion = async () => {
        try {
            const res = await getListeAssertion();
            setAssertions(res.result);
        } catch (error) {
            console.error('Erreur lors de la récupération des rubriques', error);
        }
    };

    useEffect(() => {
        fetchAssertion();
    }, []);

    useEffect(() => {
        if (datas.length > 0 && (selectItems ?? []).length <= 0) {
            setLoading(true);
            setTimeout(() => {
                const updatedList = [...selectItems];

                datas.forEach((item) => {
                    const groupExistIndex = updatedList.findIndex(
                        (question) => question.libelleGroupe === item.groupe
                    );

                    if (groupExistIndex !== -1) {
                        const questionExist = updatedList[groupExistIndex].questions.find(
                            (q) => q.question_id === item.question_id
                        );

                        if (!questionExist) {
                            updatedList[groupExistIndex].questions.push({
                                question_id: item.question_id,
                                groupe: item.groupe,
                                ref: item.ref,
                                libelle: item.libelle,
                                reponse: item.reponse,
                                assertions: item.assertions,
                                commentaire: item.commentaire,

                            });
                        }
                    } else {
                        updatedList.push({
                            libelleGroupe: item.groupe,
                            questions: [{
                                question_id: item.question_id,
                                groupe: item.groupe,
                                ref: item.ref,
                                libelle: item.libelle,
                                reponse: item.reponse,
                                assertions: item.assertions,
                                commentaire: item.commentaire,
                            }],
                        });
                    }
                });

                setSelectItems(updatedList);

                setLoading(false);
            }, 1500)
        }
            
    }, [datas, setSelectItems]);

    const handleAdd = () => {
        setLoading(true);
        setTimeout(() => {
            const newElement = {
                libelleGroupe: "",
                questions: [{
                    question_id: null,
                    groupe: "",
                    ref: "",
                    libelle: "",
                    reponse: "1",
                    refActivite: "",
                    assertions: [],
                    commentaire: "",
                    isAdd: true
                }],
            };

            setSelectItems([...selectItems, newElement]);

            notification.success({
                description: "Question ajoutée avec succès !",
            });
            setLoading(false);
        }, 1000);

    };

    const handleDeleteElement = (questionIndex, index,) => {
        setLoading(true);
        setTimeout(() => {
            const updated = [...selectItems];
            updated[questionIndex].questions.splice(index, 1);

            const updatedList = updated.filter((item) => item.questions.length > 0)

            setSelectItems(updatedList);

            notification.success({
                description: "Question supprimée avec succès !",
            });
            setLoading(false)
        }, 1000);
    };

    const handleModalOpen = () => {
        setIsModalOpen(prevState => !prevState);
    };

    return (
        <>
            <div className="modal-body">
                <PageChildLabel label={"Cycles revus"} />
                <div className="d-flex justify-content-end align-center my-2 me-5">
                    <div className="d-flex flex-wrap align-center">
                        <Space>
                            {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                                <Button
                                    size={"middle"}
                                    style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                    onClick={handleAdd}
                                    icon={<FontAwesomeIcon icon={faCartPlus}/>}
                                >
                                    Ajouter
                                </Button>
                            </Tooltip>}
                            {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                                <Button
                                    size={"middle"}
                                    style={{color: "white", backgroundColor: appTheme.colors.primary}}
                                    onClick={handleModalOpen}
                                    icon={<FontAwesomeIcon icon={faCloudUpload}/>}
                                >
                                    Charger
                                </Button>
                            </Tooltip>}
                        </Space>
                    </div>
                </div>
                <div className="table-responsive pt-5 pb-10">
                    <TableContainer>
                        <TableChackra variant="simple">
                            <Thead>
                                <Tr>
                                    {tableHeaders.map((item, index) => (
                                        <Th width={item.width || 100} key={index}><TableTitle>{item.title}</TableTitle> </Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {selectItems
                                    ? (selectItems ?? []).map((question, questionIndex) => (
                                        question.questions.map((item, itemIndex) => {
                                            const questionLibelle = (itemIndex === 0) ? question.libelleGroupe : '';
                                            return (
                                                <>
                                                    <Tr key={`${questionIndex}-${itemIndex}`}>
                                                        {itemIndex === 0 && (
                                                            <Td width={200} rowSpan={question.questions.length}>
                                                                <Input.TextArea
                                                                    style={{fontSize: "12px", fontWeight: "700", textTransform: "uppercase"}}
                                                                    size="large"
                                                                    placeholder="Saisisser le groupe..."
                                                                    bordered={false}
                                                                    value={questionLibelle}
                                                                    maxLength={2000}
                                                                    readOnly={true}
                                                                    // showCount
                                                                    autoSize={{
                                                                        minRows: 1,
                                                                        maxRows: 5,
                                                                    }}

                                                                />
                                                            </Td>
                                                        )}
                                                        {/*<Td width={100} >*/}
                                                        {/*    <Tag*/}
                                                        {/*        style={{fontSize: "13px", fontWeight: "700", textTransform: "uppercase"}}*/}
                                                        {/*    >*/}
                                                        {/*        {item.ref}*/}
                                                        {/*    </Tag>*/}
                                                        {/*</Td>*/}
                                                        <Td width={300}>
                                                            <Input.TextArea
                                                                style={{fontSize: "14px"}}
                                                                placeholder={"Saisisser le libellé..."}
                                                                size="large"
                                                                bordered={false}
                                                                value={item.libelle}
                                                                maxLength={3000}
                                                                // showCount
                                                                autoSize={{
                                                                    minRows: 1,
                                                                    maxRows: 6,
                                                                }}
                                                                onChange={(e) => {
                                                                    const updated = [...selectItems];
                                                                    updated[questionIndex].questions[itemIndex].libelle = e.target.value;
                                                                    setSelectItems(updated);
                                                                }}
                                                            />
                                                        </Td>
                                                        <Td width={100}>
                                                            {item.isAdd ?
                                                                <Select
                                                                    mode='tags'
                                                                    style={{width: '100%', fontSize: "12px"}}
                                                                    placeholder='choisir les assertions'
                                                                    value={item.assertions}
                                                                    options={assertions.map((item) => ({
                                                                        label: item.libelle,
                                                                        value: item.libelle,
                                                                    }))}
                                                                    onChange={(value) => {
                                                                        const updated = [...selectItems];
                                                                        updated[questionIndex].questions[itemIndex].assertions = value;
                                                                        setSelectItems(updated);
                                                                    }}
                                                                />
                                                                :
                                                                <div className="d-flex flex-column">
                                                                    {item.assertions.map((assertion, indexAssertion) => (
                                                                        assertion ? <div className="mb-2" key={indexAssertion}>
                                                                                <Tag
                                                                                    style={{fontSize: "14px", fontWeight: "600", textTransform: "uppercase"}}
                                                                                >
                                                                                    {assertion}
                                                                                </Tag>
                                                                            </div>
                                                                            :
                                                                            ""
                                                                    ))}
                                                                </div>
                                                            }
                                                        </Td>
                                                        <Td width={150}>
                                                            <select
                                                                style={{fontSize: '12px'}}
                                                                name='reponse'
                                                                className="form-select text-center"
                                                                onChange={(e) => {
                                                                    const updated = [...selectItems];
                                                                    updated[questionIndex].questions[itemIndex].reponse = e.target.value === 'S/O' ? '1' : e.target.value === 'OUI' ? '2' : '3';
                                                                    setSelectItems(updated);
                                                                }}
                                                                value={item.reponse === '1' ? 'S/O': item.reponse === '2' ? 'OUI' : 'NON'  }
                                                            >
                                                                {['S/O', 'OUI', 'NON'].map((opt, index) => (
                                                                    <option key={index} value={opt}>
                                                                        {opt}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </Td>
                                                        <Td width={300}>
                                                            <Input.TextArea
                                                                style={{fontSize: "14px",}}
                                                                size="large"
                                                                bordered={false}
                                                                placeholder="Saississer le commentaire..."
                                                                value={item.commentaire}
                                                                maxLength={3000}
                                                                // showCount
                                                                autoSize={{
                                                                    minRows: 1,
                                                                    maxRows: 6,
                                                                }}
                                                                onChange={(e) => {
                                                                    const updated = [...selectItems];
                                                                    updated[questionIndex].questions[itemIndex].commentaire = e.target.value;
                                                                    setSelectItems(updated);
                                                                }}
                                                            />
                                                        </Td>
                                                        <Td width={70} colSpan={itemIndex}>
                                                            {loading ? (
                                                                <Spinner />
                                                            ) : (
                                                                <>
                                                                    <Space>
                                                                        <Tooltip title='Supprimer une queston'>
                                                                            <Button
                                                                                onClick={(e) => handleDeleteElement(questionIndex, itemIndex, e)}
                                                                                title="Supprimer ?"
                                                                                type="primary"
                                                                                icon={<DeleteOutlined />}
                                                                                danger
                                                                            />
                                                                        </Tooltip>
                                                                    </Space>
                                                                </>
                                                            )}
                                                        </Td>
                                                    </Tr>
                                                </>
                                            );
                                        })
                                    ))
                                    : null}
                            </Tbody>
                        </TableChackra>
                    </TableContainer>
                </div>
                <br/>
                <br/>
                <div className="d-flex flex-column mb-3 mx-2">
                    <FormLabel label="CONCLUSION" />
                    <Input.TextArea
                        style={{fontSize: "14px"}}
                        size="large"
                        placeholder="Saisisser la conclusion..."
                        value={conclusion}
                        maxLength={5000}
                        showCount
                        autoSize={{
                            minRows: 8,
                            maxRows: 10,
                        }}
                        onChange={(e) => setConclusion(e.target.value)}
                    />
                    <div className='mt-5'>
                        <div className="">
                            <Checkbox
                                onChange={() => setIsAddMemoire(!isAddMemoire)}
                                value={isAddMemoire}
                            >
                                Aide memoire
                            </Checkbox>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <ModalQuestions
                    handleVisibility={handleModalOpen}
                    visible={isModalOpen}
                    selectData={selectItems}
                    setSelectData={setSelectItems}
                    assertions={assertions}
                    libelleCycle={libelleCycle}
                    chargement={loading || chargement}
                />
            )}
        </>
    );
}

export {TraitementTravauxExpertComptable}