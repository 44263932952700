import React, {useEffect, useState} from "react";
import {Button, Input, Table, Tag, Tooltip} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {getListeProgramme} from "../../../../../../../data/params/programme";
import {ajouterProgrammeTache} from "../../../../../../../data/stategieGlobale";
import {succesMessage} from "../../../../../../../helpers/AssetsHelpers";
import swal from "sweetalert";
import {CustomInputNumber} from "../../../../../../layout/tools/InputNumber";
import useUsers from "../../../../../../../hooks/users/useUser";
import TableTitle from "../../../../../../common/TableTitle";
import {appTheme} from "../../../../../../../config/theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartPlus, faSave} from "@fortawesome/free-solid-svg-icons";
import {getListeMembreMission} from "../../../../../../../data/exercice";

const ProgrammeControle1 = ({
        mission,
        programmeTaches,
        strategies,
        isProvisoire,
        setSelectedMap,
        codeSommaire ,
        heureEntree,
        date,
        tache_id,
        intervention_id,
    }) => {
    const [loading, setLoading] = useState(false);
    const [selectItems, setSelectItems] = useState([]);
    const [programmes, setProgrammes] = useState([]);
    const [users, setUsers] = useState([]);
    const [index, setIndex] = useState(1);

    useEffect(() => {
        const fetchUserMission = async (id) => {
            setLoading(true);
            getListeMembreMission(id)
                .then((res) => {
                    if(res){
                        setUsers(res);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    console.error(error);
                })
        };

        fetchUserMission(mission.id);
    }, [mission]);

    const fetchProgrammes = async () => {
        setLoading(true);
        getListeProgramme()
            .then((res) => {
                setProgrammes(res.result);
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchProgrammes()
    },[]);

    useEffect(() => {
        if(programmeTaches && programmeTaches.length > 0 ){
            const taches = programmeTaches.map((tache) => ({
                programme_id: tache.programme_id,
                programme: tache.programme,
                initial: tache.initial,
                user: tache.user,
                coutHoraire: tache.coutHoraire,
                tempsProv: tache.tempsProv,
                totalProv: tache.totalProv,
                tempsDef: tache.tempsDef,
                totalDef: tache.totalDef,
            }))

            setSelectItems(taches)
        }
    }, [programmeTaches, setSelectItems])

    useEffect(() => {
        if((selectItems ?? []).length > 0){
            const updated = selectItems.map((item) => ({
                programme_id: item.programme_id,
                programme: item.programme,
                initial: users.find(user => user.id === item.initial)?.initial || "",
                user: item.initial,
                coutHoraire: item.coutHoraire,
                tempsProv: item.tempsProv,
                totalProv: item.totalProv,
                tempsDef: item.tempsDef,
                totalDef: item.totalDef,
            }))
            setSelectedMap(updated)
        }
    }, [selectItems, setSelectedMap, users])

    const handleAdd = (e) => {
        e.preventDefault()
        const newElement = {
            programme_id: index,
            programme: '',
            initial: '',
            user: '',
            coutHoraire: 0,
            tempsProv: 0,
            totalProv: 0,
            tempsDef: 0,
            totalDef: 0,
        };

        setIndex(index + 1);

        setSelectItems([...selectItems, newElement]);
    };

    const handleDeleteElement = (index) => {
        const updatedList = selectItems.filter((_, i) => i !== index);
        setSelectItems(updatedList);
    };

    const handleCoutHoraireInput = (value, index) =>{
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  coutHoraire: parseInt(value), totalProv: parseInt(value) * (item.tempsProv || 0), totalDef: parseInt(value) * (item.tempsDef || 0)} : item
        );

        setSelectItems(updatedList);
    }

    const handleTempProvInput = (value, index) =>{
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  tempsProv: parseInt(value), totalProv: parseInt(value) * (item.coutHoraire || 0)} : item
        );

        setSelectItems(updatedList);
    }

    const handleTempsDefInput = (value, index) =>{
        const updatedList = selectItems.map((item, i) =>
            i === index ? { ...item,  tempsDef: parseInt(value), totalDef: parseInt(value) * (item.coutHoraire || 0)} : item
        );

        setSelectItems(updatedList);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = {
            strategie_id: strategies.id,
            isProvisoire,
            datas: selectItems,
            exercice_id: mission.id,
            client_id: mission.client_id,
            codeSommaire ,
            heureEntree,
            date,
            tache_id,
            intervention_id,
        };

        const { status, message } = await ajouterProgrammeTache(data);
        if (status === 'success') {
            setLoading(false)
            await swal(succesMessage, '', 'success')
        } else {
            setLoading(false)
            await swal(message, '', 'error')
        }
    };

    const columns = [
        {
            title: () => <TableTitle>Programme</TableTitle>,
            dataIndex: 'programme',
            width: 300,
            key: 'index',
            render: (_, record, index) => (
                <Input.TextArea
                    size="large"
                    placeholder="Saissiser le programme"
                    readOnly={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                    maxLength={2000}
                    bordered={false}
                    showCount
                    autoSize={{
                        minRows: 1,
                        maxRows: 6,
                    }}
                    value={record.programme}
                    onChange={(e) =>{
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item, programme: e.target.value} : item
                        );

                        setSelectItems(updatedList);
                    }}
                />
                // <select
                //     style={{fontSize: '12px'}}
                //     name='action'
                //     className="form-select"
                //     onChange={(e) => {
                //         const updatedList = selectItems.map((item, i) =>
                //             i === index ? { ...item,  programme_id: parseInt(e.target.value), programme: programmes.find((opt) => opt.id === parseInt(e.target.value))?.libelle} : item
                //         );
                //
                //         setSelectItems(updatedList);
                //     }}
                //     value={record.programme_id}
                // >
                //     <option>Selectionner un programme...</option>
                //     {programmes.map((opt, index) => (
                //         <option key={index} value={opt.id}>
                //             {opt.libelle}
                //         </option>
                //     ))}
                // </select>
            ),
        },
        {
            title: () => <TableTitle>Initial/Noms</TableTitle>,
            dataIndex: 'initial',
            key: 'initial',
            width: 150,
            render: (_, record, index) => (
                <select
                    name='initial'
                    className="form-select fs-5"
                    onChange={(e) => {
                        const updatedList = selectItems.map((item, i) =>
                            i === index ? { ...item,  user: parseInt(e.target.value), initial: parseInt(e.target.value)} : item
                        );

                        setSelectItems(updatedList);
                    }}
                    value={record.initial}
                >
                    <option>Sélectionner utilisateur...</option>
                    {users?.map((opt, index) => (
                        <option key={index} value={opt.id}>
                            {opt.initial ? opt.initial : opt.nom_user.toUpperCase() + ' ' + (opt.prenom_user && opt.prenom_user.toUpperCase())}
                        </option>
                    ))}
                </select>
            ),
        },
        {
            title: () => <TableTitle>Coût horaire</TableTitle>,
            dataIndex: 'coutHoraire',
            key: 'coutHoraire',
            width: 120,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.coutHoraire}
                    name='coutHoraire'
                    onChange={handleCoutHoraireInput}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Temps Provisoire</TableTitle>,
            dataIndex: 'tempsProv',
            key: 'tempsProv',
            width: 120,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.tempsProv}
                    name='tempsProv'
                    onChange={handleTempProvInput}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Total Honoraire Provisoire</TableTitle>,
            dataIndex: 'totalProv',
            key: 'totalProv',
            width: 120,
            render: (_, record) => (
                <Tag>
                    <div className="text-end fs-5 fw-bolder">
                        {record.totalProv.toLocaleString("fr")}
                    </div>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Temps Définitif</TableTitle>,
            dataIndex: 'tempsDef',
            key: 'tempsDef',
            width: 120,
            render: (_, record, index) => (
                <CustomInputNumber
                    value={record.tempsDef}
                    name='tempsDef'
                    onChange={handleTempsDefInput}
                    index={index}
                />
            ),
        },
        {
            title: () => <TableTitle>Total Honoraire Définitif</TableTitle>,
            dataIndex: 'totalDef',
            key: 'totalDef',
            width: 120,
            render: (_, record) => (
                <Tag>
                    <div className="text-end fs-5 fw-bolder">
                        {record.totalDef.toLocaleString("fr")}
                    </div>
                </Tag>
            ),
        },
        {
            title: () => <TableTitle>Action</TableTitle>,
            dataIndex: 'action',
            width: 50,
            render: (_, record, index) => (
                <>
                    <Button
                        onClick={() => handleDeleteElement(index)}
                        title="Supprimer ?"
                        type="primary"
                        icon={<DeleteOutlined />}
                        danger
                    />
                </>
            ),
        },
    ];

    return (
        <div className="d-flex flex-column fv-plugins-bootstrap5 fv-plugins-framework pb-10">
            {<div className="d-flex justify-content-end align-items-end mb-2">
                <Tooltip title="Enregistrer">
                    <Button
                        disabled={(mission.isCloture === 1 || mission.isClotureProvisoire === 1)}
                        size={"large"}
                        // loading={loading}
                        style={{
                            backgroundColor: appTheme.colors.secondary,
                            color: "white"
                        }}
                        icon={<FontAwesomeIcon style={{paddingRight: "10"}} icon={faSave}/>}
                        onClick={handleSubmit}
                    >
                        {!loading &&
                            <span className='indicator-label text-white'>Enregistrer</span>}
                        {loading && (
                            <span className='indicator-progress fs-9 text-white'
                                  style={{display: 'block'}}>
                                  Patientez svp...
                                  <span
                                      className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                        )}
                    </Button>
                </Tooltip>
            </div>}
           <div className="d-flex justify-content-end align-items-end my-5">
               {(mission.isCloture !== 1 || mission.isClotureProvisoire !==1) && <Tooltip color={appTheme.colors.primary} title="Ajouter une nouvelle question">
                   <Button
                       size={"middle"}
                       style={{color: "white", backgroundColor: appTheme.colors.primary}}
                       onClick={handleAdd}
                       icon={<FontAwesomeIcon icon={faCartPlus}/>}
                   >
                       Ajouter
                   </Button>
               </Tooltip>}
           </div>
            <Table
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={selectItems}
                loading={loading}
                scroll={{
                    y: 800,
                }}
                // bordered
                size={"small"}
                pagination={false}
            />
        </div>
    );
}

export {ProgrammeControle1}