import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {ModalTitle} from "./modals.components";
import {Form, Formik} from "formik";
import {Button, Col, Input, notification, Row, Select, Tooltip, Typography} from "antd";
import FormError from "../common/Form/FormError";
import ModalAlert from "./Modal";
import {copyFileSommaire, ModdifierFileSommaire} from "../../data/file";
import swal from "sweetalert";
import FormLabel from "../common/Form/FormLabel";
import {Spinner} from "@chakra-ui/react";
import {dossierSommaires} from "../client/modals/missionSteps/EA/DPSommaires";
import {getListeSommaireActive} from "../../data/params/sommaire";

const options = [
    { label: "Dossier Permanent", value: 0 },
    { label: "Sommaire LEA", value: 1 },
];

export const ModifyFichier = ({
        handleVisibility,
        mission,
        codeSommaire,
        fetchData,
        visible,
        file,
        chargement,
    }) => {
    const [loading, setLoading] = useState(false);

    const handleModifierSommaire = (value) => {
        setLoading(true);
        const data = {
            file_id: value.file_id || file.id ,
            reference: value.reference || '',
            libelle: value.libelle || "",
            description: value.description || "",
        };
        ModdifierFileSommaire(data)
            .then(async (res) => {
                const {status, message} = res;
                if (status === 'success') {
                    setLoading(false)
                    fetchData(mission.id, codeSommaire);
                    handleVisibility();
                    await swal(`Opération effectuée avec succès`, '', 'success')
                } else {
                    await swal(message, '', 'error')
                    setLoading(false)
                }
            })
            .catch((err) => {
                notification.error({
                    description: err,
                });
            })
    }

    const dataValidationScheme = Yup.object({
        file_id: Yup.number()
            .required("Veuillez sélectionner le fichier a modifier")
            .nullable("Le fichier est obligatoire")
            .typeError("Le fichier est obligatoire"),
    });

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Modification du fichier {file?.libelle || ""}</ModalTitle>}
                footer={[]}
                modalStyles={{
                    top: 30,
                }}
                width={600}
            >
                <Formik
                    initialValues={{
                        file_id: file ? file.id : null ,
                        reference: file ? file.reference : "",
                        libelle: file ? file.libelle : "",
                        description: file ? file.description : "",
                    }}
                    onSubmit={(values) => handleModifierSommaire(values)}
                    validationSchema={dataValidationScheme}
                >
                    {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                        <Form>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <FormLabel label="Reférence" />
                                    <Input
                                        size="large"
                                        placeholder="Saisir la reférence"
                                        name="reference"
                                        value={values.reference}
                                        onChange={(e) =>
                                            setFieldValue("reference", e.target.value)
                                        }
                                    />
                                    {touched.reference && errors.reference && (
                                        <FormError fieldName="reference" />
                                    )}

                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Nom du fichier" required />
                                    <Input
                                        size="large"
                                        placeholder="Le nom du fichier"
                                        name="libelle"
                                        value={values.libelle}
                                        onChange={(e) =>
                                            setFieldValue("libelle", e.target.value)
                                        }
                                    />
                                    {touched.libelle && errors.libelle && (
                                        <FormError fieldName="libelle" />
                                    )}
                                </Col>
                                <Col span={24}>
                                    <FormLabel label="Note" />
                                    <Input.TextArea
                                        size="large"
                                        placeholder="Notes..."
                                        name="description"
                                        value={values.description}
                                        onChange={(e) =>
                                            setFieldValue("description", e.target.value)
                                        }
                                        showCount
                                        maxLength={2000}
                                        rows={2}
                                    />
                                    {touched.description && errors.description && (
                                        <FormError fieldName="description" />
                                    )}
                                </Col>
                                <Col span={24}>
                                    <Button
                                        type="primary"
                                        block
                                        size="large"
                                        loading={loading || chargement}
                                        disabled={loading || chargement}
                                        onClick={handleSubmit}
                                    >
                                        {!(loading || chargement) && <span className='indicator-label'>Enregistrer</span>}
                                        {(loading || chargement) && (
                                            <span className="indicator-progress text-white">
                                            Veuillez patienter...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                        </span>
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </ModalAlert>
        </>
    );
};

export const CopyFileSommaire = ({
        handleVisibility,
        fetchData,
        visible,
        file,
         mission,
         codeSommaire,
        chargement,
    }) => {
    const [loading, setLoading] = useState(false);
    const [sommairesTable, setSommairesTable] = useState([]);

    const fetchSommaire = async () => {
        getListeSommaireActive()
            .then((res) => {
                setSommairesTable(res.result);
            });
    };

    useEffect(() => {
        fetchSommaire();
    }, []);

    const handleCopySommaireFile = (value) => {
        setLoading(true);
        const data = {
            file_id: value.file_id || file.id,
            reference: value.reference || '',
            libelle: value.libelle || "",
            description: value.description || "",
            code: value.code || "",
            exercice_id: mission.id,
            isAddPermanent: value.isAddPermanent,
        };
        // setLoading(false);
        // return console.log("data", data)
        copyFileSommaire(data)
            .then(async (res) => {
                const {status, message} = res;
                if (status === 'success') {
                    setLoading(false)
                    handleVisibility();
                    fetchData(mission.id, codeSommaire);
                    await swal(`Opération effectuée avec succès`, '', 'success')
                } else {
                    await swal(message, '', 'error')
                    setLoading(false)
                }
            })
            .catch((err) => {
                notification.error({
                    description: err,
                });
            })
    }

    const dataValidationScheme = Yup.object({
        file_id: Yup.number()
            .required("Veuillez sélectionner le fichier a modifier")
            .nullable("Le fichier est obligatoire")
            .typeError("Le fichier est obligatoire"),
        code: Yup.string()
            .required("Veuillez sélectionner le sommaire DP ou LEA")
            .typeError("Le sommaire est obligatoire"),
    });

    return (
        <>
            <ModalAlert
                closeModal={handleVisibility}
                show={visible}
                title={<ModalTitle> Copy du fichier {file?.libelle || ""}</ModalTitle>}
                footer={[]}
                modalStyles={{
                    top: 30,
                }}
                width={600}
            >
                <Formik
                    initialValues={{
                        file_id: file ? file.id : null ,
                        reference: file ? file.reference : "",
                        libelle: file ? file.libelle : "",
                        description: file ? file.description : "",
                        code: "",
                        exercice_id: "",
                        isAddPermanent: false,
                    }}
                    onSubmit={(values) => handleCopySommaireFile(values)}
                    validationSchema={dataValidationScheme}
                >
                    {({ values, handleSubmit, setFieldValue, touched, errors }) => (
                        <Form>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <FormLabel label="Reférence" />
                                    <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-5">
                                        {options.map((item, index) => (
                                            <li className="nav-item" key={index}>
                                                <Tooltip title={item?.label}>
                                                    <button
                                                        className={`nav-link text-active-primary pb-4 ${values.isAddPermanent === (item.value === 0) ? 'active' : ''}`}
                                                        onClick={() => {
                                                            setLoading(true);
                                                            setTimeout(() => {
                                                                setFieldValue("code", "")
                                                                setFieldValue("isAddPermanent", (item.value === 0));
                                                                setLoading(false);
                                                            }, 1000)
                                                        }}
                                                    >
                                                        <span className="fs-4 fw-bolder">
                                                            {item.label}
                                                        </span>
                                                    </button>
                                                </Tooltip>
                                            </li>
                                        ))}
                                    </ul>
                                    {touched.reference && errors.reference && (
                                        <FormError fieldName="reference" />
                                    )}

                                </Col>
                                {loading ?
                                    <div className="d-flex justify-content-center align-center">
                                        <Spinner/>
                                    </div>
                                    :
                                    <>
                                        <Col span={24}>
                                            <FormLabel label="Reférence" />
                                            <Input
                                                size="large"
                                                placeholder="Saisir la reférence"
                                                name="reference"
                                                value={values.reference}
                                                onChange={(e) =>
                                                    setFieldValue("reference", e.target.value)
                                                }
                                            />
                                            {touched.reference && errors.reference && (
                                                <FormError fieldName="reference" />
                                            )}

                                        </Col>
                                        <Col span={24}>
                                            <FormLabel label="Nom du fichier" required />
                                            <Input
                                                size="large"
                                                placeholder="Le nom du fichier"
                                                name="libelle"
                                                value={values.libelle}
                                                onChange={(e) =>
                                                    setFieldValue("libelle", e.target.value)
                                                }
                                            />
                                            {touched.libelle && errors.libelle && (
                                                <FormError fieldName="libelle" />
                                            )}
                                        </Col>
                                        <Col span={24}>
                                            <FormLabel label="Note" />
                                            <Input.TextArea
                                                size="large"
                                                placeholder="Notes..."
                                                name="description"
                                                value={values.description}
                                                onChange={(e) =>
                                                    setFieldValue("description", e.target.value)
                                                }
                                                showCount
                                                maxLength={2000}
                                                rows={4}
                                            />
                                            {touched.description && errors.description && (
                                                <FormError fieldName="description" />
                                            )}
                                        </Col>
                                        <Col span={24}>
                                            { (values.isAddPermanent ) ?
                                                <>
                                                    <FormLabel label="Copie dans sommaire Dossiers Permanent"/>
                                                    <Select
                                                        showSearch
                                                        placeholder="Sélectionnez..."
                                                        optionFilterProp="label"
                                                        filterOption={(input, option) =>
                                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        options={dossierSommaires && dossierSommaires.map((sommaire) => ({
                                                            value: sommaire.code,
                                                            label: sommaire.libelle.toUpperCase()
                                                        }))}
                                                        onChange={(value) =>
                                                            setFieldValue("code", value)
                                                        }
                                                        size="large"
                                                        style={{ width: "100%" }}
                                                    />
                                                </>
                                                :
                                                <>
                                                    <FormLabel label="Copie dans sommaire LEA"/>
                                                    <Select
                                                        showSearch
                                                        placeholder="Sélectionnez..."
                                                        optionFilterProp="label"
                                                        filterOption={(input, option) =>
                                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        options={sommairesTable.map((statut) => ({
                                                            value: statut.libelle,
                                                            label: `${statut.libelle} - ${statut.description}`,
                                                        }))}

                                                        onChange={(value) =>
                                                            setFieldValue("code", value)
                                                        }
                                                        size="large"
                                                        style={{ width: "100%" }}
                                                    />
                                                </>
                                            }
                                            {(!values.code ) && (
                                                <Typography.Text type="danger">Veuillez sélectionner le sommaire </Typography.Text>
                                            )}
                                        </Col>
                                    </>
                                }
                                <Col span={24}>
                                    <Button
                                        type="primary"
                                        block
                                        size="large"
                                        loading={loading || chargement}
                                        disabled={loading || chargement}
                                        onClick={handleSubmit}
                                    >
                                        {!(loading || chargement) && <span className='indicator-label'>Enregistrer</span>}
                                        {(loading || chargement) && (
                                            <span className="indicator-progress text-white">
                                            Veuillez patienter...
                                            <span className="spinner-border spinner-border-sm align-middle ms-2 text-white"></span>
                                        </span>
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </ModalAlert>
        </>
    );
};